import { forwardRef, useState } from 'react';
import { motion } from 'framer-motion';
import { styled } from '../../styles/stitches.config';
import { DropdownOption, LabelColor } from '../../types/constants';
import { CommandRoot, CommandEmpty, CommandList, CommandItem } from './cmdk';
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
  PopoverClose,
} from './popover';
import Box from '../box';
import { ButtonText, Caption, Text } from '../typography/text';
import Spinner from '../helper/spinner';
import Label from '../typography/label';
import { fadeInOutMotion } from '../../styles/motions';

const StyledPopoverTrigger = styled(PopoverTrigger, {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: 5,
  border: '1px solid $func-border-main',
  backgroundColor: '$white',
  color: '$primary50',
  px: 7,
  cursor: 'pointer',
  transition: '$colors',
  '&:hover': {
    backgroundColor: '$func-hover',
  },
  '&[data-state=open]': {
    color: '$primary60',
    backgroundColor: '$primary20',
  },
  '&[data-error=true]': {
    border: '1px solid $error60',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: '$func-disabled-dark',
    backgroundColor: '$func-disabled-light',
  },
  variants: {
    size: {
      sm: {
        height: 30,
      },
      md: {
        height: 36,
      },
      lg: {
        height: 44,
      },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const StyledPopoverClose = styled(PopoverClose, {
  px: 7,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$text-primary',
  cursor: 'pointer',
  backgroundColor: '$white',
  transition: '$colors',
  borderRadius: 5,
  border: 'none',
  '&:hover': {
    backgroundColor: '$func-hover',
  },
  '&:active': {
    backgroundColor: '$func-active',
  },
  '&[data-state=active]': {
    backgroundColor: '$primary20',
  },
  '&:disabled': {
    color: '$func-disabled-dark',
    backgroundColor: '$white',
  },
  variants: {
    size: {
      sm: { minHeight: 40 },
      md: { minHeight: 50 },
      lg: { minHeight: 60 },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface ChipSelectProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  options: Pick<DropdownOption, 'id' | 'name' | 'value' | 'other_info'>[];
  value: { name: string; value: DropdownOption['value'] };
  onChange: (newValue: { name: string; value: DropdownOption['value'] }) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
  disabled?: boolean;
  optionSize?: 'sm' | 'md' | 'lg';
  optionIconSize?: number;
  chipColor?: LabelColor;
}

const ChipSelect = forwardRef<HTMLDivElement, ChipSelectProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value,
      onChange,
      options,
      isLoading,
      isError,
      error,
      disabled,
      optionSize = 'sm',
      optionIconSize,
      ...restProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const onCommandValueChange = (hoveredName: string) => {
      setSelectedName(hoveredName);
    };
    const onCommandClick = () => {
      // * Because CMDK automatically turns its value (we feed option.name as its value) into lower case.
      const selectedOption = options?.find((option) => option.name.toLowerCase() === selectedName);
      onChange(
        selectedOption
          ? { name: selectedOption.name, value: selectedOption.value }
          : { name: '', value: '' }
      );
    };
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <PopoverRoot open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <StyledPopoverTrigger name={fieldName} disabled={disabled} data-error={isError}>
            <Box
              css={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'inherit',
                flex: 1,
              }}>
              <Text
                css={{
                  display: 'block',
                  textAlign: 'center',
                  color: value?.value ? 'inherit' : '$text-hint',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}>
                {value?.value ? value.name : placeholder}
              </Text>
            </Box>
          </StyledPopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              sideOffset={4}
              css={{ width: 'var(--radix-popover-trigger-width)', p: 6 }}>
              <CommandRoot
                value={value?.name}
                onValueChange={(hoveredName) => onCommandValueChange(hoveredName)}
                onClick={() => onCommandClick()}>
                {isLoading ? (
                  <Box css={{ p: 10 }}>
                    <Spinner />
                  </Box>
                ) : options.length > 0 ? (
                  <CommandList css={{ maxHeight: 280, overflow: 'auto' }}>
                    <CommandEmpty>
                      <ButtonText size={14} css={{ color: '$text-hint' }}>
                        No result
                      </ButtonText>
                    </CommandEmpty>
                    {options.map((option) => (
                      <CommandItem key={option.id} value={option.name}>
                        <StyledPopoverClose
                          data-state={value?.value === option.value ? 'active' : 'inactive'}
                          disabled={disabled}
                          size={optionSize}>
                          <Text
                            css={{
                              wordBreak: 'break-word',
                              textAlign: 'center',
                            }}>{`${option.name}`}</Text>
                        </StyledPopoverClose>
                      </CommandItem>
                    ))}
                  </CommandList>
                ) : (
                  <ButtonText size={14} css={{ color: '$text-hint' }}>
                    No option
                  </ButtonText>
                )}
              </CommandRoot>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
        <Box
          as={motion.div}
          initial={'hide'}
          variants={fadeInOutMotion}
          animate={isError ? 'show' : 'hide'}>
          <Caption css={{ color: '$error60' }}>{error}</Caption>
        </Box>
      </Box>
    );
  }
);

ChipSelect.displayName = 'ChipSelect';

export default ChipSelect;
