import type { ApplicationActionsMap } from '../../types';
import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { isNavBarOpenAtom, navBarWidthAtom } from '../../store/atoms';
import { elementGrowMotion } from '../../styles/motions';
import Box from '../box';
import Button from '../button/deprecated-button';
import Spacer from '../surface/spacer';
import Spinner from '../helper/spinner';
import Typography from '../typography/deprecated-typography';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../helper/tool-tip';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../helper/dialog';
import Divider from '../surface/divider';
import Checkbox from '../field/checkbox';
import Label from '../typography/label';
import MSymbol from '../icon/m-symbol';
import IconButton from '../button/icon-button';
import ColorfulChip from '../chip/colorful-chip';

const HeaderBar = ({
  headerTitle,
  applicationActionsMap,
  isLoading,
  onCancelClick,
  isCancelLoading,
  onEditClick,
  isEditLoading,
  onDeleteClick,
  isDeleteLoading,
  onCopyClick,
  isKeepLabels,
  setIsKeepLabels,
  isCopyLoading,
  onGenerateDocumentsClick,
  isGenerateDocumentsLoading,
}: {
  headerTitle?: string;
  applicationActionsMap?: ApplicationActionsMap;
  isLoading?: boolean;
  onCancelClick?: () => void;
  isCancelLoading?: boolean;
  onEditClick?: () => void;
  isEditLoading?: boolean;
  onDeleteClick?: () => void;
  isDeleteLoading?: boolean;
  onCopyClick?: () => void;
  isKeepLabels?: boolean;
  setIsKeepLabels?: Dispatch<SetStateAction<boolean>>;
  isCopyLoading?: boolean;
  onGenerateDocumentsClick?: () => void;
  isGenerateDocumentsLoading?: boolean;
}) => {
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const navBarWidth = useAtomValue(navBarWidthAtom);

  return (
    <Box
      as={motion.div}
      initial={isNavBarOpen ? 'shrink' : 'grow'}
      animate={isNavBarOpen ? 'shrink' : 'grow'}
      variants={elementGrowMotion(navBarWidth)}
      css={{
        px: '20px',
        py: '10px',
        backgroundColor: '$white',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid $secondary20',
        position: 'fixed',
        zIndex: 90,
        height: 57,
      }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ColorfulChip text="Official" color="sky" variant="solid" />
          <Box css={{ display: 'flex', flexGrow: 1, minWidth: 0, ml: 20 }}>
            {/* Header Title */}
            <Typography
              variant={'h5'}
              css={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>{`${headerTitle}`}</Typography>
          </Box>
          <Spacer axis={'horizontal'} css={{ $$size: '40px' }} />
          {/* Copy, Download, Print */}
          <Box css={{ display: 'flex', columnGap: '10px' }}>
            {applicationActionsMap?.show_copy ? (
              <DialogRoot>
                <DialogTrigger asChild>
                  <div>
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          color="secondary"
                          iconName="content_copy"
                          isIconFill={false}
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Copy`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </div>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ px: 40, py: 20, borderRadius: '$10' }}>
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                      <DialogTitle asChild>
                        <Typography as={'h5'} variant={'h5'}>{`Copy application`}</Typography>
                      </DialogTitle>
                      <Divider
                        axis={'horizontal'}
                        css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                      />
                      {/* Document Name */}
                      <Box>
                        <Typography
                          as={'p'}
                          variant={'caption-bold'}
                          css={{ color: '$secondary40', mb: 4 }}>{`Document Name`}</Typography>
                        <Typography as={'p'} variant={'button-14'}>{`${headerTitle}`}</Typography>
                      </Box>
                      {/* Keep */}
                      <Box>
                        <Typography
                          as={'p'}
                          variant={'caption-bold'}
                          css={{ color: '$secondary40', mb: 4 }}>{`Keep`}</Typography>
                        <Box css={{ display: 'flex', alignItems: 'center' }}>
                          <Box css={{ width: 16, height: 16 }}>
                            <Checkbox
                              id="is-keep-labels"
                              checked={isKeepLabels ?? false}
                              onChange={() => {
                                if (setIsKeepLabels) {
                                  setIsKeepLabels(!isKeepLabels);
                                }
                              }}
                            />
                          </Box>
                          <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                          <Label htmlFor="is-keep-labels">
                            <Typography variant={'body'}>{`Labels`}</Typography>
                          </Label>
                        </Box>
                      </Box>
                      {/* Action */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        <DialogClose asChild>
                          <Button variant={'borderless'} color={'secondary'}>{`Cancel`}</Button>
                        </DialogClose>
                        {/* <DialogClose asChild> */}
                        <Button
                          variant={'solid'}
                          color={'primary'}
                          isLoading={isCopyLoading}
                          onClick={() => {
                            if (onCopyClick) {
                              onCopyClick();
                            }
                          }}>{`Create`}</Button>
                        {/* </DialogClose> */}
                      </Box>
                    </Box>
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
            ) : null}
          </Box>
          <Spacer axis={'horizontal'} css={{ $$size: '20px' }} />
          {/* Edit, Cancel, Apply for Seal, Generate Document */}
          <Box css={{ display: 'flex', columnGap: '10px' }}>
            {applicationActionsMap?.show_cancel ? (
              <DialogRoot>
                <DialogTrigger asChild>
                  <Button
                    color={'error'}
                    variant={'outlined'}
                    startElement={
                      <MSymbol
                        iconName="undo"
                        weight={700}
                        css={{ color: 'inherit', fontSize: '16px !important' }}
                      />
                    }>{`Withdraw`}</Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                      {/* Cancel Title, Close Button */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                        <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                        <DialogTitle asChild>
                          <Typography
                            as={'h5'}
                            variant={'h5'}
                            css={{ flexGrow: 1 }}>{`Withdraw application`}</Typography>
                        </DialogTitle>
                        <DialogClose asChild>
                          <IconButton iconName="close" size="small" isIconBold={true} />
                        </DialogClose>
                      </Box>
                      {/* Description */}
                      <DialogDescription css={{ pl: 40 }}>
                        <Typography variant={'body'} css={{ color: '$secondary70' }}>{`
                        If you withdraw your application now, we will no longer proceed with the subsequent review and it will be saved to Draft. Are you sure you want to cancel?
                        `}</Typography>
                      </DialogDescription>
                      {/* Action */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        <Button
                          variant={'solid'}
                          color={'primary'}
                          isLoading={isCancelLoading}
                          onClick={() => {
                            if (onCancelClick) {
                              onCancelClick();
                            }
                          }}>{`Withdraw`}</Button>
                        <DialogClose asChild>
                          <Button variant={'borderless'} color={'secondary'}>{`Back`}</Button>
                        </DialogClose>
                      </Box>
                    </Box>
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
            ) : null}
            {applicationActionsMap?.show_delete ? (
              <DialogRoot>
                <DialogTrigger asChild>
                  <Button
                    color={'error'}
                    variant={'outlined'}
                    startElement={
                      <MSymbol
                        iconName="delete"
                        css={{ color: 'inherit', fontSize: '16px !important' }}
                        fill={false}
                        weight={700}
                      />
                    }>{`Delete`}</Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                      {/* Delete Title, Close Button */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                        <MSymbol iconName="error" css={{ color: '$error60' }} weight={700} />
                        <DialogTitle asChild>
                          <Typography
                            as={'h5'}
                            variant={'h5'}
                            css={{ flexGrow: 1 }}>{`Delete application`}</Typography>
                        </DialogTitle>
                        <DialogClose asChild>
                          <IconButton iconName="close" size="small" isIconBold={true} />
                        </DialogClose>
                      </Box>
                      {/* Description */}
                      <DialogDescription css={{ pl: 40 }}>
                        <Typography variant={'body'} css={{ color: '$secondary70' }}>{`
                        Are you sure you want to permanently delete the application, its comments and attachments, and all of its data?
                        `}</Typography>
                      </DialogDescription>
                      {/* Action */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        <Button
                          variant={'solid'}
                          color={'primary'}
                          isLoading={isDeleteLoading}
                          onClick={() => {
                            if (onDeleteClick) {
                              onDeleteClick();
                            }
                          }}>{`Delete`}</Button>
                        <DialogClose asChild>
                          <Button variant={'borderless'} color={'secondary'}>{`Cancel`}</Button>
                        </DialogClose>
                      </Box>
                    </Box>
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
            ) : null}
            {applicationActionsMap?.show_edit ? (
              <Button
                variant={'outlined'}
                startElement={
                  <MSymbol
                    iconName="edit"
                    css={{ color: 'inherit', fontSize: '16px !important' }}
                    weight={700}
                  />
                }
                color={'primary'}
                spinnerColor={'primary'}
                isLoading={isEditLoading}
                onClick={() => {
                  if (onEditClick) {
                    onEditClick();
                  }
                }}>{`Edit`}</Button>
            ) : null}
            {applicationActionsMap?.show_generate_document ? (
              <Button
                color={'primary'}
                variant={'solid'}
                disabled={isGenerateDocumentsLoading}
                startElement={isGenerateDocumentsLoading ? <Spinner elementFor="button" /> : null}
                onClick={() => {
                  if (onGenerateDocumentsClick) {
                    onGenerateDocumentsClick();
                  }
                }}>{`${isGenerateDocumentsLoading ? 'Generating' : 'Generate Documents'}`}</Button>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
export default HeaderBar;
