import { ComponentProps, forwardRef } from 'react';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../../store/atoms';
import { styled } from '../../styles/stitches.config';
import { Text, Caption } from '../typography/text';
import IconButton from '../button/icon-button';
import DocAppActiveIcon from '../../assets/images/icon-doc-app-active.svg';

const FileCardRoot = styled('div', {
  borderRadius: 10,
  p: 10,
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
  backgroundColor: '$func-hover',
  transition: '$colors',
  '&:hover': {
    backgroundColor: '$primary10',
  },
  '&:active': {
    backgroundColor: '$primary20',
  },
});

const FileCardThumbnail = styled('div', {
  flexShrink: 0,
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  overflow: 'hidden',
});

const FileCardThumbnailImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const FileCardContent = styled('div', {
  flex: 1,
});

const FileCardContentHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: 4,
});

const FileCardAction = styled('div', {
  ml: 'auto',
});

interface FileCardProps extends ComponentProps<typeof FileCardRoot> {
  fileName: string;
  fileSize: number;
  previewSignedUrl: string;
  signedUrl: string;
  needAction?: boolean;
}

const FileCard = forwardRef<HTMLDivElement, FileCardProps>(
  ({ fileName, fileSize, previewSignedUrl, signedUrl, needAction = true, ...restProps }, ref) => {
    const setIsToastOpen = useSetAtom(isToastOpenAtom);
    const setToastContent = useSetAtom(toastContentAtom);
    const onDownloadClick = async () => {
      try {
        const res = await fetch(signedUrl);
        const blob = await res.blob();
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `${fileName}`;
        anchor.click();
      } catch (err) {
        setToastContent({
          isError: true,
          error: `File download failed`,
        });
        setIsToastOpen(true);
      }
    };
    return (
      <FileCardRoot ref={ref} {...restProps}>
        <a target="_blank" href={signedUrl} rel="noreferrer">
          <FileCardThumbnail>
            <FileCardThumbnailImage src={previewSignedUrl || DocAppActiveIcon} />
          </FileCardThumbnail>
        </a>
        <FileCardContent>
          <FileCardContentHeader>
            <Text>{`${fileName || '-'}`}</Text>
          </FileCardContentHeader>
          <Caption bold css={{ color: '$text-secondary' }}>
            {`${fileSize || '-'} MB`}
          </Caption>
        </FileCardContent>
        {needAction ? (
          <FileCardAction>
            <IconButton
              type="button"
              size={'small'}
              iconName="download"
              isIconFill={false}
              onClick={onDownloadClick}
            />
          </FileCardAction>
        ) : null}
      </FileCardRoot>
    );
  }
);

FileCard.displayName = 'FileCard';

export default FileCard;
