import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { scrollToTop } from '../../../../utils/scroll';
import { PostPLPSStdAppPayload } from '../../../../types/application/constants';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postPLPSStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import Disclosure from '../../../../components/surface/disclosure';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Avatar from '../../../../components/surface/avatar';
import CheckText from '../../../../components/field/check-text';
import LogoPLPS from '../../../../assets/images/logo-plps.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({});

const Confirmation = () => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postPLPSStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [submitDialog, setSubmitDialog] = useState<{ type: string; conditions: string[] }>({
    type: '',
    conditions: [],
  });

  const { handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });

  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      } else {
        postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      }
    },
    onError: (err) => {
      setIsSubmitDialogOpen(false);
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBackClick = () => {
    setCurSubFormStep(3);
  };
  const onSubmitClick: SubmitHandler<PostPLPSStdAppPayload> = () => {
    setSubmitDialog({ type: 'conditionNotMet', conditions: [] });
    setPostStdAppPayload((prev) => ({
      ...prev,
      is_needed_finance_approval: false,
      is_needed_legal_approval: false,
      status: 'processing',
    }));
    setIsSubmitDialogOpen(true);
  };
  const onSubmitError = () => {
    setIsSubmitDialogOpen(false);
  };
  const onSubmitCancel = () => {
    setPostStdAppPayload((prev) => ({ ...prev, is_cm_seal_first: false }));
    setIsSubmitDialogOpen(false);
  };
  const onGenerateClick = () => {
    postAttachmentMutation.mutate({ ...postStdAppAttPayload, appStatus: postStdAppPayload.status });
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? postStdAppPayload.status : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitClick, onSubmitError)}>
      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
        <SubmissionPaper>
          <Disclosure
            accordionHeaderEndElement={
              <ColorfulChip text="PLPS" color="turquoise" variant="solid" />
            }
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>General Information</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoPLPS} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.cm_legal_entity.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={postStdAppPayload.cm_attention_to.name || '-'}
                              picture={postStdAppPayload.cm_picture || '-'}
                              size="sm"
                            />
                            <Text
                              css={{
                                mt: '$1',
                              }}>{`${postStdAppPayload.cm_attention_to.name || '-'}`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.cm_email || '-'}
                          </Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number / CM Extension Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.cm_phone_number
                              ? `${postStdAppPayload.cm_country_calling_code.name || ''} ${
                                  postStdAppPayload.cm_phone_number
                                } ${
                                  postStdAppPayload.cm_extension_number
                                    ? `#${postStdAppPayload.cm_extension_number}`
                                    : ''
                                }`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(postStdAppPayload.quotation_validity).format('DD MMM YYYY') ||
                            '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.currency.name || '-'} / ${
                            postStdAppPayload.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Customer Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Name`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_country?.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Tax Reg Number (統一編號)`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.tax_reg_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_address || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_attention_to || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_email || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number / Extension Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.client_phone_number
                              ? `${postStdAppPayload.client_country_calling_code.name || ''} ${
                                  postStdAppPayload.client_phone_number
                                } ${
                                  postStdAppPayload.client_extension_number
                                    ? `#${postStdAppPayload.client_extension_number}`
                                    : ''
                                }`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Billing Account */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Billing Account`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {`${postStdAppPayload.billing_account.name || '-'}`}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${`${postStdAppPayload.payment_term.name}` || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.support_plan.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postStdAppPayload.official_pricing_discount_type.name || '-'
                          } / ${
                            postStdAppPayload.official_pricing_discount_type.value ===
                            'percentage_discount'
                              ? `${postStdAppPayload.official_pricing_discount_value} %`
                              : postStdAppPayload.official_pricing_discount_type.value ===
                                'fixed_amount_discount'
                              ? `${
                                  postStdAppPayload.official_pricing_discount_value?.toLocaleString() ||
                                  '-'
                                } ${postStdAppPayload.official_pricing_discount_currency.name}`
                              : `0 ${postStdAppPayload.official_pricing_discount_currency.name}`
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Will Customer issue PO, LOA, Order Form, other ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Will Customer issue PO, LOA, Order Form, other ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.is_issued_form?.toUpperCase() || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-seal',
                name: 'Seal',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Seal Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Assignee */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Assignee`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.assignee.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Application Unit */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Application Unit`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.application_unit || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Seal Type */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Seal Type`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.seal_type.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Deliver To */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Deliver To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.deliver_to.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Reason For Application */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason for Application`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.reason_for_application || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button size="md" variant="borderless" color="achromatic" css={{ mr: 'auto' }}>
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      onClick={() => onLeaveClick()}>
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {appId && postStdAppPayload.status === 'rejected' ? (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}>
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  ) : null}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }>
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button size="md" variant="outlined" onClick={() => onBackClick()}>
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Submit */}
          <DialogAlertRoot open={isSubmitDialogOpen}>
            {/* Submit Dialog Trigger */}
            <DialogAlertTrigger asChild>
              <Button type="submit" size="md" css={{ width: 82 }}>
                <ButtonText size={14} bold>
                  Submit
                </ButtonText>
              </Button>
            </DialogAlertTrigger>
            <DialogAlertPortal>
              <DialogAlertOverlay />
              <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: 655 }}>
                <>
                  {/* Submit Dialog Content (Condition Not Met) */}
                  {submitDialog.type === 'conditionNotMet' ? (
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                      {/* Submit Dialog Header (Condition Not Met) */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                        <MSymbol
                          iconName="check_circle"
                          weight={700}
                          css={{ color: '$success70' }}
                        />
                        <DialogAlertTitle asChild>
                          <Heading5
                            css={{ flexGrow: 1 }}>{`Automatically generated Documents`}</Heading5>
                        </DialogAlertTitle>
                      </Box>
                      {/* Submit Dialog Body (Condition Not Met) */}
                      <DialogAlertDescription css={{ pl: 40 }}>
                        <Text css={{ color: '$text-secondary' }}>
                          Once you submit this application, the system will automatically create the
                          Quotation.
                        </Text>
                        <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
                        {/* Execute the "Sealing" process directly */}
                        <CheckText
                          text={`Execute the "Sealing" process directly`}
                          checked={postStdAppPayload.is_cm_seal_first}
                          disabled
                          onChecked={() => {}}
                        />
                      </DialogAlertDescription>
                      {/* Submit Dialog Footer (Condition Not Met) */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        {/* Generate */}
                        <Button
                          size="md"
                          css={{ width: 94.5 }}
                          onClick={onGenerateClick}
                          isLoading={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }
                          disabled={
                            postAttachmentMutation.isLoading ||
                            postAppMutation.isLoading ||
                            patchAppMutation.isLoading
                          }>
                          <ButtonText size={14} bold>
                            Generate
                          </ButtonText>
                        </Button>
                        {/* Cancel */}
                        <DialogAlertCancel asChild>
                          <Button
                            size="md"
                            variant="borderless"
                            color="achromatic"
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            onClick={onSubmitCancel}>
                            <ButtonText size={14} bold>
                              Cancel
                            </ButtonText>
                          </Button>
                        </DialogAlertCancel>
                      </Box>
                    </Box>
                  ) : null}
                </>
              </DialogAlertContent>
            </DialogAlertPortal>
          </DialogAlertRoot>
        </Box>
      </WizardBar>
    </form>
  );
};

export default Confirmation;
