import { Command } from 'cmdk';
import { styled } from '../../styles/stitches.config';

export const CommandRoot = styled(Command, {});
export const CommandInput = styled(Command.Input, {});
export const CommandList = styled(Command.List, {});
export const CommandEmpty = styled(Command.Empty, {});
export const CommandGroup = styled(Command.Group, {});
export const CommandItem = styled(Command.Item, {});
export const CommandSeparator = styled(Command.Separator, {});
