import type {
  GetApplicationAttachmentResult,
  GetApplicationGoogleDocumentResult,
  GetApplicationPayload,
  GetApplicationResult,
  GetApplicationSidebarActivitiesResult,
  GetApplicationSidebarApprovalResult,
  GetApplicationSidebarInfoResult,
  GetApplicationSidebarItemsResult,
  GetApplicationByIdPayload,
  GetApplicationByIdResult,
  PostApplicationPayload,
  PostApplicationSharedPayload,
  PostApplicationSidebarApprovalPayload,
  PostValidateSubmissionFormPayload,
  PostValidateSubmissionFormResult,
  GetApplicationForHomeResult,
  ApplicationEntity,
  GetApplicationGoogleDocSituationByIdResult,
  GetApplicationCommentsResult,
  GetApplicationCommentsPayload,
  ApplicationCommentEntity,
  GetApplicationMentionableUserResult,
  PostApplicationCommentPayload,
} from '../../../types';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useAxios } from '../../../hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  activeStepAtom,
  currentApplicationsTabAtom,
  getApplicationCommentsParamsAtom,
  getApplicationParamsAtom,
  homeApplicationCountAtom,
  isToastOpenAtom,
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormSelectedProductAtom,
  submissionFormSelectedSubsidiaryIdAtom,
  submissionFormValidationAtom,
  toastContentAtom,
} from '../../../store/atoms';
import moment from 'moment';
import { removeCommaForField } from '../../../utils/strict-for-field';
import { getFileObject } from '../../../utils/get-file-object';
import { useResetAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';

// * Step 2: Cloudmile Info; Step 3: Customer Info, Step 4: Confirmation
// ---
// * Validation API 沒有分 Step，傳什麼就全都檢查，
// * 因此 Step 2 進到 Step 3，必須只傳 Step 2 的資料才行
// * 以免使用者曾一度進到 Step 3 填寫了錯誤資料又按 Back 時，再一次按 Next 可能就永遠卡關的情況
// * 而 Step 3 進到 Step 4 時，則必須 Step 2 + 3 的資料都傳才行，因為某些欄位，如 Legal Entity 跟 Currency 資料是連動的
// ---
// * Post Application API 則是傳什麼都全接收。
// * 例如在 GCP 流程傳了 GWS 才需要填的資料給後端，後端一樣會將多餘資料存入 Firestore 之中，並檢查格式，
// * 雖然不會對 APP 的後續使用流程產生任何影響，程式上也不會出錯，但 Firestore 裡會存了許多無用的東西。
// * 因此前端這邊明確區分出 GCP、GWS 要傳的東西，盡量讓 Firestore 不要變肥。
const submissionForm = ({
  validate_type,
  submissionFormSelectedProduct,
  submissionFormCloudmile,
  submissionFormCustomer,
}: {
  validate_type?: 'validate_cloudmile_info' | 'validate_customer_info';
  submissionFormSelectedProduct: string | undefined;
  submissionFormCloudmile: PostApplicationPayload;
  submissionFormCustomer: PostApplicationPayload;
}) => {
  switch (submissionFormSelectedProduct) {
    case 'gcp':
      const gcpCloudmileFields = {
        ...submissionFormCloudmile.gcp,
        product: submissionFormSelectedProduct,
        validity: moment(submissionFormCloudmile.gcp.validity).format('YYYY-MM-DD'),
        commencement_date: moment(submissionFormCloudmile.gcp.commencement_date).format(
          'YYYY-MM-DD'
        ),
        end_date: submissionFormCloudmile.gcp.end_date
          ? moment(submissionFormCloudmile.gcp.end_date).format('YYYY-MM-DD')
          : undefined,
        ...Object.entries(submissionFormCloudmile.gcp).reduce((acc, [key, value]) => {
          if (
            key.startsWith('cm_to_google') ||
            key.startsWith('customer_to_cm') ||
            key.startsWith('b2b_lite_customer_to_cm')
          ) {
            acc[key] = removeCommaForField(value ?? '') || '';
          }
          return acc;
        }, {} as Record<string, string>),
      };
      const gcpCustomerFields = {
        ...submissionFormCustomer.gcp,
      };
      switch (validate_type) {
        case 'validate_cloudmile_info':
          return gcpCloudmileFields;
        default:
          return {
            ...gcpCloudmileFields,
            ...gcpCustomerFields,
            urgent_level: submissionFormCloudmile.gcp.urgent_level ? 1 : 0,
            will_customer_issue_po_loa_orderform_other:
              submissionFormCustomer.gcp.will_customer_issue_po_loa_orderform_other === 'Yes'
                ? true
                : false,
          };
      }
    case 'gws':
      const gwsCloudmileFields = {
        ...submissionFormCloudmile.gws,
        product: submissionFormSelectedProduct,
        validity: moment(submissionFormCloudmile.gws.validity).format('YYYY-MM-DD'),
      };
      const gwsCustomerFields = {
        ...submissionFormCustomer.gws,
        fixed_amount_discount: removeCommaForField(
          submissionFormCustomer.gws.fixed_amount_discount ?? ''
        ),
        special_price_from_google:
          submissionFormCustomer.gws.special_price_from_google === 'Yes' ? true : false,
      };
      switch (validate_type) {
        case 'validate_cloudmile_info':
          return gwsCloudmileFields;
        default:
          return {
            ...gwsCloudmileFields,
            ...gwsCustomerFields,
            urgent_level: submissionFormCloudmile.gws.urgent_level ? 1 : 0,
          };
      }
    default:
      return {};
  }
};

export const usePostValidateSubmissionForm = (
  { subsidiary_id, validate_type, product_type }: PostValidateSubmissionFormPayload,
  onValidationSuccess?: (data: PostValidateSubmissionFormResult) => void
) => {
  const axios = useAxios();
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const submissionFormCloudmile = useAtomValue(submissionFormCloudmileAtom);
  const submissionFormCustomer = useAtomValue(submissionFormCustomerAtom);
  const setSubmissionFormValidation = useSetAtom(submissionFormValidationAtom);
  return useMutation(
    async () => {
      const requestBody = submissionForm({
        validate_type,
        submissionFormSelectedProduct,
        submissionFormCloudmile,
        submissionFormCustomer,
      });
      const { data } = await axios.post(`/v1/validate_submission_form`, requestBody, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          subsidiary_id,
          validate_type,
          product_type: product_type,
        },
      });
      return data;
    },
    {
      onSuccess: (res) => {
        setSubmissionFormValidation(res);
        if (onValidationSuccess) onValidationSuccess(res);
      },
      onError: () => {},
    }
  );
};

const APPLICATION_FOR_HOME_PAGE_LIMIT = 10;
export const useGetApplicationForHomeInfinite = ({
  tab,
  service_item_ids,
  status_ids,
  currency_ids,
  exchange_rate_ids,
  approver_ids,
  applicant_ids,
  review_ids,
  // label_ids,
  view,
  sort_by,
  order_by,
  start_date,
  end_date,
  search,
  is_only_application_count,
}: GetApplicationPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const setHomeApplicationCount = useSetAtom(homeApplicationCountAtom);
  return useInfiniteQuery<GetApplicationForHomeResult, AxiosError>(
    [
      'application-for-home',
      tab,
      service_item_ids,
      status_ids,
      currency_ids,
      exchange_rate_ids,
      approver_ids,
      applicant_ids,
      review_ids,
      // label_ids,
      view,
      sort_by,
      order_by,
      start_date,
      end_date,
      search,
      is_only_application_count,
    ],
    async ({
      pageParam = {
        tab,
        service_item_ids,
        status_ids,
        currency_ids,
        exchange_rate_ids,
        approver_ids,
        applicant_ids,
        review_ids,
        // label_ids,
        view,
        first_id: undefined,
        offset: 0,
        limit: APPLICATION_FOR_HOME_PAGE_LIMIT,
        sort_by,
        order_by,
        start_date,
        end_date,
        search,
        is_only_application_count,
      },
    }) => {
      const { data } = await axios.get(`/v1/application_for_homepage`, {
        params: pageParam,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        setHomeApplicationCount((prev) => ({
          ...prev,
          [`${tab}`]: data.pages.length > 0 ? data.pages[0].application_count : 0,
        }));
      },
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: APPLICATION_FOR_HOME_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
              tab,
              service_item_ids,
              status_ids,
              currency_ids,
              exchange_rate_ids,
              approver_ids,
              applicant_ids,
              review_ids,
              // label_ids,
              view,
              sort_by,
              order_by,
              start_date,
              end_date,
              search,
              is_only_application_count,
            }
          : undefined;
      },
    }
  );
};
export const useGetApplicationForHomeById = (applicationId: string) => {
  const axios = useAxios();
  return useQuery<ApplicationEntity, Error>(
    ['application-for-home-by-id', applicationId],
    async () => {
      const { data } = await axios.get(`/v1/application_for_homepage/${applicationId}`);
      return data;
    }
  );
};

export const useGetApplication = ({
  tab,
  applicant_ids,
  approver_ids,
  service_item_ids,
  status_ids,
  currency_ids,
  exchange_rate_ids,
  payment_term_ids,
  special_deal_ids,
  special_price_from_google_ids,
  review_ids,
  // label_ids,
  offset,
  limit,
  sort_by,
  order_by,
  start_date,
  end_date,
  search,
}: GetApplicationPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationResult, AxiosError>(
    [
      'application',
      tab,
      applicant_ids,
      approver_ids,
      service_item_ids,
      status_ids,
      currency_ids,
      exchange_rate_ids,
      payment_term_ids,
      special_deal_ids,
      special_price_from_google_ids,
      review_ids,
      // label_ids,
      offset,
      limit,
      sort_by,
      order_by,
      start_date,
      end_date,
      search,
    ],
    async () => {
      const { data } = await axios.get(`/v1/application`, {
        params: {
          tab,
          applicant_ids,
          approver_ids,
          service_item_ids,
          status_ids,
          currency_ids,
          exchange_rate_ids,
          payment_term_ids,
          special_deal_ids,
          special_price_from_google_ids,
          review_ids,
          // label_ids,
          offset,
          limit,
          sort_by,
          order_by,
          start_date,
          end_date,
          search,
        },
      });
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const usePostApplication = ({
  onPostApplicationSuccess,
}: {
  onPostApplicationSuccess?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const submissionFormCloudmile = useAtomValue(submissionFormCloudmileAtom);
  const submissionFormCustomer = useAtomValue(submissionFormCustomerAtom);
  const requestBody = submissionForm({
    submissionFormSelectedProduct,
    submissionFormCloudmile,
    submissionFormCustomer,
  });
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  return useMutation(
    async ({
      is_draft = false,
      filing_type = 'submit',
    }: {
      is_draft: PostApplicationSharedPayload['is_draft'];
      filing_type: 'save' | 'submit';
    }) => {
      const { data } = await axios.post(
        `/v1/application`,
        { ...requestBody, is_draft, filing_type },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    {
      onSuccess: (res, variable) => {
        queryClient.invalidateQueries(['applications']);
        if (variable.is_draft) {
          resetGetApplicationParams();
          setCurrentApplicationsTab('drafts');
          setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
        } else {
          resetGetApplicationParams();
          setCurrentApplicationsTab('applied_by_me');
          setGetApplicationParams((prev) => ({ ...prev, tab: 'applied_by_me' }));
        }
        if (onPostApplicationSuccess) onPostApplicationSuccess();
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const usePutApplicationById = ({ onMutationSuccess }: { onMutationSuccess: () => void }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const submissionFormCloudmile = useAtomValue(submissionFormCloudmileAtom);
  const submissionFormCustomer = useAtomValue(submissionFormCustomerAtom);
  const requestBody = submissionForm({
    submissionFormSelectedProduct,
    submissionFormCloudmile,
    submissionFormCustomer,
  });
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  return useMutation(
    async ({
      application_id,
      is_draft,
      filing_type = 'save',
    }: {
      application_id: string;
      is_draft?: PostApplicationSharedPayload['is_draft'];
      filing_type: 'save' | 'submit';
      status?: string;
    }) => {
      const { data } = await axios.put(
        `/v1/application/${application_id}`,
        { ...requestBody, is_draft, filing_type },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    {
      onSuccess: (res, variable) => {
        queryClient.invalidateQueries(['applications']);
        resetGetApplicationParams();
        if (variable.status === 'draft') {
          if (variable.filing_type === 'save') {
            setCurrentApplicationsTab('drafts');
            setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
          }
          if (variable.filing_type === 'submit') {
            setCurrentApplicationsTab('applied_by_me');
            setGetApplicationParams((prev) => ({ ...prev, tab: 'applied_by_me' }));
          }
        }
        if (variable.status === 'rejected') {
          setCurrentApplicationsTab('all');
          setGetApplicationParams((prev) => ({ ...prev, tab: 'all' }));
        }
        if (onMutationSuccess) onMutationSuccess();
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const usePutCancelApplicationById = ({
  onMutationSuccess,
}: {
  onMutationSuccess: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  return useMutation(
    async ({ application_id }: { application_id: string }) => {
      const { data } = await axios.put(
        `/v1/application/${application_id}`,
        { is_draft: true },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['applications']);
        resetGetApplicationParams();
        setCurrentApplicationsTab('drafts');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
        if (onMutationSuccess) onMutationSuccess();
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetApplicationByIdNames = ({ application_id }: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationByIdResult, AxiosError>(
    ['application-by-id', application_id, 'names'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}`, {
        params: { application_data_type: 'name' },
      });
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          // navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const useGetApplicationByIdValues = ({
  application_id,
  onGetApplicationByIdError,
}: GetApplicationByIdPayload & { onGetApplicationByIdError?: () => void }) => {
  const axios = useAxios();
  const setActiveStep = useSetAtom(activeStepAtom);
  const setSubmissionFormSelectedProduct = useSetAtom(submissionFormSelectedProductAtom);
  const setSubmissionFormSelectedSubsidiaryId = useSetAtom(submissionFormSelectedSubsidiaryIdAtom);
  const setSubmissionFormCloudmile = useSetAtom(submissionFormCloudmileAtom);
  const setSubmissionFormCustomer = useSetAtom(submissionFormCustomerAtom);

  return useQuery<GetApplicationByIdResult, Error>(
    ['application-by-id', application_id, 'values'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}`, {
        params: { application_data_type: 'value' },
      });
      return data;
    },
    {
      onSuccess: async (res) => {
        setActiveStep(2);
        const { application_submission: applicationSubmission } = res;
        const googleAddendum = applicationSubmission.cloudmile_info?.google_addendum
          ? await getFileObject(applicationSubmission.cloudmile_info?.google_addendum)
          : null;
        const companyRegistration = applicationSubmission.customer_info?.company_registration
          ? await getFileObject(applicationSubmission.customer_info?.company_registration)
          : null;
        const fsrMail = applicationSubmission.customer_info?.fsr_mail
          ? await getFileObject(applicationSubmission.customer_info?.fsr_mail)
          : null;

        setSubmissionFormSelectedProduct(applicationSubmission.product as 'gcp' | 'gws');
        setSubmissionFormSelectedSubsidiaryId(applicationSubmission.subsidiary_id);
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          [applicationSubmission.product]: {
            ...applicationSubmission.cloudmile_info,
            validity: applicationSubmission.cloudmile_info.validity,
            google_addendum: googleAddendum,
          },
        }));
        setSubmissionFormCustomer((prev) => ({
          ...prev,
          [applicationSubmission.product]: {
            ...applicationSubmission.customer_info,
            company_registration: companyRegistration,
            fsr_mail: fsrMail,
          },
        }));
      },
      onError: () => {
        if (onGetApplicationByIdError) {
          onGetApplicationByIdError();
        }
      },
      enabled: false,
    }
  );
};

export const useDeleteApplicationById = ({
  application_id,
  onMutationSuccess,
}: GetApplicationByIdPayload & { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async () => {
      const { data } = await axios.delete(`/v1/application/${application_id}`);
      return data;
    },
    {
      onSuccess: (res) => {
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const usePostCopyApplicationById = ({
  application_id,
  onMutationSuccess,
}: GetApplicationByIdPayload & { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({ is_keep_labels }: { is_keep_labels: boolean }) => {
      const { data } = await axios.post(`/v1/application/${application_id}/copy`, {
        is_keep_labels: is_keep_labels,
      });
      return data;
    },
    {
      onSuccess: (res) => {
        resetGetApplicationParams();
        setCurrentApplicationsTab('drafts');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetApplicationFilter = () => {
  const axios = useAxios();
  return useQuery<any, Error>(['application-filter'], async () => {
    const { data } = await axios.get(`v1/application_filter`);
    return data;
  });
};

export const useGetApplicationGoogleDocumentById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationGoogleDocumentResult[], AxiosError>(
    ['application-by-id', application_id, 'google-document'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/google_document`);
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const useGetApplicationGoogleDocSituationById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  return useQuery<GetApplicationGoogleDocSituationByIdResult, Error>(
    ['application-google-doc-situation-by-id', application_id],
    async () => {
      const { data } = await axios.get(`/v1/application_doc_situation/${application_id}`);
      return data;
    }
  );
};

export const usePostApplicationGoogleDocumentById = ({
  application_id,
  onMutationSuccess,
}: GetApplicationByIdPayload & { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const { data } = await axios.post(`/v1/application/${application_id}/google_document`);
      return data;
    },
    {
      onSuccess: (res) => {
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        queryClient.invalidateQueries(['application-by-id', application_id]);
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        queryClient.invalidateQueries(['application-by-id', application_id]);
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetApplicationSidebarItemsById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationSidebarItemsResult, AxiosError>(
    ['application-by-id', application_id, 'sidebar-items'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/sidebar_item`);
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const useGetApplicationSidebarInfoById = ({ application_id }: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationSidebarInfoResult, AxiosError>(
    ['application-by-id', application_id, 'sidebar-info'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/sidebar_info`);
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

const ACTIVITIES_PAGE_LIMIT = 10;
export const useGetApplicationActivitiesInfiniteById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useInfiniteQuery<GetApplicationSidebarActivitiesResult, AxiosError>(
    ['application-by-id', application_id, 'sidebar-activities'],
    async ({ pageParam = { offset: 0, limit: ACTIVITIES_PAGE_LIMIT } }) => {
      const { data } = await axios.get(`/v1/application/${application_id}/activity`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: ACTIVITIES_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
            }
          : undefined,
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

// * 相依於 Get Application by id
export const useGetApplicationSidebarApprovalById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useQuery<GetApplicationSidebarApprovalResult, AxiosError>(
    ['application-by-id', application_id, 'sidebar-approval'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/sidebar_approval`);
      return data;
    },
    {
      enabled: Boolean(
        queryClient.getQueryData<GetApplicationByIdResult>([
          'application-by-id',
          application_id,
          'names',
        ])
      ),
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const usePostApplicationSidebarApprovalById = ({
  application_id,
  onMutationSuccess,
}: GetApplicationByIdPayload & { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({
      feedback_content,
      is_gft,
      approval_result_code,
    }: PostApplicationSidebarApprovalPayload) => {
      try {
        const { data } = await axios.post(`/v1/application/${application_id}/sidebar_approval`, {
          feedback_content,
          is_gft,
          approval_result_code,
        });
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (res) => {
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        queryClient.invalidateQueries({ queryKey: ['application-by-id', application_id] });
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetApplicationAttachmentById = ({ application_id }: GetApplicationByIdPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetApplicationAttachmentResult[], AxiosError>(
    ['application-by-id', application_id, 'attachment'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/attachment`);
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

const COMMENT_PAGE_LIMIT = 10;
export const useGetApplicationCommentInfinite = ({
  application_id,
  offset,
  limit,
  filter_by,
}: GetApplicationByIdPayload & GetApplicationCommentsPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useInfiniteQuery<GetApplicationCommentsResult, AxiosError>(
    ['application-by-id', application_id, 'comments', offset, limit, filter_by],
    async ({ pageParam = { offset: 0, limit: COMMENT_PAGE_LIMIT } }) => {
      const { data } = await axios.get(`/v1/application/${application_id}/comment`, {
        params: { ...pageParam, filter_by },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: COMMENT_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
            }
          : undefined,
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const usePostApplicationComment = ({
  application_id,
  onSuccess,
}: {
  application_id: string;
  onSuccess?: () => void;
}) => {
  const axios = useAxios();
  // const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async (requestBody: PostApplicationCommentPayload) => {
      const { data } = await axios.post(
        `/v1/application/${application_id}/comment`,
        { ...requestBody },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    {
      onSuccess: (res, variable) => {
        if (onSuccess) {
          onSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetApplicationMentionableUserById = ({
  application_id,
}: GetApplicationByIdPayload) => {
  const axios = useAxios();
  return useQuery<GetApplicationMentionableUserResult[], Error>(
    ['application-by-id', application_id, 'mentionable-user'],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/mentionable_user`);
      return data;
    }
  );
};

export const useGetApplicationCommentById = ({
  application_id,
  comment_id,
}: GetApplicationByIdPayload & { comment_id: string }) => {
  const axios = useAxios();
  return useQuery<ApplicationCommentEntity, Error>(
    ['application-by-id', application_id, 'comment-by-id', comment_id],
    async () => {
      const { data } = await axios.get(`/v1/application/${application_id}/comment/${comment_id}`);
      return data;
    }
  );
};

export const usePutApplicationCommentById = ({
  application_id,
  comment_id,
  onSuccess,
}: GetApplicationByIdPayload & { comment_id: string; onSuccess?: () => void }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async (requestBody: PostApplicationCommentPayload) => {
      const { data } = await axios.put(
        `/v1/application/${application_id}/comment/${comment_id}`,
        { ...requestBody },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    {
      onSuccess: (res, variable) => {
        queryClient.invalidateQueries([
          'application-by-id',
          application_id,
          'comment-by-id',
          comment_id,
        ]);
        if (onSuccess) onSuccess();
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useDeleteApplicationCommentById = ({
  application_id,
  comment_id,
  onSuccess,
}: GetApplicationByIdPayload & { comment_id: string; onSuccess?: () => void }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async () => {
      const { data } = await axios.delete(
        `/v1/application/${application_id}/comment/${comment_id}`
      );
      return data;
    },
    {
      onSuccess: (res) => {
        if (onSuccess) {
          onSuccess();
        }
        queryClient.invalidateQueries(['application-by-id', application_id, 'comments']);
        queryClient.invalidateQueries(['app', 'verified', application_id, 'comments']);
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};
