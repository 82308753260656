import { DateISO } from '../../types';
import moment from 'moment';
import { styled } from '../../styles/stitches.config';
import { getApproverResultVariant } from '../../utils/get-variant';
import MentionedTypography from '../typography/mentioned-typography';
import { Heading6 } from '../typography/heading';
import { Text, Caption } from '../typography/text';
import Spacer from './spacer';
import Box from '../box';
import Avatar from './avatar';

const ApproverFeedbackerRoot = styled('div', {
  position: 'relative',
  px: 10,
  pb: 14,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 13,
    left: 0,
    width: 3,
    borderTopLeftRadius: '$5',
    borderBottomLeftRadius: '$5',
    borderTopRightRadius: '$5',
    borderBottomRightRadius: '$5',
  },
});

interface ApproverFeedbackerProps {
  approverResultCode: number;
  feedbackContent?: string;
  approver?: { id?: string; name?: string; picture?: string } | undefined;
  approvedTime?: DateISO;
}

export const ApproverFeedbackerBody = ({
  approver,
  approvedTime,
  feedbackContent,
}: Pick<ApproverFeedbackerProps, 'approver' | 'approvedTime' | 'feedbackContent'>) => {
  return (
    <>
      <Box>
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
          <Avatar
            size="sm"
            name={`${approver?.name || '-'}`}
            picture={`${approver?.picture || '-'}`}
          />
          <Text css={{ color: '$primary50' }} bold>{`${approver?.name || '-'}`}</Text>
        </Box>
        <Caption css={{ color: '$secondary70' }}>{`${moment(approvedTime).format(
          'MM/DD/YY HH:mm A (Z)'
        )}`}</Caption>
      </Box>
      <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
      <MentionedTypography ellipsisLine="revert" message={feedbackContent || '-'} />
    </>
  );
};

const ApproverFeedbacker = ({
  feedbackContent,
  approverResultCode,
  approver,
  approvedTime,
}: ApproverFeedbackerProps) => {
  return (
    <ApproverFeedbackerRoot
      css={{
        '&:before': {
          backgroundColor: getApproverResultVariant(approverResultCode).color,
        },
      }}>
      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
        <Box
          as="img"
          css={{ width: 20, height: 20 }}
          src={getApproverResultVariant(approverResultCode).icon}
        />
        <Heading6
          css={{
            color: getApproverResultVariant(approverResultCode).color,
          }}>
          {getApproverResultVariant(approverResultCode).title}
        </Heading6>
      </Box>
      <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
      <ApproverFeedbackerBody
        approver={approver}
        approvedTime={approvedTime}
        feedbackContent={feedbackContent}
      />
    </ApproverFeedbackerRoot>
  );
};

export default ApproverFeedbacker;
