import { useState } from 'react';
import { Mention } from 'react-mentions';
import {
  useGetAppDetailApprovalPanel,
  usePostAppDetailApprovalPanel,
} from '../../../../services/application/verified-standard/app-detail-panels.api';
import { useGetMentionableUserOptions } from '../../../../services/application/verified-standard/dropdown.api';
import { getApprovalConditionVariant } from '../../../../utils/get-variant';
import { CenteredBoxAbsolute, CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Box from '../../../../components/box';
import Spacer from '../../../../components/surface/spacer';
import Divider from '../../../../components/surface/divider';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import { Heading5 } from '../../../../components/typography/heading';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import MentionArea from '../../../../components/dropdown/mention-area';
import MSymbol from '../../../../components/icon/m-symbol';
import Button from '../../../../components/button/button';
import ApproverResulter from '../../../../components/surface/approver-resulter';
import { ApproverFeedbackerBody } from '../../../../components/surface/approver-feedbacker';
import NoApprovalBG from '../../../../assets/images/bg-no-approval.svg';
import PopupAttentionIcon from '../../../../assets/images/icon-popup-attention.svg';

export interface AppDetailApprovalPanelProps {
  appId: string;
}

const ApprovalPanel = ({ appId }: AppDetailApprovalPanelProps) => {
  const appDetailApprovalPanelQuery = useGetAppDetailApprovalPanel({ appId });
  const mentionableUserQuery = useGetMentionableUserOptions({ appId });
  const [feedbackInput, setFeedbackInput] = useState('');
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isUrgentDialogOpen, setIsUrgentDialogOpen] = useState(false);
  const [isGeneralDialogOpen, setIsGeneralDialogOpen] = useState(false);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const onFeedbackInputChange = (newValue: string) => {
    setFeedbackInput(newValue);
  };
  const appDetailApprovalPanelMutation = usePostAppDetailApprovalPanel({
    appId,
    onSettled: () => {
      setFeedbackInput('');
      setIsApproveDialogOpen(false);
      setIsRejectDialogOpen(false);
    },
  });
  const onRejectClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      approval_result_code: -1,
    });
  };
  const onApproveClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      approval_result_code: 3,
    });
  };
  const onUrgentClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      approval_result_code: 3.1,
    });
  };
  const onGeneralClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      approval_result_code: 3.2,
    });
  };
  const onCompleteClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      approval_result_code: 3.3,
    });
  };
  return (
    <>
      {appDetailApprovalPanelQuery.isLoading ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Spinner size="large" />
        </CenteredBoxFlex>
      ) : appDetailApprovalPanelQuery.isSuccess ? (
        appDetailApprovalPanelQuery.data.approver_status_code ? (
          <>
            <CenteredBoxFlex css={{ height: 64, flexShrink: 0 }}>
              <Heading5
                css={{
                  width: 290,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>{`Approval`}</Heading5>
            </CenteredBoxFlex>
            <Divider
              axis={'horizontal'}
              css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
            />
            <ScrollAreaRoot css={{ flexGrow: 1, p: 20 }}>
              <ScrollAreaViewport>
                {(() => {
                  switch (true) {
                    case appDetailApprovalPanelQuery.data.approver_status_code === 1:
                      return (
                        <CenteredBoxAbsolute>
                          <CenteredBoxFlex css={{ width: 280 }}>
                            <Box as={'img'} src={NoApprovalBG} />
                            <Text as="p" css={{ color: '$secondary70' }}>
                              {`This approval is still waiting for `}
                              <Text
                                css={{
                                  color: '$primary50',
                                  display: 'inline-block',
                                }}>{`${appDetailApprovalPanelQuery.data.waiting_for || '-'}`}</Text>
                            </Text>
                          </CenteredBoxFlex>
                        </CenteredBoxAbsolute>
                      );
                    case appDetailApprovalPanelQuery.data.approver_status_code === 2:
                      return (
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 50,
                            width: '100%',
                          }}>
                          <Box
                            as={'img'}
                            css={{ width: 20, height: 20, mt: 4 }}
                            src={PopupAttentionIcon}
                          />
                          <Text
                            css={{ width: 254 }}
                            bold>{`This application is pending your approval`}</Text>
                        </Box>
                      );
                    default:
                      return (
                        <ApproverResulter
                          approverResultCode={appDetailApprovalPanelQuery.data.approver_status_code}
                        />
                      );
                  }
                })()}
                <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                {/* Conditions Container */}
                {!appDetailApprovalPanelQuery.data.approver_status_code ||
                appDetailApprovalPanelQuery.data.approver_status_code === 1 ? null : (
                  <Box
                    css={{
                      borderRadius: 6,
                      backgroundColor:
                        appDetailApprovalPanelQuery.data.approval_type === 'urgent_approval' &&
                        appDetailApprovalPanelQuery.data.approver_status_code === 2
                          ? '$error10'
                          : '$func-hover',
                      p: 10,
                      maxWidth: 279,
                    }}>
                    {/* Conditions */}
                    <>
                      {appDetailApprovalPanelQuery.data.approval_type === 'urgent_approval' &&
                      appDetailApprovalPanelQuery.data.approver_status_code === 2 ? (
                        <>
                          <ButtonText size={'14'} bold css={{ display: 'block' }}>
                            Urgent
                          </ButtonText>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: 8,
                              mt: 10,
                              mb: 20,
                            }}>
                            <MSymbol
                              size={20}
                              iconName={getApprovalConditionVariant('urgent').iconName}
                              css={{
                                color: getApprovalConditionVariant('urgent').color,
                              }}>
                              urgent
                            </MSymbol>
                            <Text>
                              This application was submitted by Sales for urgent scenario.
                            </Text>
                          </Box>
                        </>
                      ) : null}
                      <ButtonText size={'14'} bold css={{ display: 'block' }}>
                        Conditions
                      </ButtonText>
                      {appDetailApprovalPanelQuery.data.conditions?.length > 0
                        ? appDetailApprovalPanelQuery.data.conditions.map((condition) => (
                            <Box
                              key={condition.id}
                              css={{ display: 'flex', alignItems: 'center', columnGap: 8, mt: 10 }}>
                              <MSymbol
                                size={20}
                                iconName={
                                  getApprovalConditionVariant(condition.condition_type).iconName
                                }
                                css={{
                                  color: getApprovalConditionVariant(condition.condition_type)
                                    .color,
                                }}>
                                {condition.condition_type}
                              </MSymbol>
                              <Text>{`${condition.condition_message || '-'}`}</Text>
                            </Box>
                          ))
                        : '-'}
                    </>
                    <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                    {/* Reasons For Application */}
                    <>
                      <ButtonText size={'14'} bold css={{ display: 'block' }}>
                        Reasons For Application
                      </ButtonText>
                      <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                      <Text as="p">{`${
                        appDetailApprovalPanelQuery.data.reason_for_application || 'No reasons'
                      }`}</Text>
                    </>
                    {appDetailApprovalPanelQuery.data.approver_status_code === 2 ? (
                      <>
                        <Divider
                          axis={'horizontal'}
                          css={{ $$size: '100%', backgroundColor: '$func-border-main', my: 20 }}
                        />
                        {/* Feedback Input */}
                        <>
                          <MentionArea
                            placeholder="You can reply to reasons, feedback, or add others with @ (optional)"
                            value={feedbackInput}
                            onChange={(e, newValue) => onFeedbackInputChange(newValue)}>
                            <Mention
                              trigger="@"
                              appendSpaceOnAdd={true}
                              displayTransform={(id, display) => {
                                return `@${display}`;
                              }}
                              data={mentionableUserQuery.data || []}
                            />
                          </MentionArea>
                          <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                          {/* Approval Buttons */}
                          {appDetailApprovalPanelQuery.data.approval_type === 'urgent_approval' ? (
                            <CenteredBoxFlex css={{ flexDirection: 'row', columnGap: 10 }}>
                              <Box css={{ flex: 1 }}>
                                {/* General (for Local GM 3.2) */}
                                <DialogAlertRoot
                                  open={isGeneralDialogOpen}
                                  onOpenChange={(open) => setIsGeneralDialogOpen(open)}>
                                  {/* General Trigger */}
                                  <DialogAlertTrigger asChild>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="md"
                                      css={{ px: 0 }}>
                                      <ButtonText size={'14'} bold>
                                        General approval
                                      </ButtonText>
                                    </Button>
                                  </DialogAlertTrigger>
                                  <DialogAlertPortal>
                                    <DialogAlertOverlay />
                                    <DialogAlertContent
                                      css={{
                                        px: 30,
                                        py: 20,
                                        borderRadius: '$10',
                                        maxWidth: '655px',
                                      }}>
                                      <Box
                                        css={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: 30,
                                        }}>
                                        {/* Approve Dialog Title, Dialog Close Button */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            columnGap: 15,
                                          }}>
                                          <MSymbol
                                            iconName="error"
                                            weight={700}
                                            size={30}
                                            css={{ color: '$error60' }}
                                          />
                                          <DialogAlertTitle asChild>
                                            <Heading5
                                              css={{
                                                flexGrow: 1,
                                              }}>{`General approval`}</Heading5>
                                          </DialogAlertTitle>
                                        </Box>
                                        {/* Dialog Description */}
                                        <DialogAlertDescription css={{ px: 40 }}>
                                          <Text>
                                            {`We will instantly change the application to the`}{' '}
                                            <Text
                                              css={{
                                                display: 'inline-block',
                                                color: '$primary50',
                                              }}>
                                              general
                                            </Text>{' '}
                                            {`review process and continue the approval to the next approver.`}
                                          </Text>
                                        </DialogAlertDescription>
                                        {/* Dialog Action */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            columnGap: 20,
                                          }}>
                                          <Button
                                            size="md"
                                            color="primary"
                                            spinnerColor="primary"
                                            isLoading={appDetailApprovalPanelMutation.isLoading}
                                            disabled={appDetailApprovalPanelMutation.isLoading}
                                            css={{ width: 137 }}
                                            onClick={onGeneralClick}>
                                            <ButtonText size={'14'} bold>
                                              Make it general
                                            </ButtonText>
                                          </Button>
                                          <DialogAlertCancel asChild>
                                            <Button
                                              size="md"
                                              variant="borderless"
                                              color="achromatic"
                                              disabled={appDetailApprovalPanelMutation.isLoading}>
                                              <ButtonText size={'14'} bold>
                                                Cancel
                                              </ButtonText>
                                            </Button>
                                          </DialogAlertCancel>
                                        </Box>
                                      </Box>
                                    </DialogAlertContent>
                                  </DialogAlertPortal>
                                </DialogAlertRoot>
                              </Box>
                              <Box css={{ flex: 1 }}>
                                {/* Urgent (for Local GM 3.1) */}
                                <DialogAlertRoot
                                  open={isUrgentDialogOpen}
                                  onOpenChange={(open) => setIsUrgentDialogOpen(open)}>
                                  {/* Urgent Trigger */}
                                  <DialogAlertTrigger asChild>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="md"
                                      css={{ px: 0 }}>
                                      <ButtonText size={'14'} bold>
                                        Urgent approval
                                      </ButtonText>
                                    </Button>
                                  </DialogAlertTrigger>
                                  <DialogAlertPortal>
                                    <DialogAlertOverlay />
                                    <DialogAlertContent
                                      css={{
                                        px: 30,
                                        py: 20,
                                        borderRadius: '$10',
                                        maxWidth: '655px',
                                      }}>
                                      <Box
                                        css={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: 30,
                                        }}>
                                        {/* Approve Dialog Title, Dialog Close Button */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            columnGap: 15,
                                          }}>
                                          <MSymbol
                                            iconName="error"
                                            weight={700}
                                            size={30}
                                            css={{ color: '$error60' }}
                                          />
                                          <DialogAlertTitle asChild>
                                            <Heading5
                                              css={{
                                                flexGrow: 1,
                                              }}>{`Urgent approval`}</Heading5>
                                          </DialogAlertTitle>
                                        </Box>
                                        {/* Dialog Description */}
                                        <DialogAlertDescription css={{ px: 40 }}>
                                          <Text>
                                            {`We will instantly change the application to the`}{' '}
                                            <Text
                                              css={{ display: 'inline-block', color: '$error60' }}>
                                              urgent
                                            </Text>{' '}
                                            {`review process and continue the approval to the next approver.`}
                                          </Text>
                                        </DialogAlertDescription>
                                        {/* Dialog Action */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            columnGap: 20,
                                          }}>
                                          <Button
                                            size="md"
                                            color="error"
                                            spinnerColor="error"
                                            isLoading={appDetailApprovalPanelMutation.isLoading}
                                            disabled={appDetailApprovalPanelMutation.isLoading}
                                            css={{ width: 131 }}
                                            onClick={onUrgentClick}>
                                            <ButtonText size={'14'} bold>
                                              Make it urgent
                                            </ButtonText>
                                          </Button>
                                          <DialogAlertCancel asChild>
                                            <Button
                                              size="md"
                                              variant="borderless"
                                              color="achromatic"
                                              disabled={appDetailApprovalPanelMutation.isLoading}>
                                              <ButtonText size={'14'} bold>
                                                Cancel
                                              </ButtonText>
                                            </Button>
                                          </DialogAlertCancel>
                                        </Box>
                                      </Box>
                                    </DialogAlertContent>
                                  </DialogAlertPortal>
                                </DialogAlertRoot>
                              </Box>
                            </CenteredBoxFlex>
                          ) : (
                            <CenteredBoxFlex css={{ flexDirection: 'row', columnGap: 10 }}>
                              <Box css={{ flex: 1 }}>
                                <DialogAlertRoot
                                  open={isRejectDialogOpen}
                                  onOpenChange={(open) => setIsRejectDialogOpen(open)}>
                                  {/* Reject */}
                                  <DialogAlertTrigger asChild>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="md"
                                      startElement={
                                        <MSymbol
                                          size={20}
                                          iconName="close"
                                          css={{ color: 'inherit' }}
                                        />
                                      }>
                                      <ButtonText size={'14'} bold>
                                        Reject
                                      </ButtonText>
                                    </Button>
                                  </DialogAlertTrigger>
                                  <DialogAlertPortal>
                                    <DialogAlertOverlay />
                                    <DialogAlertContent
                                      css={{
                                        px: 30,
                                        py: 20,
                                        borderRadius: '$10',
                                        maxWidth: '655px',
                                      }}>
                                      <Box
                                        css={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          rowGap: 30,
                                        }}>
                                        {/* Reject Dialog Title, Dialog Close Button */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            columnGap: 15,
                                          }}>
                                          <MSymbol
                                            iconName="error"
                                            weight={700}
                                            size={30}
                                            css={{ color: '$error60' }}
                                          />
                                          <DialogAlertTitle asChild>
                                            <Heading5
                                              css={{
                                                flexGrow: 1,
                                              }}>{`Reject the application`}</Heading5>
                                          </DialogAlertTitle>
                                        </Box>
                                        {/* Dialog Description */}
                                        <DialogAlertDescription css={{ px: 40 }}>
                                          <Text>
                                            {`Once the application is`}{' '}
                                            <Text
                                              css={{ display: 'inline-block', color: '$error60' }}>
                                              rejected
                                            </Text>{' '}
                                            {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                          </Text>
                                        </DialogAlertDescription>
                                        {/* Dialog Action */}
                                        <Box
                                          css={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            columnGap: 20,
                                          }}>
                                          <Button
                                            size="md"
                                            color="error"
                                            spinnerColor="error"
                                            isLoading={appDetailApprovalPanelMutation.isLoading}
                                            disabled={appDetailApprovalPanelMutation.isLoading}
                                            css={{ width: 75 }}
                                            onClick={onRejectClick}>
                                            <ButtonText size={'14'} bold>
                                              Reject
                                            </ButtonText>
                                          </Button>
                                          <DialogAlertCancel asChild>
                                            <Button
                                              size="md"
                                              variant="borderless"
                                              color="achromatic"
                                              disabled={appDetailApprovalPanelMutation.isLoading}>
                                              <ButtonText size={'14'} bold>
                                                Cancel
                                              </ButtonText>
                                            </Button>
                                          </DialogAlertCancel>
                                        </Box>
                                      </Box>
                                    </DialogAlertContent>
                                  </DialogAlertPortal>
                                </DialogAlertRoot>
                              </Box>
                              <Box css={{ flex: 1 }}>
                                {appDetailApprovalPanelQuery.data.approval_type ===
                                'seal_approval' ? (
                                  <>
                                    {/* Complete (for Admin 3.3) */}
                                    <DialogAlertRoot
                                      open={isCompleteDialogOpen}
                                      onOpenChange={(open) => setIsCompleteDialogOpen(open)}>
                                      {/* Complete Trigger */}
                                      <DialogAlertTrigger asChild>
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="md"
                                          startElement={
                                            <MSymbol
                                              size={20}
                                              iconName="check"
                                              css={{ color: 'inherit' }}
                                            />
                                          }>
                                          <ButtonText size={'14'} bold>
                                            Complete
                                          </ButtonText>
                                        </Button>
                                      </DialogAlertTrigger>
                                      <DialogAlertPortal>
                                        <DialogAlertOverlay />
                                        <DialogAlertContent
                                          css={{
                                            px: 30,
                                            py: 20,
                                            borderRadius: '$10',
                                            maxWidth: '655px',
                                          }}>
                                          <Box
                                            css={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              rowGap: 30,
                                            }}>
                                            {/* Approve Dialog Title, Dialog Close Button */}
                                            <Box
                                              css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: 15,
                                              }}>
                                              <MSymbol
                                                iconName="error"
                                                weight={700}
                                                size={30}
                                                css={{ color: '$error60' }}
                                              />
                                              <DialogAlertTitle asChild>
                                                <Heading5
                                                  css={{
                                                    flexGrow: 1,
                                                  }}>{`Complete the application`}</Heading5>
                                              </DialogAlertTitle>
                                            </Box>
                                            {/* Dialog Description */}
                                            <DialogAlertDescription css={{ px: 40 }}>
                                              <Text>
                                                {`Once the application is`}{' '}
                                                <Text
                                                  css={{
                                                    display: 'inline-block',
                                                    color: '$success70',
                                                  }}>
                                                  complete
                                                </Text>{' '}
                                                {`, it cannot be revised again. Are you sure you want to proceed with the complete?`}
                                              </Text>
                                            </DialogAlertDescription>
                                            {/* Dialog Action */}
                                            <Box
                                              css={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                columnGap: 20,
                                              }}>
                                              <Button
                                                size="md"
                                                color="success"
                                                spinnerColor="success"
                                                isLoading={appDetailApprovalPanelMutation.isLoading}
                                                disabled={appDetailApprovalPanelMutation.isLoading}
                                                css={{ width: 90 }}
                                                onClick={onCompleteClick}>
                                                <ButtonText size={'14'} bold>
                                                  Complete
                                                </ButtonText>
                                              </Button>
                                              <DialogAlertCancel asChild>
                                                <Button
                                                  size="md"
                                                  variant="borderless"
                                                  color="achromatic"
                                                  disabled={
                                                    appDetailApprovalPanelMutation.isLoading
                                                  }>
                                                  <ButtonText size={'14'} bold>
                                                    Cancel
                                                  </ButtonText>
                                                </Button>
                                              </DialogAlertCancel>
                                            </Box>
                                          </Box>
                                        </DialogAlertContent>
                                      </DialogAlertPortal>
                                    </DialogAlertRoot>
                                  </>
                                ) : (
                                  <>
                                    {/* Approve (3.0) */}
                                    <DialogAlertRoot
                                      open={isApproveDialogOpen}
                                      onOpenChange={(open) => setIsApproveDialogOpen(open)}>
                                      {/* Approve Trigger */}
                                      <DialogAlertTrigger asChild>
                                        <Button
                                          variant="outlined"
                                          color="success"
                                          size="md"
                                          startElement={
                                            <MSymbol
                                              size={20}
                                              iconName="check"
                                              css={{ color: 'inherit' }}
                                            />
                                          }>
                                          <ButtonText size={'14'} bold>
                                            Approve
                                          </ButtonText>
                                        </Button>
                                      </DialogAlertTrigger>
                                      <DialogAlertPortal>
                                        <DialogAlertOverlay />
                                        <DialogAlertContent
                                          css={{
                                            px: 30,
                                            py: 20,
                                            borderRadius: '$10',
                                            maxWidth: '655px',
                                          }}>
                                          <Box
                                            css={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              rowGap: 30,
                                            }}>
                                            {/* Approve Dialog Title, Dialog Close Button */}
                                            <Box
                                              css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: 15,
                                              }}>
                                              <MSymbol
                                                iconName="error"
                                                weight={700}
                                                size={30}
                                                css={{ color: '$error60' }}
                                              />
                                              <DialogAlertTitle asChild>
                                                <Heading5
                                                  css={{
                                                    flexGrow: 1,
                                                  }}>{`Approve the application`}</Heading5>
                                              </DialogAlertTitle>
                                            </Box>
                                            {/* Dialog Description */}
                                            <DialogAlertDescription css={{ px: 40 }}>
                                              <Text>
                                                {`Once the application is`}{' '}
                                                <Text
                                                  css={{
                                                    display: 'inline-block',
                                                    color: '$success70',
                                                  }}>
                                                  approved
                                                </Text>{' '}
                                                {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                              </Text>
                                            </DialogAlertDescription>
                                            {/* Dialog Action */}
                                            <Box
                                              css={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                columnGap: 20,
                                              }}>
                                              <Button
                                                size="md"
                                                color="success"
                                                spinnerColor="success"
                                                isLoading={appDetailApprovalPanelMutation.isLoading}
                                                disabled={appDetailApprovalPanelMutation.isLoading}
                                                css={{ width: 90 }}
                                                onClick={onApproveClick}>
                                                <ButtonText size={'14'} bold>
                                                  Approve
                                                </ButtonText>
                                              </Button>
                                              <DialogAlertCancel asChild>
                                                <Button
                                                  size="md"
                                                  variant="borderless"
                                                  color="achromatic"
                                                  disabled={
                                                    appDetailApprovalPanelMutation.isLoading
                                                  }>
                                                  <ButtonText size={'14'} bold>
                                                    Cancel
                                                  </ButtonText>
                                                </Button>
                                              </DialogAlertCancel>
                                            </Box>
                                          </Box>
                                        </DialogAlertContent>
                                      </DialogAlertPortal>
                                    </DialogAlertRoot>
                                  </>
                                )}
                              </Box>
                            </CenteredBoxFlex>
                          )}
                        </>
                      </>
                    ) : null}
                    {/* Approval Feedback or Reasons */}
                    <>
                      {appDetailApprovalPanelQuery.data.feedback ? (
                        <>
                          <Divider
                            axis={'horizontal'}
                            css={{ $$size: '100%', backgroundColor: '$func-border-main', my: 20 }}
                          />
                          <ButtonText size={'14'} bold css={{ display: 'block' }}>
                            Approval Feedback or Reasons
                          </ButtonText>
                          <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                          <ApproverFeedbackerBody
                            approver={appDetailApprovalPanelQuery.data.feedback.approver}
                            approvedTime={appDetailApprovalPanelQuery.data.feedback.approved_at}
                            feedbackContent={
                              appDetailApprovalPanelQuery.data.feedback.feedback_content
                            }
                          />
                        </>
                      ) : null}
                    </>
                  </Box>
                )}
                <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaViewport>
            </ScrollAreaRoot>
          </>
        ) : (
          <CenteredBoxFlex css={{ height: '100%' }}>
            <Box as="img" src={NoApprovalBG} />
            <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
            <Text
              as="p"
              css={{
                textAlign: 'center',
                width: 280,
                color: '$achromatic70',
              }}>
              You do not have the permission to approve this application.
            </Text>
          </CenteredBoxFlex>
        )
      ) : appDetailApprovalPanelQuery.isError ? (
        <CenteredBoxFlex css={{ height: '100%', rowGap: 20 }}>
          <Box as="img" src={NoApprovalBG} />
          <Box>
            <Text
              semibold
              css={{
                textAlign: 'center',
              }}>{`${'Something went wrong'}`}</Text>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Caption semibold css={{ width: 280, textAlign: 'center' }}>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Caption>
          </Box>
          <Button
            variant="solid"
            size={'sm'}
            isLoading={appDetailApprovalPanelQuery.isLoading}
            onClick={() => {
              appDetailApprovalPanelQuery.refetch();
            }}>
            <ButtonText size={12} bold>
              Refresh
            </ButtonText>
          </Button>
        </CenteredBoxFlex>
      ) : null}
    </>
  );
};

export default ApprovalPanel;
