import { motion } from 'framer-motion';
import { theme, styled } from '../../styles/stitches.config';
import Box from '../box';
import MSymbol from '../icon/m-symbol';
import { Text } from '../typography/text';

interface StepperProps {
  activeStep: number;
  steps: { id: string; name: string }[];
}

const stepperNameMotion = (activeColor: string, inactiveColor: string) => ({
  active: {
    color: activeColor,
  },
  inactive: {
    color: inactiveColor,
  },
});
const stepperCheckMarkMotion = (activeColor: string, inactiveColor: string) => ({
  active: {
    backgroundColor: activeColor,
    borderColor: activeColor,
  },
  inactive: {
    backgroundColor: 'hsl(0, 0%, 100%)',
    borderColor: inactiveColor,
  },
});

const StepItem = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: 'clamp(5px, 2vw, 10px)',
  '&:not(:last-child)': {
    flex: 1,
    '&:after': {
      content: '',
      flex: 1,
      height: '2px',
      borderRadius: '4px',
      position: 'relative',
      marginRight: '10px',
      transition: 'background-color 0.15s linear',
    },
  },
  variants: {
    light: {
      on: { '&:not(:last-child)': { '&:after': { backgroundColor: '$primary50' } } },
      off: { '&:not(:last-child)': { '&:after': { backgroundColor: '$secondary30' } } },
    },
  },
});

const Stepper = ({ activeStep, steps }: StepperProps) => {
  return (
    <Box
      as="ol"
      css={{
        display: 'flex',
        maxWidth: '780px',
        margin: '0 auto',
        paddingInlineStart: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        listStyleType: 'none',
      }}>
      {steps.length > 0
        ? steps.map((step, index) => (
            <StepItem
              data-gtm-id={index}
              as="li"
              key={`${step.id}`}
              light={activeStep >= index + 1 ? 'on' : 'off'}>
              <Box
                as={motion.div}
                initial={'inactive'}
                animate={activeStep >= index + 1 ? 'active' : 'inactive'}
                variants={stepperCheckMarkMotion(
                  `${theme.colors.primary50}`,
                  `${theme.colors.secondary30}`
                )}
                css={{
                  width: '20px',
                  height: '20px',
                  color: '$white',
                  borderRadius: '50%',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <MSymbol
                  iconName="check"
                  weight={700}
                  css={{ color: 'inherit', fontSize: '14px !important' }}
                />
              </Box>
              <Box
                as={motion.div}
                initial={'inactive'}
                animate={activeStep >= index + 1 ? 'active' : 'inactive'}
                variants={stepperNameMotion(
                  `${theme.colors.primary50}`,
                  `${theme.colors.secondary30}`
                )}>
                <Text
                  bold={activeStep >= index + 1}
                  css={{ color: 'inherit', whiteSpace: 'nowrap' }}>
                  {step.name}
                </Text>
              </Box>
            </StepItem>
          ))
        : null}
    </Box>
  );
};

export default Stepper;
