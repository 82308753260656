import { ComponentProps, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import { Text } from '../typography/text';
import Checkbox from '../field/checkbox';

const CheckButtonRoot = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '1px solid $func-border-main',
  borderRadius: 6,
  px: 10,
  py: 12,
  columnGap: 10,
  backgroundColor: '$white',
  '&:hover': {
    backgroundColor: '$primary10',
  },
  '&[data-state=active]': {
    backgroundColor: '$primary20',
  },
});

interface CheckButtonProps extends ComponentProps<typeof CheckButtonRoot> {
  buttonText: string;
  checked: boolean;
  onChecked: (newValue: boolean) => void;
  disabled?: boolean;
}

const CheckButton = forwardRef<HTMLButtonElement, CheckButtonProps>(
  ({ buttonText, checked, onChecked, disabled, ...restProps }, ref) => {
    return (
      <CheckButtonRoot
        ref={ref}
        type="button"
        disabled={disabled}
        onClick={() => onChecked(!checked)}
        data-state={checked ? 'active' : 'inactive'}
        {...restProps}>
        <Checkbox checked={checked} onChange={() => {}} />
        <Text>{`${buttonText}`}</Text>
      </CheckButtonRoot>
    );
  }
);

CheckButton.displayName = 'CheckButton';

export default CheckButton;
