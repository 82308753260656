import type { MutableRefObject, ReactNode } from 'react';
import type { IdNameValueItem } from '../../types/constants';
import { useRef, forwardRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { isNavBarOpenAtom, isOverlayOpenAtom } from '../../store/atoms';
import { motion, useAnimationControls } from 'framer-motion';
import useOnClickOutside from '../../hooks/use-on-click-outside';
import useBrowserDimensions from '../../hooks/use-browser-dimensions';
import Box from '../box';
import Label from '../typography/label';
import Avatar from '../surface/avatar';
import Typography from '../typography/deprecated-typography';
import useRect from '../../hooks/use-rect';
import Spinner from '../helper/spinner';
import SelectOption from '../card/select-option';
import InputErrorMessage from '../typography/input-error-message';

const AutoCompleteInput = forwardRef<
  HTMLInputElement,
  {
    placeholder?: string;
    value?: string;
    onChange?: (newValue: string) => void;
    isError?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
  }
>(({ placeholder, value, onChange, onFocus, isError, disabled = false }, ref) => {
  return (
    <Box
      ref={ref}
      as="input"
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      onFocus={onFocus}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: '5px',
        width: '100%',
        height: '30px',
        borderStyle: 'solid',
        borderRadius: '$5',
        borderWidth: '1px',
        outline: 'none',
        fontSize: '$14',
        borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary20',
        backgroundColor: disabled ? '$secondary5' : '$white',
        transition: 'border-color 0.15s linear',
        '&::placeholder': {
          color: '$secondary30',
          fontSize: '$14',
          height: 'inherit',
          lineHeight: 'inherit',
        },
        '&:focus': {
          borderColor: '$secondary40',
        },
        '&:disabled': {
          backgroundColor: 'unset',
          borderColor: 'unset',
        },
      }}
    />
  );
});
AutoCompleteInput.displayName = 'AutoCompleteInput';

const AutoCompletePopover = ({
  children,
  isMenuOpen = false,
  anchor,
  popoverHeight,
}: {
  children?: ReactNode;
  isMenuOpen: boolean;
  anchor: DOMRect | undefined;
  popoverHeight: number;
}) => {
  const { browserHeight } = useBrowserDimensions();
  const [topPosition, setTopPosition] = useState(0);
  const popoverRef = useRef<HTMLUListElement | null>(null);
  const setIsOverlayOpen = useSetAtom(isOverlayOpenAtom);

  const animationControls = useAnimationControls();
  const menuOpenFadeIn = useCallback(async () => {
    await animationControls.start({ display: 'block' });
    animationControls.start({
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    });
  }, [animationControls]);
  const menuOpenFadeOut = useCallback(async () => {
    await animationControls.start({
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    });
    animationControls.start({ display: 'none' });
  }, [animationControls]);

  useEffect(() => {
    if (isMenuOpen) {
      menuOpenFadeIn();
      setIsOverlayOpen(true);
    } else {
      menuOpenFadeOut();
      setIsOverlayOpen(false);
    }
  }, [isMenuOpen, menuOpenFadeIn, menuOpenFadeOut]);

  useEffect(() => {
    if (anchor) {
      const dropDownTopPosition = anchor.top + anchor.height + window.scrollY + 4;
      const dropUpTopPosition = anchor.top - popoverHeight + window.scrollY - 4;
      setTopPosition(
        dropDownTopPosition + popoverHeight - window.scrollY > browserHeight
          ? dropUpTopPosition
          : dropDownTopPosition
      );
    }
  }, [anchor, browserHeight, popoverHeight]);

  return (
    <Box
      as={motion.ul}
      ref={popoverRef}
      initial={{
        opacity: 0,
        display: 'none',
      }}
      animate={animationControls}
      css={{
        display: isMenuOpen ? 'block' : 'none',
        position: 'absolute',
        top: `${topPosition}px`,
        left: `${anchor ? anchor.left : 0}px`,
        zIndex: 1000,
        width: `${anchor ? anchor.width : 0}px`,
        listStyleType: 'none',
        maxHeight: '280px',
        p: '10px',
        borderRadius: '$5',
        boxShadow: '$basic',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: '$white',
      }}>
      {children}
    </Box>
  );
};

const AutoComplete = ({
  label,
  labelPosition = 'top',
  required = false,
  placeholder,
  optionHeight = 40,
  value,
  options,
  onChange,
  disabled,
  isLoading,
  isError,
  error,
}: {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  placeholder?: string;
  value: string;
  options?: IdNameValueItem[];
  onChange?: (newValue: string) => void;
  disabled?: boolean;
  needSearch?: boolean;
  optionHeight?: number;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<IdNameValueItem[] | []>([]);
  const [inputRect, inputRef, resetInputRef] = useRect();
  const autoCompleteRef = useRef<HTMLDivElement | null>(null);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);

  const handleOnInputFocus = () => {
    if (disabled) {
      return;
    }
    if (isMenuOpen === false) {
      resetInputRef();
    }
    setIsMenuOpen(!isMenuOpen);
  };
  const handleOnClickOutside = () => {
    if (isMenuOpen === true) {
      setIsMenuOpen(false);
    }
  };
  useOnClickOutside(autoCompleteRef, handleOnClickOutside);
  const searchedOptions = useMemo(() => {
    const searchTerm = value.toString() ?? '';
    return filteredOptions.filter((option) => {
      return option.value.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [filteredOptions, value]);

  useEffect(() => {
    resetInputRef();
  }, [isNavBarOpen]);

  useEffect(() => {
    if (options && options.length > 0) {
      setFilteredOptions(options);
    }
  }, [options]);

  return (
    <Box
      ref={autoCompleteRef}
      css={{
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <Box>
        <AutoCompleteInput
          ref={inputRef as MutableRefObject<HTMLInputElement>}
          value={value ?? ''}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          onFocus={handleOnInputFocus}
          onChange={(newValue) => {
            if (onChange) onChange(newValue);
          }}
        />
        <AutoCompletePopover
          isMenuOpen={isMenuOpen}
          anchor={inputRect}
          popoverHeight={
            searchedOptions
              ? searchedOptions.length >= 7
                ? 280
                : searchedOptions.length === 0
                ? 60
                : searchedOptions.length * 40 + 20
              : 0
          }>
          {isLoading ? (
            <Box
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40px',
              }}>
              <Spinner />
            </Box>
          ) : searchedOptions && searchedOptions.length > 0 ? (
            searchedOptions.map((option: IdNameValueItem) => (
              <SelectOption
                key={option.id}
                value={option.value}
                light={option.value === value ? 'on' : 'off'}
                css={{ height: optionHeight }}
                onClick={() => {
                  if (onChange) {
                    onChange(option.value);
                  }
                  if (isMenuOpen === true) {
                    setIsMenuOpen(false);
                  }
                }}>
                <Box css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Avatar
                    name={option.name || '-'}
                    picture={option.picture || ''}
                    size="sm"
                    css={{ mr: 8 }}
                  />
                  <Typography variant="body">{`${option.name}`}</Typography>
                </Box>
              </SelectOption>
            ))
          ) : (
            <Box
              css={{
                px: '$5',
                height: optionHeight,
                fontSize: '$14',
                color: '$secondary30',
                display: 'flex',
                alignItems: 'center',
              }}>{`No Result`}</Box>
          )}
        </AutoCompletePopover>
      </Box>
      <InputErrorMessage isError={isError} errorMessage={error} />
    </Box>
  );
};

export default AutoComplete;
