import type { IdNameValueItem } from '../../types';
import Box from '../box';
import Select from './select';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';

interface ComboSelectProps {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  selectLeftValue: string;
  selectLeftPlaceholder?: string;
  selectLeftOptions: IdNameValueItem[];
  selectLeftOptionHeight?: number;
  isSelectLeftOptionsLoading?: boolean;
  isSelectLeftError?: boolean;
  selectLeftError?: string;
  onSelectLeftValueChange: (newValue: string) => void;
  selectLeftDisabled?: boolean;
  selectRightValue: string;
  selectRightPlaceholder?: string;
  selectRightOptions: IdNameValueItem[];
  selectRightOptionHeight?: number;
  isSelectRightOptionsLoading?: boolean;
  isSelectRightError?: boolean;
  selectRightError?: string;
  onSelectRightValueChange: (newValue: string) => void;
  selectRightDisabled?: boolean;
}

const ComboSelect = ({
  label,
  labelPosition,
  required,
  selectLeftValue,
  selectLeftPlaceholder,
  selectLeftOptions,
  selectLeftOptionHeight = 40,
  isSelectLeftOptionsLoading,
  isSelectLeftError,
  selectLeftError,
  onSelectLeftValueChange,
  selectLeftDisabled,
  selectRightValue,
  selectRightPlaceholder,
  selectRightOptions,
  selectRightOptionHeight = 40,
  isSelectRightOptionsLoading,
  isSelectRightError,
  selectRightError,
  onSelectRightValueChange,
  selectRightDisabled,
}: ComboSelectProps) => {
  return (
    <Box
      css={{
        width: '100%',
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <Box css={{ display: 'flex', columnGap: '$3' }}>
        <Box css={{ width: 'calc(30% - 6px)' }}>
          <Select
            value={selectLeftValue}
            onChange={(newValue) => onSelectLeftValueChange(newValue)}
            placeholder={selectLeftPlaceholder}
            options={selectLeftOptions}
            optionHeight={selectLeftOptionHeight}
            isLoading={isSelectLeftOptionsLoading}
            isError={isSelectLeftError}
            error={selectLeftError}
            disabled={selectLeftDisabled}
          />
        </Box>
        <Box css={{ width: 'calc(70% - 6px)' }}>
          <Select
            inputWidth={170}
            value={selectRightValue}
            onChange={(newValue) => onSelectRightValueChange(newValue)}
            placeholder={selectRightPlaceholder}
            options={selectRightOptions}
            optionHeight={selectRightOptionHeight}
            isLoading={isSelectRightOptionsLoading}
            isError={isSelectRightError}
            error={selectRightError}
            disabled={selectRightDisabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ComboSelect;
