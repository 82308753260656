import { useEffect, useState } from 'react';

const SCRIPT_REGEX_MAIN = /^.*<script.*\/(main.*\.js).*$/gim;

interface UsePollerProps {
  deploymentUrl: string;
}

const fetchPageText = async (url: string): Promise<string> => {
  const response = await fetch(url, { method: 'GET', mode: 'cors', cache: 'no-store' });
  const text = await response.text();
  return text;
};

const extractMainScriptName = (text: string): string | undefined => {
  const match = SCRIPT_REGEX_MAIN.exec(text);
  SCRIPT_REGEX_MAIN.lastIndex = 0;
  return match ? match[1] : undefined;
};

const getCurrentMainScript = (): string | undefined => {
  const scriptTags = document.head.getElementsByTagName('script');
  for (let i = 0; i < scriptTags.length; i++) {
    const scriptTag = scriptTags[i];
    const match = /^.*\/(main.*\.js).*$/gim.exec(scriptTag.src);
    if (match) return match[1];
  }
  return undefined;
};

const usePoller = ({ deploymentUrl }: UsePollerProps) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    const compareVersions = async () => {
      try {
        if (deploymentUrl === 'localhost') {
          return;
        }
        const loadedText = await fetchPageText(deploymentUrl);
        const remoteMainScript = extractMainScriptName(loadedText);

        if (!remoteMainScript) {
          console.log(
            "Could not find main script in index.html or Regex last index doesn't back to 0"
          );
          setIsNewVersionAvailable(false);
          return;
        }

        const currentMainScript = getCurrentMainScript();
        setIsNewVersionAvailable(!!currentMainScript && currentMainScript !== remoteMainScript);

        console.log('Current main script: ', currentMainScript);
        console.log('Remote main script: ', remoteMainScript);
      } catch (error) {
        console.error('Error fetching page:', error);
      }
    };

    const intervalId = setInterval(compareVersions, 1000 * 60 * 30);

    return () => clearInterval(intervalId);
  }, [deploymentUrl]);

  return { isNewVersionAvailable };
};

export default usePoller;
