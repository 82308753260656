import { ComponentProps } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import { styled } from '../../styles/stitches.config';

const StyledMSymbol = styled(MaterialSymbol, {
  color: '$secondary70',
});

interface MSymbolProps extends ComponentProps<typeof StyledMSymbol> {
  iconName: string;
}

const MSymbol = ({
  iconName,
  size = 24,
  weight = 500,
  fill = true,
  grade = 0,
  ...restProps
}: MSymbolProps) => {
  return (
    <StyledMSymbol
      icon={iconName}
      size={size}
      weight={weight}
      fill={fill}
      grade={grade}
      {...restProps}
    />
  );
};

export default MSymbol;
