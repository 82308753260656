import { styled } from '../../styles/stitches.config';

const Typography = styled('span', {
  color: '$secondary90',
  variants: {
    variant: {
      h1: {
        fontSize: '$48',
        fontWeight: '$bold',
        lineHeight: '$125',
      },
      h2: {
        fontSize: '$36',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      h3: {
        fontSize: '$24',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      h4: {
        fontSize: '$20',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      h5: {
        fontSize: '$18',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      h6: {
        fontSize: '$16',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      subtitle: {
        fontSize: '$18',
        fontWeight: '$medium',
        lineHeight: '$150',
      },
      body: {
        fontSize: '$14',
        fontWeight: '$normal',
        lineHeight: '$175',
      },
      'body-medium': {
        fontSize: '$14',
        fontWeight: '$medium',
        lineHeight: '$175',
      },
      'body-bold': {
        fontSize: '$14',
        fontWeight: '$bold',
        lineHeight: '$175',
      },
      caption: {
        fontSize: '$12',
        fontWeight: '$normal',
        lineHeight: '$150',
      },
      'caption-medium': {
        fontSize: '$12',
        fontWeight: '$medium',
        lineHeight: '$150',
      },
      'caption-bold': {
        fontSize: '$12',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      button: {
        fontSize: '$16',
        fontWeight: '$normal',
        lineHeight: '$150',
      },
      'button-bold': {
        fontSize: '$16',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      'button-14': {
        fontSize: '$14',
        fontWeight: '$normal',
        lineHeight: '$150',
      },
      'button-14-bold': {
        fontSize: '$14',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      'button-12': {
        fontSize: '$12',
        fontWeight: '$normal',
        lineHeight: '$150',
      },
      'button-12-bold': {
        fontSize: '$12',
        fontWeight: '$bold',
        lineHeight: '$150',
      },
      'link-16-bold': {
        fontSize: '$16',
        fontWeight: '$bold',
        lineHeight: '$150',
        textDecoration: 'underline',
      },
      'link-14': {
        fontSize: '$14',
        fontWeight: '$normal',
        lineHeight: '$150',
        textDecoration: 'underline',
      },
      'link-14-medium-175': {
        fontSize: '$14',
        fontWeight: '$medium',
        lineHeight: '$175',
        textDecoration: 'underline',
      },
      'link-14-bold-175': {
        fontSize: '$14',
        fontWeight: '$bold',
        lineHeight: '$175',
        textDecoration: 'underline',
      },
      'link-12': {
        fontSize: '$12',
        fontWeight: '$normal',
        lineHeight: '$150',
        textDecoration: 'underline',
      },
      'link-12-bold-175': {
        fontSize: '$12',
        fontWeight: '$bold',
        lineHeight: '$175',
        textDecoration: 'underline',
      },
    },
    align: {
      left: { textAlign: 'left' },
      center: { textAlign: 'center' },
      right: { textAlign: 'right' },
    },
  },
});

export default Typography;
