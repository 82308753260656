import { useState } from 'react';
import { useAtomValue } from 'jotai';
import { appBarHeightAtom, breadcrumbHeightAtom } from '../../store/atoms';
import Box from '../../components/box';
import Spacer from '../../components/surface/spacer';
import Typography from '../../components/typography/deprecated-typography';
import { styled } from '../../styles/stitches.config';
import { settingsTabs } from '../../store/constants';
import { SettingsTabs } from '../../types';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../components/surface/scroll-area';
import MyProfile from './personal/my-profile';
import Organization from './personal/organization';
import Notifications from './personal/notifications';

const SettingTitle = styled('div', {
  height: 40,
  px: 10,
  py: 8,
});
const SettingOptionWrapper = styled('ul', {
  listStyle: 'none',
  pl: 10,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 10,
});
const SettingOption = styled('li', {
  height: 40,
  pl: 10,
  borderRadius: '$5',
  cursor: 'pointer',
  color: '$secondary100',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  transition: 'background-color 0.2s linear, color 0.2s linear',
  backgroundColor: '$solitude',
  '&:hover': {
    backgroundColor: '$secondary3',
    color: '$secondary100',
  },
  '&:active': {
    backgroundColor: '$primary20',
    color: '$primary60',
    fontWeight: 700,
  },
  variants: {
    light: {
      on: {
        backgroundColor: '$primary20',
        color: '$primary60',
        fontWeight: 700,
        '&:hover': {
          backgroundColor: '$primary20',
          color: '$primary60',
        },
      },
      off: {
        color: '$secondary100',
        fontWeight: 400,
        backgroundColor: '$solitude',
      },
    },
  },
  defaultVariants: {
    light: 'off',
  },
});

const SettingPage = () => {
  const appBarHeight = useAtomValue(appBarHeightAtom);
  const breadcrumbHeight = useAtomValue(breadcrumbHeightAtom);
  const [currentSettingsGroup, setCurrentSettingsGroup] = useState<'personal' | 'company'>(
    'personal'
  );
  const [currentSettingsTab, setCurrentSettingsTab] = useState<SettingsTabs>('my-profile');
  return (
    <Box css={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start' }}>
      <Box css={{ p: 20, flexBasis: 260, flexShrink: 0 }}>
        {/* Personal */}
        <Box>
          <SettingTitle>
            <Typography variant={'h6'} css={{ color: '$secondary40' }}>{`Personal`}</Typography>
          </SettingTitle>
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <SettingOptionWrapper onClick={() => setCurrentSettingsGroup('personal')}>
            {settingsTabs.personal.map((personalTab) => (
              <SettingOption
                key={personalTab.id}
                value={personalTab.value}
                onClick={() => setCurrentSettingsTab(personalTab.value)}
                light={
                  personalTab.value === currentSettingsTab ? 'on' : 'off'
                }>{`${personalTab.name}`}</SettingOption>
            ))}
          </SettingOptionWrapper>
        </Box>
        <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
        {/* Company */}
        {/* <Box>
          <SettingTitle>
            <Typography variant={'h6'} css={{ color: '$secondary40' }}>{`Company`}</Typography>
          </SettingTitle>
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <SettingOptionWrapper onClick={() => setCurrentSettingsGroup('company')}>
            {settingsTabs.company.map((companyTab) => (
              <SettingOption
                key={companyTab.id}
                value={companyTab.value}
                onClick={() => setCurrentSettingsTab(companyTab.value)}
                light={
                  companyTab.value === currentSettingsTab ? 'on' : 'off'
                }>{`${companyTab.name}`}</SettingOption>
            ))}
          </SettingOptionWrapper>
        </Box> */}
      </Box>
      <Box
        css={{
          flexGrow: 1,
          height: `calc(100vh - ${appBarHeight} - ${breadcrumbHeight})`,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <ScrollAreaRoot>
          <ScrollAreaViewport css={{ px: 20, py: 15 }}>
            {(() => {
              switch (currentSettingsTab) {
                case 'my-profile':
                  return <MyProfile />;
                case 'organization':
                  return <Organization />;
                case 'notifications':
                  return <Notifications />;
                default:
                  return null;
              }
            })()}
            <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </ScrollAreaRoot>
      </Box>
    </Box>
  );
};

export default SettingPage;
