import type { IdNameValueItem } from '../../../../../../types';
import { useState, useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  authUserAtom,
  submissionFormSelectedSubsidiaryIdAtom,
  submissionFormValidationAtom,
  submissionFormSelectedProductAtom,
  submissionFormCustomerAtom,
} from '../../../../../../store/atoms';
import {
  useGetCurrencyOracleOptions,
  useGetCustomerToCMCurrencyOracleOptions,
  useGetSubsidiaryOracleOptions,
} from '../../../../../../services/application/official-standard/oracle-options.api';
import { useGetCountryDialCodeAppOptions } from '../../../../../../services/application/official-standard/app-options.api';
import { useGetCMUsers } from '../../../../../../services/user.api';
import Box from '../../../../../../components/box';
import Select from '../../../../../../components/dropdown/select';
import AutoComplete from '../../../../../../components/dropdown/auto-complete';
import Spacer from '../../../../../../components/surface/spacer';
import ComboField from '../../../../../../components/dropdown/combo-field';
import { applicationApplyServiceItems } from '../../../../../../store/constants';

const GcpCloudmileGeneral = () => {
  const authUser = useAtomValue(authUserAtom);
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const submissionFormValidation = useAtomValue(submissionFormValidationAtom);
  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const setSubmissionFormSelectedSubsidiaryId = useSetAtom(submissionFormSelectedSubsidiaryIdAtom);

  const currencyOracleOptionsQueries = useGetCurrencyOracleOptions(
    'gcp',
    submissionFormCloudmile.gcp.cm_legal_entity
  );
  const customerToCMCurrencyOracleOptionsQueries = useGetCustomerToCMCurrencyOracleOptions(
    'gcp',
    submissionFormCloudmile.gcp.cm_legal_entity
  );
  const cmLegalEntityOracleOptionsQueries = useGetSubsidiaryOracleOptions('gcp');
  const dialingCodeAppOptionsQueries = useGetCountryDialCodeAppOptions(true);

  const cmUsersQueries = useGetCMUsers();
  const [cmUserNames, setCMUserNames] = useState<IdNameValueItem[]>([]);
  const [cmUserEmails, setCMUserEmails] = useState<IdNameValueItem[]>([]);

  useEffect(() => {
    if (submissionFormCloudmile.gcp.cm_legal_entity) {
      currencyOracleOptionsQueries.refetch();
      customerToCMCurrencyOracleOptionsQueries.refetch();
    }
  }, [submissionFormCloudmile.gcp.cm_legal_entity]);

  useEffect(() => {
    if (cmUsersQueries.isSuccess) {
      setCMUserNames(
        cmUsersQueries.data.map((option) => ({
          id: option.id,
          name: option.name,
          value: option.name,
          picture: option.picture ?? '',
        }))
      );
      setCMUserEmails(
        cmUsersQueries.data.map((option) => ({
          id: option.id,
          name: option.email ?? '',
          value: option.email ?? '',
        }))
      );
      if (authUser !== null) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: {
            ...prev.gcp,
            cm_attention_to: authUser.name,
            cm_email: authUser.email,
          },
        }));
      }
    }
  }, [authUser, cmUsersQueries.data, cmUsersQueries.isSuccess, setSubmissionFormCloudmile]);

  useEffect(() => {
    if (submissionFormCloudmile.gcp.cm_attention_to) {
      const selectedCMUser = cmUsersQueries.data?.find(
        (cmUser) => cmUser.name === submissionFormCloudmile.gcp.cm_attention_to
      );
      if (selectedCMUser) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: {
            ...prev.gcp,
            cm_email: selectedCMUser.email,
          },
        }));
      } else {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: {
            ...prev.gcp,
            cm_email: '',
          },
        }));
      }
    }
  }, [
    submissionFormCloudmile.gcp.cm_attention_to,
    cmUsersQueries.data,
    setSubmissionFormCloudmile,
  ]);

  useEffect(() => {
    if (submissionFormCloudmile.gcp.cm_email) {
      const selectedCMUser = cmUsersQueries.data?.find(
        (cmUser) => cmUser.email === submissionFormCloudmile.gcp.cm_email
      );
      if (selectedCMUser) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: {
            ...prev.gcp,
            cm_attention_to: selectedCMUser.name,
          },
        }));
      } else {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: {
            ...prev.gcp,
            cm_attention_to: '',
          },
        }));
      }
    }
  }, [submissionFormCloudmile.gcp.cm_email, cmUsersQueries.data, setSubmissionFormCloudmile]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* CM-Legal Entity */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="CM-Legal Entity"
            required={true}
            value={submissionFormCloudmile.gcp.cm_legal_entity ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  cm_legal_entity: newValue,
                  currency: '',
                  exchange_rate: '',
                  customer_to_cm_currency: '',
                },
              }));
              const selectedSubsidiaryId = cmLegalEntityOracleOptionsQueries.data
                ?.find((option) => option.name === newValue)
                ?.id.split('-')[2];
              setSubmissionFormSelectedSubsidiaryId(selectedSubsidiaryId ?? '');
            }}
            placeholder="Select a legal entity"
            options={cmLegalEntityOracleOptionsQueries.data}
            isLoading={cmLegalEntityOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.cm_legal_entity?.is_error ?? false}
            error={submissionFormValidation.cm_legal_entity?.error ?? undefined}
          />
        </Box>
        {/* Product */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Product"
            required={true}
            value={submissionFormSelectedProduct ?? ''}
            disabled={true}
            options={applicationApplyServiceItems}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Attention To */}
          <AutoComplete
            label="Attention To"
            required={true}
            value={submissionFormCloudmile.gcp.cm_attention_to ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  cm_attention_to: newValue,
                },
              }));
            }}
            placeholder="Enter a name"
            options={cmUserNames ?? []}
            isError={submissionFormValidation.cm_attention_to?.is_error ?? false}
            error={submissionFormValidation.cm_attention_to?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Email */}
          <AutoComplete
            label="Email"
            required={true}
            value={submissionFormCloudmile.gcp.cm_email ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  cm_email: newValue,
                },
              }));
            }}
            placeholder="Enter an email"
            options={cmUserEmails ?? []}
            isError={submissionFormValidation.cm_email?.is_error ?? false}
            error={submissionFormValidation.cm_email?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Phone Number */}
        <Box css={{ flexBasis: 'calc(50% - 10px)', display: 'flex' }}>
          <ComboField
            mode="easy"
            label="Phone Number"
            selectPlaceholder="(TW) +886"
            selectValue={submissionFormCloudmile.gcp.cm_country_calling_codes ?? ''}
            fieldValue={submissionFormCloudmile.gcp.cm_phone_number ?? ''}
            fieldPlaceholder="Enter a phone number"
            isFieldError={submissionFormValidation.cm_phone_number?.is_error ?? undefined}
            fieldError={submissionFormValidation.cm_phone_number?.error ?? undefined}
            onSelectChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  cm_country_calling_codes: newValue,
                },
              }))
            }
            onFieldChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  cm_phone_number: newValue,
                },
              }))
            }
            selectOptions={dialingCodeAppOptionsQueries.data ?? []}
            isSelectError={submissionFormValidation.cm_country_calling_codes?.is_error ?? undefined}
            selectError={submissionFormValidation.cm_country_calling_codes?.error ?? undefined}
            isSelectOptionsLoading={dialingCodeAppOptionsQueries.isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GcpCloudmileGeneral;
