import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GetAppDetailInfoPanelResponse,
  GetAppDetailApprovalPanelResponse,
  PostAppDetailApprovalPanelPayload,
} from '../../../types/application/simplified/app-detail-panels';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../../../store/atoms';

export const useGetAppDetailInfoPanel = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailInfoPanelResponse, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'detail-panels', 'info-panel'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application/${options.appId}/sidebar_info`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};

export const useGetAppDetailApprovalPanel = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailApprovalPanelResponse, AxiosError<{ message: string }>>({
    queryKey: ['app', 'simp', options.appId, 'detail-panels', 'approval-panel'],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application/${options.appId}/sidebar_approval`,
        {
          params: {
            application_id: options.appId,
          },
        }
      );
      return data;
    },
    enabled: !!options.appId,
  });
};

export const usePostAppDetailApprovalPanel = (options: {
  appId: string;
  onSettled?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<any, AxiosError<{ message: string }>, PostAppDetailApprovalPanelPayload>({
    mutationKey: ['app', 'simp', options.appId, 'detail-panels', 'approval-panel'],
    mutationFn: async (requestBody: PostAppDetailApprovalPanelPayload) => {
      const { data } = await axios.post<any>(
        `/v1/simplified_application/${options.appId}/sidebar_approval`,
        requestBody
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'simp', options.appId]);
      if (options.onSettled) options.onSettled();
      setIsToastOpen(true);
    },
  });
};
