import { ComponentProps, forwardRef, ReactNode } from 'react';
import { styled } from '../../styles/stitches.config';
import Spacer from '../surface/spacer';
import Spinner from '../helper/spinner';

const StyledButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$5',
  borderStyle: 'solid',
  borderWidth: '1px',
  fontWeight: 700,
  lineHeight: 1.7,
  outline: 0,
  whiteSpace: 'nowrap',
  transitionProperty: 'color, background-color, border-color',
  transitionDuration: '0.1s',
  transitionTimingFunction: 'linear',
  '> i': {
    color: 'inherit',
    fontWeight: 'inherit',
  },
  variants: {
    color: {
      primary: {},
      secondary: {},
      'secondary-primary': {},
      success: {},
      warning: {},
      error: {},
    },
    variant: {
      solid: {
        '&:disabled': {
          backgroundColor: '$secondary20',
          borderColor: '$secondary20',
        },
        '&:disabled:hover': {
          backgroundColor: '$secondary20',
          borderColor: '$secondary20',
        },
        '&:disabled:active': {
          backgroundColor: '$secondary20',
          borderColor: '$secondary20',
        },
      },
      outlined: {
        '&:disabled': {
          backgroundColor: '$white',
          borderColor: '$secondary20',
          color: '$secondary20',
        },
        '&:disabled:hover': {
          backgroundColor: '$white',
          borderColor: '$secondary20',
          color: '$secondary20',
        },
        '&:disabled:active': {
          backgroundColor: '$white',
          borderColor: '$secondary20',
          color: '$secondary20',
        },
      },
      borderless: {
        '&:disabled': {
          backgroundColor: '$transparent',
          borderColor: '$transparent',
          color: '$secondary20',
        },
        '&:disabled:hover': {
          backgroundColor: '$transparent',
          borderColor: '$transparent',
          color: '$secondary20',
        },
        '&:disabled:active': {
          backgroundColor: '$transparent',
          borderColor: '$transparent',
          color: '$secondary20',
        },
      },
      dashed: {},
    },
    size: {
      chip: {
        fontSize: '$16',
        height: '16px',
        '> i': {
          fontSize: 'inherit',
        },
      },
      mini: {
        fontSize: '$12',
        height: '20px',
        '> i': {
          fontSize: '$12',
        },
      },
      tiny: {
        fontSize: '$12',
        height: '24px',
        '> i': {
          fontSize: '$16',
        },
      },
      small: {
        fontSize: '$14',
        height: '30px',
        '> i': {
          fontSize: '$16',
        },
      },
      medium: {
        fontSize: '$14',
        height: '36px',
        '> i': {
          fontSize: '$16',
        },
      },
      large: {
        fontSize: '$16',
        height: '44px',
        '> i': {
          fontSize: '$18',
        },
      },
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      variant: 'solid',
      css: {
        color: '$white',
        backgroundColor: '$primary50',
        borderColor: '$primary50',
        '&:hover': {
          backgroundColor: '$primary40',
          borderColor: '$primary40',
        },
        '&:active': {
          backgroundColor: '$primary60',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      css: {
        color: '$primary50',
        backgroundColor: '$white',
        borderColor: '$primary50',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'primary',
      variant: 'borderless',
      css: {
        color: '$primary50',
        backgroundColor: '$transparent',
        borderColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary20',
        },
      },
    },
    {
      color: 'primary',
      variant: 'dashed',
      css: {
        color: '$primary50',
        backgroundColor: '$white',
        borderColor: '$primary50',
        borderStyle: 'dashed',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'solid',
      css: {
        color: '$white',
        borderColor: '$secondary70',
        backgroundColor: '$secondary70',
        '&:hover': {
          borderColor: '$secondary60',
          backgroundColor: '$secondary60',
        },
        '&:active': {
          borderColor: '$secondary80',
          backgroundColor: '$secondary80',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'outlined',
      css: {
        color: '$secondary70',
        backgroundColor: '$white',
        borderColor: '$secondary20',
        '&:hover': {
          backgroundColor: '$secondary3',
          borderColor: '$secondary70',
        },
        '&:active': {
          backgroundColor: '$secondary10',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'borderless',
      css: {
        color: '$secondary70',
        backgroundColor: '$transparent',
        borderColor: '$transparent',
        '&:hover': {
          backgroundColor: '$secondary3',
          borderColor: '$secondary3',
        },
        '&:active': {
          backgroundColor: '$secondary10',
          borderColor: '$secondary10',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'dashed',
      css: {
        color: '$secondary70',
        backgroundColor: '$white',
        borderColor: '$secondary20',
        borderStyle: 'dashed',
        '&:hover': {
          backgroundColor: '$secondary3',
          borderColor: '$secondary70',
        },
        '&:active': {
          backgroundColor: '$secondary10',
        },
      },
    },
    {
      color: 'secondary-primary',
      variant: 'outlined',
      css: {
        color: '$secondary70',
        backgroundColor: '$white',
        borderColor: '$secondary20',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
        '&[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'secondary-primary',
      variant: 'borderless',
      css: {
        color: '$secondary70',
        backgroundColor: '$transparent',
        borderColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary20',
        },
        '&[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary20',
        },
      },
    },
    {
      color: 'success',
      variant: 'solid',
      css: {
        color: '$white',
        backgroundColor: '$success70',
        borderColor: '$success70',
        '&:hover': {
          backgroundColor: '$success60',
          borderColor: '$success60',
        },
        '&:active': {
          backgroundColor: '$success80',
          borderColor: '$success80',
        },
      },
    },
    {
      color: 'success',
      variant: 'outlined',
      css: {
        color: '$success70',
        backgroundColor: '$white',
        borderColor: '$success70',
        '&:hover': {
          color: '$success70',
          backgroundColor: '$success10',
          borderColor: '$success70',
        },
        '&:active': {
          color: '$success80',
          backgroundColor: '$success20',
          borderColor: '$success80',
        },
      },
    },
    // TODO: success borderless; success dashed
    // TODO: warning solid; warning borderless; warning outlined 10 50 60 90
    {
      color: 'warning',
      variant: 'outlined',
      css: {
        color: '$warning60',
        backgroundColor: '$white',
        borderColor: '$warning60',
        '&:hover': {
          color: '$warning50',
          backgroundColor: '$warning10',
          borderColor: '$warning50',
        },
        '&:active': {
          color: '$warning90',
          backgroundColor: '$warning20',
          borderColor: '$warning90',
        },
      },
    },
    {
      color: 'error',
      variant: 'solid',
      css: {
        color: '$white',
        backgroundColor: '$error60',
        borderColor: '$error60',
        '&:hover': {
          backgroundColor: '$error50',
          borderColor: '$error50',
        },
        '&:active': {
          backgroundColor: '$error90',
          borderColor: '$error90',
        },
      },
    },
    {
      color: 'error',
      variant: 'outlined',
      css: {
        color: '$error60',
        backgroundColor: '$white',
        borderColor: '$error60',
        '&:hover': {
          color: '$error50',
          backgroundColor: '$error10',
          borderColor: '$error50',
        },
        '&:active': {
          color: '$error90',
          backgroundColor: '$error20',
          borderColor: '$error90',
        },
      },
    },
    {
      color: 'error',
      variant: 'borderless',
      css: {
        color: '$error60',
        backgroundColor: '$transparent',
        borderColor: '$transparent',
        '&:hover': {
          color: '$error50',
          backgroundColor: '$error10',
          borderColor: '$error10',
        },
        '&:active': {
          color: '$error90',
          backgroundColor: '$error20',
          borderColor: '$error20',
        },
      },
    },
    {
      color: 'error',
      variant: 'dashed',
      css: {
        color: '$error50',
        backgroundColor: '$white',
        borderColor: '$error60',
        borderStyle: 'dashed',
        '&:hover': {
          color: '$error50',
          backgroundColor: '$error10',
          borderColor: '$error50',
        },
        '&:active': {
          color: '$error90',
          backgroundColor: '$error20',
          borderColor: '$error90',
        },
      },
    },
  ],
  defaultVariants: {
    color: 'primary',
    variant: 'solid',
    size: 'medium',
  },
});

type Variant = 'solid' | 'outlined' | 'borderless' | 'dashed';
type Size = 'chip' | 'mini' | 'tiny' | 'small' | 'medium' | 'large';
type Color = 'primary' | 'secondary' | 'secondary-primary' | 'success' | 'warning' | 'error';

interface ButtonProps extends ComponentProps<typeof StyledButton> {
  variant?: Variant;
  size?: Size;
  color?: Color;
  spinnerColor?: Color;
  disabled?: boolean;
  isLoading?: boolean;
  startElement?: ReactNode;
  endElement?: ReactNode;
  children?: ReactNode;
  customWidth?: number | string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      spinnerColor,
      startElement,
      endElement,
      disabled,
      isLoading,
      customWidth,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        {...restProps}
        disabled={disabled}
        css={{
          width: customWidth ? customWidth : 'fit-content',
          paddingLeft: startElement ? ' 10px' : '12px',
          paddingRight: endElement ? '10px;' : '12px',
          cursor: disabled ? 'not-allowed' : 'pointer',
          pointerEvents: disabled ? 'none' : isLoading ? 'none' : 'auto',
        }}>
        {isLoading ? (
          <Spinner elementFor="button" spinnerColor={spinnerColor} />
        ) : (
          <>
            {startElement ? (
              <>
                {startElement}
                <Spacer axis="horizontal" css={{ $$size: '4px' }} />
              </>
            ) : null}
            {children}
            {endElement ? (
              <>
                {endElement}
                <Spacer axis="horizontal" css={{ $$size: '4px' }} />
              </>
            ) : null}
          </>
        )}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
