import moment from 'moment';
import { useGetAppDetailInfoPanel } from '../../../../services/application/simplified-standard/app-detail-panels.api';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Box from '../../../../components/box';
import Spacer from '../../../../components/surface/spacer';
import Divider from '../../../../components/surface/divider';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import { Heading5 } from '../../../../components/typography/heading';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import Avatar, { AvatarMore } from '../../../../components/surface/avatar';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import { avatarStackLength } from '../../../../store/constants';

export interface AppDetailInfoPanelProps {
  appId: string;
}

const InfoPanel = ({ appId }: AppDetailInfoPanelProps) => {
  const appDetailInfoPanelQuery = useGetAppDetailInfoPanel({ appId });
  return (
    <>
      {appDetailInfoPanelQuery.isLoading ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Spinner size="large" />
        </CenteredBoxFlex>
      ) : appDetailInfoPanelQuery.isSuccess ? (
        <>
          <CenteredBoxFlex css={{ height: 90, flexShrink: 0 }}>
            <Heading5
              css={{
                width: 290,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
              }}>{`${appDetailInfoPanelQuery.data.name || '-'}`}</Heading5>
          </CenteredBoxFlex>
          <Divider
            axis={'horizontal'}
            css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
          />
          {/* Access */}
          <Box css={{ p: 20 }}>
            <ButtonText size={'16'}>Who has access</ButtonText>
            <Box css={{ display: 'flex', mt: 6, flexWrap: 'wrap', rowGap: 4 }}>
              {appDetailInfoPanelQuery.data.accessors
                .slice(0, avatarStackLength)
                .map((accessor, accessorIndex) => (
                  <TooltipRoot key={accessor.id}>
                    <TooltipTrigger asChild>
                      <Box css={{ zIndex: accessorIndex + 1, mr: -4 }}>
                        <Avatar
                          name={accessor.name}
                          picture={accessor.picture}
                          css={{ border: '1px solid $white' }}
                        />
                      </Box>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={4}
                        side={`bottom`}
                        css={{
                          px: 16,
                          py: 4.5,
                        }}>
                        <Text css={{ color: '$white' }}>{`${accessor.name}`}</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                ))}
              {appDetailInfoPanelQuery.data.accessors.length - avatarStackLength > 0 ? (
                <TooltipRoot>
                  <TooltipTrigger asChild>
                    <div>
                      <AvatarMore
                        css={{ border: '1px solid $white' }}
                        remainNumber={
                          appDetailInfoPanelQuery.data.accessors.length - avatarStackLength
                        }
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent
                      sideOffset={4}
                      side={`bottom`}
                      css={{
                        px: 16,
                        py: 4.5,
                      }}>
                      <Text css={{ color: '$white' }}>{`${appDetailInfoPanelQuery.data.accessors
                        .slice(avatarStackLength)
                        .map((accessor) => accessor.name)
                        .join(', ')}`}</Text>
                      <TooltipArrow />
                    </TooltipContent>
                  </TooltipPortal>
                </TooltipRoot>
              ) : null}
            </Box>
          </Box>
          <Divider
            axis={'horizontal'}
            css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
          />
          {/* Labels */}
          <Box></Box>
          <ScrollAreaRoot>
            <ScrollAreaViewport>
              {/* File Details */}
              <Box css={{ p: 20 }}>
                <Text css={{ fontSize: '$16' }}>{`File Details`}</Text>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Owner */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Caption semibold css={{ width: 90, color: '$secondary70' }}>{`Owner`}</Caption>
                  <Caption
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>{`${appDetailInfoPanelQuery.data.owner || '-'}`}</Caption>
                </Box>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Created */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Caption semibold css={{ width: 90, color: '$secondary70' }}>{`Created`}</Caption>
                  <Caption
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>
                    {appDetailInfoPanelQuery.data?.created
                      ? moment(appDetailInfoPanelQuery.data.created).format('MM/DD/YY HH:mm A (Z)')
                      : '-'}
                  </Caption>
                </Box>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Modified */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Caption
                    semibold
                    css={{ width: 90, color: '$secondary70' }}>{`Modified`}</Caption>
                  <Caption
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>
                    {appDetailInfoPanelQuery.data?.modified
                      ? moment(appDetailInfoPanelQuery.data.modified).format('MM/DD/YY HH:mm A (Z)')
                      : '-'}
                  </Caption>
                </Box>
              </Box>
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </ScrollAreaRoot>
        </>
      ) : appDetailInfoPanelQuery.isError ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxFlex>
      ) : null}
    </>
  );
};

export default InfoPanel;
