import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { AppStatus, CommonAPIResponse, OrderByValue, SortByValue } from '../../types/constants';
import useAxios from '../../hooks/use-axios';
import { GetAppListResponse } from '../../types/application/constants';

export const useGetAppList = (options: {
  appStatus: AppStatus | 'all';
  sortBy: SortByValue;
  orderBy: OrderByValue;
  offset: string;
  limit: string;
  search: string;
}) => {
  const axios = useAxios();
  return useQuery<GetAppListResponse, AxiosError<CommonAPIResponse>>({
    queryKey: [
      'app',
      'list',
      options.appStatus,
      options.sortBy,
      options.orderBy,
      options.offset,
      options.limit,
      options.search,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/`, {
        params: {
          application_status: options.appStatus,
          sort_by: options.sortBy,
          order_by: options.orderBy,
          offset: options.offset,
          limit: options.limit,
          search: options.search,
        },
      });
      return data;
    },
  });
};
