import type { IdNameValueItem } from '../../../../../types';
import { useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  submissionFormCustomerAtom,
  submissionFormSelectedProductAtom,
  submissionFormValidationAtom,
} from '../../../../../store/atoms';
import { useQueryClient } from 'react-query';
import { checkFileSize, checkFileExt } from '../../../../../utils/check-file';
import { getAttachmentErrorWording } from '../../../../../utils/get-wording';
import Box from '../../../../../components/box';
import Divider from '../../../../../components/surface/divider';
import Typography from '../../../../../components/typography/deprecated-typography';
import Spacer from '../../../../../components/surface/spacer';
import LogoButton from '../../../../../components/button/logo-button';
import LogoSvg from '../../../../../components/surface/logo-svg';
import GcpCustomerGeneral from '../gcp/standard/gcp-customer-general';
import GwsCustomerGeneral from '../gws/standard/gws-customer-general';
import GcpCustomerPayment from '../gcp/standard/gcp-customer-payment';
import GwsCustomerPayment from '../gws/standard/gws-customer-payment';
import AttachFile from '../../../../../components/card/attach-file';

const CustomerVenderInfo = () => {
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const [submissionFormCustomer, setSubmissionFormCustomer] = useAtom(submissionFormCustomerAtom);

  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  const queryClient = useQueryClient();
  const selectedClientSource = queryClient
    .getQueryData<IdNameValueItem[]>(['customer-abbreviations'])
    ?.find((customer: IdNameValueItem) => {
      if (submissionFormSelectedProduct) {
        return customer.value === submissionFormCustomer[submissionFormSelectedProduct].client_name;
      }
    })?.source;

  useEffect(() => {
    if (submissionFormCustomer.gws.fsr_mail === null) {
      setSubmissionFormValidation((prev) => ({
        ...prev,
        fsr_mail: undefined,
      }));
    }
    if (submissionFormCustomer.gcp.company_registration === null) {
      setSubmissionFormValidation((prev) => ({
        ...prev,
        company_registration: undefined,
      }));
    }
  }, [
    setSubmissionFormValidation,
    submissionFormCustomer.gcp.company_registration,
    submissionFormCustomer.gws.fsr_mail,
  ]);

  return (
    <>
      <Box
        css={{
          m: '0 auto',
          width: '780px',
          px: '40px',
          py: '20px',
          backgroundColor: '$white',
        }}>
        <Typography variant="h5">{`${
          submissionFormSelectedProduct === 'gws' ? 'Customer & Vendor' : 'Customer'
        }`}</Typography>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <Typography
          variant="body-bold"
          css={{ color: '$secondary40' }}>{`General Information`}</Typography>
        <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <LogoButton light="on" css={{ backgroundColor: '$primary10' }}>
          <LogoSvg product={submissionFormSelectedProduct} />
        </LogoButton>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <>
          {(() => {
            switch (submissionFormSelectedProduct) {
              case 'gcp':
                return <GcpCustomerGeneral />;
              case 'gws':
                return <GwsCustomerGeneral />;
              default:
                return null;
            }
          })()}
        </>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <Typography
          variant="body-bold"
          css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
        <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <>
          {(() => {
            switch (submissionFormSelectedProduct) {
              case 'gcp':
                return <GcpCustomerPayment />;
              case 'gws':
                return <GwsCustomerPayment />;
              default:
                return null;
            }
          })()}
        </>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box
        css={{
          m: '0 auto',
          width: '780px',
          px: '40px',
          py: '20px',
          backgroundColor: '$white',
        }}>
        <Typography variant="h5">{`Attach Files`}</Typography>
        <Spacer axis="vertical" css={{ $$size: '30px' }} />
        <AttachFile
          attachName="Company Registration"
          file={
            submissionFormSelectedProduct
              ? submissionFormCustomer[submissionFormSelectedProduct].company_registration
              : null
          }
          required={selectedClientSource === 'manual'}
          needSampleImage={
            submissionFormSelectedProduct &&
            (submissionFormCustomer[submissionFormSelectedProduct].client_country === 'TW' ||
              submissionFormCustomer[submissionFormSelectedProduct].client_country === 'HK' ||
              submissionFormCustomer[submissionFormSelectedProduct].client_country === 'SG' ||
              submissionFormCustomer[submissionFormSelectedProduct].client_country === 'ID' ||
              submissionFormCustomer[submissionFormSelectedProduct].client_country === 'PH' ||
              submissionFormCustomer[submissionFormSelectedProduct].client_country === 'MY')
          }
          sampleImageId={
            submissionFormSelectedProduct &&
            (submissionFormCustomer[submissionFormSelectedProduct].client_country === 'TW' ||
            submissionFormCustomer[submissionFormSelectedProduct].client_country === 'HK' ||
            submissionFormCustomer[submissionFormSelectedProduct].client_country === 'SG' ||
            submissionFormCustomer[submissionFormSelectedProduct].client_country === 'ID' ||
            submissionFormCustomer[submissionFormSelectedProduct].client_country === 'PH' ||
            submissionFormCustomer[submissionFormSelectedProduct].client_country === 'MY'
              ? `${submissionFormCustomer[submissionFormSelectedProduct].client_country}`
              : undefined)
          }
          onFileChange={(selectedFile) => {
            setSubmissionFormValidation((prev) => ({
              ...prev,
              company_registration: undefined,
            }));
            setSubmissionFormCustomer((prev) => {
              if (submissionFormSelectedProduct) {
                return {
                  ...prev,
                  [`${submissionFormSelectedProduct}`]: {
                    ...prev[`${submissionFormSelectedProduct}`],
                    company_registration: selectedFile,
                  },
                };
              }
              return prev;
            });
          }}
          isError={
            submissionFormSelectedProduct
              ? checkFileSize(
                  submissionFormCustomer[submissionFormSelectedProduct].company_registration
                    ?.size ?? 0
                ) ||
                checkFileExt(
                  submissionFormCustomer[submissionFormSelectedProduct].company_registration
                    ?.name ?? ''
                ) ||
                submissionFormValidation.company_registration?.is_error
              : false
          }
          error={
            submissionFormSelectedProduct
              ? getAttachmentErrorWording(
                  checkFileSize(
                    submissionFormCustomer[submissionFormSelectedProduct].company_registration
                      ?.size ?? 0
                  ),
                  checkFileExt(
                    submissionFormCustomer[submissionFormSelectedProduct].company_registration
                      ?.name ?? ''
                  )
                ) || submissionFormValidation.company_registration?.error
              : undefined
          }
        />
        {submissionFormSelectedProduct === 'gws' ? (
          <>
            <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
            <AttachFile
              attachName="FSR Mail"
              needSampleImage={true}
              sampleImageId={`FSR_Mail`}
              file={submissionFormCustomer.gws.fsr_mail}
              disabled={submissionFormCustomer.gws.special_price_from_google === 'No'}
              required={submissionFormCustomer.gws.special_price_from_google === 'Yes'}
              isError={
                checkFileSize(submissionFormCustomer.gws.fsr_mail?.size ?? 0) ||
                checkFileExt(submissionFormCustomer.gws.fsr_mail?.name ?? '') ||
                submissionFormValidation.fsr_mail?.is_error
              }
              error={
                getAttachmentErrorWording(
                  checkFileSize(submissionFormCustomer.gws.fsr_mail?.size ?? 0),
                  checkFileExt(submissionFormCustomer.gws.fsr_mail?.name ?? '')
                ) || submissionFormValidation.fsr_mail?.error
              }
              onFileChange={(selectedFile) => {
                setSubmissionFormValidation((prev) => ({ ...prev, fsr_mail: undefined }));
                setSubmissionFormCustomer((prev) => ({
                  ...prev,
                  gws: {
                    ...prev.gws,
                    fsr_mail: selectedFile,
                  },
                }));
              }}
            />
          </>
        ) : null}
      </Box>
    </>
  );
};

export default CustomerVenderInfo;
