import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { GetAppDocBarResponse } from '../../../types/application/simplified/app-doc-bar';

const useGetAppDocBar = (options: { appId: string; isDraft: boolean }) => {
  const axios = useAxios();
  return useQuery<GetAppDocBarResponse, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'doc-bar'],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application/${options.appId}/google_document`,
        {
          params: {
            application_id: options.appId,
          },
        }
      );
      return data;
    },
    enabled: !!options.appId && !options.isDraft,
  });
};

export default useGetAppDocBar;
