import { getApproverResultVariant } from '../../utils/get-variant';
import Box from '../box';
import { Heading6 } from '../typography/heading';
import { Text } from '../typography/text';

interface ApproverResulterProps {
  approverResultCode: number;
}

const ApproverResulter = ({ approverResultCode }: ApproverResulterProps) => {
  return (
    <>
      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4, mb: 6 }}>
        <Box
          as="img"
          css={{ width: 20, height: 20 }}
          src={getApproverResultVariant(approverResultCode).icon}
        />
        <Heading6 css={{ color: getApproverResultVariant(approverResultCode).color }}>
          {getApproverResultVariant(approverResultCode).title}
        </Heading6>
      </Box>
      <Box>
        <Text as="p" css={{ maxWidth: 280 }}>
          {getApproverResultVariant(approverResultCode).desc}
        </Text>
      </Box>
    </>
  );
};

export default ApproverResulter;
