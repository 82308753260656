import { SettingsTabs } from '../types';
import type { DropdownOption, IdNameValueItem } from '../types/constants';

// * Nano ID CC https://zelark.github.io/nano-id-cc/

// * Validation Regex
export const validationRegex = {
  email:
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

// * Layout Stuff
export const appBarDesktopHeight = '60px';
export const navBarOpenWidth = '240px';
export const navBarClosedWidth = '54px';
export const breadcrumbNormalHeight = '30px';
export const applicationDetailsSidebarWidth = 370;
export const avatarStackLength = 5;

export const navLinks: IdNameValueItem[] = [
  { id: 'DRZ90J', name: 'Home', value: '/', iconName: 'home' },
  {
    id: 'aVakcv',
    name: 'Application',
    value: '/applications',
    iconName: 'edit_document',
  },
  { id: 'zms7at', name: 'Customer', value: '/customers', iconName: 'groups' },
  { id: '43A5B0', name: 'Setting', value: '/setting', iconName: 'settings' },
];
export const navLinksDev: IdNameValueItem[] = [
  { id: 'DRZ90J', name: 'Home', value: '/', iconName: 'home' },
  {
    id: 'HOl6PF',
    name: 'Application',
    value: '/applications',
    iconName: 'edit_document',
  },
  { id: 'zms7at', name: 'Customer', value: '/customers', iconName: 'groups' },
  // { id: 'M6uz__', name: 'Archive', value: '/archive', iconName: 'folder' },
  { id: '43A5B0', name: 'Setting', value: '/setting', iconName: 'settings' },
];

export const sortByOptions: DropdownOption[] = [
  { id: 'k84jsa', name: 'Modified date', value: 'modified_at' },
  { id: 'n1sf9e', name: 'Urgent level', value: 'urgent_level' },
  { id: '9d2ks6', name: 'Awaiting', value: 'awaiting' },
];
export const orderByOptions: DropdownOption[] = [
  { id: 'J8El8C', name: 'Ascending (A → Z)', value: 'asc' },
  { id: 'HR6wgH', name: 'Descending (Z → A)', value: 'desc' },
];
export const applicationCommentsFilterByOptions: IdNameValueItem[] = [
  { id: 'k84jsa', name: 'All', value: 'all' },
  { id: 'n1sf9e', name: 'Mention you', value: 'mentioned' },
  { id: '9d2ks6', name: 'Resolved', value: 'resolved' },
];
export const customerSortByOptions: IdNameValueItem[] = [
  { id: '3tFb79', name: 'Last Modified', value: 'last_updated_time' },
  { id: 'K2jxH8', name: 'Company Name', value: 'company_name' },
];

export const assignmentCardOptions: IdNameValueItem[] = [
  { id: '3Fbh0D', name: 'View Details', value: 'view-details', iconName: 'visibility' },
  { id: 'dSxCsT', name: 'View Document', value: 'view-document', iconName: 'description' },
];
export const commentSummaryCardOptions: IdNameValueItem[] = [
  { id: 'IowRsf', name: 'Check Comment', value: 'check-comment', iconName: 'chat_bubble' },
  { id: 'RdFhYg', name: 'Reply', value: 'reply', iconName: 'reply_all' },
];

export const NotificationCardOptions: IdNameValueItem[] = [
  { id: '1v9Cfr', name: 'Mark as unread', value: 'mark-as-unread' },
  { id: 'GJdK5z', name: 'Dismiss this notification', value: 'dismiss-this-notification' },
];

export const applicationApplyServiceItems: IdNameValueItem[] = [
  { id: 'DMRY6J', name: 'Google Cloud (GCP)', value: 'gcp' },
  { id: 'IuaMoh', name: 'Google Workspace (GWS)', value: 'gws' },
  // { id: 'fTTeE4', name: 'cloudflare' },
  // { id: 'YMEyVi', name: 'apigee' },
];
export const yesNoAppOptions: IdNameValueItem[] = [
  { id: '9sUcA2', name: 'Yes', value: 'Yes' },
  { id: 'dV8nG4', name: 'No', value: 'No' },
];
export const yesNoOptions: DropdownOption[] = [
  { id: 'wRpY3f', name: 'YES', value: 'yes' },
  { id: 'kT7eL9', name: 'NO', value: 'no' },
];
export const settingsTabs: {
  personal: { id: string; name: string; value: SettingsTabs }[];
  company: { id: string; name: string; value: SettingsTabs }[];
} = {
  personal: [
    { id: 'w_xMxO', name: 'My Profile', value: 'my-profile' },
    { id: 'NcgURh', name: 'Organization', value: 'organization' },
    { id: '__Cvkw', name: 'Notifications', value: 'notifications' },
    // { id: 'O0kQ_', name: 'Language & Region', value: 'language-region' },
  ],
  company: [
    { id: '9BzPyh', name: 'Manage Users', value: 'manage-users' },
    { id: 'QgQmUh', name: 'Currency', value: 'currency' },
  ],
};

export const settingsNotificationsSharedOptions: {
  id: string;
  name: string;
  keyName: string;
  desc: string;
}[] = [
  {
    id: 'j7e8N9',
    name: 'Comments',
    keyName: 'comment_notification',
    desc: 'These are notifications for comments on your application and replies to your comments',
  },
  {
    id: 'X6d2L1',
    name: 'Mentions',
    keyName: 'mention_notification',
    desc: 'These are notifications for when someone mentions you in a comment',
  },
  {
    id: 'z3k9T2',
    name: 'Access Requests',
    keyName: 'access_notification',
    desc: 'Notifications when someone gives you a user request or accepts your access request',
  },
  {
    id: 'F1r6H8',
    name: 'Activities Related to You',
    keyName: 'approval_notification',
    desc: 'These are alerts for activities taken on your application, such as likes approving, editing your application, and more',
  },
];
