import { ComponentProps } from 'react';
import { nanoid } from 'nanoid';
import Typography from './deprecated-typography';

interface MentionedTypographyProps extends ComponentProps<typeof Typography> {
  message: string;
  ellipsisLine?: number | string;
  color?: string;
  tagVariant?: ComponentProps<typeof Typography>['variant'];
}

const MentionedTypography = ({
  message,
  tagVariant = 'body-bold',
  variant = 'body',
  ellipsisLine = 3,
  color = '$secondary90',
}: MentionedTypographyProps) => {
  // * Template: @[displayName](userId)
  const parts = message.split(/@\[(.*?)\]\((.*?)\)/);
  return (
    <Typography
      as={'p'}
      variant={variant}
      css={{
        display: '-webkit-box',
        '-webkit-line-clamp': ellipsisLine,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: color,
        wordBreak: 'break-all',
      }}>
      {parts.map((part, index) => {
        if (index % 3 === 1) {
          // * Display Name
          return (
            <Typography key={nanoid()} variant={tagVariant} css={{ color: '$primary50' }}>
              {` @${part} `}
            </Typography>
          );
        } else if (index % 3 === 2) {
          // * User ID
          return null;
        } else {
          // * Normal Content
          return part;
        }
      })}
    </Typography>
  );
};

export default MentionedTypography;
