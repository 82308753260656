import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './app';
import reportWebVitals from './report-web-vitals';
import { initializeReactGA4 } from './utils/g-analytics';
import { initializeReactGTM } from './utils/g-tag-manager';

initializeReactGA4();
initializeReactGTM();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
        <DevTools />
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// * If you want to start measuring performance in your app, pass a function
// *  to log results (for example: reportWebVitals(console.log))
// *  or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
