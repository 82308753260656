import Box from '../box';
import Typography from '../typography/deprecated-typography';

const CountingBox = ({ countingNumber }: { countingNumber: number | string }) => {
  return (
    <Box
      css={{
        minWidth: 30,
        px: 4,
        height: 20,
        borderRadius: '$5',
        backgroundColor: '$secondary10',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography variant={'body-bold'}>{countingNumber ? `${countingNumber}` : '0'}</Typography>
    </Box>
  );
};

export default CountingBox;
