import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types/constants';
import {
  GetAppActionBarResponse,
  PostAppCopyPayload,
} from '../../../types/application/simplified/app-actions';
import {
  currentApplicationsTabAtom,
  getApplicationParamsAtom,
  isToastOpenAtom,
  toastContentAtom,
} from '../../../store/atoms';

export const useGetAppActionBar = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppActionBarResponse, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'action-bar'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application/${options.appId}/action_bar`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};

export const usePostAppCopy = (options: { appId: string }) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>, PostAppCopyPayload>({
    mutationKey: ['app', 'simp', options.appId, 'copy'],
    mutationFn: async (requestBody: PostAppCopyPayload) => {
      const { data } = await axios.post<CommonAPIResponse>(
        `/v1/simplified_application/${options.appId}/copy`,
        requestBody
      );
      return data;
    },
    onSuccess: (res) => {
      resetGetApplicationParams();
      setCurrentApplicationsTab('drafts');
      setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
      setIsToastOpen(true);
      navigate('/applications');
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });
};

export const usePatchAppWithdraw = (options: { appId: string }) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'simp', options.appId, 'withdraw'],
    mutationFn: async () => {
      const { data } = await axios.patch<CommonAPIResponse>(
        `/v1/simplified_application/${options.appId}/withdraw`
      );
      return data;
    },
    onSuccess: (res) => {
      resetGetApplicationParams();
      setCurrentApplicationsTab('drafts');
      setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
      setIsToastOpen(true);
      navigate('/applications');
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });
};

export const useDeleteAppDelete = (options: { appId: string }) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const currentApplicationsTab = useAtomValue(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'simp', options.appId, 'delete'],
    mutationFn: async () => {
      const { data } = await axios.delete<CommonAPIResponse>(
        `/v1/simplified_application/${options.appId}`
      );
      return data;
    },
    onSuccess: (res) => {
      resetGetApplicationParams();
      setGetApplicationParams((prev) => ({ ...prev, tab: currentApplicationsTab }));
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
      setIsToastOpen(true);
      navigate('/applications');
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });
};
