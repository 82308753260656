import { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  submissionFormCustomerAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import {
  taxRegNumberFieldLabel,
  isTaxRegNumberFieldRequired,
  isTaxRegNumberFieldOptional,
} from '../../../../../../utils/tax-reg-number-field';
import { useGetCustomerAbbreviations } from '../../../../../../services/customer.api';
import {
  useGetCountryCodeAppOptions,
  useGetCountryDialCodeAppOptions,
} from '../../../../../../services/application/official-standard/app-options.api';
import Box from '../../../../../../components/box';
import Field from '../../../../../../components/field/deprecated-field';
import Select from '../../../../../../components/dropdown/select';
import Spacer from '../../../../../../components/surface/spacer';
import ComboField from '../../../../../../components/dropdown/combo-field';

const GcpCustomerGeneral = () => {
  const [submissionFormCustomer, setSubmissionFormCustomer] = useAtom(submissionFormCustomerAtom);
  const customerAbbreviationsQueries = useGetCustomerAbbreviations();
  const countryCodeAppOptionsQueries = useGetCountryCodeAppOptions(false);
  const dialCodeAppOptionsQueries = useGetCountryDialCodeAppOptions(false);

  const submissionFormValidation = useAtomValue(submissionFormValidationAtom);

  const [isClientCountryDisabled, setIsClientCountryDisabled] = useState(true);
  const [isClientAddressDisabled, setIsClientAddressDisabled] = useState(true);
  const [isTaxRegNumberDisabled, setIsTaxRegNumberDisabled] = useState(true);

  useEffect(() => {
    if (submissionFormCustomer.gcp.client_country && dialCodeAppOptionsQueries.data) {
      const selectedDialCode = dialCodeAppOptionsQueries.data.find(
        (option) => option.name.split(' ')[0] === `(${submissionFormCustomer.gcp.client_country})`
      );
      setSubmissionFormCustomer((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          client_country_calling_codes: selectedDialCode?.value,
        },
      }));
    }
  }, [
    submissionFormCustomer.gcp.client_country,
    dialCodeAppOptionsQueries.data,
    setSubmissionFormCustomer,
  ]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(100%)' }}>
          {/* Client Name */}
          <Select
            label="Client Name"
            required={true}
            value={submissionFormCustomer.gcp.client_name ?? ''}
            options={customerAbbreviationsQueries.data}
            optionHeight={60}
            inputWidth={655}
            placeholder="Select a client name"
            onChange={(newValue) => {
              const selectedCustomer = customerAbbreviationsQueries.data?.find(
                (customer) => customer.value === newValue
              );
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_name: newValue,
                  client_country: selectedCustomer?.country_code || '',
                  client_address: selectedCustomer?.address || '',
                  tax_reg_number: selectedCustomer?.tax_reg_number || '',
                  payment_term: selectedCustomer?.payment_term || '',
                },
              }));
              if (selectedCustomer?.country) {
                setIsClientCountryDisabled(true);
              } else {
                setIsClientCountryDisabled(false);
              }
              if (selectedCustomer?.address) {
                setIsClientAddressDisabled(true);
              } else {
                setIsClientAddressDisabled(false);
              }
              if (selectedCustomer?.tax_reg_number) {
                setIsTaxRegNumberDisabled(true);
              } else {
                setIsTaxRegNumberDisabled(false);
              }
            }}
            isLoading={customerAbbreviationsQueries.isLoading}
            isError={submissionFormValidation.client_name?.is_error ?? false}
            error={submissionFormValidation.client_name?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Client Country */}
          <Select
            label="Client Country"
            required={true}
            disabled={isClientCountryDisabled}
            value={submissionFormCustomer.gcp.client_country ?? ''}
            options={countryCodeAppOptionsQueries.data}
            placeholder="Select a country"
            onChange={(newValue) =>
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_country: newValue,
                },
              }))
            }
            isLoading={countryCodeAppOptionsQueries.isLoading}
            isError={submissionFormValidation.client_country?.is_error ?? false}
            error={submissionFormValidation.client_country?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Tex Reg Number (統一編號) */}
          <Field
            label={taxRegNumberFieldLabel(submissionFormCustomer.gcp.client_country ?? '')}
            required={isTaxRegNumberFieldRequired(submissionFormCustomer.gcp.client_country ?? '')}
            value={submissionFormCustomer.gcp.tax_reg_number ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  tax_reg_number: newValue,
                },
              }));
            }}
            placeholder={`Enter a ${taxRegNumberFieldLabel(
              submissionFormCustomer.gcp.client_country ?? ''
            )}`}
            disabled={
              (!isTaxRegNumberFieldRequired(submissionFormCustomer.gcp.client_country ?? '') &&
                !isTaxRegNumberFieldOptional(submissionFormCustomer.gcp.client_country ?? '')) ||
              isTaxRegNumberDisabled
            }
            isError={submissionFormValidation.tax_reg_number?.is_error ?? false}
            error={submissionFormValidation.tax_reg_number?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(100%)' }}>
          {/* Address */}
          <Field
            label="Address"
            required={true}
            disabled={isClientAddressDisabled}
            value={submissionFormCustomer.gcp.client_address ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, client_address: newValue },
              }));
            }}
            placeholder="Enter an address"
            isError={submissionFormValidation.client_address?.is_error ?? false}
            error={submissionFormValidation.client_address?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Attention To */}
          <Field
            label="Attention To"
            required={true}
            value={submissionFormCustomer.gcp.client_attention_to ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_attention_to: newValue,
                },
              }));
            }}
            placeholder="Enter an name"
            isError={submissionFormValidation.client_attention_to?.is_error ?? false}
            error={submissionFormValidation.client_attention_to?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Email */}
          <Field
            label="Email"
            required={true}
            value={submissionFormCustomer.gcp.client_email ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_email: newValue,
                },
              }));
            }}
            placeholder="Enter an email"
            isError={submissionFormValidation.client_email?.is_error ?? false}
            error={submissionFormValidation.client_email?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)', display: 'flex' }}>
          {/* Phone Number */}
          <ComboField
            mode="easy"
            label="Phone Number"
            selectPlaceholder="(TW) +886"
            fieldPlaceholder="Enter a phone number"
            selectValue={submissionFormCustomer.gcp.client_country_calling_codes ?? ''}
            fieldValue={submissionFormCustomer.gcp.client_phone_number ?? ''}
            isFieldError={submissionFormValidation.client_phone_number?.is_error ?? undefined}
            fieldError={submissionFormValidation.client_phone_number?.error ?? undefined}
            onSelectChange={(newValue) =>
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_country_calling_codes: newValue,
                },
              }))
            }
            onFieldChange={(newValue) =>
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  client_phone_number: newValue,
                },
              }))
            }
            selectOptions={dialCodeAppOptionsQueries.data ?? []}
            isSelectError={
              submissionFormValidation.client_country_calling_codes?.is_error ?? undefined
            }
            selectError={submissionFormValidation.client_country_calling_codes?.error ?? undefined}
            isSelectOptionsLoading={dialCodeAppOptionsQueries.isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GcpCustomerGeneral;
