import { AwarenessType } from '../types/constants';
import { SpecialDealSubject, SpecialDealType } from '../types/application/constants';
import PopupSuccessIcon from '../assets/images/icon-popup-success.svg';
import PopupSuccessBlueIcon from '../assets/images/icon-popup-success-blue.svg';
import PopupWaitIcon from '../assets/images/icon-popup-wait.svg';
import PopupRejectIcon from '../assets/images/icon-popup-reject.svg';
import IllustWorkflowGCP from '../assets/images/illust-workflow-gcp.png';
import IllustWorkflowPLPS from '../assets/images/illust-workflow-plps.png';

export const getAwarenessVariant = (options: {
  awarenessType: AwarenessType;
  urgentLevel?: number;
  expiredCode?: number;
  isPayment?: boolean;
}) => {
  switch (true) {
    case options.awarenessType === 'urgent' && options.urgentLevel === 1:
      return {
        icon: 'error',
        wording: 'Urgent Review',
        backgroundColor: '$warning10',
        color: '$warning90',
      };
    case options.awarenessType === 'urgent' && options.urgentLevel === 2:
      return {
        icon: 'hourglass',
        wording: 'Urgent Approval',
        backgroundColor: '$error10',
        color: '$error60',
      };
    case options.awarenessType === 'expired' && options.expiredCode !== 0:
      const expiredWording =
        options.expiredCode === 4
          ? 'Expired Contract'
          : `Expiring in ${options.expiredCode} ${options.expiredCode === 1 ? `day` : `days`}`;
      return {
        icon: 'warning',
        wording: expiredWording,
        backgroundColor: '$error10',
        color: '$error60',
      };
    case options.awarenessType === 'payment':
      return {
        icon: 'calendar_clock',
        wording: 'Payment Terms',
        backgroundColor: '$warning10',
        color: '$warning90',
      };
    default:
      return null;
  }
};

export const getApproverResultVariant = (statusCode: number) => {
  switch (statusCode) {
    case -1:
      return {
        icon: PopupRejectIcon,
        color: '$error60',
        title: 'Rejected',
        desc: 'This application has been rejected.',
      };
    case 3:
      return {
        icon: PopupSuccessIcon,
        color: '$success70',
        title: 'Approved',
        desc: 'This application has been approved.',
      };
    case 3.1:
      return {
        icon: PopupWaitIcon,
        color: '$error60',
        title: 'Made this application as urgent',
        desc: 'This application has been urgently approved for review.',
      };
    case 3.2:
      return {
        icon: PopupSuccessBlueIcon,
        color: '$primary50',
        title: 'Made this application as general',
        desc: 'This application has been generally approved for review.',
      };
    case 3.3:
      return {
        icon: PopupSuccessIcon,
        color: '$success70',
        title: 'Completed',
        desc: 'This application has been signed.',
      };
    default:
      return {
        icon: '',
        color: '$transparent',
        title: '-',
        desc: '-',
      };
  }
};

export const getApprovalProgressCardVariant = (approverStatusCode: number) => {
  switch (true) {
    case approverStatusCode === -1:
      return 'rejected';
    case approverStatusCode === 0:
      return 'pending';
    case approverStatusCode === 1:
      return 'pending';
    case approverStatusCode === 2:
      return 'processing';
    case Math.floor(approverStatusCode) === 3:
      return 'approved';
    default:
      return 'pending';
  }
};

export const getApprovalProgressCardIcon = (approverStatusCode: number) => {
  switch (true) {
    case approverStatusCode === -1:
      return 'close';
    case Math.floor(approverStatusCode) === 3:
      return 'check';
    default:
      return '';
  }
};

export const getApprovalConditionVariant = (conditionType: string) => {
  switch (conditionType) {
    case 'payment_term':
      return { iconName: 'calendar_clock', color: '$primary50' };
    case 'official_pricing_discount_rate':
      return { iconName: 'mintmark', color: '$primary50' };
    case 'cross_border_transactions':
      return { iconName: 'language', color: '$primary50' };
    case 'seal_flow_process':
      return { iconName: 'approval', color: '$primary50' };
    case 'gft':
      return { iconName: 'monetization_on', color: '$primary50' };
    case 'urgent':
      return { iconName: 'acute', color: '$error60' };
    default:
      return { iconName: '', color: '$primary50' };
  }
};

export const getInitSpecialDealYearlyValues = () => {
  return {
    '1st': undefined,
    '2nd': undefined,
    '3rd': undefined,
    '4th': undefined,
    '5th': undefined,
  };
};

export const getSpecialDealSubjectEntity = (options: { subject: SpecialDealSubject }) => {
  return {
    total_value: undefined,
    currency: {
      name: `${options.subject === 'cm_to_google' ? 'USD' : ''}`,
      value: `${options.subject === 'cm_to_google' ? 'USD' : ''}`,
    },
    yearly_values: getInitSpecialDealYearlyValues(),
  };
};

export const getInitSpecialDeal = (options: { deal: SpecialDealType }) => {
  switch (options.deal) {
    case 'cm_b2b_lite':
      return {
        period: { name: '12 months', value: 12 },
        customer_to_cm: getSpecialDealSubjectEntity({ subject: 'customer_to_cm' }),
      };
    // case 'flex':
    //   return {
    //     period: { name: '12 months', value: 12 },
    //     cm_to_google: {
    //       currency: { name: 'USD', value: 'USD' },
    //       milestone_credit_pairs: [{ milestone: undefined, credit: undefined }],
    //     },
    //     customer_to_cm: {
    //       currency: { name: '', value: '' },
    //       milestone: undefined,
    //       credit: undefined,
    //     },
    //   };
    case 'flex':
      return {
        period: { name: '12 months', value: 12 },
        cm_to_google_currency: { name: 'USD', value: 'USD' },
        customer_to_cm_currency: { name: '', value: '' },
        milestone_credit_pairs: [
          {
            cm_to_google_milestone: undefined,
            cm_to_google_credit: undefined,
            customer_to_cm_milestone: undefined,
            customer_to_cm_credit: undefined,
          },
        ],
      };
    default:
      return {
        period: { name: '12 months', value: 12 },
        cm_to_google: getSpecialDealSubjectEntity({ subject: 'cm_to_google' }),
        customer_to_cm: getSpecialDealSubjectEntity({ subject: 'customer_to_cm' }),
      };
  }
};

export const getWorkspacePlanVariant = (options: { planValue: string }) => {
  switch (options.planValue) {
    case 'annual_pay_by_year':
      return 'turquoise';
    case 'flexible_pay_by_month':
      return 'purple';
    default:
      return 'sky';
  }
};

export const getInitDomainInfo = (options?: {
  initPUPPFromGoogleCurrency?: { name: string; value: string };
}) => {
  return {
    domain_name: '',
    has_signed_domain_quotation: 'no' as 'yes' | 'no',
    workspace_edition_and_plan: { name: '', value: '' },
    workspace_edition: { name: '', value: '' },
    workspace_plan: { name: '', value: '' },
    subscription_term: undefined,
    seats: undefined,
    special_price_from_google: undefined,
    google_pupp_type: { name: '', value: '' },
    google_pupp_value: undefined,
    google_pupp_currency: options?.initPUPPFromGoogleCurrency || { name: 'USD', value: 'USD' },
  };
};

export const getProductChipColor = (options: { product: string }) => {
  switch (options.product) {
    case 'gcp':
      return 'sky';
    case 'plps':
      return 'turquoise';
    case 'gws':
      return 'tangerine';
    default:
      return 'sky';
  }
};

export const getCreateAppWorkflow = (options: { product: string }) => {
  switch (options.product) {
    case 'plps':
      return { title: 'The workflow for PLPS document application :', image: IllustWorkflowPLPS };
    default:
      return { title: 'The workflow for document application :', image: IllustWorkflowGCP };
  }
};
