import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DropdownOption } from '../../../../../../types';
import { PostSimpGWSStdAppPayload } from '../../../../../../types/application/simplified/create';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../../../store/atoms';
import { curSubmissionStepAtom } from '../../../../../../store/application/atoms';
import {
  postSimpGWSStdAppPayloadAtom,
  postSimpStdAppAttPayloadAtom,
} from '../../../../../../store/application/create/simplified/atoms';
import { yesNoOptions } from '../../../../../../store/constants';
import { getPUPPUnit } from '../../../../../../utils/get-wording';
import {
  useGetCurrencyOptions,
  useGetOfficialPricingDiscountOptions,
  useGetPaymentTermOptions,
  useGetSupportPlanOptions,
  useGetWorkspaceEditionOptions,
  useGetWorkspacePlanOptions,
  usePostPUPP,
} from '../../../../../../services/application/simplified-standard/dropdown.api';
import { scrollToFirstError } from '../../../../../../utils/scroll';
import { motion } from 'framer-motion';
import Box from '../../../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../../../components/typography/text';
import { Heading5 } from '../../../../../../components/typography/heading';
import Spacer from '../../../../../../components/surface/spacer';
import ColorfulChip from '../../../../../../components/chip/colorful-chip';
import RadioGroup from '../../../../../../components/field/radio-group';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../../../components/helper/dialog-alert';
import Button from '../../../../../../components/button/button';
import MSymbol from '../../../../../../components/icon/m-symbol';
import Combobox from '../../../../../../components/dropdown/combobox';
import Label from '../../../../../../components/typography/label';
import TextInputArea from '../../../../../../components/field/text-input-area';
import TextInput from '../../../../../../components/field/text-input';
import ChipSelect from '../../../../../../components/dropdown/chip-select';
import Spinner from '../../../../../../components/helper/spinner';
import WizardBar from '../../../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../../../components/button/service-item-button';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../../../../styles/motions';
import LogoGoogleWorkspace from '../../../../../../assets/images/logo-google-workspace.svg';
import { usePostSimpStdApp } from '../../../../../../services/application/simplified-standard/app.api';
import { usePostSimpStdAppAtt } from '../../../../../../services/application/simplified-standard/attachment.api';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  workspace_edition: Yup.object().shape({
    value: Yup.string().required(),
  }),
  workspace_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  payment_term: Yup.object().shape({
    value: Yup.number()
      .typeError('Must specify a number')
      .moreThan(0, 'Must be a positive number')
      .integer('Must be an integer')
      .required('This field is required'),
  }),
  support_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  official_pricing_discount_type: Yup.object().shape({
    value: Yup.string().required(),
  }),
  official_pricing_discount_value: Yup.number()
    .typeError('Must specify a number')
    .test('official_pricing_discount_value', '', (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      if (curOfficialPricingDiscountType === 'Percentage discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            parseFloat(value.toString()) < 10 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number < 10, up to 2 decimal places` })
        );
      } else if (curOfficialPricingDiscountType === 'Fixed amount discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number, up to 2 decimal places` })
        );
      } else {
        return true;
      }
    }),
  official_pricing_discount_currency: Yup.object().shape({
    value: Yup.string().test(
      'official_pricing_discount_currency',
      'This field is required',
      (value, context) => {
        const curOfficialPricingDiscountType =
          context.from?.[1].value.official_pricing_discount_type.value;
        return curOfficialPricingDiscountType === 'No discount' || value !== '';
      }
    ),
  }),
  estimated_mrr: Yup.number()
    .typeError('Must specify a number')
    .moreThan(0, 'Must be a positive number')
    .integer('Must be an integer')
    .required('This field is required'),
  per_user_per_period: Yup.number()
    .typeError('Must specify a number')
    .moreThan(-1, 'Must grater than 0')
    .required('This field is required'),
  is_auto_renewal: Yup.string().required(),
  is_issued_form: Yup.string().required(),
  reason_for_discount_rate: Yup.string().test(
    'reason_for_discount_rate',
    'When percentage discount rate >= 5%, this field is required',
    (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      const curOfficialPricingDiscountValue =
        context.from?.[0].value.official_pricing_discount_value;
      return (
        curOfficialPricingDiscountType !== 'Percentage discount' ||
        curOfficialPricingDiscountValue < 5 ||
        value !== ''
      );
    }
  ),
});

const ServiceInfo = () => {
  const navigate = useNavigate();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubmissionStep = useSetAtom(curSubmissionStepAtom);
  const [postSimpStdAppPayload, setPostSimpStdAppPayload] = useAtom(postSimpGWSStdAppPayloadAtom);
  const postSimpStdAppAttPayload = useAtomValue(postSimpStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues, setValue, setError, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postSimpStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const curCMLegalEntity = useWatch({
    control,
    name: 'cm_legal_entity',
  });
  const curCurrency = useWatch({
    control,
    name: 'currency',
  });
  const curExchangeRate = useWatch({
    control,
    name: 'exchange_rate',
  });
  const curWorkspaceEdition = useWatch({
    control,
    name: 'workspace_edition',
  });
  const curWorkspacePlan = useWatch({
    control,
    name: 'workspace_plan',
  });
  const curOfficialPricingDiscountType = useWatch({
    control,
    name: 'official_pricing_discount_type',
  });
  const curOfficialPricingDiscountValue = useWatch({
    control,
    name: 'official_pricing_discount_value',
  });

  const workspaceEditionOptionsQuery = useGetWorkspaceEditionOptions();
  const workspacePlanOptionsQuery = useGetWorkspacePlanOptions({
    workspaceEdition: curWorkspaceEdition.value,
  });
  const paymentTermOptionsQuery = useGetPaymentTermOptions();
  const supportPlanOptionsQuery = useGetSupportPlanOptions({ product: getValues('product') });
  const officialPricingDiscountOptionsQuery = useGetOfficialPricingDiscountOptions();
  const officialPricingDiscountCurrencyOptionsQuery = useGetCurrencyOptions({
    currencyType: 'official_pricing_discount_currencies',
    cmLegalEntity: getValues('cm_legal_entity.value'),
    exchangeRate: getValues('exchange_rate.value'),
  });
  const { mutate: PUPPMutate, ...PUPPMutation } = usePostPUPP();
  const postAppMutation = usePostSimpStdApp();
  const postAttachmentMutation = usePostSimpStdAppAtt({
    onSuccess: (res, appStatus) => {
      postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onWorkspaceEditionChange = (newValue: { name: string; value: DropdownOption['value'] }) => {
    setValue('workspace_edition', newValue, { shouldValidate: true });
    setValue('workspace_plan', { name: '', value: '' }, { shouldValidate: true });
  };
  const onOfficialPricingDiscountTypeChange = (newValue: {
    name: string;
    value: DropdownOption['value'];
  }) => {
    setValue('official_pricing_discount_type', newValue, { shouldValidate: true });
    setValue('official_pricing_discount_value', 0, { shouldValidate: true });
  };
  const onBackClick = () => {
    setPostSimpStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubmissionStep(1);
  };
  const onNextClick: SubmitHandler<PostSimpGWSStdAppPayload> = async (data) => {
    try {
      const PUPPMutationData = await PUPPMutation.mutateAsync({
        workspace_edition: data.workspace_edition,
        workspace_plan: data.workspace_plan,
        official_pricing_discount_type: data.official_pricing_discount_type,
        official_pricing_discount_value: Number(data.official_pricing_discount_value),
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        cm_legal_entity: data.cm_legal_entity,
      });
      if (PUPPMutationData.is_over_ten_percent_off) {
        setError('root.serverError', {
          type: 'per_user_per_period',
          message: `Must > 0, and discount can't exceed 10% of the list price`,
        });
      } else {
        setPostSimpStdAppPayload((prev) => ({ ...prev, ...data }));
        setCurSubmissionStep(3);
      }
    } catch (err) {
      setError('root.serverError', {
        type: 'per_user_per_period',
        message: `Something went wrong`,
      });
    }
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({ ...postSimpStdAppAttPayload, appStatus: 'draft' });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    if (
      officialPricingDiscountCurrencyOptionsQuery.isSuccess &&
      officialPricingDiscountCurrencyOptionsQuery.data.length > 0
    ) {
      if (!getValues('official_pricing_discount_currency.value')) {
        setValue(
          'official_pricing_discount_currency',
          {
            name: officialPricingDiscountCurrencyOptionsQuery.data[0].name,
            value: officialPricingDiscountCurrencyOptionsQuery.data[0].value,
          },
          { shouldValidate: true }
        );
      }
    }
  }, [
    officialPricingDiscountCurrencyOptionsQuery.data,
    officialPricingDiscountCurrencyOptionsQuery.isSuccess,
    getValues,
    setValue,
  ]);

  useEffect(() => {
    if (isNaN(curOfficialPricingDiscountValue)) {
      return;
    } else {
      PUPPMutate({
        workspace_edition: curWorkspaceEdition,
        workspace_plan: curWorkspacePlan,
        official_pricing_discount_type: curOfficialPricingDiscountType,
        official_pricing_discount_value: Number(curOfficialPricingDiscountValue),
        currency: curCurrency,
        exchange_rate: curExchangeRate,
        cm_legal_entity: curCMLegalEntity,
      });
    }
  }, [
    PUPPMutate,
    curCMLegalEntity,
    curCurrency,
    curExchangeRate,
    curOfficialPricingDiscountType,
    curOfficialPricingDiscountValue,
    curWorkspaceEdition,
    curWorkspacePlan,
  ]);

  useEffect(() => {
    if (PUPPMutation.isSuccess && PUPPMutation.data) {
      setValue('per_user_per_period', PUPPMutation.data.per_user_per_period || 0, {
        shouldValidate: true,
      });
    }
  }, [PUPPMutation.data, PUPPMutation.isSuccess, setValue]);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Service */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Service</Heading5>
          <ColorfulChip text="Simplified" color="turquoise" variant="solid" />
        </SubmissionPaperHeader>
        {/* Payment Details */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Payment Details</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoGoogleWorkspace} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Workspace Edition */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="workspace_edition"
                render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Workspace Edition"
                    placeholder="Select a workspace edition"
                    isLoading={workspaceEditionOptionsQuery.isLoading}
                    options={workspaceEditionOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onWorkspaceEditionChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Workspace Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="workspace_plan"
                render={({ field: { value, name, onChange }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    disabled={!curWorkspaceEdition.value}
                    label="Workspace Plan"
                    placeholder="Select a workspace plan"
                    isLoading={workspacePlanOptionsQuery.isLoading}
                    options={workspacePlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Payment Term */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="payment_term"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Payment Term"
                    placeholder="Select a payment term"
                    isLoading={paymentTermOptionsQuery.isLoading}
                    options={paymentTermOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Support Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="support_plan"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Support Plan"
                    placeholder="Select a support plan"
                    isLoading={supportPlanOptionsQuery.isLoading}
                    options={supportPlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Official Pricing Discount */}
            <SubmissionColumn>
              <Label>
                <Caption>
                  Official Pricing Discount
                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                </Caption>
              </Label>
              <SubmissionRow columnGapSize="5">
                {/* Official Pricing Discount Type  */}
                <Box css={{ width: `calc(50% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_type"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        placeholder="Select a discount type"
                        isLoading={officialPricingDiscountOptionsQuery.isLoading}
                        options={officialPricingDiscountOptionsQuery.data || []}
                        optionSize="lg"
                        fieldName={name}
                        value={value}
                        onChange={onOfficialPricingDiscountTypeChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Value  */}
                <Box css={{ width: `calc(30% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_value"
                    render={({
                      field: { value, name, onChange },
                      fieldState: { invalid, error },
                    }) => (
                      <TextInput
                        placeholder="Enter a discount value"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'No discount'}
                        isError={invalid}
                        error={error?.message}
                        endElement={
                          curOfficialPricingDiscountType.value === 'Percentage discount' ? (
                            <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                              %
                            </ButtonText>
                          ) : null
                        }
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Currency */}
                <Box css={{ width: `calc(20% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_currency"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <ChipSelect
                        placeholder="Select a discount currency"
                        isLoading={officialPricingDiscountCurrencyOptionsQuery.isLoading}
                        options={officialPricingDiscountCurrencyOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'No discount'}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
              </SubmissionRow>
            </SubmissionColumn>
            {/* Estimated MRR */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="estimated_mrr"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInput
                    required
                    label="Estimated MRR"
                    placeholder="Enter an estimated MRR"
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                    endElement={
                      <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                        USD
                      </ButtonText>
                    }
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* PUPY / PUPM */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="per_user_per_period"
                render={({
                  field: { ref, onChange, value, name },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <TextInput
                      inputRef={ref}
                      required
                      disabled
                      label="PUPM / PUPY"
                      placeholder="Enter a PUPM / PUPY"
                      fieldName={name}
                      value={value}
                      onChange={onChange}
                      isError={
                        invalid || formState.errors.root?.serverError.type === 'per_user_per_period'
                      }
                      error={error?.message || formState.errors.root?.serverError.message}
                      endElement={
                        <>
                          {PUPPMutation.isLoading ? <Spinner size="mini" /> : null}
                          <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                            {getPUPPUnit({ workspacePlan: curWorkspacePlan.value })}
                          </ButtonText>
                        </>
                      }
                    />
                  );
                }}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Is Auto Renewal ? */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="is_auto_renewal"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Is Auto Renewal ?"
                    value={value}
                    options={yesNoOptions}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Will customer issue PO / LOA / Order Form / other ? */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="is_issued_form"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Will customer issue PO / LOA / Order Form / other ?"
                    value={value}
                    options={yesNoOptions}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Reason for Discount Rate */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="reason_for_discount_rate"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    required={
                      curOfficialPricingDiscountType.value === 'Percentage discount' &&
                      curOfficialPricingDiscountValue >= 5
                    }
                    label="Reason for Discount Rate"
                    placeholder="Let us know if the discount rate is more than 5% and also can leave the necessary verification information."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={
                  curOfficialPricingDiscountType.value === 'Percentage discount' &&
                  curOfficialPricingDiscountValue >= 5
                    ? 'show'
                    : 'hide'
                }>
                <Spacer axis="vertical" css={{ $$size: '8px' }} />
                <Box
                  css={{
                    borderRadius: 6,
                    border: '1px solid $warning60',
                    backgroundColor: '$warning10',
                    px: 20,
                    py: 4,
                  }}>
                  <ButtonText size={12} css={{ color: '$warning90' }}>
                    If the discount rate exceeds or equal to 5%, it should be reviewed by the GM
                    initially. If it surpasses 10%, the CEO will need to review it.
                  </ButtonText>
                </Box>
              </Box>
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button size="md" variant="borderless" color="achromatic" css={{ mr: 'auto' }}>
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                      onClick={() => onLeaveClick()}>
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  <Button
                    variant="outlined"
                    size="md"
                    onClick={() => onSaveClick()}
                    isLoading={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    css={{ width: 123 }}>
                    <ButtonText size={14} bold>
                      Save as draft
                    </ButtonText>
                  </Button>
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}>
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button size="md" variant="outlined" onClick={() => onBackClick()}>
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md" disabled={PUPPMutation.isLoading}>
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default ServiceInfo;
