import { AppStatus } from '../../../../types';
import { useGetAppDetailDocTab } from '../../../../services/application/simplified-standard/app-detail-tabs.api';
import { CenteredBoxAbsolute, CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import Spacer from '../../../../components/surface/spacer';
import { Text } from '../../../../components/typography/text';
import EmptyApplicationBG from '../../../../assets/images/bg-empty-application.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import EmptyApplicationProcessedBG from '../../../../assets/images/bg-empty-application-processed.svg';

export interface AppDetailDocumentTabProps {
  appId: string;
  googleDocId: string;
  appStatus: AppStatus | '';
}

const DocumentTab = ({ appId, googleDocId, appStatus }: AppDetailDocumentTabProps) => {
  const appDetailDocTabQuery = useGetAppDetailDocTab({
    appId,
    docId: googleDocId,
  });
  return (
    <>
      {appStatus === 'draft' ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={EmptyApplicationProcessedBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text
            semibold
            css={{ textAlign: 'center' }}>{`The application is still in draft status.`}</Text>
        </CenteredBoxAbsolute>
      ) : (
        <>
          {appDetailDocTabQuery.isLoading ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Spinner size="large" />
            </CenteredBoxAbsolute>
          ) : appDetailDocTabQuery.isSuccess ? (
            appDetailDocTabQuery.data ? (
              <>
                {(() => {
                  switch (appDetailDocTabQuery.data.google_doc_status) {
                    case 'can_not_show':
                      return (
                        <CenteredBoxAbsolute>
                          <CenteredBoxFlex>
                            <Box as="img" src={EmptyApplicationBG} />
                            <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                            <Text
                              semibold
                              css={{
                                textAlign: 'center',
                              }}>{`The application has not completed the approval process; therefore, we cannot provide the required documents.`}</Text>
                          </CenteredBoxFlex>
                        </CenteredBoxAbsolute>
                      );
                    case 'can_show':
                      return (
                        <Box css={{ height: 1920 }}>
                          <Box
                            as="iframe"
                            css={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                              border: 'none',
                            }}
                            src={appDetailDocTabQuery.data.google_doc_url}
                          />
                        </Box>
                      );
                    case 'failed_to_be_generated':
                      return (
                        <CenteredBoxAbsolute>
                          <CenteredBoxFlex>
                            <Box as="img" src={ErrorBG} />
                            <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                            <Text
                              semibold>{`Your Document is failed to be generated, please generate again or contact legal.`}</Text>
                          </CenteredBoxFlex>
                        </CenteredBoxAbsolute>
                      );
                    default:
                      return null;
                  }
                })()}
              </>
            ) : (
              <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
                <Box as="img" src={EmptyApplicationBG} />
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                <Text semibold css={{ textAlign: 'center' }}>{`No Matching Application`}</Text>
              </CenteredBoxAbsolute>
            )
          ) : appDetailDocTabQuery.isError ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Box as="img" src={ErrorBG} />
              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
              <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
            </CenteredBoxAbsolute>
          ) : null}
        </>
      )}
    </>
  );
};

export default DocumentTab;
