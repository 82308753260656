import * as RadixTabs from '@radix-ui/react-tabs';
import { styled } from '../../styles/stitches.config';

export const AppDetailTabsContainer = styled('div', {
  width: '100%',
  flexGrow: 1,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'column',
  pl: 16,
  pr: 66,
  pt: 18,
});
export const AppDetailTabsRoot = styled(RadixTabs.Root, {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: '$white',
});
export const AppDetailTabsList = styled(RadixTabs.List, {
  height: '40px',
  backgroundColor: '$white',
  boxShadow: '$basic',
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: '40px',
  px: '$5',
});
export const AppDetailTabsTrigger = styled(RadixTabs.Trigger, {
  border: 'none',
  backgroundColor: '$white',
  pb: 0,
  color: '$text-secondary',
  cursor: 'pointer',
  '&:after': {
    content: '""',
    height: 4,
    display: 'block',
    mt: '5px',
    backgroundColor: '$transparent',
    borderTopLeftRadius: '$5',
    borderTopRightRadius: '$5',
    borderBottomLeftRadius: '$5',
    borderBottomRightRadius: '$5',
  },
  '&[data-disabled]': {
    color: '$secondary20',
    cursor: 'not-allowed',
  },
  '&[data-state=active]': {
    color: '$primary50',
    fontWeight: '$bold',
    '&:after': {
      backgroundColor: '$primary50',
    },
  },
});
export const AppDetailTabsContent = styled(RadixTabs.Content, {
  p: 0,
  height: '100%',
  flexGrow: 1,
  position: 'relative',
  variants: {
    size: {
      fixed: {
        height: 1920,
      },
      fill: {
        height: '100%',
      },
    },
  },
});
