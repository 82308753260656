import type { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { authUserAtom } from '../store/atoms';

const PrivateRoute = ({ children }: { children: ReactNode }): JSX.Element => {
  const location = useLocation();
  const authUser = useAtomValue(authUserAtom);
  const isLoggedIn: boolean = authUser !== null;

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};

export default PrivateRoute;
