import type {
  GetSettingsPersonalMyProfileResult,
  GetSettingsPersonalOrganizationResult,
  GetSettingsPersonalNotificationsResult,
  PutSettingsPersonalNotificationsPayload,
} from '../types';
import { useAxios } from '../hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../store/atoms';
import { AxiosError } from 'axios';

export const useGetSettingsPersonalMyProfile = () => {
  const axios = useAxios();
  return useQuery<GetSettingsPersonalMyProfileResult, Error>(
    ['settings', 'personal', 'my-profile'],
    async () => {
      const { data } = await axios.get(`/v1/user_setting/personal`, {
        params: {
          tab: 'my_profile',
        },
      });
      return data;
    }
  );
};

export const usePutSettingsPersonalMyProfile = (onMutationSuccess?: () => void) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({ contact_email }: { contact_email: string }) => {
      const { data } = await axios.put(`/v1/user_setting/personal`, {
        contact_email,
      });
      return data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['settings', 'personal', 'my-profile']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetSettingsPersonalOrganization = () => {
  const axios = useAxios();
  return useQuery<GetSettingsPersonalOrganizationResult, Error>(
    ['settings', 'personal', 'organization'],
    async () => {
      const { data } = await axios.get(`/v1/user_setting/personal`, {
        params: {
          tab: 'organization',
        },
      });
      return data;
    }
  );
};

export const usePutSettingsPersonalOrganization = (onMutationSuccess?: () => void) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({ job_title }: { job_title: string }) => {
      const { data } = await axios.put(`/v1/user_setting/personal`, {
        job_title,
      });
      return data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['settings', 'personal', 'organization']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetSettingsPersonalNotifications = () => {
  const axios = useAxios();
  return useQuery<GetSettingsPersonalNotificationsResult, Error>(
    ['settings', 'personal', 'notifications'],
    async () => {
      const { data } = await axios.get(`/v1/user_setting/personal`, {
        params: {
          tab: 'notification',
        },
      });
      return data;
    }
  );
};

export const usePutSettingsPersonalNotifications = (onMutationSuccess?: () => void) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async (requestBody: PutSettingsPersonalNotificationsPayload) => {
      const { data } = await axios.put(`/v1/user_setting/personal`, requestBody);
      return data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['settings', 'personal', 'notifications']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};
