import { motion } from 'framer-motion';
import Box from '../box';
import Typography from './deprecated-typography';

const InputErrorMessage = ({
  isError,
  errorMessage,
  customHeight,
}: {
  isError?: boolean;
  errorMessage?: string;
  customHeight?: number;
}) => {
  return (
    <Box
      as={motion.div}
      css={{
        cursor: isError && errorMessage ? 'auto' : 'default',
      }}
      initial={{ height: '0px', opacity: 0 }}
      animate={isError && errorMessage ? 'expand' : 'collapse'}
      variants={{
        expand: {
          height: customHeight ? customHeight : '100%',
          opacity: 1,
        },
        collapse: {
          height: '0px',
          opacity: 0,
        },
      }}>
      <Typography
        variant="caption"
        css={{
          color: '$error60',
          wordBreak: 'break-word',
        }}>{`${errorMessage ?? ''}`}</Typography>
    </Box>
  );
};

export default InputErrorMessage;
