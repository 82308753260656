import { ComponentProps, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import MSymbol from '../icon/m-symbol';
import Spinner from '../helper/spinner';
import Box from '../box';

const StyledButton = styled('button', {
  borderWidth: 1,
  borderStyle: 'solid',
  cursor: 'pointer',
  position: 'relative',
  transition: '$colors',
  display: 'block',
  variants: {
    color: {
      primary: {},
      secondary: {},
      'secondary-primary': {},
      error: {},
      achromatic: {},
    },
    variant: {
      solid: {},
      borderless: {},
      outlined: {},
    },
    shape: {
      rounded: {
        borderRadius: '$rounded',
      },
      squared: {
        borderRadius: '$5',
      },
    },
    size: {
      micro: {
        width: 16,
        height: 16,
        fontSize: 12,
      },
      mini: {
        width: 20,
        height: 20,
        fontSize: 16,
      },
      tiny: {
        width: 24,
        height: 24,
        fontSize: 18,
      },
      small: {
        width: 30,
        height: 30,
        fontSize: 24,
      },
      medium: {
        width: 36,
        height: 36,
        fontSize: 30,
      },
    },
    glow: {
      true: {},
    },
  },
  compoundVariants: [
    {
      color: 'achromatic',
      variant: 'solid',
      css: {
        color: '$white',
        backgroundColor: '$achromatic20',
        border: 'none',
        '&:hover': {
          backgroundColor: '$achromatic10',
        },
        '&:active, &[data-state=open], &[data-state=active]': {
          backgroundColor: '$achromatic30',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'borderless',
      glow: false,
      css: {
        color: '$secondary70',
        borderColor: '$transparent',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$secondary60',
          backgroundColor: '$secondary3',
        },
        '&:active': {
          color: '$secondary80',
          backgroundColor: '$secondary10',
        },
        '&[data-state=open]': {
          color: '$secondary80',
          backgroundColor: '$secondary10',
        },
      },
    },
    {
      color: 'secondary',
      variant: 'borderless',
      glow: true,
      css: {
        color: '$secondary80',
        borderColor: '$transparent',
        backgroundColor: '$secondary10',
      },
    },
    {
      color: 'secondary-primary',
      variant: 'solid',
      glow: false,
      css: {
        color: '$white',
        borderColor: '$secondary70',
        backgroundColor: '$secondary70',
        '&:hover': {
          color: '$white',
          borderColor: '$primary40',
          backgroundColor: '$primary40',
        },
        '&:active': {
          color: '$white',
          borderColor: '$primary60',
          backgroundColor: '$primary60',
        },
        '&[data-state=open]': {
          color: '$white',
          borderColor: '$primary60',
          backgroundColor: '$primary60',
        },
      },
    },
    {
      color: 'secondary-primary',
      variant: 'solid',
      glow: true,
      css: {
        color: '$white',
        borderColor: '$primary60',
        backgroundColor: '$primary60',
      },
    },
    {
      color: 'secondary-primary',
      variant: 'borderless',
      glow: false,
      css: {
        color: '$secondary70',
        borderColor: '$transparent',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          borderColor: '$primary10',
          backgroundColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          borderColor: '$primary20',
          backgroundColor: '$primary20',
        },
        '&[data-state=open]': {
          color: '$primary60',
          borderColor: '$primary20',
          backgroundColor: '$primary20',
        },
      },
    },
    {
      color: 'secondary-primary',
      variant: 'borderless',
      glow: true,
      css: {
        color: '$primary60',
        borderColor: '$primary20',
        backgroundColor: '$primary20',
      },
    },
    {
      color: 'secondary-primary',
      variant: 'outlined',
      glow: false,
      css: {
        color: '$secondary70',
        borderColor: '$secondary70',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          borderColor: '$primary50',
          backgroundColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          borderColor: '$primary60',
          backgroundColor: '$primary20',
        },
        '&[data-state=open]': {
          color: '$primary60',
          borderColor: '$primary60',
          backgroundColor: '$primary20',
        },
      },
    },
    {
      color: 'secondary-primary',
      variant: 'outlined',
      glow: true,
      css: {
        color: '$primary60',
        borderColor: '$primary60',
        backgroundColor: '$primary20',
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      glow: false,
      css: {
        color: '$primary50',
        borderColor: '$primary50',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          borderColor: '$primary50',
          backgroundColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          borderColor: '$primary60',
          backgroundColor: '$primary20',
        },
        '&[data-state=open]': {
          color: '$primary60',
          borderColor: '$primary60',
          backgroundColor: '$primary20',
        },
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      glow: true,
      css: {
        color: '$primary60',
        borderColor: '$primary60',
        backgroundColor: '$primary20',
      },
    },
    {
      color: 'primary',
      variant: 'borderless',
      glow: false,
      css: {
        color: '$primary50',
        borderColor: '$transparent',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
        },
        '&:active': {
          color: '$primary60',
          backgroundColor: '$primary20',
        },
        '&[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
        },
      },
    },
    {
      color: 'primary',
      variant: 'borderless',
      glow: true,
      css: {
        color: '$secondary60',
        borderColor: '$transparent',
        backgroundColor: '$secondary20',
      },
    },
    {
      color: 'error',
      variant: 'outlined',
      glow: false,
      css: {
        color: '$error50',
        borderColor: '$error50',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$error40',
          borderColor: '$error50',
          backgroundColor: '$error10',
        },
        '&:active': {
          color: '$error60',
          borderColor: '$error60',
          backgroundColor: '$error20',
        },
        '&[data-state=open]': {
          color: '$error60',
          borderColor: '$error60',
          backgroundColor: '$error20',
        },
      },
    },
    {
      color: 'error',
      variant: 'outlined',
      glow: true,
      css: {
        color: '$error60',
        borderColor: '$error60',
        backgroundColor: '$error20',
      },
    },
    {
      color: 'error',
      variant: 'borderless',
      glow: false,
      css: {
        color: '$error50',
        borderColor: '$transparent',
        backgroundColor: '$transparent',
        '&:hover': {
          color: '$error40',
          backgroundColor: '$error10',
        },
        '&:active': {
          color: '$error60',
          backgroundColor: '$error20',
        },
        '&[data-state=open]': {
          color: '$error60',
          backgroundColor: '$error20',
        },
      },
    },
    {
      color: 'error',
      variant: 'borderless',
      glow: true,
      css: {
        color: '$secondary60',
        borderColor: '$transparent',
        backgroundColor: '$secondary20',
      },
    },
  ],
  defaultVariants: {
    size: 'tiny',
    color: 'secondary-primary',
    shape: 'rounded',
    variant: 'borderless',
    glow: false,
  },
});

interface NewIconButtonProps extends ComponentProps<typeof StyledButton> {
  iconName: string;
  isIconFill?: boolean;
  isIconBold?: boolean;
  isLoading?: boolean;
}

const IconButton = forwardRef<HTMLButtonElement, NewIconButtonProps>(
  ({ iconName, isIconFill = true, isIconBold, isLoading, ...restProps }, ref) => {
    return (
      <StyledButton ref={ref} {...restProps}>
        {isLoading ? (
          <Box css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner spinnerColor="secondary" size="mini" />
          </Box>
        ) : (
          <MSymbol
            iconName={`${iconName}`}
            fill={isIconFill}
            weight={isIconBold ? 700 : 500}
            css={{
              color: 'inherit',
              fontSize: 'inherit !important',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </StyledButton>
    );
  }
);

IconButton.displayName = 'IconButton';
export default IconButton;
