import type { Axios, AxiosRequestConfig, AxiosError } from 'axios';
import axios from 'axios';
import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store/atoms';

const useAxios = () => {
  const [authUser, setAuthUser] = useAtom(authUserAtom);
  const { access_token: accessToken } = authUser || {};

  const axiosClient: Axios = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        // Refresh Token 範例，請酌情修改
        // if (accessToken && expiredIn && Date.now() >= expiredIn * 1000) {
        //   const { data, status } = await axios.post('/refresh-token', {}, config);
        //   if (config.headers && status === 200) {
        //     setAuthUser(data);
        //     config.headers.Authorization = `Bearer ${data.access_token}`;
        //   }
        // }

        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      }
    );

    axiosInstance.interceptors.response.use(undefined, (error: AxiosError): Promise<AxiosError> => {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        setAuthUser(null);
      }
      return Promise.reject(error);
    });
    return axiosInstance;
  }, [accessToken, setAuthUser]);

  return axiosClient;
};

export default useAxios;
