import {
  useGetSettingsPersonalNotifications,
  usePutSettingsPersonalNotifications,
} from '../../../services/settings.api';
import { settingsNotificationsSharedOptions } from '../../../store/constants';
import Box from '../../../components/box';
import Typography from '../../../components/typography/deprecated-typography';
import Spacer from '../../../components/surface/spacer';
import Spinner from '../../../components/helper/spinner';
import Button from '../../../components/button/deprecated-button';
import Divider from '../../../components/surface/divider';
import { SwitchRoot, SwitchThumb } from '../../../components/button/switch';
import ErrorBG from '../../../assets/images/bg-error.svg';

const Notifications = () => {
  const notificationsQueries = useGetSettingsPersonalNotifications();
  const notificationsMutate = usePutSettingsPersonalNotifications();
  return (
    <>
      <Box css={{ py: 5 }}>
        <Typography variant={'h4'}>{`Notifications`}</Typography>
        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
        <Typography variant={'body'} css={{ color: '$secondary70' }}>
          {`We may still send you important notifications about your account and content outside of your preferred notification settings`}
        </Typography>
      </Box>
      <Spacer axis={'vertical'} css={{ $$size: '15px' }} />
      {notificationsQueries.isLoading ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Spinner size={'large'} />
        </Box>
      ) : null}
      {notificationsQueries.isError ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Typography variant="h6">{`Something's gone wrong`}</Typography>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Typography variant="body-medium">
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Typography>
          </Box>
          <Button
            variant="solid"
            size={'small'}
            onClick={() => notificationsQueries.refetch()}>{`Refresh`}</Button>
        </Box>
      ) : null}
      {notificationsQueries.isSuccess ? (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
          }}>
          {/* Paper */}
          <Box
            css={{
              width: '100%',
              maxWidth: 664,
              borderRadius: '$10',
              p: 20,
              backgroundColor: '$white',
              borderStyle: 'solid',
              borderColor: '$secondary20',
              borderWidth: '1px',
            }}>
            {/* Email All */}
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 80,
                columnGap: 40,
              }}>
              <Box css={{ flexGrow: 1 }}>
                <Typography
                  as={'p'}
                  variant={'body-bold'}
                  css={{ color: '$primary50' }}>{`Email Notifications`}</Typography>
                <Typography
                  as={'p'}
                  variant={'body'}
                  css={{
                    color: '$secondary70',
                  }}>{`Get email to find out what’s going on when you’re not online . You can turn these off`}</Typography>
              </Box>
              <Box css={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  as={'p'}
                  variant={'body-bold'}
                  css={{
                    whiteSpace: 'nowrap',
                    color: notificationsQueries.data.is_enabled_email_all_notification
                      ? '$secondary90'
                      : '$secondary70',
                    visibility: !notificationsQueries.data.is_enabled_email_all_notification
                      ? 'hidden'
                      : 'revert',
                    opacity: !notificationsQueries.data.is_enabled_email_all_notification ? 0 : 1,
                    transition: 'opacity 0.2 linear',
                  }}>
                  {`Allow all`}
                </Typography>
                <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                <SwitchRoot
                  checked={notificationsQueries.data.is_enabled_email_all_notification || false}
                  onCheckedChange={(checked) =>
                    notificationsMutate.mutate({
                      is_enabled_email_all_notification: checked,
                    })
                  }>
                  <SwitchThumb />
                </SwitchRoot>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ $$size: '100%', backgroundColor: '$secondary20', my: '$2' }}
            />
            {/* Email Notifications Options */}
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
              }}>
              {settingsNotificationsSharedOptions.map((option) => {
                return (
                  <Box
                    key={`email-${option.id}`}
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      minHeight: 70,
                      columnGap: 40,
                    }}>
                    <Box css={{ flexGrow: 1 }}>
                      <Typography as={'p'} variant={'body-bold'}>{`${option.name}`}</Typography>
                      <Typography
                        as={'p'}
                        variant={'body-medium'}
                        css={{ color: '$secondary70' }}>{`${option.desc}`}</Typography>
                    </Box>
                    <Box>
                      <SwitchRoot
                        id={`email-switch-${option.id}`}
                        checked={
                          notificationsQueries.data[`is_enabled_email_${option.keyName}`] || false
                        }
                        onCheckedChange={(checked) => {
                          notificationsMutate.mutate({
                            [`is_enabled_email_${option.keyName}`]: checked,
                          });
                        }}>
                        <SwitchThumb />
                      </SwitchRoot>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
            {/* System All */}
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 80,
                columnGap: 40,
              }}>
              <Box css={{ flexGrow: 1 }}>
                <Typography
                  as={'p'}
                  variant={'body-bold'}
                  css={{ color: '$primary50' }}>{`App Notifications`}</Typography>
                <Typography
                  as={'p'}
                  variant={'body'}
                  css={{
                    color: '$secondary70',
                  }}>{`Get push notifications in -app to find out what’s going on when you’re not online`}</Typography>
              </Box>
              <Box css={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  as={'p'}
                  variant={'body-bold'}
                  css={{
                    whiteSpace: 'nowrap',
                    color: notificationsQueries.data.is_enabled_system_all_notification
                      ? '$secondary90'
                      : '$secondary70',
                    visibility: !notificationsQueries.data.is_enabled_system_all_notification
                      ? 'hidden'
                      : 'revert',
                    opacity: !notificationsQueries.data.is_enabled_system_all_notification ? 0 : 1,
                    transition: 'opacity 0.2 linear',
                  }}>
                  {`Allow all`}
                </Typography>
                <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                <SwitchRoot
                  checked={notificationsQueries.data.is_enabled_system_all_notification || false}
                  onCheckedChange={(checked) =>
                    notificationsMutate.mutate({
                      is_enabled_system_all_notification: checked,
                    })
                  }>
                  <SwitchThumb />
                </SwitchRoot>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ $$size: '100%', backgroundColor: '$secondary20', my: '$2' }}
            />
            {/* System Notifications Options */}
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
              }}>
              {settingsNotificationsSharedOptions.map((option) => {
                return (
                  <Box
                    key={`system-${option.id}`}
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      minHeight: 70,
                      columnGap: 40,
                    }}>
                    <Box css={{ flexGrow: 1 }}>
                      <Typography as={'p'} variant={'body-bold'}>{`${option.name}`}</Typography>
                      <Typography
                        as={'p'}
                        variant={'body-medium'}
                        css={{ color: '$secondary70' }}>{`${option.desc}`}</Typography>
                    </Box>
                    <Box>
                      <SwitchRoot
                        id={`email-switch-${option.id}`}
                        checked={
                          notificationsQueries.data[`is_enabled_system_${option.keyName}`] || false
                        }
                        onCheckedChange={(checked) => {
                          notificationsMutate.mutate({
                            [`is_enabled_system_${option.keyName}`]: checked,
                          });
                        }}>
                        <SwitchThumb />
                      </SwitchRoot>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default Notifications;
