import { ReactNode, forwardRef, useState } from 'react';
import { motion } from 'framer-motion';
import { styled, css } from '../../styles/stitches.config';
import { DropdownOption, LabelColor } from '../../types/constants';
import {
  CommandRoot,
  CommandEmpty,
  CommandList,
  CommandItem,
  CommandInput,
  CommandSeparator,
} from './cmdk';
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
  PopoverClose,
} from './popover';
import Box from '../box';
import { ButtonText, Caption, Text } from '../typography/text';
import MSymbol from '../icon/m-symbol';
import Spinner from '../helper/spinner';
import Avatar from '../surface/avatar';
import ColorfulChip from '../chip/colorful-chip';
// import IconButton from '../button/icon-button';
import Label from '../typography/label';
import { fadeInOutMotion } from '../../styles/motions';

const PopoverTriggerIconStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'rotate(0deg)',
  transition: '$transform',
  '&[data-state=open]': {
    transform: 'rotate(180deg)',
  },
});

const StyledPopoverTrigger = styled(PopoverTrigger, {
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  width: '100%',
  borderRadius: 5,
  border: 'none',
  backgroundColor: '$white',
  color: '$text-primary',
  pr: 2,
  pl: 8,
  cursor: 'pointer',
  boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
  transition: '$shadow',
  '&:hover': {
    boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
  },
  '&[data-state=open]': {
    boxShadow: `0 0 0 2px var(--colors-primary60)`,
  },
  '&[data-error=true]': {
    color: '$error60',
    boxShadow: `0 0 0 1px var(--colors-error60)`,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: '$text-hint',
    boxShadow: `0 0 0 1px var(--colors-func-disabled-dark)`,
    backgroundColor: '$func-disabled-light',
  },
  variants: {
    size: {
      sm: {
        height: 30,
      },
      md: {
        height: 36,
      },
      lg: {
        height: 44,
      },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const StyledPopoverClose = styled(PopoverClose, {
  px: 10,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  color: '$text-primary',
  cursor: 'pointer',
  backgroundColor: '$white',
  transition: '$colors',
  borderRadius: 5,
  border: 'none',
  '&:hover': {
    backgroundColor: '$func-hover',
  },
  '&:active': {
    backgroundColor: '$func-active',
  },
  '&[data-state=active]': {
    backgroundColor: '$primary20',
  },
  '&:disabled': {
    color: '$func-disabled-dark',
    backgroundColor: '$white',
  },
  variants: {
    size: {
      sm: { minHeight: 40 },
      md: { minHeight: 50 },
      lg: { minHeight: 60 },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});
const StyledPopoverCloseCaption = styled(Caption, {
  display: 'block',
  color: '$text-secondary',
  textAlign: 'left',
  wordBreak: 'break-word',
  '&[data-disabled=true]': {
    color: '$func-disabled-dark',
    '&[data-error=true]': {
      color: '$error60',
    },
  },
});

interface ComboboxProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  options: DropdownOption[];
  value: { name: string; value: DropdownOption['value'] };
  onChange: (newValue: { name: string; value: DropdownOption['value'] }) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
  disabled?: boolean;
  // * <button> cannot appear as a descendant of <button> issue, comment out this prop temporary
  // clearable?: boolean;
  optionSize?: 'sm' | 'md' | 'lg';
  optionIconSize?: number;
  chipColor?: LabelColor;
  triggerStartElement?: ReactNode;
  triggerEndElement?: ReactNode;
}

const Combobox = forwardRef<HTMLDivElement, ComboboxProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value,
      onChange,
      options,
      isLoading,
      // clearable = false,
      isError,
      error,
      disabled,
      optionSize = 'sm',
      optionIconSize,
      chipColor = 'sky',
      triggerStartElement,
      triggerEndElement,
      ...restProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const onCommandValueChange = (hoveredValue: string) => {
      setSelectedValue(hoveredValue);
    };
    const onCommandClick = () => {
      // * Because CMDK automatically turns its value (we feed option.name as its value) into lower case.
      const selectedOption = options?.find(
        (option) =>
          option.value.toString().toLowerCase() ===
          JSON.parse(selectedValue).value.toString().toLowerCase()
      );
      onChange(
        selectedOption
          ? { name: selectedOption.name, value: selectedOption.value }
          : { name: '', value: '' }
      );
      setSearchTerm('');
    };
    // const onClearClick = (e: MouseEvent<HTMLButtonElement>) => {
    //   e.stopPropagation();
    //   onChange({ name: '', value: '' });
    // };
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <PopoverRoot open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <StyledPopoverTrigger name={fieldName} disabled={disabled} data-error={isError}>
            {triggerStartElement ?? null}
            <Box
              css={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'inherit',
                flex: 1,
              }}>
              <Text
                css={{
                  display: 'block',
                  textAlign: 'left',
                  color: value?.value ? 'inherit' : '$text-hint',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}>
                {value?.value ? value.name : placeholder}
              </Text>
            </Box>
            {/* {clearable ? (
              <IconButton
                size="micro"
                color="secondary"
                iconName="close"
                onClick={(e) => onClearClick(e)}
              />
            ) : null} */}
            {triggerEndElement ?? null}
            <Box data-state={isOpen ? 'open' : 'close'} className={PopoverTriggerIconStyle()}>
              <MSymbol
                iconName="arrow_drop_down"
                css={{ color: disabled ? '$func-disabled-dark' : '$text-secondary' }}
              />
            </Box>
          </StyledPopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              sideOffset={4}
              css={{ width: 'var(--radix-popover-trigger-width)', p: 10 }}>
              <CommandRoot
                value={JSON.stringify(value)}
                onValueChange={(hoveredValue) => onCommandValueChange(hoveredValue)}
                onClick={() => onCommandClick()}>
                {options.length > 7 ? (
                  <>
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: 4,
                        borderBottom: '1px solid $achromatic10',
                        p: 10,
                      }}>
                      <CommandInput
                        value={searchTerm}
                        onValueChange={(search) => setSearchTerm(search)}
                        css={{
                          outline: 'none',
                          border: 'none',
                          width: '100%',
                          pr: 8,
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: 1.5,
                        }}
                      />
                      <MSymbol iconName="search" size={20} css={{ color: '$primary60' }} />
                    </Box>
                    <CommandSeparator />
                  </>
                ) : null}
                {isLoading ? (
                  <Box css={{ p: 10 }}>
                    <Spinner />
                  </Box>
                ) : options.length > 0 ? (
                  <CommandList css={{ maxHeight: 280, overflow: 'auto' }}>
                    <CommandEmpty>
                      <ButtonText size={14} css={{ color: '$text-hint' }}>
                        No result
                      </ButtonText>
                    </CommandEmpty>
                    {options.map((option) => (
                      <CommandItem
                        key={option.id}
                        value={JSON.stringify({ name: option.name, value: option.value })}>
                        <StyledPopoverClose
                          data-state={value?.value === option.value ? 'active' : 'inactive'}
                          disabled={option.disabled}
                          size={optionSize}>
                          {option.picture ? (
                            <Avatar name={option.name || '-'} picture={option.picture} size="sm" />
                          ) : null}
                          <Box css={{ flex: 1 }}>
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                              {option.icon ? (
                                <MSymbol iconName={option.icon} size={optionIconSize || 24} />
                              ) : null}
                              <Text
                                css={{
                                  wordBreak: 'break-word',
                                  textAlign: 'left',
                                }}>{`${option.name}`}</Text>
                            </Box>
                            {option.desc ? (
                              <StyledPopoverCloseCaption
                                data-disabled={option.disabled}
                                data-error={
                                  option.is_error
                                }>{`${option.desc}`}</StyledPopoverCloseCaption>
                            ) : null}
                          </Box>
                          {option.chip ? (
                            <ColorfulChip
                              variant="solid"
                              color={option.chip_color || chipColor}
                              text={option.chip}
                            />
                          ) : null}
                        </StyledPopoverClose>
                      </CommandItem>
                    ))}
                  </CommandList>
                ) : (
                  <ButtonText size={14} css={{ color: '$text-hint' }}>
                    No option
                  </ButtonText>
                )}
              </CommandRoot>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
        <Box
          as={motion.div}
          initial={'hide'}
          variants={fadeInOutMotion}
          animate={isError ? 'show' : 'hide'}>
          <Caption css={{ color: '$error60' }}>{error}</Caption>
        </Box>
      </Box>
    );
  }
);

Combobox.displayName = 'Combobox';

export default Combobox;
