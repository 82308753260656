import { styled } from '../../styles/stitches.config';
import { motion } from 'framer-motion';

// * 要記得餵 left 值，跟設定縮放動畫 (navBarWidth, isNavBarOpen, fixedLayoutGrowMotion)
const WizardBar = styled(motion.div, {
  px: 30,
  position: 'fixed',
  bottom: '0',
  right: '0',
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  backgroundColor: '$white',
  boxShadow: '$basic',
});

export default WizardBar;
