import { Fragment } from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { isDealSelected } from '../../../../utils/check-validation';
import {
  AppDetailAttachmentEntity,
  CustomerType,
  SpecialDealMilestoneCreditPairEntity,
} from '../../../../types/application/constants';
import { AppDetailContractRequestTabProps } from './contract-request-tab';
import { useGetAppDetailContractRequestTabGCP } from '../../../../services/application/verified-standard/app-detail-tabs.api';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import { Caption, Text } from '../../../../components/typography/text';
import Spacer from '../../../../components/surface/spacer';
import Disclosure from '../../../../components/surface/disclosure';
import {
  SubmissionColumn,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../components/button/service-item-button';
import FileCard from '../../../../components/card/file-card';
import Avatar from '../../../../components/surface/avatar';
import LogoGoogleCloud from '../../../../assets/images/logo-google-cloud.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';

const ContractRequestTabGCP = ({ product, appId }: AppDetailContractRequestTabProps) => {
  const appDetailContractRequestTabQuery = useGetAppDetailContractRequestTabGCP({
    product,
    appId,
  });
  return (
    <>
      {appDetailContractRequestTabQuery.isLoading ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Spinner size="large" />
        </CenteredBoxAbsolute>
      ) : appDetailContractRequestTabQuery.isSuccess ? (
        <Box
          css={{
            px: 30,
            pb: 30,
            margin: '0 auto',
            mt: 30,
            minWidth: '580px',
            maxWidth: '760px',
            backgroundColor: '$white',
          }}>
          <Disclosure
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    {/* General Info */}
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>CloudMile Details</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoGoogleCloud} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.cm_legal_entity.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={
                                appDetailContractRequestTabQuery.data.cm_attention_to.name || '-'
                              }
                              picture={appDetailContractRequestTabQuery.data.cm_picture || ''}
                              size="sm"
                            />
                            <Text>{`${
                              appDetailContractRequestTabQuery.data.cm_attention_to.name || '-'
                            }`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailContractRequestTabQuery.data.cm_email || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.cm_country_calling_code.name || ''
                          } ${appDetailContractRequestTabQuery.data.cm_phone_number || '-'} ${
                            appDetailContractRequestTabQuery.data.cm_extension_number
                              ? `#${appDetailContractRequestTabQuery.data.cm_extension_number}`
                              : ''
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(appDetailContractRequestTabQuery.data.quotation_validity).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Agreement Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.agreement_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Automatically Renew Term */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Automatically Renew Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.automatically_renew_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailContractRequestTabQuery.data.currency.name || '-'} / ${
                            appDetailContractRequestTabQuery.data.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Agreement Commencement Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Agreement Commencement Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(
                              appDetailContractRequestTabQuery.data.agreement_commencement_date
                            ).format('DD MMM YYYY') || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement End Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Agreement End Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(appDetailContractRequestTabQuery.data.agreement_end_date).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Customer Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Has this customer ever signed a GCP quotation ? */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Has this customer ever signed a GCP quotation ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.customer_type ===
                            ('existed' as CustomerType)
                              ? 'YES'
                              : 'NO'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Name`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_country?.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Tax Reg Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.tax_reg_number || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_address || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_attention_to || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_email || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_country_calling_code
                              .name || ''
                          } ${appDetailContractRequestTabQuery.data.client_phone_number || '-'} ${
                            appDetailContractRequestTabQuery.data.client_extension_number
                              ? `#${appDetailContractRequestTabQuery.data.client_extension_number}`
                              : ''
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            `${appDetailContractRequestTabQuery.data.payment_term.name}` || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.official_pricing_discount_type
                              .name || '-'
                          } / ${
                            appDetailContractRequestTabQuery.data.official_pricing_discount_type
                              .value === 'percentage_discount'
                              ? `${appDetailContractRequestTabQuery.data.official_pricing_discount_value} %`
                              : appDetailContractRequestTabQuery.data.official_pricing_discount_type
                                  .value === 'fixed_amount_discount'
                              ? `${appDetailContractRequestTabQuery.data.official_pricing_discount_value} ${appDetailContractRequestTabQuery.data.official_pricing_discount_currency.name}`
                              : '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.support_plan.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Estimated MRR */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Estimated MRR`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {appDetailContractRequestTabQuery.data.estimated_mrr
                              ? `${`${appDetailContractRequestTabQuery.data.estimated_mrr.toLocaleString()} USD`}`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Will Customer issue PO, LOA, Order Form, other ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Will Customer issue PO, LOA, Order Form, other ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.is_issued_form?.toUpperCase() ||
                            '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal */}
                      {appDetailContractRequestTabQuery.data?.special_deal?.selected_deals?.length >
                      0 ? (
                        <>
                          {isDealSelected({
                            curSelectedDeals:
                              appDetailContractRequestTabQuery.data.special_deal.selected_deals,
                            deal: 'edp',
                          }) ? (
                            <SubmissionRow>
                              {/* EDP */}
                              <SubmissionColumn
                                widthRatio={10}
                                css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                <SubmissionRow>
                                  {/* EDP Name */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{ color: '$secondary40' }}>{`Special Deal`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${'EDP'}`}</Text>
                                  </SubmissionColumn>
                                  {/* EDP Period */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Commitment Period`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${
                                      appDetailContractRequestTabQuery.data.special_deal.edp.period
                                        .name || '-'
                                    }`}</Text>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* EDP C2G Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[CM to Google] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box css={{ maxWidth: 100 }}>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.edp.cm_to_google.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal.edp
                                              .cm_to_google.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal.edp
                                          .cm_to_google.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()} css={{ maxWidth: 100 }}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal.edp
                                                .cm_to_google.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* EDP C2C Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[Customer to CM] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.edp.customer_to_cm.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal.edp
                                              .customer_to_cm.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal.edp
                                          .customer_to_cm.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal.edp
                                                .customer_to_cm.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                              </SubmissionColumn>
                            </SubmissionRow>
                          ) : null}
                          {isDealSelected({
                            curSelectedDeals:
                              appDetailContractRequestTabQuery.data.special_deal.selected_deals,
                            deal: 'cnsp',
                          }) ? (
                            <SubmissionRow>
                              {/* CNSP */}
                              <SubmissionColumn
                                widthRatio={10}
                                css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                <SubmissionRow>
                                  {/* CNSP Name */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{ color: '$secondary40' }}>{`Special Deal`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${'CNSP'}`}</Text>
                                  </SubmissionColumn>
                                  {/* CNSP Period */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Commitment Period`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${
                                      appDetailContractRequestTabQuery.data.special_deal.cnsp.period
                                        .name || '-'
                                    }`}</Text>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* CNSP C2G Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[CM to Google] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box css={{ maxWidth: 100 }}>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.cnsp.cm_to_google.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal.cnsp
                                              .cm_to_google.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal.cnsp
                                          .cm_to_google.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()} css={{ maxWidth: 100 }}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal
                                                .cnsp.cm_to_google.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* CNSP C2C Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[Customer to CM] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.cnsp.customer_to_cm.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal.cnsp
                                              .customer_to_cm.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal.cnsp
                                          .customer_to_cm.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal
                                                .cnsp.customer_to_cm.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                              </SubmissionColumn>
                            </SubmissionRow>
                          ) : null}
                          {isDealSelected({
                            curSelectedDeals:
                              appDetailContractRequestTabQuery.data.special_deal.selected_deals,
                            deal: 'google_full_b2b',
                          }) ? (
                            <SubmissionRow>
                              {/* Google Full B2B */}
                              <SubmissionColumn
                                widthRatio={10}
                                css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                <SubmissionRow>
                                  {/* Google Full B2B Name */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{ color: '$secondary40' }}>{`Special Deal`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${'Google Full B2B'}`}</Text>
                                  </SubmissionColumn>
                                  {/* Google Full B2B Period */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Commitment Period`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${
                                      appDetailContractRequestTabQuery.data.special_deal
                                        .google_full_b2b.period.name || '-'
                                    }`}</Text>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* Google Full B2B C2G Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[CM to Google] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box css={{ maxWidth: 100 }}>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.google_full_b2b.cm_to_google.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal
                                              .google_full_b2b.cm_to_google.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal
                                          .google_full_b2b.cm_to_google.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()} css={{ maxWidth: 100 }}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal
                                                .google_full_b2b.cm_to_google.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* Google Full B2B C2C Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[Customer to CM] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.google_full_b2b.customer_to_cm.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal
                                              .google_full_b2b.customer_to_cm.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal
                                          .google_full_b2b.customer_to_cm.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal
                                                .google_full_b2b.customer_to_cm.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                              </SubmissionColumn>
                            </SubmissionRow>
                          ) : null}
                          {isDealSelected({
                            curSelectedDeals:
                              appDetailContractRequestTabQuery.data.special_deal.selected_deals,
                            deal: 'cm_b2b_lite',
                          }) ? (
                            <SubmissionRow>
                              {/* CM B2B Lite */}
                              <SubmissionColumn
                                widthRatio={10}
                                css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                <SubmissionRow>
                                  {/* CM B2B Lite Name */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{ color: '$secondary40' }}>{`Special Deal`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${'CM B2B Lite'}`}</Text>
                                  </SubmissionColumn>
                                  {/* CM B2B Lite Period */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Commitment Period`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${
                                      appDetailContractRequestTabQuery.data.special_deal.cm_b2b_lite
                                        .period.name || '-'
                                    }`}</Text>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* CM B2B Lite C2C Commitment Amount */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`[Customer to CM] Commitment Amount`}</Caption>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 20,
                                        mt: 10,
                                      }}>
                                      <Box>
                                        <Caption bold>Total</Caption>
                                        <Text
                                          css={{
                                            mt: '$1',
                                          }}>
                                          {`${
                                            appDetailContractRequestTabQuery.data.special_deal.cm_b2b_lite.customer_to_cm.total_value?.toLocaleString() ||
                                            '-'
                                          } ${
                                            appDetailContractRequestTabQuery.data.special_deal
                                              .cm_b2b_lite.customer_to_cm.currency.name || '-'
                                          }`}
                                        </Text>
                                      </Box>
                                      {Object.entries(
                                        appDetailContractRequestTabQuery.data.special_deal
                                          .cm_b2b_lite.customer_to_cm.yearly_values
                                      ).map(([year, value]) => (
                                        <Box key={nanoid()}>
                                          <Caption bold>{`${year} yr`}</Caption>
                                          <Text
                                            css={{
                                              mt: '$1',
                                            }}>
                                            {`${value?.toLocaleString() || '-'} ${
                                              appDetailContractRequestTabQuery.data.special_deal
                                                .cm_b2b_lite.customer_to_cm.currency.name || '-'
                                            }`}
                                          </Text>
                                        </Box>
                                      ))}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                              </SubmissionColumn>
                            </SubmissionRow>
                          ) : null}
                          {isDealSelected({
                            curSelectedDeals:
                              appDetailContractRequestTabQuery.data.special_deal.selected_deals,
                            deal: 'flex',
                          }) ? (
                            <SubmissionRow>
                              {/* Flex */}
                              <SubmissionColumn
                                widthRatio={10}
                                css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                                <SubmissionRow>
                                  {/* Flex Name */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{ color: '$secondary40' }}>{`Special Deal`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${'Flex'}`}</Text>
                                  </SubmissionColumn>
                                  {/* Flex Period */}
                                  <SubmissionColumn>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Commitment Period`}</Caption>
                                    <Text
                                      as="p"
                                      css={{
                                        mt: '$1',
                                      }}>{`${
                                      appDetailContractRequestTabQuery.data.special_deal.flex.period
                                        .name || '-'
                                    }`}</Text>
                                  </SubmissionColumn>
                                </SubmissionRow>
                                {/* Flex C2G Consumption Milestone and Corresponding Service Credit */}
                                <SubmissionRow>
                                  <SubmissionColumn widthRatio={'10'}>
                                    <Caption
                                      bold
                                      css={{
                                        color: '$secondary40',
                                      }}>{`Consumption Milestone and Corresponding Service Credit`}</Caption>
                                    <Box
                                      css={{
                                        display: 'grid',
                                        gridTemplateColumns: '40px repeat(4, 120px)',
                                        gridColumnGap: '20px',
                                        mt: 10,
                                        width: 'fit-content',
                                      }}>
                                      <Box>
                                        <Caption bold>{`No.`}</Caption>
                                      </Box>
                                      <Box>
                                        <Caption bold>{`[CM to Google] Milestone`}</Caption>
                                      </Box>
                                      <Box>
                                        <Caption bold>{`[CM to Google] Credit Amount`}</Caption>
                                      </Box>
                                      <Box>
                                        <Caption bold>{`[Customer to CM] Milestone`}</Caption>
                                      </Box>
                                      <Box>
                                        <Caption bold>{`[Customer to CM] Credit Amount`}</Caption>
                                      </Box>
                                      {appDetailContractRequestTabQuery.data.special_deal.flex.milestone_credit_pairs.map(
                                        (
                                          pair: SpecialDealMilestoneCreditPairEntity,
                                          pairIndex: number
                                        ) => (
                                          <Fragment key={nanoid()}>
                                            <Box>
                                              <Text>{`${pairIndex + 1}`}</Text>
                                            </Box>
                                            <Box>
                                              <Text>
                                                {`${
                                                  pair.cm_to_google_milestone?.toLocaleString() ||
                                                  '-'
                                                } ${
                                                  appDetailContractRequestTabQuery.data.special_deal
                                                    .flex.cm_to_google_currency.name || '-'
                                                }`}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text>
                                                {`${
                                                  pair.cm_to_google_credit?.toLocaleString() || '-'
                                                } ${
                                                  appDetailContractRequestTabQuery.data.special_deal
                                                    .flex.cm_to_google_currency.name || '-'
                                                }`}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text>
                                                {`${
                                                  pair.customer_to_cm_milestone?.toLocaleString() ||
                                                  '-'
                                                } ${
                                                  appDetailContractRequestTabQuery.data.special_deal
                                                    .flex.customer_to_cm_currency.name || '-'
                                                }`}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text>
                                                {`${
                                                  pair.customer_to_cm_credit?.toLocaleString() ||
                                                  '-'
                                                } ${
                                                  appDetailContractRequestTabQuery.data.special_deal
                                                    .flex.customer_to_cm_currency.name || '-'
                                                }`}
                                              </Text>
                                            </Box>
                                          </Fragment>
                                        )
                                      )}
                                    </Box>
                                  </SubmissionColumn>
                                </SubmissionRow>
                              </SubmissionColumn>
                            </SubmissionRow>
                          ) : null}
                        </>
                      ) : null}

                      <SubmissionRow>
                        {/* Reason For Discount Rate */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason For Discount Rate`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                              wordBreak: 'break-all',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.reason_for_discount_rate ||
                            'No reason'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Memo for Legal or Finance */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Memo for Legal or Finance`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                              wordBreak: 'break-all',
                            }}>{`${appDetailContractRequestTabQuery.data.memo || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-attachment',
                name: 'Attach files',
                content: (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                    {appDetailContractRequestTabQuery.data.contract_file_list?.length > 0 ? (
                      <>
                        {appDetailContractRequestTabQuery.data.contract_file_list.map(
                          (file: AppDetailAttachmentEntity) => (
                            <FileCard
                              key={file.id}
                              fileName={file.filename}
                              fileSize={file.size}
                              previewSignedUrl={file.preview_signed_url}
                              signedUrl={file.signed_url}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <Text>No files uploaded by applicant</Text>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      ) : appDetailContractRequestTabQuery.isError ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxAbsolute>
      ) : null}
    </>
  );
};

export default ContractRequestTabGCP;
