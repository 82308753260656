import type { IdNameValueItem } from '../../../../../types';
import { useQueryClient } from 'react-query';
import { useAtomValue } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormSelectedProductAtom,
} from '../../../../../store/atoms';
import { taxRegNumberFieldLabel } from '../../../../../utils/tax-reg-number-field';
import Disclosure from '../../../../../components/surface/disclosure';
import Box from '../../../../../components/box';
import Typography from '../../../../../components/typography/deprecated-typography';
import Avatar from '../../../../../components/surface/avatar';
import Divider from '../../../../../components/surface/divider';
import Spacer from '../../../../../components/surface/spacer';
import LogoButton from '../../../../../components/button/logo-button';
import AwarenessChip from '../../../../../components/chip/awareness-chip';
import LogoSvg from '../../../../../components/surface/logo-svg';
import AttachPDFLightOnIcon from '../../../../../assets/images/icon-attach-pdf-light-on.svg';

const Confirmation = () => {
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom) as
    | 'gcp'
    | 'gws';
  const submissionFormCloudmile = useAtomValue(submissionFormCloudmileAtom);
  const submissionFormCustomer = useAtomValue(submissionFormCustomerAtom);

  const queryClient = useQueryClient();
  const selectedCMUserPicture = queryClient
    .getQueryData<IdNameValueItem[]>(['cm-users'])
    ?.find(
      (cmUser: IdNameValueItem) =>
        cmUser.name === submissionFormCloudmile[submissionFormSelectedProduct].cm_attention_to
    )?.picture;
  const selectedClientName = queryClient
    .getQueryData<IdNameValueItem[]>(['customer-abbreviations'])
    ?.find(
      (customer: IdNameValueItem) =>
        customer.value === submissionFormCustomer[submissionFormSelectedProduct].client_name
    )?.name;
  const selectedPaymentTermName = queryClient
    .getQueryData<IdNameValueItem[]>(['oracle-options-paymentTerm'])
    ?.find(
      (term: IdNameValueItem) =>
        term.value === submissionFormCustomer[submissionFormSelectedProduct].payment_term
    )?.name;
  const selectedExchangeRateName = queryClient
    .getQueryData<IdNameValueItem[]>([
      'oracle-options-exchange-rate',
      submissionFormSelectedProduct,
      submissionFormCloudmile[submissionFormSelectedProduct].cm_legal_entity,
      submissionFormCloudmile[submissionFormSelectedProduct].currency,
    ])
    ?.find(
      (exchangeRate: IdNameValueItem) =>
        exchangeRate.value === submissionFormCloudmile[submissionFormSelectedProduct].exchange_rate
    )?.name;

  return (
    <Box
      css={{
        m: '0 auto',
        width: '780px',
        px: '$10',
        py: '$5',
        backgroundColor: '$white',
      }}>
      <Disclosure
        panels={[
          {
            id: 'panel-cloudmile',
            name: 'CloudMile',
            content: (
              <>
                {/* General Information */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`General Information`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                {submissionFormCloudmile[submissionFormSelectedProduct].urgent_level ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <AwarenessChip
                      awarenessType="urgent"
                      urgentLevel={Number(
                        submissionFormCloudmile[submissionFormSelectedProduct].urgent_level
                      )}
                    />
                  </>
                ) : null}
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <LogoButton light="on" css={{ backgroundColor: '$primary10' }}>
                  <LogoSvg product={submissionFormSelectedProduct} />
                </LogoButton>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* CM Legal Entity */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCloudmile[submissionFormSelectedProduct].cm_legal_entity}`}</Typography>
                  </Box>
                  {/* Attention To */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Attention To`}</Typography>
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: '$1',
                      }}>
                      <Avatar
                        name={`${
                          submissionFormCloudmile[submissionFormSelectedProduct].cm_attention_to ||
                          '-'
                        }`}
                        picture={selectedCMUserPicture || ''}
                        css={{ mr: 8 }}
                        size="sm"
                      />
                      <Typography
                        as="p"
                        variant="body">{`${submissionFormCloudmile[submissionFormSelectedProduct].cm_attention_to}`}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Email */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Email`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCloudmile[submissionFormSelectedProduct].cm_email}`}</Typography>
                  </Box>
                  {/* Phone Number */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Phone Number`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${
                      submissionFormCloudmile[submissionFormSelectedProduct]
                        .cm_country_calling_codes &&
                      submissionFormCloudmile[submissionFormSelectedProduct].cm_phone_number
                        ? `${submissionFormCloudmile[submissionFormSelectedProduct].cm_country_calling_codes} ${submissionFormCloudmile[submissionFormSelectedProduct].cm_phone_number}`
                        : '-'
                    }`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Payment Detail */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Validity */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Quotation Validity`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCloudmile[submissionFormSelectedProduct].validity}`}</Typography>
                  </Box>
                  {/* Terms */}
                  {submissionFormSelectedProduct === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Terms`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${
                        submissionFormCloudmile[submissionFormSelectedProduct].terms === '-1'
                          ? submissionFormCloudmile[submissionFormSelectedProduct].terms_other
                          : submissionFormCloudmile[submissionFormSelectedProduct].terms
                      } Months`}</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Automatically Renew Terms */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Automatically Renew Terms`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${
                      submissionFormCloudmile[submissionFormSelectedProduct]
                        .automatically_renew_terms === '-1'
                        ? submissionFormCloudmile[submissionFormSelectedProduct]
                            .automatically_renew_terms_other
                        : submissionFormCloudmile[submissionFormSelectedProduct]
                            .automatically_renew_terms
                    } Months`}</Typography>
                  </Box>
                  {/* Currency & Exchange Rate */}
                  {submissionFormSelectedProduct === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Currency & Exchange Rate`}</Typography>
                      <Typography as="p" variant="body" css={{ mt: '$1' }}>
                        {!submissionFormCloudmile.gcp.currency &&
                        !submissionFormCloudmile.gcp.exchange_rate
                          ? '-'
                          : `${submissionFormCloudmile.gcp.currency ?? '-'} / ${
                              selectedExchangeRateName ?? '-'
                            }`}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    {/* Commencement Date */}
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Agreement Commencement Date`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCloudmile.gcp.commencement_date ?? '-'}`}</Typography>
                  </Box>
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    {/* End Date */}
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Agreement End Date`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCloudmile.gcp.end_date ?? '-'}`}</Typography>
                  </Box>
                </Box>
                {/* Google Cloud */}
                {submissionFormSelectedProduct === 'gcp' ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Special Deal */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Special Deal`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${submissionFormCloudmile.gcp.special_deal ?? '-'}`}</Typography>
                      </Box>
                      {Boolean(submissionFormCloudmile.gcp.special_deal) &&
                      submissionFormCloudmile.gcp.special_deal !== 'None' ? (
                        <>
                          {/* Commitment Period / Eligibility Period / B2B Lite Commitment Period */}
                          <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                            <Typography variant="caption-bold" css={{ color: '$secondary40' }}>{`${
                              submissionFormCloudmile.gcp.special_deal === 'Flex'
                                ? 'Eligibility Period'
                                : submissionFormCloudmile.gcp.special_deal === 'CM B2B Lite'
                                ? 'B2B Lite Commitment Period'
                                : 'Commitment Period'
                            }`}</Typography>
                            <Typography
                              as="p"
                              variant="body"
                              css={{
                                mt: '$1',
                              }}>
                              {`${
                                submissionFormCloudmile.gcp.special_deal === 'Flex'
                                  ? submissionFormCloudmile.gcp.eligibility_period || '-'
                                  : submissionFormCloudmile.gcp.special_deal === 'CM B2B Lite'
                                  ? submissionFormCloudmile.gcp.b2b_lite_commitment_period || '-'
                                  : submissionFormCloudmile.gcp.commitment_period || '-'
                              } Months`}
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                    {Boolean(submissionFormCloudmile.gcp.special_deal) &&
                    submissionFormCloudmile.gcp.special_deal !== 'None' ? (
                      <>
                        <Spacer axis="vertical" css={{ $$size: '20px' }} />
                        <Box css={{ display: 'flex', columnGap: '$5' }}></Box>
                        {submissionFormCloudmile.gcp.special_deal === 'EDP' ||
                        submissionFormCloudmile.gcp.special_deal === 'CNSP' ||
                        submissionFormCloudmile.gcp.special_deal === 'Google Full B2B' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.cm_to_google_commitment_amount} USD`}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount} ${submissionFormCloudmile.gcp.customer_to_cm_currency}` ??
                                    '-'}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .cm_to_google_commitment_amount_1yr
                                        ? `${submissionFormCloudmile.gcp.cm_to_google_commitment_amount_1yr} USD`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .cm_to_google_commitment_amount_2yr
                                        ? `${submissionFormCloudmile.gcp.cm_to_google_commitment_amount_2yr} USD`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .cm_to_google_commitment_amount_3yr
                                        ? `${submissionFormCloudmile.gcp.cm_to_google_commitment_amount_3yr} USD`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .cm_to_google_commitment_amount_4yr
                                        ? `${submissionFormCloudmile.gcp.cm_to_google_commitment_amount_4yr} USD`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .cm_to_google_commitment_amount_5yr
                                        ? `${submissionFormCloudmile.gcp.cm_to_google_commitment_amount_5yr} USD`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              {/* [Customer to CM] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .customer_to_cm_commitment_amount_1yr
                                        ? `${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_1yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .customer_to_cm_commitment_amount_2yr
                                        ? `${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_2yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .customer_to_cm_commitment_amount_3yr
                                        ? `${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_3yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .customer_to_cm_commitment_amount_4yr
                                        ? `${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_4yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .customer_to_cm_commitment_amount_5yr
                                        ? `${submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_5yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                        {submissionFormCloudmile.gcp.special_deal === 'Flex' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Consumption Milestone */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Consumption Milestone`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.cm_to_google_consumption_milestone_amount} USD`}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Consumption Milestone */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Consumption Milestone`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.customer_to_cm_consumption_milestone_amount} ${submissionFormCloudmile.gcp.customer_to_cm_currency}` ||
                                    '-'}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Service Credit Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Service Credit Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.cm_to_google_service_credit_amount} USD`}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Corresponding Service Credit Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Service Credit Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.cm_to_google_service_credit_amount} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                        {submissionFormCloudmile.gcp.special_deal === 'CM B2B Lite' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [Customer to CM] B2B Lite Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] B2B Lite Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [Customer to CM] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .b2b_lite_customer_to_cm_commitment_amount_1yr
                                        ? `${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount_1yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .b2b_lite_customer_to_cm_commitment_amount_2yr
                                        ? `${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount_2yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .b2b_lite_customer_to_cm_commitment_amount_3yr
                                        ? `${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount_3yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .b2b_lite_customer_to_cm_commitment_amount_4yr
                                        ? `${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount_4yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {submissionFormCloudmile.gcp
                                        .b2b_lite_customer_to_cm_commitment_amount_5yr
                                        ? `${submissionFormCloudmile.gcp.b2b_lite_customer_to_cm_commitment_amount_5yr} ${submissionFormCloudmile.gcp.customer_to_cm_currency}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ),
          },
          {
            id: 'panel-customer',
            name: `Customer ${submissionFormSelectedProduct === 'gws' ? '& Vender' : ''}`,
            content: (
              <>
                {/* General Information */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`General Information`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Client Name */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Client Name`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${selectedClientName ?? '-'}`}</Typography>
                  </Box>
                  {/* Client Country */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Client Country`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCustomer[submissionFormSelectedProduct].client_country}`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Tax Reg Number */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography variant="caption-bold" css={{ color: '$secondary40' }}>
                      {taxRegNumberFieldLabel(
                        submissionFormCustomer[submissionFormSelectedProduct].client_country ?? ''
                      )}
                    </Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                      submissionFormCustomer[submissionFormSelectedProduct].tax_reg_number || '-'
                    }`}</Typography>
                  </Box>
                  {/* Attention To */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Attention To`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCustomer[submissionFormSelectedProduct].client_attention_to}`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Email */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Email`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCustomer[submissionFormSelectedProduct].client_email}`}</Typography>
                  </Box>
                  {/* Phone Number */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Phone Number`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>
                      {submissionFormCustomer[submissionFormSelectedProduct]
                        .client_country_calling_codes &&
                      submissionFormCustomer[submissionFormSelectedProduct].client_phone_number
                        ? `${submissionFormCustomer[submissionFormSelectedProduct].client_country_calling_codes} ${submissionFormCustomer[submissionFormSelectedProduct].client_phone_number}`
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Address */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Address`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>
                      {submissionFormCustomer[submissionFormSelectedProduct].client_address || '-'}
                    </Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Payment Detail */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Google Workspace */}
                {submissionFormSelectedProduct === 'gws' ? (
                  <>
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Workspace Edition */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Workspace Edition`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${submissionFormCustomer.gws.workspace_edition}`}</Typography>
                      </Box>
                      {/* Workspace Plan */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Workspace Plan`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${submissionFormCustomer.gws.workspace_plan}`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Seats */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Seats`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${submissionFormCustomer.gws.client_seats || '-'}`}</Typography>
                      </Box>
                      {/* Google Workspace - Domain Name */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{
                            color: '$secondary40',
                          }}>{`Domain Name`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${
                          submissionFormCustomer.gws.client_domain_name || '-'
                        }`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Subscription Term */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Subscription Term`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>
                          {submissionFormCustomer.gws.subscription_term
                            ? `${
                                submissionFormCustomer.gws.subscription_term === '-1'
                                  ? submissionFormCustomer.gws.subscription_term_other
                                  : submissionFormCustomer.gws.subscription_term
                              } Months`
                            : '-'}
                        </Typography>
                      </Box>
                      {/* Currency & Exchange Rate */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Currency & Exchange Rate`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>
                          {!submissionFormCloudmile.gws.currency &&
                          !submissionFormCloudmile.gws.exchange_rate
                            ? '-'
                            : `${submissionFormCloudmile.gws.currency ?? '-'} / ${
                                selectedExchangeRateName ?? '-'
                              }`}
                        </Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                  </>
                ) : null}
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* {`Payment Term`} */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Payment Term`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>
                      {selectedPaymentTermName ? `${selectedPaymentTermName}` : '-'}
                    </Typography>
                  </Box>
                  {/* {`Payment Discount Rate`} */}
                  {submissionFormSelectedProduct === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Discount Rate`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${submissionFormCustomer[submissionFormSelectedProduct].payment_term_discount_rate} %`}</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Support Plan */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Support Plan`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCustomer[submissionFormSelectedProduct].support_plan}`}</Typography>
                  </Box>
                  {/* Google Cloud */}
                  {/* Will customer issue PO / LOA / Order Form/ other ? */}
                  {submissionFormSelectedProduct === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{
                          color: '$secondary40',
                        }}>{`Will customer issue PO / LOA / Order Form/ other ?`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${submissionFormCustomer.gcp.will_customer_issue_po_loa_orderform_other}`}</Typography>
                    </Box>
                  ) : null}
                  {/* Support Fee Discount Rate */}
                  {/* <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Support Fee Discount Rate`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${submissionFormCustomer[submissionFormSelectedProduct].support_fee_discount_rate} %`}</Typography>
                  </Box> */}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Google Workspace */}
                {submissionFormSelectedProduct === 'gws' ? (
                  <>
                    {/* <Spacer axis="vertical" css={{ $$size: '20px' }} /> */}
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Official Pricing Discount */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          submissionFormCustomer.gws.official_pricing_discount ?? '-'
                        }`}</Typography>
                      </Box>
                      {/* Fixed Amount Discount or Percentage Discount */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        {submissionFormCustomer.gws.official_pricing_discount ===
                        'Fixed amount discount' ? (
                          <>
                            <Typography
                              variant="caption-bold"
                              css={{
                                color: '$secondary40',
                              }}>{`Fixed Amount Discount / Currency`}</Typography>
                            <Typography as="p" variant="body" css={{ mt: '$1' }}>
                              {submissionFormCustomer.gws.fixed_amount_discount &&
                              submissionFormCustomer.gws.official_pricing_discount_currency
                                ? `${submissionFormCustomer.gws.fixed_amount_discount} ${submissionFormCustomer.gws.official_pricing_discount_currency}`
                                : '-'}
                            </Typography>
                          </>
                        ) : null}
                        {submissionFormCustomer.gws.official_pricing_discount ===
                        'Percentage discount' ? (
                          <>
                            <Typography
                              variant="caption-bold"
                              css={{ color: '$secondary40' }}>{`Percentage discount`}</Typography>
                            <Typography as="p" variant="body" css={{ mt: '$1' }}>
                              {submissionFormCustomer.gws.percentage_discount
                                ? `${submissionFormCustomer.gws.percentage_discount} %`
                                : '-'}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </>
                ) : null}
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Memo */}
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Memo`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${
                      submissionFormCustomer[submissionFormSelectedProduct].memo ?? '-'
                    }`}</Typography>
                  </Box>
                </Box>
                {submissionFormSelectedProduct === 'gws' ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Special Price From Google */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Special Price From Google`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${submissionFormCustomer.gws.special_price_from_google}`}</Typography>
                      </Box>
                      {/* Google Workspace - Special Price Workspace Plan */}
                      {submissionFormCustomer.gws.special_price_from_google === 'Yes' ? (
                        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                          <Typography
                            variant="caption-bold"
                            css={{
                              color: '$secondary40',
                            }}>{`Special Price Workspace Plan`}</Typography>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              mt: '$1',
                            }}>{`${submissionFormCustomer.gws.special_price_workspace_plan}`}</Typography>
                        </Box>
                      ) : null}
                    </Box>
                    {submissionFormCustomer.gws.special_price_from_google === 'Yes' ? (
                      <>
                        <Spacer axis="vertical" css={{ $$size: '20px' }} />
                        {/* Google Workspace - Special Price From Google Subscription Term */}
                        <Box css={{ display: 'flex', columnGap: '$5' }}>
                          <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                            <Typography
                              variant="caption-bold"
                              css={{
                                color: '$secondary40',
                              }}>{`Special Price From Google Subscription Term`}</Typography>
                            <Typography
                              as="p"
                              variant="body"
                              css={{
                                mt: '$1',
                              }}>{`${
                              submissionFormCustomer.gws
                                .special_price_from_google_subscription_term === '-1'
                                ? submissionFormCustomer.gws
                                    .special_price_from_google_subscription_term_other
                                : submissionFormCustomer.gws
                                    .special_price_from_google_subscription_term
                            } Months`}</Typography>
                          </Box>
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ),
          },
          {
            id: 'panel-attach-files',
            name: 'Attach Files',
            content: (
              <>
                {' '}
                {/* Google Cloud */}
                {submissionFormSelectedProduct === 'gcp' ? (
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 20,
                    }}>
                    {/* Google Addendum */}
                    {submissionFormCloudmile.gcp.google_addendum ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={
                              submissionFormCloudmile.gcp.google_addendum.type === 'application/pdf'
                                ? AttachPDFLightOnIcon
                                : URL.createObjectURL(submissionFormCloudmile.gcp.google_addendum)
                            }
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              color: '$secondary70',
                            }}>{`${submissionFormCloudmile.gcp.google_addendum.name}`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{ color: '$secondary70' }}>{`${
                            Math.round(
                              (submissionFormCloudmile.gcp.google_addendum.size / (1024 * 1024)) *
                                100
                            ) / 100
                          } MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {/* Company Registration */}
                    {submissionFormCustomer.gcp.company_registration ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={
                              submissionFormCustomer.gcp.company_registration.type ===
                              'application/pdf'
                                ? AttachPDFLightOnIcon
                                : URL.createObjectURL(
                                    submissionFormCustomer.gcp.company_registration
                                  )
                            }
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              color: '$secondary70',
                            }}>{`${submissionFormCustomer.gcp.company_registration.name}`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{ color: '$secondary70' }}>{`${
                            Math.round(
                              (submissionFormCustomer.gcp.company_registration.size /
                                (1024 * 1024)) *
                                100
                            ) / 100
                          } MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {!submissionFormCustomer.gcp.company_registration &&
                    !submissionFormCustomer.gcp.google_addendum
                      ? '-'
                      : null}
                  </Box>
                ) : null}
                {/* Google Workspace */}
                {submissionFormSelectedProduct === 'gws' ? (
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 20,
                    }}>
                    {/* Company Registration */}
                    {submissionFormCustomer.gws.company_registration ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={
                              submissionFormCustomer.gws.company_registration.type ===
                              'application/pdf'
                                ? AttachPDFLightOnIcon
                                : URL.createObjectURL(
                                    submissionFormCustomer.gws.company_registration
                                  )
                            }
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              color: '$secondary70',
                            }}>{`${submissionFormCustomer.gws.company_registration.name}`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{ color: '$secondary70' }}>{`${
                            Math.round(
                              (submissionFormCustomer.gws.company_registration.size /
                                (1024 * 1024)) *
                                100
                            ) / 100
                          } MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {/* FSR Mail */}
                    {submissionFormCustomer.gws.fsr_mail ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={
                              submissionFormCustomer.gws.fsr_mail.type === 'application/pdf'
                                ? AttachPDFLightOnIcon
                                : URL.createObjectURL(submissionFormCustomer.gws.fsr_mail)
                            }
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              color: '$secondary70',
                            }}>{`${submissionFormCustomer.gws.fsr_mail.name}`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{ color: '$secondary70' }}>{`${
                            Math.round(
                              (submissionFormCustomer.gws.fsr_mail.size / (1024 * 1024)) * 100
                            ) / 100
                          } MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {!submissionFormCustomer.gws.company_registration &&
                    !submissionFormCustomer.gws.fsr_mail
                      ? '-'
                      : null}
                  </Box>
                ) : null}
              </>
            ),
          },
        ]}
      />
    </Box>
  );
};

export default Confirmation;
