import type { UserEntity } from '../types/auth';
import type {
  ApplicationActiveDocument,
  AppDetailTabs,
  AppDetailPanels,
  ApplicationsTabs,
  GetApplicationCommentsPayload,
  GetApplicationPayload,
  GetCustomerPayload,
  IdNameValueItem,
  PostApplicationPayload,
  PostValidateSubmissionFormResult,
  ServiceItemValue,
} from '../types';
import type { ToastProps } from '../components/helper/toast';
import { atom } from 'jotai';
import { atomWithStorage, atomWithDefault } from 'jotai/utils';
import { appBarDesktopHeight, navBarOpenWidth, breadcrumbNormalHeight } from './constants';
import moment from 'moment';

// * New Version Stuff
export const hasNewVersionAtom = atomWithStorage<boolean>(
  'hasNewVersion',
  JSON.parse(localStorage.getItem('hasNewVersion') || 'false')
);

// * Location Stuff
export const currentPathnameAtom = atom<string>('');

// * Auth User
export const authUserAtom = atomWithStorage<UserEntity | null>(
  'authUser',
  JSON.parse(localStorage.getItem('authUser') || 'null')
);

// * Layout Stuff
export const isNavBarOpenAtom = atomWithStorage<boolean>(
  'isNavBarOpen',
  JSON.parse(localStorage.getItem('isNavBarOpen') || 'true')
);
export const appBarHeightAtom = atom<string>(`${appBarDesktopHeight}`);
export const navBarWidthAtom = atom<string>(`${navBarOpenWidth}`);
export const breadcrumbHeightAtom = atom<string>(`${breadcrumbNormalHeight}`);
export const isOverlayOpenAtom = atom<boolean>(false);
export const isToastOpenAtom = atom<boolean>(false);
export const toastContentAtom = atom<ToastProps>({});
export const isAppDetailPanelsOpenAtom = atomWithStorage<boolean>(
  'isAppDetailPanelsOpen',
  JSON.parse(localStorage.getItem('isAppDetailPanelsOpenAtom') || 'false')
);
export const isHomeSummaryHideAtom = atomWithStorage<boolean>(
  'isHomeSummaryHide',
  JSON.parse(localStorage.getItem('isHomeSummaryHide') || 'false')
);

// * Home
export const homeApplicationCountAtom = atom<{ [key: string]: number }>({});

// * Applications
export const applicationsSearchTermAtom = atom<string>('');
export const applicationsPaginationLimitAtom = atom<number>(20);
export const applicationsPaginationOffsetAtom = atom<number>(0);
export const initGetApplicationParamsAtom = atom<GetApplicationPayload>({
  tab: 'all',
  offset: 0,
  limit: 20,
  sort_by: 'modified_at',
  order_by: 'desc',
});
export const getApplicationParamsAtom = atomWithDefault<GetApplicationPayload>((get) =>
  get(initGetApplicationParamsAtom)
);

export const isFilterHideAtom = atom<boolean>(true);
export const selectedFilterCountAtom = atom<number>(0);
const initApplicationFilterSelectionAtom = atom<{ [key: string]: string[] }>({
  applicant: [],
  approver: [],
  service_item: [],
  status: [],
  currency: [],
  exchange_rate: [],
  payment_term: [],
  special_deal: [],
  special_price_from_google: [],
  review: [],
  // label: [],
});
export const applicationFilterSelectionAtom = atomWithDefault<{ [key: string]: string[] }>((get) =>
  get(initApplicationFilterSelectionAtom)
);
const initApplicationFilterBarDataAtom = atom<
  { id: string; name: string; icon_name: string; names: string[] }[]
>([]);
export const applicationFilterBarDataAtom = atomWithDefault<
  { id: string; name: string; icon_name: string; names: string[] }[]
>((get) => get(initApplicationFilterBarDataAtom));

// * Applications > Create Application
export const initActiveStepAtom = atom<number>(1);
export const activeStepAtom = atomWithDefault<number>((get) => get(initActiveStepAtom));

export const initSubmissionStepsAtom = atom<IdNameValueItem[]>([
  { id: 'RPgti-', name: 'Service Items', value: 'Service Items' },
  { id: 'T_N-1L', name: 'CloudMile Info', value: 'CloudMile Info' },
  { id: '5Ylh8Q', name: 'Customer Info', value: 'Customer Info' },
  { id: '3cxc36', name: 'Confirmation', value: 'Confirmation' },
]);
export const submissionStepsAtom = atomWithDefault<IdNameValueItem[]>((get) =>
  get(initSubmissionStepsAtom)
);

export const initSubmissionFormSelectedProductAtom = atom<ServiceItemValue>(undefined);
export const submissionFormSelectedProductAtom = atomWithDefault<ServiceItemValue>((get) =>
  get(initSubmissionFormSelectedProductAtom)
);
export const initSubmissionFormSelectedSubsidiaryIdAtom = atom<string | undefined>(undefined);
export const submissionFormSelectedSubsidiaryIdAtom = atomWithDefault<string | undefined>((get) =>
  get(initSubmissionFormSelectedSubsidiaryIdAtom)
);

export const initSubmissionFormCloudmileAtom = atom<PostApplicationPayload>({
  gcp: {
    validity: moment(new Date()).format('DD MMM YYYY'),
    special_deal: 'None',
    commencement_date: moment(new Date()).format('DD MMM YYYY'),
  },
  gws: {
    validity: moment(new Date()).format('DD MMM YYYY'),
  },
});
export const submissionFormCloudmileAtom = atomWithDefault<PostApplicationPayload>((get) =>
  get(initSubmissionFormCloudmileAtom)
);

export const initSubmissionFormCustomerAtom = atom<PostApplicationPayload>({
  gcp: {
    urgent_level: false,
    will_customer_issue_po_loa_orderform_other: 'No',
  },
  gws: {
    special_price_from_google: 'No',
    urgent_level: false,
    official_pricing_discount: 'No discount',
  },
});
export const submissionFormCustomerAtom = atomWithDefault<PostApplicationPayload>((get) =>
  get(initSubmissionFormCustomerAtom)
);

export const initSubmissionFormValidationAtom = atom<PostValidateSubmissionFormResult>({});
export const submissionFormValidationAtom = atomWithDefault<PostValidateSubmissionFormResult>(
  (get) => get(initSubmissionFormValidationAtom)
);

// * Applications > Application
export const initCurrentApplicationsTabAtom = atom<ApplicationsTabs>('all');
export const currentApplicationsTabAtom = atomWithDefault<ApplicationsTabs>((get) =>
  get(initCurrentApplicationsTabAtom)
);
export const curAppDetailTabAtom = atom<AppDetailTabs>('submission');
export const curAppDetailPanelAtom = atom<AppDetailPanels>('info');
export const currentApplicationDocumentAtom = atom<ApplicationActiveDocument>({
  activeDocument: '',
  activeDocumentUrl: '',
  activeDocumentStatus: '',
});
export const initGetApplicationCommentsParamsAtom = atom<GetApplicationCommentsPayload>({
  offset: 0,
  limit: 10,
  filter_by: 'all',
});
export const getApplicationCommentsParamsAtom = atomWithDefault<GetApplicationCommentsPayload>(
  (get) => get(initGetApplicationCommentsParamsAtom)
);
export const isAddCommentEditorOpenAtom = atom<boolean>(false);
export const initialAddCommentContentAtom = atom<string>('');
export const addCommentContentAtom = atomWithDefault<string>((get) =>
  get(initialAddCommentContentAtom)
);

// * Customers
export const customersSearchTermAtom = atom<string>('');
export const customersPaginationLimitAtom = atom<number>(20);
export const customersPaginationOffsetAtom = atom<number>(0);
export const getCustomerParamsAtom = atom<GetCustomerPayload>({
  limit: '20',
  offset: '0',
  order_by: 'desc',
  sort_by: 'last_updated_time',
});
