import type { ChangeEvent } from 'react';
import { styled } from '../../styles/stitches.config';
import Box from '../box';

interface CheckboxProps {
  id?: string;
  className?: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Checkmark = styled('svg', {
  fill: 'none',
  strokeWidth: '4px',
  strokeLinecap: 'round',
});

const RealCheckbox = styled('input', {
  position: 'absolute',
  width: '1em',
  height: '1em',
  left: '50%',
  top: '50%',
  cursor: 'pointer',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
});

const StyledCheckbox = styled('div', {
  width: '16px',
  height: '16px',
  my: '0px',
  mx: 'auto',
  borderRadius: '2px',
  borderStyle: 'solid',
  borderWidth: '1px',
  transition: '$colors',
});

const Checkbox = ({ checked = false, onChange, id, disabled = false }: CheckboxProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '1px',
        m: '0 auto',
        pointerEvents: disabled ? 'none' : 'revert',
      }}>
      <RealCheckbox type="checkbox" id={id} checked={checked} onChange={onChange} />
      <StyledCheckbox
        css={{
          borderColor: disabled ? '$func-disabled-dark' : '$primary50',
          backgroundColor: disabled ? '$func-disabled-dark' : checked ? '$primary50' : '$white',
          '> input[type=checkbox]': {
            visibility: checked ? 'visible' : 'hidden',
          },
        }}>
        <Checkmark
          viewBox="0 0 24 24"
          css={{ stroke: disabled ? (checked ? '$white' : '$func-disabled-dark') : '$white' }}>
          <polyline points="20 6 9 17 4 12" />
        </Checkmark>
      </StyledCheckbox>
    </Box>
  );
};

export default Checkbox;
