export const navyBlue = {
  10: 'hsla(218, 100%, 96%, 1)', // * #ECF3FF
  20: 'hsla(215, 55%, 90%, 1)', // * #D7E3F3
  30: 'hsla(215, 39%, 82%, 1)', // * #BFCEE3
  40: 'hsla(218, 26%, 69%, 1)', // * #9DACC5
  50: 'hsla(218, 21%, 57%, 1)', // * #7A8BA9
  60: 'hsla(217, 24%, 44%, 1)', // * #55698A
  70: 'hsla(214, 32%, 33%, 1)', // * 3A5170
  80: 'hsla(214, 35%, 26%, 1)', // * #2B3F5A
  90: 'hsla(215, 41%, 19%, 1)', // * #1D2E45
  100: 'hsla(212, 64%, 11%, 1)', // * #0A1B2E
};
export const skyBlue = {
  10: 'hsla(203, 100%, 97%, 1)', // * #F2FAFF
  20: 'hsla(202, 100%, 91%, 1)', // * #D1EEFF
  30: 'hsla(205, 100%, 81%, 1)', // * #9ED7FF
  40: 'hsla(207, 83%, 65%, 1)', // * #59ACF0
  50: 'hsla(209, 76%, 54%, 1)', // * #308CE3
  60: 'hsla(211, 90%, 44%, 1)', // * #0B6DD7
  70: 'hsla(213, 100%, 35%, 1)', // * #004FB0
  80: 'hsla(215, 100%, 27%, 1)', // * #00398A
  90: 'hsla(217, 100%, 19%, 1)', // * #002663
  100: 'hsla(219, 100%, 12%, 1)', // * #00153D
};
export const turquoiseBlue = {
  10: 'hsla(180, 100%, 97%, 1)', // * #F0FFFF
  40: 'hsla(187, 100%, 81%, 1)', // * #9CF3FF
  60: 'hsla(191, 100%, 65%, 1)', // * #4ADFFF
  70: 'hsla(193, 68%, 53%, 1)', // * #34B5D9
};
export const green = {
  10: 'hsla(163, 100%, 95%, 1)', // * #E6FFF8
  20: 'hsla(163, 100%, 91%, 1)', // *#D1FFF2'
  30: 'hsla(167, 100%, 74%, 1)', // * #7AFFE2'
  40: 'hsla(169, 89%, 63%, 1)', // * #4EF5D6'
  50: 'hsla(171, 81%, 53%, 1)', // * #25E8CB'
  60: 'hsla(173, 100%, 43%, 1)', // * #00DCC2
  70: 'hsla(175, 100%, 35%, 1)', // * #00B5A6
  80: 'hsla(177, 100%, 28%, 1)', // * #008F88
  90: 'hsla(179, 100%, 21%, 1)', // * #006967
  100: 'hsla(179, 83%, 19%, 1)', // * #085756
};
export const emeraldGreen = {
  10: 'hsla(120, 100%, 97%, 1)', // * #F0FFF0
  20: 'hsla(126, 100%, 90%, 1)', // * #CCFFD1
  40: 'hsla(126, 78%, 76%, 1)', // * #94F29D
  60: 'hsla(132, 65%, 60%, 1)', // * #56DB71
};
export const tangerineOrange = {
  10: 'hsla(37, 94%, 94%, 1)', // * #FEF3E1
  20: 'hsla(36, 100%, 85%, 1)', // * #FFE0B3
  30: 'hsla(37, 97%, 75%, 1)', // * #FDCD82
  40: 'hsla(37, 85%, 68%, 1)', // * #F3BE68
  50: 'hsla(36, 90%, 59%, 1)', // * #F4A938
  60: 'hsla(35, 98%, 52%, 1)', // * #FC9A0F
  70: 'hsla(33, 94%, 51%, 1)', // * F88E0D
  80: 'hsla(30, 91%, 50%, 1)', // * F2800B
  90: 'hsla(27, 93%, 48%, 1)', // * #EC6F09
  100: 'hsla(21, 94%, 46%, 1)', // * #E25507
};
export const lanternOrange = {
  10: 'hsla(32, 100%, 97%, 1)', // * #FFF8F0
  20: 'hsla(32, 100%, 93%, 1)', // * #FFEDD9
  50: 'hsla(27, 84%, 73%, 1)', // * #F4B581
  60: 'hsla(19, 83%, 64%, 1)', // * #EF8857
  70: 'hsla(20, 83%, 56%, 1)', // * EC6F31
};
export const red = {
  10: 'hsla(348, 100%, 96%, 1)', // * #FFEBEF
  20: 'hsla(351, 100%, 91%, 1)', // * #FFD1D8
  30: 'hsla(357, 100%, 82%, 1)', // * #FFA4A9
  40: 'hsla(357, 100%, 75%, 1)', // * #FF8187
  50: 'hsla(358, 100%, 68%, 1)', // * #FF5A60
  60: 'hsla(1, 100%, 62%, 1)', // * #FF403D
  70: 'hsla(2, 71%, 55%, 1)', // * #DE403B
  80: 'hsla(1, 60%, 50%, 1)', // * #CC3735
  90: 'hsla(1, 61%, 46%, 1)', // * #BF302E
  100: 'hsla(2, 67%, 41%, 1)', // * #AF2723
};
export const fuchsia = {
  10: 'hsla(330, 100%, 95%, 1)', // * #FFE7F3
  30: 'hsla(331, 98%, 77%, 1)', // * #FE89C1
  60: 'hsla(319, 85%, 43%, 1)', // * #CB1090
};
export const purple = {
  10: 'hsla(268, 100%, 97%, 1)', // * #F7F0FF
  40: 'hsla(264, 100%, 79%, 1)', // * #C096FF
  60: 'hsla(261, 100%, 72%, 1)', // * #A370FF
};
export const gray = {
  3: 'hsla(0, 0%, 96%, 1)', // * #F6F6F6
  5: 'hsla(0, 0%, 95%, 1)', // * #F2F2F2
  10: 'hsla(0, 0%, 90%, 1)', // * #E6E6E6
  20: 'hsla(0, 0%, 80%, 1)', // * #CCCCCC
  30: 'hsla(0, 0%, 70%, 1)', // * #B3B3B3
  40: 'hsla(0, 0%, 61%, 1)', // * #9B9B9B
  50: 'hsla(0, 0%, 54%, 1)', // * #898989
  60: 'hsla(0, 0%, 49%, 1)', // * #7C7C7C
  70: 'hsla(0, 0%, 40%, 1)', // * #676767
  80: 'hsla(0, 0%, 28%, 1)', // * #484848
  90: 'hsla(0, 0%, 15%, 1)', // * #262626
  100: 'hsla(206, 100%, 3%, 1)', // * #000910
};
export const achromatic = {
  white: 'hsla(0, 0%, 100%, 1)', // * #FFFFFF
  black: 'hsla(0, 0%, 0%, 1)', // * #000000
  transparent: 'hsla(0, 0%, 0%, 0)', // * transparent
};

export const colorKit = {
  light: {
    navy: navyBlue[20],
    sky: skyBlue[10],
    turquoise: turquoiseBlue[10],
    green: green[10],
    emerald: emeraldGreen[10],
    tangerine: tangerineOrange[10],
    lantern: lanternOrange[20],
    red: red[10],
    fuchsia: fuchsia[10],
    purple: purple[10],
    gray: gray[5],
  },
  main: {
    navy: navyBlue[50],
    sky: skyBlue[30],
    turquoise: turquoiseBlue[40],
    green: green[60],
    emerald: emeraldGreen[40],
    tangerine: tangerineOrange[40],
    lantern: lanternOrange[50],
    red: red[30],
    fuchsia: fuchsia[30],
    purple: purple[40],
    gray: gray[30],
  },
  dark: {
    navy: navyBlue[60],
    sky: skyBlue[50],
    turquoise: turquoiseBlue[70],
    green: green[70],
    emerald: emeraldGreen[60],
    tangerine: tangerineOrange[60],
    lantern: lanternOrange[60],
    red: red[60],
    fuchsia: fuchsia[60],
    purple: purple[60],
    gray: gray[60],
  },
};
