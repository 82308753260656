import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import { useQueryClient } from 'react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DropdownOption } from '../../../../../../types';
import { PostSimpGCPStdAppPayload } from '../../../../../../types/application/simplified/create';
import { usePostSimpStdAppAtt } from '../../../../../../services/application/simplified-standard/attachment.api';
import { fixedLayoutGrowMotion } from '../../../../../../styles/motions';
import { usePostSimpStdApp } from '../../../../../../services/application/simplified-standard/app.api';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../../../store/atoms';
import { curSubmissionStepAtom } from '../../../../../../store/application/atoms';
import {
  postSimpGCPStdAppPayloadAtom,
  postSimpStdAppAttPayloadAtom,
} from '../../../../../../store/application/create/simplified/atoms';
import Box from '../../../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../../../components/typography/text';
import { Heading5 } from '../../../../../../components/typography/heading';
import Spacer from '../../../../../../components/surface/spacer';
import ColorfulChip from '../../../../../../components/chip/colorful-chip';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../../../components/helper/dialog-alert';
import Button from '../../../../../../components/button/button';
import MSymbol from '../../../../../../components/icon/m-symbol';
import WizardBar from '../../../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../../../components/surface/submission-paper';
import Disclosure from '../../../../../../components/surface/disclosure';
import ServiceItemButton from '../../../../../../components/button/service-item-button';
import Avatar from '../../../../../../components/surface/avatar';
import FileBlobCard from '../../../../../../components/card/file-blob-card';
import LogoGoogleCloud from '../../../../../../assets/images/logo-google-cloud.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({});

const Confirmation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setCurSubmissionStep = useSetAtom(curSubmissionStepAtom);
  const postSimpStdAppPayload = useAtomValue(postSimpGCPStdAppPayloadAtom);
  const postSimpStdAppAttPayload = useAtomValue(postSimpStdAppAttPayloadAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const curCMAttentionToList = useMemo(() => {
    return queryClient.getQueryData<DropdownOption[]>(['options', 'simp', 'cm-attention-to']) || [];
  }, [queryClient]);

  const { handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postSimpStdAppPayload,
    resolver: yupResolver(validationSchema),
  });

  const postAppMutation = usePostSimpStdApp();
  const postAttachmentMutation = usePostSimpStdAppAtt({
    onSuccess: (res, appStatus) => {
      postAppMutation.mutate({ ...postSimpStdAppPayload, ...res, status: appStatus });
    },
    onError: (err) => {
      setIsSubmitDialogOpen(false);
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBackClick = () => {
    setCurSubmissionStep(3);
  };
  const onSubmitClick: SubmitHandler<PostSimpGCPStdAppPayload> = () => {
    setIsSubmitDialogOpen(true);
  };
  const onSubmitError = () => {
    setIsSubmitDialogOpen(false);
  };
  const onSubmitCancel = () => {
    setIsSubmitDialogOpen(false);
  };
  const onGenerateClick = () => {
    postAttachmentMutation.mutate({ ...postSimpStdAppAttPayload, appStatus: 'pending' });
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({ ...postSimpStdAppAttPayload, appStatus: 'draft' });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitClick, onSubmitError)}>
      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
        <SubmissionPaper>
          <Disclosure
            accordionHeaderEndElement={
              <ColorfulChip text="Simplified" color="turquoise" variant="solid" />
            }
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>General Information</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoGoogleCloud} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.cm_legal_entity.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={postSimpStdAppPayload.cm_attention_to.name || '-'}
                              picture={
                                curCMAttentionToList.find(
                                  (cmUser) =>
                                    cmUser.value === postSimpStdAppPayload.cm_attention_to.value
                                )?.picture || '-'
                              }
                              size="sm"
                            />
                            <Text>{`${postSimpStdAppPayload.cm_attention_to.name || '-'}`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {curCMAttentionToList.find(
                              (cmUser) =>
                                cmUser.value === postSimpStdAppPayload.cm_attention_to.value
                            )?.desc || '-'}
                          </Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.cm_country_calling_code.name || ''} ${
                            postSimpStdAppPayload.cm_phone_number || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(postSimpStdAppPayload.quotation_validity).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Agreement Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.agreement_term || '-'} Months`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Automatically Renew Term */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Automatically Renew Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postSimpStdAppPayload.automatically_renew_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.currency.name || '-'} / ${
                            postSimpStdAppPayload.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Agreement Commencement Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Agreement Commencement Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(postSimpStdAppPayload.agreement_commencement_date).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement End Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Agreement End Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(postSimpStdAppPayload.agreement_end_date).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Customer Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Has this customer ever signed a GCP quotation ? */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Has this customer ever signed a GCP quotation ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.customer_type || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client_country?.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Tax Reg Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.tax_reg_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client_address || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client_attention_to || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client_email || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.client_country_calling_code.name || ''} ${
                            postSimpStdAppPayload.client_phone_number || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${`${postSimpStdAppPayload.payment_term.name}` || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postSimpStdAppPayload.official_pricing_discount_type.name || '-'
                          } / ${
                            postSimpStdAppPayload.official_pricing_discount_type.value ===
                            'Percentage discount'
                              ? `${postSimpStdAppPayload.official_pricing_discount_value} %`
                              : postSimpStdAppPayload.official_pricing_discount_type.value ===
                                'Fixed amount discount'
                              ? `${postSimpStdAppPayload.official_pricing_discount_value} ${postSimpStdAppPayload.official_pricing_discount_currency.name}`
                              : `0 ${postSimpStdAppPayload.official_pricing_discount_currency.name}`
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.support_plan.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Estimated MRR */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Estimated MRR`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postSimpStdAppPayload.estimated_mrr
                              ? `${`${postSimpStdAppPayload.estimated_mrr.toLocaleString()} USD`}`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Will Customer issue PO, LOA, Order Form, other ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Will Customer issue PO, LOA, Order Form, other ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postSimpStdAppPayload.is_issued_form || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Reason For Discount Rate */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason For Discount Rate`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${
                            postSimpStdAppPayload.reason_for_discount_rate || 'No reason'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-attachment',
                name: 'Attachment',
                content: (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                    {!postSimpStdAppAttPayload.company_registration &&
                    !postSimpStdAppAttPayload.random_file ? (
                      <Text>-</Text>
                    ) : (
                      <>
                        {postSimpStdAppAttPayload.company_registration ? (
                          <FileBlobCard
                            file={postSimpStdAppAttPayload.company_registration}
                            needAction={false}
                          />
                        ) : null}
                        {postSimpStdAppAttPayload.random_file &&
                        postSimpStdAppAttPayload.random_file.length > 0 ? (
                          <>
                            {postSimpStdAppAttPayload.random_file.map((randomFile) => (
                              <FileBlobCard key={nanoid()} file={randomFile} needAction={false} />
                            ))}
                          </>
                        ) : null}
                      </>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </SubmissionPaper>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button size="md" variant="borderless" color="achromatic" css={{ mr: 'auto' }}>
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      onClick={() => onLeaveClick()}>
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  <Button
                    variant="outlined"
                    size="md"
                    onClick={() => onSaveClick()}
                    isLoading={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    css={{ width: 123 }}>
                    <ButtonText size={14} bold>
                      Save as draft
                    </ButtonText>
                  </Button>
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}>
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button size="md" variant="outlined" onClick={() => onBackClick()}>
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Submit */}
          <DialogAlertRoot open={isSubmitDialogOpen}>
            {/* Submit Dialog Trigger */}
            <DialogAlertTrigger asChild>
              <Button type="submit" size="md">
                <ButtonText size={14} bold>
                  Submit
                </ButtonText>
              </Button>
            </DialogAlertTrigger>
            <DialogAlertPortal>
              <DialogAlertOverlay />
              {/* Submit Dialog Content */}
              <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: 655 }}>
                <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                  {/* Submit Dialog Header */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                    <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                    <DialogAlertTitle asChild>
                      <Heading5
                        css={{ flexGrow: 1 }}>{`Automatically generated Documents`}</Heading5>
                    </DialogAlertTitle>
                  </Box>
                  {/* Submit Dialog Body */}
                  <DialogAlertDescription css={{ pl: 40 }}>
                    <Text css={{ color: '$text-secondary' }}>
                      Once you submit this application, the system will automatically create the
                      Agreement and Quotation (Draft version).
                    </Text>
                  </DialogAlertDescription>
                  {/* Submit Dialog Footer */}
                  <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                    {/* Generate */}
                    <Button
                      size="md"
                      css={{ width: 94.5 }}
                      onClick={onGenerateClick}
                      isLoading={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}>
                      <ButtonText size={14} bold>
                        Generate
                      </ButtonText>
                    </Button>
                    {/* Cancel */}
                    <DialogAlertCancel asChild>
                      <Button
                        size="md"
                        variant="borderless"
                        color="achromatic"
                        disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                        onClick={onSubmitCancel}>
                        <ButtonText size={14} bold>
                          Cancel
                        </ButtonText>
                      </Button>
                    </DialogAlertCancel>
                  </Box>
                </Box>
              </DialogAlertContent>
            </DialogAlertPortal>
          </DialogAlertRoot>
        </Box>
      </WizardBar>
    </form>
  );
};

export default Confirmation;
