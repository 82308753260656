import { ComponentProps } from 'react';
import { styled } from '../../styles/stitches.config';
import { rotation } from '../../styles/keyframes';
import { getSpinnerColor } from '../../utils/get-color';

const StyledSpinner = styled('div', {
  borderRadius: '$rounded',
  border: '2px solid $primary50',
  borderTop: '2px solid #D9D9D9',
  mx: 'auto',
  animation: `${rotation} 1s linear infinite`,
  variants: {
    size: {
      large: {
        width: '54px',
        height: '54px',
      },
      medium: {
        width: '36px',
        height: '36px',
      },
      small: {
        width: '20px',
        height: '20px',
      },
      tiny: {
        width: '16px',
        height: '16px',
      },
      mini: {
        width: '14px',
        height: '14px',
      },
    },
    elementFor: {
      button: {
        width: '16px',
        height: '16px',
        border: '2px solid $white',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '$$spinnerColor',
        borderTop: '2px solid #D9D9D9',
      },
    },
  },
});

interface SpinnerProps extends ComponentProps<typeof StyledSpinner> {
  elementFor?: 'button';
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large';
  spinnerColor?: 'primary' | 'secondary' | 'secondary-primary' | 'success' | 'warning' | 'error';
}

const Spinner = ({ elementFor, size = 'small', spinnerColor, ...restProps }: SpinnerProps) => {
  return (
    <StyledSpinner
      {...restProps}
      elementFor={elementFor}
      size={size}
      css={{
        $$color: getSpinnerColor(spinnerColor),
      }}
    />
  );
};

export default Spinner;
