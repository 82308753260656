import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types/constants';
import { GetAppDocBarResponse } from '../../../types/application/verified/app-doc-bar';

const useGetAppDocBar = (options: { appId: string; isDraft: boolean }) => {
  const axios = useAxios();
  return useQuery<GetAppDocBarResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'doc-bar'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/document`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId && !options.isDraft,
  });
};

export default useGetAppDocBar;
