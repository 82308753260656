import type { ChangeEvent } from 'react';
import { motion } from 'framer-motion';
import { styled } from '../../styles/stitches.config';
import Box from '../box';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';

const StyledTextArea = styled('textarea', {
  width: '100%',
  height: '100%',
  resize: 'vertical',
  borderStyle: 'solid',
  borderRadius: '$5',
  borderWidth: '1px',
  outline: 'none',
  fontSize: '$14',
  px: '$4',
  py: '10px',
  backgroundColor: '$white',
  transition: 'border-color 0.15s linear, background-color 0.15s linear',
  '&::placeholder': {
    height: 'inherit',
    lineHeight: 'inherit',
    color: '$secondary30',
    fontSize: '$14',
  },
  '&:disabled': {
    backgroundColor: '$secondary10',
    borderColor: '$secondary5',
  },
});

const MultiRowField = ({
  label,
  labelPosition = 'top',
  required = false,
  placeholder,
  rows,
  customWidth,
  value,
  onChange,
  disabled,
  isError,
  error,
}: {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  placeholder?: string;
  rows?: number;
  customWidth?: number;
  value?: string;
  onChange?: (newValue: string) => void;
  disabled?: boolean;
  isError?: boolean;
  error?: string;
}) => {
  return (
    <Box
      css={{
        width: customWidth ? customWidth : '100%',
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <StyledTextArea
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        css={{
          borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary20',
          backgroundColor: disabled ? '$secondary5' : '$white',
        }}></StyledTextArea>
      <Box
        as={motion.div}
        css={{ cursor: isError && error ? 'auto' : 'default' }}
        initial={{ height: '0px', opacity: 0 }}
        animate={isError && error ? 'expand' : 'collapse'}
        variants={{
          expand: {
            height: '100%',
            opacity: 1,
          },
          collapse: {
            height: '0px',
            opacity: 0,
          },
        }}>
        <Typography variant="caption" css={{ color: '$error60' }}>{`${error ?? ''}`}</Typography>
      </Box>
    </Box>
  );
};

export default MultiRowField;
