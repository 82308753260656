import { ChangeEvent, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { isGMApprovalRequired, isCEOApprovalRequired } from '../../../../utils/check-validation';
import { getBossApprovalDesc } from '../../../../utils/get-wording';
import * as Yup from 'yup';
import {
  ClientSourceType,
  PostStdAppAttPayload,
  SpecialDealEntity,
} from '../../../../types/application/constants';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../../styles/motions';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postStdAppAttPayloadAtom,
  postGCPStdAppPayloadAtom,
} from '../../../../store/application/atoms';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import FileUploader from '../../../../components/helper/file-uploader';
import FileBlobCard from '../../../../components/card/file-blob-card';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
} from '../../../../components/surface/submission-paper';
import PopupErrorIcon from '../../../../assets/images/icon-popup-error.svg';
import AttSampleImageCard from '../../../../components/card/att-sample-image-card';

const ALLOWED_PAYMENT_TERM_MAX_DAY_CEO = 60; // * NET 60 (O/A 30)
const ALLOWED_PAYMENT_TERM_MAX_DAY_GM = 45; // * NET 45
const ALLOWED_DISCOUNT_MAX_PERCENTAGE_CEO = 8;
const ALLOWED_DISCOUNT_MAX_PERCENTAGE_GM = 3.01;
const ALLOWED_FILE_MAX_SIZE = 2097152; // * 2 MB
const ALLOWED_FILE_TYPES = [
  'application/pdf', // * PDF
  'image/jpeg', // * JPEG and JPG
  'image/png', // * PNG
  'application/msword', // * DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // * DOCX
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // * XLSX
];
const ALLOWED_FILE_TYPES_SEAL = ['application/pdf'];

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  google_addendum: Yup.mixed<File>()
    .nullable()
    .test('google_addendum', 'This field is required', (value, context) => {
      const curSelectedDeals = context.from?.[0].value.selected_deals;
      return (
        !curSelectedDeals.some(
          (deal: SpecialDealEntity) =>
            deal.value === 'edp' || deal.value === 'cnsp' || deal.value === 'google_full_b2b'
        ) || !!value
      );
    })
    .test('google_addendum_size', 'Maximum file size 2 MB', (value) => {
      return value ? value.size <= ALLOWED_FILE_MAX_SIZE : true;
    }),
  company_registration: Yup.mixed<File>()
    .nullable()
    .test('company_registration', 'This field is required', (value, context) => {
      const curClientSource = context.from?.[0].value.client_source;
      return curClientSource === 'oracle' || !!value;
    })
    .test('company_registration_size', 'Maximum file size 2 MB', (value) => {
      return value ? value.size <= ALLOWED_FILE_MAX_SIZE : true;
    }),
  ceo_approval: Yup.mixed<File>()
    .nullable()
    .test('ceo_approval', 'This field is required', (value, context) => {
      const curPaymentTerm = context.from?.[0].value.payment_term;
      const curDiscountType = context.from?.[0].value.discount_type;
      const curDiscountValue = context.from?.[0].value.discount_value;
      return (
        (!isCEOApprovalRequired({
          condition: 'paymentTerm',
          paymentTerm: curPaymentTerm,
          max: ALLOWED_PAYMENT_TERM_MAX_DAY_CEO,
        }) &&
          !isCEOApprovalRequired({
            condition: 'discount',
            discountType: curDiscountType,
            discountValue: curDiscountValue || 0,
            max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_CEO,
          })) ||
        !!value
      );
    })
    .test('ceo_approval_size', 'Maximum file size 2 MB', (value) => {
      return value ? value.size <= ALLOWED_FILE_MAX_SIZE : true;
    }),
  gm_approval: Yup.mixed<File>()
    .nullable()
    .test('gm_approval', 'This field is required', (value, context) => {
      const curPaymentTerm = context.from?.[0].value.payment_term;
      const curDiscountType = context.from?.[0].value.discount_type;
      const curDiscountValue = context.from?.[0].value.discount_value;
      return (
        (!isGMApprovalRequired({
          condition: 'paymentTerm',
          paymentTerm: curPaymentTerm,
          max: ALLOWED_PAYMENT_TERM_MAX_DAY_GM,
        }) &&
          !isGMApprovalRequired({
            condition: 'discount',
            discountType: curDiscountType,
            discountValue: curDiscountValue || 0,
            max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_GM,
          })) ||
        !!value
      );
    })
    .test('gm_approval_size', 'Maximum file size 2 MB', (value) => {
      return value ? value.size <= ALLOWED_FILE_MAX_SIZE : true;
    }),
  random_file: Yup.array()
    .nullable()
    .of(
      Yup.mixed<File>().test('random_file_size', 'Maximum file size 2 MB', (value) => {
        if (!value) {
          return true; // * Skip validation for null or undefined
        }
        return value.size <= ALLOWED_FILE_MAX_SIZE;
      })
    )
    .test('random_file_length', 'Maximum of 10 files allowed for upload', (value) => {
      return !value || value?.length <= 10;
    }),
  seal_file: Yup.array()
    .nullable()
    .of(
      Yup.mixed<File>().test('seal_file_size', 'Maximum file size 2 MB', (value) => {
        if (!value) {
          return true; // * Skip validation for null or undefined
        }
        return value.size <= ALLOWED_FILE_MAX_SIZE;
      })
    )
    .test('seal_file_length', 'Maximum of 5 files allowed for upload', (value) => {
      return !value || value?.length <= 5;
    }),
});

const Additional = () => {
  const navigate = useNavigate();
  const { appId } = useParams();

  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const postStdAppPayload = useAtomValue(postGCPStdAppPayloadAtom);
  const [postStdAppAttPayload, setPostStdAppAttPayload] = useAtom(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues, setValue, formState } = useForm<
    PostStdAppAttPayload & {
      client_source: ClientSourceType;
      payment_term: number;
      discount_type: string;
      discount_value: number;
      selected_deals: { value: string }[];
    }
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...postStdAppAttPayload,
      client_source: postStdAppPayload.client_source,
      payment_term: postStdAppPayload.payment_term.value,
      discount_type: postStdAppPayload.official_pricing_discount_type.value,
      discount_value: postStdAppPayload.official_pricing_discount_value,
      selected_deals: postStdAppPayload.special_deal.selected_deals,
    },
    resolver: yupResolver(validationSchema),
  });
  const curGoogleAddendum = useWatch({ control, name: 'google_addendum' });
  const curCompanyRegistration = useWatch({ control, name: 'company_registration' });
  const curCEOApproval = useWatch({ control, name: 'ceo_approval' });
  const curGMApproval = useWatch({ control, name: 'gm_approval' });
  const curRandomFiles = useWatch({ control, name: 'random_file' });
  const curSealFiles = useWatch({ control, name: 'seal_file' });

  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      } else {
        postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const hiddenRandomFilesInput = useRef<HTMLInputElement | null>(null);
  const onRandomFilesChosen = () => {
    if (hiddenRandomFilesInput.current) {
      hiddenRandomFilesInput.current.click();
    }
  };
  const onRandomFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const prevSelectedFiles = getValues('random_file');
    const selectedFiles = e.target.files || null;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    if (!prevSelectedFiles || prevSelectedFiles.length <= 0) {
      setValue('random_file', Array.from(selectedFiles), { shouldValidate: true });
    } else {
      setValue('random_file', [...Array.from(prevSelectedFiles), ...Array.from(selectedFiles)], {
        shouldValidate: true,
      });
    }
  };
  const onRandomFilesCloseClick = (randomFileIndex: number) => {
    if (curRandomFiles && curRandomFiles.length > 0) {
      setValue(
        'random_file',
        [...curRandomFiles.slice(0, randomFileIndex), ...curRandomFiles.slice(randomFileIndex + 1)],
        { shouldValidate: true }
      );
    }
  };

  const hiddenSealFilesInput = useRef<HTMLInputElement | null>(null);
  const onSealFilesChosen = () => {
    if (hiddenSealFilesInput.current) {
      hiddenSealFilesInput.current.click();
    }
  };
  const onSealFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const prevSelectedFiles = getValues('seal_file');
    const selectedFiles = e.target.files || null;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    if (!prevSelectedFiles || prevSelectedFiles.length <= 0) {
      setValue('seal_file', Array.from(selectedFiles), { shouldValidate: true });
    } else {
      setValue('seal_file', [...Array.from(prevSelectedFiles), ...Array.from(selectedFiles)], {
        shouldValidate: true,
      });
    }
  };
  const onSealFilesCloseClick = (sealFileIndex: number) => {
    if (curSealFiles && curSealFiles.length > 0) {
      setValue(
        'seal_file',
        [...curSealFiles.slice(0, sealFileIndex), ...curSealFiles.slice(sealFileIndex + 1)],
        { shouldValidate: true }
      );
    }
  };

  const onBackClick = () => {
    setPostStdAppAttPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(3);
  };
  const onNextClick: SubmitHandler<PostStdAppAttPayload> = (data) => {
    setPostStdAppAttPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(5);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...getValues(),
      appStatus: appId ? postStdAppPayload.status : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Required documents */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Required documents</Heading5>
          <ColorfulChip text="GCP" color="sky" variant="solid" />
        </SubmissionPaperHeader>
        <SubmissionSection>
          <SubmissionRow>
            {/* Google Addendum */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="google_addendum"
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <FileUploader
                    required={
                      postStdAppPayload.special_deal.selected_deals.some(
                        (deal) =>
                          deal.value === 'edp' ||
                          deal.value === 'cnsp' ||
                          deal.value === 'google_full_b2b'
                      )
                        ? true
                        : false
                    }
                    disabled={
                      postStdAppPayload.special_deal.selected_deals.some(
                        (deal) =>
                          deal.value === 'edp' ||
                          deal.value === 'cnsp' ||
                          deal.value === 'google_full_b2b'
                      )
                        ? false
                        : true
                    }
                    needInfo
                    infoElement={<AttSampleImageCard sampleImageId={'Google_Addendum'} />}
                    allowedFileType={ALLOWED_FILE_TYPES}
                    title={'Google Addendum'}
                    desc={
                      'If Google offers a special deal, the Google addendum is required to be included.'
                    }
                    fieldName={name}
                    onChange={onChange}
                    isError={error?.type === 'google_addendum'}
                    error={error?.type === 'google_addendum' ? error.message : ''}
                  />
                )}
              />
              <Spacer axis="vertical" css={{ $$size: '10px' }} />
              {curGoogleAddendum ? (
                <Box
                  as={motion.div}
                  initial={'hide'}
                  variants={fadeInOutMotion}
                  animate={curGoogleAddendum ? 'show' : 'hide'}>
                  <FileBlobCard
                    file={curGoogleAddendum}
                    isError={formState.errors.google_addendum?.type === 'google_addendum_size'}
                    error={
                      formState.errors.google_addendum?.type === 'google_addendum_size'
                        ? formState.errors.google_addendum?.message
                        : ''
                    }
                    onCloseClick={() => setValue('google_addendum', null, { shouldValidate: true })}
                  />
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Company Registration */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="company_registration"
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <FileUploader
                    required={postStdAppPayload.client_source !== 'oracle' ? true : false}
                    disabled={postStdAppPayload.client_source === 'oracle' ? true : false}
                    allowedFileType={ALLOWED_FILE_TYPES}
                    title={'Company Registration'}
                    desc={
                      'If not an existing customer, the company registration certificate is required.'
                    }
                    fieldName={name}
                    needInfo
                    infoElement={
                      <AttSampleImageCard
                        sampleImageId={postStdAppPayload.client_country?.value || ''}
                      />
                    }
                    onChange={onChange}
                    isError={error?.type === 'company_registration'}
                    error={error?.type === 'company_registration' ? error.message : ''}
                  />
                )}
              />
              <Spacer axis="vertical" css={{ $$size: '10px' }} />
              {curCompanyRegistration ? (
                <Box
                  as={motion.div}
                  initial={'hide'}
                  variants={fadeInOutMotion}
                  animate={curCompanyRegistration ? 'show' : 'hide'}>
                  <FileBlobCard
                    file={curCompanyRegistration}
                    isError={
                      formState.errors.company_registration?.type === 'company_registration_size'
                    }
                    error={
                      formState.errors.company_registration?.type === 'company_registration_size'
                        ? formState.errors.company_registration?.message
                        : ''
                    }
                    onCloseClick={() =>
                      setValue('company_registration', null, { shouldValidate: true })
                    }
                  />
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* CEO Approval */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="ceo_approval"
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <FileUploader
                    required={
                      isCEOApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_CEO,
                      }) ||
                      isCEOApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_CEO,
                      })
                    }
                    disabled={
                      !isCEOApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_CEO,
                      }) &&
                      !isCEOApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_CEO,
                      })
                    }
                    allowedFileType={ALLOWED_FILE_TYPES}
                    title={'CEO Approval'}
                    desc={getBossApprovalDesc({
                      isPaymentTerm: isCEOApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_CEO,
                      }),
                      isPercentageDiscount: isCEOApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_CEO,
                      }),
                    })}
                    fieldName={name}
                    onChange={onChange}
                    isError={error?.type === 'ceo_approval'}
                    error={error?.type === 'ceo_approval' ? error.message : ''}
                  />
                )}
              />
              <Spacer axis="vertical" css={{ $$size: '10px' }} />
              {curCEOApproval ? (
                <Box
                  as={motion.div}
                  initial={'hide'}
                  variants={fadeInOutMotion}
                  animate={curCEOApproval ? 'show' : 'hide'}>
                  <FileBlobCard
                    file={curCEOApproval}
                    isError={formState.errors.ceo_approval?.type === 'ceo_approval_size'}
                    error={
                      formState.errors.ceo_approval?.type === 'ceo_approval_size'
                        ? formState.errors.ceo_approval?.message
                        : ''
                    }
                    onCloseClick={() => setValue('ceo_approval', null, { shouldValidate: true })}
                  />
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* GM Approval */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="gm_approval"
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <FileUploader
                    required={
                      isGMApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_GM,
                      }) ||
                      isGMApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_GM,
                      })
                    }
                    disabled={
                      !isGMApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_GM,
                      }) &&
                      !isGMApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_GM,
                      })
                    }
                    allowedFileType={ALLOWED_FILE_TYPES}
                    title={'GM Approval'}
                    desc={getBossApprovalDesc({
                      isPaymentTerm: isGMApprovalRequired({
                        condition: 'paymentTerm',
                        paymentTerm: postStdAppPayload.payment_term.value,
                        max: ALLOWED_PAYMENT_TERM_MAX_DAY_GM,
                      }),
                      isPercentageDiscount: isGMApprovalRequired({
                        condition: 'discount',
                        discountType: postStdAppPayload.official_pricing_discount_type.value,
                        discountValue: postStdAppPayload.official_pricing_discount_value || 0,
                        max: ALLOWED_DISCOUNT_MAX_PERCENTAGE_GM,
                      }),
                    })}
                    fieldName={name}
                    onChange={onChange}
                    isError={error?.type === 'gm_approval'}
                    error={error?.type === 'gm_approval' ? error.message : ''}
                  />
                )}
              />
              <Spacer axis="vertical" css={{ $$size: '10px' }} />
              {curGMApproval ? (
                <Box
                  as={motion.div}
                  initial={'hide'}
                  variants={fadeInOutMotion}
                  animate={curGMApproval ? 'show' : 'hide'}>
                  <FileBlobCard
                    file={curGMApproval}
                    isError={formState.errors.gm_approval?.type === 'gm_approval_size'}
                    error={
                      formState.errors.gm_approval?.type === 'gm_approval_size'
                        ? formState.errors.gm_approval?.message
                        : ''
                    }
                    onCloseClick={() => setValue('gm_approval', null, { shouldValidate: true })}
                  />
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
        {/* Supplementary documents */}
        <SubmissionSection css={{ mt: 10 }}>
          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 20,
              justifyContent: 'space-between',
            }}>
            <Box>
              <Heading5>Supplementary documents</Heading5>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Caption css={{ color: '$text-secondary' }}>
                You can upload any supplementary documents in the field provided.
              </Caption>
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={curRandomFiles && curRandomFiles.length > 10 ? 'show' : 'hide'}>
                <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
                  <Box
                    as="img"
                    src={PopupErrorIcon}
                    css={{
                      objectFit: 'contain',
                      width: 18,
                      height: 18,
                    }}
                  />
                  <Caption
                    css={{
                      ml: '$1',
                      color: '$error60',
                    }}>{`${formState.errors.random_file?.message || ''}`}</Caption>
                </Box>
              </Box>
            </Box>
            <Controller
              control={control}
              name="random_file"
              render={({ field: { name } }) => (
                <Button
                  name={name}
                  size="sm"
                  onClick={onRandomFilesChosen}
                  startElement={
                    <MSymbol
                      iconName="file_upload"
                      weight={700}
                      css={{ color: 'inherit', fontSize: '16px !important' }}
                    />
                  }>
                  <ButtonText size={14} bold>
                    Upload
                  </ButtonText>
                  <input
                    ref={hiddenRandomFilesInput}
                    name={name}
                    type="file"
                    multiple
                    accept={ALLOWED_FILE_TYPES.join(',')}
                    style={{ display: 'none' }}
                    onChange={onRandomFilesChange}
                  />
                </Button>
              )}
            />
          </Box>
          {curRandomFiles && curRandomFiles.length > 0 ? (
            <Box
              as={motion.div}
              initial={'hide'}
              variants={fadeInOutMotion}
              animate={curRandomFiles && curRandomFiles.length > 0 ? 'show' : 'hide'}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                {Array.from(curRandomFiles).map((randomFile, randomFileIndex) => (
                  <FileBlobCard
                    key={nanoid()}
                    file={randomFile}
                    isError={
                      formState.errors.random_file
                        ? formState.errors.random_file[randomFileIndex]?.type === 'random_file_size'
                        : false
                    }
                    error={
                      formState.errors.random_file
                        ? `${formState.errors.random_file[randomFileIndex]?.message}`
                        : ''
                    }
                    onCloseClick={() => onRandomFilesCloseClick(randomFileIndex)}
                  />
                ))}
              </Box>
            </Box>
          ) : null}
        </SubmissionSection>
        {/* Request for sealed documents */}
        <SubmissionSection css={{ mt: 10 }}>
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
            <Box>
              <Heading5>Request for sealed documents</Heading5>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Caption css={{ color: '$text-secondary' }}>
                You can upload documents requiring a seal or those that have been signed by the
                client, excluding contracts and quotations already on this system.
              </Caption>
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={curSealFiles && curSealFiles.length > 10 ? 'show' : 'hide'}>
                <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
                  <Box
                    as="img"
                    src={PopupErrorIcon}
                    css={{
                      objectFit: 'contain',
                      width: 18,
                      height: 18,
                    }}
                  />
                  <Caption
                    css={{
                      ml: '$1',
                      color: '$error60',
                    }}>{`${formState.errors.random_file?.message || ''}`}</Caption>
                </Box>
              </Box>
            </Box>
            <Controller
              control={control}
              name="seal_file"
              render={({ field: { name } }) => (
                <Button
                  name={name}
                  size="sm"
                  onClick={onSealFilesChosen}
                  startElement={
                    <MSymbol
                      iconName="file_upload"
                      weight={700}
                      css={{ color: 'inherit', fontSize: '16px !important' }}
                    />
                  }>
                  <ButtonText size={14} bold>
                    Upload
                  </ButtonText>
                  <input
                    ref={hiddenSealFilesInput}
                    name={name}
                    type="file"
                    multiple
                    accept={ALLOWED_FILE_TYPES_SEAL.join(',')}
                    style={{ display: 'none' }}
                    onChange={onSealFilesChange}
                  />
                </Button>
              )}
            />
          </Box>
          {curSealFiles && curSealFiles.length > 0 ? (
            <Box
              as={motion.div}
              initial={'hide'}
              variants={fadeInOutMotion}
              animate={curSealFiles && curSealFiles.length > 0 ? 'show' : 'hide'}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                {Array.from(curSealFiles).map((sealFile, sealFileIndex) => (
                  <FileBlobCard
                    key={nanoid()}
                    file={sealFile}
                    isError={
                      formState.errors.seal_file
                        ? formState.errors.seal_file[sealFileIndex]?.type === 'seal_file_size'
                        : false
                    }
                    error={
                      formState.errors.seal_file
                        ? `${formState.errors.seal_file[sealFileIndex]?.message}`
                        : ''
                    }
                    onCloseClick={() => onSealFilesCloseClick(sealFileIndex)}
                  />
                ))}
              </Box>
            </Box>
          ) : null}
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {postStdAppPayload.status === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button size="md" type="submit" data-track-id="btn-next">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default Additional;
