export const fadeInOutMotion = {
  show: { opacity: 1, height: 'auto', display: 'block' },
  hide: { opacity: 0, height: 0, transitionEnd: { display: 'none' } },
};

export const navItemExpandMotion = {
  expand: {
    width: '220px',
    paddingRight: '10px',
    paddingLeft: '10px',
    transition: { duration: 0.25 },
  },
  collapse: {
    width: '40px',
    paddingRight: '8px',
    paddingLeft: '8px',
    transition: { duration: 0.15 },
  },
};

export const navBarOpenMotion = (navBarWidth: string) => ({
  open: { maxWidth: `${navBarWidth}`, transition: { duration: 0.2 } },
  closed: { maxWidth: `${navBarWidth}`, transition: { duration: 0.2 } },
});

export const layoutGrowMotion = (navBarWidth: string) => ({
  grow: { marginLeft: `${navBarWidth}`, transition: { duration: 0.2 } },
  shrink: { marginLeft: `${navBarWidth}`, transition: { duration: 0.2 } },
});

export const fixedLayoutGrowMotion = (navBarWidth: string) => ({
  grow: { left: `${navBarWidth}`, transition: { duration: 0.2 } },
  shrink: { left: `${navBarWidth}`, transition: { duration: 0.2 } },
});

export const elementGrowMotion = (navBarWidth: string) => ({
  grow: { width: `calc(100% - ${navBarWidth})`, transition: { duration: 0.2 } },
  shrink: { width: `calc(100% - ${navBarWidth})`, transition: { duration: 0.2 } },
});
