import React, { ReactNode, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import Box from '../box';
import Label from '../typography/label';
import { Caption } from '../typography/text';
import { motion } from 'framer-motion';
import { fadeInOutMotion } from '../../styles/motions';

const TextInputAreaContent = styled('div', {
  borderRadius: 5,
  border: 'none',
  backgroundColor: '$white',
  py: 10,
  px: 7,
  color: '$text-primary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
  transition: '$colors-and-shadow',
  '&:hover': {
    boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
  },
  '&[data-error=true]': {
    color: '$error60',
    boxShadow: `0 0 0 1px var(--colors-error60)`,
  },
  '&[data-disabled=true]': {
    cursor: 'not-allowed',
    color: '$text-hint',
    boxShadow: `0 0 0 1px var(--colors-func-disabled-dark)`,
    backgroundColor: '$func-disabled-light',
  },
});

const StyledTextarea = styled('textarea', {
  all: 'unset',
  display: 'block',
  width: `calc(100% - 10px)`,
  height: '100%',
  resize: 'vertical',
  fontSize: 14,
  lineHeight: 1.75,
  fontWeight: 400,
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  '&::placeholder': {
    color: '$text-hint',
  },
});

interface TextInputAreaProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  value: string | undefined;
  onChange: (newValue: string) => void;
  rows?: number;
  autocomplete?: string;
  disabled?: boolean;
  isError?: boolean;
  error?: string;
  startElement?: ReactNode;
  endElement?: ReactNode;
}

const TextInputArea = forwardRef<HTMLDivElement, TextInputAreaProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value = '',
      onChange,
      rows = 3,
      autocomplete = 'off',
      disabled,
      isError,
      error,
      ...restProps
    },
    ref
  ) => {
    const onTextareaChange = (newValue: string) => {
      onChange(newValue);
    };
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <TextInputAreaContent data-error={isError} data-disabled={disabled}>
          <StyledTextarea
            name={fieldName}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onTextareaChange(e.target.value)}
            disabled={disabled}
            rows={rows}
            autoComplete={autocomplete}></StyledTextarea>
        </TextInputAreaContent>
        <Box
          as={motion.div}
          initial={'hide'}
          variants={fadeInOutMotion}
          animate={isError ? 'show' : 'hide'}>
          <Caption css={{ color: '$error60' }}>{error}</Caption>
        </Box>
      </Box>
    );
  }
);

TextInputArea.displayName = 'TextInputArea';

export default TextInputArea;
