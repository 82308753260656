import { motion } from 'framer-motion';
import Box from '../box';
import Typography from '../typography/deprecated-typography';

const expandCollapseMotion = {
  expand: {
    height: 'auto',
    marginTop: '15px',
    opacity: 1,
    transition: { duration: 0.15 },
  },
  collapse: {
    height: '0px',
    marginTop: '0px',
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const submissionUrgentBox = ({ urgentLevel }: { urgentLevel?: boolean }) => {
  return (
    <Box
      as={motion.div}
      initial={expandCollapseMotion.collapse}
      animate={urgentLevel ? 'expand' : 'collapse'}
      variants={expandCollapseMotion}
      css={{
        px: '$5',
        width: 'fit-content',
        minHeight: 30,
        overflow: 'hidden',
        borderRadius: '$5',
        borderWidth: '1px',
        borderColor: '$error60',
        borderStyle: 'solid',
        backgroundColor: '$error10',
      }}>
      <Typography
        variant={`caption`}
        css={{
          color: '$error70',
        }}>{`The local GM will review and approve the request first.`}</Typography>
    </Box>
  );
};

export default submissionUrgentBox;
