import { styled } from '../../styles/stitches.config';

export const Heading1 = styled('h1', {
  fontSize: 48,
  fontWeight: 700,
  lineHeight: 1.25,
});

export const Heading2 = styled('h2', {
  fontSize: 36,
  fontWeight: 700,
  lineHeight: 1.5,
});

export const Heading3 = styled('h3', {
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.5,
});

export const Heading4 = styled('h4', {
  fontSize: 20,
  fontWeight: 700,
  lineHeight: 1.5,
});

export const Heading5 = styled('h5', {
  fontSize: 18,
  fontWeight: 700,
  lineHeight: 1.5,
});

export const Heading6 = styled('h6', {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.5,
});
