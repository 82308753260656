import { styled } from '../../styles/stitches.config';

const SelectOption = styled('li', {
  px: '$5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  color: '$secondary90',
  fontSize: '$14',
  borderRadius: '$5',
  cursor: 'pointer',
  transition: 'background-color 0.15s linear',
  variants: {
    light: {
      on: {
        backgroundColor: '$primary20',
        '&:hover': {
          backgroundColor: '$primary30',
        },
        '&:active': {
          backgroundColor: '$primary40',
        },
      },
      off: {
        backgroundColor: '$white',
        '&:hover': {
          backgroundColor: '$secondary3',
        },
        '&:active': {
          backgroundColor: '$secondary10',
        },
      },
    },
  },
  defaultVariants: {
    light: 'off',
  },
});

export default SelectOption;
