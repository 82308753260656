import React, { ComponentProps, useMemo, useState } from 'react';
import { styled } from '../../styles/stitches.config';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { curAppDetailTabAtom, isAppDetailPanelsOpenAtom } from '../../store/atoms';
import { useGetAppliedByMe, useGetAssignedToMe } from '../../services/home/home.api';
import { AppType, ClientType, ProductType } from '../../types/application/constants';
import { assignmentCardOptions } from '../../store/constants';
import Box from '../box';
import Skeleton from '../surface/skeleton';
import AwarenessChip from '../chip/awareness-chip';
import DocIcon from '../icon/doc-icon';
import MSymbol from '../icon/m-symbol';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
} from '../dropdown/popover';
import { ButtonText, Caption, Text } from '../typography/text';
import IconButton from '../button/icon-button';
import SelectOption from './select-option';
import Spacer from '../surface/spacer';
import moment from 'moment';
import ApprovalStatusCaption from '../typography/approval-status-caption';
import { CenteredBoxFlex } from '../surface/centered-box';
import Button from '../button/button';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';
import { Heading6 } from '../typography/heading';

export const AssignmentCardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 10,
});
const AssignmentCardRoot = styled('div', {
  borderRadius: '$5',
  border: '1px solid $achromatic20',
  position: 'relative',
  transition: '$colors $opacity',
  overflow: 'hidden',
  cursor: 'pointer',
  '& [data-button=more]': {
    opacity: 0,
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '$secondary3',
    '& [data-button=more]': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  '&:active': {
    backgroundColor: '$achromatic5',
  },
});

export const AssignmentCardContent = styled('div', {});

interface AssignmentCardProps extends ComponentProps<typeof AssignmentCardRoot> {
  appId: string;
  assignmentType: 'appliedByMe' | 'assignedToMe';
}

const AssignmentCard = ({ appId, assignmentType, ...restProps }: AssignmentCardProps) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const setIsAppDetailPanelsOpen = useSetAtom(isAppDetailPanelsOpenAtom);
  const setCurAppDetailTab = useSetAtom(curAppDetailTabAtom);

  const appliedByMeQuery = useGetAppliedByMe({ appId: appId || '', assignmentType });
  const assignedToMeQuery = useGetAssignedToMe({ appId: appId || '', assignmentType });

  const onSuccessAssignmentClick = (options: {
    appType: AppType;
    product: ProductType;
    clientType: ClientType;
    appId: string;
  }) => {
    if (options.appType === 'official') {
      setCurAppDetailTab('approval');
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      setCurAppDetailTab('submission');
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };

  const assignmentCardQuery = useMemo(() => {
    switch (assignmentType) {
      case 'appliedByMe':
        return appliedByMeQuery;
      case 'assignedToMe':
        return assignedToMeQuery;
      default:
        return undefined;
    }
  }, [appliedByMeQuery, assignedToMeQuery, assignmentType]);

  return assignmentCardQuery ? (
    <AssignmentCardRoot {...restProps}>
      {assignmentCardQuery.isLoading ? (
        <Skeleton css={{ height: 150 }} />
      ) : assignmentCardQuery.isSuccess ? (
        <AssignmentCardContent
          onClick={() =>
            onSuccessAssignmentClick({
              appType: assignmentCardQuery.data.app_type,
              product: assignmentCardQuery.data.product,
              clientType: assignmentCardQuery.data.client_type,
              appId: assignmentCardQuery.data.app_id,
            })
          }>
          {/* Awareness Chip */}
          {/* Urgent Level */}
          {assignmentCardQuery.data.urgent_level ? (
            <AwarenessChip
              awarenessType="urgent"
              urgentLevel={assignmentCardQuery.data.urgent_level}
              needTopFlat={true}
              needBottomFlat={true}
            />
          ) : null}
          {/* Is Payment Term */}
          {assignmentCardQuery.data.is_payment_term_awareness ? (
            <AwarenessChip
              awarenessType="payment"
              isPayment={assignmentCardQuery.data.is_payment_term_awareness}
              needTopFlat={true}
              needBottomFlat={true}
            />
          ) : null}
          {/* Body */}
          <Box css={{ p: 10 }}>
            <Box css={{ display: 'flex', columnGap: 6 }}>
              {/* Doc Icon */}
              <Box
                css={{
                  width: 32,
                  height: 32,
                  flexShrink: 0,
                }}>
                <DocIcon
                  statusIconSmall
                  docType={assignmentCardQuery.data.icon_map.doc_type}
                  docIconBGColor={assignmentCardQuery.data.icon_map.doc_icon_bg}
                  docIconStatus={assignmentCardQuery.data.icon_map.doc_icon_status}
                />
              </Box>
              {/* Application Name */}
              <Box css={{ flexGrow: 1 }}>
                <Text
                  css={{
                    display: '-webkit-box',
                    '-webkit-line-clamp': 2,
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {`${assignmentCardQuery.data.name}`}
                  {assignmentCardQuery.data.app_type === 'official' ? (
                    <Box css={{ display: 'inline-block', ml: 4 }}>
                      <MSymbol iconName="verified" size={12} css={{ color: '$primary50' }} />
                    </Box>
                  ) : null}
                </Text>
              </Box>
              {/* Menu */}
              <PopoverRoot open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
                <PopoverTrigger asChild>
                  <IconButton
                    data-button="more"
                    iconName={'more_horiz'}
                    size={'tiny'}
                    css={{ ml: 'auto' }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </PopoverTrigger>
                <PopoverPortal>
                  <PopoverContent
                    sideOffset={5}
                    align={'end'}
                    css={{ p: 10 }}
                    onClick={(e) => e.stopPropagation()}>
                    {assignmentCardOptions.map((option) => (
                      <PopoverClose key={option.id} asChild>
                        <SelectOption
                          value={option.value}
                          css={{ height: 40 }}
                          onClick={() => {
                            if (option.value === 'view-details') {
                              setCurAppDetailTab('approval');
                              if (assignmentCardQuery.data.app_type === 'official') {
                                navigate(`/applications/application-details/${appId}`);
                              } else {
                                navigate(
                                  `/applications/detail/${assignmentCardQuery.data.app_type}/gcp/standard/${appId}`
                                );
                              }
                            }
                            if (option.value === 'view-document') {
                              setCurAppDetailTab('document');
                              setIsAppDetailPanelsOpen(false);
                              if (assignmentCardQuery.data.app_type === 'official') {
                                navigate(`/applications/application-details/${appId}`);
                              } else {
                                navigate(
                                  `/applications/detail/${assignmentCardQuery.data.app_type}/gcp/standard/${appId}`
                                );
                              }
                            }
                          }}>
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                            <MSymbol
                              iconName={`${option.iconName}`}
                              fill={false}
                              css={{ fontSize: '18px !important' }}
                            />
                            <Text>{`${option.name}`}</Text>
                          </Box>
                        </SelectOption>
                      </PopoverClose>
                    ))}
                  </PopoverContent>
                </PopoverPortal>
              </PopoverRoot>
            </Box>
            <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
            {/* Modified At */}
            <Caption css={{ color: '$text-secondary' }}>{`${moment(
              assignmentCardQuery.data.modified_at
            ).format('MM/DD/YY HH:mm A (Z)')}`}</Caption>
            <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
            {/* Approver */}
            {assignmentCardQuery.data.approver_user_info ? (
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                <Caption css={{ color: '$text-secondary' }}>{'Approver'}</Caption>
                <ButtonText size="12" bold css={{ color: '$primary50' }}>
                  {assignmentCardQuery.data.approver_user_info.name || '-'}
                </ButtonText>
              </Box>
            ) : null}
            {/* Attention to */}
            {assignmentCardQuery.data.attention_to_user_info ? (
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                <Caption css={{ color: '$text-secondary' }}>{'Attention to'}</Caption>
                <ButtonText size="12" bold css={{ color: '$primary50' }}>
                  {assignmentCardQuery.data.attention_to_user_info.name || '-'}
                </ButtonText>
              </Box>
            ) : null}
            {/* Applicant */}
            {assignmentCardQuery.data.applicant_user_info ? (
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                <Caption css={{ color: '$text-secondary' }}>{'Applicant'}</Caption>
                <ButtonText size="12" bold css={{ color: '$primary50' }}>
                  {assignmentCardQuery.data.applicant_user_info.name || '-'}
                </ButtonText>
              </Box>
            ) : null}
          </Box>
          {/* Footer */}
          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 10,
              pb: 10,
            }}>
            {/* Attachment and Comment Total */}
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '$5',
              }}>
              {/* Attachment Total */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: '$1',
                  color:
                    assignmentCardQuery.data.attachment_count === 0
                      ? '$func-disabled-dark'
                      : '$text-secondary',
                }}>
                <Text css={{ color: 'inherit' }}>
                  {`${assignmentCardQuery.data.attachment_count}`}
                </Text>
                <MSymbol
                  iconName={`attach_file`}
                  fill={false}
                  size={18}
                  css={{ color: 'inherit' }}
                />
              </Box>
              {/* Comment Total */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: '$1',
                  color:
                    assignmentCardQuery.data.comment_count === 0
                      ? '$func-disabled-dark'
                      : '$text-secondary',
                }}>
                <Text css={{ color: 'inherit' }}>
                  {`${assignmentCardQuery.data.comment_count}`}
                </Text>
                <MSymbol iconName={`chat`} fill={false} size={18} css={{ color: 'inherit' }} />
              </Box>
            </Box>
            {/* Status */}
            <ApprovalStatusCaption
              textSize="button-text-12"
              status={assignmentCardQuery.data.application_status}
            />
          </Box>
        </AssignmentCardContent>
      ) : assignmentCardQuery.isError ? (
        <CenteredBoxFlex css={{ p: 10 }} onClick={(e) => e.stopPropagation()}>
          <Box as="img" src={ErrorMonoBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
            <Heading6>{`Something's gone wrong`}</Heading6>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Text semibold>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Text>
          </Box>
          <Button
            size="sm"
            isLoading={assignmentCardQuery.isLoading}
            onClick={() => {
              assignmentCardQuery.refetch();
            }}>
            <ButtonText size="14">Refresh</ButtonText>
          </Button>
        </CenteredBoxFlex>
      ) : null}
    </AssignmentCardRoot>
  ) : null;
};

export default AssignmentCard;
