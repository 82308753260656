import { ChangeEvent, ReactNode, useRef } from 'react';
import { styled } from '../../styles/stitches.config';
import { motion } from 'framer-motion';
import { fadeInOutMotion } from '../../styles/motions';
import Box from '../box';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverArrow,
} from '../dropdown/popover';
import { Text, Caption, ButtonText } from '../typography/text';
import Button from '../button/button';
import MSymbol from '../icon/m-symbol';
import IconButton from '../button/icon-button';
import DocAppDefaultIcon from '../../assets/images/icon-doc-app-default.svg';
import PopupErrorIcon from '../../assets/images/icon-popup-error.svg';

const FileUploaderRoot = styled('div', {
  border: '1px solid $func-border-main',
  borderRadius: 10,
  p: 10,
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
});
const FileUploaderThumbnail = styled('div', {
  flexShrink: 0,
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  overflow: 'hidden',
});
const FileCardThumbnailImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const FileUploaderContent = styled('div', {
  flex: 1,
});
const FileUploaderContentHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: 4,
});

interface FileUploaderProps {
  required?: boolean;
  title: string;
  desc?: string;
  fieldName?: string;
  onChange: (newFile: File | File[] | null) => void;
  disabled?: boolean;
  isError?: boolean;
  error?: string;
  needInfo?: boolean;
  allowedFileType: string[];
  infoElement?: ReactNode;
  isMultipleFiles?: boolean;
  prevSelectedFiles?: File[] | null;
}

const FileUploader = ({
  required,
  title,
  desc,
  fieldName,
  onChange,
  disabled,
  isError,
  error,
  needInfo = false,
  allowedFileType,
  infoElement,
  isMultipleFiles = false,
  prevSelectedFiles = null,
}: FileUploaderProps) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const onFileChosen = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isMultipleFiles) {
      const selectedFiles = e.target.files || null;
      if (!selectedFiles || selectedFiles.length <= 0) {
        return;
      }
      if (!prevSelectedFiles || prevSelectedFiles.length <= 0) {
        onChange(Array.from(selectedFiles));
      } else {
        onChange([...Array.from(prevSelectedFiles), ...Array.from(selectedFiles)]);
      }
    } else {
      const selectedFile = e.target.files ? e.target.files[0] : null;
      onChange(selectedFile);
    }
  };
  return (
    <FileUploaderRoot>
      <FileUploaderThumbnail>
        <FileCardThumbnailImage src={DocAppDefaultIcon} />
      </FileUploaderThumbnail>
      <FileUploaderContent>
        <FileUploaderContentHeader>
          <Text>
            {`${title}`}
            {required ? (
              <Text css={{ display: 'inline-block', color: '$error60', ml: 4 }}>{`*`}</Text>
            ) : (
              ''
            )}
          </Text>
          {needInfo ? (
            <PopoverRoot>
              <PopoverTrigger asChild>
                <IconButton type="button" iconName="info" isIconFill={false} />
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent
                  collisionPadding={20}
                  sideOffset={5}
                  css={{
                    p: '$5',
                    maxWidth: 700,
                    maxHeight: 'var(--radix-popover-content-available-height)',
                  }}>
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: 'calc(var(--radix-popover-content-available-height) - 20px)',
                    }}>
                    {infoElement || null}
                  </Box>
                  <PopoverArrow />
                </PopoverContent>
              </PopoverPortal>
            </PopoverRoot>
          ) : null}
        </FileUploaderContentHeader>
        {desc ? <Caption css={{ color: '$text-secondary' }}>{`${desc}`}</Caption> : null}
        {isError ? (
          <Box
            as={motion.div}
            initial={'hide'}
            variants={fadeInOutMotion}
            animate={isError ? 'show' : 'hide'}>
            <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
              <Box
                as="img"
                src={PopupErrorIcon}
                css={{
                  objectFit: 'contain',
                  width: 18,
                  height: 18,
                }}
              />
              <Caption
                css={{
                  ml: '$1',
                  color: '$error60',
                }}>{`${error || ''}`}</Caption>
            </Box>
          </Box>
        ) : null}
      </FileUploaderContent>
      <Box css={{ ml: 'auto' }}>
        <Button
          size="sm"
          onClick={onFileChosen}
          disabled={disabled}
          startElement={
            <MSymbol
              iconName="file_upload"
              weight={700}
              css={{ color: 'inherit', fontSize: '16px !important' }}
            />
          }>
          <ButtonText size={14} bold>
            Upload
          </ButtonText>
          <input
            ref={hiddenFileInput}
            name={fieldName}
            type="file"
            multiple={isMultipleFiles}
            accept={allowedFileType.join(',')}
            style={{ display: 'none' }}
            onChange={onFileChange}
          />
        </Button>
      </Box>
    </FileUploaderRoot>
  );
};

export default FileUploader;
