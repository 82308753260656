import React from 'react';
import { AwarenessType } from '../../types/constants';
import { getAwarenessVariant } from '../../utils/get-variant';
import Box from '../box';
import MSymbol from '../icon/m-symbol';
import { ButtonText } from '../typography/text';

interface AwarenessChipProps {
  awarenessType: AwarenessType;
  urgentLevel?: number;
  expiredCode?: number;
  isPayment?: boolean;
  needTopFlat?: boolean;
  needBottomFlat?: boolean;
  customWidth?: number | string;
}

const AwarenessChip = ({
  awarenessType,
  urgentLevel,
  expiredCode,
  isPayment,
  needTopFlat = false,
  needBottomFlat = false,
  customWidth = 'auto',
}: AwarenessChipProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        px: '10px',
        alignItems: 'center',
        backgroundColor: getAwarenessVariant({ awarenessType, urgentLevel, expiredCode, isPayment })
          ?.backgroundColor,
        borderTopLeftRadius: needTopFlat ? 0 : '$5',
        borderTopRightRadius: needTopFlat ? 0 : '$5',
        borderBottomLeftRadius: needBottomFlat ? 0 : '$5',
        borderBottomRightRadius: needBottomFlat ? 0 : '$5',
        height: '26px',
        columnGap: '$1',
        width: customWidth ? customWidth : 'auto',
      }}>
      <MSymbol
        size={18}
        iconName={
          getAwarenessVariant({ awarenessType, urgentLevel, expiredCode, isPayment })?.icon || ''
        }
        fill={false}
        css={{
          color: getAwarenessVariant({ awarenessType, urgentLevel, expiredCode, isPayment })?.color,
        }}
      />
      <ButtonText
        size={'12'}
        bold
        css={{
          color: getAwarenessVariant({ awarenessType, urgentLevel, expiredCode, isPayment })?.color,
        }}>{`${
        getAwarenessVariant({ awarenessType, urgentLevel, expiredCode, isPayment })?.wording || ''
      }`}</ButtonText>
    </Box>
  );
};

export default AwarenessChip;
