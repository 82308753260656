import { useAxios } from '../hooks';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../store/atoms';
import { AxiosError } from 'axios';

export const usePostSyncCustomer = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async () => {
      try {
        const { data } = await axios.post(`/v1/sync/customer`);
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['customer']);
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};
