export const getRange = (start: number, end: number) => {
  const step = 1;
  return Array.from({ length: (end - start) / step + 1 }, (_, i) => (start + i * step).toString());
};

export const getPaginationParamNumber = (options: { num: string; type: 'limit' | 'offset' }) => {
  if (isNaN(parseInt(options.num, 10))) {
    return options.type === 'limit' ? 20 : 0;
  } else {
    return parseInt(options.num, 10);
  }
};
