import type { ReactNode, FormEvent } from 'react';
import type { TLength } from '../../types';
import type * as CSS from 'csstype';
import Box from '../box';

const FormControl = ({
  width = '100%',
  maxWidth,
  children,
  onSubmit,
}: {
  width?: CSS.Property.Width<TLength> | undefined;
  maxWidth?: CSS.Property.MaxWidth<TLength> | undefined;
  children?: ReactNode;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}) => {
  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(e);
    }
  };
  return (
    <Box
      as="form"
      css={{
        width: width,
        maxWidth: maxWidth,
      }}
      onSubmit={(e: FormEvent<HTMLFormElement>) => handleOnSubmit(e)}>
      {children}
    </Box>
  );
};

export default FormControl;
