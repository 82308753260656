import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { ProductType } from '../../../types/application/constants';
import { AppStatus, CommonAPIResponse } from '../../../types';
import {
  GetAppDetailApprovalTabResponse,
  GetAppDetailGCPContractRequestTabResponse,
  GetAppDetailPLPSContractRequestTabResponse,
  GetAppDetailGWSContractRequestTabResponse,
  GetAppDetailSealRequestTabResponse,
} from '../../../types/application/verified/app-detail-tabs';

export const useGetAppDetailApprovalTab = (options: {
  appId: string;
  appStatus: AppStatus | '';
}) => {
  const axios = useAxios();
  return useQuery<GetAppDetailApprovalTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'approval-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/approval_tab`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId && !!options.appStatus && options.appStatus !== 'draft',
  });
};

export const useGetAppDetailContractRequestTabGCP = (options: {
  product: ProductType | '';
  appId: string;
}) => {
  const axios = useAxios();
  return useQuery<GetAppDetailGCPContractRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'contract-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/gcp_contract_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.product && !!options.appId,
  });
};
export const useGetAppDetailContractRequestTabPLPS = (options: {
  product: ProductType | '';
  appId: string;
}) => {
  const axios = useAxios();
  return useQuery<GetAppDetailPLPSContractRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'contract-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/plps_contract_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.product && !!options.appId,
  });
};
export const useGetAppDetailContractRequestTabGWS = (options: {
  product: ProductType | '';
  appId: string;
}) => {
  const axios = useAxios();
  return useQuery<GetAppDetailGWSContractRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'contract-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/gws_contract_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.product && !!options.appId,
  });
};

export const useGetAppDetailSealRequestTabGCP = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailSealRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'seal-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/gcp_seal_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};
export const useGetAppDetailSealRequestTabPLPS = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailSealRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'seal-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/plps_seal_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};
export const useGetAppDetailSealRequestTabGWS = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailSealRequestTabResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-tabs', 'seal-request-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/gws_seal_request`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};
