import { ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import * as Yup from 'yup';
import { ClientSourceType } from '../../../../../../types/application/constants';
import { PostSimpStdAppAttPayload } from '../../../../../../types/application/simplified/create';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../../../../styles/motions';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../../../store/atoms';
import { curSubmissionStepAtom } from '../../../../../../store/application/atoms';
import {
  postSimpStdAppAttPayloadAtom,
  postSimpGWSStdAppPayloadAtom,
} from '../../../../../../store/application/create/simplified/atoms';
import { usePostSimpStdApp } from '../../../../../../services/application/simplified-standard/app.api';
import { usePostSimpStdAppAtt } from '../../../../../../services/application/simplified-standard/attachment.api';
import { scrollToFirstError } from '../../../../../../utils/scroll';
import Box from '../../../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../../../components/typography/text';
import { Heading5 } from '../../../../../../components/typography/heading';
import Spacer from '../../../../../../components/surface/spacer';
import ColorfulChip from '../../../../../../components/chip/colorful-chip';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../../../components/helper/dialog-alert';
import Button from '../../../../../../components/button/button';
import MSymbol from '../../../../../../components/icon/m-symbol';
import FileUploader from '../../../../../../components/helper/file-uploader';
import FileBlobCard from '../../../../../../components/card/file-blob-card';
import WizardBar from '../../../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
} from '../../../../../../components/surface/submission-paper';
import PopupErrorIcon from '../../../../../../assets/images/icon-popup-error.svg';

const ALLOWED_FILE_MAX_SIZE = 2097152; // * 2 MB
const ALLOWED_FILE_TYPE = ['application/pdf', 'image/jpeg', 'image/png'];

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  company_registration: Yup.mixed<File>()
    .nullable()
    .test('company_registration', 'This field is required', (value, context) => {
      const curClientSource = context.from?.[0].value.client_source;
      return curClientSource === 'oracle' || !!value;
    })
    .test('company_registration_size', 'Maximum file size 2 MB', (value) => {
      return value ? value.size <= ALLOWED_FILE_MAX_SIZE : true;
    }),
  random_file: Yup.array()
    .nullable()
    .of(
      Yup.mixed<File>().test('random_file_size', 'Maximum file size 2 MB', (value) => {
        if (!value) {
          return true; // * Skip validation for null or undefined
        }
        return value.size <= ALLOWED_FILE_MAX_SIZE;
      })
    )
    .test('random_file_length', 'Maximum of 10 files allowed for upload', (value) => {
      return !value || value?.length <= 10;
    }),
});

const Additional = () => {
  const navigate = useNavigate();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubmissionStep = useSetAtom(curSubmissionStepAtom);
  const [postSimpStdAppAttPayload, setPostSimpStdAppAttPayload] = useAtom(
    postSimpStdAppAttPayloadAtom
  );
  const postSimpStdAppPayload = useAtomValue(postSimpGWSStdAppPayloadAtom);

  const { control, handleSubmit, getValues, setValue, formState } = useForm<
    PostSimpStdAppAttPayload & { client_source: ClientSourceType }
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...postSimpStdAppAttPayload,
      client_source: postSimpStdAppPayload.client_source,
    },
    resolver: yupResolver(validationSchema),
  });
  const curCompanyRegistration = useWatch({ control, name: 'company_registration' });
  const curRandomFiles = useWatch({ control, name: 'random_file' });

  const postAppMutation = usePostSimpStdApp();
  const postAttachmentMutation = usePostSimpStdAppAtt({
    onSuccess: (res, appStatus) => {
      postAppMutation.mutate({ ...postSimpStdAppPayload, ...res, status: appStatus });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const hiddenRandomFilesInput = useRef<HTMLInputElement | null>(null);
  const onRandomFilesChosen = () => {
    if (hiddenRandomFilesInput.current) {
      hiddenRandomFilesInput.current.click();
    }
  };
  const onRandomFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const prevSelectedFiles = getValues('random_file');
    const selectedFiles = e.target.files || null;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    if (!prevSelectedFiles || prevSelectedFiles.length <= 0) {
      setValue('random_file', Array.from(selectedFiles), { shouldValidate: true });
    } else {
      setValue('random_file', [...Array.from(prevSelectedFiles), ...Array.from(selectedFiles)], {
        shouldValidate: true,
      });
    }
  };
  const onRandomFilesCloseClick = (randomFileIndex: number) => {
    if (curRandomFiles && curRandomFiles.length > 0) {
      setValue(
        'random_file',
        [...curRandomFiles.slice(0, randomFileIndex), ...curRandomFiles.slice(randomFileIndex + 1)],
        { shouldValidate: true }
      );
    }
  };

  const onBackClick = () => {
    setPostSimpStdAppAttPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubmissionStep(2);
  };
  const onNextClick: SubmitHandler<PostSimpStdAppAttPayload> = (data) => {
    setPostSimpStdAppAttPayload((prev) => ({ ...prev, ...data }));
    setCurSubmissionStep(4);
  };
  const onNextError = (errors: unknown) => {
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({ ...getValues(), appStatus: 'draft' });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Required documents */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Required documents</Heading5>
          <ColorfulChip text="Simplified" color="turquoise" variant="solid" />
        </SubmissionPaperHeader>
        <SubmissionSection>
          <SubmissionRow>
            {/* Company Registration */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="company_registration"
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <FileUploader
                    required={postSimpStdAppPayload.client_source !== 'oracle' ? true : false}
                    disabled={postSimpStdAppPayload.client_source === 'oracle' ? true : false}
                    allowedFileType={ALLOWED_FILE_TYPE}
                    title={'Company Registration'}
                    desc={
                      'If not an existing customer, the company registration certificate is required'
                    }
                    fieldName={name}
                    onChange={onChange}
                    isError={error?.type === 'company_registration'}
                    error={error?.type === 'company_registration' ? error.message : ''}
                  />
                )}
              />
              <Spacer axis="vertical" css={{ $$size: '10px' }} />
              {curCompanyRegistration ? (
                <Box
                  as={motion.div}
                  initial={'hide'}
                  variants={fadeInOutMotion}
                  animate={curCompanyRegistration ? 'show' : 'hide'}>
                  <FileBlobCard
                    file={curCompanyRegistration}
                    isError={
                      formState.errors.company_registration?.type === 'company_registration_size'
                    }
                    error={
                      formState.errors.company_registration?.type === 'company_registration_size'
                        ? formState.errors.company_registration?.message
                        : ''
                    }
                    onCloseClick={() =>
                      setValue('company_registration', null, { shouldValidate: true })
                    }
                  />
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
        <SubmissionSection>
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
            <Box>
              <Heading5>Other documents</Heading5>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Caption css={{ color: '$text-secondary' }}>
                You can upload any supplementary documents in the field provided.
              </Caption>
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={curRandomFiles && curRandomFiles.length > 10 ? 'show' : 'hide'}>
                <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
                  <Box
                    as="img"
                    src={PopupErrorIcon}
                    css={{
                      objectFit: 'contain',
                      width: 18,
                      height: 18,
                    }}
                  />
                  <Caption
                    css={{
                      ml: '$1',
                      color: '$error60',
                    }}>{`${formState.errors.random_file?.message || ''}`}</Caption>
                </Box>
              </Box>
            </Box>
            <Controller
              control={control}
              name="random_file"
              render={({ field: { name } }) => (
                <Button
                  name={name}
                  size="sm"
                  onClick={onRandomFilesChosen}
                  startElement={
                    <MSymbol
                      iconName="file_upload"
                      weight={700}
                      css={{ color: 'inherit', fontSize: '16px !important' }}
                    />
                  }>
                  <ButtonText size={14} bold>
                    Upload
                  </ButtonText>
                  <input
                    ref={hiddenRandomFilesInput}
                    name={name}
                    type="file"
                    multiple
                    accept={ALLOWED_FILE_TYPE.join(',')}
                    style={{ display: 'none' }}
                    onChange={onRandomFilesChange}
                  />
                </Button>
              )}
            />
          </Box>
          {curRandomFiles && curRandomFiles.length > 0 ? (
            <Box
              as={motion.div}
              initial={'hide'}
              variants={fadeInOutMotion}
              animate={curRandomFiles && curRandomFiles.length > 0 ? 'show' : 'hide'}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                {Array.from(curRandomFiles).map((randomFile, randomFileIndex) => (
                  <FileBlobCard
                    key={nanoid()}
                    file={randomFile}
                    isError={
                      formState.errors.random_file
                        ? formState.errors.random_file[randomFileIndex]?.type === 'random_file_size'
                        : false
                    }
                    error={
                      formState.errors.random_file
                        ? `${formState.errors.random_file[randomFileIndex]?.message}`
                        : ''
                    }
                    onCloseClick={() => onRandomFilesCloseClick(randomFileIndex)}
                  />
                ))}
              </Box>
            </Box>
          ) : null}
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button size="md" variant="borderless" color="achromatic" css={{ mr: 'auto' }}>
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                      onClick={() => onLeaveClick()}>
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  <Button
                    variant="outlined"
                    size="md"
                    onClick={() => onSaveClick()}
                    isLoading={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}
                    css={{ width: 123 }}>
                    <ButtonText size={14} bold>
                      Save as draft
                    </ButtonText>
                  </Button>
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={postAttachmentMutation.isLoading || postAppMutation.isLoading}>
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button size="md" variant="outlined" onClick={() => onBackClick()}>
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button size="md" type="submit">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default Additional;
