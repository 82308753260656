import { AppDetailCommentPanelFilterType } from '../../../../types';
import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  useGetApplicationCommentInfinite,
  useGetApplicationMentionableUserById,
  usePostApplicationComment,
} from '../../../../services/application/official-standard/application.api';
import {
  addCommentContentAtom,
  authUserAtom,
  getApplicationCommentsParamsAtom,
  isAddCommentEditorOpenAtom,
} from '../../../../store/atoms';
import { useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { applicationCommentsFilterByOptions } from '../../../../store/constants';
import { checkAttachmentsLength } from '../../../../utils/check-checked';
import { motion } from 'framer-motion';
import { Mention } from 'react-mentions';
import Box from '../../../../components/box';
import Typography from '../../../../components/typography/deprecated-typography';
import {
  SimpleSelectContent,
  SimpleSelectIcon,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../../../components/dropdown/simple-select';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import MentionArea from '../../../../components/dropdown/mention-area';
import MSymbol from '../../../../components/icon/m-symbol';
import IconButton from '../../../../components/button/icon-button';
import Button from '../../../../components/button/deprecated-button';
import Divider from '../../../../components/surface/divider';
import Spinner from '../../../../components/helper/spinner';
import Spacer from '../../../../components/surface/spacer';
import Avatar from '../../../../components/surface/avatar';
import CommentCard from '../../../../components/card/comment-card';
import ErrorMonoBG from '../../../../assets/images/bg-error-mono.svg';
import NoCommentBG from '../../../../assets/images/bg-no-comment.svg';
import AttachPDFLightOnIcon from '../../../../assets/images/icon-attach-pdf-light-on.svg';
import PopupErrorIcon from '../../../../assets/images/icon-popup-error.svg';

const COMMENT_HEADER_HEIGHT = 64;
const IMAGE_LIMIT = 8;
const PDF_LIMIT = 2;
const ALLOWED_FILE_MAX_SIZE = 2; // * MB

const Comment = ({ applicationId }: { applicationId?: string }) => {
  const authUser = useAtomValue(authUserAtom);
  const { name, picture } = authUser || {};
  const queryClient = useQueryClient();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [isAddEditorOpen, setIsAddEditorOpen] = useAtom(isAddCommentEditorOpenAtom);
  const [addContents, setAddContents] = useAtom(addCommentContentAtom);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isSelectedFilesLimitExceeded, setIsSelectedFilesLimitExceeded] = useState(false);
  const [isImageFileSizeOver, setIsImageFileSizeOver] = useState(false);
  const [isFileSizeOver, setIsFileSizeOver] = useState(false);

  const [getApplicationCommentsParams, setGetApplicationCommentsParams] = useAtom(
    getApplicationCommentsParamsAtom
  );
  const commentsQueries = useGetApplicationCommentInfinite({
    application_id: applicationId || '',
    ...getApplicationCommentsParams,
  });
  const mentionableUserQueries = useGetApplicationMentionableUserById({
    application_id: applicationId || '',
  });

  const handleOnAddEditorClose = () => {
    setAddContents('');
    setSelectedFiles([]);
    setIsAddEditorOpen(false);
    setIsFileSizeOver(false);
    setIsImageFileSizeOver(false);
    setIsSelectedFilesLimitExceeded(false);
  };

  const postCommentMutation = usePostApplicationComment({
    application_id: applicationId || '',
    onSuccess: () => {
      handleOnAddEditorClose();
      queryClient.setQueryData(
        [
          'application-by-id',
          applicationId,
          'comments',
          getApplicationCommentsParams.offset,
          getApplicationCommentsParams.limit,
          getApplicationCommentsParams.filter_by,
        ],
        (data: any) => ({
          pages: [],
          pageParams: {},
        })
      );
      commentsQueries.refetch({ refetchPage: (page, index) => index === 0 });
    },
  });

  const handleOnPostComment = () => {
    const payloadSelectedFiles = selectedFiles.reduce((payload, file, index) => {
      const key = `file_${index + 1}`;
      payload[key] = file;
      return payload;
    }, {});
    postCommentMutation.mutate({ content: addContents, ...payloadSelectedFiles });
  };
  const handleOnFileChosen = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleOnFileCancelChosen = (fileName: string) => {
    const indexToCancelChosen = selectedFiles.findIndex((file) => file.name === fileName);
    setSelectedFiles((prev) => prev.filter((_, index) => index !== indexToCancelChosen));
  };
  const handleOnFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles((prev) => {
        const currentSelectedFiles = Array.prototype.slice.call(e.target.files);
        if (
          checkAttachmentsLength({ fileType: 'image', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'image', files: prev }) >
            IMAGE_LIMIT ||
          checkAttachmentsLength({ fileType: 'pdf', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'pdf', files: prev }) >
            PDF_LIMIT
        ) {
          setIsSelectedFilesLimitExceeded(true);
          return [...prev];
        }
        setIsSelectedFilesLimitExceeded(false);
        return [...prev, ...currentSelectedFiles];
      });
    }
  };

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      commentsQueries.fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    const hasImageFileSizeOver = selectedFiles
      .filter(
        (file) =>
          file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      )
      .some((file) => file.size > 2 * 1024 * 1024);
    const hasFileSizeOver = selectedFiles.some((file) => file.size > 2 * 1024 * 1024);
    setIsImageFileSizeOver(hasImageFileSizeOver);
    setIsFileSizeOver(hasFileSizeOver);
  }, [selectedFiles]);

  return (
    <>
      {/* Panel Header */}
      <Box
        css={{
          height: '64px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid $secondary20',
          flexShrink: 0,
          px: 20,
        }}>
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
          }}>
          {/* Panel Title */}
          <Typography
            variant={'h5'}
            css={{
              width: 90,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>{`Comment`}</Typography>
          {/* Filter */}
          <Box css={{ width: 126 }}>
            <SimpleSelectRoot
              value={getApplicationCommentsParams.filter_by}
              onValueChange={(newValue) => {
                setGetApplicationCommentsParams((prev) => ({
                  ...prev,
                  filter_by: newValue as AppDetailCommentPanelFilterType,
                }));
              }}>
              <SimpleSelectTrigger>
                <Box css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <SimpleSelectValue placeholder="Modified Date" />
                </Box>
                <SimpleSelectIcon asChild>
                  <Box css={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <MSymbol iconName="arrow_drop_down" css={{ color: 'inherit' }} />
                  </Box>
                </SimpleSelectIcon>
              </SimpleSelectTrigger>
              <SimpleSelectPortal>
                <SimpleSelectContent position="popper" sideOffset={4} css={{ width: 180 }}>
                  <SimpleSelectViewport>
                    <Box
                      css={{
                        height: 40,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        px: 10,
                      }}>
                      <Typography variant={'h6'}>{`Filter by`}</Typography>
                    </Box>
                    <Divider axis={'horizontal'} css={{ backgroundColor: '$secondary10' }} />
                    {applicationCommentsFilterByOptions.map((filterByOption) => (
                      <SimpleSelectItem key={filterByOption.id} value={filterByOption.value}>
                        <SimpleSelectItemText>{`${filterByOption.name}`}</SimpleSelectItemText>
                      </SimpleSelectItem>
                    ))}
                  </SimpleSelectViewport>
                </SimpleSelectContent>
              </SimpleSelectPortal>
            </SimpleSelectRoot>
          </Box>
        </Box>
        {/* Add New Comment Button */}
        {commentsQueries.isSuccess ? (
          <IconButton
            iconName="add"
            isIconBold={true}
            size="small"
            color="primary"
            onClick={() => {
              if (!isAddEditorOpen) {
                setIsAddEditorOpen(true);
              } else {
                handleOnAddEditorClose();
              }
            }}
          />
        ) : null}
      </Box>
      {/* Panel Body */}
      <Box css={{ height: `calc(100% - ${COMMENT_HEADER_HEIGHT}px)` }}>
        <ScrollAreaRoot css={{ width: 320, px: 20 }}>
          <ScrollAreaViewport>
            {commentsQueries.status === 'loading' ? (
              <Box
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                }}>
                <Spinner size={'medium'} />
              </Box>
            ) : commentsQueries.status === 'error' ? (
              <Box
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '$5',
                }}>
                <Box as="img" src={ErrorMonoBG} />
                <Box css={{ width: '100%', maxWidth: 280, textAlign: 'center' }}>
                  <Typography variant="h6">{`Something's gone wrong`}</Typography>
                  <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                  <Typography variant="body-medium">
                    {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                  </Typography>
                </Box>
                <Button
                  variant="solid"
                  size={'small'}
                  isLoading={commentsQueries.isLoading}
                  onClick={() => {
                    commentsQueries.refetch();
                  }}>{`Refresh`}</Button>
              </Box>
            ) : (
              <>
                <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                {/* Add Editor */}
                <Box
                  as={motion.div}
                  initial="hide"
                  animate={isAddEditorOpen ? 'show' : 'hide'}
                  variants={{
                    show: { opacity: 1, display: 'flex' },
                    hide: {
                      opacity: 0,
                      transitionEnd: { display: 'none' },
                    },
                  }}
                  css={{
                    mb: 10,
                    borderRadius: '$5',
                    border: '1px solid $secondary20',
                    flexDirection: 'column',
                    rowGap: 10,
                    p: 10,
                    width: 280,
                  }}>
                  {/* Avatar */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: '100%' }}>
                    <Avatar name={name || '-'} picture={picture || ''} />
                    <Box css={{ flexGrow: 1 }}>
                      <Typography as={'p'} variant={'body-bold'}>
                        {name || '-'}
                      </Typography>
                    </Box>
                    {/* Close Button */}
                    <Box css={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton iconName="close" size="small" onClick={handleOnAddEditorClose} />
                    </Box>
                  </Box>
                  {/* React Mentions */}
                  <MentionArea
                    value={addContents}
                    onChange={(e, newValue) => setAddContents(newValue)}>
                    <Mention
                      trigger="@"
                      className={`${MentionArea.className}__mention`}
                      appendSpaceOnAdd={true}
                      displayTransform={(id, display) => {
                        return `@${display}`;
                      }}
                      data={mentionableUserQueries.data || []}
                    />
                  </MentionArea>
                  {/* Selected attachments (Images) */}
                  {checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 0 ? (
                    <>
                      <Box
                        css={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          rowGap: 6,
                          columnGap:
                            checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 2
                              ? 6
                              : 10,
                          height:
                            checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) <
                              3 ||
                            checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 4
                              ? 125
                              : 60,
                        }}>
                        {selectedFiles
                          .filter(
                            (file) =>
                              file.type === 'image/jpg' ||
                              file.type === 'image/jpeg' ||
                              file.type === 'image/png'
                          )
                          .map((image) => (
                            <Box
                              key={image.name}
                              css={{
                                borderRadius: '$10',
                                overflow: 'hidden',
                                flexBasis:
                                  checkAttachmentsLength({
                                    fileType: 'image',
                                    files: selectedFiles,
                                  }) > 2
                                    ? `calc(25% - ${18 / 4}px)`
                                    : checkAttachmentsLength({
                                        fileType: 'image',
                                        files: selectedFiles,
                                      }) === 2
                                    ? `calc(50% - 5px)`
                                    : `calc(100%)`,
                                border: '1px solid $secondary10',
                                height:
                                  checkAttachmentsLength({
                                    fileType: 'image',
                                    files: selectedFiles,
                                  }) > 4
                                    ? 'calc(50% - 3px)'
                                    : '100%',
                                position: 'relative',
                                '&:hover': {
                                  '> .attachment-mask': {
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                  },
                                  '> .attachment-cancel-chosen': {
                                    visibility: 'visible',
                                  },
                                },
                              }}>
                              <IconButton
                                iconName="close"
                                isIconBold={true}
                                size={'mini'}
                                className="attachment-cancel-chosen"
                                css={{
                                  position: 'absolute',
                                  top: 6,
                                  right: 6,
                                  zIndex: 90,
                                  color: '$white',
                                  visibility: 'hidden',
                                }}
                                onClick={() => handleOnFileCancelChosen(image.name)}
                              />
                              <Box
                                className="attachment-mask"
                                css={{
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  left: 0,
                                  zIndex: 85,
                                  transition: '$colors',
                                  backgroundColor: '$transparent',
                                }}
                              />
                              {Math.round((image.size / (1024 * 1024)) * 100) / 100 >
                              ALLOWED_FILE_MAX_SIZE ? (
                                <Box
                                  css={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}>
                                  <Box as="img" src={PopupErrorIcon} css={{ width: 30 }}></Box>
                                </Box>
                              ) : null}
                              <Box
                                as="img"
                                src={URL.createObjectURL(image)}
                                css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                              />
                            </Box>
                          ))}
                      </Box>
                    </>
                  ) : null}
                  {/* Selected attachments (PDFs) */}
                  {checkAttachmentsLength({ fileType: 'pdf', files: selectedFiles }) > 0 ? (
                    <>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 10,
                        }}>
                        {selectedFiles
                          .filter((file) => file.type === 'application/pdf')
                          .map((pdf) => (
                            <Box
                              key={pdf.name}
                              css={{
                                position: 'relative',
                                borderRadius: '$5',
                                overflow: 'hidden',
                                '&:hover': {
                                  '> .attachment-mask': {
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                  },
                                  '> .attachment-cancel-chosen': {
                                    visibility: 'visible',
                                  },
                                },
                              }}>
                              <IconButton
                                iconName="close"
                                isIconBold={true}
                                size={'mini'}
                                className="attachment-cancel-chosen"
                                css={{
                                  position: 'absolute',
                                  top: 6,
                                  right: 6,
                                  zIndex: 90,
                                  color: '$white',
                                  visibility: 'hidden',
                                }}
                                onClick={() => handleOnFileCancelChosen(pdf.name)}
                              />
                              <Box
                                className="attachment-mask"
                                css={{
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  left: 0,
                                  zIndex: 85,
                                  transition: '$colors',
                                  backgroundColor: '$transparent',
                                }}
                              />
                              <Box
                                css={{
                                  backgroundColor: '$secondary5',
                                  px: 5,
                                  py: 6,
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 6,
                                  justifyContent: 'space-between',
                                }}>
                                <Box as="img" src={AttachPDFLightOnIcon} />
                                <Box
                                  css={{
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 206,
                                  }}>
                                  <Typography
                                    as="p"
                                    variant={'body-medium'}
                                    css={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}>
                                    {pdf.name || '-'}
                                  </Typography>
                                  <Typography
                                    as="p"
                                    variant={'caption'}
                                    css={{
                                      color: '$secondary40',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}>
                                    {`${Math.round((pdf.size / (1024 * 1024)) * 100) / 100} MB`}
                                  </Typography>
                                  {Math.round((pdf.size / (1024 * 1024)) * 100) / 100 >
                                  ALLOWED_FILE_MAX_SIZE ? (
                                    <Typography
                                      as="p"
                                      variant={'caption'}
                                      css={{
                                        color: '$error70',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                      }}>
                                      {`Maximum file size 2MB`}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </>
                  ) : null}
                  {/* Attachments */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                    {/* Select File Button */}
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <Box css={{ width: 'fit-content' }}>
                          <IconButton
                            iconName="attach_file"
                            color={'secondary'}
                            onClick={handleOnFileChosen}
                          />
                          <input
                            ref={hiddenFileInput}
                            type="file"
                            multiple
                            accept="application/pdf, image/png, image/jpg, image/jpeg"
                            style={{ display: 'none' }}
                            onChange={handleOnFileChange}
                          />
                        </Box>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`top`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Attach a file`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                    {/* Selected files limit exceeded */}
                    <Box
                      as={motion.div}
                      initial="hide"
                      animate={
                        isSelectedFilesLimitExceeded || isImageFileSizeOver ? 'show' : 'hide'
                      }
                      variants={{
                        show: { display: 'flex', opacity: 1 },
                        hide: { opacity: 0, transitionEnd: { display: 'none' } },
                      }}
                      css={{ alignItems: 'center' }}>
                      {isSelectedFilesLimitExceeded ? (
                        <Typography
                          variant={'caption'}
                          css={{
                            color: '$error70',
                          }}>{`File limitation : Up to 8 images and a maximum of 2 PDFs.`}</Typography>
                      ) : null}
                      {isImageFileSizeOver ? (
                        <Typography
                          as="p"
                          variant={'caption'}
                          css={{
                            color: '$error70',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}>
                          {`Maximum file size 2MB`}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  {/* Cancel, Save Button */}
                  <Box
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 10,
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}>
                    <Button
                      size="tiny"
                      color="secondary-primary"
                      variant="outlined"
                      onClick={handleOnAddEditorClose}>{`Cancel`}</Button>
                    <Button
                      size="tiny"
                      onClick={handleOnPostComment}
                      disabled={isFileSizeOver}
                      isLoading={postCommentMutation.isLoading}>{`Save`}</Button>
                  </Box>
                </Box>
                {commentsQueries.isSuccess && commentsQueries.data.pages.length > 0 ? (
                  <>
                    {commentsQueries.data.pages.map((page, pageIndex) => {
                      return (
                        <Fragment key={pageIndex}>
                          {page.application_comments.length > 0 ? (
                            page.application_comments.map((comment) => {
                              return (
                                <CommentCard
                                  key={comment.id}
                                  applicationId={comment.application_id}
                                  commentId={comment.id}
                                />
                              );
                            })
                          ) : isAddEditorOpen ? null : (
                            <Box
                              css={{
                                // position: 'absolute',
                                // top: '50%',
                                // left: '50%',
                                // transform: 'translate(-50%, -50%)',
                                mt: 145,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                rowGap: 10,
                              }}>
                              <Box as="img" src={NoCommentBG} />
                              <Typography
                                as={`p`}
                                variant={'body-medium'}
                                align={'center'}
                                css={{ color: '$secondary70' }}>
                                {`No comments at this point`}
                              </Typography>
                            </Box>
                          )}
                        </Fragment>
                      );
                    })}
                    <Box ref={ref} css={{ mt: 20, mb: 30 }}>
                      <Typography
                        as="p"
                        align={'center'}
                        variant={'caption'}
                        css={{ color: '$primary50' }}>
                        {commentsQueries.isFetchingNextPage
                          ? 'Loading more...'
                          : `No more comments`}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </>
            )}
            <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </ScrollAreaRoot>
      </Box>
    </>
  );
};

export default Comment;
