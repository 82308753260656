export const scrollToTop = () => {
  document.querySelector('#root-scroll-area-viewport')?.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToFirstError = (errors: any) => {
  // * List all error elements by name
  const errorElements = Object.keys(errors)
    .map((name) => document.getElementsByName(name)[0])
    .filter((element) => !!element);

  // * Find the first error element by compare their top position and scroll
  errorElements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
  if (errorElements.length > 0) {
    const firstErrorElement = errorElements[0];
    document
      .querySelector('#root-scroll-area-viewport')
      ?.scrollTo(0, firstErrorElement.offsetTop - 120); // * Minus app bar, breadcrumb, and label height
  }
};
