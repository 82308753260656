import { ComponentProps, forwardRef, ReactNode } from 'react';
import { styled } from '../../styles/stitches.config';
import { rotation } from '../../styles/keyframes';
import Box from '../box';

const ButtonRoot = styled('div', {
  '&[data-disabled=true]': {
    cursor: 'not-allowed',
  },
});

const ButtonContent = styled('button', {
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  transition: '$colors',
  '&:disabled': {
    pointerEvents: 'none',
  },
  variants: {
    color: {
      primary: {},
      secondary: {},
      achromatic: {},
      'achromatic-primary': {},
      success: {},
      warning: {},
      error: {},
    },
    variant: {
      solid: {
        color: '$white',
        border: 'none',
        '&:disabled': {
          backgroundColor: '$func-disabled-dark',
        },
      },
      outlined: {
        backgroundColor: '$white',
        borderStyle: 'solid',
        borderWidth: 1,
        '&:disabled': {
          borderColor: '$func-disabled-dark',
          color: '$func-disabled-dark',
        },
      },
      borderless: {
        backgroundColor: '$transparent',
        border: 'none',
        '&:disabled': {
          color: '$func-disabled-dark',
        },
      },
      dashed: {
        borderStyle: 'dashed',
        borderWidth: 1,
        backgroundColor: '$white',
        '&:disabled': {
          borderColor: '$func-disabled-dark',
          color: '$func-disabled-dark',
        },
      },
    },
    size: {
      xs: {
        px: 10,
        height: 26,
      },
      sm: {
        px: 10,
        height: 30,
      },
      md: {
        px: 16,
        height: 36,
      },
      lg: {
        px: 16,
        height: 44,
      },
    },
    shadow: {
      true: {
        boxShadow: '$basic',
      },
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      variant: 'solid',
      css: {
        backgroundColor: '$primary50',
        '&:hover': {
          backgroundColor: '$primary40',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$primary60',
        },
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      css: {
        color: '$primary50',
        borderColor: '$primary50',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active, &[data-state=active]': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'primary',
      variant: 'borderless',
      css: {
        color: '$primary50',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
        },
        '&:active, &[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
        },
        '&[data-state=active]': {
          color: '$white',
          backgroundColor: '$primary60',
        },
      },
    },
    {
      color: 'primary',
      variant: 'dashed',
      css: {
        color: '$primary50',
        borderColor: '$primary50',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active, &[data-state=active]': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'achromatic',
      variant: 'solid',
      css: {
        backgroundColor: '$achromatic70',
        '&:hover': {
          backgroundColor: '$achromatic60',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$achromatic80',
        },
      },
    },
    {
      color: 'achromatic',
      variant: 'outlined',
      css: {
        color: '$achromatic70',
        borderColor: '$achromatic20',
        '&:hover': {
          backgroundColor: '$achromatic3',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$achromatic10',
        },
      },
    },
    {
      color: 'achromatic',
      variant: 'borderless',
      css: {
        color: '$achromatic70',
        '&:hover': {
          color: '$achromatic70',
          backgroundColor: '$achromatic3',
        },
        '&:active': {
          color: '$achromatic70',
          backgroundColor: '$achromatic10',
        },
        '&[data-state=active]': {
          color: '$white',
          backgroundColor: '$achromatic70',
        },
      },
    },
    {
      color: 'achromatic-primary',
      variant: 'outlined',
      css: {
        color: '$achromatic70',
        borderColor: '$achromatic20',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
          borderColor: '$primary40',
        },
        '&:active, &[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
          borderColor: '$primary60',
        },
      },
    },
    {
      color: 'achromatic-primary',
      variant: 'borderless',
      css: {
        color: '$secondary70',
        '&:hover': {
          color: '$primary40',
          backgroundColor: '$primary10',
        },
        '&:active, &[data-state=open]': {
          color: '$primary60',
          backgroundColor: '$primary20',
        },
      },
    },
    {
      color: 'success',
      variant: 'solid',
      css: {
        backgroundColor: '$success70',
        '&:hover': {
          backgroundColor: '$success60',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$success80',
        },
      },
    },
    {
      color: 'success',
      variant: 'outlined',
      css: {
        color: '$success70',
        borderColor: '$success70',
        '&:hover': {
          color: '$success70',
          backgroundColor: '$success10',
          borderColor: '$success70',
        },
        '&:active, &[data-state=active]': {
          color: '$success80',
          backgroundColor: '$success20',
          borderColor: '$success80',
        },
      },
    },
    {
      color: 'warning',
      variant: 'solid',
      css: {
        backgroundColor: '$warning60',
        '&:hover': {
          backgroundColor: '$warning50',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$warning90',
        },
      },
    },
    {
      color: 'warning',
      variant: 'outlined',
      css: {
        color: '$warning60',
        borderColor: '$warning60',
        '&:hover': {
          color: '$warning50',
          backgroundColor: '$warning10',
          borderColor: '$warning50',
        },
        '&:active, &[data-state=active]': {
          color: '$warning90',
          backgroundColor: '$warning20',
          borderColor: '$warning90',
        },
      },
    },
    {
      color: 'error',
      variant: 'solid',
      css: {
        backgroundColor: '$error60',
        '&:hover': {
          backgroundColor: '$error50',
        },
        '&:active, &[data-state=active]': {
          backgroundColor: '$error90',
        },
      },
    },
    {
      color: 'error',
      variant: 'outlined',
      css: {
        color: '$error60',
        borderColor: '$error60',
        '&:hover': {
          color: '$error50',
          backgroundColor: '$error10',
          borderColor: '$error50',
        },
        '&:active, &[data-state=active]': {
          color: '$error90',
          backgroundColor: '$error20',
          borderColor: '$error90',
        },
      },
    },
    {
      color: 'error',
      variant: 'borderless',
      css: {
        color: '$error60',
        '&:hover': {
          color: '$error50',
          backgroundColor: '$error10',
        },
        '&:active': {
          color: '$error90',
          backgroundColor: '$error20',
        },
        '&[data-state=active]': {
          color: '$white',
          backgroundColor: '$error60',
        },
      },
    },
  ],
  defaultVariants: {
    size: 'xs',
    color: 'primary',
    variant: 'solid',
    shadow: false,
  },
});

const Spinner = styled('div', {
  borderRadius: '$rounded',
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderRightWidth: 2,
  borderRightStyle: 'solid',
  borderLeftWidth: 2,
  borderLeftStyle: 'solid',
  borderTop: '2px solid $achromatic20',
  mx: 'auto',
  animation: `${rotation()} 1s linear infinite`,
  width: '16px',
  height: '16px',
  '&[data-disabled]': {
    borderBottomColor: '$white',
    borderRightColor: '$white',
    borderLeftColor: '$white',
  },
  variants: {
    variant: {
      solid: {
        borderBottomColor: '$white',
        borderRightColor: '$white',
        borderLeftColor: '$white',
      },
      outlined: {},
      borderless: {},
      dashed: {},
    },
    color: {
      achromatic: {
        borderBottomColor: '$white',
        borderRightColor: '$white',
        borderLeftColor: '$white',
      },
      primary: {
        borderBottomColor: '$primary50',
        borderRightColor: '$primary50',
        borderLeftColor: '$primary50',
      },
      success: {
        borderBottomColor: '$success70',
        borderRightColor: '$success70',
        borderLeftColor: '$success70',
      },
      warning: {
        borderBottomColor: '$waring60',
        borderRightColor: '$waring60',
        borderLeftColor: '$waring60',
      },
      error: {
        borderBottomColor: '$error60',
        borderRightColor: '$error60',
        borderLeftColor: '$error60',
      },
    },
  },
  defaultVariants: {
    variant: 'solid',
    color: 'primary',
  },
});

type Variant = 'solid' | 'outlined' | 'borderless' | 'dashed';
type Size = 'xs' | 'sm' | 'md' | 'lg';
type Color =
  | 'primary'
  | 'secondary'
  | 'achromatic'
  | 'achromatic-primary'
  | 'success'
  | 'warning'
  | 'error';
type SpinnerColor = 'achromatic' | 'primary' | 'success' | 'warning' | 'error';
interface ButtonProps extends ComponentProps<typeof ButtonContent> {
  type?: 'button' | 'submit' | 'reset';
  variant?: Variant;
  size?: Size;
  color?: Color;
  disabled?: boolean;
  spinnerColor?: SpinnerColor;
  spinnerVariant?: Variant;
  isLoading?: boolean;
  startElement?: ReactNode;
  endElement?: ReactNode;
  children: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      spinnerColor,
      spinnerVariant,
      children,
      startElement,
      endElement,
      disabled,
      isLoading,
      ...restProps
    },
    ref
  ) => {
    return (
      <ButtonRoot data-disabled={disabled}>
        <ButtonContent ref={ref} type={type} disabled={disabled} {...restProps}>
          {isLoading ? (
            <Spinner color={spinnerColor} variant={spinnerVariant} data-disabled={disabled} />
          ) : (
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: 4,
                ml: endElement ? 2 : 0,
                mr: startElement ? 2 : 0,
              }}>
              {startElement ? <>{startElement}</> : null}
              {children}
              {endElement ? <>{endElement}</> : null}
            </Box>
          )}
        </ButtonContent>
      </ButtonRoot>
    );
  }
);

Button.displayName = 'Button';

export default Button;
