import { useQuery } from 'react-query';
import { useAxios } from '../../../hooks';
import type { IdNameValueItem } from '../../../types';

export const useGetMonthAppOptions = (state: string, idSuffix: string) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>([`app-options-month-${idSuffix}`], async () => {
    const { data } = await axios.get(`/v1/month`, {
      params: { state },
    });
    return data.map(({ id, ...rest }: IdNameValueItem) => ({ id: `${idSuffix}-${id}`, ...rest }));
  });
};

export const useGetCountryDialCodeAppOptions = (isCloudmileEntityOnly: boolean) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`app-options-country-dial-code`, isCloudmileEntityOnly],
    async () => {
      const { data } = await axios.get(`/v1/country_code`, {
        params: {
          cloudmile_entity_only: isCloudmileEntityOnly,
        },
      });
      return data.map((option: IdNameValueItem) => ({
        id: `dial-code-option-${option.id}`,
        name: `(${option.country_code}) ${option.country_dial_code}`,
        value: option.country_dial_code,
      }));
    }
  );
};

export const useGetCountryCodeAppOptions = (isCloudmileEntityOnly: boolean) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`app-options-country-code`, isCloudmileEntityOnly],
    async () => {
      const { data } = await axios.get(`/v1/country_code`, {
        params: {
          cloudmile_entity_only: isCloudmileEntityOnly,
        },
      });
      return data.map((option: IdNameValueItem) => ({
        id: `country-code-option-${option.id}`,
        name: `(${option.country_code}) ${option.country_name}`,
        value: option.country_code,
      }));
    }
  );
};

export const useGetWorkspacePlanAppOptions = (idSuffix: string, workspaceEdition?: string) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    ['app-options-workspace-plan', workspaceEdition],
    async () => {
      const { data } = await axios.get(`/v1/workspace_plan`, {
        params: {
          workspace_edition: workspaceEdition,
        },
      });
      return data.map((option: IdNameValueItem) => ({
        id: `${idSuffix}-option-${option.id}`,
        name: option.value,
        value: option.value,
      }));
    }
  );
};

export const useGetSpecialDealAppOptions = () => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>([`app-options-special-deal`], async () => {
    const { data } = await axios.get(`/v1/special_deal`);
    return data.map((option: IdNameValueItem) => ({
      id: `app-options-special-deal-${option.id}`,
      name: option.value,
      value: option.value,
    }));
  });
};

export const useGetOfficialPricingDiscountAppOptions = () => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>([`app-options-official-pricing-discount`], async () => {
    const { data } = await axios.get(`/v1/official_pricing_discount`);
    return data.map((option: IdNameValueItem) => ({
      id: `app-options-official-pricing-discount-${option.id}`,
      name: option.value,
      value: option.value,
    }));
  });
};
