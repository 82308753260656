import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { nanoid } from 'nanoid';
import { AppStatus, CommonAPIResponse } from '../../../types';
import { PostStdAppAttPayload, PostStdAppAttResponse } from '../../../types/application/constants';

export const usePostStdAppAtt = (options: {
  appId: string | undefined;
  onSuccess: (
    res: PostStdAppAttResponse,
    appStatus: Extract<AppStatus, 'pending' | 'processing' | 'rejected' | 'draft'>
  ) => void;
  onError: (err: AxiosError<CommonAPIResponse>) => void;
}) => {
  const axios = useAxios();
  return useMutation<PostStdAppAttResponse, AxiosError<CommonAPIResponse>, PostStdAppAttPayload>({
    mutationKey: ['app', 'create', 'attachment'],
    mutationFn: async (requestBody: PostStdAppAttPayload) => {
      const newAppId = options.appId || nanoid(20);
      const filesFormData = new FormData();
      if (requestBody.google_addendum) {
        filesFormData.append(`google_addendum`, requestBody.google_addendum);
      }
      if (requestBody.company_registration) {
        filesFormData.append(`company_registration`, requestBody.company_registration);
      }
      if (requestBody.fsr_mail && requestBody.fsr_mail.length > 0) {
        requestBody.fsr_mail.forEach((fsrMailFile) => {
          filesFormData.append(`fsr_mail`, fsrMailFile);
        });
      }
      if (requestBody.ceo_approval) {
        filesFormData.append(`ceo_approval`, requestBody.ceo_approval);
      }
      if (requestBody.gm_approval) {
        filesFormData.append(`gm_approval`, requestBody.gm_approval);
      }
      if (requestBody.random_file && requestBody.random_file.length > 0) {
        requestBody.random_file.forEach((randomFile) => {
          filesFormData.append(`random_file`, randomFile);
        });
      }
      if (requestBody.seal_file && requestBody.seal_file.length > 0) {
        requestBody.seal_file.forEach((sealFile) => {
          filesFormData.append(`seal_file`, sealFile);
        });
      }
      const { data } = await axios.post<PostStdAppAttResponse>(
        `/v1/verified_app/${newAppId}/attachment`,
        filesFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            application_id: newAppId,
          },
        }
      );
      return data;
    },
    onSuccess: (res, variables) => {
      options.onSuccess(res, variables.appStatus ?? 'pending');
    },
    onError: (err) => {
      options.onError(err);
    },
  });
};
