import type { GetApplicationByIdResult } from '../../../../types/application/official/application';
import {
  getApproverResultVariant,
  getApprovalProgressCardIcon,
  getApprovalProgressCardVariant,
} from '../../../../utils/get-variant';
import AwarenessChip from '../../../../components/chip/awareness-chip';
import moment from 'moment';
import Box from '../../../../components/box';
import { Text } from '../../../../components/typography/text';
import ApprovalFlowBox from '../../../../components/card/approval-flow-box';
import Disclosure from '../../../../components/surface/disclosure';
import Typography from '../../../../components/typography/deprecated-typography';
import Spacer from '../../../../components/surface/spacer';
import CountingBox from '../../../../components/card/counting-box';
import Avatar from '../../../../components/surface/avatar';
import ApprovalStatusCaption from '../../../../components/typography/approval-status-caption';
import MSymbol from '../../../../components/icon/m-symbol';
import MentionedTypography from '../../../../components/typography/mentioned-typography';
import { getStatusColor } from '../../../../utils/get-color';

const Approval = ({ data }: { data?: GetApplicationByIdResult['application_approval'] }) => {
  return (
    <>
      <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
      {/* Approval Progress Bar */}
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          columnGap: '6px',
          px: 30,
        }}>
        {data?.approval_flow &&
          data.approval_flow.length > 0 &&
          data.approval_flow.map((flow) => (
            <ApprovalFlowBox
              key={flow.id}
              variant={getApprovalProgressCardVariant(flow.approver_status_code)}>
              <MSymbol
                className="i"
                weight={700}
                iconName={`${getApprovalProgressCardIcon(flow.approver_status_code)}`}
                css={{ color: 'inherit' }}
              />
              <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
              <Text bold>{`${flow.name}`}</Text>
            </ApprovalFlowBox>
          ))}
      </Box>
      {/* Approval Content */}
      <Box css={{ p: 30, margin: '0 auto', minWidth: '580px', maxWidth: '760px' }}>
        <Disclosure
          needDivider={true}
          panelGap={'30px'}
          panels={[
            {
              id: 'panel-approval-details',
              name: 'Approval Details',
              content: (
                <>
                  {/* Urgent Level */}
                  {data && data.urgent_level ? (
                    <>
                      <AwarenessChip awarenessType="urgent" urgentLevel={data.urgent_level} />
                      <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                    </>
                  ) : null}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                    {/* Status */}
                    <Box
                      css={{
                        flexBasis: 'calc(50% - 10px)',
                        '> div': { justifyContent: 'flex-start' },
                      }}>
                      <Typography
                        variant={'caption-bold'}
                        css={{ color: '$secondary40' }}>{`Status`}</Typography>
                      <ApprovalStatusCaption status={data?.status ?? ''} />
                    </Box>
                  </Box>
                  <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                    {/* Created Date */}
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Created At`}</Typography>
                      <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                        data?.created_at
                          ? moment(data?.created_at).format('MM/DD/YY HH:mm A (Z)')
                          : '-'
                      }`}</Typography>
                    </Box>
                    {/* Modified Date */}
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Modified At`}</Typography>
                      <Typography as="p" variant="body" css={{ mt: '$1' }}>
                        {data?.modified_at
                          ? moment(data?.modified_at).format('MM/DD/YY HH:mm A (Z)')
                          : '-'}
                      </Typography>
                    </Box>
                  </Box>
                  <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                    {/* Applicant */}
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Applicant`}</Typography>
                      <Box
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          mt: '$1',
                        }}>
                        <Avatar
                          name={data?.applicant.name || '-'}
                          picture={data?.applicant.picture || ''}
                          css={{ mr: 8 }}
                          size="sm"
                        />
                        <Typography as="p" variant="body-medium">{`${
                          data?.applicant.name ?? '-'
                        }`}</Typography>
                      </Box>
                    </Box>
                    {/* Email */}
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Email`}</Typography>
                      <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                        data?.applicant.email ?? '-'
                      }`}</Typography>
                    </Box>
                  </Box>
                </>
              ),
            },
            {
              id: 'panel-approvers',
              name: 'Approvers',
              content: (
                <Box css={{ display: 'flex', flexDirection: 'column', rowGap: '$5' }}>
                  {data?.approvers && data.approvers.length > 0
                    ? data.approvers.map((approver) => (
                        <Box
                          key={approver.id}
                          css={{ display: 'flex', alignItems: 'center', columnGap: '$4' }}>
                          <Box
                            css={{
                              flexBasis: 'calc(50% - 10px)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <Box
                              css={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <Avatar
                                name={approver.name || '-'}
                                picture={approver.picture || ''}
                                css={{ mr: 8 }}
                                size="sm"
                              />
                              <Typography
                                as="p"
                                variant="body-medium">{`${approver.name}`}</Typography>
                            </Box>
                            {data?.status === 'rejected' && approver.status === 'pending' ? null : (
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                <Box
                                  css={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '$rounded',
                                    backgroundColor: getStatusColor(approver.status),
                                  }}
                                />
                                <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mr: '$5',
                                  }}>{`${
                                  approver.status.charAt(0).toUpperCase() + approver.status.slice(1)
                                }`}</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ))
                    : null}
                </Box>
              ),
            },
            {
              id: 'panel-feedbacks',
              name: 'Result',
              content: (
                <Box css={{ p: '$5', borderRadius: '$5', backgroundColor: '$secondary3' }}>
                  <Box css={{ display: 'flex', columnGap: '$2' }}>
                    <Typography
                      as="p"
                      variant="caption-bold"
                      css={{
                        color: '$secondary70',
                      }}>{`Feedback`}</Typography>
                    <CountingBox countingNumber={data?.feedbacks ? data.feedbacks.length : 0} />
                  </Box>
                  {data?.feedbacks && data.feedbacks.length > 0 ? (
                    <>
                      <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                      {data.feedbacks.map((feedback, index) => (
                        <Box
                          key={feedback.id}
                          css={{
                            position: 'relative',
                            borderBottom: '1px $secondary20 solid',
                            px: 13,
                            pb: 13,
                            mt: index !== 0 ? 20 : 0,
                            '&:before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              bottom: 13,
                              left: 0,
                              width: 3,
                              borderTopLeftRadius: '$5',
                              borderBottomLeftRadius: '$5',
                              borderTopRightRadius: '$5',
                              borderBottomRightRadius: '$5',
                              backgroundColor: getApproverResultVariant(
                                feedback.approver_status_code
                              )?.color,
                            },
                          }}>
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
                            <Box
                              as="img"
                              css={{ width: 20, height: 20 }}
                              src={getApproverResultVariant(feedback.approver_status_code)?.icon}
                            />
                            <Typography
                              variant={'h6'}
                              css={{
                                color: getApproverResultVariant(feedback.approver_status_code)
                                  ?.color,
                              }}>{`${feedback.approval_result}`}</Typography>
                          </Box>
                          <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
                          <Box>
                            <Typography
                              variant={'body-bold'}
                              css={{ mr: '8px' }}>{`${feedback.approver.name}`}</Typography>
                            <Typography
                              variant={'caption'}
                              css={{ color: '$secondary70' }}>{`${moment(
                              feedback.approved_at
                            ).format('MM/DD/YY HH:mm A (Z)')}`}</Typography>
                          </Box>
                          <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
                          <MentionedTypography
                            ellipsisLine="revert"
                            message={feedback.feedback_content}
                          />
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      as={'p'}
                      variant={'body'}
                      css={{
                        mt: '10px',
                        color: '$secondary40',
                      }}>{`There were no responses from reviewers.`}</Typography>
                  )}
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};

export default Approval;
