import { useParams } from 'react-router-dom';
import { appDetailPageMaps } from '../../../store/application/constants';

const AppDetailPage = () => {
  const { appType, product, clientType, appId } = useParams();

  return (
    <>
      {appType && product && clientType && appId ? (
        <>{appDetailPageMaps[appType][clientType]}</>
      ) : null}
    </>
  );
};

export default AppDetailPage;
