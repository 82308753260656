import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import * as color from './colors';

export const { theme, keyframes, styled, css, globalCss } = createStitches({
  theme: {
    colors: {
      'text-primary': color.gray[90],
      'text-secondary': color.gray[70],
      'text-hint': color.gray[30],
      'text-hyperlink': color.skyBlue[60],

      'func-hover': color.gray[3],
      'func-disabled-light': color.gray[5],
      'func-disabled-dark': color.gray[20],
      'func-active': color.gray[10],
      'func-border-main': color.gray[20],
      'func-border-light': color.gray[10],
      'func-border-dark': color.gray[30],

      transparent: color.achromatic.transparent,
      white: color.achromatic.white,
      solitude: 'hsla(220, 20%, 94%, 1)', // * #EDEFF3
      black: color.achromatic.black,

      primary10: color.skyBlue[10],
      primary20: color.skyBlue[20],
      primary30: color.skyBlue[30],
      primary40: color.skyBlue[40],
      primary50: color.skyBlue[50],
      primary60: color.skyBlue[60],
      primary70: color.skyBlue[70],
      primary80: color.skyBlue[80],
      primary90: color.skyBlue[90],
      primary100: color.skyBlue[100],

      secondary3: color.gray[3],
      secondary5: color.gray[5],
      secondary10: color.gray[10],
      secondary20: color.gray[20],
      secondary30: color.gray[30],
      secondary40: color.gray[40],
      secondary50: color.gray[50],
      secondary60: color.gray[60],
      secondary70: color.gray[70],
      secondary80: color.gray[80],
      secondary90: color.gray[90],
      secondary100: color.gray[100],

      achromatic3: color.gray[3],
      achromatic5: color.gray[5],
      achromatic10: color.gray[10],
      achromatic20: color.gray[20],
      achromatic30: color.gray[30],
      achromatic40: color.gray[40],
      achromatic50: color.gray[50],
      achromatic60: color.gray[60],
      achromatic70: color.gray[70],
      achromatic80: color.gray[80],
      achromatic90: color.gray[90],
      achromatic100: color.gray[100],

      success10: color.green[10],
      success20: color.green[20],
      success30: color.green[30],
      success40: color.green[40],
      success50: color.green[50],
      success60: color.green[60],
      success70: color.green[70],
      success80: color.green[80],
      success90: color.green[90],
      success100: color.green[100],

      warning10: color.tangerineOrange[10],
      warning20: color.tangerineOrange[20],
      warning40: color.tangerineOrange[40],
      warning50: color.tangerineOrange[50],
      warning60: color.tangerineOrange[60],
      warning90: color.tangerineOrange[90],

      error10: color.red[10],
      error20: color.red[20],
      error30: color.red[30],
      error40: color.red[40],
      error50: color.red[50],
      error60: color.red[60],
      error70: color.red[70],
      error80: color.red[80],
      error90: color.red[90],
      error100: color.red[100],

      purple60: color.purple[60],

      turquoise70: color.turquoiseBlue[70],
    },
    space: {
      0: '0px',
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      8: '32px',
      9: '36px',
      10: '40px',
    },
    fontSizes: {
      48: '48px',
      36: '36px',
      24: '24px',
      20: '20px',
      18: '18px',
      16: '16px',
      14: '14px',
      12: '12px',
    },
    fonts: {
      untitled: 'Noto Sans TC, sans-serif',
      // mono: '',
    },
    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
    lineHeights: {
      125: 1.25,
      150: 1.5,
      175: 1.75,
    },
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {
      0: '0px',
      5: '5px',
      10: '10px',
      rounded: '50%',
    },
    shadows: {
      basic: '0px 0px 6px rgba(0, 0, 0, 0.2)',
    },
    zIndices: {},
    transitions: {
      all: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
      opacity: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)',
      colors:
        'color 200ms cubic-bezier(0.4, 0, 0.2, 1), background-color 200ms cubic-bezier(0.4, 0, 0.2, 1), border-color 200ms cubic-bezier(0.4, 0, 0.2, 1), text-decoration-color 200ms cubic-bezier(0.4, 0, 0.2, 1), fill 200ms cubic-bezier(0.4, 0, 0.2, 1), stroke 200ms cubic-bezier(0.4, 0, 0.2, 1)',
      shadow: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      'colors-and-shadow':
        'color 200ms cubic-bezier(0.4, 0, 0.2, 1), background-color 200ms cubic-bezier(0.4, 0, 0.2, 1), border-color 200ms cubic-bezier(0.4, 0, 0.2, 1), text-decoration-color 200ms cubic-bezier(0.4, 0, 0.2, 1), fill 200ms cubic-bezier(0.4, 0, 0.2, 1), stroke 200ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      transform: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
  },
  utils: {
    // Abbreviated margin properties
    m: (value: Stitches.PropertyValue<'margin'> | Stitches.ScaleValue<'space'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<'marginTop'> | Stitches.ScaleValue<'space'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<'marginRight'> | Stitches.ScaleValue<'space'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<'marginBottom'> | Stitches.ScaleValue<'space'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'marginLeft'> | Stitches.ScaleValue<'space'>) => ({
      marginLeft: value,
    }),
    mx: (
      value:
        | Stitches.PropertyValue<'marginLeft'>
        | Stitches.PropertyValue<'marginRight'>
        | Stitches.ScaleValue<'space'>
    ) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (
      value:
        | Stitches.PropertyValue<'marginTop'>
        | Stitches.PropertyValue<'marginBottom'>
        | Stitches.ScaleValue<'space'>
    ) => ({
      marginTop: value,
      marginBottom: value,
    }),
    // Abbreviated padding properties
    p: (value: Stitches.PropertyValue<'padding'> | Stitches.ScaleValue<'space'>) => ({
      padding: value,
    }),
    pt: (value: Stitches.PropertyValue<'paddingTop'> | Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<'paddingRight'> | Stitches.ScaleValue<'space'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<'paddingBottom'> | Stitches.ScaleValue<'space'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<'paddingLeft'> | Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
    }),
    px: (
      value:
        | Stitches.PropertyValue<'paddingLeft'>
        | Stitches.PropertyValue<'paddingRight'>
        | Stitches.ScaleValue<'space'>
    ) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (
      value:
        | Stitches.PropertyValue<'paddingTop'>
        | Stitches.PropertyValue<'paddingBottom'>
        | Stitches.ScaleValue<'space'>
    ) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
});
