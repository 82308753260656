import type { LabelColor } from '../../types/constants';
import { styled } from '../../styles/stitches.config';
import { getColorKit } from '../../utils/get-color';
import Typography from '../typography/deprecated-typography';
import Spacer from '../surface/spacer';
import MSymbol from '../icon/m-symbol';

const StyledColorfulChip = styled('div', {
  width: 'fit-content',
  fontSize: '$12',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '$5',
  variants: {
    variant: {
      solid: { color: '$white' },
      outlined: {},
    },
  },
});

const ColorfulChip = ({
  text,
  variant = 'solid',
  color = 'gray',
  dense = false,
  deletable = false,
  onDelete,
}: {
  text: string;
  color?: LabelColor;
  customColor?: string;
  variant: 'solid' | 'outlined';
  dense?: boolean;
  deletable?: boolean;
  onDelete?: () => void;
}) => {
  return (
    <StyledColorfulChip
      variant={variant}
      css={{
        px: dense ? '4px' : '8px',
        ...getColorKit({ variant, color }),
        height: dense ? 18 : 24,
      }}>
      <Typography variant="button-12" css={{ color: 'inherit' }}>
        {text}
      </Typography>
      {deletable ? (
        <>
          <Spacer axis="horizontal" css={{ $$size: '4px' }} />
          <MSymbol
            iconName="close"
            weight={700}
            css={{ color: 'inherit', fontSize: '16px !important', cursor: 'pointer' }}
            onClick={onDelete}
          />
        </>
      ) : null}
    </StyledColorfulChip>
  );
};

export default ColorfulChip;
