import { AppDetailAttachmentEntity } from '../../../../types/application/constants';
import { AppDetailSealRequestTabProps } from './seal-request-tab';
import { useGetAppDetailSealRequestTabGCP } from '../../../../services/application/verified-standard/app-detail-tabs.api';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import { Caption, Text } from '../../../../components/typography/text';
import Spacer from '../../../../components/surface/spacer';
import Disclosure from '../../../../components/surface/disclosure';
import {
  SubmissionColumn,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Avatar from '../../../../components/surface/avatar';
import LogoGoogleCloud from '../../../../assets/images/logo-google-cloud.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import FileCard from '../../../../components/card/file-card';

const SealRequestTabGCP = ({ appId }: Pick<AppDetailSealRequestTabProps, 'appId'>) => {
  const appDetailSealRequestTabQuery = useGetAppDetailSealRequestTabGCP({ appId });
  return (
    <>
      {appDetailSealRequestTabQuery.isLoading ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Spinner size="large" />
        </CenteredBoxAbsolute>
      ) : appDetailSealRequestTabQuery.isSuccess ? (
        <Box
          css={{
            px: 30,
            pb: 30,
            margin: '0 auto',
            mt: 30,
            minWidth: '580px',
            maxWidth: '760px',
            backgroundColor: '$white',
          }}>
          <Disclosure
            panels={[
              {
                id: 'section-seal',
                name: 'Seal',
                content: (
                  <>
                    {/* General Info */}
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Seal Details</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoGoogleCloud} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* Assignee */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Assignee`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={appDetailSealRequestTabQuery.data.assignee.name || '-'}
                              picture={appDetailSealRequestTabQuery.data.assignee_picture || ''}
                              size="sm"
                            />
                            <Text>{`${
                              appDetailSealRequestTabQuery.data.assignee.name || '-'
                            }`}</Text>
                          </Box>
                        </SubmissionColumn>
                        {/* Application Unit */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Application Unit`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSealRequestTabQuery.data.application_unit || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Seal Type */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Seal Type`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSealRequestTabQuery.data.seal_type.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Deliver To */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Deliver To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSealRequestTabQuery.data.deliver_to.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Reason for Application */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason for Application`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSealRequestTabQuery.data.reason_for_application || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-attachment',
                name: 'Attach files',
                content: (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                    {appDetailSealRequestTabQuery.data.seal_file_list?.length > 0 ? (
                      <>
                        {appDetailSealRequestTabQuery.data.seal_file_list.map(
                          (file: AppDetailAttachmentEntity) => (
                            <FileCard
                              key={file.id}
                              fileName={file.filename}
                              fileSize={file.size}
                              previewSignedUrl={file.preview_signed_url}
                              signedUrl={file.signed_url}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <Text>No files uploaded by applicant</Text>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      ) : appDetailSealRequestTabQuery.isError ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxAbsolute>
      ) : null}
    </>
  );
};

export default SealRequestTabGCP;
