import { BossApproval, SpecialDealEntity, SpecialDealType } from '../types/application/constants';
import type { PostValidateSubmissionFormResult } from '../types/application/official/application';

// * 檢查 Submission Form Validation 是否全通過，任一有錯就不過
export const checkValidationPass = (validateResult: PostValidateSubmissionFormResult) => {
  return Object.keys(validateResult).every((field: string) => {
    if (validateResult[field]?.is_error) {
      return false;
    }
    return true;
  });
};

// * 檢查必填有沒有填
export const checkRequiredField = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return { is_error: true, error: 'This field is required', is_success: false };
  } else {
    return { is_error: false, error: '', is_success: true };
  }
};

export const isGMApprovalRequired = (options: BossApproval) => {
  switch (options.condition) {
    case 'paymentTerm':
      return options.paymentTerm >= options.max;
    case 'discount':
      if (options.discountType === 'percentage_discount') {
        // * GCP > 3% (>= 3.01%), GWS >= 5%
        return options.discountValue >= options.max;
      } else {
        return false;
      }
    default:
      return false;
  }
};

export const isCEOApprovalRequired = (options: BossApproval) => {
  switch (options.condition) {
    case 'paymentTerm':
      return options.paymentTerm >= options.max;
    case 'discount':
      if (options.discountType === 'percentage_discount') {
        // * GCP >= 8%, GWS >= 10%
        return options.discountValue >= options.max;
      } else {
        return false;
      }
    default:
      return false;
  }
};

export const isDealSelected = (options: {
  curSelectedDeals: SpecialDealEntity[];
  deal: SpecialDealType;
}) => {
  return options.curSelectedDeals.some((deal: SpecialDealEntity) => deal.value === options.deal);
};

export const isNoAttachment = (options: { attachments: any }) => {
  const attachmentToCheck = [
    'ceo_approval',
    'gm_approval',
    'google_addendum',
    'random_file',
    'seal_file',
    'company_registration',
  ];
  return attachmentToCheck.every((attachment) => options.attachments[attachment] === null);
};
