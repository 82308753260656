import { Outlet, useLocation } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import {
  appBarHeightAtom,
  navBarWidthAtom,
  breadcrumbHeightAtom,
  isNavBarOpenAtom,
  toastContentAtom,
  isOverlayOpenAtom,
  authUserAtom,
} from '../store/atoms';
import { motion } from 'framer-motion';
import Location from '../layouts/location';
import AppBar from '../components/bar/app-bar';
import Breadcrumbs from '../components/bar/breadcrumbs';
import Box from '../components/box';
import Spacer from '../components/surface/spacer';
import NavBar from '../components/bar/nav-bar';
import { ReactComponent as MileCoolabLogoSVG } from '../assets/images/logo-milecoolab.svg';
import { navLinks, navLinksDev } from '../store/constants';
import { layoutGrowMotion } from '../styles/motions';
import Toast from '../components/helper/toast';
import Overlay from '../components/surface/overlay';
import IconButton from '../components/button/icon-button';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
} from '../components/dropdown/popover';
import SelectOption from '../components/card/select-option';
import MSymbol from '../components/icon/m-symbol';
import Typography from '../components/typography/deprecated-typography';

const AppLayout = () => {
  const { pathname } = useLocation();
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const isOverlayOpen = useAtomValue(isOverlayOpenAtom);
  const appBarHeight = useAtomValue(appBarHeightAtom);
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const breadcrumbHeight = useAtomValue(breadcrumbHeightAtom);
  const toastContent = useAtomValue(toastContentAtom);
  const authUser = useAtomValue(authUserAtom);
  const {
    name,
    picture,
    email,
    id,
    group_name: groupName,
    position: positionName,
  } = authUser || {};

  return (
    <>
      <Location />
      <Spacer axis="vertical" css={{ $$size: appBarHeight }} />
      {isOverlayOpen ? <Overlay /> : null}
      <Box
        id="app-layout"
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          flexGrow: 1,
          height: '100%',
          minHeight: `calc(100vh - ${appBarHeight})`,
        }}>
        <AppBar
          appLogo={MileCoolabLogoSVG}
          userId={id}
          userName={name}
          userAvatarUrl={picture}
          userGroup={groupName}
          userPosition={positionName}
          userEmail={email}
        />
        <NavBar navs={process.env.REACT_APP_NODE_ENV === 'dev' ? navLinksDev : navLinks} />
        {/* Breadcrumb */}
        <Box
          as={motion.div}
          initial={isNavBarOpen ? 'grow' : 'shrink'}
          animate={isNavBarOpen ? 'grow' : 'shrink'}
          variants={layoutGrowMotion(navBarWidth)}
          css={{
            position: 'fixed',
            top: appBarHeight,
            left: '0',
            right: '0',
            height: breadcrumbHeight,
            display: 'flex',
            alignItems: 'center',
            zIndex: 80,
            pl: '18px',
            backgroundColor: '$white',
            borderBottom: '1px solid $secondary20',
          }}>
          <Breadcrumbs />
        </Box>
        {/* Main */}
        <Box
          as={motion.main}
          initial={isNavBarOpen ? 'grow' : 'shrink'}
          animate={isNavBarOpen ? 'grow' : 'shrink'}
          variants={layoutGrowMotion(navBarWidth)}
          css={{
            flexGrow: 1,
            mt: breadcrumbHeight,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Outlet />
        </Box>
      </Box>
      {/* Report Center */}
      <Box
        as={motion.div}
        animate={pathname.split('/').length > 2 ? 'hide' : 'show'}
        variants={{
          show: { opacity: 1, display: 'block' },
          hide: { opacity: 0, transitionEnd: { display: 'none' } },
        }}
        css={{ position: 'fixed', right: 20, bottom: 10, zIndex: '1000' }}>
        <PopoverRoot>
          <PopoverTrigger asChild>
            <IconButton iconName="help" size="medium" />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={5} align={'end'} css={{ p: 10, width: 190 }}>
              <PopoverClose asChild>
                <SelectOption value={'a'} css={{ height: 40, px: 10 }}>
                  <Box
                    as="a"
                    href="https://docs.google.com/spreadsheets/d/1wh_IXSNVVnnRGEytmfKI5UR8dsE2d5myVdy_H-xy8Bs/edit?usp=sharing"
                    target="_blank"
                    css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                    <MSymbol
                      iconName={`chat_error`}
                      fill={false}
                      css={{ fontSize: '18px !important' }}
                    />
                    <Typography variant={'body'}>{`Report an issue`}</Typography>
                  </Box>
                </SelectOption>
              </PopoverClose>
              <PopoverClose asChild>
                <SelectOption value={'a'} css={{ height: 40, px: 10 }}>
                  <Box
                    as="a"
                    href="https://forms.gle/QWhzMw8xMPz3J3z69"
                    target="_blank"
                    css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                    <MSymbol iconName={`flag`} fill={false} css={{ fontSize: '18px !important' }} />
                    <Typography variant={'body'}>{`Feature request`}</Typography>
                  </Box>
                </SelectOption>
              </PopoverClose>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      </Box>
      {/* Toast */}
      <Toast
        isSuccess={toastContent.isSuccess}
        isError={toastContent.isError}
        success={toastContent.success}
        error={toastContent.error}
      />
    </>
  );
};

export default AppLayout;
