import { ChangeEvent, useEffect, useRef } from 'react';
import { useGetSampleImage } from '../../services/application/sample.api';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverArrow,
} from '../dropdown/popover';
import Box from '../box';
import Button from '../button/deprecated-button';
import Typography from '../typography/deprecated-typography';
import Spacer from '../surface/spacer';
import Spinner from '../helper/spinner';
import MSymbol from '../icon/m-symbol';
import IconButton from '../button/icon-button';
import AttachFileLightOffIcon from '../../assets/images/icon-attach-file-light-off.svg';
import PopupErrorIcon from '../../assets/images/icon-popup-error.svg';
import AttachPDFLightOnIcon from '../../assets/images/icon-attach-pdf-light-on.svg';

interface AttachFileProps {
  attachName: 'FSR Mail' | 'Google Addendum' | 'Company Registration';
  onFileChange?: (selectedFile: File | null) => void;
  file?: File | null;
  disabled?: boolean;
  required?: boolean;
  needSampleImage?: boolean;
  sampleImageId?: string;
  isError?: boolean;
  error?: string;
}

const AttachFile = ({
  attachName,
  onFileChange,
  file,
  disabled,
  required,
  needSampleImage = false,
  sampleImageId,
  isError,
  error,
}: AttachFileProps) => {
  const sampleImageQueries = useGetSampleImage(sampleImageId as string);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const handleOnFileChosen = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleOnFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (onFileChange) {
      onFileChange(selectedFile);
    }
  };
  useEffect(() => {
    if (needSampleImage && sampleImageId) {
      sampleImageQueries.refetch();
    }
  }, [needSampleImage, sampleImageId]);

  return (
    <Box
      css={{
        borderWidth: '1px',
        borderColor: '$secondary20',
        borderStyle: 'solid',
        borderRadius: '$5',
        px: '20px',
        py: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Box css={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
        <Box
          css={{
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            as="img"
            src={
              file
                ? file.type === 'application/pdf'
                  ? AttachPDFLightOnIcon
                  : URL.createObjectURL(file)
                : AttachFileLightOffIcon
            }
            css={{
              objectFit: file ? 'cover' : 'contain',
              width: '100%',
              height: '100%',
              borderRadius: '$5',
            }}
          />
        </Box>
        <Box>
          {file ? (
            <>
              <Typography as="p" variant="button">{`${attachName}`}</Typography>
              <Typography
                as="p"
                variant="body"
                css={{ color: '$secondary70' }}>{`${file.name}`}</Typography>
              {/* 四捨五入到小數點第二位 */}
              <Typography as="p" variant="caption" css={{ color: '$secondary70' }}>{`${
                Math.round((file.size / (1024 * 1024)) * 100) / 100
              } MB`}</Typography>
              {/* 如果檔案大於 2 MB */}
              {isError ? (
                <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
                  <Box
                    as="img"
                    src={PopupErrorIcon}
                    css={{
                      objectFit: 'contain',
                      width: '18px',
                      height: '18px',
                      borderRadius: '$5',
                    }}
                  />
                  <Typography
                    as="p"
                    variant="caption"
                    css={{
                      ml: '$1',
                      color: '$error60',
                    }}>{`${error}`}</Typography>
                </Box>
              ) : null}
            </>
          ) : (
            <>
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <Typography>{`${attachName}`}</Typography>
                <Spacer axis="horizontal" css={{ $$size: '6px' }} />
                {needSampleImage && sampleImageId ? (
                  <PopoverRoot>
                    <PopoverTrigger asChild>
                      <IconButton iconName="info" isIconFill={false} />
                    </PopoverTrigger>
                    <PopoverPortal>
                      <PopoverContent
                        sideOffset={5}
                        css={{
                          p: '$5',
                          maxWidth: 600,
                          minHeight: 200,
                        }}>
                        <Typography>{`Sample as below`}</Typography>
                        <Spacer axis="vertical" css={{ $$size: '15px' }} />
                        <Box
                          css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {sampleImageQueries.isLoading ? <Spinner /> : null}
                          {sampleImageQueries.isError ? (
                            <Typography
                              css={{ color: '$error60' }}>{`Something went wrong`}</Typography>
                          ) : null}
                          {sampleImageQueries.isSuccess ? (
                            <img src={sampleImageQueries.data.url} />
                          ) : null}
                        </Box>
                        <PopoverArrow />
                      </PopoverContent>
                    </PopoverPortal>
                  </PopoverRoot>
                ) : null}
                {required ? (
                  <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
                ) : null}
              </Box>
              {isError ? (
                <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
                  <Box
                    as="img"
                    src={PopupErrorIcon}
                    css={{
                      objectFit: 'contain',
                      width: '18px',
                      height: '18px',
                      borderRadius: '$5',
                    }}
                  />
                  <Typography
                    as="p"
                    variant="caption"
                    css={{
                      ml: '$1',
                      color: '$error60',
                    }}>{`${error}`}</Typography>
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </Box>
      <Box css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {file ? (
          <IconButton
            iconName="close"
            isIconBold={true}
            color="secondary-primary"
            onClick={() => {
              if (onFileChange) {
                onFileChange(null);
              }
            }}
          />
        ) : null}
        {file ? null : (
          <Button
            size="small"
            onClick={handleOnFileChosen}
            disabled={disabled}
            startElement={
              <MSymbol
                weight={700}
                iconName="file_upload"
                css={{ color: 'inherit', fontSize: '16px !important' }}
              />
            }>
            {`Upload`}
            <input
              ref={hiddenFileInput}
              type="file"
              style={{ display: 'none' }}
              onChange={handleOnFileChange}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AttachFile;
