import { ComponentProps, MouseEvent } from 'react';
import { styled } from '../../styles/stitches.config';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { AppType, ClientType, ProductType } from '../../types/application/constants';
import { checkNDaysAgo } from '../../utils/check-n-days-ago';
import { getApproverResultVariant } from '../../utils/get-variant';
import moment from 'moment';
import {
  curAppDetailPanelAtom,
  curAppDetailTabAtom,
  isAppDetailPanelsOpenAtom,
} from '../../store/atoms';
import { useGetMentionedFeedback } from '../../services/home/home.api';
import Skeleton from '../surface/skeleton';
import { CenteredBoxFlex } from '../surface/centered-box';
import Box from '../box';
import { Heading6 } from '../typography/heading';
import Spacer from '../surface/spacer';
import Button from '../button/button';
import { Text, ButtonText, Caption } from '../typography/text';
import DocIcon from '../icon/doc-icon';
import Avatar from '../surface/avatar';
import ColorfulChip from '../chip/colorful-chip';
import MentionedTypography from '../typography/mentioned-typography';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';

const MentionedFeedbackCardRoot = styled('div', {
  borderRadius: '$5',
  border: '1px solid $secondary20',
  position: 'relative',
  transition: '$colors $opacity',
  cursor: 'pointer',
  '& [data-button=more]': {
    opacity: 0,
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '$secondary3',
    '& [data-button=more]': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  '&:active': {
    backgroundColor: '$secondary5',
  },
});
export const MentionedFeedbackCardContent = styled('div', { px: 10, pt: 6, pb: 10 });

interface MentionedFeedbackCardProps extends ComponentProps<typeof MentionedFeedbackCardRoot> {
  feedbackId: string;
}

const MentionedFeedbackCard = ({ feedbackId, ...restProps }: MentionedFeedbackCardProps) => {
  const navigate = useNavigate();

  const setIsAppDetailPanelsOpen = useSetAtom(isAppDetailPanelsOpenAtom);
  const setCurAppDetailTab = useSetAtom(curAppDetailTabAtom);
  const setCurrentApplicationPanel = useSetAtom(curAppDetailPanelAtom);

  const mentionedFeedbackCardQuery = useGetMentionedFeedback({ feedbackId });

  const onSuccessFeedbackClick = (options: {
    appType: AppType;
    product: ProductType;
    clientType: ClientType;
    appId: string;
  }) => {
    setCurAppDetailTab('approval');
    setCurrentApplicationPanel('comment');
    setIsAppDetailPanelsOpen(true);
    if (options.appType === 'official') {
      setCurAppDetailTab('approval');
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };

  const onAppNameClick = (
    e: MouseEvent,
    options: {
      appType: AppType;
      product: ProductType;
      clientType: ClientType;
      appId: string;
    }
  ) => {
    e.stopPropagation();
    setCurAppDetailTab('document');
    setIsAppDetailPanelsOpen(false);
    if (options.appType === 'official') {
      setCurAppDetailTab('approval');
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };

  return (
    <MentionedFeedbackCardRoot {...restProps}>
      {mentionedFeedbackCardQuery.isLoading ? (
        <Skeleton css={{ height: 150 }} />
      ) : mentionedFeedbackCardQuery.isSuccess ? (
        <MentionedFeedbackCardContent
          onClick={() =>
            onSuccessFeedbackClick({
              appType: mentionedFeedbackCardQuery.data.app_type,
              product: mentionedFeedbackCardQuery.data.product,
              clientType: mentionedFeedbackCardQuery.data.client_type,
              appId: mentionedFeedbackCardQuery.data.application_id,
            })
          }>
          {/* Header */}
          <Box
            css={{
              height: 2,
              mb: 4,
              borderRadius: 2,
              backgroundColor: getApproverResultVariant(
                mentionedFeedbackCardQuery.data.approver_status_code
              ).color,
            }}
          />
          <Box css={{ display: 'flex', columnGap: 6, alignItems: 'center', position: 'relative' }}>
            {/* Avatar */}
            <Avatar
              name={mentionedFeedbackCardQuery.data.feedbacker_user_info.name || '-'}
              picture={mentionedFeedbackCardQuery.data.feedbacker_user_info.picture || ''}
            />
            {/* Name, Time */}
            <Box css={{ flexGrow: 1 }}>
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                <Text bold>{mentionedFeedbackCardQuery.data.feedbacker_user_info.name || '-'}</Text>
                {checkNDaysAgo({ time: mentionedFeedbackCardQuery.data.edited_at }) ? null : (
                  <ColorfulChip text="New" color="tangerine" variant="solid" dense />
                )}
              </Box>
              <Caption css={{ color: '$text-secondary' }}>
                {mentionedFeedbackCardQuery.data.edited_at
                  ? checkNDaysAgo({ time: mentionedFeedbackCardQuery.data.edited_at })
                    ? moment(mentionedFeedbackCardQuery.data.edited_at).format(
                        'MM/DD/YY HH:mm A (Z)'
                      )
                    : moment(mentionedFeedbackCardQuery.data.edited_at).fromNow()
                  : '-'}
              </Caption>
            </Box>
          </Box>
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
            <Box
              as="img"
              css={{ width: 20, height: 20 }}
              src={
                getApproverResultVariant(mentionedFeedbackCardQuery.data.approver_status_code).icon
              }
            />
            <Heading6
              css={{
                color: getApproverResultVariant(
                  mentionedFeedbackCardQuery.data.approver_status_code
                ).color,
              }}>
              {getApproverResultVariant(mentionedFeedbackCardQuery.data.approver_status_code).title}
            </Heading6>
          </Box>
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          {/* Body */}
          {/* Mention Content */}
          <MentionedTypography message={mentionedFeedbackCardQuery.data.content} />
          {/* Application Name */}
          <Box
            onClick={(e) =>
              onAppNameClick(e, {
                appType: mentionedFeedbackCardQuery.data.app_type,
                appId: mentionedFeedbackCardQuery.data.application_id,
                product: mentionedFeedbackCardQuery.data.product,
                clientType: mentionedFeedbackCardQuery.data.client_type,
              })
            }
            css={{
              mt: 10,
              display: 'flex',
              alignItems: 'center',
              p: 4,
              columnGap: 4,
              borderRadius: '$5',
              backgroundColor: '$secondary3',
              transition: '$colors',
              '&:hover': { backgroundColor: '$primary10' },
              '&:active': { backgroundColor: '$primary20' },
            }}>
            <Box
              css={{
                width: 18,
                height: 18,
                flexShrink: 0,
              }}>
              <DocIcon
                docType={mentionedFeedbackCardQuery.data.icon_map.doc_type}
                docIconBGColor={mentionedFeedbackCardQuery.data.icon_map.doc_icon_bg}
                docIconStatus={mentionedFeedbackCardQuery.data.icon_map.doc_icon_status}
              />
            </Box>
            <Caption semibold>{`${
              mentionedFeedbackCardQuery.data.application_name || '-'
            }`}</Caption>
          </Box>
        </MentionedFeedbackCardContent>
      ) : mentionedFeedbackCardQuery.isError ? (
        <CenteredBoxFlex css={{ p: 10 }} onClick={(e) => e.stopPropagation()}>
          <Box as="img" src={ErrorMonoBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
            <Heading6>{`Something's gone wrong`}</Heading6>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Text semibold>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Text>
          </Box>
          <Button
            size="sm"
            isLoading={mentionedFeedbackCardQuery.isLoading}
            disabled={mentionedFeedbackCardQuery.isLoading}
            onClick={() => {
              mentionedFeedbackCardQuery.refetch();
            }}>
            <ButtonText size="14">Refresh</ButtonText>
          </Button>
        </CenteredBoxFlex>
      ) : null}
    </MentionedFeedbackCardRoot>
  );
};

export default MentionedFeedbackCard;
