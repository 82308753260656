import { useEffect, useState } from 'react';
import { PutSettingsPersonalOrganizationPayload } from '../../../types/settings';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetSettingsPersonalOrganization,
  usePutSettingsPersonalOrganization,
} from '../../../services/settings.api';
import { motion } from 'framer-motion';
import Box from '../../../components/box';
import Typography from '../../../components/typography/deprecated-typography';
import Spacer from '../../../components/surface/spacer';
import Spinner from '../../../components/helper/spinner';
import Button from '../../../components/button/deprecated-button';
import Divider from '../../../components/surface/divider';
import IconButton from '../../../components/button/icon-button';
import MSymbol from '../../../components/icon/m-symbol';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../components/helper/tool-tip';
import FormControl from '../../../components/field/deprecated-form-control';
import Field from '../../../components/field/deprecated-field';
import ErrorBG from '../../../assets/images/bg-error.svg';

const Organization = () => {
  const [isEditing, setIsEditing] = useState(false);
  const organizationQueries = useGetSettingsPersonalOrganization();
  const { control, handleSubmit, getValues, setValue, clearErrors, getFieldState, reset } = useForm(
    { defaultValues: { job_title: '' } }
  );
  const organizationMutate = usePutSettingsPersonalOrganization(() => {
    reset({ job_title: organizationQueries.data?.job_title || '' });
    setIsEditing(false);
  });
  const handleOnEdit = ({ job_title }: PutSettingsPersonalOrganizationPayload) => {
    if (!getFieldState('job_title').invalid) {
      organizationMutate.mutate({ job_title });
    }
  };
  useEffect(() => {
    if (organizationQueries.isSuccess && organizationQueries.data) {
      reset({ job_title: organizationQueries.data.job_title });
    }
  }, [organizationQueries.data, organizationQueries.isSuccess, reset]);
  return (
    <>
      <Box css={{ py: 5 }}>
        <Typography variant={'h4'}>{`Organization`}</Typography>
        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
        <Typography variant={'body'} css={{ color: '$secondary70' }}>
          {`Manage organizations for your employment role`}
        </Typography>
      </Box>
      <Spacer axis={'vertical'} css={{ $$size: '15px' }} />
      {organizationQueries.isLoading ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Spinner size={'large'} />
        </Box>
      ) : null}
      {organizationQueries.isError ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Typography variant="h6">{`Something's gone wrong`}</Typography>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Typography variant="body-medium">
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Typography>
          </Box>
          <Button
            variant="solid"
            size={'small'}
            onClick={() => organizationQueries.refetch()}>{`Refresh`}</Button>
        </Box>
      ) : null}
      {organizationQueries.isSuccess ? (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
          }}>
          <Box
            css={{
              width: '100%',
              maxWidth: 664,
              borderRadius: '$10',
              py: 10,
              px: 20,
              backgroundColor: '$white',
              borderStyle: 'solid',
              borderColor: '$secondary20',
              borderWidth: '1px',
            }}>
            {/* Organization, TODO: 先不管 Electron 先寫死在 Front-end */}
            <Box
              as={'dl'}
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120, flexShrink: 0 }}>
                <Typography variant={'body-bold'}>{`Organization`}</Typography>
              </Box>
              <Box
                as={'dd'}
                css={{
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant={'body-medium'}
                  css={{ color: '$secondary70', flexGrow: 1 }}>{`CloudMile`}</Typography>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Entity */}
            <Box
              as={'dl'}
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120, flexShrink: 0 }}>
                <Typography variant={'body-bold'}>{`Entity`}</Typography>
              </Box>
              <Box
                as={'dd'}
                css={{
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant={'body-medium'}
                  css={{ color: '$secondary70', flexGrow: 1 }}>{`${
                  organizationQueries.data.entity || '-'
                }`}</Typography>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Department */}
            <Box
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120 }}>
                <Typography variant={'body-bold'}>{`Department`}</Typography>
              </Box>
              <Box
                as={'dd'}
                css={{
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant={'body-medium'}
                  css={{ color: '$secondary70', flexGrow: 1 }}>{`${
                  organizationQueries.data.department
                    ? organizationQueries.data.department.charAt(0).toUpperCase() +
                      organizationQueries.data.department.slice(1)
                    : '-'
                }`}</Typography>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Job Title */}
            <Box
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120 }}>
                <Typography variant={'body-bold'}>{`Job Title`}</Typography>
              </Box>
              <Box
                as={motion.dd}
                css={{
                  position: 'relative',
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {isEditing ? (
                  <>
                    {/* Field */}
                    <FormControl width="100%" onSubmit={handleSubmit((data) => handleOnEdit(data))}>
                      <Controller
                        name={`job_title`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Field
                            value={field.value}
                            onChange={(value: string) => field.onChange(value)}
                            placeholder="Enter a job title"
                            isError={fieldState.invalid}
                            error={fieldState.error?.message}
                            endElement={
                              <>
                                {getValues('job_title') && getValues('job_title') !== '' ? (
                                  <Box
                                    onClick={() => {
                                      setValue('job_title', '');
                                      clearErrors('job_title');
                                    }}
                                    css={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: '$white',
                                      width: '16px',
                                      height: '16px',
                                      borderStyle: 'none',
                                      cursor: 'pointer',
                                      flexShrink: 0,
                                      transition: 'background-color 0.15s linear',
                                      backgroundColor: '$secondary20',
                                      borderRadius: '$rounded',
                                      mr: '$2',
                                      '&:hover': { backgroundColor: '$secondary10' },
                                      '&:active': { backgroundColor: '$secondary30' },
                                    }}>
                                    <MSymbol
                                      iconName="close"
                                      weight={700}
                                      css={{ fontSize: '12px !important', color: 'inherit' }}
                                    />
                                  </Box>
                                ) : null}
                              </>
                            }
                          />
                        )}
                      />
                    </FormControl>
                    <Spacer axis={'horizontal'} css={{ $$size: '16px' }} />
                    {/* Cancel Edit */}
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          iconName="close"
                          shape="squared"
                          variant="outlined"
                          onClick={() => {
                            setValue('job_title', organizationQueries.data.job_title);
                            clearErrors('job_title');
                            setIsEditing(false);
                          }}
                          css={{ width: 28, height: 28, fontSize: 20, flexShrink: 0 }}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Cancel`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                    <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                    {/* Submit Edit */}
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          color={'primary'}
                          iconName="check"
                          shape="squared"
                          variant="outlined"
                          isLoading={organizationMutate.isLoading}
                          onClick={handleSubmit((data) => handleOnEdit(data))}
                          css={{ width: 28, height: 28, fontSize: 20, flexShrink: 0 }}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Submit`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </>
                ) : (
                  <>
                    <Typography variant={'body-medium'} css={{ flexGrow: 1 }}>{`${
                      organizationQueries.data.job_title || '-'
                    }`}</Typography>
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          iconName="edit"
                          css={{ fontSize: '18px' }}
                          onClick={() => setIsEditing(true)}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Edit`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default Organization;
