import { GetNotificationsResult, NotificationEntity } from '../types/notification';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAxios } from '../hooks';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../store/atoms';
import { AxiosError } from 'axios';

const NOTIFICATION_PAGE_LIMIT = 10;

export const useGetNotificationUnread = () => {
  const axios = useAxios();
  return useQuery<{ unread: number }, Error>(['notification-unread'], async () => {
    const { data } = await axios.get(`/v1/number_of_unread_notifications`);
    return data;
  });
};

export const useGetNotificationInfinite = () => {
  const axios = useAxios();
  return useInfiniteQuery<GetNotificationsResult, Error>(
    ['notification'],
    async ({ pageParam = { offset: 0, limit: NOTIFICATION_PAGE_LIMIT } }) => {
      const { data } = await axios.get(`/v1/notification`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: NOTIFICATION_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
            }
          : undefined,
    }
  );
};

export const usePutNotification = (onMutationSuccess?: () => void) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({ is_read_all }: { is_read_all: boolean }) => {
      const { data } = await axios.put(`/v1/notification`, {
        is_read_all,
      });
      return data;
    },
    {
      onSuccess: async (res) => {
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        await queryClient.invalidateQueries(['notification-unread']);
        await queryClient.invalidateQueries(['notification']);
        await queryClient.invalidateQueries(['notification-by-id']);
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useGetNotificationById = (notificationId: string) => {
  const axios = useAxios();
  return useQuery<NotificationEntity, Error>(['notification-by-id', notificationId], async () => {
    const { data } = await axios.get(`/v1/notification/${notificationId}`);
    return data;
  });
};

// * error 才要跳 Toast
export const usePutNotificationById = (notificationId: string, onMutationSuccess?: () => void) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({
      is_read,
      is_notifyee_notification_enabled,
    }: {
      is_read?: boolean;
      is_notifyee_notification_enabled?: boolean;
    }) => {
      const { data } = await axios.put(`/v1/notification/${notificationId}`, {
        is_read,
        is_notifyee_notification_enabled,
      });
      return data;
    },
    {
      onSuccess: async () => {
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        await queryClient.invalidateQueries(['notification-unread']);
        await queryClient.invalidateQueries(['notification-by-id', notificationId]);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};
