import { keyframes, styled } from '../../styles/stitches.config';
import * as RadixToast from '@radix-ui/react-toast';
import { isToastOpenAtom } from '../../store/atoms';
import { useAtom } from 'jotai';
import Typography from '../typography/deprecated-typography';
import IconButton from '../button/icon-button';
import Box from '../box';
import Spacer from '../surface/spacer';
import PopupSuccessIcon from '../../assets/images/icon-popup-success.svg';
import PopupErrorIcon from '../../assets/images/icon-popup-error.svg';

const VIEWPORT_PADDING = 25;

export const ToastProvider = styled(RadixToast.Provider, {});

const ToastViewport = styled(RadixToast.Viewport, {
  position: 'fixed',
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 465,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 1001,
  outline: 'none',
});

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});
const slideIn = keyframes({
  from: { transform: `translateX(calc(-100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});
const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(-100% + ${VIEWPORT_PADDING}px))` },
});

const ToastRoot = styled(RadixToast.Root, {
  backgroundColor: '$white',
  borderRadius: '$5',
  boxShadow: '$basic',
  py: '$6',
  px: '$5',
  display: 'flex',
  alignItems: 'center',
  columnGap: '$4',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '4px',
  },
  '&[data-state="open"]': {
    animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${hide} 100ms ease-in`,
  },
  '&[data-swipe="move"]': {
    transform: 'translateX(var(--radix-toast-swipe-move-x))',
  },
  '&[data-swipe="cancel"]': {
    transform: 'translateX(0)',
    transition: 'transform 200ms ease-out',
  },
  '&[data-swipe="end"]': {
    animation: `${swipeOut} 100ms ease-out`,
  },
});

export interface ToastProps {
  isSuccess?: boolean;
  isError?: boolean;
  success?: string;
  error?: string;
}

const Toast = ({
  isSuccess = false,
  isError = false,
  success = undefined,
  error = undefined,
}: ToastProps) => {
  const [isToastOpen, setIsToastOpen] = useAtom(isToastOpenAtom);

  return (
    <>
      <ToastRoot
        open={isToastOpen}
        onOpenChange={setIsToastOpen}
        css={{ '&:before': { backgroundColor: isSuccess ? '$success70' : '$error60' } }}>
        <Box css={{ alignSelf: 'flex-start' }}>
          <Box
            css={{
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {isSuccess ? (
              <Box
                as="img"
                src={PopupSuccessIcon}
                css={{ objectFit: 'contain', width: '100%', height: '100%', borderRadius: '$5' }}
              />
            ) : null}
            {isError ? (
              <Box
                as="img"
                src={PopupErrorIcon}
                css={{ objectFit: 'contain', width: '100%', height: '100%', borderRadius: '$5' }}
              />
            ) : null}
          </Box>
        </Box>
        <Box>
          <RadixToast.Title>
            {isSuccess ? <Typography variant={'h6'}>{`Success`}</Typography> : null}
            {isError ? <Typography variant={'h6'}>{`Error`}</Typography> : null}
          </RadixToast.Title>
          <RadixToast.Description asChild>
            <>
              {isSuccess ? (
                <Typography
                  variant={'body'}
                  css={{ color: '$secondary70' }}>{`${success}`}</Typography>
              ) : null}
              <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
              {isError ? (
                <Typography
                  variant={'body'}
                  css={{ color: '$secondary70' }}>{`${error}`}</Typography>
              ) : null}
            </>
          </RadixToast.Description>
        </Box>
        <RadixToast.Action asChild altText="Close the toast">
          <IconButton
            iconName={'close'}
            size={'small'}
            isIconBold={true}
            css={{ position: 'absolute', top: 20, right: 20 }}
          />
        </RadixToast.Action>
      </ToastRoot>
      <ToastViewport />
    </>
  );
};

export default Toast;
