import { useEffect, useState } from 'react';

const getBrowserDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    browserWidth: width,
    browserHeight: height,
  };
};

const useBrowserDimensions = () => {
  const [browserDimensions, setBrowserDimensions] = useState(getBrowserDimensions());

  useEffect(() => {
    const handleResize = () => {
      setBrowserDimensions(getBrowserDimensions());
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return browserDimensions;
};

export default useBrowserDimensions;
