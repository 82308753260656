import moment from 'moment';
import { AppStatus } from '../../../../types';
import {
  getApproverResultVariant,
  getApprovalProgressCardIcon,
  getApprovalProgressCardVariant,
} from '../../../../utils/get-variant';
import { getStatusColor } from '../../../../utils/get-color';
import { useGetAppDetailApprovalTab } from '../../../../services/application/simplified-standard/app-detail-tabs.api';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Box from '../../../../components/box';
import Spinner from '../../../../components/helper/spinner';
import Spacer from '../../../../components/surface/spacer';
import MSymbol from '../../../../components/icon/m-symbol';
import Disclosure from '../../../../components/surface/disclosure';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import Avatar from '../../../../components/surface/avatar';
import AwarenessChip from '../../../../components/chip/awareness-chip';
import ApprovalStatusCaption from '../../../../components/typography/approval-status-caption';
import CountingBox from '../../../../components/card/counting-box';
import { Heading6 } from '../../../../components/typography/heading';
import MentionedTypography from '../../../../components/typography/mentioned-typography';
import {
  ApprovalProgressCard,
  ApprovalProgressRoot,
} from '../../../../components/surface/approval-progress';
import EmptyApplicationProcessedBG from '../../../../assets/images/bg-empty-application-processed.svg';

export interface AppDetailApprovalTabProps {
  appId: string;
  needApproval: boolean;
  appStatus: AppStatus | '';
}

const ApprovalTab = ({ appId, needApproval, appStatus }: AppDetailApprovalTabProps) => {
  const appDetailApprovalTabQuery = useGetAppDetailApprovalTab({
    appId,
    needApproval,
    appStatus,
  });
  return (
    <>
      {!needApproval || appStatus === 'draft' ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={EmptyApplicationProcessedBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>
            {appStatus === 'draft'
              ? `The application is still in draft status.`
              : !needApproval
              ? `The application does not require review.`
              : '-'}
          </Text>
        </CenteredBoxAbsolute>
      ) : (
        <>
          {appDetailApprovalTabQuery.isLoading ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Spinner size="large" />
            </CenteredBoxAbsolute>
          ) : appDetailApprovalTabQuery.isSuccess ? (
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 30,
                alignItems: 'center',
                p: 30,
              }}>
              {/* Approval Progress */}
              <ApprovalProgressRoot>
                {appDetailApprovalTabQuery.data.approval_flow_section.map((approvalProgress) => (
                  <ApprovalProgressCard
                    key={approvalProgress.id}
                    state={getApprovalProgressCardVariant(approvalProgress.approver_status_code)}>
                    <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                      <Text bold>{`${approvalProgress.name}`}</Text>
                      <MSymbol
                        className="i"
                        weight={700}
                        iconName={`${getApprovalProgressCardIcon(
                          approvalProgress.approver_status_code
                        )}`}
                      />
                    </Box>
                    <ButtonText size={12}>{`${approvalProgress.desc || '-'}`}</ButtonText>
                  </ApprovalProgressCard>
                ))}
              </ApprovalProgressRoot>
              {/* Approval Details */}
              <Box css={{ width: '100%', maxWidth: 700 }}>
                <Disclosure
                  needDivider={true}
                  panelGap={'30px'}
                  panels={[
                    {
                      id: 'section-approval-detail',
                      name: 'Approval Details',
                      content: (
                        <>
                          {/* Urgent Level */}
                          {appDetailApprovalTabQuery.data.approval_detail_section &&
                          appDetailApprovalTabQuery.data.approval_detail_section.urgent_level ? (
                            <>
                              <AwarenessChip
                                awarenessType="urgent"
                                urgentLevel={
                                  appDetailApprovalTabQuery.data.approval_detail_section
                                    .urgent_level
                                }
                              />
                              <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                            </>
                          ) : null}
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                            {/* Status */}
                            <Box
                              css={{
                                flexBasis: 'calc(50% - 10px)',
                                '> div': { justifyContent: 'flex-start' },
                              }}>
                              <Caption bold css={{ color: '$secondary40' }}>{`Status`}</Caption>
                              <ApprovalStatusCaption
                                status={
                                  appDetailApprovalTabQuery.data.approval_detail_section?.status ??
                                  ''
                                }
                              />
                            </Box>
                          </Box>
                          <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                            {/* Created Date */}
                            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                              <Caption bold css={{ color: '$secondary40' }}>{`Created At`}</Caption>
                              <Text css={{ mt: '$1' }}>{`${
                                appDetailApprovalTabQuery.data.approval_detail_section?.created_at
                                  ? moment(
                                      appDetailApprovalTabQuery.data.approval_detail_section
                                        ?.created_at
                                    ).format('MM/DD/YY HH:mm A (Z)')
                                  : '-'
                              }`}</Text>
                            </Box>
                            {/* Modified Date */}
                            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                              <Caption
                                bold
                                css={{ color: '$secondary40' }}>{`Modified At`}</Caption>
                              <Text css={{ mt: '$1' }}>
                                {appDetailApprovalTabQuery.data.approval_detail_section?.modified_at
                                  ? moment(
                                      appDetailApprovalTabQuery.data.approval_detail_section
                                        ?.modified_at
                                    ).format('MM/DD/YY HH:mm A (Z)')
                                  : '-'}
                              </Text>
                            </Box>
                          </Box>
                          <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                            {/* Applicant */}
                            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                              <Caption bold css={{ color: '$secondary40' }}>{`Applicant`}</Caption>
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mt: '$1',
                                }}>
                                <Avatar
                                  name={
                                    appDetailApprovalTabQuery.data.approval_detail_section
                                      ?.applicant.name || '-'
                                  }
                                  picture={
                                    appDetailApprovalTabQuery.data.approval_detail_section
                                      ?.applicant.picture || ''
                                  }
                                  css={{ mr: 8 }}
                                  size="sm"
                                />
                                <Text semibold>{`${
                                  appDetailApprovalTabQuery.data.approval_detail_section?.applicant
                                    .name ?? '-'
                                }`}</Text>
                              </Box>
                            </Box>
                            {/* Email */}
                            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                              <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                              <Text css={{ mt: '$1' }}>{`${
                                appDetailApprovalTabQuery.data.approval_detail_section?.applicant
                                  .email ?? '-'
                              }`}</Text>
                            </Box>
                          </Box>
                        </>
                      ),
                    },
                    {
                      id: 'section-approver',
                      name: 'Approvers',
                      content: (
                        <Box css={{ display: 'flex', flexDirection: 'column', rowGap: '$5' }}>
                          {appDetailApprovalTabQuery.data.approver_section?.application_approval &&
                          appDetailApprovalTabQuery.data.approver_section.application_approval
                            .length > 0
                            ? appDetailApprovalTabQuery.data.approver_section.application_approval.map(
                                (approver) => (
                                  <Box
                                    key={approver.id}
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Box
                                      css={{
                                        flexBasis: 'calc(50% - 10px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}>
                                      <Box
                                        css={{
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        <Avatar
                                          name={approver.name || '-'}
                                          picture={approver.picture || ''}
                                          css={{ mr: 8 }}
                                          size="sm"
                                        />
                                        <Text semibold>{`${approver.name}`}</Text>
                                      </Box>
                                      {appDetailApprovalTabQuery.data.approval_detail_section
                                        ?.status === 'rejected' &&
                                      approver.status === 'pending' ? null : (
                                        <Box
                                          css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}>
                                          <Box
                                            css={{
                                              width: '8px',
                                              height: '8px',
                                              borderRadius: '$rounded',
                                              backgroundColor: getStatusColor(approver.status),
                                            }}
                                          />
                                          <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
                                          <Text
                                            css={{
                                              mr: '$5',
                                            }}>{`${
                                            approver.status.charAt(0).toUpperCase() +
                                            approver.status.slice(1)
                                          }`}</Text>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                )
                              )
                            : null}
                        </Box>
                      ),
                    },
                    {
                      id: 'section-result',
                      name: 'Result',
                      content: (
                        <Box css={{ p: '$5', borderRadius: '$5', backgroundColor: '$secondary3' }}>
                          <Box css={{ display: 'flex', columnGap: '$2' }}>
                            <Caption
                              bold
                              css={{
                                color: '$secondary70',
                              }}>{`Feedback`}</Caption>
                            <CountingBox
                              countingNumber={
                                appDetailApprovalTabQuery.data.feedback_section
                                  ? appDetailApprovalTabQuery.data.feedback_section.length
                                  : 0
                              }
                            />
                          </Box>
                          {appDetailApprovalTabQuery.data.feedback_section &&
                          appDetailApprovalTabQuery.data.feedback_section?.length > 0 ? (
                            <>
                              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                              {appDetailApprovalTabQuery.data.feedback_section.map(
                                (feedback, index) => (
                                  <Box
                                    key={feedback.id}
                                    css={{
                                      position: 'relative',
                                      borderBottom: '1px $secondary20 solid',
                                      px: 13,
                                      pb: 13,
                                      mt: index !== 0 ? 20 : 0,
                                      '&:before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 13,
                                        left: 0,
                                        width: 3,
                                        borderTopLeftRadius: '$5',
                                        borderBottomLeftRadius: '$5',
                                        borderTopRightRadius: '$5',
                                        borderBottomRightRadius: '$5',
                                        backgroundColor: getApproverResultVariant(
                                          feedback.approver_status_code
                                        )?.color,
                                      },
                                    }}>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '6px',
                                      }}>
                                      <Box
                                        as="img"
                                        css={{ width: 20, height: 20 }}
                                        src={
                                          getApproverResultVariant(feedback.approver_status_code)
                                            ?.icon
                                        }
                                      />
                                      <Heading6
                                        css={{
                                          color: getApproverResultVariant(
                                            feedback.approver_status_code
                                          )?.color,
                                        }}>{`${feedback.approval_result}`}</Heading6>
                                    </Box>
                                    <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
                                    <Box>
                                      <Text
                                        bold
                                        css={{ mr: '8px' }}>{`${feedback.approver.name}`}</Text>
                                      <Caption css={{ color: '$secondary70' }}>{`${moment(
                                        feedback.approved_at
                                      ).format('MM/DD/YY HH:mm A (Z)')}`}</Caption>
                                    </Box>
                                    <Spacer axis={'vertical'} css={{ $$size: '6px' }} />
                                    <MentionedTypography
                                      ellipsisLine="revert"
                                      message={feedback.feedback_content}
                                    />
                                  </Box>
                                )
                              )}
                            </>
                          ) : (
                            <Text
                              bold
                              css={{
                                mt: '10px',
                                color: '$secondary40',
                              }}>{`There were no responses from reviewers.`}</Text>
                          )}
                        </Box>
                      ),
                    },
                  ]}
                />
              </Box>
            </Box>
          ) : appDetailApprovalTabQuery.isError ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Box as="img" src={ErrorBG} />
              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
              <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
            </CenteredBoxAbsolute>
          ) : null}
        </>
      )}
    </>
  );
};

export default ApprovalTab;
