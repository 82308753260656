import { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  addCommentContentAtom,
  authUserAtom,
  getApplicationCommentsParamsAtom,
  isAddCommentEditorOpenAtom,
} from '../../../../store/atoms';
import { useQueryClient } from 'react-query';
import { motion } from 'framer-motion';
import { Mention } from 'react-mentions';
import { useInView } from 'react-intersection-observer';
import {
  useGetAppDetailCommentPanel,
  usePostAppComment,
} from '../../../../services/application/verified-standard/app-detail-panels.api';
import { useGetMentionableUserOptions } from '../../../../services/application/verified-standard/dropdown.api';
import { checkAttachmentsLength } from '../../../../utils/check-checked';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Spinner from '../../../../components/helper/spinner';
import { CenteredBoxAbsolute, CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spacer from '../../../../components/surface/spacer';
import NoCommentBG from '../../../../assets/images/bg-no-comment.svg';
import { Heading5, Heading6 } from '../../../../components/typography/heading';
import Divider from '../../../../components/surface/divider';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import {
  SimpleSelectContent,
  SimpleSelectIcon,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../../../components/dropdown/simple-select';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import Button from '../../../../components/button/button';
import CommentCard from '../../../../components/card/comment-card';
import MSymbol from '../../../../components/icon/m-symbol';
import { applicationCommentsFilterByOptions } from '../../../../store/constants';
import { AppDetailCommentPanelFilterType } from '../../../../types/constants';
import IconButton from '../../../../components/button/icon-button';
import Avatar from '../../../../components/surface/avatar';
import MentionArea from '../../../../components/dropdown/mention-area';
import AttachPDFLightOnIcon from '../../../../assets/images/icon-attach-pdf-light-on.svg';
import PopupErrorIcon from '../../../../assets/images/icon-popup-error.svg';

export interface AppDetailCommentPanelProps {
  appId: string;
}

const COMMENT_HEADER_HEIGHT = 64;
const IMAGE_LIMIT = 8;
const PDF_LIMIT = 2;
const ALLOWED_FILE_MAX_SIZE = 2; // * MB

const CommentPanel = ({ appId }: AppDetailCommentPanelProps) => {
  const authUser = useAtomValue(authUserAtom);
  const { name, picture } = authUser || {};
  const queryClient = useQueryClient();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [isAddEditorOpen, setIsAddEditorOpen] = useAtom(isAddCommentEditorOpenAtom);
  const [addContents, setAddContents] = useAtom(addCommentContentAtom);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isSelectedFilesLimitExceeded, setIsSelectedFilesLimitExceeded] = useState(false);
  const [isImageFileSizeOver, setIsImageFileSizeOver] = useState(false);
  const [isFileSizeOver, setIsFileSizeOver] = useState(false);
  const [getApplicationCommentsParams, setGetApplicationCommentsParams] = useAtom(
    getApplicationCommentsParamsAtom
  );
  const appDetailCommentPanelQuery = useGetAppDetailCommentPanel({
    appId,
    ...getApplicationCommentsParams,
  });
  const mentionableUserQuery = useGetMentionableUserOptions({ appId });

  const onAddEditorClose = () => {
    setAddContents('');
    setSelectedFiles([]);
    setIsAddEditorOpen(false);
    setIsFileSizeOver(false);
    setIsImageFileSizeOver(false);
    setIsSelectedFilesLimitExceeded(false);
  };
  const onAddCommentClick = (open: boolean) => {
    if (!isAddEditorOpen) {
      setIsAddEditorOpen(open);
    } else {
      onAddEditorClose();
    }
  };

  const postAppCommentMutation = usePostAppComment({
    appId: appId || '',
    onSuccess: () => {
      onAddEditorClose();
      queryClient.setQueryData(
        [
          'app',
          'verified',
          appId,
          'comments',
          getApplicationCommentsParams.offset,
          getApplicationCommentsParams.limit,
          getApplicationCommentsParams.filter_by,
        ],
        (data: any) => ({
          pages: [],
          pageParams: {},
        })
      );
      appDetailCommentPanelQuery.refetch({ refetchPage: (page, index) => index === 0 });
    },
  });

  const onPostAppComment = () => {
    const payloadSelectedFiles = selectedFiles.reduce((payload, file, index) => {
      const key = `file_${index + 1}`;
      payload[key] = file;
      return payload;
    }, {});
    postAppCommentMutation.mutate({ content: addContents, ...payloadSelectedFiles });
  };
  const onFileChosen = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const onFileCancelChosen = (fileName: string) => {
    const indexToCancelChosen = selectedFiles.findIndex((file) => file.name === fileName);
    setSelectedFiles((prev) => prev.filter((_, index) => index !== indexToCancelChosen));
  };
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles((prev) => {
        const currentSelectedFiles = Array.prototype.slice.call(e.target.files);
        if (
          checkAttachmentsLength({ fileType: 'image', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'image', files: prev }) >
            IMAGE_LIMIT ||
          checkAttachmentsLength({ fileType: 'pdf', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'pdf', files: prev }) >
            PDF_LIMIT
        ) {
          setIsSelectedFilesLimitExceeded(true);
          return [...prev];
        }
        setIsSelectedFilesLimitExceeded(false);
        return [...prev, ...currentSelectedFiles];
      });
    }
  };

  const { ref, inView } = useInView();

  const fetchNextPageCallback = useCallback(() => {
    appDetailCommentPanelQuery.fetchNextPage();
  }, [appDetailCommentPanelQuery]);

  useEffect(() => {
    if (inView) {
      fetchNextPageCallback();
    }
  }, [inView]);

  useEffect(() => {
    const hasImageFileSizeOver = selectedFiles
      .filter(
        (file) =>
          file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      )
      .some((file) => file.size > 2 * 1024 * 1024);
    const hasFileSizeOver = selectedFiles.some((file) => file.size > 2 * 1024 * 1024);
    setIsImageFileSizeOver(hasImageFileSizeOver);
    setIsFileSizeOver(hasFileSizeOver);
  }, [selectedFiles]);

  return (
    <>
      {appDetailCommentPanelQuery.isLoading ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Spinner size="large" />
        </CenteredBoxFlex>
      ) : appDetailCommentPanelQuery.isSuccess ? (
        <>
          <CenteredBoxFlex css={{ height: 64, flexShrink: 0 }}>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 290,
              }}>
              <Heading5
                css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>{`Comment`}</Heading5>
              {/* Filter */}
              <Box css={{ width: 126 }}>
                <SimpleSelectRoot
                  value={getApplicationCommentsParams.filter_by}
                  onValueChange={(newValue) => {
                    setGetApplicationCommentsParams((prev) => ({
                      ...prev,
                      filter_by: newValue as AppDetailCommentPanelFilterType,
                    }));
                  }}>
                  <SimpleSelectTrigger>
                    <Box
                      css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <SimpleSelectValue placeholder="Modified Date" />
                    </Box>
                    <SimpleSelectIcon asChild>
                      <Box css={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                        <MSymbol iconName="arrow_drop_down" css={{ color: 'inherit' }} />
                      </Box>
                    </SimpleSelectIcon>
                  </SimpleSelectTrigger>
                  <SimpleSelectPortal>
                    <SimpleSelectContent position="popper" sideOffset={4} css={{ width: 180 }}>
                      <SimpleSelectViewport>
                        <Box
                          css={{
                            height: 40,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            px: 10,
                          }}>
                          <Heading6>{`Filter by`}</Heading6>
                        </Box>
                        <Divider axis={'horizontal'} css={{ backgroundColor: '$achromatic10' }} />
                        {applicationCommentsFilterByOptions.map((filterByOption) => (
                          <SimpleSelectItem key={filterByOption.id} value={filterByOption.value}>
                            <SimpleSelectItemText>{`${filterByOption.name}`}</SimpleSelectItemText>
                          </SimpleSelectItem>
                        ))}
                      </SimpleSelectViewport>
                    </SimpleSelectContent>
                  </SimpleSelectPortal>
                </SimpleSelectRoot>
              </Box>
              {/* Add New Comment Button */}
              <IconButton
                iconName="add"
                isIconBold={true}
                size="small"
                color="primary"
                onClick={() => onAddCommentClick(true)}
              />
            </Box>
          </CenteredBoxFlex>
          <Divider
            axis={'horizontal'}
            css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
          />
          {/* <Spacer axis={'vertical'} css={{ $$size: '20px' }} /> */}
          {/* Add Editor */}
          <Box
            as={motion.div}
            initial="hide"
            animate={isAddEditorOpen ? 'show' : 'hide'}
            variants={{
              show: { opacity: 1, display: 'flex' },
              hide: {
                opacity: 0,
                transitionEnd: { display: 'none' },
              },
            }}
            css={{
              mt: 20,
              borderRadius: '$5',
              border: '1px solid $secondary20',
              flexDirection: 'column',
              rowGap: 10,
              p: 10,
              width: 280,
              mx: 20,
              zIndex: 99999,
            }}>
            {/* Avatar */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: '100%' }}>
              <Avatar name={name || '-'} picture={picture || ''} />
              <Box css={{ flexGrow: 1 }}>
                <Text bold>{name || '-'}</Text>
              </Box>
              {/* Close Button */}
              <Box css={{ display: 'flex', alignItems: 'center' }}>
                <IconButton iconName="close" size="small" onClick={onAddEditorClose} />
              </Box>
            </Box>
            {/* React Mentions */}
            <MentionArea value={addContents} onChange={(e, newValue) => setAddContents(newValue)}>
              <Mention
                trigger="@"
                className={`${MentionArea.className}__mention`}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => {
                  return `@${display}`;
                }}
                data={mentionableUserQuery.data || []}
              />
            </MentionArea>
            {/* Selected attachments (Images) */}
            {checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 0 ? (
              <>
                <Box
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    rowGap: 6,
                    columnGap:
                      checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 2
                        ? 6
                        : 10,
                    height:
                      checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) < 3 ||
                      checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 4
                        ? 125
                        : 60,
                  }}>
                  {selectedFiles
                    .filter(
                      (file) =>
                        file.type === 'image/jpg' ||
                        file.type === 'image/jpeg' ||
                        file.type === 'image/png'
                    )
                    .map((image) => (
                      <Box
                        key={image.name}
                        css={{
                          borderRadius: '$10',
                          overflow: 'hidden',
                          flexBasis:
                            checkAttachmentsLength({
                              fileType: 'image',
                              files: selectedFiles,
                            }) > 2
                              ? `calc(25% - ${18 / 4}px)`
                              : checkAttachmentsLength({
                                  fileType: 'image',
                                  files: selectedFiles,
                                }) === 2
                              ? `calc(50% - 5px)`
                              : `calc(100%)`,
                          border: '1px solid $secondary10',
                          height:
                            checkAttachmentsLength({
                              fileType: 'image',
                              files: selectedFiles,
                            }) > 4
                              ? 'calc(50% - 3px)'
                              : '100%',
                          position: 'relative',
                          '&:hover': {
                            '> .attachment-mask': {
                              backgroundColor: 'rgba(0,0,0,0.3)',
                            },
                            '> .attachment-cancel-chosen': {
                              visibility: 'visible',
                            },
                          },
                        }}>
                        <IconButton
                          iconName="close"
                          isIconBold={true}
                          size={'mini'}
                          className="attachment-cancel-chosen"
                          css={{
                            position: 'absolute',
                            top: 6,
                            right: 6,
                            zIndex: 90,
                            color: '$white',
                            visibility: 'hidden',
                          }}
                          onClick={() => onFileCancelChosen(image.name)}
                        />
                        <Box
                          className="attachment-mask"
                          css={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            zIndex: 85,
                            transition: '$colors',
                            backgroundColor: '$transparent',
                          }}
                        />
                        {Math.round((image.size / (1024 * 1024)) * 100) / 100 >
                        ALLOWED_FILE_MAX_SIZE ? (
                          <Box
                            css={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              right: 0,
                              left: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <Box as="img" src={PopupErrorIcon} css={{ width: 30 }}></Box>
                          </Box>
                        ) : null}
                        <Box
                          as="img"
                          src={URL.createObjectURL(image)}
                          css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                      </Box>
                    ))}
                </Box>
              </>
            ) : null}
            {/* Selected attachments (PDFs) */}
            {checkAttachmentsLength({ fileType: 'pdf', files: selectedFiles }) > 0 ? (
              <>
                <Box
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 10,
                  }}>
                  {selectedFiles
                    .filter((file) => file.type === 'application/pdf')
                    .map((pdf) => (
                      <Box
                        key={pdf.name}
                        css={{
                          position: 'relative',
                          borderRadius: '$5',
                          overflow: 'hidden',
                          '&:hover': {
                            '> .attachment-mask': {
                              backgroundColor: 'rgba(0,0,0,0.3)',
                            },
                            '> .attachment-cancel-chosen': {
                              visibility: 'visible',
                            },
                          },
                        }}>
                        <IconButton
                          iconName="close"
                          isIconBold={true}
                          size={'mini'}
                          className="attachment-cancel-chosen"
                          css={{
                            position: 'absolute',
                            top: 6,
                            right: 6,
                            zIndex: 90,
                            color: '$white',
                            visibility: 'hidden',
                          }}
                          onClick={() => onFileCancelChosen(pdf.name)}
                        />
                        <Box
                          className="attachment-mask"
                          css={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            zIndex: 85,
                            transition: '$colors',
                            backgroundColor: '$transparent',
                          }}
                        />
                        <Box
                          css={{
                            backgroundColor: '$secondary5',
                            px: 5,
                            py: 6,
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 6,
                            justifyContent: 'space-between',
                          }}>
                          <Box as="img" src={AttachPDFLightOnIcon} />
                          <Box
                            css={{
                              flexGrow: 1,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: 206,
                            }}>
                            <Text
                              semibold
                              css={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {pdf.name || '-'}
                            </Text>
                            <Caption
                              css={{
                                color: '$secondary40',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {`${Math.round((pdf.size / (1024 * 1024)) * 100) / 100} MB`}
                            </Caption>
                            {Math.round((pdf.size / (1024 * 1024)) * 100) / 100 >
                            ALLOWED_FILE_MAX_SIZE ? (
                              <Caption
                                css={{
                                  display: 'block',
                                  color: '$error70',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}>
                                {`Maximum file size 2MB`}
                              </Caption>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </>
            ) : null}
            {/* Attachments */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
              {/* Select File Button */}
              <TooltipRoot>
                <TooltipTrigger asChild>
                  <Box css={{ width: 'fit-content' }}>
                    <IconButton iconName="attach_file" color={'secondary'} onClick={onFileChosen} />
                    <input
                      ref={hiddenFileInput}
                      type="file"
                      multiple
                      accept="application/pdf, image/png, image/jpg, image/jpeg"
                      style={{ display: 'none' }}
                      onChange={onFileChange}
                    />
                  </Box>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent
                    sideOffset={5}
                    side={`top`}
                    css={{
                      px: 16,
                      py: 4.5,
                    }}>
                    <Text css={{ color: '$white' }}>{`Attach a file`}</Text>
                    <TooltipArrow />
                  </TooltipContent>
                </TooltipPortal>
              </TooltipRoot>
              {/* Selected files limit exceeded */}
              <Box
                as={motion.div}
                initial="hide"
                animate={isSelectedFilesLimitExceeded || isImageFileSizeOver ? 'show' : 'hide'}
                variants={{
                  show: { display: 'flex', opacity: 1 },
                  hide: { opacity: 0, transitionEnd: { display: 'none' } },
                }}
                css={{ alignItems: 'center' }}>
                {isSelectedFilesLimitExceeded ? (
                  <Caption
                    css={{
                      color: '$error70',
                    }}>{`File limitation : Up to 8 images and a maximum of 2 PDFs.`}</Caption>
                ) : null}
                {isImageFileSizeOver ? (
                  <Caption
                    css={{
                      color: '$error70',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                    {`Maximum file size 2MB`}
                  </Caption>
                ) : null}
              </Box>
            </Box>
            {/* Cancel, Save Button */}
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 10,
                width: '100%',
                justifyContent: 'flex-end',
              }}>
              <Button
                size="xs"
                color="achromatic-primary"
                variant="outlined"
                onClick={onAddEditorClose}>
                <ButtonText size="12" bold>
                  Cancel
                </ButtonText>
              </Button>
              <Button
                size="xs"
                onClick={onPostAppComment}
                disabled={isFileSizeOver}
                isLoading={postAppCommentMutation.isLoading}
                css={{ width: 51 }}>
                <ButtonText size="12" bold>
                  Save
                </ButtonText>
              </Button>
            </Box>
          </Box>
          {appDetailCommentPanelQuery.data.pages.length > 0 &&
          appDetailCommentPanelQuery.data.pages[0].application_comments.length > 0 ? (
            <ScrollAreaRoot css={{ flexGrow: 1, p: 20 }}>
              <ScrollAreaViewport>
                {appDetailCommentPanelQuery.data.pages.map((page, pageIndex) => {
                  return (
                    <Fragment key={pageIndex}>
                      {page.application_comments.length > 0 ? (
                        <>
                          {page.application_comments.map((comment) => {
                            return (
                              <CommentCard
                                key={comment.id}
                                applicationId={comment.application_id}
                                commentId={comment.id}
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </Fragment>
                  );
                })}
                <Caption
                  ref={ref}
                  css={{
                    display: 'block',
                    color: '$primary50',
                    textAlign: 'center',
                    mt: 20,
                    mb: 30,
                  }}>
                  {appDetailCommentPanelQuery.isFetchingNextPage
                    ? 'Loading more...'
                    : `No more comments`}
                </Caption>
                <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaViewport>
            </ScrollAreaRoot>
          ) : (
            <CenteredBoxFlex css={{ height: '100%' }}>
              <Box as="img" src={NoCommentBG} />
              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
              <Text
                semibold
                css={{
                  textAlign: 'center',
                }}>{`${'No comments at this point'}`}</Text>
            </CenteredBoxFlex>
          )}
        </>
      ) : appDetailCommentPanelQuery.isError ? (
        <CenteredBoxFlex css={{ height: '100%', rowGap: 20 }}>
          <Box as="img" src={NoCommentBG} />
          <Box>
            <Text
              semibold
              css={{
                textAlign: 'center',
              }}>{`${'Something went wrong'}`}</Text>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Caption semibold css={{ width: 280, textAlign: 'center' }}>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Caption>
          </Box>
          <Button
            variant="solid"
            size={'sm'}
            isLoading={appDetailCommentPanelQuery.isLoading}
            onClick={() => {
              appDetailCommentPanelQuery.refetch();
            }}>
            <ButtonText size={12} bold>
              Refresh
            </ButtonText>
          </Button>
        </CenteredBoxFlex>
      ) : null}
    </>
  );
};

export default CommentPanel;
