// import type { IdNameValueItem, LabelColor } from '../../types';
import type { ValidateResult } from '../../types/constants';
import type { CustomerEntity, PostCustomerPayload } from '../../types/customers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOracleOptions } from '../../services/application/official-standard/oracle-options.api';
import { useGetCountryCodeAppOptions } from '../../services/application/official-standard/app-options.api';
import {
  useGetCustomerById,
  usePostCustomer,
  usePutCustomerById,
} from '../../services/customer.api';
import {
  isTaxRegNumberFieldOptional,
  isTaxRegNumberFieldRequired,
  taxRegNumberFieldLabel,
} from '../../utils/tax-reg-number-field';
import { checkRequiredField } from '../../utils/check-validation';
import Box from '../../components/box';
import Spacer from '../../components/surface/spacer';
import Typography from '../../components/typography/deprecated-typography';
import Divider from '../../components/surface/divider';
import Select from '../../components/dropdown/select';
import Field from '../../components/field/deprecated-field';
import Button from '../../components/button/deprecated-button';
import { useQueryClient } from 'react-query';
// import ColorfulChip from '../../components/colorful-chip';

const postCustomerPayload = {
  company_name: '',
  customer_abbreviation: '',
  category: '',
  country_code: '',
  tax_reg_number: '',
  address: '',
};

const CreateCompany = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const queryClient = useQueryClient();
  const categoryOracleOptionsQueries = useGetOracleOptions('customerCategory');
  const countryCodeAppOptionsQueries = useGetCountryCodeAppOptions(false);

  const [customerGeneralInformation, setCustomerGeneralInformation] =
    useState<PostCustomerPayload>(postCustomerPayload);
  const [validateResult, setValidateResult] = useState<ValidateResult>({});

  // const [labels, setLabels] = useState<{ id: string; color: LabelColor; tag: string }[]>([]);

  const postCustomerMutation = usePostCustomer({
    onMutationSuccess: () => {
      setCustomerGeneralInformation(postCustomerPayload);
      setValidateResult({});
      navigate('..');
    },
  });

  const putCustomerByIdMutation = usePutCustomerById({
    onMutationSuccess: () => {
      navigate(`..`);
    },
  });

  useEffect(() => {
    if (customerId) {
      const editCustomerPayload = queryClient.getQueryData<CustomerEntity>([
        'customer-by-id',
        customerId,
      ]);
      if (editCustomerPayload) {
        setCustomerGeneralInformation({
          company_name: editCustomerPayload.company_name,
          customer_abbreviation: editCustomerPayload.customer_abbreviation,
          category: editCustomerPayload.category,
          tax_reg_number: editCustomerPayload.tax_reg_number,
          country_code: editCustomerPayload.country_code,
          address: editCustomerPayload.address,
        });
      }
    }
  }, [customerId, queryClient]);

  return (
    <Box css={{ px: '20px', pt: '20px', pb: '50px' }}>
      <Box css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h4">{`${customerId ? 'Edit Company' : 'Create Company'}`}</Typography>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '30px' }} />
      {/* Paper */}
      <Box
        css={{
          m: '0 auto',
          width: '780px',
          px: '$10',
          py: '$5',
          backgroundColor: '$white',
        }}>
        <Typography variant="h6">{`Organization Detail`}</Typography>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        {/* General Information */}
        <Typography
          variant="body-bold"
          css={{ color: '$secondary40' }}>{`General Information`}</Typography>
        <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <Box>
          <Box css={{ display: 'flex', columnGap: '$5' }}>
            {/* Company Name */}
            <Box css={{ flexBasis: 'calc(100%)' }}>
              <Field
                label="Company Name"
                required={true}
                value={customerGeneralInformation.company_name}
                onChange={(newValue) =>
                  setCustomerGeneralInformation((prev) => ({ ...prev, company_name: newValue }))
                }
                placeholder="Enter a company name"
                isError={validateResult.company_name?.is_error ?? false}
                error={validateResult.company_name?.error ?? ''}
              />
            </Box>
          </Box>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          <Box css={{ display: 'flex', columnGap: '$5' }}>
            {/* Customer Abbreviation */}
            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
              <Field
                label="Abbreviation"
                value={customerGeneralInformation.customer_abbreviation}
                onChange={(newValue) =>
                  setCustomerGeneralInformation((prev) => ({
                    ...prev,
                    customer_abbreviation: newValue,
                  }))
                }
                placeholder="Enter a company abbreviation"
              />
            </Box>
            {/* Category */}
            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
              <Select
                label="Category"
                required={true}
                value={customerGeneralInformation.category}
                options={categoryOracleOptionsQueries.data}
                onChange={(newValue) =>
                  setCustomerGeneralInformation((prev) => ({ ...prev, category: newValue }))
                }
                placeholder="Select a category"
                isLoading={categoryOracleOptionsQueries.isLoading}
                isError={validateResult.category?.is_error ?? false}
                error={validateResult.category?.error ?? ''}
              />
            </Box>
          </Box>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          <Box css={{ display: 'flex', columnGap: '$5' }}>
            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
              {/* Customer Country */}
              <Select
                label="Country"
                required={true}
                value={customerGeneralInformation.country_code ?? ''}
                options={countryCodeAppOptionsQueries.data}
                placeholder="Select a country"
                onChange={(newValue) =>
                  setCustomerGeneralInformation((prev) => ({ ...prev, country_code: newValue }))
                }
                isLoading={countryCodeAppOptionsQueries.isLoading}
                isError={validateResult.country_code?.is_error ?? false}
                error={validateResult.country_code?.error ?? ''}
              />
            </Box>
            {/* Tax Reg Number (統一編號) */}
            <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
              {/* Tex Reg Number (統一編號) */}
              <Field
                label={taxRegNumberFieldLabel(customerGeneralInformation.country_code ?? '')}
                required={isTaxRegNumberFieldRequired(
                  customerGeneralInformation.country_code ?? ''
                )}
                value={customerGeneralInformation.tax_reg_number ?? ''}
                onChange={(newValue) =>
                  setCustomerGeneralInformation((prev) => ({ ...prev, tax_reg_number: newValue }))
                }
                placeholder={`Enter a ${taxRegNumberFieldLabel(
                  customerGeneralInformation.country_code ?? ''
                )}`}
                disabled={
                  !isTaxRegNumberFieldRequired(customerGeneralInformation.country_code ?? '') &&
                  !isTaxRegNumberFieldOptional(customerGeneralInformation.country_code ?? '')
                }
                isError={validateResult.tex_reg_number?.is_error ?? false}
                error={validateResult.tex_reg_number?.error ?? ''}
              />
            </Box>
          </Box>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          {/* Address */}
          <Box>
            <Field
              label="Address"
              required={true}
              value={customerGeneralInformation.address}
              onChange={(newValue) =>
                setCustomerGeneralInformation((prev) => ({ ...prev, address: newValue }))
              }
              placeholder="Enter an address"
              isError={validateResult.address?.is_error ?? false}
              error={validateResult.address?.error ?? ''}
            />
          </Box>
        </Box>
        <Spacer axis="vertical" css={{ $$size: '30px' }} />
        {/* TODO: Labels */}
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '$5',
          }}>
          <Button
            variant="borderless"
            color="secondary"
            onClick={() => {
              if (customerId) {
                navigate(`../customer-details/${customerId}`);
              } else {
                navigate(`..`);
              }
            }}>{`Cancel`}</Button>
          <Button
            isLoading={postCustomerMutation.isLoading}
            onClick={() => {
              const companyNameValidation = checkRequiredField(
                customerGeneralInformation.company_name
              );
              const addressValidation = checkRequiredField(customerGeneralInformation.address);
              const categoryValidation = checkRequiredField(customerGeneralInformation.category);
              const countryCodeValidation = checkRequiredField(
                customerGeneralInformation.country_code
              );
              const texRegNumberValidation = isTaxRegNumberFieldRequired(
                customerGeneralInformation.country_code ?? ''
              )
                ? checkRequiredField(customerGeneralInformation.tax_reg_number)
                : undefined;

              setValidateResult({
                company_name: companyNameValidation,
                country_code: countryCodeValidation,
                tex_reg_number: texRegNumberValidation,
                category: categoryValidation,
                address: addressValidation,
              });
              if (
                companyNameValidation.is_success &&
                countryCodeValidation.is_success &&
                categoryValidation.is_success &&
                addressValidation.is_success &&
                (texRegNumberValidation ? texRegNumberValidation.is_success : true)
              ) {
                if (customerId) {
                  putCustomerByIdMutation.mutate({
                    customerId,
                    requestBody: customerGeneralInformation,
                  });
                } else {
                  postCustomerMutation.mutate(customerGeneralInformation as PostCustomerPayload);
                }
              }
            }}>{`Submit`}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCompany;
