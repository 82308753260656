import type { IdNameValueItem } from '../../types';
import type { ReactElement } from 'react';
import Box from '../box';
import Select from './select';
import Field from '../field/deprecated-field';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';

interface ComboFieldProps {
  mode?: 'easy' | 'normal';
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  fieldPlaceholder?: string;
  fieldValue: string;
  isFieldError?: boolean;
  fieldError?: string;
  fieldEndElement?: ReactElement;
  isFieldDisabled?: boolean;
  selectValue: string;
  selectPlaceholder?: string;
  selectOptions?: IdNameValueItem[];
  isSelectOptionsLoading?: boolean;
  isSelectError?: boolean;
  selectError?: string;
  isSelectDisabled?: boolean;
  onSelectChange: (newValue: string) => void;
  onFieldChange: (newValue: string) => void;
}

const getInputWidth = (selectValue: string, mode?: 'easy' | 'normal') => {
  switch (true) {
    case mode === 'easy' || (mode === 'normal' && selectValue === '-1'):
      return { selectWidth: 'calc(45% - 6px)', fieldWidth: 'calc(55% - 6px)', columnGap: '$3' };
    case mode === 'normal':
      return { selectWidth: '100%', fieldWidth: '0px', columnGap: 0 };
    default:
      return { selectWidth: '0px', fieldWidth: '0px', columnGap: 0 };
  }
};

const ComboField = ({
  mode,
  label,
  labelPosition,
  required,
  fieldValue,
  fieldPlaceholder,
  isFieldError,
  fieldError,
  fieldEndElement,
  isFieldDisabled,
  selectValue,
  selectPlaceholder,
  selectOptions,
  isSelectOptionsLoading,
  isSelectError,
  selectError,
  isSelectDisabled,
  onSelectChange,
  onFieldChange,
}: ComboFieldProps) => {
  return (
    <Box
      css={{
        width: '100%',
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <Box
        css={{
          display: 'flex',
          transition: 'column-gap 0.15s linear',
          columnGap: getInputWidth(selectValue, mode).columnGap,
        }}>
        <Box
          css={{
            transition: 'flex-basis 0.15s linear',
            flexBasis: getInputWidth(selectValue, mode).selectWidth,
          }}>
          <Select
            value={selectValue}
            onChange={(newValue) => onSelectChange(newValue)}
            placeholder={selectPlaceholder}
            options={selectOptions}
            isLoading={isSelectOptionsLoading}
            isError={isSelectError}
            error={selectError}
            disabled={isSelectDisabled}
          />
        </Box>
        <Box
          css={{
            transition: 'flex-basis 0.15s linear',
            flexBasis: getInputWidth(selectValue, mode).fieldWidth,
            overflow: 'hidden',
          }}>
          <Field
            value={fieldValue}
            onChange={(newValue) => onFieldChange(newValue)}
            placeholder={fieldPlaceholder}
            isError={isFieldError}
            error={fieldError}
            endElement={fieldEndElement}
            disabled={isFieldDisabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ComboField;
