import React from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import { styled } from '../../styles/stitches.config';

export const SwitchThumb = styled(RadixSwitch.Thumb, {
  display: 'block',
  width: 12,
  height: 12,
  backgroundColor: 'white',
  outlineWidth: '1px',
  outlineStyle: 'solid',
  outlineColor: '$primary50',
  borderRadius: '$rounded',
  transition: 'transform 100ms, outline-color 0.2s cubic-bezier(0.4, 0, 0.6, 1)',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&:active': {
    borderColor: '$primary60',
  },
  '&[data-state="checked"]': { transform: 'translateX(17px)', borderColor: '$primary60' },
});

export const SwitchRoot = styled(RadixSwitch.Root, {
  all: 'unset',
  width: 30,
  height: 16,
  cursor: 'pointer',
  backgroundColor: '$white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$primary50',
  borderRadius: '9999px',
  position: 'relative',
  WebkitTapHighlightColor: '$transparent',
  transition:
    'border-color 0.2s cubic-bezier(0.4, 0, 0.6, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1)',
  '&:focus': { outline: 'none' },
  '&:hover': {
    backgroundColor: '$primary40',
    borderColor: '$primary40',
    [`> ${SwitchThumb}`]: {
      outlineColor: '$primary40',
    },
  },
  '&:active': {
    backgroundColor: '$primary60',
    borderColor: '$primary60',
    [`> ${SwitchThumb}`]: {
      outlineColor: '$primary60',
    },
  },
  '&[data-state="checked"]': {
    borderColor: '$primary60',
    backgroundColor: '$primary60',
    [`> ${SwitchThumb}`]: {
      outlineColor: '$primary60',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '$secondary20',
      borderColor: '$secondary20',
      [`> ${SwitchThumb}`]: {
        outlineColor: '$secondary20',
      },
    },
  },
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '$white',
    borderColor: '$secondary20',
    [`> ${SwitchThumb}`]: {
      outlineColor: '$secondary20',
    },
  },
});
