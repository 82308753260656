import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useLogin } from '../services/auth.api';
import Typography from '../components/typography/deprecated-typography';
import Spacer from '../components/surface/spacer';
import Box from '../components/box';
import Spinner from '../components/helper/spinner';
import { ReactComponent as MileCoolabLogoSVG } from '../assets/images/logo-milecoolab.svg';

const LoginPage = () => {
  const navigate = useNavigate();
  const loginMutation = useLogin({
    onMutationSuccess: () => {
      navigate('/');
    },
  });
  const [isOAuthError, setIsOAuthError] = useState(false);

  return (
    <Box
      css={{
        width: 420,
        height: 400,
        backgroundColor: '$white',
        borderRadius: 20,
        px: '$10',
        pt: 72,
        pb: 35,
        boxShadow: '0px 0px 6px rgba(48, 140, 227, 0.3)',
      }}>
      <MileCoolabLogoSVG style={{ margin: '0 auto' }} />
      <Spacer axis={'vertical'} css={{ $$size: '60px' }} />
      <Box>
        <Typography as={'h2'} variant={'h2'} align={'center'}>
          {`Login`}
        </Typography>
        <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
        <Typography as={'p'} variant={'subtitle'} align={'center'} css={{ color: '$secondary70' }}>
          {`Welcome to Contract System`}
        </Typography>
        <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
        <GoogleLogin
          size={'large'}
          width={'340px'}
          onSuccess={(credentialResponse) => {
            setIsOAuthError(false);
            loginMutation.mutate(credentialResponse);
          }}
          onError={() => setIsOAuthError(true)}
        />
        {loginMutation.isError || isOAuthError ? (
          <Typography
            as={'p'}
            variant={'body-bold'}
            align={'center'}
            css={{ color: '$error50', mt: 6 }}>
            {`${loginMutation.error}`}
          </Typography>
        ) : null}
        {loginMutation.isLoading ? (
          <Box css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
            <Spinner size={'small'} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default LoginPage;
