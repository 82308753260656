import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types';
import {
  PostSimpGCPStdAppPayload,
  PostSimpGWSStdAppPayload,
} from '../../../types/application/simplified/create';
import {
  currentApplicationsTabAtom,
  getApplicationParamsAtom,
  isToastOpenAtom,
  toastContentAtom,
} from '../../../store/atoms';

export const usePostSimpStdApp = () => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setCurrentApplicationsTab = useSetAtom(currentApplicationsTabAtom);
  const resetGetApplicationParams = useResetAtom(getApplicationParamsAtom);
  const setGetApplicationParams = useSetAtom(getApplicationParamsAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<
    any,
    AxiosError<CommonAPIResponse>,
    PostSimpGCPStdAppPayload | PostSimpGWSStdAppPayload
  >({
    mutationKey: ['app', 'simp', 'create'],
    mutationFn: async (requestBody: PostSimpGCPStdAppPayload | PostSimpGWSStdAppPayload) => {
      const { data } = await axios.post<any>(`/v1/simplified_application/`, requestBody);
      return data;
    },
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(['applications']);
      resetGetApplicationParams();
      if (variables.status === 'pending') {
        setCurrentApplicationsTab('applied_by_me');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'applied_by_me' }));
      }
      if (variables.status === 'draft') {
        setCurrentApplicationsTab('drafts');
        setGetApplicationParams((prev) => ({ ...prev, tab: 'drafts' }));
      }
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
      setIsToastOpen(true);
      navigate('/applications');
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
      navigate('/applications');
    },
  });
};
