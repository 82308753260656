import { styled } from '../../styles/stitches.config';
import Box from '../box';

const LogoButton = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '60px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '$10',
  transition: 'border-color 0.15s linear, background-color 0.15s linear',
  variants: {
    light: {
      on: {
        borderColor: '$primary50',
      },
      off: {
        borderColor: '$transparent',
        '&:hover': {
          borderColor: '$secondary3',
          backgroundColor: '$secondary3',
        },
        '&:active': {
          borderColor: '$secondary10',
          backgroundColor: '$secondary10',
        },
      },
    },
  },
});

export default LogoButton;
