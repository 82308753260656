import { useEffect, useState } from 'react';
import {
  useGetApplicationByIdNames,
  useDeleteApplicationById,
  useGetApplicationGoogleDocumentById,
  usePostCopyApplicationById,
  useGetApplicationSidebarItemsById,
  usePostApplicationGoogleDocumentById,
  usePutCancelApplicationById,
} from '../../../../services/application/official-standard/application.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import {
  currentApplicationDocumentAtom,
  curAppDetailPanelAtom,
  curAppDetailTabAtom,
  isAppDetailPanelsOpenAtom,
} from '../../../../store/atoms';
import { AppDetailTabs } from '../../../../types';
import { appDetailTabs } from '../../../../store/application/constants';
import {
  AppDetailTabsRoot,
  AppDetailTabsList,
  AppDetailTabsTrigger,
  AppDetailTabsContent,
} from '../../../../components/surface/app-detail-tabs';
import Box from '../../../../components/box';
import Spacer from '../../../../components/surface/spacer';
import Spinner from '../../../../components/helper/spinner';
import HeaderBar from '../../../../components/bar/header-bar';
import {
  AppDetailPanelsContent,
  AppDetailPanelsRoot,
  AppDetailPanelsToggler,
  AppDetailPanelsTrigger,
  AppDetailPanelsList,
  AppDetailPanelsTogglerArrow,
} from '../../../../components/surface/app-detail-panels';
import Divider from '../../../../components/surface/divider';
import MSymbol from '../../../../components/icon/m-symbol';
import DocIcon from '../../../../components/icon/doc-icon';
import Approval from './approval';
import Submission from './submission';
import Document from './document';
import SidebarInfo from './sidebar-info';
import Attachment from './attachment';
import Comment from './comment';
import Activities from './activities';
import SidebarApproval from './sidebar-approval';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import Typography from '../../../../components/typography/deprecated-typography';
import { Text } from '../../../../components/typography/text';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import EmptyApplicationProcessedBG from '../../../../assets/images/bg-empty-application-processed.svg';

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const [isAppDetailPanelsOpen, setIsAppDetailPanelsOpen] = useAtom(isAppDetailPanelsOpenAtom);
  const [curAppDetailTab, setCurAppDetailTab] = useAtom(curAppDetailTabAtom);
  const [curAppDetailPanel, setCurAppDetailPanel] = useAtom(curAppDetailPanelAtom);
  const [currentDocument, setCurrentDocument] = useAtom(currentApplicationDocumentAtom);
  const [isKeepLabels, setIsKeepLabels] = useState(false);

  const applicationByIdNamesQueries = useGetApplicationByIdNames({
    application_id: applicationId ?? '',
  });
  const applicationSidebarItemsByIdQueries = useGetApplicationSidebarItemsById({
    application_id: applicationId ?? '',
  });
  const applicationGoogleDocumentByIdQueries = useGetApplicationGoogleDocumentById({
    application_id: applicationId ?? '',
  });

  const copyApplicationByIdMutation = usePostCopyApplicationById({
    application_id: applicationId ?? '',
    onMutationSuccess: () => {
      navigate('..');
    },
  });
  const deleteApplicationByIdMutation = useDeleteApplicationById({
    application_id: applicationId ?? '',
    onMutationSuccess: () => {
      navigate('..');
    },
  });
  const generateGoogleDocumentsByIdMutation = usePostApplicationGoogleDocumentById({
    application_id: applicationId ?? '',
    onMutationSuccess: () => {
      setIsAppDetailPanelsOpen(false);
      setCurAppDetailTab('document');
    },
  });
  const cancelApplicationMutation = usePutCancelApplicationById({
    onMutationSuccess: () => {
      navigate('..');
    },
  });

  useEffect(() => {
    if (!applicationId) {
      navigate('..');
    }
  }, [applicationId, navigate]);

  useEffect(() => {
    if (
      applicationGoogleDocumentByIdQueries.isSuccess &&
      applicationGoogleDocumentByIdQueries.data.length > 0
    ) {
      setCurrentDocument({
        activeDocument: applicationGoogleDocumentByIdQueries.data[0].id,
        activeDocumentUrl: applicationGoogleDocumentByIdQueries.data[0].google_doc_url,
        activeDocumentStatus: applicationGoogleDocumentByIdQueries.data[0].google_doc_status,
      });
    }
  }, [
    applicationGoogleDocumentByIdQueries.data,
    applicationGoogleDocumentByIdQueries.isSuccess,
    setCurrentDocument,
  ]);

  useEffect(() => {
    if (applicationSidebarItemsByIdQueries.isSuccess) {
      if (
        applicationSidebarItemsByIdQueries.data.default_sidebar_item_value === 'info' &&
        curAppDetailPanel === 'approval'
      ) {
        setCurAppDetailPanel(applicationSidebarItemsByIdQueries.data.default_sidebar_item_value);
      }
    }
  }, [
    applicationSidebarItemsByIdQueries.data,
    applicationSidebarItemsByIdQueries.isSuccess,
    curAppDetailPanel,
    setCurAppDetailPanel,
  ]);

  return (
    <>
      {/* Header */}
      <HeaderBar
        applicationActionsMap={applicationByIdNamesQueries.data?.application_action_map}
        headerTitle={`${applicationByIdNamesQueries.data?.application_name ?? ''}`}
        isLoading={applicationByIdNamesQueries.isLoading}
        onCancelClick={() => {
          cancelApplicationMutation.mutate({
            application_id: applicationId ?? '',
          });
        }}
        isCancelLoading={cancelApplicationMutation.isLoading}
        onEditClick={() => {
          navigate(`../edit-application/${applicationId}`);
        }}
        onDeleteClick={() => {
          deleteApplicationByIdMutation.mutate();
        }}
        isDeleteLoading={deleteApplicationByIdMutation.isLoading}
        onCopyClick={() => {
          copyApplicationByIdMutation.mutate({ is_keep_labels: isKeepLabels });
        }}
        isKeepLabels={isKeepLabels}
        setIsKeepLabels={setIsKeepLabels}
        isCopyLoading={copyApplicationByIdMutation.isLoading}
        onGenerateDocumentsClick={() => {
          generateGoogleDocumentsByIdMutation.mutate();
        }}
        isGenerateDocumentsLoading={generateGoogleDocumentsByIdMutation.isLoading}
      />
      {/* Left Side */}
      <Box
        css={{
          width: '50px',
          height: '100vh',
          backgroundColor: '$white',
          position: 'fixed',
          zIndex: 85,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: '$5',
        }}>
        <Spacer axis={'vertical'} css={{ $$size: '57px' }} />
        {/* Google Doc Bar */}
        <Box css={{ display: 'flex', flexDirection: 'column', rowGap: '$5' }}>
          {applicationGoogleDocumentByIdQueries.isLoading ? (
            <Spinner />
          ) : (
            <>
              {applicationGoogleDocumentByIdQueries.isSuccess &&
              applicationGoogleDocumentByIdQueries.data.length > 0 ? (
                <>
                  {applicationGoogleDocumentByIdQueries.data.map((googleDocument) => (
                    <Box
                      key={googleDocument.id}
                      onClick={() => {
                        if (
                          applicationByIdNamesQueries.data?.application_approval.status !== 'draft'
                        ) {
                          setCurrentDocument({
                            activeDocument: googleDocument.id,
                            activeDocumentUrl: googleDocument.google_doc_url,
                            activeDocumentStatus: googleDocument.google_doc_status,
                          });
                          setCurAppDetailTab('document');
                        }
                      }}
                      css={{
                        width: 50,
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        cursor:
                          applicationByIdNamesQueries.data?.application_approval.status !== 'draft'
                            ? 'pointer'
                            : 'not-allowed',
                        '&:before': {
                          content: '""',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          bottom: 0,
                          width: 2,
                          backgroundColor:
                            currentDocument.activeDocument === googleDocument.id &&
                            googleDocument.is_google_doc_generated
                              ? '$primary50'
                              : '$transparent',
                        },
                      }}>
                      <TooltipRoot>
                        <TooltipTrigger asChild>
                          <Box css={{ width: 36, height: 36, flexShrink: 0 }}>
                            <DocIcon
                              docType={googleDocument.icon_map.doc_type}
                              docIconBGColor={googleDocument.icon_map.doc_icon_bg}
                              docIconStatus={googleDocument.icon_map.doc_icon_status}
                            />
                          </Box>
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent
                            sideOffset={15}
                            side={`right`}
                            css={{
                              px: 16,
                              py: 4.5,
                              display: googleDocument.google_doc_name ? 'revert' : 'none',
                            }}>
                            <Typography
                              variant={'body'}
                              css={{
                                color: '$white',
                              }}>{`${googleDocument.google_doc_name}`}</Typography>
                            <TooltipArrow />
                          </TooltipContent>
                        </TooltipPortal>
                      </TooltipRoot>
                    </Box>
                  ))}
                </>
              ) : null}
            </>
          )}
        </Box>
      </Box>
      {/* Main */}
      <Box
        css={{
          display: 'flex',
          mt: '57px',
          ml: '50px',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
          minHeight: 'calc(100vh - 60px - 30px - 57px)',
        }}>
        {/* Content Container */}
        <Box
          css={{
            width: '100%',
            flexGrow: 1,
            flexShrink: 1,
            display: 'flex',
            flexDirection: 'column',
            pl: '$4',
            pr: '66px',
          }}>
          {/* Content */}
          <Spacer axis={'vertical'} css={{ $$size: '18px' }} />
          <AppDetailTabsRoot
            value={curAppDetailTab}
            css={{
              flexGrow: 1,
              backgroundColor: '$white',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <AppDetailTabsList aria-label="application-details-tabs">
              {appDetailTabs
                .filter((appDetailTab) => appDetailTab.value !== 'seal')
                .map((tab) => (
                  <AppDetailTabsTrigger
                    // disabled={
                    //   applicationByIdNamesQueries.data?.application_approval.status === 'draft' &&
                    //   tab.value !== 'submission'
                    //     ? true
                    //     : false
                    // }
                    key={tab.id}
                    value={tab.value as AppDetailTabs}
                    // data-active={curAppDetailTab === tab.value ? 'active' : 'inactive'}
                    onClick={() => setCurAppDetailTab(tab.value as AppDetailTabs)}>
                    <Box
                      css={{
                        display: 'flex',
                        height: 25,
                        alignItems: 'center',
                        columnGap: '5px',
                      }}>
                      <MSymbol
                        iconName={`${tab.other_info?.iconName}`}
                        css={{ color: 'inherit', fontSize: '16px !important' }}
                      />
                      {`${tab.name}`}
                    </Box>
                  </AppDetailTabsTrigger>
                ))}
            </AppDetailTabsList>
            {applicationByIdNamesQueries.isLoading ? (
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                }}>
                <Spinner size="large" />
              </Box>
            ) : (
              <>
                {applicationByIdNamesQueries.isSuccess &&
                  appDetailTabs
                    .filter((appDetailTab) => appDetailTab.value !== 'seal')
                    .map((tab) => (
                      <AppDetailTabsContent
                        key={tab.id}
                        value={tab.value as string}
                        css={{
                          flexGrow: 1,
                          position: 'relative',
                          height:
                            tab.name === 'Document' &&
                            applicationGoogleDocumentByIdQueries.data &&
                            applicationGoogleDocumentByIdQueries.data[0]?.google_doc_status ===
                              'generated'
                              ? 1920
                              : '',
                        }}>
                        {(() => {
                          switch (true) {
                            case tab.name === 'Approval' &&
                              applicationByIdNamesQueries.data.application_approval.status !==
                                'draft':
                              return (
                                <Approval
                                  data={applicationByIdNamesQueries.data.application_approval}
                                />
                              );
                            case tab.name === 'Contract Request':
                              return (
                                <Submission
                                  data={applicationByIdNamesQueries.data.application_submission}
                                />
                              );
                            case tab.name === 'Document' &&
                              applicationByIdNamesQueries.data.application_approval.status !==
                                'draft':
                              return (
                                <Document
                                  documentUrl={currentDocument.activeDocumentUrl}
                                  documentStatus={currentDocument.activeDocumentStatus}
                                />
                              );
                            default:
                              return (
                                <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
                                  <Box as="img" src={EmptyApplicationProcessedBG} />
                                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                                  <Text semibold css={{ textAlign: 'center' }}>
                                    {`The application is still in draft status.`}
                                  </Text>
                                </CenteredBoxAbsolute>
                              );
                          }
                        })()}
                      </AppDetailTabsContent>
                    ))}
              </>
            )}
          </AppDetailTabsRoot>
          <Spacer axis={'vertical'} css={{ $$size: '18px' }} />
        </Box>
        {/* Right Side */}
        <AppDetailPanelsRoot open={isAppDetailPanelsOpen}>
          {/* Right Side left */}
          <AppDetailPanelsList
            open={isAppDetailPanelsOpen}
            onOpenChange={(open) => setIsAppDetailPanelsOpen(open)}>
            <AppDetailPanelsToggler
              onClick={() => {
                setIsAppDetailPanelsOpen(!isAppDetailPanelsOpen);
              }}>
              <AppDetailPanelsTogglerArrow iconName="navigate_next" open={isAppDetailPanelsOpen} />
            </AppDetailPanelsToggler>
            <Box>
              {applicationSidebarItemsByIdQueries.isLoading ? (
                <>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  <Spinner />
                </>
              ) : (
                <>
                  {applicationSidebarItemsByIdQueries.isSuccess ? (
                    <>
                      {applicationSidebarItemsByIdQueries.data.upper.map((sidebarItem) => (
                        <TooltipRoot key={sidebarItem.id}>
                          <TooltipTrigger asChild>
                            <div>
                              <AppDetailPanelsTrigger
                                triggerIconName={sidebarItem.icon_name}
                                light={curAppDetailPanel === sidebarItem.value ? 'on' : 'off'}
                                onClick={() => setCurAppDetailPanel(sidebarItem.value)}
                              />
                            </div>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent
                              sideOffset={5}
                              side={`left`}
                              css={{
                                px: 16,
                                py: 4.5,
                              }}>
                              <Typography variant={'body'} css={{ color: '$white' }}>{`${
                                sidebarItem.name ?? '-'
                              }`}</Typography>
                              <TooltipArrow />
                            </TooltipContent>
                          </TooltipPortal>
                        </TooltipRoot>
                      ))}
                      <Divider
                        axis={'horizontal'}
                        css={{ $$size: '30px', backgroundColor: '$secondary40', m: '10px auto' }}
                      />
                      {applicationSidebarItemsByIdQueries.data.lower.map((sidebarItem) => (
                        <TooltipRoot key={sidebarItem.id}>
                          <TooltipTrigger asChild>
                            <div>
                              <AppDetailPanelsTrigger
                                key={sidebarItem.id}
                                triggerIconName={sidebarItem.icon_name}
                                light={curAppDetailPanel === sidebarItem.value ? 'on' : 'off'}
                                onClick={() => setCurAppDetailPanel(sidebarItem.value)}
                              />
                            </div>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent
                              sideOffset={5}
                              side={`left`}
                              css={{
                                px: 16,
                                py: 4.5,
                                backgroundColor: '$secondary100',
                                opacity: 0.8,
                              }}>
                              <Typography variant={'body'} css={{ color: '$white' }}>{`${
                                sidebarItem.name ?? '-'
                              }`}</Typography>
                              <TooltipArrow />
                            </TooltipContent>
                          </TooltipPortal>
                        </TooltipRoot>
                      ))}
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </AppDetailPanelsList>
          {/* Right Side right */}
          <AppDetailPanelsContent>
            {(() => {
              switch (curAppDetailPanel) {
                case 'info':
                  return <SidebarInfo applicationId={applicationId} />;
                case 'activity':
                  return <Activities applicationId={applicationId} />;
                case 'approval':
                  return <SidebarApproval applicationId={applicationId} />;
                case 'attachment':
                  return <Attachment applicationId={applicationId} />;
                case 'comment':
                  return <Comment applicationId={applicationId} />;
                default:
                  return null;
              }
            })()}
          </AppDetailPanelsContent>
        </AppDetailPanelsRoot>
      </Box>
    </>
  );
};

export default ApplicationDetails;
