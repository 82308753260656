import type { MutableRefObject } from 'react';
import { useState, useEffect, useRef } from 'react';

const useEffectInEvent = (event: 'resize' | 'scroll', set: () => void, useCapture?: boolean) => {
  useEffect(() => {
    set();
    window.addEventListener(event, set, useCapture);
    return () => {
      window.removeEventListener(event, set, useCapture);
    };
  }, []);
};

const useRect = <T extends Element | HTMLButtonElement | HTMLUListElement>(): [
  DOMRect | undefined,
  MutableRefObject<T | null>,
  () => void
] => {
  const ref = useRef<T>(null);
  const [rect, setRect] = useState<DOMRect>();

  const set = () => setRect(ref.current?.getBoundingClientRect());

  useEffectInEvent('resize', set);
  useEffectInEvent('scroll', set, true);

  return [rect, ref, set];
};

export default useRect;
