import type { LabelColor } from '../types/constants';
import { colorKit } from '../styles/colors';

export const getColorKit = (options: { variant: 'solid' | 'outlined'; color: LabelColor }) => {
  const baseColor = colorKit.dark[options.color];
  switch (options.variant) {
    case 'solid':
      return {
        borderColor: baseColor,
        backgroundColor: baseColor,
      };
    case 'outlined':
      return {
        color: baseColor,
        borderColor: colorKit.main[options.color],
        backgroundColor: colorKit.light[options.color],
      };
    default:
      return {};
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'all':
      return '$text-primary';
    case 'draft':
      return '$text-primary';
    case 'pending':
      return '$warning60';
    case 'processing':
      return '$primary50';
    case 'rejected':
      return '$error60';
    case 'approved':
      return '$success70';
    case 'urgent_approved':
      return '$error60';
    case 'general_approved':
      return '$primary50';
    case 'seal_approved':
      return '$success70';
    case 'sealing':
      return '$purple60';
    case 'signed':
      return '$success70';
    case 'completed':
      return '$achromatic30';
    default:
      return '$achromatic10';
  }
};

export const getSpinnerColor = (spinnerColor?: string) => {
  switch (spinnerColor) {
    case 'error':
      return '$error60';
    case 'warning':
      return '$warning60';
    case 'success':
      return '$success70';
    case 'primary':
      return '$primary50';
    default:
      return '$white';
  }
};
