import type {
  GetCustomerResult,
  GetCustomerPayload,
  PostCustomerPayload,
  CustomerEntity,
  // CustomersFilterMenu,
  CustomerAbbreviationsItem,
} from '../types/customers';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAxios } from '../hooks';
import { IdNameValueItem } from '../types';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../store/atoms';
import { AxiosError } from 'axios';

// export const useGetCustomersFilterMenu = (userId: string) => {
//   const axios = useAxios();
//   return useQuery<CustomersFilterMenu[], Error>(['customer-filter'], async () => {
//     const { data } = await axios.get(`/v1/customer-filter`, {
//       params: { user_id: userId },
//     });
//     return data;
//   });
// };

export const useGetCustomer = ({
  limit,
  offset,
  source,
  subsidiary,
  search,
  order_by,
  sort_by,
  start_date,
  end_date,
}: GetCustomerPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  return useQuery<GetCustomerResult, AxiosError>(
    [
      'customer',
      limit,
      offset,
      source,
      subsidiary,
      search,
      order_by,
      sort_by,
      start_date,
      end_date,
    ],
    async () => {
      const { data } = await axios.get(`/v1/customer`, {
        params: {
          limit,
          offset,
          source,
          subsidiary,
          search,
          order_by,
          sort_by,
          start_date,
          end_date,
        },
      });
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
    }
  );
};

export const useGetCustomerAbbreviations = () => {
  const axios = useAxios();
  return useQuery<CustomerAbbreviationsItem[], Error>(['customer-abbreviations'], async () => {
    const { data } = await axios.get(`/v1/customer-abbreviation`);
    return data;
  });
};

export const useGetCustomerById = (customerId: string) => {
  const axios = useAxios();
  return useQuery<CustomerEntity, Error>(['customer-by-id', customerId], async () => {
    const { data } = await axios.get(`/v1/customer/${customerId}`);
    return data;
  });
};

export const usePostCustomer = ({ onMutationSuccess }: { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async (requestBody: PostCustomerPayload) => {
      try {
        const { data } = await axios.post(`/v1/customer`, requestBody);
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['customer-abbreviations']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const usePutCustomerById = ({
  onMutationSuccess,
}: { onMutationSuccess?: () => void } = {}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async ({
      customerId,
      requestBody,
    }: {
      customerId: string;
      requestBody: PostCustomerPayload;
    }) => {
      try {
        const { data } = await axios.put(`/v1/customer/${customerId}`, requestBody);
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['customer-by-id']);
        queryClient.invalidateQueries(['customer-abbreviations']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};

export const useDeleteCustomerById = ({
  onMutationSuccess,
}: {
  onMutationSuccess?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation(
    async (customerId: string) => {
      const { data } = await axios.delete(`/v1/customer/${customerId}`);
      return data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['customer']);
        queryClient.invalidateQueries(['customer-by-id']);
        queryClient.invalidateQueries(['customer-abbreviations']);
        if (onMutationSuccess) {
          onMutationSuccess();
        }
        setToastContent({
          isSuccess: true,
          success: `${res.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        setToastContent({
          isError: true,
          error: `${error.response?.data.message ?? '-'}`,
        });
        setIsToastOpen(true);
      },
    }
  );
};
