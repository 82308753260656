import Box from '../box';
import Spacer from '../surface/spacer';
import { ButtonText, Text } from './text';
import { getStatusColor } from '../../utils/get-color';
import { useMemo } from 'react';

const ApprovalStatusCaption = ({ status, textSize }: { status: string; textSize?: string }) => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        css={{
          width: '8px',
          height: '8px',
          borderRadius: '$rounded',
          backgroundColor: getStatusColor(status),
        }}
      />
      <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
      {(() => {
        switch (textSize) {
          case 'button-text-12':
            return (
              <ButtonText size="12" css={{ color: getStatusColor(status) }}>{`${
                status.charAt(0).toUpperCase() + status.slice(1)
              }`}</ButtonText>
            );
          default:
            return (
              <Text as="p" css={{ color: getStatusColor(status) }}>{`${
                status.charAt(0).toUpperCase() + status.slice(1)
              }`}</Text>
            );
        }
      })()}
    </Box>
  );
};

export default ApprovalStatusCaption;
