import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};

export const initializeReactGTM = () => {
  try {
    TagManager.initialize(tagManagerArgs);
  } catch (error) {
    console.warn('React GTM module initialization failed.', `${error}`);
  }
};

export const pushUserIdToGTM = ({ user_id }: { user_id: string }) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        user_id,
      },
    });
  } catch (error) {
    console.warn('Push user id to GTM failed', `${error}`);
  }
};
