import React, { ComponentProps, MouseEvent, useState } from 'react';
import { styled } from '../../styles/stitches.config';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { AppType, ClientType, ProductType } from '../../types/application/constants';
import { commentSummaryCardOptions } from '../../store/constants';
import { checkNDaysAgo } from '../../utils/check-n-days-ago';
import moment from 'moment';
import {
  addCommentContentAtom,
  curAppDetailPanelAtom,
  curAppDetailTabAtom,
  isAddCommentEditorOpenAtom,
  isAppDetailPanelsOpenAtom,
} from '../../store/atoms';
import { useGetMentionedComment } from '../../services/home/home.api';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
} from '../dropdown/popover';
import Skeleton from '../surface/skeleton';
import { CenteredBoxFlex } from '../surface/centered-box';
import Box from '../box';
import { Heading6 } from '../typography/heading';
import Spacer from '../surface/spacer';
import Button from '../button/button';
import { Text, ButtonText, Caption } from '../typography/text';
import DocIcon from '../icon/doc-icon';
import IconButton from '../button/icon-button';
import Avatar from '../surface/avatar';
import ColorfulChip from '../chip/colorful-chip';
import SelectOption from './select-option';
import MSymbol from '../icon/m-symbol';
import MentionedTypography from '../typography/mentioned-typography';
import AttachPDFLightOnIcon from '../../assets/images/icon-attach-pdf-light-on.svg';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';

const MentionedCommentCardRoot = styled('div', {
  borderRadius: '$5',
  border: '1px solid $secondary20',
  position: 'relative',
  transition: '$colors $opacity',
  cursor: 'pointer',
  '& [data-button=more]': {
    opacity: 0,
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '$secondary3',
    '& [data-button=more]': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  '&:active': {
    backgroundColor: '$secondary5',
  },
});
export const MentionedCommentCardContent = styled('div', { p: 10 });

interface MentionedCommentCardProps extends ComponentProps<typeof MentionedCommentCardRoot> {
  commentId: string;
}

const MentionedCommentCard = ({ commentId, ...restProps }: MentionedCommentCardProps) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const setIsAppDetailPanelsOpen = useSetAtom(isAppDetailPanelsOpenAtom);
  const setCurAppDetailTab = useSetAtom(curAppDetailTabAtom);
  const setCurrentApplicationPanel = useSetAtom(curAppDetailPanelAtom);
  const setIsAddCommentEditorOpen = useSetAtom(isAddCommentEditorOpenAtom);
  const setAddCommentContent = useSetAtom(addCommentContentAtom);

  const mentionedCommentCardQuery = useGetMentionedComment({ commentId });

  const onSuccessCommentClick = (options: {
    appType: AppType;
    product: ProductType;
    clientType: ClientType;
    appId: string;
  }) => {
    setCurAppDetailTab('approval');
    setCurrentApplicationPanel('comment');
    setIsAppDetailPanelsOpen(true);
    if (options.appType === 'official') {
      setCurAppDetailTab('approval');
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };

  const onAppNameClick = (
    e: MouseEvent,
    options: {
      appType: AppType;
      product: ProductType;
      clientType: ClientType;
      appId: string;
    }
  ) => {
    e.stopPropagation();
    setCurAppDetailTab('document');
    setIsAppDetailPanelsOpen(false);
    if (options.appType === 'official') {
      setCurAppDetailTab('approval');
      navigate(`/applications/application-details/${options.appId}`);
    } else {
      navigate(
        `/applications/detail/${options.appType}/${options.product}/${options.clientType}/${options.appId}`
      );
    }
  };

  return (
    <MentionedCommentCardRoot {...restProps}>
      {mentionedCommentCardQuery.isLoading ? (
        <Skeleton css={{ height: 150 }} />
      ) : mentionedCommentCardQuery.isSuccess ? (
        <MentionedCommentCardContent
          onClick={() =>
            onSuccessCommentClick({
              appType: mentionedCommentCardQuery.data.app_type,
              product: mentionedCommentCardQuery.data.product,
              clientType: mentionedCommentCardQuery.data.client_type,
              appId: mentionedCommentCardQuery.data.application_id,
            })
          }>
          {/* Header */}
          <Box css={{ display: 'flex', columnGap: 6, alignItems: 'center', position: 'relative' }}>
            {/* Avatar */}
            <Avatar
              name={mentionedCommentCardQuery.data.commenter_user_info.name || '-'}
              picture={mentionedCommentCardQuery.data.commenter_user_info.picture || ''}
            />
            {/* Name, Time */}
            <Box css={{ flexGrow: 1 }}>
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                <Text bold>{mentionedCommentCardQuery.data.commenter_user_info.name || '-'}</Text>
                {checkNDaysAgo({ time: mentionedCommentCardQuery.data.edited_at }) ? null : (
                  <ColorfulChip text="New" color="tangerine" variant="solid" dense />
                )}
              </Box>
              <Caption css={{ color: '$text-secondary' }}>
                {mentionedCommentCardQuery.data.edited_at
                  ? checkNDaysAgo({ time: mentionedCommentCardQuery.data.edited_at })
                    ? moment(mentionedCommentCardQuery.data.edited_at).format(
                        'MM/DD/YY HH:mm A (Z)'
                      )
                    : moment(mentionedCommentCardQuery.data.edited_at).fromNow()
                  : '-'}
              </Caption>
            </Box>
            {/* Menu */}
            <PopoverRoot open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
              <PopoverTrigger asChild>
                <IconButton
                  data-button="more"
                  iconName={'more_horiz'}
                  size={'tiny'}
                  css={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={(e) => e.stopPropagation()}
                />
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent
                  sideOffset={5}
                  align={'end'}
                  css={{ p: 10 }}
                  onClick={(e) => e.stopPropagation()}>
                  {commentSummaryCardOptions.map((option) => (
                    <PopoverClose key={option.id} asChild>
                      <SelectOption
                        value={option.value}
                        css={{ height: 40 }}
                        onClick={() => {
                          setCurrentApplicationPanel('comment');
                          setCurAppDetailTab('approval');
                          setIsAppDetailPanelsOpen(true);
                          if (option.value === 'check-comment') {
                            navigate(
                              `/applications/application-details/${mentionedCommentCardQuery.data.application_id}`
                            );
                          }
                          if (option.value === 'reply') {
                            setIsAddCommentEditorOpen(true);
                            setAddCommentContent(
                              `@[${mentionedCommentCardQuery.data.commenter_user_info.name}](${mentionedCommentCardQuery.data.commenter_user_info.id})`
                            );
                            navigate(
                              `/applications/application-details/${mentionedCommentCardQuery.data.application_id}`
                            );
                          }
                        }}>
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                          <MSymbol
                            iconName={`${option.iconName}`}
                            fill={false}
                            css={{ fontSize: '18px !important' }}
                          />
                          <Text>{`${option.name}`}</Text>
                        </Box>
                      </SelectOption>
                    </PopoverClose>
                  ))}
                </PopoverContent>
              </PopoverPortal>
            </PopoverRoot>
          </Box>
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          {/* Body */}
          {/* Mention Content */}
          <MentionedTypography message={mentionedCommentCardQuery.data.content} />
          {/* Attachments after filing (Images) */}
          {mentionedCommentCardQuery.data.images?.length > 0 ? (
            <Box
              css={{
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 6,
                columnGap: mentionedCommentCardQuery.data.images?.length > 2 ? 6 : 10,
                height:
                  mentionedCommentCardQuery.data.images?.length < 3 ||
                  mentionedCommentCardQuery.data.images?.length > 4
                    ? 125
                    : 60,
              }}>
              {mentionedCommentCardQuery.data.images.map((image) => (
                <Box
                  key={image.id}
                  css={{
                    borderRadius: '$10',
                    overflow: 'hidden',
                    flexBasis:
                      mentionedCommentCardQuery.data.images?.length > 2
                        ? `calc(25% - ${18 / 4}px)`
                        : mentionedCommentCardQuery.data.images?.length === 2
                        ? `calc(50% - 5px)`
                        : `calc(100%)`,
                    border: '1px solid $secondary10',
                    height:
                      mentionedCommentCardQuery.data.images?.length > 4
                        ? 'calc(50% - 3px)'
                        : '100%',
                  }}>
                  <a href={`${image.url || ''}`} target="_blank" rel="noreferrer">
                    <Box
                      as="img"
                      src={image.url}
                      css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    />
                  </a>
                </Box>
              ))}
            </Box>
          ) : null}
          {/* Attachments after filing (PDFs) */}
          {mentionedCommentCardQuery.data.pdfs?.length > 0 ? (
            <Box
              css={{
                mt: 10,
                display: 'flex',
                flexDirection: 'column',
                rowGap: 6,
              }}>
              {mentionedCommentCardQuery.data.pdfs.map((pdf) => (
                <a key={pdf.id} href={`${pdf.url || ''}`} target="_blank" rel="noreferrer">
                  <Box
                    css={{
                      borderRadius: '$5',
                      px: 5,
                      py: 6,
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 6,
                      justifyContent: 'space-between',
                      backgroundColor: '$secondary3',
                      transition: '$colors',
                      '&:hover': { backgroundColor: '$primary10' },
                      '&:active': { backgroundColor: '$primary20' },
                    }}>
                    <Box as="img" src={AttachPDFLightOnIcon} />
                    <Box css={{ flexGrow: 1 }}>
                      <Text
                        semibold
                        css={{
                          display: '-webkit-box',
                          '-webkit-line-clamp': 1,
                          '-webkit-box-orient': 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {pdf.file_name || '-'}
                      </Text>
                      <Caption
                        css={{
                          color: '$achromatic40',
                          display: '-webkit-box',
                          '-webkit-line-clamp': 1,
                          '-webkit-box-orient': 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {pdf.updated_at
                          ? moment(pdf.updated_at).format('MM/DD/YY HH:mm A (Z)')
                          : '-'}
                      </Caption>
                    </Box>
                  </Box>
                </a>
              ))}
            </Box>
          ) : null}
          {/* Application Name */}
          <Box
            onClick={(e) =>
              onAppNameClick(e, {
                appType: mentionedCommentCardQuery.data.app_type,
                appId: mentionedCommentCardQuery.data.application_id,
                product: mentionedCommentCardQuery.data.product,
                clientType: mentionedCommentCardQuery.data.client_type,
              })
            }
            css={{
              mt: 10,
              display: 'flex',
              alignItems: 'center',
              p: 4,
              columnGap: 4,
              borderRadius: '$5',
              backgroundColor: '$secondary3',
              transition: '$colors',
              '&:hover': { backgroundColor: '$primary10' },
              '&:active': { backgroundColor: '$primary20' },
            }}>
            <Box
              css={{
                width: 18,
                height: 18,
                flexShrink: 0,
              }}>
              <DocIcon
                docType={mentionedCommentCardQuery.data.icon_map.doc_type}
                docIconBGColor={mentionedCommentCardQuery.data.icon_map.doc_icon_bg}
                docIconStatus={mentionedCommentCardQuery.data.icon_map.doc_icon_status}
              />
            </Box>
            <Caption semibold>{`${
              mentionedCommentCardQuery.data.application_name || '-'
            }`}</Caption>
          </Box>
        </MentionedCommentCardContent>
      ) : mentionedCommentCardQuery.isError ? (
        <CenteredBoxFlex css={{ p: 10 }} onClick={(e) => e.stopPropagation()}>
          <Box as="img" src={ErrorMonoBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
            <Heading6>{`Something's gone wrong`}</Heading6>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Text semibold>
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Text>
          </Box>
          <Button
            size="sm"
            isLoading={mentionedCommentCardQuery.isLoading}
            disabled={mentionedCommentCardQuery.isLoading}
            onClick={() => {
              mentionedCommentCardQuery.refetch();
            }}>
            <ButtonText size="14">Refresh</ButtonText>
          </Button>
        </CenteredBoxFlex>
      ) : null}
    </MentionedCommentCardRoot>
  );
};

export default MentionedCommentCard;
