import { styled } from '../../styles/stitches.config';

const ActionBar = styled('div', {
  px: 20,
  py: 10,
  backgroundColor: '$white',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid $func-border-main',
  position: 'fixed',
  zIndex: 90,
  height: 57,
});

export default ActionBar;
