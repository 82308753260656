import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../../../store/atoms';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types/constants';
import { GetAppActionBarResponse } from '../../../types/application/verified/app-actions';
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../../types/application/constants';

export const useGetAppActionBar = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppActionBarResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'action-bar'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/action_bar`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};

export const usePostApprovalRequest = (options: {
  appId: string;
  product: ProductType | undefined;
  onSettled?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'verified', options.appId, options.product, 'approval-request'],
    mutationFn: async () => {
      const { data } = await axios.post<CommonAPIResponse>(
        `/v1/verified_app/${options.appId}/${options.product}_contract_request`
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'list']);
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      setIsToastOpen(true);
    },
  });
};

export const usePostSealRequest = (options: {
  appId: string;
  product: ProductType | undefined;
  onSettled?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);

  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'verified', options.appId, options.product, 'seal-request'],
    mutationFn: async () => {
      const { data } = await axios.post<CommonAPIResponse>(
        `/v1/verified_app/${options.appId}/${options.product}_seal_request`
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'list']);
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      setIsToastOpen(true);
    },
  });
};

export const usePatchWithdraw = (options: { appId: string; onSettled?: () => void }) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);

  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'verified', options.appId, 'withdraw'],
    mutationFn: async () => {
      const { data } = await axios.patch<CommonAPIResponse>(
        `/v1/verified_app/${options.appId}/withdraw`
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'list']);
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      navigate('/applications');
      setIsToastOpen(true);
    },
  });
};

export const usePostCopy = (options: { appId: string; onSettled?: () => void }) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);

  return useMutation<CommonAPIResponse, AxiosError<CommonAPIResponse>>({
    mutationKey: ['app', 'verified', options.appId, 'copy'],
    mutationFn: async () => {
      const { data } = await axios.post<CommonAPIResponse>(
        `/v1/verified_app/${options.appId}/copy`
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'list']);
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      navigate('/applications');
      setIsToastOpen(true);
    },
  });
};
