import { globalCss } from './stitches.config';

const globalStyles = globalCss({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },
  '*': {
    margin: 0,
  },
  html: {
    overflow: 'hidden',
  },
  'html, body, #root': {
    height: '100%',
  },
  body: {
    overflow: 'auto',
    scrollBehavior: 'smooth',
    lineHeight: 1.5,
    fontFamily: "'Noto Sans TC', sans-serif",
    '-webkit-font-smoothing': 'antialiased',
    backgroundColor: '$solitude',
  },
  a: {
    textDecoration: 'none',
    transition: '$colors',
    color: '$achromatic70',
    '&:hover': {
      color: '$primary40',
    },
    '&:active': {
      color: '$primary60',
    },
  },
  'img, picture, video, canvas, svg': {
    display: 'block',
    maxWidth: '100%',
  },
  svg: {
    margin: '0 auto',
  },
  'input, button, textarea, select': {
    font: 'inherit',
  },
  ul: {
    paddingInlineStart: 0,
    listStyle: 'none',
  },
  'p, h1, h2, h3, h4, h5, h6': {
    overflowWrap: 'break-word',
  },
  '#root': {
    isolation: 'isolate',
    display: 'flex',
    flexDirection: 'column',
  },
  '::-webkit-scrollbar': {
    width: 4,
    borderRadius: 20,
  },
  '::-webkit-scrollbar-track': {
    background: '$white',
    borderRadius: 20,
  },
  '::-webkit-scrollbar-thumb': {
    background: '$achromatic20',
    borderRadius: 20,
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '$achromatic30',
  },
});

export default globalStyles;
