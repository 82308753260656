import moment from 'moment';
import { AppDetailGWSSubmissionTab } from '../../../../types/application/simplified/app-detail-tabs';
import { ProductType, AppDetailAttachmentEntity } from '../../../../types/application/constants';
import { useGetAppDetailSubmissionTab } from '../../../../services/application/simplified-standard/app-detail-tabs.api';
import {
  getGWSOfficialPricingDiscountWording,
  getPUPPWording,
} from '../../../../utils/get-wording';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import { Caption, Text } from '../../../../components/typography/text';
import Spacer from '../../../../components/surface/spacer';
import Disclosure from '../../../../components/surface/disclosure';
import {
  SubmissionColumn,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Avatar from '../../../../components/surface/avatar';
import LogoGoogleWorkspace from '../../../../assets/images/logo-google-workspace.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import FileCard from '../../../../components/card/file-card';

export interface AppDetailSubmissionTabProps {
  product: ProductType;
  appId: string;
}

const SubmissionTab = ({ product, appId }: AppDetailSubmissionTabProps) => {
  const appDetailSubmissionTabQuery = useGetAppDetailSubmissionTab({ product, appId });
  return (
    <>
      {appDetailSubmissionTabQuery.isLoading ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Spinner size="large" />
        </CenteredBoxAbsolute>
      ) : appDetailSubmissionTabQuery.isSuccess ? (
        <Box
          css={{
            px: 30,
            pb: 30,
            margin: '0 auto',
            mt: 30,
            minWidth: '580px',
            maxWidth: '760px',
            backgroundColor: '$white',
          }}>
          <Disclosure
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    {/* General Info */}
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>CloudMile Details</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoGoogleWorkspace} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.cm_legal_entity.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={appDetailSubmissionTabQuery.data.cm_attention_to.name || '-'}
                              picture={
                                appDetailSubmissionTabQuery.data.cm_attention_to_picture || ''
                              }
                              size="sm"
                            />
                            <Text
                              css={{
                                mt: '$1',
                              }}>{`${
                              appDetailSubmissionTabQuery.data.cm_attention_to.name || '-'
                            }`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.cm_attention_to_email || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.cm_country_calling_code.name || ''
                          } ${appDetailSubmissionTabQuery.data.cm_phone_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(appDetailSubmissionTabQuery.data.quotation_validity).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement Term Subscription Term */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Subscription Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.agreement_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Automatically Renew Term */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Automatically Renew Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.automatically_renew_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.currency.name || '-'} / ${
                            appDetailSubmissionTabQuery.data.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Agreement Commencement Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Agreement Commencement Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(
                              appDetailSubmissionTabQuery.data.agreement_commencement_date
                            ).format('DD MMM YYYY') || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Agreement End Date */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Agreement End Date`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(appDetailSubmissionTabQuery.data.agreement_end_date).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Customer Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.client.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.client_country?.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Tax Reg Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.tax_reg_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.client_address || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.client_attention_to || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.client_email || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.client_country_calling_code.name || ''
                          } ${appDetailSubmissionTabQuery.data.client_phone_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Workspace Edition */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Workspace Edition`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            `${
                              (appDetailSubmissionTabQuery.data as AppDetailGWSSubmissionTab)
                                .workspace_edition.name
                            }` || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Workspace Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Workspace Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            `${
                              (appDetailSubmissionTabQuery.data as AppDetailGWSSubmissionTab)
                                .workspace_plan.name
                            }` || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            `${appDetailSubmissionTabQuery.data.payment_term.name}` || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.support_plan.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {getGWSOfficialPricingDiscountWording({
                              type: appDetailSubmissionTabQuery.data.official_pricing_discount_type,
                              value:
                                appDetailSubmissionTabQuery.data.official_pricing_discount_value ||
                                0,
                              currency:
                                appDetailSubmissionTabQuery.data.official_pricing_discount_currency,
                            })}
                          </Text>
                        </SubmissionColumn>
                        {/* Estimated MRR */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Estimated MRR`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {appDetailSubmissionTabQuery.data.estimated_mrr
                              ? `${`${appDetailSubmissionTabQuery.data.estimated_mrr.toLocaleString()} USD`}`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* PUPM / PUPY */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`PUPM / PUPY`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {getPUPPWording({
                              pupp:
                                (appDetailSubmissionTabQuery.data as AppDetailGWSSubmissionTab)
                                  .per_user_per_period || 0,
                              workspacePlan: (
                                appDetailSubmissionTabQuery.data as AppDetailGWSSubmissionTab
                              ).workspace_plan.value,
                              currency:
                                appDetailSubmissionTabQuery.data.official_pricing_discount_currency
                                  .name,
                            })}
                          </Text>
                        </SubmissionColumn>
                        {/* Is Auto Renewal ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Is Auto Renewal ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            (appDetailSubmissionTabQuery.data as AppDetailGWSSubmissionTab)
                              .is_auto_renewal || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Will Customer issue PO, LOA, Order Form, other ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Will Customer issue PO, LOA, Order Form, other ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailSubmissionTabQuery.data.is_issued_form || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Reason For Discount Rate */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason For Discount Rate`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailSubmissionTabQuery.data.reason_for_discount_rate || 'No reason'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-attachment',
                name: 'Attach files',
                content: (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                    {appDetailSubmissionTabQuery.data.file_list &&
                    appDetailSubmissionTabQuery.data.file_list.length > 0 ? (
                      <>
                        {appDetailSubmissionTabQuery.data.file_list.map(
                          (file: AppDetailAttachmentEntity) => (
                            <FileCard
                              key={file.id}
                              fileName={file.filename}
                              fileSize={file.size}
                              previewSignedUrl={file.preview_signed_url}
                              signedUrl={file.signed_url}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <Text>No files uploaded by applicant</Text>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      ) : appDetailSubmissionTabQuery.isError ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxAbsolute>
      ) : null}
    </>
  );
};

export default SubmissionTab;
