import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import {
  addCommentContentAtom,
  applicationsPaginationOffsetAtom,
  currentPathnameAtom,
  customersPaginationOffsetAtom,
  isAddCommentEditorOpenAtom,
} from '../store/atoms';
import {
  curSubFormStepAtom,
  postGCPStdAppPayloadAtom,
  postGWSStdAppPayloadAtom,
  postPLPSStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../store/application/atoms';

const Location = () => {
  const { pathname } = useLocation();
  const setCurrentPathname = useSetAtom(currentPathnameAtom);

  const setApplicationsPaginationOffset = useSetAtom(applicationsPaginationOffsetAtom);

  const setCustomersPaginationOffset = useSetAtom(customersPaginationOffsetAtom);
  const setIsAddCommentEditorOpen = useSetAtom(isAddCommentEditorOpenAtom);
  const setAddCommentContents = useSetAtom(addCommentContentAtom);

  const resetCurSubFormStep = useResetAtom(curSubFormStepAtom);
  const resetPostGCPStdAppPayload = useResetAtom(postGCPStdAppPayloadAtom);
  const resetPostPLPSStdAppPayload = useResetAtom(postPLPSStdAppPayloadAtom);
  const resetPostGWSStdAppPayload = useResetAtom(postGWSStdAppPayloadAtom);
  const resetPostStdAppAttPayloadAtom = useResetAtom(postStdAppAttPayloadAtom);

  useEffect(() => {
    if (!pathname.startsWith('/customers')) {
      setCustomersPaginationOffset(0);
    }
    if (!pathname.startsWith('/applications')) {
      setApplicationsPaginationOffset(0);
    }
    if (!pathname.startsWith('/applications/application-details')) {
      setIsAddCommentEditorOpen(false);
      setAddCommentContents('');
    }
    if (!pathname.startsWith('/applications/edit')) {
      resetCurSubFormStep();
      resetPostGCPStdAppPayload();
      resetPostPLPSStdAppPayload();
      resetPostGWSStdAppPayload();
      resetPostStdAppAttPayloadAtom();
    }
    setCurrentPathname(pathname);
  }, [
    pathname,
    resetCurSubFormStep,
    resetPostGCPStdAppPayload,
    resetPostPLPSStdAppPayload,
    resetPostGWSStdAppPayload,
    resetPostStdAppAttPayloadAtom,
    setAddCommentContents,
    setApplicationsPaginationOffset,
    setCurrentPathname,
    setCustomersPaginationOffset,
    setIsAddCommentEditorOpen,
  ]);

  return null;
};

export default Location;
