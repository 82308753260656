import { styled } from '../../styles/stitches.config';
import * as RadixSelect from '@radix-ui/react-select';

export const SimpleSelectRoot = styled(RadixSelect.Root, {});
export const SimpleSelectTrigger = styled(RadixSelect.Trigger, {
  // all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  overflow: 'hidden',
  height: 30,
  pl: 8,
  width: '100%',
  color: '$secondary70',
  fontSize: '$14',
  fontWeight: '$bold',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$secondary20',
  borderRadius: '$5',
  backgroundColor: '$white',
  cursor: 'pointer',
  transition: '$colors',
  outline: 'none',
  '&:hover': {
    borderColor: '$primary40',
    color: '$primary40',
    backgroundColor: '$primary10',
  },
  '&[data-state=open]': {
    backgroundColor: '$primary20',
    borderColor: '$primary60',
    color: '$primary60',
  },
});
// * SimpleSelectValue will render the chosen SimpleSelectItemText
export const SimpleSelectValue = styled(RadixSelect.Value, {});
export const SimpleSelectIcon = styled(RadixSelect.Icon, {});
export const SimpleSelectPortal = styled(RadixSelect.Portal, {});
export const SimpleSelectContent = styled(RadixSelect.Content, {
  p: 10,
  boxShadow: '$basic',
  borderRadius: '$5',
  backgroundColor: '$white',
  width: 'var(--radix-select-trigger-width)',
  // maxHeight: 'var(--radix-select-content-available-height)',
});
export const SimpleSelectViewport = styled(RadixSelect.Viewport, {});
export const SimpleSelectGroup = styled(RadixSelect.Group, {});
export const SimpleSelectLabel = styled(RadixSelect.Label, {});
export const SimpleSelectItem = styled(RadixSelect.Item, {
  outline: 'none',
  px: 10,
  transition: '$colors',
  cursor: 'pointer',
  borderRadius: '$5',
  minHeight: 40,
  fontSize: '$14',
  display: 'flex',
  alignItems: 'center',
  '&[data-state=checked]': {
    backgroundColor: '$primary20',
    '&:hover': {
      backgroundColor: '$primary30',
    },
    '&:active': {
      backgroundColor: '$primary40',
    },
  },
  '&[data-state=unchecked]': {
    backgroundColor: '$white',
    '&:hover': {
      backgroundColor: '$secondary3',
    },
    '&:active': {
      backgroundColor: '$secondary10',
    },
  },
});
export const SimpleSelectItemText = styled(RadixSelect.ItemText, {});
export const SimpleSelectItemIndicator = styled(RadixSelect.ItemIndicator, {});
export const SimpleSelectSeparator = styled(RadixSelect.Separator, {});
export const SimpleSelectScrollUpButton = styled(RadixSelect.ScrollUpButton, {});
export const SimpleSelectScrollDownButton = styled(RadixSelect.ScrollDownButton, {});
export const SimpleSelectArrow = styled(RadixSelect.Arrow, {});
