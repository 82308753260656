import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import {
  activeStepAtom,
  submissionStepsAtom,
  submissionFormSelectedProductAtom,
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormSelectedSubsidiaryIdAtom,
  isNavBarOpenAtom,
  navBarWidthAtom,
  submissionFormValidationAtom,
} from '../../../../../store/atoms';
import {
  usePostValidateSubmissionForm,
  usePostApplication,
  useGetApplicationByIdValues,
  usePutApplicationById,
} from '../../../../../services/application/official-standard/application.api';
import { checkValidationPass } from '../../../../../utils/check-validation';
import ServiceItems from './service-items';
import CloudmileInfo from './cloudmile-info';
import { motion } from 'framer-motion';
import Box from '../../../../../components/box';
import Typography from '../../../../../components/typography/deprecated-typography';
import Stepper from '../../../../../components/helper/deprecated-stepper';
import Button from '../../../../../components/button/deprecated-button';
import Spacer from '../../../../../components/surface/spacer';
import { fixedLayoutGrowMotion } from '../../../../../styles/motions';
import CustomerVenderInfo from './customer-info';
import Confirmation from './confirmation';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/helper/dialog';
import Spinner from '../../../../../components/helper/spinner';
import MSymbol from '../../../../../components/icon/m-symbol';
import IconButton from '../../../../../components/button/icon-button';

const CreateApplication = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [getParams, setParam] = useSearchParams();

  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  // * Standard
  const [activeStep, setActiveStep] = useAtom(activeStepAtom);
  const [submissionSteps, setSubmissionSteps] = useAtom(submissionStepsAtom);
  const [submissionFormSelectedProduct, setSubmissionFormSelectedProduct] = useAtom(
    submissionFormSelectedProductAtom
  );
  const submissionFormSelectedSubsidiaryId = useAtomValue(submissionFormSelectedSubsidiaryIdAtom);
  const resetActiveStep = useResetAtom(activeStepAtom);
  const resetSubmissionSteps = useResetAtom(submissionStepsAtom);
  const resetSubmissionSelectedProduct = useResetAtom(submissionFormSelectedProductAtom);
  const resetSubmissionFormSelectedSubsidiaryId = useResetAtom(
    submissionFormSelectedSubsidiaryIdAtom
  );
  const resetSubmissionFormCloudmile = useResetAtom(submissionFormCloudmileAtom);
  const resetSubmissionFormCustomer = useResetAtom(submissionFormCustomerAtom);
  const resetSubmissionFormValidation = useResetAtom(submissionFormValidationAtom);
  const applicationByIdValuesQueries = useGetApplicationByIdValues({
    application_id: applicationId ?? '',
    onGetApplicationByIdError: () => {
      navigate('..');
    },
  });
  const cloudmileInfoValidationMutation = usePostValidateSubmissionForm(
    {
      validate_type: 'validate_cloudmile_info',
      product_type: submissionFormSelectedProduct ?? '',
      subsidiary_id: submissionFormSelectedSubsidiaryId,
    },
    (res) => {
      const isPass = checkValidationPass(res);
      if (isPass && activeStep >= 1 && activeStep <= submissionSteps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      document.querySelector('#root-scroll-area-viewport')?.scrollTo(0, 0);
    }
  );
  const customerInfoValidationMutation = usePostValidateSubmissionForm(
    {
      validate_type: 'validate_customer_info',
      product_type: submissionFormSelectedProduct ?? '',
      subsidiary_id: submissionFormSelectedSubsidiaryId,
    },
    (res) => {
      const isPass = checkValidationPass(res);
      if (isPass && activeStep >= 1 && activeStep <= submissionSteps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      document.querySelector('#root-scroll-area-viewport')?.scrollTo(0, 0);
    }
  );
  const submitApplicationMutation = usePostApplication({
    onPostApplicationSuccess: () => {
      resetActiveStep();
      resetSubmissionSelectedProduct();
      resetSubmissionFormCloudmile();
      resetSubmissionFormCustomer();
      resetSubmissionFormSelectedSubsidiaryId();
      resetSubmissionFormValidation();
      navigate('..');
    },
  });
  const editApplicationMutation = usePutApplicationById({
    onMutationSuccess: () => {
      resetActiveStep();
      resetSubmissionSelectedProduct();
      resetSubmissionFormCloudmile();
      resetSubmissionFormCustomer();
      resetSubmissionFormSelectedSubsidiaryId();
      resetSubmissionFormValidation();
      navigate('..');
    },
  });

  useEffect(() => {
    if (getParams.get('productType')) {
      setActiveStep(2);
      setSubmissionFormSelectedProduct(getParams.get('productType') as 'gcp' | 'gws');
    }
    if (applicationId) {
      applicationByIdValuesQueries.refetch();
    }
    return () => {
      resetActiveStep();
      resetSubmissionSelectedProduct();
      resetSubmissionFormCloudmile();
      resetSubmissionFormCustomer();
      resetSubmissionFormSelectedSubsidiaryId();
      resetSubmissionFormValidation();
    };
  }, [
    resetActiveStep,
    resetSubmissionSelectedProduct,
    resetSubmissionFormCloudmile,
    resetSubmissionFormCustomer,
    resetSubmissionFormSelectedSubsidiaryId,
    resetSubmissionFormValidation,
    applicationId,
  ]);

  useEffect(() => {
    if (submissionFormSelectedProduct === 'gws') {
      setSubmissionSteps((prev) => [
        ...prev.slice(0, 2),
        { ...prev[2], name: 'Customer & Vender Info' },
        ...prev.slice(2 + 1),
      ]);
    } else {
      resetSubmissionSteps();
    }
  }, [submissionFormSelectedProduct, resetSubmissionSteps, setSubmissionSteps]);

  return (
    <Box css={{ px: '20px', py: '15px' }}>
      {applicationByIdValuesQueries.isLoading ? (
        <Box css={{ mt: 300 }}>
          <Spinner size="medium" />
        </Box>
      ) : (
        <>
          <Box css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography variant="h4">{`${
              applicationId ? 'Edit' : 'Create'
            } Agreement & Quo.`}</Typography>
          </Box>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          <Stepper activeStep={activeStep} steps={submissionSteps} />
          <Spacer axis="vertical" css={{ $$size: '40px' }} />
          <>
            {/* Step 1 */}
            {activeStep === 1 ? <ServiceItems /> : null}
            {/* Step 2 */}
            {activeStep === 2 ? <CloudmileInfo /> : null}
            {/* Step 3 */}
            {activeStep === 3 ? <CustomerVenderInfo /> : null}
            {/* Step 4 */}
            {activeStep === 4 ? <Confirmation /> : null}
          </>
          {/* 下面 Fixed 的那條，有 Cancel, Back, Next 按鈕 */}
          <Spacer axis="vertical" css={{ $$size: '80px' }} />
          <Box
            as={motion.div}
            animate={isNavBarOpen ? 'shrink' : 'grow'}
            variants={fixedLayoutGrowMotion(navBarWidth)}
            css={{
              px: '30px',
              py: '12px',
              position: 'fixed',
              bottom: '0',
              left: navBarWidth,
              right: '0',
              display: 'flex',
              alignItems: 'center',
              height: '50px',
              backgroundColor: '$white',
              boxShadow: '$basic',
            }}>
            <Box css={{ mr: 'auto' }}>
              {/* Cancel */}
              <DialogRoot
                open={isCancelDialogOpen}
                onOpenChange={(open) => setIsCancelDialogOpen(open)}>
                <DialogTrigger asChild>
                  <Button variant="borderless" color="secondary">{`Cancel`}</Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                      {/* Delete Title, Close Button */}
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                        <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                        <DialogTitle asChild>
                          <Typography
                            as={'h5'}
                            variant={'h5'}
                            css={{ flexGrow: 1 }}>{`Leave site`}</Typography>
                        </DialogTitle>
                        <DialogClose asChild>
                          <IconButton iconName="close" size="small" isIconBold={true} />
                        </DialogClose>
                      </Box>
                      {/* Description */}
                      <DialogDescription css={{ pl: 40 }}>
                        <Typography
                          variant={'body'}
                          css={{
                            color: '$secondary70',
                          }}>{`Are you sure you want to leave this page?`}</Typography>
                      </DialogDescription>
                      {/* Action */}
                      <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                        <DialogClose asChild>
                          <Button
                            variant={'borderless'}
                            color={'secondary'}
                            onClick={() => {
                              resetActiveStep();
                              resetSubmissionSelectedProduct();
                              resetSubmissionFormCloudmile();
                              resetSubmissionFormCustomer();
                              resetSubmissionFormSelectedSubsidiaryId();
                              resetSubmissionFormValidation();
                              if (applicationId) {
                                navigate(`/applications/application-details/${applicationId}`);
                              } else {
                                navigate('..');
                              }
                            }}>{`Leave`}</Button>
                        </DialogClose>
                        {activeStep >= 2 && activeStep <= submissionSteps.length ? (
                          <DialogClose asChild>
                            <Button
                              variant={'outlined'}
                              color={'primary'}
                              isLoading={submitApplicationMutation.isLoading}
                              onClick={() => {
                                if (applicationId) {
                                  editApplicationMutation.mutate({
                                    application_id: applicationId,
                                    filing_type: 'save',
                                    status: applicationByIdValuesQueries.data?.status,
                                  });
                                } else {
                                  submitApplicationMutation.mutate({
                                    is_draft: true,
                                    filing_type: 'submit',
                                  });
                                }
                              }}>{`Save ${applicationId ? 'and Leave' : 'as Draft'}`}</Button>
                          </DialogClose>
                        ) : null}
                        <DialogClose asChild>
                          <Button variant={'solid'} color={'primary'}>{`Stay on this page`}</Button>
                        </DialogClose>
                      </Box>
                    </Box>
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
            </Box>
            {/* Back */}
            <Button
              variant="outlined"
              disabled={
                !(activeStep >= 3 && activeStep <= submissionSteps.length) ||
                (activeStep <= 2 && Boolean(applicationId))
              }
              onClick={() => {
                document.querySelector('#root-scroll-area-viewport')?.scrollTo(0, 0);
                // * 是否回到上一步邏輯
                if (activeStep >= 3 && activeStep <= submissionSteps.length) {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }
              }}>{`Back`}</Button>
            <Spacer axis="horizontal" css={{ $$size: '20px' }} />
            {/* Next */}
            <Button
              isLoading={
                cloudmileInfoValidationMutation.isLoading ||
                customerInfoValidationMutation.isLoading ||
                submitApplicationMutation.isLoading ||
                editApplicationMutation.isLoading
              }
              disabled={
                (activeStep === 1 && !submissionFormSelectedProduct) ||
                submitApplicationMutation.isLoading ||
                editApplicationMutation.isLoading
              }
              onClick={() => {
                if (!submissionFormSelectedProduct) {
                  return;
                }
                // Step 1 按下去時會發生的事
                if (activeStep === 1) {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                // Step 2 按下去時會發生的事
                if (activeStep === 2) {
                  cloudmileInfoValidationMutation.mutate();
                }
                // Step 3 按下去時會發生的事
                if (activeStep === 3) {
                  customerInfoValidationMutation.mutate();
                }
                // Step 4 按下去時會發生的事
                if (activeStep === 4) {
                  if (applicationId) {
                    editApplicationMutation.mutate({
                      application_id: applicationId,
                      is_draft: false,
                      filing_type: 'submit',
                      status: applicationByIdValuesQueries.data?.application_submission.status,
                    });
                  } else {
                    submitApplicationMutation.mutate({ is_draft: false, filing_type: 'submit' });
                  }
                }
              }}>
              {`${activeStep >= 1 && activeStep <= submissionSteps.length - 1 ? 'Next' : 'Submit'}`}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateApplication;
