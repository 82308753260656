export const taxRegNumberFieldLabel = (countryCode: string) => {
  switch (countryCode) {
    case 'MY':
      return 'SST No.';
    case 'PH':
      return 'SEC No.';
    case 'ID':
      return 'NPWP No.';
    default:
      return 'Tax Reg Number (統一編號)';
  }
};

export const isTaxRegNumberFieldRequired = (countryCode: string) => {
  switch (countryCode) {
    case 'TW':
      return true;
    default:
      return false;
  }
};

export const isTaxRegNumberFieldOptional = (countryCode: string) => {
  switch (countryCode) {
    case 'MY':
      return true;
    case 'PH':
      return true;
    case 'ID':
      return true;
    default:
      return false;
  }
};
