import type { GetCustomerResult } from '../../types/customers';
import { FormEvent, useEffect } from 'react';
import type { UseQueryResult } from 'react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import {
  isNavBarOpenAtom,
  getCustomerParamsAtom,
  customersPaginationLimitAtom,
  customersPaginationOffsetAtom,
  customersSearchTermAtom,
} from '../../store/atoms';
import { useGetCustomer } from '../../services/customer.api';
import moment from 'moment';
import Typography from '../../components/typography/deprecated-typography';
import Box from '../../components/box';
import Button from '../../components/button/deprecated-button';
import FormControl from '../../components/field/deprecated-form-control';
import Field from '../../components/field/deprecated-field';
import Spacer from '../../components/surface/spacer';
import Spinner from '../../components/helper/spinner';
import Pagination from '../../components/surface/pagination';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFoot,
  TableHeader,
} from '../../components/surface/table';
import NoResultBG from '../../assets/images/bg-no-result.svg';
import ErrorBG from '../../assets/images/bg-error.svg';
import EmptyCustomerBG from '../../assets/images/bg-empty-customer.svg';
import MSymbol from '../../components/icon/m-symbol';
import { motion } from 'framer-motion';
import { fadeInOutMotion } from '../../styles/motions';
import {
  SimpleSelectContent,
  SimpleSelectIcon,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../components/dropdown/simple-select';
import { customerSortByOptions } from '../../store/constants';
import { SortByValue } from '../../types';
import { usePostSyncCustomer } from '../../services/oracle-sync.api';

const CustomerPage = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useAtom(customersSearchTermAtom);

  const [getCustomerParams, setGetCustomersParams] = useAtom(getCustomerParamsAtom);
  const [paginationLimit, setPaginationLimit] = useAtom(customersPaginationLimitAtom);
  const [paginationOffset, setPaginationOffset] = useAtom(customersPaginationOffsetAtom);

  const syncCustomerMutation = usePostSyncCustomer();
  const customerQueries = useGetCustomer(getCustomerParams) as UseQueryResult<GetCustomerResult>;

  // * 當 NavBar 展開時，Column 只顯示 ['Company Name', 'Tags', 'Tax ID No.', 'Subsidiary', 'Last modified']，
  // * 當 NavBar 收合時，Column 顯示所有欄位 (+ ['Currency', 'Source'])
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);

  // * 換頁時要做的事情
  useEffect(() => {
    setGetCustomersParams((prev) => ({ ...prev, offset: paginationOffset.toString() }));
  }, [paginationOffset]);

  // * 搜尋時要做的事
  const handleOnSearch = (e: FormEvent<HTMLFormElement>) => {
    setGetCustomersParams((prev) => ({ ...prev, search: searchTerm, offset: '0' }));
    setPaginationOffset(0);
  };

  return (
    <Box css={{ px: '20px', py: '15px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <MSymbol iconName="groups" fill={false} />
        <Spacer axis="horizontal" css={{ $$size: '10px' }} />
        <Typography variant="h4">{`Customers`}</Typography>
        <Spacer axis="horizontal" css={{ $$size: '20px' }} />
        <Button
          onClick={() => {
            navigate('./create-company');
          }}
          startElement={
            <MSymbol
              iconName="add"
              weight={700}
              css={{ color: 'inherit', fontSize: '16px !important' }}
            />
          }
          size="small">{`Create Organization`}</Button>
        <Box css={{ display: 'flex', ml: 'auto', alignItems: 'center', columnGap: 10 }}>
          <Button
            variant="borderless"
            isLoading={syncCustomerMutation.isLoading}
            disabled={syncCustomerMutation.isLoading}
            spinnerColor="primary"
            startElement={
              <MSymbol
                iconName="sync"
                weight={700}
                css={{ color: 'inherit', fontSize: '14px !important' }}
              />
            }
            size="small"
            onClick={() => {
              syncCustomerMutation.mutate();
            }}>{`Sync`}</Button>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '28px' }} />
      {/* Control Bar */}
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          {/* Search */}
          <FormControl
            width="250px"
            onSubmit={(e: FormEvent<HTMLFormElement>) => handleOnSearch(e)}>
            <Field
              value={searchTerm}
              onChange={(newValue) => setSearchTerm(newValue)}
              placeholder="Search"
              endElement={
                <>
                  {searchTerm && searchTerm !== '' ? (
                    <Box
                      onClick={() => {
                        setSearchTerm('');
                        setGetCustomersParams((prev) => ({ ...prev, search: '' }));
                      }}
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '$white',
                        width: '16px',
                        height: '16px',
                        borderStyle: 'none',
                        cursor: 'pointer',
                        flexShrink: 0,
                        transition: 'background-color 0.15s linear',
                        backgroundColor: '$secondary20',
                        borderRadius: '$rounded',
                        mr: '$2',
                        '&:hover': { backgroundColor: '$secondary10' },
                        '&:active': { backgroundColor: '$secondary30' },
                      }}>
                      <MSymbol
                        iconName="close"
                        weight={700}
                        css={{ color: 'inherit', fontSize: '12px !important' }}
                      />
                    </Box>
                  ) : null}
                  <Box
                    css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() =>
                      setGetCustomersParams({ ...getCustomerParams, search: searchTerm })
                    }>
                    <MSymbol
                      iconName="search"
                      size={20}
                      weight={700}
                      css={{ color: '$secondary30' }}
                    />
                  </Box>
                </>
              }
            />
          </FormControl>
          {/* Filter */}
        </Box>
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          {/* Sort */}
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: 205 }}>
            <Typography
              variant="body"
              css={{ whiteSpace: 'nowrap', color: '$secondary70' }}>{`Sort by`}</Typography>
            <SimpleSelectRoot
              value={getCustomerParams.sort_by}
              onValueChange={(newValue) =>
                setGetCustomersParams((prev) => ({ ...prev, sort_by: newValue as SortByValue }))
              }>
              <SimpleSelectTrigger>
                <Box css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <SimpleSelectValue placeholder="Modified Date" />
                </Box>
                <SimpleSelectIcon asChild>
                  <Box css={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <MSymbol iconName="arrow_drop_down" css={{ color: 'inherit' }} />
                  </Box>
                </SimpleSelectIcon>
              </SimpleSelectTrigger>
              <SimpleSelectPortal>
                <SimpleSelectContent position="popper" sideOffset={4}>
                  <SimpleSelectViewport>
                    {customerSortByOptions.map((sortByOption) => (
                      <SimpleSelectItem key={sortByOption.id} value={sortByOption.value}>
                        <SimpleSelectItemText>{`${sortByOption.name}`}</SimpleSelectItemText>
                      </SimpleSelectItem>
                    ))}
                  </SimpleSelectViewport>
                </SimpleSelectContent>
              </SimpleSelectPortal>
            </SimpleSelectRoot>
          </Box>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '17px' }} />
      {customerQueries.isError ? (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Typography variant="h6">{`Something's gone wrong`}</Typography>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Typography variant="body-medium">
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Typography>
          </Box>
          <Button
            variant="solid"
            size={'small'}
            onClick={() => customerQueries.refetch()}>{`Refresh`}</Button>
        </Box>
      ) : null}
      {customerQueries.isLoading ? (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          <Spinner size="large" />
        </Box>
      ) : null}
      {customerQueries.isSuccess ? (
        customerQueries.data.customers.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader grow={0} basis="40px"></TableHeader>
                  <TableHeader basis={`calc((100% - 40px) * 0.3)`}>
                    <Typography variant="body-bold">{`Company Name`}</Typography>
                    <Box
                      as={motion.div}
                      initial={getCustomerParams.sort_by === 'company_name' ? 'show' : 'hide'}
                      variants={fadeInOutMotion}
                      animate={getCustomerParams.sort_by === 'company_name' ? 'show' : 'hide'}
                      css={{ position: 'relative', width: 24, height: 24 }}
                      onClick={() => {
                        setGetCustomersParams((prev) => ({
                          ...prev,
                          order_by: prev.order_by === 'desc' ? 'asc' : 'desc',
                        }));
                      }}>
                      <MSymbol
                        iconName={
                          getCustomerParams.order_by === 'desc' ? 'arrow_downward' : 'arrow_upward'
                        }
                        css={{
                          ml: 4,
                          cursor: 'pointer',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Box>
                  </TableHeader>
                  <TableHeader basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}>
                    <Typography variant="body-bold">{`Tax ID No.`}</Typography>
                  </TableHeader>
                  {isNavBarOpen ? null : (
                    <TableHeader
                      basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}>
                      <Typography variant="body-bold">{`Currency`}</Typography>
                    </TableHeader>
                  )}
                  <TableHeader basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}>
                    <Typography variant="body-bold">{`Subsidiary`}</Typography>
                  </TableHeader>
                  {/* <TableHeader basis="15%">
                    <Typography variant="body-bold">{`Labels`}</Typography>
                  </TableHeader> */}
                  <TableHeader basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}>
                    <Typography variant="body-bold">{`Last Modified`}</Typography>
                    <Box
                      as={motion.div}
                      initial={getCustomerParams.sort_by === 'last_updated_time' ? 'show' : 'hide'}
                      variants={fadeInOutMotion}
                      animate={getCustomerParams.sort_by === 'last_updated_time' ? 'show' : 'hide'}
                      css={{ position: 'relative', width: 24, height: 24 }}
                      onClick={() => {
                        setGetCustomersParams((prev) => ({
                          ...prev,
                          order_by: prev.order_by === 'desc' ? 'asc' : 'desc',
                        }));
                      }}>
                      <MSymbol
                        iconName={
                          getCustomerParams.order_by === 'desc' ? 'arrow_downward' : 'arrow_upward'
                        }
                        css={{
                          ml: 4,
                          cursor: 'pointer',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Box>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerQueries.data.customers.map(
                  (
                    {
                      id,
                      company_name,
                      customer_abbreviation,
                      tax_reg_number,
                      currency,
                      // country,
                      subsidiary,
                      source,
                      // labels,
                      last_updated_time,
                      customer_id,
                    },
                    index
                  ) => (
                    <TableRow key={id} needHover={true} needPointer={true}>
                      <TableCell grow={0} basis="40px"></TableCell>
                      <TableCell
                        basis={`calc((100% - 40px) * 0.3)`}
                        onClick={() => {
                          navigate(`./customer-details/${customer_id}`);
                        }}>
                        <Typography variant="body">{`${company_name}`}</Typography>
                        <Typography
                          variant="caption"
                          css={{
                            color: '$secondary70',
                          }}>{`${customer_abbreviation}`}</Typography>
                      </TableCell>
                      <TableCell
                        basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}
                        onClick={() => {
                          navigate(`./customer-details/${customer_id}`);
                        }}>
                        <Typography variant="body">{`${
                          tax_reg_number ? tax_reg_number : '-'
                        }`}</Typography>
                      </TableCell>
                      {isNavBarOpen ? null : (
                        <TableCell
                          basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}
                          onClick={() => {
                            navigate(`./customer-details/${customer_id}`);
                          }}>
                          <Typography variant="body">{`${currency || '-'}`}</Typography>
                        </TableCell>
                      )}
                      <TableCell
                        basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}
                        onClick={() => {
                          navigate(`./customer-details/${customer_id}`);
                        }}>
                        <Typography variant="body">{`${subsidiary || '-'}`}</Typography>
                      </TableCell>
                      <TableCell
                        basis={`calc((100% - 40px) * ${isNavBarOpen ? 0.7 / 3 : 0.7 / 4})`}
                        onClick={() => {
                          navigate(`./customer-details/${customer_id}`);
                        }}>
                        <Typography variant="body">{`${moment(last_updated_time).format(
                          'MM/DD/YY HH:mm A (Z)'
                        )}`}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
              <TableFoot>
                <TableRow>
                  <TableCell>
                    <Pagination
                      pageOffset={paginationOffset}
                      pageLimit={paginationLimit}
                      setPageOffset={({ setter }) => setPaginationOffset(setter)}
                      pageTotal={customerQueries.data.customer_total}
                    />
                  </TableCell>
                </TableRow>
              </TableFoot>
            </Table>
          </TableContainer>
        ) : getCustomerParams.search ? (
          <Box
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flexGrow: 1,
              rowGap: '$5',
            }}>
            <Box as="img" src={NoResultBG} />
            <Typography variant="body-medium">{`No Matching Results`}</Typography>
          </Box>
        ) : (
          <Box
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flexGrow: 1,
              rowGap: '$5',
            }}>
            <Box as="img" src={EmptyCustomerBG} />
            <Typography variant="body-medium">{`No customers manually created`}</Typography>
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default CustomerPage;
