import { useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import { useGetMonthAppOptions } from '../../../../../../services/application/official-standard/app-options.api';
import {
  useGetCurrencyOracleOptions,
  useGetExchangeRateOracleOptions,
} from '../../../../../../services/application/official-standard/oracle-options.api';
import moment from 'moment';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { onlyPositiveIntegerForField } from '../../../../../../utils/strict-for-field';
import Label from '../../../../../../components/typography/label';
import Box from '../../../../../../components/box';
import Typography from '../../../../../../components/typography/deprecated-typography';
import ComboField from '../../../../../../components/dropdown/combo-field';
import Spacer from '../../../../../../components/surface/spacer';
import Checkbox from '../../../../../../components/field/checkbox';
import '../../../../../../styles/custom-react-multi-date-picker.css';
import SubmissionUrgentBox from '../../../../../../components/card/submission-urgent-box';
import ComboSelect from '../../../../../../components/dropdown/combo-select';

const GwsCloudmilePayment = () => {
  const termAppOptionsQueries = useGetMonthAppOptions('term', 'cloudmile-term');
  const autoRenewTermAppOptionsQueries = useGetMonthAppOptions(
    'auto_renew_term',
    'cloudmile-auto-renew-term'
  );

  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  const currencyOracleOptionsQueries = useGetCurrencyOracleOptions(
    'gws',
    submissionFormCloudmile.gws.cm_legal_entity
  );
  const exChangeRateOracleOptionsQueries = useGetExchangeRateOracleOptions(
    'gws',
    submissionFormCloudmile.gws.cm_legal_entity,
    submissionFormCloudmile.gws.currency
  );

  useEffect(() => {
    if (submissionFormCloudmile.gws.currency) {
      exChangeRateOracleOptionsQueries.refetch();
    }
  }, [submissionFormCloudmile.gws.currency]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Validity */}
          <Label>
            <Typography variant="caption">
              {`Quotation Validity`}
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            </Typography>
          </Label>
          <DatePicker
            // placeholder=''
            value={submissionFormCloudmile.gws.validity ?? ''}
            format="DD MMM YYYY"
            minDate={new Date()}
            onChange={(newDate) => {
              if (newDate instanceof DateObject) {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gws: {
                    ...prev.gws,
                    validity: moment(newDate.toDate()).format('DD MMM YYYY'),
                  },
                }));
              }
            }}
            containerClassName="cm-calendar-container"
            inputClass="cm-calendar-input"
            className="cm-calendar"
            arrowClassName="cm-calendar-arrow"
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Automatically Renew Terms */}
          <Box css={{ flexBasis: 'calc(50% - 10px)', mr: 'auto' }}>
            <ComboField
              mode="normal"
              label="Automatically Renew Terms"
              required={true}
              selectPlaceholder="Select a renew terms"
              selectValue={(submissionFormCloudmile.gws.automatically_renew_terms as string) ?? ''}
              fieldValue={
                (submissionFormCloudmile.gws.automatically_renew_terms_other as string) ?? undefined
              }
              fieldPlaceholder=""
              fieldEndElement={
                <Typography
                  variant="body"
                  css={{ display: 'block', color: '$secondary70' }}>{`months`}</Typography>
              }
              onSelectChange={(newValue) => {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gws: {
                    ...prev.gws,
                    automatically_renew_terms: newValue,
                    automatically_renew_terms_other: undefined,
                  },
                }));
                setSubmissionFormValidation((prev: any) => ({
                  ...prev,
                  automatically_renew_terms_other: undefined,
                }));
              }}
              onFieldChange={(newValue) =>
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gws: {
                    ...prev.gws,
                    automatically_renew_terms_other: onlyPositiveIntegerForField(newValue),
                  },
                }))
              }
              selectOptions={autoRenewTermAppOptionsQueries.data ?? []}
              isSelectOptionsLoading={autoRenewTermAppOptionsQueries.isLoading}
              isSelectError={submissionFormValidation.automatically_renew_terms?.is_error ?? false}
              selectError={submissionFormValidation.automatically_renew_terms?.error ?? undefined}
              isFieldError={
                submissionFormValidation.automatically_renew_terms_other?.is_error ?? false
              }
              fieldError={
                submissionFormValidation.automatically_renew_terms_other?.error ?? undefined
              }
            />
          </Box>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Currency & Exchange Rate */}
        <Box css={{ width: 'calc(50% - 10px)' }}>
          <ComboSelect
            label="Currency & Exchange Rate"
            required={true}
            selectLeftValue={submissionFormCloudmile.gws.currency ?? ''}
            onSelectLeftValueChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, currency: newValue, exchange_rate: '' },
              }));
            }}
            selectLeftOptions={currencyOracleOptionsQueries.data ?? []}
            selectLeftPlaceholder="Select a currency"
            isSelectLeftOptionsLoading={currencyOracleOptionsQueries.isLoading}
            isSelectLeftError={submissionFormValidation.currency?.is_error ?? false}
            selectLeftError={submissionFormValidation.currency?.error ?? undefined}
            selectRightValue={submissionFormCloudmile.gws.exchange_rate ?? ''}
            onSelectRightValueChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, exchange_rate: newValue },
              }));
            }}
            selectRightOptions={exChangeRateOracleOptionsQueries.data ?? []}
            selectRightOptionHeight={60}
            selectRightPlaceholder="Select an exchange rate"
            isSelectRightOptionsLoading={exChangeRateOracleOptionsQueries.isLoading}
            isSelectRightError={submissionFormValidation.exchange_rate?.is_error ?? false}
            selectRightError={submissionFormValidation.exchange_rate?.error ?? undefined}
            selectRightDisabled={
              !exChangeRateOracleOptionsQueries.data ||
              exChangeRateOracleOptionsQueries.data.length <= 0
            }
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '35px' }} />
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* This is an urgent request */}
        <Box css={{ mr: '10px' }}>
          <Checkbox
            id="urgent-checkbox"
            checked={submissionFormCloudmile.gws.urgent_level as boolean}
            onChange={() => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, urgent_level: !submissionFormCloudmile.gws.urgent_level },
              }));
            }}
          />
        </Box>
        <Label htmlFor="urgent-checkbox">
          <Typography>{`This is an urgent request`}</Typography>
        </Label>
      </Box>
      {/* The local GM will review and approve the request first. */}
      <SubmissionUrgentBox urgentLevel={submissionFormCloudmile.gws.urgent_level} />
    </Box>
  );
};

export default GwsCloudmilePayment;
