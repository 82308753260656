import { useGetSampleImage } from '../../services/application/sample.api';
import Box from '../box';
import Spinner from '../helper/spinner';
import Spacer from '../surface/spacer';
import { Text } from '../typography/text';
import NoAttachesBG from '../../assets/images/bg-no-attaches.svg';
import { CenteredBoxFlex } from '../surface/centered-box';

interface AttSampleImageCardProps {
  sampleImageId: string; // * Company registration client country or attachment name
}

const AttSampleImageCard = ({ sampleImageId }: AttSampleImageCardProps) => {
  const sampleImageQueries = useGetSampleImage(sampleImageId);
  return (
    <>
      {sampleImageQueries.isLoading ? (
        <CenteredBoxFlex css={{ maxHeight: 'calc(100% - 60px)' }}>
          <Spinner />
        </CenteredBoxFlex>
      ) : null}
      {sampleImageQueries.isError ? (
        <CenteredBoxFlex css={{ maxHeight: 'calc(100% - 60px)' }}>
          <Text>{`Something went wrong`}</Text>
        </CenteredBoxFlex>
      ) : null}
      {sampleImageQueries.isSuccess ? (
        <>
          <Text>Sample as below</Text>
          <Spacer axis={'vertical'} css={{ $$size: '15px' }} />
          <CenteredBoxFlex css={{ maxHeight: 'calc(100% - 60px)' }}>
            {sampleImageQueries.data.url ? (
              <Box
                as="img"
                src={sampleImageQueries.data.url}
                css={{
                  width: '100%',
                  maxHeight: 'calc(var(--radix-popper-available-height) - 80px)',
                }}
              />
            ) : (
              <>
                <Box as="img" src={NoAttachesBG} />
                <Spacer axis="vertical" css={{ $$size: '4px' }} />
                <Text bold>This client country has no sample image</Text>
              </>
            )}
          </CenteredBoxFlex>
        </>
      ) : null}
    </>
  );
};

export default AttSampleImageCard;
