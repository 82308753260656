import { styled } from '../../styles/stitches.config';
import * as RadixPopover from '@radix-ui/react-popover';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../styles/keyframes';

export const PopoverRoot = styled(RadixPopover.Root, {});
export const PopoverTrigger = styled(RadixPopover.Trigger, {});
export const PopoverPortal = styled(RadixPopover.Portal, {});
export const PopoverAnchor = styled(RadixPopover.Anchor, {});
export const PopoverClose = styled(RadixPopover.Close, {});
export const PopoverArrow = styled(RadixPopover.Arrow, { fill: '$white' });

export const PopoverContent = styled(RadixPopover.Content, {
  outline: 'none',
  borderRadius: '$5',
  backgroundColor: '$white',
  boxShadow: '$basic',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});
