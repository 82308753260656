import { styled } from '../../styles/stitches.config';

export const ApprovalProgressCardContainer = styled('div', {
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'repeat(auto-fit, minmax(242.5px, 1fr))',
});

export const ApprovalProgressCardRoot = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 10,
  py: 4.5,
  backgroundColor: '$white',
  borderRadius: 10,
});

export const ApprovalProgressCardContent = styled('div', {});

export const ApprovalProgressCardAside = styled('div', {
  width: 40,
  height: 40,
});
