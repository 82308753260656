import type {
  OracleOptionItem,
  SubsidiaryAndCurrencyOracleOptionItem,
} from '../../../types/oracle-options';
import { IdNameValueItem, ServiceItemValue } from '../../../types';
import { useQuery } from 'react-query';
import { useAxios } from '../../../hooks';

export const useGetOracleOptions = (
  listName: 'paymentTerm' | 'msp' | 'customerCategory',
  product?: 'gcp' | 'gws'
) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(`oracle-options-${listName}`, async () => {
    const params = product
      ? {
          product,
        }
      : {};
    const { data } = await axios.get(`/v1/sync/${listName}`, { params });
    switch (listName) {
      case 'paymentTerm':
        return data.map((option: OracleOptionItem) => ({
          id: `payment-term-option-${option.id}`,
          name: option.name,
          value: option.days_till_net_due,
        }));
      default:
        return data.map((option: OracleOptionItem) => ({
          id: option.id,
          name: option.name,
          value: option.name,
        }));
    }
  });
};

export const useGetWorkspaceEditionOracleOptions = () => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(`oracle-options-workspace-edition`, async () => {
    const { data } = await axios.get(`/v1/sync/workspaceEdition`);
    return data.map((option: OracleOptionItem) => ({
      id: `workspace-edition-${option.id}`,
      name: option.name,
      value: option.name,
    }));
  });
};

export const useGetSubsidiaryOracleOptions = (serviceItem: ServiceItemValue) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`oracle-options-subsidiary`, serviceItem],
    async () => {
      const { data } = await axios.get(`/v1/sync/subsidiary`, {
        params: {
          service_item: serviceItem,
        },
      });
      return data.map((option: SubsidiaryAndCurrencyOracleOptionItem) => ({
        id: `subsidiary-option-${option.oracle_id}`,
        name: option.entity,
        value: option.entity,
      }));
    },
    { staleTime: Infinity }
  );
};

export const useGetCurrencyOracleOptions = (
  serviceItem: ServiceItemValue,
  entity: string | undefined,
  onGetCurrencySuccess?: (res: IdNameValueItem[]) => void
) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`oracle-options-currency`, serviceItem, entity],
    async () => {
      const { data } = await axios.get(`/v1/sync/subsidiary`, {
        params: {
          service_item: serviceItem,
          currency_type: 'normal_currencies',
        },
      });
      if (!entity) {
        return [];
      }
      const selectedEntity = data.find(
        (option: SubsidiaryAndCurrencyOracleOptionItem) => option.entity === entity
      );
      return (
        selectedEntity?.currency.map((option: IdNameValueItem) => ({
          id: `currency-option-${option.id}`,
          name: option.name,
          value: option.name,
        })) || []
      );
    },
    {
      staleTime: Infinity,
      enabled: false,
      onSuccess: (res) => {
        if (onGetCurrencySuccess) {
          onGetCurrencySuccess(res);
        }
      },
    }
  );
};

export const useGetCustomerToCMCurrencyOracleOptions = (
  serviceItem: ServiceItemValue,
  entity: string | undefined,
  onGetCurrencySuccess?: (res: IdNameValueItem[]) => void
) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`oracle-options-customer-to-cm-currency`, serviceItem, entity],
    async () => {
      const { data } = await axios.get(`/v1/sync/subsidiary`, {
        params: {
          service_item: serviceItem,
          currency_type: 'customer_to_cm_currencies',
        },
      });
      if (!entity) {
        return [];
      }
      const selectedEntity = data.find(
        (option: SubsidiaryAndCurrencyOracleOptionItem) => option.entity === entity
      );
      return (
        selectedEntity?.currency.map((option: IdNameValueItem) => ({
          id: `customer-to-cm-currency-option-${option.id}`,
          name: option.name,
          value: option.name,
        })) || []
      );
    },
    {
      staleTime: Infinity,
      enabled: false,
      onSuccess: (res) => {
        if (onGetCurrencySuccess) {
          onGetCurrencySuccess(res);
        }
      },
    }
  );
};

export const useGetOfficialPricingDiscountCurrencyOracleOptions = (
  serviceItem: ServiceItemValue,
  entity: string | undefined,
  exchangeRate: string | undefined,
  onGetCurrencySuccess?: (res: IdNameValueItem[]) => void
) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`oracle-options-official-pricing-discount-currency`, exchangeRate, serviceItem, entity],
    async () => {
      const { data } = await axios.get(`/v1/sync/subsidiary`, {
        params: {
          service_item: serviceItem,
          exchange_rate: exchangeRate,
          currency_type: 'official_pricing_discount_currencies',
        },
      });
      if (!entity) {
        return [];
      }
      const selectedEntity = data.find(
        (option: SubsidiaryAndCurrencyOracleOptionItem) => option.entity === entity
      );
      return (
        selectedEntity?.currency.map((option: IdNameValueItem) => ({
          id: `official-pricing-discount-currency-option-${option.id}`,
          name: option.name,
          value: option.name,
        })) || []
      );
    },
    {
      staleTime: Infinity,
      enabled: false,
      onSuccess: (res) => {
        if (onGetCurrencySuccess) {
          onGetCurrencySuccess(res);
        }
      },
    }
  );
};

export const useGetExchangeRateOracleOptions = (
  serviceItem: ServiceItemValue,
  entity: string | undefined,
  currency: string | undefined,
  onGetExchangeRateSuccess?: (res: IdNameValueItem[]) => void
) => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(
    [`oracle-options-exchange-rate`, serviceItem, entity, currency],
    async () => {
      if (serviceItem && entity && currency) {
        const { data } = await axios.get(`/v1/sync/exchangeRate`, {
          params: {
            service_item: serviceItem,
            entity,
            currency,
          },
        });
        return data.options;
      } else {
        return [];
      }
    },
    {
      staleTime: Infinity,
      enabled: false,
      onSuccess: (res) => {
        if (onGetExchangeRateSuccess) {
          onGetExchangeRateSuccess(res);
        }
      },
    }
  );
};
