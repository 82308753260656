import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { DropdownOption } from '../../../types';
import {
  CurrencyType,
  MonthField,
  PostAgreementEndDatePayload,
  PostAgreementEndDateResponse,
  PostPUPPPayload,
  PostPUPPResponse,
  ProductType,
} from '../../../types/application/constants';

export const useGetCMLegalEntityOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'cm-legal-entity'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/cm_legal_entity`);
      return data;
    },
  });
};
export const useGetCMAttentionToOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'cm-attention-to'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/cm_attention_to`);
      return data;
    },
  });
};
export const useGetCountryCallingCodeOptions = (options: { isCMOnly: boolean }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'country-calling-code', options.isCMOnly],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application_dropdown/cm_country_calling_code`,
        {
          params: {
            cloudmile_entity_only: options.isCMOnly,
          },
        }
      );
      return data;
    },
  });
};
export const useGetMonthOptions = (options: { field: MonthField }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'month', options.field],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/month`, {
        params: {
          state: options.field,
        },
      });
      return data;
    },
  });
};
export const useGetCurrencyOptions = (options: {
  currencyType: CurrencyType;
  cmLegalEntity: string;
  exchangeRate?: string;
}) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: [
      'options',
      'simp',
      'currency',
      options.currencyType,
      options.cmLegalEntity,
      options.exchangeRate,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/currency`, {
        params: {
          currency_type: options.currencyType,
          cm_legal_entity_value: options.cmLegalEntity,
          exchange_rate_value: options.exchangeRate,
        },
      });
      return data;
    },
    // enabled: false,
  });
};
export const useGetExchangeRateOptions = (options: {
  product: ProductType;
  cmLegalEntity: string;
  currency: string;
}) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: [
      'options',
      'simp',
      'exchange-rate',
      options.product,
      options.cmLegalEntity,
      options.currency,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/exchange_rate`, {
        params: {
          currency_value: options.currency,
          cm_legal_entity_value: options.cmLegalEntity,
          product: options.product,
        },
      });
      return data;
    },
    // enabled: false,
  });
};
export const useGetClientOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'client'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/client`);
      return data;
    },
  });
};
export const useGetCountryCodeOptions = (options: { isCMOnly: boolean }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'country-code'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/client_country`, {
        params: {
          cloudmile_entity_only: options.isCMOnly,
        },
      });
      return data;
    },
  });
};
export const useGetPaymentTermOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'payment-term'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/payment_term`);
      return data;
    },
  });
};
export const useGetOfficialPricingDiscountOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'official-pricing-discount'],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application_dropdown/official_pricing_discount_type`
      );
      return data;
    },
  });
};
export const useGetSupportPlanOptions = (options: { product: ProductType }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', options.product, 'support-plan'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/support_plan`, {
        params: {
          product: options.product,
        },
      });
      return data;
    },
  });
};
export const usePostAgreementEndDate = () => {
  const axios = useAxios();
  return useMutation<
    PostAgreementEndDateResponse,
    AxiosError<{ message: string }>,
    PostAgreementEndDatePayload
  >({
    mutationKey: ['options', 'simp', 'agreement-end-date'],
    mutationFn: async (requestBody: PostAgreementEndDatePayload) => {
      const { data } = await axios.post<PostAgreementEndDateResponse>(
        `v1/validation/end_date`,
        requestBody
      );
      return data;
    },
  });
};
export const useGetMentionableUserOptions = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', options.appId, 'mentionable-user'],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application/${options.appId}/mentionable_user`,
        {
          params: {
            application_id: options.appId,
          },
        }
      );
      return data;
    },
    enabled: !!options.appId,
  });
};
export const usePostPUPP = () => {
  const axios = useAxios();
  return useMutation<PostPUPPResponse, AxiosError<{ message: string }>, PostPUPPPayload>({
    mutationKey: ['options', 'simp', 'pupp'],
    mutationFn: async (requestBody: PostPUPPPayload) => {
      const { data } = await axios.post<PostPUPPResponse>(
        `v1/validation/per_user_per_period`,
        requestBody
      );
      return data;
    },
  });
};
export const useGetWorkspaceEditionOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', 'workspace-edition'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/workspace_edition`);
      return data;
    },
  });
};
export const useGetWorkspacePlanOptions = (options: { workspaceEdition: string }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'simp', options.workspaceEdition, 'workspace-plan'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application_dropdown/workspace_plan`, {
        params: {
          workspace_edition_value: options.workspaceEdition,
        },
      });
      return data;
    },
    enabled: !!options.workspaceEdition,
  });
};
