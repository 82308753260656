import { styled } from '../../styles/stitches.config';

const Divider = styled('span', {
  display: 'block',
  variants: {
    axis: {
      horizontal: {
        width: '$$size',
        minWidth: '$$size',
        height: '1px',
        minHeight: '1px',
      },
      vertical: {
        width: '1px',
        minWidth: '1px',
        height: '$$size',
        minHeight: '$$size',
      },
    },
  },
});

export default Divider;
