import ReactGA from 'react-ga4';

export const initializeReactGA4 = () => {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
  } catch (error) {
    console.warn('React GA4 initialization failed.', `${error}`);
  }
};

export const setUserIDtoGA4 = (userId: string) => {
  try {
    ReactGA.set({ userId: userId });
  } catch (error) {
    console.warn('Set User ID to GA4 failed', `${error}`);
  }
};
