import { forwardRef, useState, ReactNode } from 'react';
import { DateISO } from '../../types';
import moment from 'moment';
import { styled, css } from '../../styles/stitches.config';
import { Calendar, DateObject } from 'react-multi-date-picker';
import Box from '../box';
import Label from '../typography/label';
import { Text, Caption, ButtonText } from '../typography/text';
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
  PopoverClose,
} from './popover';
import MSymbol from '../icon/m-symbol';
import Button from '../button/button';

const PopoverTriggerIconStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$text-secondary',
  mr: 4,
});

const StyledPopoverTrigger = styled(PopoverTrigger, {
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  width: '100%',
  borderRadius: 5,
  border: 'none',
  backgroundColor: '$white',
  color: '$text-primary',
  px: 8,
  cursor: 'pointer',
  boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
  transition: '$shadow',
  '&:hover': {
    boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
  },
  '&[data-state=open]': {
    boxShadow: `0 0 0 2px var(--colors-primary60)`,
  },
  '&[data-error=true]': {
    color: '$error60',
    boxShadow: `0 0 0 1px var(--colors-error60)`,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: '$text-hint',
    boxShadow: `0 0 0 1px var(--colors-func-disabled-dark)`,
    backgroundColor: '$func-disabled-light',
  },
  variants: {
    size: {
      sm: {
        height: 30,
      },
      md: {
        height: 36,
      },
      lg: {
        height: 44,
      },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const CMRMDPStyle = css({
  ' .rmdp-wrapper': {
    border: '1px solid $primary50',
  },
  '&.rmdp-wrapper.rmdp-shadow': {
    boxShadow: 'none',
  },
  ' .rmdp-panel-body li': {
    boxShadow: 'none',
    backgroundColor: '$primary60',
  },
  ' .rmdp-week-day': {
    color: '$primary60',
  },
  ' .rmdp-day.rmdp-deactive': {
    color: '$primary50',
  },
  ' .rmdp-range': {
    backgroundColor: '$primary60',
  },
  ' .rmdp-arrow': {
    border: 'solid $primary60',
    borderWidth: '0 2px 2px 0',
  },
  ' .rmdp-arrow-container:hover': {
    backgroundColor: '$primary60',
    borderRadius: 5,
    boxShadow: 'none',
  },
  ' .rmdp-panel-body::-webkit-scrollbar-thumb': {
    background: '$primary60',
  },
  '.rmdp-day.rmdp-today span': {
    backgroundColor: '$primary40',
  },
  ' .rmdp-day span': {
    borderRadius: 5,
  },
  ' .rmdp-rtl .rmdp-panel': {
    borderLeft: 'unset',
    borderRight: '1px solid $primary50',
  },
  ' .rmdp-day.rmdp-selected span:not(.highlight)': {
    backgroundColor: '$primary60',
    boxShadow: 'none',
  },
  ' .rmdp-day:not(.rmdp-day-hidden) span:hover': {
    backgroundColor: '$primary30 !important',
  },
  ' .b-deselect': {
    color: '$primary20',
    backgroundColor: '$white',
  },
  ' .rmdp-action-button': {
    color: '$primary60',
  },
  ' .rmdp-button:not(.rmdp-action-button)': {
    backgroundColor: '$primary60',
  },
  ' .rmdp-button:not(.rmdp-action-button):hover': {
    backgroundColor: '$primary20',
  },
});

interface DayPickerProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  value: DateISO | undefined;
  onChange: (newValue: DateISO) => void;
  isError?: boolean;
  error?: string;
  disabled?: boolean;
  minDate?: Date | DateObject | string | number;
  maxDate?: Date | DateObject | string | number;
  triggerEndElement?: ReactNode;
}

const DayPicker = forwardRef<HTMLDivElement, DayPickerProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value,
      onChange,
      isError,
      error,
      disabled,
      minDate,
      maxDate,
      triggerEndElement,
      ...restProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <PopoverRoot open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <StyledPopoverTrigger name={fieldName} disabled={disabled} data-error={isError}>
            <Box data-state={isOpen ? 'open' : 'close'} className={PopoverTriggerIconStyle()}>
              <MSymbol
                iconName="event"
                css={{ color: disabled ? '$func-disabled-dark' : 'inherit' }}
              />
            </Box>
            <Box
              css={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'inherit',
                flex: 1,
              }}>
              <Text
                css={{
                  display: 'block',
                  textAlign: 'left',
                  color: value ? 'inherit' : '$text-hint',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}>
                {value ? moment(value).format('DD MMM YYYY') : placeholder}
              </Text>
            </Box>
            {triggerEndElement ?? null}
          </StyledPopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={4} align="start" css={{ pt: 10, pb: 20, px: 10 }}>
              <Calendar
                showOtherDays
                className={CMRMDPStyle()}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newDate) => {
                  if (newDate instanceof DateObject) {
                    onChange(moment(newDate.toDate()).toISOString() as DateISO);
                  }
                }}
              />
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  pr: 10,
                }}>
                <PopoverClose asChild>
                  <Button variant="outlined">
                    <ButtonText size={12}>Close</ButtonText>
                  </Button>
                </PopoverClose>
              </Box>
            </PopoverContent>
          </PopoverPortal>
        </PopoverRoot>
      </Box>
    );
  }
);

DayPicker.displayName = 'DayPicker';

export default DayPicker;
