import { styled } from '../../styles/stitches.config';

export const DocBarRoot = styled('div', {
  width: 50,
  height: '100vh',
  backgroundColor: '$white',
  position: 'fixed',
  zIndex: 85,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: '$5',
});

export const DocBarContent = styled('div', { display: 'flex', flexDirection: 'column' });
export const DocBarItemTrigger = styled('div', {
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  cursor: 'pointer',
  '&:before': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 2,
    backgroundColor: '$transparent',
  },
  '&[data-state=active]': {
    '&:before': {
      backgroundColor: '$primary50',
    },
  },
  '&[data-disabled=true]': {
    cursor: 'not-allowed',
  },
});
