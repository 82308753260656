import type { IdNameValueItem } from '../../types';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { appBarHeightAtom, navBarWidthAtom, isNavBarOpenAtom } from '../../store/atoms';
import checkPathname from '../../utils/check-pathname';
import { styled } from '../../styles/stitches.config';
import { motion } from 'framer-motion';
import Box from '../box';
import Spacer from '../surface/spacer';
import MSymbol from '../icon/m-symbol';
import Typography from '../typography/deprecated-typography';
import { TooltipPortal, TooltipRoot, TooltipTrigger, TooltipContent } from '../helper/tool-tip';
import { navBarOpenMotion, navItemExpandMotion } from '../../styles/motions';
import { navBarOpenWidth, navBarClosedWidth } from '../../store/constants';

const NAV_LINKS_SEPARATE_INDEX = 3;

const NavContainer = styled('div', {
  listStyle: 'none',
  padding: 0,
  flexGrow: 1,
  rowGap: 20,
  transition: 'margin-left 0.15s linear, margin-right 0.15s linear',
  '& > a': {
    cursor: 'unset',
    textDecoration: 'none',
  },
});

const Nav = styled('div', {
  height: '40px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  mx: 'auto',
  transition: 'background-color 0.2s linear',
  '&:active': {
    backgroundColor: '$primary90',
  },
  variants: {
    light: {
      on: { backgroundColor: '$primary50', '&:hover': { backgroundColor: '$primary60' } },
      off: { backgroundColor: '$transparent', '&:hover': { backgroundColor: '$primary90' } },
    },
  },
});

const NavBar = ({ navs }: { navs: IdNameValueItem[]; appBarHeight?: string }) => {
  const location = useLocation();
  const appBarHeight = useAtomValue(appBarHeightAtom);
  const [navBarWidth, setNavBarWidth] = useAtom(navBarWidthAtom);
  const [isNavBarOpen, setIsNavBarOpen] = useAtom(isNavBarOpenAtom);

  useEffect(() => {
    if (isNavBarOpen) {
      setNavBarWidth(`${navBarOpenWidth}`);
    } else {
      setNavBarWidth(`${navBarClosedWidth}`);
    }
  }, [isNavBarOpen, setNavBarWidth]);
  return (
    <Box
      as={motion.nav}
      initial={isNavBarOpen ? 'open' : 'closed'}
      animate={isNavBarOpen ? 'open' : 'closed'}
      variants={navBarOpenMotion(navBarWidth)}
      css={{
        width: '100%',
        maxWidth: navBarWidth,
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        zIndex: 99,
        pt: '20px',
        color: '$white',
        backgroundColor: '$primary100',
      }}>
      <Spacer axis="vertical" css={{ $$size: appBarHeight }} />
      <NavContainer as="ul" css={{ px: isNavBarOpen ? '10px' : '5px' }}>
        {navs.slice(0, NAV_LINKS_SEPARATE_INDEX).map((navItem, index) => (
          <Link key={`${navItem.id}`} to={`${navItem.value}`}>
            <TooltipRoot key={navItem.id}>
              <TooltipTrigger asChild>
                <Nav
                  as={motion.li}
                  initial={isNavBarOpen ? 'expand' : 'collapse'}
                  animate={isNavBarOpen ? 'expand' : 'collapse'}
                  variants={navItemExpandMotion}
                  light={checkPathname(location.pathname, navItem.value) ? 'on' : 'off'}
                  css={{
                    mb: navs.length + 1 === index ? 0 : 20,
                  }}>
                  <MSymbol
                    iconName={navItem.iconName ?? ''}
                    fill={false}
                    css={{
                      color: '$white',
                      mr: isNavBarOpen ? '10px' : '0px',
                      transition: 'margin-left 0.15s linear, margin-right 0.15s linear',
                    }}
                  />
                  {isNavBarOpen ? (
                    <Typography variant="button" css={{ color: '$white' }}>
                      {`${navItem.name}`}
                    </Typography>
                  ) : null}
                </Nav>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent
                  sideOffset={10}
                  side={`right`}
                  css={{
                    display: isNavBarOpen ? 'none' : 'revert',
                    px: 16,
                    py: 4.5,
                  }}>
                  <Typography
                    variant={'body'}
                    css={{ color: '$white' }}>{`${navItem.name}`}</Typography>
                </TooltipContent>
              </TooltipPortal>
            </TooltipRoot>
          </Link>
        ))}
      </NavContainer>
      <NavContainer
        as="ul"
        css={{
          px: isNavBarOpen ? '10px' : '5px',
          flexGrow: 0,
          borderBottom: '1px solid $primary80',
        }}>
        {navs.slice(NAV_LINKS_SEPARATE_INDEX).map((navItem, index) => (
          <Link key={`${navItem.id}`} to={`${navItem.value}`}>
            <TooltipRoot key={navItem.id}>
              <TooltipTrigger asChild>
                <Nav
                  as={motion.li}
                  initial={isNavBarOpen ? 'expand' : 'collapse'}
                  animate={isNavBarOpen ? 'expand' : 'collapse'}
                  variants={navItemExpandMotion}
                  light={checkPathname(location.pathname, navItem.value) ? 'on' : 'off'}
                  css={{
                    mb: navs.length + 1 === index ? 0 : 20,
                  }}>
                  <MSymbol
                    iconName={navItem.iconName ?? ''}
                    fill={false}
                    css={{
                      color: '$white',
                      mr: isNavBarOpen ? '10px' : '0px',
                      transition: 'margin-left 0.15s linear, margin-right 0.15s linear',
                    }}
                  />
                  {isNavBarOpen ? (
                    <Typography variant="button" css={{ color: '$white' }}>
                      {`${navItem.name}`}
                    </Typography>
                  ) : null}
                </Nav>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent
                  sideOffset={10}
                  side={`right`}
                  css={{
                    display: isNavBarOpen ? 'none' : 'revert',
                    px: 16,
                    py: 4.5,
                  }}>
                  <Typography
                    variant={'body'}
                    css={{ color: '$white' }}>{`${navItem.name}`}</Typography>
                </TooltipContent>
              </TooltipPortal>
            </TooltipRoot>
          </Link>
        ))}
      </NavContainer>
      <Box
        onClick={() => setIsNavBarOpen(!isNavBarOpen)}
        css={{
          flexBasis: '50px',
          flexShrink: 0,
          display: 'flex',
          ml: 'auto',
          mr: isNavBarOpen ? 0 : 'auto',
          alignItems: 'center',
        }}>
        <MSymbol
          iconName={`${isNavBarOpen ? 'chevron_left' : 'chevron_right'}`}
          fill={false}
          css={{
            mr: isNavBarOpen ? '10px' : '0px',
            color: '$white',
            cursor: 'pointer',
            transition: 'margin-left 0.15s linear, margin-right 0.15s linear',
          }}
        />
      </Box>
    </Box>
  );
};

export default NavBar;
