import { useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import {
  useGetMonthAppOptions,
  useGetOfficialPricingDiscountAppOptions,
  useGetWorkspacePlanAppOptions,
} from '../../../../../../services/application/official-standard/app-options.api';
import {
  useGetOracleOptions,
  useGetWorkspaceEditionOracleOptions,
  useGetOfficialPricingDiscountCurrencyOracleOptions,
} from '../../../../../../services/application/official-standard/oracle-options.api';
import {
  addCommaForInput,
  addCommaWithDecimalForInput,
  onlyDecimalNumbersForField,
  onlyPositiveIntegerForField,
  removeCommaForField,
} from '../../../../../../utils/strict-for-field';
import Field from '../../../../../../components/field/deprecated-field';
import Box from '../../../../../../components/box';
import Select from '../../../../../../components/dropdown/select';
import Spacer from '../../../../../../components/surface/spacer';
import MSymbol from '../../../../../../components/icon/m-symbol';
import MultiRowField from '../../../../../../components/field/deprecated-multi-row-field';
import Typography from '../../../../../../components/typography/deprecated-typography';
import RadioField from '../../../../../../components/field/deprecated-radio-field';
import Checkbox from '../../../../../../components/field/checkbox';
import ComboField from '../../../../../../components/dropdown/combo-field';
import {
  SimpleSelectContent,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../../../../../components/dropdown/simple-select';
import SubmissionUrgentBox from '../../../../../../components/card/submission-urgent-box';
import Label from '../../../../../../components/typography/label';
import { yesNoAppOptions } from '../../../../../../store/constants';

const GwsCustomerPayment = () => {
  const submissionFormCloudmile = useAtomValue(submissionFormCloudmileAtom);
  const [submissionFormCustomer, setSubmissionFormCustomer] = useAtom(submissionFormCustomerAtom);

  const officialPricingDiscountAppOptionsQueries = useGetOfficialPricingDiscountAppOptions();
  const workspaceEditionOracleOptionsQueries = useGetWorkspaceEditionOracleOptions();
  const supportPlanOracleOptionsQueries = useGetOracleOptions('msp', 'gws');
  const paymentTermOracleOptionsQueries = useGetOracleOptions('paymentTerm');
  const officialPricingDiscountCurrencyOracleOptionsQueries =
    useGetOfficialPricingDiscountCurrencyOracleOptions(
      'gws',
      submissionFormCloudmile.gws.cm_legal_entity,
      submissionFormCloudmile.gws.exchange_rate
    );

  const customerWorkspacePlanAppOptionsQueries = useGetWorkspacePlanAppOptions(
    'customer-gws-plan',
    submissionFormCustomer.gws.workspace_edition
  );
  const venderWorkspacePlanAppOptionsQueries = useGetWorkspacePlanAppOptions(
    'vender-gws-plan',
    submissionFormCustomer.gws.workspace_edition
  );
  const customerSubTermAppOptionsQueries = useGetMonthAppOptions(
    'subscription_term',
    'customer-sub-term'
  );
  const venderSubTermAppOptionsQueries = useGetMonthAppOptions(
    'subscription_term',
    'vender-sub-term'
  );

  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );
  const setSubmissionFormCloudmile = useSetAtom(submissionFormCloudmileAtom);

  const [isVSpPricePlanRequired, setIsVSpPricePlanRequired] = useState(false);
  const [isVSubTermRequired, setIsVSubTermRequired] = useState(false);

  useEffect(() => {
    if (submissionFormCustomer.gws.special_price_from_google === 'Yes') {
      setIsVSpPricePlanRequired(true);
      setIsVSubTermRequired(true);
    } else {
      setIsVSpPricePlanRequired(false);
      setIsVSubTermRequired(false);
      setSubmissionFormCustomer((prev) => ({
        ...prev,
        gws: {
          ...prev.gws,
          special_price_from_google_subscription_term: '',
          special_price_workspace_plan: '',
          fsr_mail: null,
        },
      }));
      setSubmissionFormValidation((prev) => ({
        ...prev,
        special_price_from_google_subscription_term: undefined,
        special_price_workspace_plan: undefined,
        fsr_mail: undefined,
      }));
    }
  }, [
    setSubmissionFormCustomer,
    setSubmissionFormValidation,
    submissionFormCustomer.gws.special_price_from_google,
  ]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Workspace Edition */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Workspace Edition"
            required={true}
            value={submissionFormCustomer.gws.workspace_edition ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  workspace_edition: newValue,
                  workspace_plan: '',
                  special_price_workspace_plan: '',
                },
              }));
            }}
            options={workspaceEditionOracleOptionsQueries.data}
            optionHeight={60}
            placeholder="Select a workspace edition"
            isLoading={workspaceEditionOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.workspace_edition?.is_error ?? false}
            error={submissionFormValidation.workspace_edition?.error ?? undefined}
          />
        </Box>
        {/* Workspace Plan */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Workspace Plan"
            required={true}
            value={submissionFormCustomer.gws.workspace_plan ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, workspace_plan: newValue },
              }));
            }}
            options={customerWorkspacePlanAppOptionsQueries.data}
            placeholder="Select a workspace plan"
            isLoading={customerWorkspacePlanAppOptionsQueries.isLoading}
            isError={submissionFormValidation.workspace_plan?.is_error ?? false}
            error={submissionFormValidation.workspace_plan?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Seat */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Field
            label="Seats"
            value={submissionFormCustomer.gws.client_seats?.toString() ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  client_seats: onlyPositiveIntegerForField(newValue),
                },
              }));
            }}
            placeholder="Enter a seat amount"
            isError={submissionFormValidation.client_seats?.is_error ?? false}
            error={submissionFormValidation.client_seats?.error ?? undefined}
          />
        </Box>
        {/* Domain Name */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Field
            required
            label="Domain Name"
            value={submissionFormCustomer.gws.client_domain_name ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  client_domain_name: newValue,
                },
              }));
            }}
            placeholder="Enter a domain name"
            isError={submissionFormValidation.client_domain_name?.is_error ?? false}
            error={submissionFormValidation.client_domain_name?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Subscription Term */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <ComboField
            label="Subscription Term"
            required={true}
            mode="normal"
            selectValue={submissionFormCustomer.gws.subscription_term ?? ''}
            fieldValue={submissionFormCustomer.gws.subscription_term_other ?? ''}
            fieldPlaceholder=""
            fieldEndElement={
              <Typography
                variant="body"
                css={{ display: 'block', color: '$secondary70' }}>{`months`}</Typography>
            }
            onFieldChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  subscription_term_other: onlyPositiveIntegerForField(newValue),
                },
              }));
            }}
            onSelectChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  subscription_term: newValue,
                  subscription_term_other: undefined,
                },
              }));
              setSubmissionFormValidation((prev) => ({
                ...prev,
                subscription_term_other: undefined,
              }));
            }}
            selectOptions={customerSubTermAppOptionsQueries.data}
            selectPlaceholder="Select a subscription term"
            isSelectOptionsLoading={customerSubTermAppOptionsQueries.isLoading}
            isSelectError={submissionFormValidation.subscription_term?.is_error ?? false}
            selectError={submissionFormValidation.subscription_term?.error ?? undefined}
            isFieldError={submissionFormValidation.subscription_term_other?.is_error ?? false}
            fieldError={submissionFormValidation.subscription_term_other?.error ?? undefined}
          />
        </Box>
        {/* Support Plan */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Support Plan"
            required={true}
            value={submissionFormCustomer.gws.support_plan ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, support_plan: newValue },
              }));
            }}
            options={supportPlanOracleOptionsQueries.data}
            placeholder="Select a support plan"
            isLoading={supportPlanOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.support_plan?.is_error ?? false}
            error={submissionFormValidation.support_plan?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Payment Term */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Payment Term"
            required={true}
            value={submissionFormCustomer.gws.payment_term ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, payment_term: newValue },
              }));
            }}
            options={paymentTermOracleOptionsQueries.data}
            placeholder="Select a payment term"
            isLoading={paymentTermOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.payment_term?.is_error ?? false}
            error={submissionFormValidation.payment_term?.error ?? undefined}
          />
        </Box>
        {/* Official Pricing Discount */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Official Pricing Discount"
            required={true}
            value={submissionFormCustomer.gws.official_pricing_discount ?? ''}
            onChange={(newValue) => {
              setSubmissionFormValidation((prev) => ({
                ...prev,
                fixed_amount_discount: undefined,
                percentage_discount: undefined,
              }));
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  official_pricing_discount: newValue,
                  fixed_amount_discount: undefined,
                  percentage_discount: undefined,
                },
              }));
            }}
            options={officialPricingDiscountAppOptionsQueries.data}
            placeholder="Select an official pricing discount"
            isLoading={officialPricingDiscountAppOptionsQueries.isLoading}
            isError={submissionFormValidation.official_pricing_discount?.is_error ?? false}
            error={submissionFormValidation.official_pricing_discount?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Fixed Amount Discount */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Label>
            <Typography variant="caption">
              Fixed Amount Discount / Currency
              {submissionFormCustomer.gws.official_pricing_discount === 'Fixed amount discount' ? (
                <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
              ) : null}
            </Typography>
          </Label>
          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 7 }}>
            <Box css={{ flexGrow: 1 }}>
              <Field
                required={
                  submissionFormCustomer.gws.official_pricing_discount === 'Fixed amount discount'
                }
                value={submissionFormCustomer.gws.fixed_amount_discount?.toString() ?? ''}
                onChange={(newValue) => {
                  const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
                  const noneCommaNewValue = removeCommaForField(newValue) || '';
                  setSubmissionFormCustomer((prev) => {
                    return {
                      ...prev,
                      gws: {
                        ...prev.gws,
                        fixed_amount_discount:
                          submissionFormCloudmile.gws.cm_legal_entity === 'CloudMile_ID'
                            ? addCommaForInput(newValue)
                            : noneCommaNewValue === ''
                            ? ''
                            : regex.test(noneCommaNewValue)
                            ? addCommaWithDecimalForInput(noneCommaNewValue)
                            : prev.gws.fixed_amount_discount,
                      },
                    };
                  });
                }}
                placeholder="Enter a fixed amount discount"
                disabled={
                  submissionFormCustomer.gws.official_pricing_discount !== 'Fixed amount discount'
                }
                isError={submissionFormValidation.fixed_amount_discount?.is_error ?? false}
                error={submissionFormValidation.fixed_amount_discount?.error ?? undefined}
              />
            </Box>
            <Box css={{ flexBasis: 26, flexShrink: 0, cursor: 'pointer' }}>
              <SimpleSelectRoot
                value={submissionFormCustomer.gws.official_pricing_discount_currency}
                disabled={
                  submissionFormCustomer.gws.official_pricing_discount !== 'Fixed amount discount'
                }
                onValueChange={(newValue) => {
                  setSubmissionFormCustomer((prev) => ({
                    ...prev,
                    gws: { ...prev.gws, official_pricing_discount_currency: newValue },
                  }));
                }}>
                <SimpleSelectTrigger
                  css={{
                    all: 'unset',
                    display: 'block',
                    width: 26,
                    height: 18,
                    color: '$secondary90',
                    backgroundColor: '$secondary20',
                    borderColor: '$secondary20',
                    transition: '$colors',
                    borderRadius: '$5',
                    p: 6,
                    '&:disabled': {
                      cursor: 'not-allowed',
                      color: '$secondary30',
                      borderColor: '$secondary20',
                      backgroundColor: '$secondary10',
                      '&:hover': {
                        color: '$secondary30',
                        borderColor: '$secondary20',
                        backgroundColor: '$secondary10',
                      },
                    },
                    '&:hover': {
                      color: '$secondary90',
                      borderColor: '$secondary10',
                      backgroundColor: '$secondary10',
                    },
                    '&[data-state=open]': {
                      color: '$secondary90',
                      backgroundColor: '$secondary30',
                      borderColor: '$secondary30',
                    },
                  }}>
                  <Box
                    css={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: 12,
                    }}>
                    <SimpleSelectValue placeholder="" />
                  </Box>
                </SimpleSelectTrigger>
                <SimpleSelectPortal>
                  <SimpleSelectContent position="popper" sideOffset={4} css={{ width: 'revert' }}>
                    <SimpleSelectViewport>
                      {officialPricingDiscountCurrencyOracleOptionsQueries.data &&
                      officialPricingDiscountCurrencyOracleOptionsQueries.data.length > 0 ? (
                        officialPricingDiscountCurrencyOracleOptionsQueries.data?.map(
                          (officialPricingDiscountCurrencyOption) => (
                            <SimpleSelectItem
                              key={officialPricingDiscountCurrencyOption.id}
                              value={officialPricingDiscountCurrencyOption.value}>
                              <SimpleSelectItemText>{`${officialPricingDiscountCurrencyOption.name}`}</SimpleSelectItemText>
                            </SimpleSelectItem>
                          )
                        )
                      ) : (
                        <Typography
                          variant={'caption'}
                          css={{ color: '$secondary40' }}>{`No Result`}</Typography>
                      )}
                    </SimpleSelectViewport>
                  </SimpleSelectContent>
                </SimpleSelectPortal>
              </SimpleSelectRoot>
            </Box>
          </Box>
        </Box>
        {/* Percentage Discount */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Field
            label="Percentage Discount"
            required={
              submissionFormCustomer.gws.official_pricing_discount === 'Percentage discount'
            }
            value={submissionFormCustomer.gws.percentage_discount?.toString() ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, percentage_discount: onlyDecimalNumbersForField(newValue) },
              }));
            }}
            endElement={
              <MSymbol
                iconName="percent"
                css={{ color: '$secondary40', fontSize: '18px !important' }}
              />
            }
            placeholder="Enter a percentage discount"
            disabled={
              submissionFormCustomer.gws.official_pricing_discount !== 'Percentage discount'
            }
            isError={submissionFormValidation.percentage_discount?.is_error ?? false}
            error={submissionFormValidation.percentage_discount?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(100%)' }}>
          {/* Memo */}
          <MultiRowField
            label="Memo"
            required={(submissionFormCustomer.gws.percentage_discount as number) > 5}
            rows={3}
            value={submissionFormCustomer.gws.memo ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, memo: newValue },
              }));
            }}
            placeholder="Let us know if the percentage discount is above 5% and also can leave the necessary verification information."
            isError={submissionFormValidation.memo?.is_error ?? false}
            error={submissionFormValidation.memo?.error ?? undefined}></MultiRowField>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '40px' }} />
      <Typography variant="h5">{`Vender`}</Typography>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Special Price From Google */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <RadioField
            label={`Special Price From Google`}
            required={true}
            value={(submissionFormCustomer.gws.special_price_from_google ?? '') as string}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, special_price_from_google: newValue },
              }));
            }}
            options={yesNoAppOptions}
          />
        </Box>
        {/* Special Price Workspace Plan */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Special Price Workspace Plan"
            required={isVSpPricePlanRequired}
            disabled={!isVSpPricePlanRequired}
            value={submissionFormCustomer.gws.special_price_workspace_plan ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: { ...prev.gws, special_price_workspace_plan: newValue },
              }));
            }}
            options={venderWorkspacePlanAppOptionsQueries.data}
            placeholder="Select a workspace plan"
            isLoading={venderWorkspacePlanAppOptionsQueries.isLoading}
            isError={submissionFormValidation.special_price_workspace_plan?.is_error ?? false}
            error={submissionFormValidation.special_price_workspace_plan?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* (Special Price From Google) Subscription Term */}
          <ComboField
            label="Subscription Term"
            mode="normal"
            required={isVSubTermRequired}
            isSelectDisabled={!isVSubTermRequired}
            isFieldDisabled={!isVSubTermRequired}
            selectValue={
              submissionFormCustomer.gws.special_price_from_google_subscription_term ?? ''
            }
            fieldValue={
              submissionFormCustomer.gws.special_price_from_google_subscription_term_other ?? ''
            }
            onSelectChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  special_price_from_google_subscription_term: newValue,
                  special_price_from_google_subscription_term_other: undefined,
                },
              }));
              setSubmissionFormValidation((prev) => ({
                ...prev,
                special_price_from_google_subscription_term_other: undefined,
              }));
            }}
            selectOptions={venderSubTermAppOptionsQueries.data}
            selectPlaceholder="Select a payment term"
            isSelectOptionsLoading={venderSubTermAppOptionsQueries.isLoading}
            isSelectError={
              submissionFormValidation.special_price_from_google_subscription_term?.is_error ??
              false
            }
            selectError={
              submissionFormValidation.special_price_from_google_subscription_term?.error ??
              undefined
            }
            fieldEndElement={
              <Typography
                variant="body"
                css={{ display: 'block', color: '$secondary70' }}>{`months`}</Typography>
            }
            onFieldChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  special_price_from_google_subscription_term_other:
                    onlyPositiveIntegerForField(newValue),
                },
              }));
            }}
            isFieldError={
              submissionFormValidation.special_price_from_google_subscription_term_other
                ?.is_error ?? false
            }
            fieldError={
              submissionFormValidation.special_price_from_google_subscription_term_other?.error ??
              undefined
            }
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '40px' }} />
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* This is an urgent request */}
        <Box css={{ mr: '10px' }}>
          <Checkbox
            id="urgent-checkbox"
            checked={submissionFormCloudmile.gws.urgent_level as boolean}
            onChange={() => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, urgent_level: !submissionFormCloudmile.gws.urgent_level },
              }));
            }}
          />
        </Box>
        <Label htmlFor="urgent-checkbox">
          <Typography>{`This is an urgent request`}</Typography>
        </Label>
      </Box>
      {/* The local GM will review and approve the request first. */}
      <SubmissionUrgentBox urgentLevel={submissionFormCloudmile.gws.urgent_level} />
    </Box>
  );
};

export default GwsCustomerPayment;
