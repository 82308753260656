import { styled } from '../../styles/stitches.config';
import { Text } from '../typography/text';
import MSymbol from '../icon/m-symbol';

interface SubFormStepperProps {
  stepperName: string;
  activeStep: number;
  steps: { id: string; name: string; value: string }[];
}

const SubFormStepCheckmark = styled('div', {
  width: 20,
  height: 20,
  color: '$white',
  borderRadius: '50%',
  borderWidth: 2,
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$white',
  borderColor: '$achromatic30',
  transition: '$colors',
});
const SubFormStep = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: 'clamp(5px, 2vw, 10px)',
  color: '$achromatic30',
  transition: '$colors',
  '&:not(:last-child)': {
    flex: 1,
    '&:after': {
      content: '',
      flex: 1,
      height: '2px',
      width: 40,
      borderRadius: 4,
      position: 'relative',
      marginRight: 10,
      transition: 'background-color 0.15s linear',
      backgroundColor: '$achromatic30',
    },
  },
  '&[data-state=active]': {
    color: '$primary50',
    [`& ${SubFormStepCheckmark}`]: {
      borderColor: '$primary50',
      backgroundColor: '$primary50',
    },
    '&:not(:last-child)': { '&:after': { backgroundColor: '$primary50' } },
  },
});
const SubFormStepperRoot = styled('ol', {
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  mx: 'auto',
  p: 0,
});

const SubFormStepper = ({ stepperName, activeStep, steps }: SubFormStepperProps) => {
  return (
    <SubFormStepperRoot data-track-id={`stepper-${stepperName}`}>
      {steps.map((step, index) => (
        <SubFormStep
          data-track-id={`step-${step.value}-${index + 1}`}
          data-state={activeStep >= index + 1 ? 'active' : 'inactive'}
          key={`${step.id}`}>
          <SubFormStepCheckmark>
            <MSymbol iconName="check" weight={700} size={14} css={{ color: 'inherit' }} />
          </SubFormStepCheckmark>
          <Text bold={activeStep >= index + 1} css={{ whiteSpace: 'nowrap' }}>
            {step.name}
          </Text>
        </SubFormStep>
      ))}
    </SubFormStepperRoot>
  );
};

export default SubFormStepper;
