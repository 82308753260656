import type { CredentialResponse } from '@react-oauth/google';
import { useMutation } from 'react-query';
import { useAxios } from '../hooks';
import { useSetAtom } from 'jotai';
import { authUserAtom } from '../store/atoms';
import { setUserIDtoGA4 } from '../utils/g-analytics';
import { pushUserIdToGTM } from '../utils/g-tag-manager';

export const useLogin = (options?: { onMutationSuccess?: () => void }) => {
  const axios = useAxios();
  const setAuthUser = useSetAtom(authUserAtom);

  return useMutation(
    (credentialResponse: CredentialResponse) =>
      axios.post(`/v1/auth/login`, { id_token: `${credentialResponse.credential}` }),
    {
      onSuccess: (res) => {
        setAuthUser(res.data);
        if (options?.onMutationSuccess) {
          options.onMutationSuccess();
        }
        pushUserIdToGTM(res.data.id);
        setUserIDtoGA4(res.data.id);
      },
    }
  );
};

// useRefreshToken example，有要做的話再請酌情修改。前提：要 Backend 要做一隻 Refresh Token API
// export const useRefreshToken = () => {
//   const axios = useAxios();
//   const setAuthUser = useSetAtom(authUserAtom);

//   return useMutation(({ data }: { data: any }) => axios.post(`/refresh-token`), {
//     onSuccess: (res) => {
//       setAuthUser(res.data);
//     },
//   });
// };

export const useLogout = () => {
  const axios = useAxios();
  const setAuthUser = useSetAtom(authUserAtom);

  return useMutation(() => axios.delete(`/v1/auth/logout`), {
    onSuccess: () => {
      setAuthUser(null);
      // 若想記錄登出時間，可在 Local Storage 紀錄 'logout', Date.now().toString()
    },
  });
};
