export const checkFileExt = (fileName: string) => {
  const fileExt = fileName?.split('.').pop()?.toLocaleLowerCase();
  switch (true) {
    case fileExt === 'jpg' ||
      fileExt === 'jpeg' ||
      fileExt === 'png' ||
      fileExt === 'pdf' ||
      fileExt === '':
      return false;
    default:
      return true;
  }
};

export const checkFileSize = (fileSize: number) => {
  // * 2 MB
  const limit = 2097152;
  if (fileSize > limit) {
    return true;
  }
  return false;
};
