const getNumberFromInput = (inputValue: string) => {
  const strings = inputValue.split('');
  const out: string[] = [];
  strings.forEach((string) => {
    if (isNaN(parseInt(string)) === false) {
      out.push(string);
    }
  });
  return Number(out.join(''));
};

export const addCommaForInput = (inputValue: string) => {
  const number = getNumberFromInput(inputValue);
  if (number === 0) {
    return '';
  } else {
    return number.toLocaleString();
  }
};

export const addCommaWithDecimalForInput = (inputValue: string) => {
  const parts = inputValue.split('.');
  return parts[1] || parts[1] === ''
    ? [parseInt(parts[0]).toLocaleString(), parts[1]].join('.')
    : parseInt(parts[0]).toLocaleString();
};

export const removeCommaForField = (inputValue: string) => {
  if (inputValue === '') {
    return undefined;
  } else {
    return inputValue.split(',').join('');
  }
};

export const onlyDecimalNumbersForField = (inputValue: string) => {
  const regex = /[^\d.]/g;
  return inputValue.replace(regex, '');
};

export const onlyPositiveIntegerForField = (inputValue: string) => {
  const regex = /[^0-9]/g;
  return inputValue.replace(regex, '');
};
