import { ComponentProps, MouseEvent, ReactNode, forwardRef } from 'react';
import { useAtomValue } from 'jotai';
import { motion } from 'framer-motion';
import { styled } from '../../styles/stitches.config';
import { appBarHeightAtom, breadcrumbHeightAtom } from '../../store/atoms';
import { applicationDetailsSidebarWidth } from '../../store/constants';
import MSymbol from '../icon/m-symbol';
import Box from '../box';

interface AppDetailPanelsRootProps {
  open: boolean;
  children: ReactNode;
}
export const AppDetailPanelsRoot = ({ open, children }: AppDetailPanelsRootProps) => {
  const appBarHeight = useAtomValue(appBarHeightAtom);
  const breadcrumbHeight = useAtomValue(breadcrumbHeightAtom);
  return (
    <Box
      as={motion.div}
      initial={open ? 'show' : 'hide'}
      animate={open ? 'show' : 'hide'}
      variants={{
        show: { translateX: 0, transition: { duration: 0.15 } },
        hide: {
          translateX: 0 + applicationDetailsSidebarWidth - 50,
          transition: { duration: 0.15 },
        },
      }}
      css={{
        width: '100%',
        display: 'flex',
        position: 'fixed',
        right: 0,
        bottom: 0,
        backgroundColor: '$white',
        filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2))',
        zIndex: 85,
        minWidth: `${applicationDetailsSidebarWidth}px`,
        maxWidth: `${applicationDetailsSidebarWidth}px`,
        height: `calc(100vh - ${appBarHeight} - ${breadcrumbHeight} - 57px)`,
      }}>
      {children}
    </Box>
  );
};

interface AppDetailPanelsListProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
}
export const AppDetailPanelsList = ({
  open,
  onOpenChange,
  children,
  onClick,
}: AppDetailPanelsListProps) => {
  return (
    <Box
      id="app-detail-panels-list"
      onClick={(e) => {
        if (!open) {
          onOpenChange(true);
        } else {
          if ((e.target as HTMLDivElement).id === 'app-detail-panels-list') {
            onOpenChange(!open);
          }
        }
        if (onClick) onClick(e);
      }}
      css={{
        flexBasis: '50px',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}>
      {children}
    </Box>
  );
};

const StyledAppDetailPanelsTrigger = styled('div', {
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  transition: '$colors',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 2,
    backgroundColor: '$transparent',
    transition: '$colors',
  },
  variants: {
    light: {
      on: {
        '&:before': {
          backgroundColor: '$primary50',
        },
        '&:hover': {
          backgroundColor: '$primary10',
        },
        '&:active': {
          backgroundColor: '$primary20',
        },
      },
      off: {
        '&:hover': {
          backgroundColor: '$secondary3',
          '&:before': {
            backgroundColor: '$secondary20',
          },
        },
        '&:active': {
          backgroundColor: '$secondary10',
          '&:before': {
            backgroundColor: '$secondary20',
          },
        },
      },
    },
  },
  defaultVariants: {
    light: 'off',
  },
});
interface AppDetailPanelsTriggerProps extends ComponentProps<typeof StyledAppDetailPanelsTrigger> {
  triggerIconName: string;
  light?: 'on' | 'off';
}
export const AppDetailPanelsTrigger = forwardRef<HTMLDivElement, AppDetailPanelsTriggerProps>(
  ({ triggerIconName, light = 'on', ...restProps }, ref) => {
    return (
      <StyledAppDetailPanelsTrigger ref={ref} light={light} {...restProps}>
        <MSymbol
          iconName={`${triggerIconName}`}
          fill={false}
          css={{ color: light === 'on' ? '$primary50' : '$secondary40' }}
        />
      </StyledAppDetailPanelsTrigger>
    );
  }
);
AppDetailPanelsTrigger.displayName = 'AppDetailPanelsTrigger';

export const AppDetailPanelsContent = styled('div', {
  flexGrow: 1,
  flexShrink: 0,
  borderLeft: 'solid 1px $secondary20',
  display: 'flex',
  flexDirection: 'column',
});

export const AppDetailPanelsToggler = styled('div', {
  position: 'absolute',
  top: '50%',
  left: -24,
  width: 25,
  height: 50,
  boxShadow:
    '0px 0px 6px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2), 0px 0px -6px rgba(0, 0, 0, 0.2)',
  backgroundColor: '$white',
  transform: 'translateY(-50%)',
  borderTopLeftRadius: '$5',
  borderBottomLeftRadius: '$5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

interface AppDetailPanelsTogglerArrowProps {
  iconName: string;
  open: boolean;
}
export const AppDetailPanelsTogglerArrow = ({
  iconName,
  open,
}: AppDetailPanelsTogglerArrowProps) => {
  return (
    <Box
      as={motion.div}
      initial={{ rotate: 0 }}
      animate={
        open
          ? { rotate: 0, transition: { duration: 0.15 } }
          : { rotate: -180, transition: { duration: 0.15 } }
      }
      css={{ display: 'flex', alignItems: 'center' }}>
      <MSymbol iconName={`${iconName}`} />
    </Box>
  );
};
