import { useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import {
  useGetCurrencyOracleOptions,
  useGetExchangeRateOracleOptions,
} from '../../../../../../services/application/official-standard/oracle-options.api';
import { useGetMonthAppOptions } from '../../../../../../services/application/official-standard/app-options.api';
import { onlyPositiveIntegerForField } from '../../../../../../utils/strict-for-field';
import moment from 'moment';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Label from '../../../../../../components/typography/label';
import Box from '../../../../../../components/box';
import Typography from '../../../../../../components/typography/deprecated-typography';
import Spacer from '../../../../../../components/surface/spacer';
import ComboField from '../../../../../../components/dropdown/combo-field';
import InputErrorMessage from '../../../../../../components/typography/input-error-message';
import ComboSelect from '../../../../../../components/dropdown/combo-select';
import '../../../../../../styles/custom-react-multi-date-picker.css';

const GcpCloudmilePayment = () => {
  const termAppOptionsQueries = useGetMonthAppOptions('term', 'cloudmile-term');
  const autoRenewTermAppOptionsQueries = useGetMonthAppOptions(
    'auto_renew_term',
    'cloudmile-auto-renew-term'
  );

  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  const currencyOracleOptionsQueries = useGetCurrencyOracleOptions(
    'gcp',
    submissionFormCloudmile.gcp.cm_legal_entity,
    (res) => {
      if (!submissionFormCloudmile.gcp.currency) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: { ...prev.gcp, currency: res[0].value },
        }));
      }
    }
  );
  const exChangeRateOracleOptionsQueries = useGetExchangeRateOracleOptions(
    'gcp',
    submissionFormCloudmile.gcp.cm_legal_entity,
    submissionFormCloudmile.gcp.currency
  );

  useEffect(() => {
    if (submissionFormCloudmile.gcp.currency) {
      exChangeRateOracleOptionsQueries.refetch();
    }
  }, [submissionFormCloudmile.gcp.currency]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Validity */}
          <Label>
            <Typography variant="caption">
              {`Quotation Validity`}
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            </Typography>
          </Label>
          <DatePicker
            // placeholder=''
            value={submissionFormCloudmile.gcp.validity ?? ''}
            format="DD MMM YYYY"
            minDate={new Date()}
            onChange={(newDate) => {
              if (newDate instanceof DateObject) {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: { ...prev.gcp, validity: moment(newDate.toDate()).format('DD MMM YYYY') },
                }));
              }
            }}
            containerClassName="cm-calendar-container"
            inputClass="cm-calendar-input"
            className="cm-calendar"
            arrowClassName="cm-calendar-arrow"
          />
          <InputErrorMessage
            isError={submissionFormValidation.validity?.is_error}
            errorMessage={submissionFormValidation.validity?.error}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Terms */}
          <ComboField
            mode="normal"
            label="Terms"
            required={true}
            selectPlaceholder="Select a terms"
            selectValue={(submissionFormCloudmile.gcp.terms as string) ?? ''}
            fieldValue={(submissionFormCloudmile.gcp.terms_other as string) ?? ''}
            fieldPlaceholder=""
            fieldEndElement={
              <Typography
                variant="body"
                css={{ display: 'block', color: '$secondary70' }}>{`months`}</Typography>
            }
            onSelectChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, terms: newValue, terms_other: undefined },
              }));
              setSubmissionFormValidation((prev) => ({ ...prev, terms_other: undefined }));
            }}
            onFieldChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  terms_other: onlyPositiveIntegerForField(newValue),
                },
              }))
            }
            selectOptions={termAppOptionsQueries.data ?? []}
            isSelectOptionsLoading={termAppOptionsQueries.isLoading}
            isSelectError={submissionFormValidation.terms?.is_error ?? false}
            selectError={submissionFormValidation.terms?.error ?? undefined}
            isFieldError={submissionFormValidation.terms_other?.is_error ?? false}
            fieldError={submissionFormValidation.terms_other?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Automatically Renew Terms */}
          <ComboField
            mode="normal"
            label="Automatically Renew Terms"
            required={true}
            selectPlaceholder="Select a renew terms"
            selectValue={(submissionFormCloudmile.gcp.automatically_renew_terms as string) ?? ''}
            fieldValue={
              (submissionFormCloudmile.gcp.automatically_renew_terms_other as string) ?? ''
            }
            fieldPlaceholder=""
            fieldEndElement={
              <Typography
                variant="body"
                css={{ display: 'block', color: '$secondary70' }}>{`months`}</Typography>
            }
            onSelectChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  automatically_renew_terms: newValue,
                  automatically_renew_terms_other: undefined,
                },
              }));
              setSubmissionFormValidation((prev) => ({
                ...prev,
                automatically_renew_terms_other: undefined,
              }));
            }}
            onFieldChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  automatically_renew_terms_other: onlyPositiveIntegerForField(newValue),
                },
              }))
            }
            selectOptions={autoRenewTermAppOptionsQueries.data ?? []}
            isSelectOptionsLoading={autoRenewTermAppOptionsQueries.isLoading}
            isSelectError={submissionFormValidation.automatically_renew_terms?.is_error ?? false}
            selectError={submissionFormValidation.automatically_renew_terms?.error ?? undefined}
            isFieldError={
              submissionFormValidation.automatically_renew_terms_other?.is_error ?? false
            }
            fieldError={
              submissionFormValidation.automatically_renew_terms_other?.error ?? undefined
            }
          />
        </Box>
        <Box css={{ width: 'calc(50% - 10px)' }}>
          {/* Currency & Exchange Rate */}
          <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
            <ComboSelect
              label="Currency & Exchange Rate"
              required={true}
              selectLeftValue={submissionFormCloudmile.gcp.currency || ''}
              onSelectLeftValueChange={(newValue) => {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: {
                    ...prev.gcp,
                    currency: newValue,
                    exchange_rate: undefined,
                  },
                }));
              }}
              selectLeftOptions={currencyOracleOptionsQueries.data ?? []}
              selectLeftPlaceholder="Select a currency"
              isSelectLeftOptionsLoading={currencyOracleOptionsQueries.isLoading}
              isSelectLeftError={submissionFormValidation.currency?.is_error ?? false}
              selectLeftError={submissionFormValidation.currency?.error ?? undefined}
              selectRightValue={submissionFormCloudmile.gcp.exchange_rate || ''}
              onSelectRightValueChange={(newValue) => {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: { ...prev.gcp, exchange_rate: newValue },
                }));
              }}
              selectRightOptions={exChangeRateOracleOptionsQueries.data ?? []}
              selectRightOptionHeight={60}
              selectRightPlaceholder="Select an exchange rate"
              isSelectRightOptionsLoading={exChangeRateOracleOptionsQueries.isLoading}
              isSelectRightError={submissionFormValidation.exchange_rate?.is_error ?? false}
              selectRightError={submissionFormValidation.exchange_rate?.error ?? undefined}
              selectRightDisabled={
                !exChangeRateOracleOptionsQueries.data ||
                exChangeRateOracleOptionsQueries.data.length <= 0
              }
            />
          </Box>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Commencement Date */}
          <Label>
            <Typography variant="caption">{`Agreement Commencement Date`}</Typography>
          </Label>
          <DatePicker
            // placeholder=''
            value={submissionFormCloudmile.gcp.commencement_date ?? ''}
            format="DD MMM YYYY"
            minDate={new Date()}
            onChange={(newDate) => {
              if (newDate instanceof DateObject) {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: {
                    ...prev.gcp,
                    commencement_date: moment(newDate.toDate()).format('DD MMM YYYY'),
                    end_date: undefined,
                  },
                }));
              }
            }}
            containerClassName="cm-calendar-container"
            inputClass="cm-calendar-input"
            className="cm-calendar"
            arrowClassName="cm-calendar-arrow"
          />
          <InputErrorMessage
            isError={submissionFormValidation.commencement_date?.is_error}
            errorMessage={submissionFormValidation.commencement_date?.error}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* End Date */}
          <Label>
            <Typography variant="caption">{`Agreement End Date`}</Typography>
          </Label>
          <DatePicker
            // placeholder=''
            value={submissionFormCloudmile.gcp.end_date ?? ''}
            format="DD MMM YYYY"
            minDate={new Date(submissionFormCloudmile.gcp.commencement_date as string)}
            onChange={(newDate) => {
              if (newDate instanceof DateObject) {
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: { ...prev.gcp, end_date: moment(newDate.toDate()).format('DD MMM YYYY') },
                }));
              }
            }}
            containerClassName="cm-calendar-container"
            inputClass="cm-calendar-input"
            className="cm-calendar"
            arrowClassName="cm-calendar-arrow"
          />
          <InputErrorMessage
            isError={submissionFormValidation.end_date?.is_error}
            errorMessage={submissionFormValidation.end_date?.error}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GcpCloudmilePayment;
