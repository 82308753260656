import moment from 'moment';
import { AppDetailContractRequestTabProps } from './contract-request-tab';
import { useGetAppDetailContractRequestTabPLPS } from '../../../../services/application/verified-standard/app-detail-tabs.api';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import { Caption, Text } from '../../../../components/typography/text';
import Spacer from '../../../../components/surface/spacer';
import Disclosure from '../../../../components/surface/disclosure';
import {
  SubmissionColumn,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Avatar from '../../../../components/surface/avatar';
import LogoPLPS from '../../../../assets/images/logo-plps.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';

const ContractRequestTabPLPS = ({ product, appId }: AppDetailContractRequestTabProps) => {
  const appDetailContractRequestTabQuery = useGetAppDetailContractRequestTabPLPS({
    product,
    appId,
  });
  return (
    <>
      {appDetailContractRequestTabQuery.isLoading ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Spinner size="large" />
        </CenteredBoxAbsolute>
      ) : appDetailContractRequestTabQuery.isSuccess ? (
        <Box
          css={{
            px: 30,
            pb: 30,
            margin: '0 auto',
            mt: 30,
            minWidth: '580px',
            maxWidth: '760px',
            backgroundColor: '$white',
          }}>
          <Disclosure
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    {/* General Info */}
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>CloudMile Details</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoPLPS} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.cm_legal_entity.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={
                                appDetailContractRequestTabQuery.data.cm_attention_to.name || '-'
                              }
                              picture={appDetailContractRequestTabQuery.data.cm_picture || ''}
                              size="sm"
                            />
                            <Text>{`${
                              appDetailContractRequestTabQuery.data.cm_attention_to.name || '-'
                            }`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailContractRequestTabQuery.data.cm_email || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.cm_country_calling_code.name || ''
                          } ${appDetailContractRequestTabQuery.data.cm_phone_number || '-'} ${
                            appDetailContractRequestTabQuery.data.cm_extension_number
                              ? `#${appDetailContractRequestTabQuery.data.cm_extension_number}`
                              : ''
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(appDetailContractRequestTabQuery.data.quotation_validity).format(
                              'DD MMM YYYY'
                            ) || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${appDetailContractRequestTabQuery.data.currency.name || '-'} / ${
                            appDetailContractRequestTabQuery.data.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Customer Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Name`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_country?.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Tax Reg Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.tax_reg_number || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_address || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_attention_to || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_email || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.client_country_calling_code
                              .name || ''
                          } ${appDetailContractRequestTabQuery.data.client_phone_number || '-'} ${
                            appDetailContractRequestTabQuery.data.client_extension_number
                              ? `#${appDetailContractRequestTabQuery.data.client_extension_number}`
                              : ''
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Billing Account */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Billing Account`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {`${appDetailContractRequestTabQuery.data.billing_account.name || '-'}`}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            `${appDetailContractRequestTabQuery.data.payment_term.name}` || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.support_plan.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.official_pricing_discount_type
                              .name || '-'
                          } / ${
                            appDetailContractRequestTabQuery.data.official_pricing_discount_type
                              .value === 'percentage_discount'
                              ? `${appDetailContractRequestTabQuery.data.official_pricing_discount_value} %`
                              : appDetailContractRequestTabQuery.data.official_pricing_discount_type
                                  .value === 'fixed_amount_discount'
                              ? `${appDetailContractRequestTabQuery.data.official_pricing_discount_value} ${appDetailContractRequestTabQuery.data.official_pricing_discount_currency.name}`
                              : '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Will Customer issue PO, LOA, Order Form, other ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Will Customer issue PO, LOA, Order Form, other ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            appDetailContractRequestTabQuery.data.is_issued_form?.toUpperCase() ||
                            '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </Box>
      ) : appDetailContractRequestTabQuery.isError ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={ErrorBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
        </CenteredBoxAbsolute>
      ) : null}
    </>
  );
};

export default ContractRequestTabPLPS;
