import { styled } from '../../styles/stitches.config';
import * as RadioGroup from '@radix-ui/react-radio-group';
import Box from '../box';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';
import { IdNameValueItem } from '../../types';

const RadioGroupRoot = styled(RadioGroup.Root, {
  display: 'flex',
  columnGap: '20px',
});

const RadioGroupItem = styled(RadioGroup.Item, {
  all: 'unset',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  borderRadius: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  mr: '10px',
  cursor: 'pointer',
  transition: 'border-color 0.15s linear',
  '&[data-state="checked"]': {
    borderColor: '$primary50',
  },
  '&[data-state="checked"]:hover': {
    borderColor: '$primary40',
  },
  '&[data-state="checked"]:active': {
    borderColor: '$primary60',
  },
  '&[data-state="unchecked"]': {
    borderColor: '$primary50',
  },
  '&[data-state="unchecked"]:hover': {
    borderColor: '$primary40',
  },
  '&[data-state="unchecked"]:active': {
    borderColor: '$primary60',
  },
});

const RadioGroupIndicator = styled(RadioGroup.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&:after': {
    content: '""',
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    transition: 'background-color 0.15s linear',
  },
  '&[data-state="checked"]:after': {
    backgroundColor: '$primary50',
  },
  '&[data-state="checked"]:hover:after': {
    backgroundColor: '$primary40',
  },
  '&[data-state="checked"]:active:after': {
    backgroundColor: '$primary60',
  },
  '&[data-state="unchecked"]:after': {
    backgroundColor: '$primary50',
  },
});

const RadioField = ({
  label,
  labelPosition = 'top',
  required,
  value,
  onChange,
  options,
}: {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  value?: string;
  onChange?: (newValue: string) => void;
  options?: IdNameValueItem[];
}) => {
  const handleOnChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <Box
      css={{
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <RadioGroupRoot value={value} onValueChange={handleOnChange}>
        {options && options.length > 0
          ? options.map((option) => (
              <Box key={option.id} css={{ display: 'flex', alignItems: 'center', px: 8 }}>
                <RadioGroupItem id={option.id} value={option.value}>
                  <RadioGroupIndicator />
                </RadioGroupItem>
                <Label htmlFor={option.id}>
                  <Typography variant="body">{option.name}</Typography>
                </Label>
              </Box>
            ))
          : null}
      </RadioGroupRoot>
    </Box>
  );
};

export default RadioField;
