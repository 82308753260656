import { useAtom } from 'jotai';
import { submissionFormSelectedProductAtom } from '../../../../../store/atoms';
import Box from '../../../../../components/box';
import Spacer from '../../../../../components/surface/spacer';
import LogoButton from '../../../../../components/button/logo-button';
import LogoSvg from '../../../../../components/surface/logo-svg';
import { applicationApplyServiceItems } from '../../../../../store/constants';

const ServiceItems = () => {
  const [submissionFormSelectedProduct, setSubmissionFormSelectedProduct] = useAtom(
    submissionFormSelectedProductAtom
  );

  return (
    <Box css={{ m: '0 auto', width: '260px' }}>
      {applicationApplyServiceItems.map((product) => (
        <Box key={product.id}>
          <Spacer axis="vertical" css={{ $$size: '10px' }} />
          <LogoButton
            light={submissionFormSelectedProduct === product.value ? 'on' : 'off'}
            css={{
              backgroundColor: '$white',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSubmissionFormSelectedProduct(product.value as 'gcp' | 'gws');
            }}>
            <LogoSvg product={product.value} />
          </LogoButton>
        </Box>
      ))}
    </Box>
  );
};

export default ServiceItems;
