import { styled } from '../../styles/stitches.config';
import { overlayShow } from '../../styles/keyframes';

const Overlay = styled('div', {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 999,
  backgroundColor: '$transparent',
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

export default Overlay;
