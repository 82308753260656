import React, { ComponentProps, ReactNode } from 'react';
import { styled } from '../../styles/stitches.config';
import { pulse } from '../../styles/keyframes';

const SkeletonRoot = styled('span', {
  display: 'block',
  backgroundColor: '$achromatic20',
  borderRadius: 5,
  animation: `${pulse} 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
  variants: {
    rounded: {
      true: { borderRadius: '$rounded' },
    },
  },
});

interface SkeletonProps extends ComponentProps<typeof SkeletonRoot> {
  children?: ReactNode;
}

const Skeleton = ({ children, ...restProps }: SkeletonProps) => {
  return <SkeletonRoot {...restProps}>{children}</SkeletonRoot>;
};

export default Skeleton;
