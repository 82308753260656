import { MentionsInput } from 'react-mentions';
import { styled } from '../../styles/stitches.config';

const MentionArea = styled(MentionsInput, {
  '&[class$="--multiLine"]': {
    '[class$="__control"]': {
      fontSize: 14,
      minHeight: 140,
      backgroundColor: '$white',
    },
    '[class$="__highlighter"]': {
      padding: 10,
      '> [class$="__mention"]': {
        backgroundColor: '$primary10',
        borderRadius: 5,
      },
    },
    '[class$="__input"]': {
      padding: 10,
      border: 'none',
      outline: 'none',
      boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
      transition: '$shadow',
      borderRadius: 5,
      p: 10,
      '&:hover': {
        boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
      },
      '&::placeholder': {
        color: '$text-hint',
      },
    },
    '[class$="__suggestions"]': {
      boxShadow: '$basic',
      p: 10,
      borderRadius: '$5',
      width: 'max-content',
      backgroundColor: '$white',
      maxHeight: 280,
      overflow: 'auto',
      zIndex: '99 !important',
      '& > [class$="__suggestions__list"]': {
        '& > [class$="__suggestions__item"], > [class$="__suggestions__item--focused"]': {
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          height: 30,
          transition: '$colors',
          borderRadius: '$5',
          px: 10,
        },
        '& > [class$="__suggestions__item--focused"]': {
          backgroundColor: '$func-hover',
          '&:active': {
            backgroundColor: '$func-active',
          },
        },
      },
    },
  },
});

export default MentionArea;
