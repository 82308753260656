import { styled } from '../../styles/stitches.config';

const AppDetailContent = styled('div', {
  display: 'flex',
  mt: 57,
  ml: 50,
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  minHeight: 'calc(100vh - 60px - 30px - 57px)',
});

export default AppDetailContent;
