import { styled } from '../../styles/stitches.config';
import { ButtonText, Text } from '../typography/text';

export const ApprovalProgressRoot = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  columnGap: 6,
});

export const ApprovalProgressCard = styled('div', {
  flex: 1,
  px: 10,
  py: 4,
  position: 'relative',
  variants: {
    state: {
      pending: {
        backgroundColor: '$func-border-light',
        [`& ${Text}`]: {
          color: '$achromatic40',
        },
        [`& .i`]: {
          color: '$achromatic40',
        },
        [`& ${ButtonText}`]: {
          color: '$text-secondary',
        },
      },
      processing: {
        backgroundColor: '$achromatic3',
        [`& ${Text}`]: {
          color: '$primary50',
        },
        [`& .i`]: {
          color: '$primary50',
        },
        [`& ${ButtonText}`]: {
          color: '$text-secondary',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 3,
          backgroundColor: '$primary50',
        },
      },
      rejected: {
        backgroundColor: '$error10',
        [`& ${Text}`]: {
          color: '$error60',
        },
        [`& .i`]: {
          color: '$error60',
        },
        [`& ${ButtonText}`]: {
          color: '$text-secondary',
        },
      },
      approved: {
        backgroundColor: '$success10',
        [`& ${Text}`]: {
          color: '$success70',
        },
        [`& .i`]: {
          color: '$success70',
        },
        [`& ${ButtonText}`]: {
          color: '$text-secondary',
        },
      },
    },
  },
  defaultVariants: {
    state: 'waiting',
  },
});
