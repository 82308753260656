import { styled } from '../../styles/stitches.config';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../styles/keyframes';

export const TooltipRoot = styled(RadixTooltip.Root, {});
export const TooltipTrigger = styled(RadixTooltip.Trigger, {});
export const TooltipPortal = styled(RadixTooltip.Portal, {});
export const TooltipArrow = styled(RadixTooltip.Arrow, { fill: 'rgba(0, 9, 16, 0.8)' });

export const TooltipContent = styled(RadixTooltip.Content, {
  borderRadius: '$5',
  backgroundColor: 'rgba(0, 9, 16, 0.8)',
  boxShadow: '$basic',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="delayed-open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});
