import type { ReactNode, MouseEvent, ComponentPropsWithoutRef } from 'react';
import Box from '../box';

const TableContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <Box
      css={{
        overflowX: 'auto',
        borderRadius: '$10',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '$secondary20',
        backgroundColor: '$white',
        overflowY: 'hidden',
      }}>
      {children}
    </Box>
  );
};

interface TableProps extends ComponentPropsWithoutRef<'table'> {
  children?: ReactNode;
}
const Table = ({ children, ...rest }: TableProps) => {
  return (
    <Box
      as="table"
      css={{
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: '0',
        minWidth: '1024px',
        tableLayout: 'fixed',
      }}
      {...rest}>
      {children}
    </Box>
  );
};

interface TableHeadProps extends ComponentPropsWithoutRef<'thead'> {
  children?: ReactNode;
}
const TableHead = ({ children, ...rest }: TableHeadProps) => {
  return (
    <Box
      as="thead"
      css={{
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '$secondary30',
      }}
      {...rest}>
      {children}
    </Box>
  );
};

interface TableHeaderProps extends ComponentPropsWithoutRef<'th'> {
  children?: ReactNode;
  grow?: number;
  basis?: string;
}
const TableHeader = ({ children, grow = 1, basis, ...rest }: TableHeaderProps) => {
  return (
    <Box
      as="th"
      css={{
        height: '50px',
        px: '10px',
        display: 'flex',
        alignItems: 'center',
        flexGrow: grow,
        flexBasis: basis,
      }}
      {...rest}>
      {children}
    </Box>
  );
};

interface TableBodyProps extends ComponentPropsWithoutRef<'tbody'> {
  children?: ReactNode;
}
const TableBody = ({ children, ...rest }: TableBodyProps) => (
  <Box as="tbody" css={{ width: '100%' }} {...rest}>
    {children}
  </Box>
);

interface TableRowProps extends ComponentPropsWithoutRef<'tr'> {
  needHover?: boolean;
  needPointer?: boolean;
  isActive?: boolean;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLTableRowElement>) => void;
}
const TableRow = ({
  needHover,
  needPointer,
  isActive,
  children,
  onClick,
  ...rest
}: TableRowProps) => {
  return (
    <Box
      as="tr"
      onClick={onClick}
      css={{
        display: 'flex',
        height: 'fit-content',
        minHeight: '60px',
        alignItems: 'center',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '$secondary10',
        transition: 'background-color 0.15s linear',
        cursor: needPointer ? 'pointer' : 'auto',
        backgroundColor: isActive ? '$primary20' : 'auto',
        '&:hover': {
          backgroundColor: isActive ? '$primary30' : needHover ? '$secondary3' : 'auto',
        },
        '&:active': {
          backgroundColor: isActive ? '$primary40' : needHover ? '$secondary10' : 'auto',
        },
      }}
      {...rest}>
      {children}
    </Box>
  );
};

interface TableCellProps extends ComponentPropsWithoutRef<'td'> {
  children?: ReactNode;
  grow?: number;
  basis?: string;
  onClick?: (e: MouseEvent<HTMLTableCellElement>) => void;
}
const TableCell = ({ children, grow = 1, basis, onClick, ...rest }: TableCellProps) => (
  <Box
    as="td"
    onClick={onClick}
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      px: '10px',
      py: '4px',
      flexBasis: basis,
      flexGrow: grow,
      wordBreak: 'break-word',
      minHeight: '60px',
    }}
    {...rest}>
    {children}
  </Box>
);

interface TableFootProps extends ComponentPropsWithoutRef<'tfoot'> {
  children?: ReactNode;
}
const TableFoot = ({ children }: TableFootProps) => <Box as="tfoot">{children}</Box>;

export { TableContainer, Table, TableHead, TableHeader, TableBody, TableRow, TableCell, TableFoot };
