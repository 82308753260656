import React from 'react';
import { styled } from '../../styles/stitches.config';
import { motion } from 'framer-motion';
import Box from '../box';
import { AppStatus } from '../../types';

export const AppListTabRoot = styled('div', {
  display: 'flex',
  borderBottom: '2px solid $secondary10',
  alignItems: 'center',
  columnGap: 32,
});

export const AppListTabTrigger = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '$1',
  cursor: 'pointer',
  px: 10,
  position: 'relative',
  flexWrap: 'wrap',
});

export const AppListTabTriggerDecorator = ({
  curAppListTab,
  tabValue,
}: {
  curAppListTab: 'all' | AppStatus;
  tabValue: 'all' | AppStatus;
}) => {
  return (
    <Box
      as={motion.div}
      animate={curAppListTab === tabValue ? 'active' : 'inactive'}
      initial={{ opacity: 0 }}
      variants={{
        active: { opacity: 1 },
        inactive: { opacity: 0 },
      }}
      css={{
        height: 4,
        width: '100%',
        borderTopLeftRadius: '$5',
        borderTopRightRadius: '$5',
        borderBottomLeftRadius: '$5',
        borderBottomRightRadius: '$5',
        backgroundColor: curAppListTab === tabValue ? '$primary50' : '$secondary70',
      }}
    />
  );
};
