import { SetStateAction } from 'react';
import { styled } from '../../styles/stitches.config';
import { getRange } from '../../utils/get-range';
import Box from '../box';
import Divider from './divider';
import MSymbol from '../icon/m-symbol';

interface PaginationProps {
  curPageOffset?: number;
  pageLimit: number;
  pageOffset: number;
  pageTotal: number | undefined;
  setPageLimit?: (options: { setter: SetStateAction<number>; newValue?: number }) => void;
  setPageOffset: (options: { setter: SetStateAction<number>; newValue?: number }) => void;
}

const PageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '5px',
  overflow: 'hidden',
  borderColor: '$secondary20',
});

const PageElement = styled('div', {
  width: '30px',
  height: '30px',
  fontSize: '14px',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'background-color 0.15s linear',
  cursor: 'pointer',
  color: '$secondary70',
  backgroundColor: '$white',
  '&:hover': {
    backgroundColor: '$secondary3',
  },
  '&:active': {
    backgroundColor: '$secondary10',
  },
  variants: {
    light: {
      on: {
        color: '$white',
        backgroundColor: '$primary50',
        '&:hover': {
          backgroundColor: '$primary40',
        },
        '&:active': {
          backgroundColor: '$primary60',
        },
      },
      off: {},
    },
    variant: {
      number: {},
      arrow: {},
      dot: {
        cursor: 'default',
        '&:active': {
          backgroundColor: '$secondary3',
        },
      },
    },
  },
});

const Pagination = ({
  curPageOffset,
  pageLimit,
  pageOffset,
  pageTotal,
  setPageLimit,
  setPageOffset,
}: PaginationProps) => {
  if (typeof pageTotal !== 'number') {
    return <>{`Loading…`}</>;
  }

  // * Example: {`Showing {from} to {to} of {pageTotal} items`}
  // * const from = Math.min(pageOffset + 1, pageTotal);
  // * const to = Math.min(pageOffset + pageLimit, pageTotal);

  const pageCount = Math.ceil(pageTotal / pageLimit);
  const currentPage = pageOffset / pageLimit + 1;
  const highestPossibleOffset = pageLimit * (pageCount - 1);

  const getPages = () => {
    let pages: string[] = [];
    if (pageCount < 10) {
      return getRange(1, pageCount);
    } else {
      if (currentPage < 5) {
        pages = getRange(1, 7);
        pages.push('...');
        pages.push(pageCount.toString());
        return pages;
      } else if (currentPage === 5) {
        pages = getRange(1, 8);
        pages.push('...');
        pages.push(pageCount.toString());
        return pages;
      } else if (currentPage > pageCount - 4) {
        pages.push('1');
        pages.push('...');
        getRange(pageCount - 7 + 1, pageCount).forEach((number) => pages.push(number));
        return pages;
      } else if (currentPage === pageCount - 4) {
        pages.push('1');
        pages.push('...');
        getRange(pageCount - 7, pageCount).forEach((number) => pages.push(number));
        return pages;
      } else {
        pages.push('1');
        pages.push('...');
        getRange(currentPage - 3, currentPage + 3).forEach((number) => pages.push(number));
        pages.push('...');
        pages.push(pageCount.toString());
        return pages;
      }
    }
  };

  return (
    <Box css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mr: '10px' }}>
      {/* 如果要做 "Page per row" 可以做一個 Dropdown, Example:
        <Dropdown
          options={pageLimitOptions}
          value={pageLimitOptions.find((v) => v.value === pageLimit)}
          onChange={(v) => {
            setPageLimit(v?.value || 10);
            setPageOffset(0);
          }}
        />
      */}
      {pageTotal > 0 && (
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          {/* Back to Start, Left */}
          <PageContainer>
            <PageElement variant="arrow" onClick={() => setPageOffset({ setter: 0, newValue: 0 })}>
              <MSymbol iconName={`keyboard_double_arrow_left`} />
            </PageElement>
            <Divider axis="vertical" css={{ $$size: '30px', backgroundColor: '$secondary20' }} />
            <PageElement
              variant="arrow"
              onClick={() => setPageOffset({ setter: (prev) => Math.max(prev - pageLimit, 0) })}>
              <MSymbol iconName={`navigate_before`} />
            </PageElement>
          </PageContainer>

          {/* Pages */}
          <PageContainer>
            {getPages() &&
              getPages().length > 0 &&
              getPages().map((page, index) => {
                return (
                  <Box key={`page-${index}`} css={{ display: 'flex', alignItems: 'center' }}>
                    <PageElement
                      variant={page === '...' ? 'dot' : 'number'}
                      light={page === currentPage.toString() ? 'on' : 'off'}
                      onClick={() => {
                        if (page === '...') {
                          return;
                        }
                        setPageOffset({
                          setter: pageLimit * (parseInt(page) - 1),
                          newValue: pageLimit * (parseInt(page) - 1),
                        });
                      }}>
                      {page}
                    </PageElement>
                    {index === getPages().length - 1 ? null : (
                      <Divider
                        axis="vertical"
                        css={{ $$size: '30px', backgroundColor: '$secondary20' }}
                      />
                    )}
                  </Box>
                );
              })}
          </PageContainer>

          {/* Go to End, Right */}
          <PageContainer>
            <PageElement
              variant="arrow"
              onClick={() =>
                setPageOffset({
                  setter: (prev) => Math.min(prev + pageLimit, highestPossibleOffset),
                  newValue: Math.min(curPageOffset || 0 + pageLimit, highestPossibleOffset),
                })
              }>
              <MSymbol iconName={`navigate_next`} />
            </PageElement>
            <Divider axis="vertical" css={{ $$size: '30px', backgroundColor: '$secondary20' }} />
            <PageElement
              variant="arrow"
              onClick={() =>
                setPageOffset({ setter: highestPossibleOffset, newValue: highestPossibleOffset })
              }>
              <MSymbol iconName={`keyboard_double_arrow_right`} />
            </PageElement>
          </PageContainer>
        </Box>
      )}
    </Box>
  );
};

export default Pagination;
