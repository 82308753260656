import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isDealSelected } from '../../../../utils/check-validation';
import { getInitSpecialDeal, getInitSpecialDealYearlyValues } from '../../../../utils/get-variant';
import { DropdownOption } from '../../../../types';
import {
  PostGCPStdAppPayload,
  SpecialDealEntity,
  SpecialDealSubject,
  SpecialDealType,
  SpecialDealYear,
} from '../../../../types/application/constants';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postGCPStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import { yesNoOptions } from '../../../../store/constants';
import { specialDealOptions } from '../../../../store/application/constants';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import {
  useGetCurrencyOptions,
  useGetMonthOptions,
  useGetOfficialPricingDiscountOptions,
  useGetPaymentTermOptions,
  useGetSupportPlanOptions,
} from '../../../../services/application/verified-standard/dropdown.api';
import { scrollToFirstError, scrollToTop } from '../../../../utils/scroll';
import { motion } from 'framer-motion';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import RadioGroup from '../../../../components/field/radio-group';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import Combobox from '../../../../components/dropdown/combobox';
import Label from '../../../../components/typography/label';
import TextInputArea from '../../../../components/field/text-input-area';
import TextInput from '../../../../components/field/text-input';
import ChipSelect from '../../../../components/dropdown/chip-select';
import WizardBar from '../../../../components/bar/wizard-bar';
import CheckButton from '../../../../components/button/check-button';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionPaperHeader,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';
import IconButton from '../../../../components/button/icon-button';
import ServiceItemButton from '../../../../components/button/service-item-button';
import { fadeInOutMotion, fixedLayoutGrowMotion } from '../../../../styles/motions';
import LogoGoogleCloud from '../../../../assets/images/logo-google-cloud.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({
  payment_term: Yup.object().shape({
    value: Yup.number()
      .typeError('Must specify a number')
      .moreThan(0, 'Must be a positive number')
      .integer('Must be an integer')
      .required('This field is required'),
  }),
  official_pricing_discount_type: Yup.object().shape({
    value: Yup.string().required(),
  }),
  official_pricing_discount_value: Yup.number()
    .typeError('Must specify a number')
    .test('official_pricing_discount_value', '', (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      if (curOfficialPricingDiscountType === 'percentage_discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            parseFloat(value.toString()) <= 14 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number <= 14, up to 2 decimal places` })
        );
      } else if (curOfficialPricingDiscountType === 'fixed_amount_discount') {
        return (
          (!!value &&
            parseFloat(value.toString()) > 0 &&
            /^\d+(\.\d{1,2})?$/.test(value.toString())) ||
          context.createError({ message: `Positive number, up to 2 decimal places` })
        );
      } else {
        return true;
      }
    }),
  official_pricing_discount_currency: Yup.object().shape({
    value: Yup.string().test(
      'official_pricing_discount_currency',
      'This field is required',
      (value, context) => {
        const curOfficialPricingDiscountType =
          context.from?.[1].value.official_pricing_discount_type.value;
        return curOfficialPricingDiscountType === 'no_discount' || value !== '';
      }
    ),
  }),
  support_plan: Yup.object().shape({
    value: Yup.string().required(),
  }),
  estimated_mrr: Yup.number()
    .typeError('Must specify a number')
    .moreThan(0, 'Must be a positive number')
    .integer('Must be an integer')
    .required('This field is required'),
  is_issued_form: Yup.string().required(),
  reason_for_discount_rate: Yup.string().test(
    'reason_for_discount_rate',
    'When percentage discount rate is more than 3%, this field is required',
    (value, context) => {
      const curOfficialPricingDiscountType =
        context.from?.[0].value.official_pricing_discount_type.value;
      const curOfficialPricingDiscountValue =
        context.from?.[0].value.official_pricing_discount_value;
      return (
        curOfficialPricingDiscountType !== 'percentage_discount' ||
        curOfficialPricingDiscountValue <= 3 ||
        value !== ''
      );
    }
  ),
  special_deal: Yup.object().shape({
    edp: Yup.object().shape({
      period: Yup.object().shape({
        value: Yup.mixed().test('edp_period', 'This field is required', (value, context) => {
          const isSelected = isDealSelected({
            curSelectedDeals: context.from?.[2].value.selected_deals,
            deal: 'edp',
          });
          return !isSelected || !!value;
        }),
      }),
      cm_to_google: Yup.object().shape({
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('edp_c2g_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'edp',
            });
            return !isSelected || !!value;
          })
          .test(
            'edp_c2g_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'edp',
              });
              const curEdpC2GYearly = context.from?.[1].value.cm_to_google.yearly_values;
              const curEdpC2GYearlySum = Object.values(curEdpC2GYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curEdpC2GYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2g_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2g_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2g_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2g_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2g_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
      customer_to_cm: Yup.object().shape({
        currency: Yup.object().shape({
          value: Yup.mixed().test(
            'edp_c2c_currency',
            'This field is required',
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              return !isSelected || !!value;
            }
          ),
        }),
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('edp_c2c_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'edp',
            });
            return !isSelected || !!value;
          })
          .test(
            'edp_c2c_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'edp',
              });
              const curEdpC2CYearly = context.from?.[1].value.customer_to_cm?.yearly_values;
              const curEdpC2CYearlySum = Object.values(curEdpC2CYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curEdpC2CYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2c_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2c_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2c_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2c_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('edp_c2c_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'edp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
    }),
    cnsp: Yup.object().shape({
      period: Yup.object().shape({
        value: Yup.mixed().test('cnsp_period', 'This field is required', (value, context) => {
          const isSelected = isDealSelected({
            curSelectedDeals: context.from?.[2].value.selected_deals,
            deal: 'cnsp',
          });
          return !isSelected || !!value;
        }),
      }),
      cm_to_google: Yup.object().shape({
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('cnsp_c2g_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'cnsp',
            });
            return !isSelected || !!value;
          })
          .test(
            'cnsp_c2g_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'cnsp',
              });
              const curC2GYearly = context.from?.[1].value.cm_to_google.yearly_values;
              const curC2GYearlySum = Object.values(curC2GYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curC2GYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2g_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2g_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .test('cnsp_c2g_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2g_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2g_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
      customer_to_cm: Yup.object().shape({
        currency: Yup.object().shape({
          value: Yup.mixed().test(
            'cnsp_c2c_currency',
            'This field is required',
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              return !isSelected || !!value;
            }
          ),
        }),
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('cnsp_c2c_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'cnsp',
            });
            return !isSelected || !!value;
          })
          .test(
            'cnsp_c2c_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'cnsp',
              });
              const curC2CYearly = context.from?.[1].value.customer_to_cm?.yearly_values;
              const curC2CYearlySum = Object.values(curC2CYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curC2CYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2c_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2c_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2c_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2c_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cnsp_c2c_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cnsp',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
    }),
    google_full_b2b: Yup.object().shape({
      period: Yup.object().shape({
        value: Yup.mixed().test(
          'google_full_b2b_period',
          'This field is required',
          (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'google_full_b2b',
            });
            return !isSelected || !!value;
          }
        ),
      }),
      cm_to_google: Yup.object().shape({
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('google_full_b2b_c2g_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'google_full_b2b',
            });
            return !isSelected || !!value;
          })
          .test(
            'cnsp_c2g_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curC2GYearly = context.from?.[1].value.cm_to_google.yearly_values;
              const curC2GYearlySum = Object.values(curC2GYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curC2GYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2g_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2g_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2g_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2g_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2g_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
      customer_to_cm: Yup.object().shape({
        currency: Yup.object().shape({
          value: Yup.mixed().test(
            'google_full_b2b_c2c_currency',
            'This field is required',
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              return !isSelected || !!value;
            }
          ),
        }),
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('google_full_b2b_c2c_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'google_full_b2b',
            });
            return !isSelected || !!value;
          })
          .test(
            'google_full_b2b_c2c_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curC2CYearly = context.from?.[1].value.customer_to_cm?.yearly_values;
              const curC2CYearlySum = Object.values(curC2CYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curC2CYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2c_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2c_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2c_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2c_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('google_full_b2b_c2c_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'google_full_b2b',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
    }),
    cm_b2b_lite: Yup.object().shape({
      period: Yup.object().shape({
        value: Yup.mixed().test(
          'cm_b2b_lite_period',
          'This field is required',
          (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'cm_b2b_lite',
            });
            return !isSelected || !!value;
          }
        ),
      }),
      customer_to_cm: Yup.object().shape({
        currency: Yup.object().shape({
          value: Yup.mixed().test(
            'cm_b2b_lite_c2c_currency',
            'This field is required',
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              return !isSelected || !!value;
            }
          ),
        }),
        total_value: Yup.number()
          .typeError('Must specify a number')
          .moreThan(0, 'Must be a positive number')
          .integer('Must be an integer')
          .test('cm_b2b_lite_c2c_total', 'This field is required', (value, context) => {
            const isSelected = isDealSelected({
              curSelectedDeals: context.from?.[2].value.selected_deals,
              deal: 'cm_b2b_lite',
            });
            return !isSelected || !!value;
          })
          .test(
            'cnsp_c2c_total_sum',
            "Total doesn't match the sum of the yearly",
            (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              const curC2CYearly = context.from?.[1].value.customer_to_cm?.yearly_values;
              const curC2CYearlySum = Object.values(curC2CYearly).reduce((acc, cur) => {
                const num = cur !== undefined ? parseInt(cur as string, 10) : 0;
                return (acc as number) + num;
              }, 0);
              return isSelected ? value === curC2CYearlySum : true;
            }
          ),
        yearly_values: Yup.object().shape({
          '1st': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cm_b2b_lite_c2c_yr_1st', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              return !isSelected || !!value;
            }),
          '2nd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cm_b2b_lite_c2c_yr_2nd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 2 || !!value : true;
            }),
          '3rd': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cm_b2b_lite_c2c_yr_3rd', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 3 || !!value : true;
            }),
          '4th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cm_b2b_lite_c2c_yr_4th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 4 || !!value : true;
            }),
          '5th': Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('cm_b2b_lite_c2c_yr_5th', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[3].value.selected_deals,
                deal: 'cm_b2b_lite',
              });
              const curPeriod = context.from?.[2].value.period.value;
              return isSelected ? curPeriod / 12 < 5 || !!value : true;
            }),
        }),
      }),
    }),
    flex: Yup.object().shape({
      period: Yup.object().shape({
        value: Yup.mixed().test('cnsp_period', 'This field is required', (value, context) => {
          const isSelected = isDealSelected({
            curSelectedDeals: context.from?.[2].value.selected_deals,
            deal: 'flex',
          });
          return !isSelected || !!value;
        }),
      }),
      customer_to_cm_currency: Yup.object().shape({
        value: Yup.mixed().test('flex_c2c_currency', 'This field is required', (value, context) => {
          const isSelected = isDealSelected({
            curSelectedDeals: context.from?.[2].value.selected_deals,
            deal: 'flex',
          });
          return !isSelected || !!value;
        }),
      }),
      milestone_credit_pairs: Yup.array().of(
        Yup.object().shape({
          cm_to_google_milestone: Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('flex_c2g_mc_pair_m', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'flex',
              });
              return !isSelected || !!value;
            }),
          cm_to_google_credit: Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('flex_c2g_mc_pair_c', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'flex',
              });
              return !isSelected || !!value;
            }),
          customer_to_cm_milestone: Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('flex_c2c_milestone', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'flex',
              });
              return !isSelected || !!value;
            }),
          customer_to_cm_credit: Yup.number()
            .typeError('Must specify a number')
            .moreThan(0, 'Must be a positive number')
            .integer('Must be an integer')
            .test('flex_c2c_credit', 'This field is required', (value, context) => {
              const isSelected = isDealSelected({
                curSelectedDeals: context.from?.[2].value.selected_deals,
                deal: 'flex',
              });
              return !isSelected || !!value;
            }),
        })
      ),
    }),
  }),
  memo: Yup.string().optional(),
});

const ServiceInfo = () => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postGCPStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);

  const { control, handleSubmit, getValues, setValue, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const curOfficialPricingDiscountType = useWatch({
    control,
    name: 'official_pricing_discount_type',
  });
  const curOfficialPricingDiscountValue = useWatch({
    control,
    name: 'official_pricing_discount_value',
  });
  const curSelectedDeals = useWatch({
    control,
    name: 'special_deal.selected_deals',
  });
  const curEDPPeriod = useWatch({
    control,
    name: 'special_deal.edp.period.value',
  });
  const curCNSPPeriod = useWatch({
    control,
    name: 'special_deal.cnsp.period.value',
  });
  const curGoogleFullB2BPeriod = useWatch({
    control,
    name: 'special_deal.google_full_b2b.period.value',
  });
  const curCMB2BLitePeriod = useWatch({
    control,
    name: 'special_deal.cm_b2b_lite.period.value',
  });
  const curEDPC2CCurrency = useWatch({
    control,
    name: 'special_deal.edp.customer_to_cm.currency',
  });
  const curCNSPC2CCurrency = useWatch({
    control,
    name: 'special_deal.cnsp.customer_to_cm.currency',
  });
  const curGoogleFullB2BC2CCurrency = useWatch({
    control,
    name: 'special_deal.google_full_b2b.customer_to_cm.currency',
  });
  const curCMB2BLiteC2CCurrency = useWatch({
    control,
    name: 'special_deal.cm_b2b_lite.customer_to_cm.currency',
  });
  const curFlexC2CCurrency = useWatch({
    control,
    name: 'special_deal.flex.customer_to_cm_currency',
  });
  const {
    fields: specialDealFlexPairFields,
    append: specialDealFlexCMToGoogleAppend,
    remove: specialDealFlexCMToGoogleRemove,
  } = useFieldArray<PostGCPStdAppPayload>({
    control,
    name: 'special_deal.flex.milestone_credit_pairs',
  });

  const paymentTermOptionsQuery = useGetPaymentTermOptions();
  const officialPricingDiscountOptionsQuery = useGetOfficialPricingDiscountOptions();
  const officialPricingDiscountCurrencyOptionsQuery = useGetCurrencyOptions({
    currencyType: 'official_pricing_discount_currencies',
    cmLegalEntity: getValues('cm_legal_entity.value'),
    exchangeRate: getValues('exchange_rate.value'),
  });
  const commitmentPeriodOptionsQuery = useGetMonthOptions({ field: 'commitment_period' });
  const specialDealC2CCurrencyOptionsQuery = useGetCurrencyOptions({
    currencyType: 'customer_to_cm_currencies',
    cmLegalEntity: getValues('cm_legal_entity.value'),
    exchangeRate: getValues('exchange_rate.value'),
  });
  const supportPlanOptionsQuery = useGetSupportPlanOptions({ product: getValues('product') });
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
      } else {
        if (appStatus === 'draft') {
          postAppMutation.mutate({ ...getValues(), ...res, status: appStatus });
        } else {
          postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
        }
      }
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onOfficialPricingDiscountTypeChange = (newValue: {
    name: string;
    value: DropdownOption['value'];
  }) => {
    setValue('official_pricing_discount_type', newValue, { shouldValidate: true });
    setValue('official_pricing_discount_value', 0, { shouldValidate: true });
  };
  const onSpecialDealNoneChecked = (newValue: boolean) => {
    if (newValue === true) {
      setValue('special_deal.selected_deals', []);
      specialDealOptions.forEach((deal) => {
        setValue(
          `special_deal.${deal.value}`,
          getInitSpecialDeal({ deal: deal.value as SpecialDealType }),
          {
            shouldValidate: true,
          }
        );
      });
    }
  };
  const onSpecialDealChecked = (options: { selectedDeal: SpecialDealType; isChecked: boolean }) => {
    const selectedDealIndex = curSelectedDeals.findIndex(
      (specialDeal: SpecialDealEntity) => specialDeal.value === options.selectedDeal
    );
    if (options.isChecked === true) {
      setValue('special_deal.selected_deals', [
        ...curSelectedDeals,
        { value: options.selectedDeal },
      ]);
    }
    if (options.isChecked === false) {
      setValue('special_deal.selected_deals', [
        ...curSelectedDeals.slice(0, selectedDealIndex),
        ...curSelectedDeals.slice(selectedDealIndex + 1),
      ]);
      setValue(
        `special_deal.${options.selectedDeal}`,
        getInitSpecialDeal({ deal: options.selectedDeal as SpecialDealType }),
        {
          shouldValidate: true,
        }
      );
    }
  };
  const onPeriodChange = (
    newValue: {
      name: string;
      value: DropdownOption['value'];
    },
    options: { deal: Exclude<SpecialDealType, 'none' | 'flex'> }
  ) => {
    setValue(`special_deal.${options.deal}.period`, newValue);
    setValue(
      `special_deal.${options.deal}.cm_to_google.yearly_values`,
      getInitSpecialDealYearlyValues(),
      { shouldValidate: true }
    );
    setValue(
      `special_deal.${options.deal}.customer_to_cm.yearly_values`,
      getInitSpecialDealYearlyValues(),
      { shouldValidate: true }
    );
  };
  const onYearlyValueChange = (
    newValue: DropdownOption['value'] | undefined,
    options: {
      subject: SpecialDealSubject;
      deal: SpecialDealType;
      year: SpecialDealYear;
    }
  ) => {
    setValue(
      `special_deal.${options.deal}.${options.subject}.yearly_values.${options.year}`,
      newValue,
      {
        shouldValidate: true,
      }
    );
    trigger(`special_deal.${options.deal}.${options.subject}.total_value`);
  };

  const onBackClick = () => {
    setPostStdAppPayload((prev) => ({ ...prev, ...getValues() }));
    setCurSubFormStep(1);
  };
  const onNextClick: SubmitHandler<PostGCPStdAppPayload> = (data) => {
    setPostStdAppPayload((prev) => ({ ...prev, ...data }));
    setCurSubFormStep(3);
  };
  const onNextError = (errors: unknown) => {
    console.log(errors);
    scrollToFirstError(errors);
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? getValues('status') : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    if (
      officialPricingDiscountCurrencyOptionsQuery.isSuccess &&
      officialPricingDiscountCurrencyOptionsQuery.data.length > 0
    ) {
      if (!getValues('official_pricing_discount_currency.value')) {
        setValue(
          'official_pricing_discount_currency',
          {
            name: officialPricingDiscountCurrencyOptionsQuery.data[0].name,
            value: officialPricingDiscountCurrencyOptionsQuery.data[0].value,
          },
          { shouldValidate: true }
        );
      }
    }
  }, [
    officialPricingDiscountCurrencyOptionsQuery.data,
    officialPricingDiscountCurrencyOptionsQuery.isSuccess,
    getValues,
    setValue,
  ]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onNextClick, onNextError)}>
      {/* Service */}
      <SubmissionPaper>
        {/* Header */}
        <SubmissionPaperHeader>
          <Heading5>Service</Heading5>
          <ColorfulChip text="GCP" color="sky" variant="solid" />
        </SubmissionPaperHeader>
        {/* Payment Details */}
        <SubmissionSection>
          <SubmissionSectionHeader>
            <Text semibold>Payment Details</Text>
          </SubmissionSectionHeader>
          <ServiceItemButton type="button" data-state="active" showcase>
            <Box as="img" src={LogoGoogleCloud} css={{ py: 14 }} />
          </ServiceItemButton>
          <SubmissionRow>
            {/* Payment Term */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="payment_term"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Payment Term"
                    placeholder="Select a payment term"
                    isLoading={paymentTermOptionsQuery.isLoading}
                    options={paymentTermOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Official Pricing Discount */}
            <SubmissionColumn>
              <Label>
                <Caption>
                  Official Pricing Discount
                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                </Caption>
              </Label>
              <SubmissionRow columnGapSize="5">
                {/* Official Pricing Discount Type  */}
                <Box css={{ width: `calc(50% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_type"
                    render={({ field: { value, name }, fieldState: { invalid, error } }) => (
                      <Combobox
                        placeholder="Select a discount type"
                        isLoading={officialPricingDiscountOptionsQuery.isLoading}
                        options={officialPricingDiscountOptionsQuery.data || []}
                        optionSize="lg"
                        fieldName={name}
                        value={value}
                        onChange={onOfficialPricingDiscountTypeChange}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Value  */}
                <Box css={{ width: `calc(30% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_value"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <TextInput
                        placeholder="Enter a discount value"
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'no_discount'}
                        isError={invalid}
                        error={error?.message}
                        endElement={
                          curOfficialPricingDiscountType.value === 'percentage_discount' ? (
                            <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                              %
                            </ButtonText>
                          ) : null
                        }
                      />
                    )}
                  />
                </Box>
                {/* Official Pricing Discount Currency */}
                <Box css={{ width: `calc(20% - ${5 * (5 / 3)}px)` }}>
                  <Controller
                    control={control}
                    name="official_pricing_discount_currency"
                    render={({
                      field: { onChange, value, name },
                      fieldState: { invalid, error },
                    }) => (
                      <ChipSelect
                        placeholder="Select a discount currency"
                        isLoading={officialPricingDiscountCurrencyOptionsQuery.isLoading}
                        options={officialPricingDiscountCurrencyOptionsQuery.data || []}
                        fieldName={name}
                        value={value}
                        onChange={onChange}
                        disabled={curOfficialPricingDiscountType.value === 'no_discount'}
                        isError={invalid}
                        error={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </Box>
              </SubmissionRow>
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Support Plan */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="support_plan"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <Combobox
                    required
                    label="Support Plan"
                    placeholder="Select a support plan"
                    isLoading={supportPlanOptionsQuery.isLoading}
                    options={supportPlanOptionsQuery.data || []}
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error ? 'This field is required' : ''}
                  />
                )}
              />
            </SubmissionColumn>
            {/* Estimated MRR */}
            <SubmissionColumn>
              <Controller
                control={control}
                name="estimated_mrr"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInput
                    required
                    label="Estimated MRR"
                    placeholder="Enter an estimated MRR"
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                    endElement={
                      <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                        USD
                      </ButtonText>
                    }
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Will customer issue PO / LOA / Order Form / other ? */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="is_issued_form"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    required
                    label="Will customer issue PO / LOA / Order Form / other ?"
                    value={value}
                    options={yesNoOptions}
                    onChange={onChange}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Reason for Discount Rate */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="reason_for_discount_rate"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    required={
                      curOfficialPricingDiscountType.value === 'percentage_discount' &&
                      curOfficialPricingDiscountValue > 3
                    }
                    label="Reason for Discount Rate"
                    placeholder="Let us know if the discount rate is more than 3% and also can leave the necessary verification information."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
              <Box
                as={motion.div}
                initial={'hide'}
                variants={fadeInOutMotion}
                animate={
                  curOfficialPricingDiscountType.value === 'percentage_discount' &&
                  curOfficialPricingDiscountValue > 3
                    ? 'show'
                    : 'hide'
                }>
                <Spacer axis="vertical" css={{ $$size: '8px' }} />
                <Box
                  css={{
                    borderRadius: 6,
                    border: '1px solid $warning60',
                    backgroundColor: '$warning10',
                    px: 10,
                    py: 4,
                  }}>
                  <ButtonText size={12} css={{ color: '$warning90' }}>
                    If the discount rate exceeds 3%, it should be reviewed by the GM initially. If
                    it surpasses 8%, the CEO will need to review it.
                  </ButtonText>
                </Box>
              </Box>
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Special Deal */}
            <SubmissionColumn widthRatio={'10'}>
              <Label>
                <Caption>
                  {`Special Deal`}
                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                </Caption>
              </Label>
              {/* Special Deal Selected Deals */}
              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10, my: 20 }}>
                <CheckButton
                  buttonText={'None'}
                  checked={curSelectedDeals.length <= 0}
                  onChecked={(newValue) => onSpecialDealNoneChecked(newValue)}
                />
                {specialDealOptions.map((specialDeal) => (
                  <CheckButton
                    key={specialDeal.id}
                    buttonText={specialDeal.name}
                    checked={curSelectedDeals.some(
                      (curSpecialDeal: SpecialDealEntity) =>
                        curSpecialDeal.value === specialDeal.value
                    )}
                    onChecked={(newValue) =>
                      onSpecialDealChecked({
                        selectedDeal: specialDeal.value as SpecialDealType,
                        isChecked: newValue,
                      })
                    }
                  />
                ))}
              </Box>
              {/* Special Deal Sections */}
              {curSelectedDeals.length > 0 ? (
                <Box
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 40,
                    borderRadius: 10,
                    p: 10,
                    backgroundColor: '$achromatic3',
                  }}>
                  {/* Special Deal EDP */}
                  {curSelectedDeals.some(
                    (curSpecialDeal: SpecialDealEntity) => curSpecialDeal.value === 'edp'
                  ) ? (
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                      }}>
                      <ColorfulChip text="EDP" variant="outlined" color="sky" />
                      <SubmissionRow>
                        {/* Special Deal EDP Commitment Period */}
                        <SubmissionColumn>
                          <Controller
                            control={control}
                            name="special_deal.edp.period"
                            render={({
                              field: { value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                required
                                label="Commitment Period"
                                placeholder="Select a commitment period"
                                isLoading={commitmentPeriodOptionsQuery.isLoading}
                                options={commitmentPeriodOptionsQuery.data || []}
                                fieldName={name}
                                value={value}
                                onChange={(newValue) => {
                                  onPeriodChange(newValue, { deal: 'edp' });
                                }}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal EDP [CM to Google] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[CM to Google] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6, alignItems: 'flex-start' }}>
                          {/* Special Deal EDP [CM to Google] Total */}
                          <SubmissionColumn css={{ alignItems: 'flex-start' }}>
                            <Controller
                              control={control}
                              name="special_deal.edp.cm_to_google.total_value"
                              render={({
                                field: { onChange, value, name },
                                fieldState: { invalid, error },
                              }) => (
                                <TextInput
                                  required
                                  placeholder="Enter an amount"
                                  fieldName={name}
                                  value={value}
                                  onChange={onChange}
                                  isError={invalid}
                                  error={error?.message}
                                  startElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', ml: 8 }}>
                                      Total
                                    </ButtonText>
                                  }
                                  endElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                                      USD
                                    </ButtonText>
                                  }
                                />
                              )}
                            />
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [CM to Google] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.cm_to_google.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'cm_to_google',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal EDP [CM to Google] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.cm_to_google.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'cm_to_google',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 2}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow css={{ alignItems: 'flex-start' }}>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [CM to Google] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.cm_to_google.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'cm_to_google',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 3}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal EDP [CM to Google] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.cm_to_google.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'cm_to_google',
                                        year: '4th',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 4}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [CM to Google] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.cm_to_google.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'cm_to_google',
                                        year: '5th',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 5}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                      {/* Special Deal EDP [Customer to CM] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[Customer to CM] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6, alignItems: 'flex-start' }}>
                          <SubmissionColumn>
                            {/* Special Deal EDP [Customer to CM] Total */}
                            <SubmissionRow columnGapSize="10">
                              <Box css={{ width: `calc(80% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.edp.customer_to_cm.total_value"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Total
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </Box>
                              {/* Special Deal EDP [Customer to CM] Currency */}
                              <Box css={{ width: `calc(20% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.edp.customer_to_cm.currency"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <ChipSelect
                                      placeholder="Currency"
                                      isLoading={specialDealC2CCurrencyOptionsQuery.isLoading}
                                      options={specialDealC2CCurrencyOptionsQuery.data || []}
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error ? 'This field is required' : ''}
                                    />
                                  )}
                                />
                              </Box>
                            </SubmissionRow>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [Customer to CM] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.customer_to_cm.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'customer_to_cm',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curEDPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal EDP [Customer to CM] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.customer_to_cm.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'customer_to_cm',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 2}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curEDPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow css={{ alignItems: 'flex-start' }}>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [Customer to CM] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.customer_to_cm.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'customer_to_cm',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 3}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curEDPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal EDP [Customer to CM] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.customer_to_cm.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'customer_to_cm',
                                        year: '4th',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 4}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curEDPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal EDP [Customer to CM] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.edp.customer_to_cm.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'edp',
                                        subject: 'customer_to_cm',
                                        year: '5th',
                                      })
                                    }
                                    disabled={!curEDPPeriod || curEDPPeriod / 12 < 5}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curEDPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                    </Box>
                  ) : null}
                  {/* Special Deal CNSP */}
                  {curSelectedDeals.some(
                    (curSpecialDeal: SpecialDealEntity) => curSpecialDeal.value === 'cnsp'
                  ) ? (
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                      }}>
                      <ColorfulChip text="CNSP" variant="outlined" color="sky" />
                      <SubmissionRow>
                        {/* Special Deal CNSP Commitment Period */}
                        <SubmissionColumn>
                          <Controller
                            control={control}
                            name="special_deal.cnsp.period"
                            render={({
                              field: { value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                required
                                label="Commitment Period"
                                placeholder="Select a commitment period"
                                isLoading={commitmentPeriodOptionsQuery.isLoading}
                                options={commitmentPeriodOptionsQuery.data || []}
                                fieldName={name}
                                value={value}
                                onChange={(newValue) => {
                                  onPeriodChange(newValue, { deal: 'cnsp' });
                                }}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal CNSP [CM to Google] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[CM to Google] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6, alignItems: 'flex-start' }}>
                          {/* Special Deal CNSP [CM to Google] Total */}
                          <SubmissionColumn>
                            <Controller
                              control={control}
                              name="special_deal.cnsp.cm_to_google.total_value"
                              render={({
                                field: { onChange, value, name },
                                fieldState: { invalid, error },
                              }) => (
                                <TextInput
                                  required
                                  placeholder="Enter an amount"
                                  fieldName={name}
                                  value={value}
                                  onChange={onChange}
                                  isError={invalid}
                                  error={error?.message}
                                  startElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', ml: 8 }}>
                                      Total
                                    </ButtonText>
                                  }
                                  endElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                                      USD
                                    </ButtonText>
                                  }
                                />
                              )}
                            />
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [CM to Google] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.cm_to_google.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'cm_to_google',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CNSP [CM to Google] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.cm_to_google.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'cm_to_google',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 2}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow css={{ alignItems: 'flex-start' }}>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [CM to Google] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.cm_to_google.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'cm_to_google',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 3}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CNSP [CM to Google] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.cm_to_google.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'cm_to_google',
                                        year: '4th',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 4}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [CM to Google] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.cm_to_google.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'cm_to_google',
                                        year: '5th',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 5}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                      {/* Special Deal CNSP [Customer to CM] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[Customer to CM] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6, alignItems: 'flex-start' }}>
                          <SubmissionColumn>
                            {/* Special Deal CNSP [Customer to CM] Total */}
                            <SubmissionRow columnGapSize="10">
                              <Box css={{ width: `calc(80% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.cnsp.customer_to_cm.total_value"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Total
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </Box>
                              {/* Special Deal EDP [Customer to CM] Currency */}
                              <Box css={{ width: `calc(20% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.cnsp.customer_to_cm.currency"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <ChipSelect
                                      placeholder="Currency"
                                      isLoading={specialDealC2CCurrencyOptionsQuery.isLoading}
                                      options={specialDealC2CCurrencyOptionsQuery.data || []}
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error ? 'This field is required' : ''}
                                    />
                                  )}
                                />
                              </Box>
                            </SubmissionRow>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [Customer to CM] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.customer_to_cm.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'customer_to_cm',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCNSPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CNSP [Customer to CM] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.customer_to_cm.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'customer_to_cm',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 2}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCNSPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow css={{ alignItems: 'flex-start' }}>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [Customer to CM] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.customer_to_cm.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'customer_to_cm',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 3}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCNSPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CNSP [Customer to CM] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.customer_to_cm.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'customer_to_cm',
                                        year: '4th',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 4}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCNSPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CNSP [Customer to CM] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cnsp.customer_to_cm.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cnsp',
                                        subject: 'customer_to_cm',
                                        year: '5th',
                                      })
                                    }
                                    disabled={!curCNSPPeriod || curCNSPPeriod / 12 < 5}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCNSPC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                    </Box>
                  ) : null}
                  {/* Special Deal Google Full B2B */}
                  {curSelectedDeals.some(
                    (curSpecialDeal: SpecialDealEntity) =>
                      curSpecialDeal.value === 'google_full_b2b'
                  ) ? (
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                      }}>
                      <ColorfulChip text="Google Full B2B" variant="outlined" color="sky" />
                      <SubmissionRow>
                        {/* Special Deal Google Full B2B Commitment Period */}
                        <SubmissionColumn>
                          <Controller
                            control={control}
                            name="special_deal.google_full_b2b.period"
                            render={({
                              field: { value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                required
                                label="Commitment Period"
                                placeholder="Select a commitment period"
                                isLoading={commitmentPeriodOptionsQuery.isLoading}
                                options={commitmentPeriodOptionsQuery.data || []}
                                fieldName={name}
                                value={value}
                                onChange={(newValue) => {
                                  onPeriodChange(newValue, { deal: 'google_full_b2b' });
                                }}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal Google Full B2B [CM to Google] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[CM to Google] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6 }}>
                          {/* Special Deal Google Full B2B [CM to Google] Total */}
                          <SubmissionColumn>
                            <Controller
                              control={control}
                              name="special_deal.google_full_b2b.cm_to_google.total_value"
                              render={({
                                field: { onChange, value, name },
                                fieldState: { invalid, error },
                              }) => (
                                <TextInput
                                  required
                                  placeholder="Enter an amount"
                                  fieldName={name}
                                  value={value}
                                  onChange={onChange}
                                  isError={invalid}
                                  error={error?.message}
                                  startElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', ml: 8 }}>
                                      Total
                                    </ButtonText>
                                  }
                                  endElement={
                                    <ButtonText size={14} css={{ color: '$text-secondary', mr: 8 }}>
                                      USD
                                    </ButtonText>
                                  }
                                />
                              )}
                            />
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [CM to Google] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.cm_to_google.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'cm_to_google',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal Google Full B2B [CM to Google] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.cm_to_google.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'cm_to_google',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 2
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [CM to Google] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.cm_to_google.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'cm_to_google',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 3
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal Google Full B2B [CM to Google] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.cm_to_google.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'cm_to_google',
                                        year: '4th',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 4
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [CM to Google] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.cm_to_google.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'cm_to_google',
                                        year: '5th',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 5
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        USD
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                      {/* Special Deal Google Full B2B [Customer to CM] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[Customer to CM] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6 }}>
                          <SubmissionColumn>
                            {/* Special Deal Google Full B2B [Customer to CM] Total */}
                            <SubmissionRow columnGapSize="10">
                              <Box css={{ width: `calc(80% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.google_full_b2b.customer_to_cm.total_value"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Total
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </Box>
                              {/* Special Deal Google Full B2B [Customer to CM] Currency */}
                              <Box css={{ width: `calc(20% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.google_full_b2b.customer_to_cm.currency"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <ChipSelect
                                      placeholder="Currency"
                                      isLoading={specialDealC2CCurrencyOptionsQuery.isLoading}
                                      options={specialDealC2CCurrencyOptionsQuery.data || []}
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error ? 'This field is required' : ''}
                                    />
                                  )}
                                />
                              </Box>
                            </SubmissionRow>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [Customer to CM] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.customer_to_cm.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'customer_to_cm',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curGoogleFullB2BC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal Google Full B2B [Customer to CM] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.customer_to_cm.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'customer_to_cm',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 2
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curGoogleFullB2BC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [Customer to CM] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.customer_to_cm.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'customer_to_cm',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 3
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curGoogleFullB2BC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal Google Full B2B [Customer to CM] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.customer_to_cm.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'customer_to_cm',
                                        year: '4th',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 4
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curGoogleFullB2BC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal Google Full B2B [Customer to CM] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.google_full_b2b.customer_to_cm.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'google_full_b2b',
                                        subject: 'customer_to_cm',
                                        year: '5th',
                                      })
                                    }
                                    disabled={
                                      !curGoogleFullB2BPeriod || curGoogleFullB2BPeriod / 12 < 5
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curGoogleFullB2BC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                    </Box>
                  ) : null}
                  {/* Special Deal CM B2B Lite */}
                  {curSelectedDeals.some(
                    (curSpecialDeal: SpecialDealEntity) => curSpecialDeal.value === 'cm_b2b_lite'
                  ) ? (
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                      }}>
                      <ColorfulChip text="CM B2B Lite" variant="outlined" color="sky" />
                      <SubmissionRow>
                        {/* Special Deal CM B2B Lite Commitment Period */}
                        <SubmissionColumn>
                          <Controller
                            control={control}
                            name="special_deal.cm_b2b_lite.period"
                            render={({
                              field: { value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                required
                                label="Commitment Period"
                                placeholder="Select a commitment period"
                                isLoading={commitmentPeriodOptionsQuery.isLoading}
                                options={commitmentPeriodOptionsQuery.data || []}
                                fieldName={name}
                                value={value}
                                onChange={(newValue) => {
                                  onPeriodChange(newValue, { deal: 'cm_b2b_lite' });
                                }}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal CM B2B Lite [Customer to CM] Commitment Amount / Per Year */}
                      <Box>
                        <Label>
                          <Caption>
                            {`[Customer to CM] Commitment Amount / Per Year`}
                            <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                          </Caption>
                        </Label>
                        <SubmissionRow css={{ mb: 6 }}>
                          <SubmissionColumn>
                            {/* Special Deal CM B2B Lite [Customer to CM] Total */}
                            <SubmissionRow columnGapSize="10">
                              <Box css={{ width: `calc(80% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.cm_b2b_lite.customer_to_cm.total_value"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Total
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </Box>
                              {/* Special Deal CM B2B Lite [Customer to CM] Currency */}
                              <Box css={{ width: `calc(20% - ${10 / 2}px)` }}>
                                <Controller
                                  control={control}
                                  name="special_deal.cm_b2b_lite.customer_to_cm.currency"
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <ChipSelect
                                      placeholder="Currency"
                                      isLoading={specialDealC2CCurrencyOptionsQuery.isLoading}
                                      options={specialDealC2CCurrencyOptionsQuery.data || []}
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error ? 'This field is required' : ''}
                                    />
                                  )}
                                />
                              </Box>
                            </SubmissionRow>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CM B2B Lite [Customer to CM] 1st Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cm_b2b_lite.customer_to_cm.yearly_values.1st"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cm_b2b_lite',
                                        subject: 'customer_to_cm',
                                        year: '1st',
                                      })
                                    }
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        1st yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCMB2BLiteC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CM B2B Lite [Customer to CM] 2nd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cm_b2b_lite.customer_to_cm.yearly_values.2nd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cm_b2b_lite',
                                        subject: 'customer_to_cm',
                                        year: '2nd',
                                      })
                                    }
                                    disabled={!curCMB2BLitePeriod || curCMB2BLitePeriod / 12 < 2}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        2nd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCMB2BLiteC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                        <SubmissionRow>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CM B2B Lite [Customer to CM] 3rd Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cm_b2b_lite.customer_to_cm.yearly_values.3rd"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cm_b2b_lite',
                                        subject: 'customer_to_cm',
                                        year: '3rd',
                                      })
                                    }
                                    disabled={!curCMB2BLitePeriod || curCMB2BLitePeriod / 12 < 3}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        3rd yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCMB2BLiteC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                            {/* Special Deal CM B2B Lite [Customer to CM] 4th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cm_b2b_lite.customer_to_cm.yearly_values.4th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cm_b2b_lite',
                                        subject: 'customer_to_cm',
                                        year: '4th',
                                      })
                                    }
                                    disabled={!curCMB2BLitePeriod || curCMB2BLitePeriod / 12 < 4}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        4th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCMB2BLiteC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                          <SubmissionColumn
                            css={{ display: 'flex', alignItems: 'flex-start', columnGap: 10 }}>
                            {/* Special Deal CM B2B Lite [Customer to CM] 5th Year */}
                            <Box css={{ width: 'calc(50% - 5px)' }}>
                              <Controller
                                control={control}
                                name="special_deal.cm_b2b_lite.customer_to_cm.yearly_values.5th"
                                render={({
                                  field: { value, name },
                                  fieldState: { invalid, error },
                                }) => (
                                  <TextInput
                                    required
                                    placeholder="Enter an amount"
                                    fieldName={name}
                                    value={value}
                                    onChange={(newValue) =>
                                      onYearlyValueChange(newValue, {
                                        deal: 'cm_b2b_lite',
                                        subject: 'customer_to_cm',
                                        year: '5th',
                                      })
                                    }
                                    disabled={!curCMB2BLitePeriod || curCMB2BLitePeriod / 12 < 5}
                                    isError={invalid}
                                    error={error?.message}
                                    startElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', ml: 8 }}>
                                        5th yr
                                      </ButtonText>
                                    }
                                    endElement={
                                      <ButtonText
                                        size={14}
                                        css={{ color: '$text-secondary', mr: 8 }}>
                                        {`${curCMB2BLiteC2CCurrency?.name || '-'}`}
                                      </ButtonText>
                                    }
                                  />
                                )}
                              />
                            </Box>
                          </SubmissionColumn>
                        </SubmissionRow>
                      </Box>
                    </Box>
                  ) : null}
                  {/* Special Deal Flex */}
                  {curSelectedDeals.some(
                    (curSpecialDeal: SpecialDealEntity) => curSpecialDeal.value === 'flex'
                  ) ? (
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                      }}>
                      <ColorfulChip text="Flex" variant="outlined" color="sky" />
                      <SubmissionRow>
                        {/* Special Deal Flex Commitment Period */}
                        <SubmissionColumn>
                          <Controller
                            control={control}
                            name="special_deal.flex.period"
                            render={({
                              field: { onChange, value, name },
                              fieldState: { invalid, error },
                            }) => (
                              <Combobox
                                required
                                label="Commitment Period"
                                placeholder="Select a commitment period"
                                isLoading={commitmentPeriodOptionsQuery.isLoading}
                                options={commitmentPeriodOptionsQuery.data || []}
                                fieldName={name}
                                value={value}
                                onChange={onChange}
                                isError={invalid}
                                error={error ? 'This field is required' : ''}
                              />
                            )}
                          />
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Special Deal Flex [CM to Google and Customer to CM] Consumption Milestone and corresponding Service Credit */}
                      {specialDealFlexPairFields.map((field, index) => (
                        <SubmissionRow key={field.id}>
                          <SubmissionColumn
                            widthRatio="10"
                            css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                            {/* Special Deal Flex [CM to Google] indexes */}
                            <CenteredBoxFlex css={{ height: 30, width: 20, mt: 24 }}>
                              <Text>{`${index + 1}`}</Text>
                            </CenteredBoxFlex>
                            <SubmissionRow columnGapSize={'10'} css={{ flex: 1, flexWrap: 'wrap' }}>
                              {/* Special Deal Flex [CM to Google] Milestone */}
                              <Label>
                                <Caption>
                                  {`[CM to Google] Consumption Milestone and corresponding Service Credit`}
                                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                                </Caption>
                              </Label>
                              <SubmissionColumn columnGapSize={'10'}>
                                <Controller
                                  control={control}
                                  name={`special_deal.flex.milestone_credit_pairs.${index}.cm_to_google_milestone`}
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Milestone
                                        </ButtonText>
                                      }
                                      endElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', mr: 8 }}>
                                          USD
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </SubmissionColumn>
                              {/* Special Deal Flex [CM to Google] Credit Amount */}
                              <SubmissionColumn columnGapSize={'10'}>
                                <Controller
                                  control={control}
                                  name={`special_deal.flex.milestone_credit_pairs.${index}.cm_to_google_credit`}
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Credit Amount
                                        </ButtonText>
                                      }
                                      endElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', mr: 8 }}>
                                          USD
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </SubmissionColumn>
                              <Label>
                                <Caption>
                                  {`[Customer to CM] Consumption Milestone and corresponding Service Credit`}
                                  <Caption css={{ color: '$error50' }}>{` *`}</Caption>
                                </Caption>
                              </Label>
                              {/* Special Deal Flex [Customer to CM] Milestone */}
                              <SubmissionColumn
                                columnGapSize={'10'}
                                css={{ display: 'flex', columnGap: 5 }}>
                                <Box css={{ width: `calc(80% - ${5 / 2}px)` }}>
                                  <Controller
                                    control={control}
                                    name={`special_deal.flex.milestone_credit_pairs.${index}.customer_to_cm_milestone`}
                                    render={({
                                      field: { onChange, value, name },
                                      fieldState: { invalid, error },
                                    }) => (
                                      <TextInput
                                        required
                                        placeholder="Enter an amount"
                                        fieldName={name}
                                        value={value}
                                        onChange={onChange}
                                        isError={invalid}
                                        error={error?.message}
                                        startElement={
                                          <ButtonText
                                            size={14}
                                            css={{ color: '$text-secondary', ml: 8 }}>
                                            Milestone
                                          </ButtonText>
                                        }
                                      />
                                    )}
                                  />
                                </Box>
                                {/* Special Deal Flex [Customer to CM] Currency */}
                                <Box css={{ width: `calc(20% - ${5 / 2}px)` }}>
                                  <Controller
                                    control={control}
                                    name={`special_deal.flex.customer_to_cm_currency`}
                                    render={({
                                      field: { onChange, value, name },
                                      fieldState: { invalid, error },
                                    }) => (
                                      <ChipSelect
                                        placeholder="Currency"
                                        isLoading={specialDealC2CCurrencyOptionsQuery.isLoading}
                                        options={specialDealC2CCurrencyOptionsQuery.data || []}
                                        fieldName={name}
                                        value={value}
                                        onChange={onChange}
                                        isError={invalid}
                                        error={error ? 'Required' : ''}
                                        disabled={index !== 0}
                                      />
                                    )}
                                  />
                                </Box>
                              </SubmissionColumn>
                              {/* Special Deal Flex [Customer to CM] Credit */}
                              <SubmissionColumn columnGapSize={'10'}>
                                <Controller
                                  control={control}
                                  name={`special_deal.flex.milestone_credit_pairs.${index}.customer_to_cm_credit`}
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <TextInput
                                      required
                                      placeholder="Enter an amount"
                                      fieldName={name}
                                      value={value}
                                      onChange={onChange}
                                      isError={invalid}
                                      error={error?.message}
                                      startElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', ml: 8 }}>
                                          Credit Amount
                                        </ButtonText>
                                      }
                                      endElement={
                                        <ButtonText
                                          size={14}
                                          css={{ color: '$text-secondary', mr: 8 }}>
                                          {`${curFlexC2CCurrency?.name || '-'}`}
                                        </ButtonText>
                                      }
                                    />
                                  )}
                                />
                              </SubmissionColumn>
                            </SubmissionRow>
                            {/* Special Deal Flex [CM to Google] Add or Remove Button */}
                            <CenteredBoxFlex css={{ height: 30, mt: 24 }}>
                              <IconButton
                                type="button"
                                color={index > 0 ? 'error' : 'primary'}
                                size="mini"
                                variant="outlined"
                                iconName={index > 0 ? 'remove' : 'add'}
                                disabled={index <= 0 && specialDealFlexPairFields.length >= 10}
                                onClick={() => {
                                  if (index > 0) {
                                    specialDealFlexCMToGoogleRemove(index);
                                  } else {
                                    specialDealFlexCMToGoogleAppend({
                                      cm_to_google_milestone: undefined,
                                      cm_to_google_credit: undefined,
                                      customer_to_cm_milestone: undefined,
                                      customer_to_cm_credit: undefined,
                                    });
                                  }
                                }}
                              />
                            </CenteredBoxFlex>
                          </SubmissionColumn>
                        </SubmissionRow>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </SubmissionColumn>
          </SubmissionRow>
          <SubmissionRow>
            {/* Memo for Legal or Finance */}
            <SubmissionColumn widthRatio={'10'}>
              <Controller
                control={control}
                name="memo"
                render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                  <TextInputArea
                    label="Memo for Legal or Finance"
                    placeholder="Inform the Legal and Finance departments of any applications for adjustments or special handling concerning Legal terms and Finance terms."
                    fieldName={name}
                    value={value}
                    onChange={onChange}
                    isError={invalid}
                    error={error?.message}
                  />
                )}
              />
            </SubmissionColumn>
          </SubmissionRow>
        </SubmissionSection>
      </SubmissionPaper>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {getValues('status') === 'rejected' ? null : (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  )}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Next */}
          <Button type="submit" size="md" data-track-id="btn-next">
            <ButtonText size={14} bold>
              Next
            </ButtonText>
          </Button>
        </Box>
      </WizardBar>
    </form>
  );
};

export default ServiceInfo;
