import { useAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import { useGetOracleOptions } from '../../../../../../services/application/official-standard/oracle-options.api';
import { onlyDecimalNumbersForField } from '../../../../../../utils/strict-for-field';
import Field from '../../../../../../components/field/deprecated-field';
import Box from '../../../../../../components/box';
import Select from '../../../../../../components/dropdown/select';
import Spacer from '../../../../../../components/surface/spacer';
import MultiRowField from '../../../../../../components/field/deprecated-multi-row-field';
import Typography from '../../../../../../components/typography/deprecated-typography';
import RadioField from '../../../../../../components/field/deprecated-radio-field';
import Checkbox from '../../../../../../components/field/checkbox';
import MSymbol from '../../../../../../components/icon/m-symbol';
import SubmissionUrgentBox from '../../../../../../components/card/submission-urgent-box';
import { Label } from '@radix-ui/react-label';
import { yesNoAppOptions } from '../../../../../../store/constants';

const GcpCustomerPayment = () => {
  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormCustomer, setSubmissionFormCustomer] = useAtom(submissionFormCustomerAtom);
  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  const supportPlanOracleOptionsQueries = useGetOracleOptions('msp', 'gcp');
  const paymentTermOracleOptionsQueries = useGetOracleOptions('paymentTerm');

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Payment Term */}
          <Select
            label="Payment Term"
            required={true}
            value={submissionFormCustomer.gcp.payment_term ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, payment_term: newValue },
              }));
            }}
            options={paymentTermOracleOptionsQueries.data}
            placeholder="Select a payment term"
            isLoading={paymentTermOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.payment_term?.is_error ?? false}
            error={submissionFormValidation.payment_term?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Payment Term Discount Rate */}
          <Field
            label="Discount Rate"
            required={true}
            value={submissionFormCustomer.gcp.payment_term_discount_rate?.toString() ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  payment_term_discount_rate: onlyDecimalNumbersForField(newValue),
                },
              }));
            }}
            endElement={
              <MSymbol
                iconName="percent"
                css={{ color: '$secondary40', fontSize: '18px !important' }}
              />
            }
            placeholder="Enter a discount rate"
            isError={submissionFormValidation.payment_term_discount_rate?.is_error ?? false}
            error={submissionFormValidation.payment_term_discount_rate?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Support Plan */}
          <Select
            label="Support Plan"
            required={true}
            value={submissionFormCustomer.gcp.support_plan ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, support_plan: newValue },
              }));
            }}
            options={supportPlanOracleOptionsQueries.data}
            placeholder="Select a support plan"
            isLoading={supportPlanOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.support_plan?.is_error ?? false}
            error={submissionFormValidation.support_plan?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Will customer issue PO / LOA / Order Form/ other ? */}
          <RadioField
            label={`Will customer issue PO / LOA / Order Form/ other ?`}
            required={true}
            value={
              (submissionFormCustomer.gcp.will_customer_issue_po_loa_orderform_other ??
                '') as string
            }
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, will_customer_issue_po_loa_orderform_other: newValue },
              }));
            }}
            options={yesNoAppOptions}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      {/* <Spacer axis="vertical" css={{ $$size: '20px' }} /> */}
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(100%)' }}>
          {/* Memo */}
          <MultiRowField
            label="Memo"
            required={(submissionFormCustomer.gcp.payment_term_discount_rate as number) > 5}
            rows={3}
            value={submissionFormCustomer.gcp.memo ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, memo: newValue },
              }));
            }}
            placeholder="Let us know if the discount rate is above 5% and also can leave the necessary verification information."
            isError={submissionFormValidation.memo?.is_error ?? false}
            error={submissionFormValidation.memo?.error ?? undefined}></MultiRowField>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '40px' }} />
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* This is an urgent request */}
        <Box css={{ mr: '10px' }}>
          <Checkbox
            id="urgent-checkbox"
            checked={submissionFormCloudmile.gcp.urgent_level as boolean}
            onChange={() => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, urgent_level: !submissionFormCloudmile.gcp.urgent_level },
              }));
            }}
          />
        </Box>
        <Label htmlFor="urgent-checkbox">
          <Typography>{`This is an urgent request`}</Typography>
        </Label>
      </Box>
      {/* The local GM will review and approve the request first. */}
      <SubmissionUrgentBox urgentLevel={submissionFormCloudmile.gcp.urgent_level} />
    </Box>
  );
};

export default GcpCustomerPayment;
