import { ReactNode, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import Box from '../box';
import Label from '../typography/label';
import { Caption } from '../typography/text';
import { motion } from 'framer-motion';
import { fadeInOutMotion } from '../../styles/motions';
import { DropdownOption } from '../../types/constants';
import IconButton from '../button/icon-button';

const TextInputContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  borderRadius: 5,
  border: 'none',
  backgroundColor: '$white',
  color: '$text-primary',
  boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
  transition: '$colors-and-shadow',
  '&:hover': {
    boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
  },
  '&[data-error=true]': {
    color: '$error60',
    boxShadow: `0 0 0 1px var(--colors-error60)`,
  },
  '&[data-disabled=true]': {
    cursor: 'not-allowed',
    color: '$text-hint',
    boxShadow: `0 0 0 1px var(--colors-func-disabled-dark)`,
    backgroundColor: '$func-disabled-light',
  },
  variants: {
    size: {
      sm: {
        height: 30,
      },
      md: {
        height: 36,
      },
      lg: {
        height: 44,
      },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

const StyledInput = styled('input', {
  all: 'unset',
  fontSize: 14,
  lineHeight: 1.75,
  fontWeight: 400,
  width: '100%',
  textIndent: 8,
  overflow: 'hidden',
  pr: 8,
  '&::placeholder': {
    color: '$text-hint',
  },
  '&:placeholder-shown': {
    textOverflow: 'ellipsis',
  },
});

interface TextInputProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  value: string | undefined;
  onChange: (newValue: DropdownOption['value'] | undefined) => void;
  autocomplete?: string;
  disabled?: boolean;
  isError?: boolean;
  error?: string;
  startElement?: ReactNode;
  endElement?: ReactNode;
  textInputContentColumnGap?: number;
  clearable?: boolean;
  inputRef?: any;
}

const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value = '',
      onChange,
      autocomplete = 'off',
      disabled,
      isError,
      error,
      startElement,
      endElement,
      clearable = false,
      inputRef,
      textInputContentColumnGap,
      ...restProps
    },
    ref
  ) => {
    const onInputChange = (newValue: string) => {
      onChange(newValue);
    };
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <TextInputContent
          data-error={isError}
          data-disabled={disabled}
          css={{ columnGap: textInputContentColumnGap }}>
          {startElement ?? null}
          <StyledInput
            ref={inputRef}
            name={fieldName}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onInputChange(e.target.value)}
            disabled={disabled}
            autoComplete={autocomplete}
          />
          {clearable ? (
            <IconButton
              iconName="close"
              size="micro"
              variant={'solid'}
              color={'achromatic'}
              onClick={() => onInputChange('')}
            />
          ) : null}
          {endElement ?? null}
        </TextInputContent>
        <Box
          as={motion.div}
          initial={'hide'}
          variants={fadeInOutMotion}
          animate={isError ? 'show' : 'hide'}>
          <Caption css={{ color: '$error60' }}>{error}</Caption>
        </Box>
      </Box>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
