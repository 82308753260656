import { Fragment } from 'react';
import { Params, useMatches, Link, useNavigate } from 'react-router-dom';
import Box from '../box';
import Typography from '../typography/deprecated-typography';
import IconButton from '../button/icon-button';

type RouteWithHandle<Handle extends string, Value> = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: Record<Handle, Value>;
};

function isRecordWithKey<T extends string>(value: unknown, key: T): value is Record<T, unknown> {
  return !!value && typeof value === 'object' && key in value;
}

function hasHandle<Handle extends string, Value>(
  handle: Handle,
  valuePredicate?: (v: unknown) => v is Value
) {
  return (
    route:
      | {
          handle: unknown;
        }
      | RouteWithHandle<Handle, Value>
  ): route is RouteWithHandle<Handle, Value> => {
    return (
      !!route.handle &&
      isRecordWithKey(route.handle, handle) &&
      (!valuePredicate || (handle in route.handle && valuePredicate(route.handle[handle])))
    );
  };
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

const Breadcrumbs = () => {
  const matches = useMatches();
  const navigate = useNavigate();
  const routesInfos = matches
    .filter(hasHandle('crumb', isString))
    .map((match) => match.handle.crumb);

  return (
    <Box as={'ol'} css={{ listStyle: 'none', p: 0, display: 'flex', columnGap: '$4' }}>
      {routesInfos.length === 1 ? null : (
        <IconButton
          iconName="chevron_left"
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      {routesInfos.map((crumb, index) => (
        <Fragment key={index}>
          <Box as={'li'}>
            {routesInfos.length - 1 === index ? (
              <Typography variant={'body-medium'} css={{ color: '$secondary90' }}>
                {crumb}
              </Typography>
            ) : (
              <Link to={matches[index].pathname}>
                <Typography
                  variant={crumb === 'Home' ? 'body-bold' : 'body'}
                  css={{
                    color: '$secondary70',
                    '&:hover': { color: 'inherit' },
                    '&:active': { color: 'inherit' },
                  }}>
                  {crumb}
                </Typography>
              </Link>
            )}
          </Box>
          {routesInfos.length - 1 === index ? null : (
            <Typography variant={'body'} css={{ color: '$secondary20' }}>{` / `}</Typography>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Breadcrumbs;
