import { styled } from '../../styles/stitches.config';
import { ScrollAreaRoot } from './scroll-area';

export const SummaryTrackContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(243px, 1fr))',
  gap: 10,
  flexGrow: 1,
});

export const SummaryTrackRoot = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$5',
  py: 20,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const SummaryTrackHeader = styled('div', {
  pb: 10,
  px: 20,
  position: 'relative',
});

export const SummaryTrackHeaderIcon = styled('img', {
  position: 'absolute',
  top: 0,
  right: 20,
});

export const SummaryTrackContent = styled(ScrollAreaRoot, {
  flexGrow: 1,
  maxHeight: 1200,
  px: 10,
  borderTop: '1px solid $transparent',
  transition: 'border-top 0.2s linear',
  '&:has([data-state=visible])': {
    borderTop: '1px solid $secondary20',
  },
});
