import { styled } from '../../styles/stitches.config';

export const SubmissionPaper = styled('div', {
  m: '0 auto',
  width: '780px',
  px: 40,
  py: 20,
  backgroundColor: '$white',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 30,
});

export const SubmissionPaperHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const SubmissionSection = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
});

export const SubmissionSectionHeader = styled('div', {
  color: '$secondary40',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '$func-border-main',
});

export const SubmissionRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    columnGapSize: {
      '5': {
        columnGap: 5,
      },
      '10': {
        columnGap: 10,
      },
      '20': {
        columnGap: 20,
      },
    },
  },
  defaultVariants: {
    columnGapSize: '20',
  },
});

export const SubmissionColumn = styled('div', {
  // flexShrink: 0,
  variants: {
    widthRatio: {
      '1': {},
      '2': {},
      '2.5': {},
      '3': {},
      '4': {},
      '5': {},
      '6': {},
      '7': {},
      '8': {},
      '9': {},
      '10': {
        width: `calc(100%)`,
      },
    },
    columnGapSize: {
      '10': {},
      '20': {},
    },
  },
  compoundVariants: [
    {
      widthRatio: '1',
      columnGapSize: '10',
      css: {
        width: `calc(10% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '1',
      columnGapSize: '20',
      css: {
        width: `calc(10% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '2',
      columnGapSize: '10',
      css: {
        width: `calc(20% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '2',
      columnGapSize: '20',
      css: {
        width: `calc(20% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '2.5',
      columnGapSize: '10',
      css: {
        width: `calc(25% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '2.5',
      columnGapSize: '20',
      css: {
        width: `calc(25% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '3',
      columnGapSize: '10',
      css: {
        width: `calc(30% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '3',
      columnGapSize: '20',
      css: {
        width: `calc(30% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '4',
      columnGapSize: '10',
      css: {
        width: `calc(40% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '4',
      columnGapSize: '20',
      css: {
        width: `calc(40% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '5',
      columnGapSize: '10',
      css: {
        width: `calc(50% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '5',
      columnGapSize: '20',
      css: {
        width: `calc(50% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '6',
      columnGapSize: '10',
      css: {
        width: `calc(60% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '6',
      columnGapSize: '20',
      css: {
        width: `calc(60% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '7',
      columnGapSize: '10',
      css: {
        width: `calc(70% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '7',
      columnGapSize: '20',
      css: {
        width: `calc(70% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '8',
      columnGapSize: '10',
      css: {
        width: `calc(80% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '8',
      columnGapSize: '20',
      css: {
        width: `calc(80% - ${20 * 0.5}px)`,
      },
    },
    {
      widthRatio: '9',
      columnGapSize: '10',
      css: {
        width: `calc(90% - ${10 * 0.5}px)`,
      },
    },
    {
      widthRatio: '9',
      columnGapSize: '20',
      css: {
        width: `calc(90% - ${20 * 0.5}px)`,
      },
    },
  ],
  defaultVariants: {
    widthRatio: '5',
    columnGapSize: '20',
  },
});
