import { useGetApplicationSidebarInfoById } from '../../../../services/application/official-standard/application.api';
import Box from '../../../../components/box';
import Typography from '../../../../components/typography/deprecated-typography';
import Divider from '../../../../components/surface/divider';
import Spacer from '../../../../components/surface/spacer';
import moment from 'moment';
import Spinner from '../../../../components/helper/spinner';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';

const SidebarInfo = ({ applicationId }: { applicationId?: string }) => {
  const applicationSidebarInfoByIdQueries = useGetApplicationSidebarInfoById({
    application_id: applicationId ?? '',
  });
  return (
    <>
      {applicationSidebarInfoByIdQueries.isLoading ? (
        <Box
          css={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size={'medium'} />
        </Box>
      ) : (
        <>
          {/* File Name */}
          <Box
            css={{
              height: '90px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}>
            <Typography
              variant={'h5'}
              css={{
                width: 290,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
              }}>{`${applicationSidebarInfoByIdQueries.data?.name ?? '-'}`}</Typography>
          </Box>
          <Divider axis={'horizontal'} css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
          <ScrollAreaRoot>
            <ScrollAreaViewport>
              {/* Access */}
              <Box></Box>
              {/* Labels */}
              <Box></Box>
              {/* File Details */}
              <Box css={{ p: 20 }}>
                <Typography variant={'body'} css={{ fontSize: '$16' }}>{`File Details`}</Typography>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Owner */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Typography
                    variant={'caption-medium'}
                    css={{ width: 90, color: '$secondary70' }}>{`Owner`}</Typography>
                  <Typography
                    variant={'caption'}
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>{`${applicationSidebarInfoByIdQueries.data?.owner ?? '-'}`}</Typography>
                </Box>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Created */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Typography
                    variant={'caption-medium'}
                    css={{ width: 90, color: '$secondary70' }}>{`Created`}</Typography>
                  <Typography
                    variant={'caption'}
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>
                    {applicationSidebarInfoByIdQueries.data?.created
                      ? moment(applicationSidebarInfoByIdQueries.data.created).format(
                          'MM/DD/YY HH:mm A (Z)'
                        )
                      : '-'}
                  </Typography>
                </Box>
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                {/* Modified */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                  <Typography
                    variant={'caption-medium'}
                    css={{ width: 90, color: '$secondary70' }}>{`Modified`}</Typography>
                  <Typography
                    variant={'caption'}
                    css={{
                      width: 186,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '$primary50',
                    }}>
                    {applicationSidebarInfoByIdQueries.data?.created
                      ? moment(applicationSidebarInfoByIdQueries.data.modified).format(
                          'MM/DD/YY HH:mm A (Z)'
                        )
                      : '-'}
                  </Typography>
                </Box>
              </Box>
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </ScrollAreaRoot>
        </>
      )}
    </>
  );
};

export default SidebarInfo;
