import { styled } from '../../styles/stitches.config';

export const Subtitle = styled('p', {
  fontSize: 18,
  fontWeight: 500,
  lineHeight: 1.5,
});

export const Text = styled('span', {
  fontSize: 14,
  lineHeight: 1.75,
  fontWeight: 400,
  display: 'block',
  variants: {
    inlineBlock: {
      true: {
        display: 'inline-block',
      },
    },
    bold: {
      true: {
        fontWeight: 700,
      },
    },
    semibold: {
      true: {
        fontWeight: 500,
      },
    },
  },
});
export const Caption = styled('span', {
  display: 'inline-block',
  fontSize: 12,
  lineHeight: 1.5,
  fontWeight: 400,
  variants: {
    bold: {
      true: {
        fontWeight: 700,
      },
    },
    semibold: {
      true: {
        fontWeight: 500,
      },
    },
  },
});

export const ButtonText = styled('span', {
  lineHeight: 1.5,
  fontWeight: 400,
  display: 'inline-block',
  variants: {
    size: {
      '12': { fontSize: 12 },
      '14': { fontSize: 14 },
      '16': { fontSize: 16 },
    },
    bold: {
      true: {
        fontWeight: 700,
      },
    },
    semibold: {
      true: {
        fontWeight: 500,
      },
    },
  },
  defaultVariants: {
    size: '12',
  },
});

export const LinkText = styled('span', {
  fontWeight: 400,
  variants: {
    size: {
      '12': { fontSize: 12 },
      '14': { fontSize: 14 },
      '16': { fontSize: 16 },
    },
    lineHeight: {
      '1.5': { lineHeight: 1.5 },
      '1.75': { lineHeight: 1.75 },
    },
    bold: {
      true: {
        fontWeight: 700,
      },
    },
    semibold: {
      true: {
        fontWeight: 500,
      },
    },
  },
  defaultVariants: {
    size: '12',
    lineHeight: '1.5',
  },
});
