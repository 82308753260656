import { ComponentProps, useState } from 'react';
import { useGetNotificationById, usePutNotificationById } from '../../services/notification.api';
import { checkNDaysAgo } from '../../utils/check-n-days-ago';
import { styled } from '../../styles/stitches.config';
import Typography from '../typography/deprecated-typography';
import Box from '../box';
import Avatar from '../surface/avatar';
import moment from 'moment';
import Spacer from '../surface/spacer';
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverPortal,
  PopoverContent,
  PopoverClose,
} from '../dropdown/popover';
import DocIcon from '../icon/doc-icon';
import MentionedTypography from '../typography/mentioned-typography';
import SelectOption from './select-option';
import IconButton from '../button/icon-button';
import { NotificationCardOptions } from '../../store/constants';
import Spinner from '../helper/spinner';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';
import Button from '../button/deprecated-button';

const StyledBox = styled('div', {
  p: 10,
  borderRadius: '$5',
  border: '1px solid $secondary20',
  position: 'relative',
  transition: '$colors $opacity',
  cursor: 'pointer',
  '& [data-button=more]': {
    opacity: 0,
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '$secondary3',
    '& [data-button=more]': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  '&:active': {
    backgroundColor: '$secondary5',
  },
});

interface NotificationCardProps extends ComponentProps<typeof StyledBox> {
  notificationId: string;
  onBoxClick: () => void;
}

const NotificationCard = ({ notificationId, onBoxClick, ...restProps }: NotificationCardProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const notificationByIdQueries = useGetNotificationById(notificationId || '');
  const notificationByIdMutation = usePutNotificationById(notificationId || '');

  const handleOnMarkAsUnRead = () => {
    notificationByIdMutation.mutate({ is_read: !notificationByIdQueries.data?.is_read });
  };
  const handleOnDismiss = () => {
    notificationByIdMutation.mutate({ is_notifyee_notification_enabled: false });
  };

  return (
    <>
      <StyledBox
        css={{
          display: 'block',
          ...(notificationByIdQueries.isSuccess &&
            !notificationByIdQueries.data.is_notifyee_notification_enabled && { display: 'none' }),
        }}
        onClick={() => {
          if (onBoxClick) {
            onBoxClick();
          }
          notificationByIdMutation.mutate({ is_read: true });
        }}
        {...restProps}>
        {notificationByIdQueries.isLoading ? (
          <Box
            css={{
              minHeight: 125,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </Box>
        ) : notificationByIdQueries.isError ? (
          <Box
            onClick={(e) => e.stopPropagation()}
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '$5',
              px: 10,
            }}>
            <Box as="img" src={ErrorMonoBG} />
            <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
              <Typography variant="h6">{`Something's gone wrong`}</Typography>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Typography variant="body-medium">
                {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
              </Typography>
            </Box>
            <Button
              variant="solid"
              size={'small'}
              isLoading={notificationByIdQueries.isLoading}
              onClick={() => {
                notificationByIdQueries.refetch();
              }}>{`Refresh`}</Button>
          </Box>
        ) : notificationByIdQueries.isSuccess ? (
          <>
            {/* Is Read */}
            {notificationByIdQueries.data.is_read ? null : (
              <Box
                css={{
                  borderRadius: '$rounded',
                  width: 8,
                  height: 8,
                  backgroundColor: '$warning90',
                  position: 'absolute',
                  top: 6,
                  left: 6,
                }}
              />
            )}
            {/* Notifier */}
            <Box css={{ display: 'flex', columnGap: 6, alignItems: 'center' }}>
              {/* Avatar */}
              <Avatar
                name={notificationByIdQueries.data.notifier.name || '-'}
                picture={notificationByIdQueries.data.notifier.picture || ''}
              />
              {/* Name, Time */}
              <Box css={{ flexGrow: 1 }}>
                <Typography as={'p'} variant={'body-bold'}>
                  {notificationByIdQueries.data.notifier.name || '-'}
                </Typography>
                <Typography as={'p'} variant={'caption'} css={{ color: '$secondary70' }}>
                  {notificationByIdQueries.data.time
                    ? checkNDaysAgo({ time: notificationByIdQueries.data.time, NDays: 14 })
                      ? moment(notificationByIdQueries.data.time).format('MM/DD/YY HH:mm A (Z)')
                      : moment(notificationByIdQueries.data.time).fromNow()
                    : '-'}
                </Typography>
              </Box>
              {/* Menu */}
              <PopoverRoot open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
                <PopoverTrigger asChild>
                  <IconButton
                    data-button="more"
                    iconName={'more_horiz'}
                    size={'tiny'}
                    css={{ ml: 'auto' }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </PopoverTrigger>
                <PopoverPortal>
                  <PopoverContent
                    sideOffset={5}
                    align={'end'}
                    css={{ p: 10 }}
                    onClick={(e) => e.stopPropagation()}>
                    {NotificationCardOptions.map((option) => (
                      <PopoverClose key={option.id} asChild>
                        <SelectOption
                          value={option.value}
                          css={{ height: 40 }}
                          onClick={() => {
                            if (option.value === 'mark-as-unread') {
                              handleOnMarkAsUnRead();
                            }
                            if (option.value === 'dismiss-this-notification') {
                              handleOnDismiss();
                            }
                          }}>
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                            <Typography variant={'body'}>
                              {option.value === 'mark-as-unread' &&
                              !notificationByIdQueries.data.is_read
                                ? 'Mark as read'
                                : `${option.name}`}
                            </Typography>
                          </Box>
                        </SelectOption>
                      </PopoverClose>
                    ))}
                  </PopoverContent>
                </PopoverPortal>
              </PopoverRoot>
            </Box>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            {/* Notification Title */}
            <Box>
              <Typography as={'p'} variant={'body-medium'}>
                <Typography css={{ color: '$primary50' }}>
                  {notificationByIdQueries.data.notifier.name || '-'}
                </Typography>
                {` `}
                {notificationByIdQueries.data.notification_title || ''}
              </Typography>
            </Box>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            {/* Message, Application & Comment or Feedback */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
              <Box css={{ width: 18, height: 18, flexShrink: 0 }}>
                <DocIcon
                  docType={notificationByIdQueries.data.icon_map.doc_type}
                  docIconBGColor={notificationByIdQueries.data.icon_map.doc_icon_bg}
                  docIconStatus={notificationByIdQueries.data.icon_map.doc_icon_status}
                />
              </Box>
              {notificationByIdQueries.data.application ? (
                <Typography as={'p'} variant={'body-medium'} css={{ flexGrow: 1 }}>
                  {notificationByIdQueries.data.application.name || '-'}
                </Typography>
              ) : null}
            </Box>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            {notificationByIdQueries.data.message ? (
              <Box>
                <MentionedTypography
                  color="$secondary70"
                  variant={'caption'}
                  tagVariant={'caption-bold'}
                  ellipsisLine={2}
                  message={notificationByIdQueries.data.message}
                />
              </Box>
            ) : null}
          </>
        ) : null}
      </StyledBox>
      <Spacer
        axis={'vertical'}
        css={{
          $$size: '10px',
          display: 'block',
          ...(notificationByIdQueries.isSuccess &&
            !notificationByIdQueries.data.is_notifyee_notification_enabled && { display: 'none' }),
        }}
      />
    </>
  );
};

export default NotificationCard;
