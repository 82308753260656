// * 用 Pathname 檢查目前在哪一個大 Page
const checkPathname = (pathname: string, destination: string) => {
  if (pathname === '/') {
    return pathname === destination;
  } else {
    return pathname.split('/')[1] === destination.slice(1);
  }
};

export default checkPathname;
