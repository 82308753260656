import { styled } from '../../styles/stitches.config';
import { green } from '../../styles/colors';
import { Text } from '../typography/text';

const ApprovalFlowBox = styled('div', {
  height: '36px',
  px: '20px',
  py: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 0,
  flexGrow: 1,
  whiteSpace: 'nowrap',
  variants: {
    variant: {
      // * 紅色
      rejected: {
        backgroundColor: '$error10',
        [`& ${Text}`]: {
          color: '$error50',
        },
        '& > .i': {
          color: '$error50',
        },
      },
      // * 灰色
      pending: {
        backgroundColor: '$secondary10',
        [`& ${Text}`]: {
          color: '$secondary40',
        },
        '& > .i': {
          color: '$secondary40',
        },
      },
      // * 深綠
      processing: {
        backgroundColor: `${green[70]}`,
        [`& ${Text}`]: {
          color: '$white',
        },
        '& > .i': {
          color: '$white',
        },
      },
      // * 淺綠
      approved: {
        backgroundColor: `${green[20]}`,
        [`& ${Text}`]: {
          color: `${green[70]}`,
        },
        '& > .i': {
          color: `${green[70]}`,
        },
      },
    },
  },
  defaultVariants: {
    variant: 'rejected',
  },
});

export default ApprovalFlowBox;
