import { Outlet } from 'react-router-dom';
import Box from '../components/box';
import LoginBG from '../assets/images/bg-login.svg';

const LoginLayout = () => {
  return (
    <Box
      css={{
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        minHeight: '100vh',
        backgroundColor: '$white',
        backgroundImage: `url(${LoginBG})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexBasis: '50%',
        }}>
        <Outlet />
      </Box>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexBasis: '50%',
        }}></Box>
    </Box>
  );
};

export default LoginLayout;
