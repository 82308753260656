import { ReactComponent as GoogleCloudSVG } from '../../assets/images/logo-google-cloud.svg';
import { ReactComponent as GoogleWorkspaceSVG } from '../../assets/images/logo-google-workspace.svg';
import { ReactComponent as CloudflareSVG } from '../../assets/images/logo-cloudflare.svg';
import { ReactComponent as ApigeeSVG } from '../../assets/images/logo-apigee.svg';

const LogoSvg = ({ product }: { product?: string }) => {
  switch (product) {
    case 'gcp':
      return <GoogleCloudSVG />;
    case 'gws':
      return <GoogleWorkspaceSVG />;
    case 'cloudflare':
      return <CloudflareSVG />;
    case 'apigee':
      return <ApigeeSVG />;
    default:
      return null;
  }
};

export default LogoSvg;
