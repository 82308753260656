import type { IdNameValueItem } from '../../types';
import type { MutableRefObject, ReactNode } from 'react';
import { useState, useEffect, useRef, forwardRef, useCallback, useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { isNavBarOpenAtom, isOverlayOpenAtom } from '../../store/atoms';
import useRect from '../../hooks/use-rect';
import useOnClickOutside from '../../hooks/use-on-click-outside';
import useBrowserDimensions from '../../hooks/use-browser-dimensions';
import { motion, useAnimationControls } from 'framer-motion';
import Box from '../box';
import Typography from '../typography/deprecated-typography';
import Label from '../typography/label';
import Divider from '../surface/divider';
import Spinner from '../helper/spinner';
import SelectOption from '../card/select-option';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../surface/scroll-area';
import InputErrorMessage from '../typography/input-error-message';
import MSymbol from '../icon/m-symbol';

const SelectSearch = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) => {
  return (
    <Box
      css={{
        px: '$5',
        height: '40px',
        color: '$secondary90',
        fontSize: '$14',
        borderRadius: '$5',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexGrow: 1,
        }}>
        <Box
          as="input"
          type="text"
          placeholder="Search"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          css={{
            width: '100%',
            border: 'none',
            outline: 'none',
            '&::placeholder': {
              height: 'inherit',
              lineHeight: 'inherit',
              color: '$secondary30',
              fontSize: '$14',
            },
          }}
        />
        <Box
          css={{ display: 'flex', alignItems: 'center', cursor: value ? 'pointer' : 'auto' }}
          onClick={() => {
            if (value) {
              onChange('');
            }
          }}>
          <MSymbol
            weight={700}
            iconName={`${value ? 'close' : 'search'}`}
            css={{ color: '$primary50' }}
          />
        </Box>
      </Box>
      <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary10' }} />
    </Box>
  );
};

const SelectButton = forwardRef<
  HTMLButtonElement,
  {
    isMenuOpen: boolean;
    placeholder?: string;
    name: string;
    onClick: () => void;
    disabled?: boolean;
    inputWidth?: number;
    isError?: boolean;
  }
>(({ isMenuOpen, placeholder, name, onClick, disabled, inputWidth, isError }, ref) => {
  return (
    <Box
      ref={ref}
      as="button"
      onClick={onClick}
      css={{
        width: '100%',
        height: '30px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary20',
        borderRadius: '$5',
        backgroundColor: disabled ? '$secondary5' : '$white',
        overflow: 'hidden',
        px: '$2',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: disabled ? 'auto' : 'pointer',
        '&:focus': {
          outline: 'none',
          borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary40',
        },
      }}>
      <Typography
        variant="body"
        align="left"
        css={{
          maxWidth: `${inputWidth}px`,
          flexGrow: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: disabled || (placeholder && !name) ? '$secondary30' : '$secondary90',
        }}>
        {name || placeholder}
      </Typography>
      <Box
        as={motion.div}
        animate={isMenuOpen ? 'up' : 'down'}
        variants={{
          up: { rotate: 180, transition: { duration: 0.15 } },
          down: { rotate: 0, transition: { duration: 0.15 } },
        }}
        css={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
        <MSymbol iconName={`arrow_drop_down`} css={{ color: '$secondary30' }} />
      </Box>
    </Box>
  );
});
SelectButton.displayName = 'SelectButton';

const SelectPopover = ({
  children,
  isMenuOpen = false,
  anchor,
  popoverHeight,
}: {
  children?: ReactNode;
  isMenuOpen: boolean;
  anchor: DOMRect | undefined;
  popoverHeight: number;
}) => {
  const { browserHeight } = useBrowserDimensions();
  const [topPosition, setTopPosition] = useState(0);
  const popoverRef = useRef<HTMLUListElement | null>(null);

  const animationControls = useAnimationControls();
  const menuOpenFadeIn = useCallback(async () => {
    await animationControls.start({ display: 'block' });
    animationControls.start({
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    });
  }, [animationControls]);
  const menuOpenFadeOut = useCallback(async () => {
    await animationControls.start({
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    });
    animationControls.start({ display: 'none' });
  }, [animationControls]);

  useEffect(() => {
    if (isMenuOpen) {
      menuOpenFadeIn();
    } else {
      menuOpenFadeOut();
    }
  }, [isMenuOpen, menuOpenFadeIn, menuOpenFadeOut]);

  useEffect(() => {
    if (anchor) {
      const dropDownTopPosition = anchor.top + anchor.height + window.scrollY + 4;
      const dropUpTopPosition = anchor.top - popoverHeight + window.scrollY - 4;
      setTopPosition(
        dropDownTopPosition + popoverHeight - window.scrollY > browserHeight
          ? dropUpTopPosition
          : dropDownTopPosition
      );
    }
  }, [anchor, browserHeight, popoverHeight]);

  return (
    <Box
      as={motion.ul}
      ref={popoverRef}
      initial={{
        opacity: 0,
        display: 'none',
      }}
      animate={animationControls}
      css={{
        display: isMenuOpen ? 'block' : 'none',
        position: 'absolute',
        top: `${topPosition}px`,
        left: `${anchor ? anchor.left : 0}px`,
        zIndex: 1000,
        width: `${anchor ? anchor.width : 0}px`,
        listStyleType: 'none',
        // maxHeight: '280px',
        p: '10px',
        borderRadius: '$5',
        boxShadow: '$basic',
        overflowX: 'hidden',
        backgroundColor: '$white',
      }}>
      {children}
    </Box>
  );
};

const Select = ({
  label,
  labelPosition = 'top',
  required = false,
  placeholder,
  value,
  options,
  onChange,
  disabled,
  inputWidth = 290,
  optionHeight = 40,
  isLoading,
  isError,
  error,
}: {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  placeholder?: string;
  value: string;
  options?: IdNameValueItem[];
  onChange?: (newValue: string) => void;
  disabled?: boolean;
  inputWidth?: number;
  optionHeight?: number;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<IdNameValueItem[] | []>([]);
  const [buttonRect, buttonRef, resetButtonRef] = useRect();
  const selectRef = useRef<HTMLDivElement | null>(null);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setIsOverlayOpen = useSetAtom(isOverlayOpenAtom);
  const needSearch = options && options.length >= 7 ? true : false;

  const handleOnButtonClick = () => {
    if (disabled) {
      return;
    }
    if (isMenuOpen === false) {
      resetButtonRef();
    }
    setIsMenuOpen(!isMenuOpen);
  };
  const handleOnClickOutside = () => {
    if (isMenuOpen === true) {
      setIsMenuOpen(false);
    }
  };
  useOnClickOutside(selectRef, handleOnClickOutside);
  const handleOnValueChange = (option: IdNameValueItem) => {
    if (onChange) {
      onChange(option.value);
    }
    if (isMenuOpen === true) {
      setIsMenuOpen(false);
    }
  };
  const searchedOptions = useMemo(() => {
    return filteredOptions.filter((option) => {
      const optionName = option.name ?? '';
      return optionName.toLowerCase().includes(searchTerm.toString().toLowerCase());
    });
  }, [filteredOptions, searchTerm]);

  useEffect(() => {
    resetButtonRef();
  }, [isNavBarOpen]);

  useEffect(() => {
    if (options && options.length > 0) {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (isMenuOpen) {
      setIsOverlayOpen(true);
    } else {
      setIsOverlayOpen(false);
    }
  }, [isMenuOpen, setIsOverlayOpen]);

  return (
    <Box
      ref={selectRef}
      css={{
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <Box>
        <SelectButton
          ref={buttonRef as MutableRefObject<HTMLButtonElement>}
          inputWidth={inputWidth}
          name={
            options?.find((option) => option.value.toString() === value.toString())?.name ?? value
          }
          placeholder={placeholder}
          isMenuOpen={isMenuOpen}
          onClick={handleOnButtonClick}
          disabled={disabled}
          isError={isError}
        />
        <SelectPopover
          popoverHeight={
            searchedOptions
              ? searchedOptions.length >= 7
                ? optionHeight * 7
                : searchedOptions.length === 0
                ? needSearch
                  ? optionHeight + 40 + 20
                  : optionHeight + 20
                : needSearch
                ? (searchedOptions.length + 1) * optionHeight + 20
                : searchedOptions.length * optionHeight + 20
              : 0
          }
          isMenuOpen={isMenuOpen}
          anchor={buttonRect}>
          {needSearch ? (
            <SelectSearch
              value={searchTerm}
              onChange={(newValue) => {
                setSearchTerm(newValue);
              }}
            />
          ) : null}
          <ScrollAreaRoot
            css={{
              height: searchedOptions
                ? searchedOptions.length >= 7
                  ? optionHeight * 7 - 60
                  : searchedOptions.length === 0
                  ? optionHeight
                  : searchedOptions.length * optionHeight
                : 0,
            }}>
            <ScrollAreaViewport>
              {isLoading ? (
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40px',
                  }}>
                  <Spinner />
                </Box>
              ) : searchedOptions && searchedOptions.length > 0 ? (
                searchedOptions.map((option: IdNameValueItem) => (
                  <SelectOption
                    key={option.id}
                    value={option.value}
                    light={option.value === value ? 'on' : 'off'}
                    onClick={() => handleOnValueChange(option)}
                    css={{
                      height: optionHeight,
                    }}>
                    {`${option.name}`}
                    <Typography variant="caption" css={{ mt: '$1', color: '$secondary70' }}>
                      {option.abbr ? `${option.abbr}` : null}
                    </Typography>
                  </SelectOption>
                ))
              ) : (
                <Box
                  css={{
                    px: '$5',
                    height: optionHeight,
                    fontSize: '$14',
                    color: '$secondary30',
                    display: 'flex',
                    alignItems: 'center',
                  }}>{`No Result`}</Box>
              )}
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </ScrollAreaRoot>
        </SelectPopover>
      </Box>
      <InputErrorMessage isError={isError} errorMessage={error} />
    </Box>
  );
};

export default Select;
