import moment from 'moment';
import {
  getApprovalProgressCardIcon,
  getApprovalProgressCardVariant,
} from '../../../../utils/get-variant';
import { getStatusColor } from '../../../../utils/get-color';
import { getFirstWordUpperCase } from '../../../../utils/get-wording';
import { useGetAppDetailApprovalTab } from '../../../../services/application/verified-standard/app-detail-tabs.api';
import { AppStatus } from '../../../../types';
import { CenteredBoxAbsolute } from '../../../../components/surface/centered-box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Box from '../../../../components/box';
import Spinner from '../../../../components/helper/spinner';
import Spacer from '../../../../components/surface/spacer';
import MSymbol from '../../../../components/icon/m-symbol';
import Disclosure from '../../../../components/surface/disclosure';
import Avatar from '../../../../components/surface/avatar';
import AwarenessChip from '../../../../components/chip/awareness-chip';
import ApprovalStatusCaption from '../../../../components/typography/approval-status-caption';
import CountingBox from '../../../../components/card/counting-box';
import ApproverFeedbacker from '../../../../components/surface/approver-feedbacker';
import {
  ApprovalProgressCard,
  ApprovalProgressRoot,
} from '../../../../components/surface/approval-progress';
import Divider from '../../../../components/surface/divider';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import EmptyApplicationProcessedBG from '../../../../assets/images/bg-empty-application-processed.svg';

export interface AppDetailApprovalTabProps {
  appId: string;
  appStatus: AppStatus | '';
}

const ApprovalTab = ({ appId, appStatus }: AppDetailApprovalTabProps) => {
  const appDetailApprovalTabQuery = useGetAppDetailApprovalTab({
    appId,
    appStatus,
  });
  return (
    <>
      {appStatus === 'draft' ? (
        <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
          <Box as="img" src={EmptyApplicationProcessedBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text
            semibold
            css={{ textAlign: 'center' }}>{`The application is still in draft status.`}</Text>
        </CenteredBoxAbsolute>
      ) : (
        <>
          {appDetailApprovalTabQuery.isLoading ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Spinner size="large" />
            </CenteredBoxAbsolute>
          ) : appDetailApprovalTabQuery.isSuccess ? (
            appDetailApprovalTabQuery.data.approval_flow_section?.length > 0 ? (
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 30,
                  alignItems: 'center',
                  p: 30,
                }}>
                {/* Approval Progress */}
                <ApprovalProgressRoot>
                  {appDetailApprovalTabQuery.data.approval_flow_section.map((approvalProgress) => (
                    <ApprovalProgressCard
                      key={approvalProgress.id}
                      state={getApprovalProgressCardVariant(approvalProgress.approver_status_code)}>
                      <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                        <Text bold>{`${approvalProgress.name}`}</Text>
                        <MSymbol
                          className="i"
                          weight={700}
                          iconName={`${getApprovalProgressCardIcon(
                            approvalProgress.approver_status_code
                          )}`}
                        />
                      </Box>
                      <ButtonText size={12}>{`${approvalProgress.desc || '-'}`}</ButtonText>
                    </ApprovalProgressCard>
                  ))}
                </ApprovalProgressRoot>
                {/* Approval Details */}
                <Box css={{ width: '100%', maxWidth: 700 }}>
                  <Disclosure
                    needDivider={true}
                    panelGap={'30px'}
                    panels={[
                      {
                        id: 'section-approval-detail',
                        name: 'Approval Details',
                        content: (
                          <>
                            {/* Urgent Level */}
                            {appDetailApprovalTabQuery.data.approval_detail_section &&
                            appDetailApprovalTabQuery.data.approval_detail_section.urgent_level ? (
                              <>
                                <AwarenessChip
                                  awarenessType="urgent"
                                  urgentLevel={
                                    appDetailApprovalTabQuery.data.approval_detail_section
                                      .urgent_level
                                  }
                                  customWidth={'fit-content'}
                                />
                                <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                              </>
                            ) : null}
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                              {/* Status */}
                              <Box
                                css={{
                                  flexBasis: 'calc(50% - 10px)',
                                  '> div': { justifyContent: 'flex-start' },
                                }}>
                                <Caption bold css={{ color: '$secondary40' }}>{`Status`}</Caption>
                                <ApprovalStatusCaption
                                  status={
                                    appDetailApprovalTabQuery.data.approval_detail_section
                                      ?.status ?? ''
                                  }
                                />
                              </Box>
                            </Box>
                            <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                              {/* Created Date */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Caption
                                  bold
                                  css={{ color: '$secondary40' }}>{`Created At`}</Caption>
                                <Text css={{ mt: '$1' }}>{`${
                                  appDetailApprovalTabQuery.data.approval_detail_section?.created_at
                                    ? moment(
                                        appDetailApprovalTabQuery.data.approval_detail_section
                                          ?.created_at
                                      ).format('MM/DD/YY HH:mm A (Z)')
                                    : '-'
                                }`}</Text>
                              </Box>
                              {/* Modified Date */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Caption
                                  bold
                                  css={{ color: '$secondary40' }}>{`Modified At`}</Caption>
                                <Text css={{ mt: '$1' }}>
                                  {appDetailApprovalTabQuery.data.approval_detail_section
                                    ?.modified_at
                                    ? moment(
                                        appDetailApprovalTabQuery.data.approval_detail_section
                                          ?.modified_at
                                      ).format('MM/DD/YY HH:mm A (Z)')
                                    : '-'}
                                </Text>
                              </Box>
                            </Box>
                            <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$5' }}>
                              {/* Applicant */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Caption
                                  bold
                                  css={{ color: '$secondary40' }}>{`Applicant`}</Caption>
                                <Box
                                  css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: '$1',
                                  }}>
                                  <Avatar
                                    name={
                                      appDetailApprovalTabQuery.data.approval_detail_section
                                        ?.applicant.name || '-'
                                    }
                                    picture={
                                      appDetailApprovalTabQuery.data.approval_detail_section
                                        ?.applicant.picture || ''
                                    }
                                    css={{ mr: 8 }}
                                    size="sm"
                                  />
                                  <Text semibold>{`${
                                    appDetailApprovalTabQuery.data.approval_detail_section
                                      ?.applicant.name ?? '-'
                                  }`}</Text>
                                </Box>
                              </Box>
                              {/* Email */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                                <Text css={{ mt: '$1' }}>{`${
                                  appDetailApprovalTabQuery.data.approval_detail_section?.applicant
                                    .email ?? '-'
                                }`}</Text>
                              </Box>
                            </Box>
                          </>
                        ),
                      },
                      {
                        id: 'section-approver',
                        name: 'Approvers',
                        content: (
                          <Box css={{ display: 'flex', columnGap: 20 }}>
                            <Box
                              css={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '$5',
                              }}>
                              <Box
                                css={{
                                  borderRadius: 5,
                                  backgroundColor: '$func-hover',
                                  textAlign: 'center',
                                }}>
                                <Text semibold css={{ color: '$text-hint' }}>
                                  Application approval
                                </Text>
                              </Box>
                              {appDetailApprovalTabQuery.data.approver_section.application_approval
                                .length > 0 ? (
                                appDetailApprovalTabQuery.data.approver_section.application_approval.map(
                                  (approver) => (
                                    <Box
                                      key={approver.id}
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '$4',
                                      }}>
                                      <Box
                                        css={{
                                          flex: 1,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}>
                                        <Box
                                          css={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <Avatar
                                            name={approver.name || '-'}
                                            picture={approver.picture || ''}
                                            css={{ mr: 8 }}
                                            size="sm"
                                          />
                                          <Text semibold>{`${approver.name}`}</Text>
                                        </Box>
                                        {appDetailApprovalTabQuery.data.approval_detail_section
                                          ?.status === 'rejected' &&
                                        approver.approver_status === 'pending' ? null : (
                                          <Box
                                            css={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              columnGap: 4,
                                              flex: '1 0 auto',
                                            }}>
                                            <Box
                                              css={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '$rounded',
                                                backgroundColor: getStatusColor(
                                                  approver.approver_status
                                                ),
                                              }}
                                            />
                                            <Spacer axis={'horizontal'} css={{ $$size: '4px' }} />
                                            <Text
                                              css={{
                                                color: getStatusColor(approver.approver_status),
                                              }}>{`${
                                              getFirstWordUpperCase(
                                                approver.approver_status.split('_').join(' ')
                                              ) || '-'
                                            }`}</Text>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  )
                                )
                              ) : (
                                <Text semibold css={{ color: '$text-hint' }}>
                                  Not requested yet
                                </Text>
                              )}
                            </Box>
                            <Box
                              css={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '$5',
                              }}>
                              <Box
                                css={{
                                  borderRadius: 5,
                                  backgroundColor: '$func-hover',
                                  textAlign: 'center',
                                }}>
                                <Text semibold css={{ color: '$text-hint' }}>
                                  Seal Application approval
                                </Text>
                              </Box>
                              {appDetailApprovalTabQuery.data.approver_section
                                .seal_application_approval.length > 0 ? (
                                appDetailApprovalTabQuery.data.approver_section.seal_application_approval.map(
                                  (approver) => (
                                    <Box
                                      key={approver.id}
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '$4',
                                      }}>
                                      <Box
                                        css={{
                                          flex: 1,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}>
                                        <Box
                                          css={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <Avatar
                                            name={approver.name || '-'}
                                            picture={approver.picture || ''}
                                            css={{ mr: 8 }}
                                            size="sm"
                                          />
                                          <Text semibold>{`${approver.name}`}</Text>
                                        </Box>
                                        {appDetailApprovalTabQuery.data.approval_detail_section
                                          ?.status === 'rejected' &&
                                        approver.approver_status === 'pending' ? null : (
                                          <Box
                                            css={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              columnGap: 4,
                                              flex: '1 0 auto',
                                            }}>
                                            <Box
                                              css={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '$rounded',
                                                backgroundColor: getStatusColor(
                                                  approver.approver_status
                                                ),
                                              }}
                                            />
                                            <Text
                                              css={{
                                                color: getStatusColor(approver.approver_status),
                                              }}>{`${
                                              getFirstWordUpperCase(
                                                approver.approver_status.split('_').join(' ')
                                              ) || '-'
                                            }`}</Text>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  )
                                )
                              ) : (
                                <Text semibold css={{ color: '$text-hint' }}>
                                  Not applied yet
                                </Text>
                              )}
                            </Box>
                          </Box>
                        ),
                      },
                      {
                        id: 'section-result',
                        name: 'Result',
                        content: (
                          <Box
                            css={{
                              px: '$5',
                              pt: '$5',
                              borderRadius: '$5',
                              backgroundColor: '$secondary3',
                            }}>
                            <Box css={{ display: 'flex', columnGap: '$2' }}>
                              <Caption
                                bold
                                css={{
                                  color: '$secondary70',
                                }}>{`Feedback`}</Caption>
                              <CountingBox
                                countingNumber={
                                  appDetailApprovalTabQuery.data.feedback_section
                                    ? appDetailApprovalTabQuery.data.feedback_section.length
                                    : 0
                                }
                              />
                            </Box>
                            {appDetailApprovalTabQuery.data.feedback_section &&
                            appDetailApprovalTabQuery.data.feedback_section?.length > 0 ? (
                              <>
                                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                                {appDetailApprovalTabQuery.data.feedback_section.map((feedback) => (
                                  <>
                                    <ApproverFeedbacker
                                      key={feedback.id}
                                      approverResultCode={feedback.approver_status_code}
                                      approver={feedback.approver}
                                      approvedTime={feedback.approved_at}
                                      feedbackContent={feedback.feedback_content}
                                    />
                                    <Divider
                                      axis={'horizontal'}
                                      css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
                                    />
                                    <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                                  </>
                                ))}
                              </>
                            ) : (
                              <Text
                                bold
                                css={{
                                  mt: 10,
                                  pb: 20,
                                  color: '$achromatic40',
                                }}>{`There were no responses from reviewers.`}</Text>
                            )}
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
              </Box>
            ) : (
              <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
                <Box as="img" src={EmptyApplicationProcessedBG} />
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                <Text
                  semibold
                  css={{ textAlign: 'center' }}>{`The application does not require review.`}</Text>
              </CenteredBoxAbsolute>
            )
          ) : appDetailApprovalTabQuery.isError ? (
            <CenteredBoxAbsolute css={{ flexGrow: 1 }}>
              <Box as="img" src={ErrorBG} />
              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
              <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
            </CenteredBoxAbsolute>
          ) : null}
        </>
      )}
    </>
  );
};

export default ApprovalTab;
