import { ReactNode } from 'react';
import { AppDetailPanels, DropdownOption } from '../../types';
import GCPStdGeneralInfo from '../../pages/application/create/gcp-standard/general-info';
import GCPStdServiceInfo from '../../pages/application/create/gcp-standard/service-info';
import GCPStdSealInfo from '../../pages/application/create/gcp-standard/seal-info';
import GCPStdAdditional from '../../pages/application/create/gcp-standard/additional';
import GCPStdConfirmation from '../../pages/application/create/gcp-standard/confirmation';
import GWSStdGeneralInfo from '../../pages/application/create/gws-standard/general-info';
import GWSStdServiceInfo from '../../pages/application/create/gws-standard/service-info';
import GWSStdSealInfo from '../../pages/application/create/gws-standard/seal-info';
import GWSStdAdditional from '../../pages/application/create/gws-standard/additional';
import GWSStdConfirmation from '../../pages/application/create/gws-standard/confirmation';
import PLPSStdGeneralInfo from '../../pages/application/create/plps-standard/general-info';
import PLPSStdServiceInfo from '../../pages/application/create/plps-standard/service-info';
import PLPSStdSealInfo from '../../pages/application/create/plps-standard/seal-info';
import PLPSStdConfirmation from '../../pages/application/create/plps-standard/confirmation';
import SimpGCPStdGeneralInfo from '../../pages/application/create/simplified/gcp/standard/general-info';
import SimpGCPStdServiceInfo from '../../pages/application/create/simplified/gcp/standard/service-info';
import SimpGCPStdAdditional from '../../pages/application/create/simplified/gcp/standard/additional';
import SimpGCPStdConfirmation from '../../pages/application/create/simplified/gcp/standard/confirmation';
import SimpGWSStdGeneralInfo from '../../pages/application/create/simplified/gws/standard/general-info';
import SimpGWSStdServiceInfo from '../../pages/application/create/simplified/gws/standard/service-info';
import SimpGWSStdAdditional from '../../pages/application/create/simplified/gws/standard/additional';
import SimpGWSStdConfirmation from '../../pages/application/create/simplified/gws/standard/confirmation';
import SimpStdDetail from '../../pages/application/detail/simplified-standard/detail';
import VerifiedStdDetail from '../../pages/application/detail/verified-standard/detail';
import LogoSimplified from '../../assets/images/logo-simplified.svg';
import LogoOfficial from '../../assets/images/logo-official.svg';
import LogoGoogleCloud from '../../assets/images/logo-google-cloud.svg';
import LogoGoogleWorkspace from '../../assets/images/logo-google-workspace.svg';

// TODO: Verified 後刪掉
export const cmAppTypes = [
  {
    id: 'aa17mX',
    name: 'Simplified',
    value: 'simplified',
    logo: LogoSimplified,
  },
  {
    id: 'oM5tk3',
    name: 'Official',
    value: 'official',
    logo: LogoOfficial,
  },
];

export const cmProductsSimp = [
  {
    id: 'DMRY6J',
    name: 'Google Cloud Platform (GCP)',
    value: 'gcp',
    logo: LogoGoogleCloud,
    availableAppTypes: ['simplified', 'official'],
  },
  {
    id: 'IuaMoh',
    name: 'Google Workspace (GWS)',
    value: 'gws',
    logo: LogoGoogleWorkspace,
    availableAppTypes: ['simplified', 'official'],
  },
];
export const cmProducts = [
  {
    id: 'dSOIRv',
    name: 'Google Cloud Platform (GCP)',
    desc: 'GCP Agreement & Quotation',
    value: 'gcp',
    icon: 'verified',
    logo: LogoGoogleCloud,
  },
  {
    id: '6l0OR_',
    name: 'Partner-led Premium Support (PLPS)',
    desc: 'Partner-led Premium Support (PLPS)',
    value: 'plps',
    icon: 'verified',
    logo: LogoGoogleCloud,
  },
  {
    id: '6f7g8h',
    name: 'Google Workspace (GWS)',
    desc: 'GWS Quotation',
    value: 'gws',
    icon: 'verified',
    logo: LogoGoogleWorkspace,
  },
];

export const cmCustomerTypesSimp = [
  { id: 'a9Ji7R', name: 'YES', value: 'EXISTED' },
  { id: 'bKu3pD', name: 'NO', value: 'NEW' },
];
export const cmCustomerTypes = [
  { id: 'C7Wt9f', name: 'YES', value: 'existed' },
  { id: 'XrP2qL', name: 'NO', value: 'new' },
];

export const specialDealOptions = [
  // { id: 'sne8VO', name: 'NONE', value: 'none' },
  { id: '9HGs5G', name: 'EDP', value: 'edp' },
  { id: 'cRflP-', name: 'CNSP', value: 'cnsp' },
  { id: 'CcDTo8', name: 'Google Full B2B', value: 'google_full_b2b' },
  { id: 'UL3KfD', name: 'CM B2B Lite', value: 'cm_b2b_lite' },
  { id: 'u2ZHl0', name: 'Flex', value: 'flex' },
];

export const subFormSteppers: Record<
  string,
  Record<string, { id: string; name: string; value: string; form: ReactNode }[]>
> = {
  gcp: {
    standard: [
      { id: 'R5wBpL', name: 'General info', value: 'general-info', form: <GCPStdGeneralInfo /> },
      { id: 'L3eGmQ', name: 'Service info', value: 'service-info', form: <GCPStdServiceInfo /> },
      { id: 'G6jBoZ', name: 'Seal info', value: 'seal-info', form: <GCPStdSealInfo /> },
      {
        id: 'P4oXjM',
        name: 'Additional documents',
        value: 'additional-documents',
        form: <GCPStdAdditional />,
      },
      { id: 'C1eNrW', name: 'Confirmation', value: 'confirmation', form: <GCPStdConfirmation /> },
    ],
  },
  gws: {
    standard: [
      { id: 'S2hTzD', name: 'General info', value: 'general-info', form: <GWSStdGeneralInfo /> },
      { id: 'Y7kDmS', name: 'Service info', value: 'service-info', form: <GWSStdServiceInfo /> },
      { id: 'X4hZnA', name: 'Seal info', value: 'seal-info', form: <GWSStdSealInfo /> },
      {
        id: 'M7bTaY',
        name: 'Additional documents',
        value: 'additional-documents',
        form: <GWSStdAdditional />,
      },
      { id: 'E8nRmG', name: 'Confirmation', value: 'confirmation', form: <GWSStdConfirmation /> },
    ],
  },
  plps: {
    standard: [
      { id: 'EzYcBq', name: 'General info', value: 'general-info', form: <PLPSStdGeneralInfo /> },
      { id: 'mAvwDD', name: 'Service info', value: 'service-info', form: <PLPSStdServiceInfo /> },
      { id: 'sdb8no', name: 'Seal info', value: 'seal-info', form: <PLPSStdSealInfo /> },
      { id: 'IH8cIY', name: 'Confirmation', value: 'confirmation', form: <PLPSStdConfirmation /> },
    ],
  },
};

// TODO: Verified 後刪掉
export const submissionSteppers: Record<
  string,
  Record<string, Record<string, { id: string; name: string; form: ReactNode }[]>>
> = {
  simplified: {
    gcp: {
      standard: [
        { id: '8oA7pL', name: 'General info', form: <SimpGCPStdGeneralInfo /> },
        { id: 'Hb1xR7', name: 'Service info', form: <SimpGCPStdServiceInfo /> },
        { id: 'J9oZcL', name: 'Additional documents', form: <SimpGCPStdAdditional /> },
        { id: 'W6fRqK', name: 'Confirmation', form: <SimpGCPStdConfirmation /> },
      ],
    },
    gws: {
      standard: [
        { id: 'Zgo4aK', name: 'General info', form: <SimpGWSStdGeneralInfo /> },
        { id: 'q2R8xU', name: 'Service info', form: <SimpGWSStdServiceInfo /> },
        { id: '9pL1fW', name: 'Additional documents', form: <SimpGWSStdAdditional /> },
        { id: 'vF6e2y', name: 'Confirmation', form: <SimpGWSStdConfirmation /> },
      ],
    },
  },
};

export const appListTabs: DropdownOption[] = [
  { id: 'c6P77P', name: 'All', value: 'all' },
  { id: 'D11auH', name: 'Draft', value: 'draft' },
  { id: 'kSidIC', name: 'Pending', value: 'pending' },
  { id: '2n3Gr8', name: 'Processing', value: 'processing' },
  { id: 'p2yRDT', name: 'Approved', value: 'approved' },
  { id: 'AF4P2j', name: 'Rejected', value: 'rejected' },
  { id: 'pFLcED', name: 'Sealing', value: 'sealing' },
  { id: 'qfWgA5', name: 'Signed', value: 'signed' },
];

export const appDetailPageMaps: Record<string, Record<string, ReactNode>> = {
  simplified: {
    standard: <SimpStdDetail />,
  },
  verified: { standard: <VerifiedStdDetail /> },
};

export const appDetailTabs: Pick<DropdownOption, 'id' | 'name' | 'value' | 'other_info'>[] = [
  {
    id: '66KheJ',
    name: 'Contract Request',
    value: 'submission',
    other_info: { iconName: 'edit_document' },
  },
  {
    id: '1YVqIh',
    name: 'Seal Request',
    value: 'seal',
    other_info: { iconName: 'approval' },
  },
  {
    id: 'k1V4yM',
    name: 'Approval',
    value: 'approval',
    other_info: { iconName: 'how_to_reg' },
  },
  { id: '2loqKw', name: 'Document', value: 'document', other_info: { iconName: 'description' } },
];

export const appDetailPanels: (Pick<DropdownOption, 'id' | 'name' | 'other_info'> & {
  value: AppDetailPanels;
})[] = [
  {
    id: '5gz4TJ',
    name: 'Info',
    value: 'info',
    other_info: { iconName: 'info' },
  },
  // {
  //   id: '',
  //   name: 'Activity',
  //   value: 'activity',
  //   other_info: { iconName: 'overview' },
  // },
  // { id: '', name: 'Attachment', value: 'attachment', other_info: { iconName: 'attach_file' } },
  { id: '', name: 'Comment', value: 'comment', other_info: { iconName: 'chat_bubble' } },
  {
    id: 'uY1s6x',
    name: 'Approval',
    value: 'approval',
    other_info: { iconName: 'approval_delegation' },
  },
];
