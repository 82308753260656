import { useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormSelectedProductAtom,
  submissionFormValidationAtom,
} from '../../../../../store/atoms';
import Box from '../../../../../components/box';
import Spacer from '../../../../../components/surface/spacer';
import Divider from '../../../../../components/surface/divider';
import Typography from '../../../../../components/typography/deprecated-typography';
import AttachFile from '../../../../../components/card/attach-file';
import LogoButton from '../../../../../components/button/logo-button';
import LogoSvg from '../../../../../components/surface/logo-svg';
import GcpCloudmileGeneral from '../gcp/standard/gcp-cloudmile-general';
import GwsCloudmileGeneral from '../gws/standard/gws-cloudmile-general';
import GcpCloudmilePayment from '../gcp/standard/gcp-cloudmile-payment';
import GwsCloudmilePayment from '../gws/standard/gws-cloudmile-payment';
import GcpCloudmileServiceDocument from '../gcp/standard/gcp-cloudmile-service-document';
import { checkFileExt, checkFileSize } from '../../../../../utils/check-file';
import { getAttachmentErrorWording } from '../../../../../utils/get-wording';

const CloudmileInfo = () => {
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  useEffect(() => {
    if (submissionFormCloudmile.gcp.google_addendum === null) {
      setSubmissionFormValidation((prev) => ({
        ...prev,
        google_addendum: undefined,
      }));
    }
  }, [setSubmissionFormValidation, submissionFormCloudmile.gcp.google_addendum]);

  return (
    <>
      <Box
        css={{
          m: '0 auto',
          width: '780px',
          px: '$10',
          py: '$5',
          backgroundColor: '$white',
        }}>
        <Typography variant="h5">{`CloudMile`}</Typography>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <Typography
          variant="body-bold"
          css={{ color: '$secondary40' }}>{`General Information`}</Typography>
        <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <LogoButton light="on" css={{ backgroundColor: '$primary10' }}>
          <LogoSvg product={submissionFormSelectedProduct} />
        </LogoButton>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <>
          {(() => {
            switch (submissionFormSelectedProduct) {
              case 'gcp':
                return <GcpCloudmileGeneral />;
              case 'gws':
                return <GwsCloudmileGeneral />;
              default:
                return null;
            }
          })()}
        </>
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <Typography
          variant="body-bold"
          css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
        <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
        <Spacer axis="vertical" css={{ $$size: '20px' }} />
        <>
          {(() => {
            switch (submissionFormSelectedProduct) {
              case 'gcp':
                return <GcpCloudmilePayment />;
              case 'gws':
                return <GwsCloudmilePayment />;
              default:
                return null;
            }
          })()}
        </>
        {submissionFormSelectedProduct === 'gcp' ? (
          <>
            <Spacer axis="vertical" css={{ $$size: '20px' }} />
            <Typography
              variant="body-bold"
              css={{ color: '$secondary40' }}>{`Services & Documents`}</Typography>
            <Divider axis="horizontal" css={{ $$size: '100%', backgroundColor: '$secondary20' }} />
            <Spacer axis="vertical" css={{ $$size: '20px' }} />
            <>
              {(() => {
                switch (submissionFormSelectedProduct) {
                  case 'gcp':
                    return <GcpCloudmileServiceDocument />;
                  default:
                    return null;
                }
              })()}
            </>
          </>
        ) : null}
      </Box>
      {submissionFormSelectedProduct === 'gcp' ? (
        <>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          <Box
            css={{
              m: '0 auto',
              width: '780px',
              px: '40px',
              py: '20px',
              backgroundColor: '$white',
            }}>
            <Typography variant="h5">{`Attach Files`}</Typography>
            <Spacer axis="vertical" css={{ $$size: '30px' }} />
            <AttachFile
              disabled={
                !Boolean(submissionFormCloudmile.gcp.special_deal) ||
                submissionFormCloudmile.gcp.special_deal === 'None'
              }
              attachName="Google Addendum"
              needSampleImage={true}
              sampleImageId={`Google_Addendum`}
              required={
                submissionFormCloudmile.gcp.special_deal === 'EDP' ||
                submissionFormCloudmile.gcp.special_deal === 'CNSP' ||
                submissionFormCloudmile.gcp.special_deal === 'Full B2B'
              }
              file={submissionFormCloudmile.gcp.google_addendum}
              onFileChange={(selectedFile) => {
                setSubmissionFormValidation((prev) => ({ ...prev, google_addendum: undefined }));
                setSubmissionFormCloudmile((prev) => ({
                  ...prev,
                  gcp: {
                    ...prev.gcp,
                    google_addendum: selectedFile,
                  },
                }));
              }}
              isError={
                checkFileSize(submissionFormCloudmile.gcp.google_addendum?.size ?? 0) ||
                checkFileExt(submissionFormCloudmile.gcp.google_addendum?.name ?? '') ||
                submissionFormValidation.google_addendum?.is_error
              }
              error={
                getAttachmentErrorWording(
                  checkFileSize(submissionFormCloudmile.gcp.google_addendum?.size ?? 0),
                  checkFileExt(submissionFormCloudmile.gcp.google_addendum?.name ?? '')
                ) || submissionFormValidation.google_addendum?.error
              }
            />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default CloudmileInfo;
