import { useState } from 'react';
import Box from '../components/box';
import Combobox from '../components/dropdown/combobox';
import Spacer from '../components/surface/spacer';
import { DropdownOption } from '../types';

const ArchivePage = () => {
  const [currentValue, setCurrentValue] = useState<{
    name: string;
    value: DropdownOption['value'];
  }>({
    name: '',
    value: '',
  });

  return (
    <Box
      css={{
        px: '20px',
        py: '20px',
      }}>
      <p>Archive Page</p>
      <Spacer axis="vertical" css={{ $$size: '10px' }} />
      <Combobox
        label="CM-Legal Entity"
        required
        placeholder="Select a legal entity"
        optionSize="md"
        options={[
          {
            id: 'a',
            name: 'Apple_HK',
            value: 10,
            picture: 'Apple',
            chip: 'Oracle',
            desc: 'iphone no company',
            icon: 'group',
          },
          { id: 'b', name: 'Berry', value: 30, icon: 'group' },
          { id: 'c', name: 'Cherry', value: 20, chip: 'Oracle' },
          {
            id: 'd',
            name: 'Duuuuuuuriiiiiiaaaannnnnn',
            value: 82,
            desc: 'denoting a version of something, especially popular music.',
            chip: 'MileCoolab',
          },
          { id: 'e', name: 'Emblica', value: 40 },
          { id: 'f', name: 'Fuji Apple', value: 20 },
          { id: 'g', name: 'Grape', value: 11 },
          { id: 'h', name: 'Hardy Kiwi', value: 1 },
          { id: 'i', name: 'Indian Mango', value: 91 },
          { id: 'j', name: 'Jackfruit', value: 38 },
        ]}
        value={currentValue}
        onChange={(newValue) => setCurrentValue(newValue)}
      />
      <Spacer axis="vertical" css={{ $$size: '10px' }} />
    </Box>
  );
};

export default ArchivePage;
