import type { ReactNode } from 'react';
import { styled, keyframes } from '../../styles/stitches.config';
import * as RadixAccordion from '@radix-ui/react-accordion';
import Box from '../box';
import Typography from '../typography/deprecated-typography';
import Spacer from './spacer';
import Divider from './divider';
import MSymbol from '../icon/m-symbol';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledAccordionHeader = styled(RadixAccordion.Header, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  columnGap: '$1',
});
const StyledAccordionTrigger = styled(RadixAccordion.Trigger, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
  borderRadius: '$rounded',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$transparent',
  backgroundColor: '$transparent',
  transition: 'border-color 0.15s linear, background-color 0.15s linear',
  '> i': {
    width: '24px',
    height: '24px',
  },
  '&:hover': {
    borderColor: '$secondary5',
    backgroundColor: '$secondary5',
  },
  '&:active': {
    borderColor: '$secondary10',
    backgroundColor: '$secondary10',
  },
});
const StyledAccordionContent = styled(RadixAccordion.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const Disclosure = ({
  panels,
  needDivider = false,
  panelGap = '40px',
  accordionHeaderEndElement,
}: {
  panels?: { id: string; name: string; content: ReactNode }[];
  needDivider?: boolean;
  panelGap?: string;
  accordionHeaderEndElement?: ReactNode;
}) => {
  return (
    <RadixAccordion.Root
      type="multiple"
      defaultValue={panels ? panels.map((panel) => panel.id) : []}>
      {panels && panels.length > 0
        ? panels.map((panel, index) => (
            <Box key={panel.id}>
              <RadixAccordion.Item value={panel.id}>
                <StyledAccordionHeader>
                  <StyledAccordionTrigger>
                    <MSymbol
                      iconName="expand_less"
                      css={{
                        transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
                        '[data-state=open] &': { transform: 'rotate(180deg)' },
                      }}
                    />
                  </StyledAccordionTrigger>
                  <Box
                    css={{
                      display: 'flex',
                      flexGrow: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <Typography
                      variant="h5"
                      css={{ whiteSpace: 'nowrap' }}>{`${panel.name}`}</Typography>
                    {accordionHeaderEndElement || null}
                    {needDivider ? (
                      <>
                        <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                        <Divider
                          axis={'horizontal'}
                          css={{ flexGrow: 1, backgroundColor: '$secondary20', mt: '$1' }}
                        />
                      </>
                    ) : null}
                  </Box>
                </StyledAccordionHeader>
                <StyledAccordionContent>
                  <Spacer axis="vertical" css={{ $$size: '20px' }} />
                  {panel.content}
                </StyledAccordionContent>
              </RadixAccordion.Item>
              {panels.length - 1 === index ? null : (
                <Spacer axis="vertical" css={{ $$size: panelGap }} />
              )}
            </Box>
          ))
        : null}
    </RadixAccordion.Root>
  );
};

export default Disclosure;
