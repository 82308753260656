import { useState } from 'react';
import {
  useGetApplicationMentionableUserById,
  useGetApplicationSidebarApprovalById,
  usePostApplicationSidebarApprovalById,
} from '../../../../services/application/official-standard/application.api';
import moment from 'moment';
import {
  getApplicationSidebarApprovalConditionsBoxPaymentTermWording,
  getApplicationSidebarApprovalConditionsBoxPaymentTermDiscountRateWording,
} from '../../../../utils/get-wording';
import { getApproverResultVariant } from '../../../../utils/get-variant';
import { Mention } from 'react-mentions';
import Box from '../../../../components/box';
import Spinner from '../../../../components/helper/spinner';
import Typography from '../../../../components/typography/deprecated-typography';
import Button from '../../../../components/button/deprecated-button';
import Spacer from '../../../../components/surface/spacer';
import Checkbox from '../../../../components/field/checkbox';
import Label from '../../../../components/typography/label';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../../../../components/helper/dialog';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import MentionedTypography from '../../../../components/typography/mentioned-typography';
import IconButton from '../../../../components/button/icon-button';
import MentionArea from '../../../../components/dropdown/mention-area';
import MSymbol from '../../../../components/icon/m-symbol';
import PopupAttentionIcon from '../../../../assets/images/icon-popup-attention.svg';
import NoApprovalBG from '../../../../assets/images/bg-no-approval.svg';
import PopupRejectIcon from '../../../../assets/images/icon-popup-reject.svg';

const SidebarApproval = ({ applicationId }: { applicationId?: string }) => {
  const applicationSidebarApprovalByIdQueries = useGetApplicationSidebarApprovalById({
    application_id: applicationId ?? '',
  });
  const [conditionsFeedback, setConditionsFeedback] = useState('');
  const [replyForUrgentFeedback, setReplyForUrgentFeedback] = useState('');
  const [approvalResultCode, setApprovalResultCode] = useState<number | undefined>(undefined);
  const [isExceptionalGftChecked, setIsExceptionalGftChecked] = useState(false);
  const approvalMutation = usePostApplicationSidebarApprovalById({
    application_id: applicationId ?? '',
  });
  const mentionableUserQueries = useGetApplicationMentionableUserById({
    application_id: applicationId || '',
  });

  return (
    <>
      {/* Approval Title */}
      <Box
        css={{
          height: '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid $secondary20',
          flexShrink: 0,
        }}>
        <Typography
          variant={'h5'}
          css={{
            width: 290,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>{`Approval`}</Typography>
      </Box>
      {applicationSidebarApprovalByIdQueries.isLoading ? (
        <Box
          css={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spinner size={'medium'} />
        </Box>
      ) : (
        <ScrollAreaRoot css={{ flexGrow: 1, p: 20 }}>
          <ScrollAreaViewport>
            {applicationSidebarApprovalByIdQueries.isSuccess ? (
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position:
                    applicationSidebarApprovalByIdQueries.data.approver_status_code === 0
                      ? 'absolute'
                      : 'revert',
                  top:
                    applicationSidebarApprovalByIdQueries.data.approver_status_code === 0
                      ? '50%'
                      : 'revert',
                  transform:
                    applicationSidebarApprovalByIdQueries.data.approver_status_code === 0
                      ? 'translateY(-50%)'
                      : 'revert',
                  wordBreak: 'break-word',
                }}>
                {/* Approver Status Code: 等前面的誰 approve / 輪到自己正在 approve / 已 approve / 已 reject */}
                {(() => {
                  switch (
                    Math.floor(applicationSidebarApprovalByIdQueries.data.approver_status_code)
                  ) {
                    case -1: // * 任一關主 Reject，重啟輪迴
                      return (
                        <>
                          <Box
                            css={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              columnGap: '6px',
                              alignSelf: 'flex-start',
                              mb: 4,
                            }}>
                            <Box as="img" css={{ width: 20, height: 20 }} src={PopupRejectIcon} />
                            <Typography
                              as={'h6'}
                              variant={'h6'}
                              css={{
                                color: '$error60',
                              }}>{`${applicationSidebarApprovalByIdQueries.data.approval_result}`}</Typography>
                          </Box>
                          <Typography
                            as={'p'}
                            variant={'body'}
                            css={{
                              width: 280,
                              alignSelf: 'flex-start',
                            }}>{`${applicationSidebarApprovalByIdQueries.data.approval_result_desc}`}</Typography>
                        </>
                      );
                    case 0: // * 等上一關
                      return (
                        <>
                          <Box as={'img'} src={NoApprovalBG} />
                          <Spacer axis={'vertical'} css={{ $$size: '25px' }} />
                          <Typography
                            as={'p'}
                            variant={'body'}
                            css={{ color: '$secondary70', width: 280 }}>
                            {`This approval is still waiting for `}
                            <Typography
                              variant={'body'}
                              css={{
                                color: '$primary50',
                              }}>{`${applicationSidebarApprovalByIdQueries.data.waiting_for}`}</Typography>
                            {`.`}
                          </Typography>
                        </>
                      );
                    case 2: // * 輪到自己這關，自己正在處理
                      return (
                        <Box
                          css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 50,
                            mb: 10,
                            width: '100%',
                          }}>
                          <Box
                            as={'img'}
                            css={{ width: 20, height: 20, mt: 4 }}
                            src={PopupAttentionIcon}
                          />
                          <Typography
                            as={'p'}
                            css={{ width: 254 }}
                            variant={
                              'body-bold'
                            }>{`This application is pending your approval`}</Typography>
                        </Box>
                      );
                    case 3: // * 自己已 Approve 去到下一關了，或是 Made it Urgent, Made it General
                      return (
                        <>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '6px',
                              alignSelf: 'flex-start',
                              mb: 4,
                            }}>
                            <Box
                              as="img"
                              css={{ width: 20, height: 20 }}
                              src={
                                getApproverResultVariant(
                                  applicationSidebarApprovalByIdQueries.data.approver_status_code
                                )?.icon
                              }
                            />
                            <Typography
                              variant={'h6'}
                              css={{
                                color: getApproverResultVariant(
                                  applicationSidebarApprovalByIdQueries.data.approver_status_code
                                )?.color,
                              }}>{`${applicationSidebarApprovalByIdQueries.data.approval_result}`}</Typography>
                          </Box>
                          <Typography
                            as={'p'}
                            variant={'body'}
                            css={{
                              width: 280,
                              alignSelf: 'flex-start',
                            }}>{`${applicationSidebarApprovalByIdQueries.data.approval_result_desc}`}</Typography>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
                {/* Reply for Urgent Box: Sales submitted for urgent 要你決定是否真 urgent */}
                {applicationSidebarApprovalByIdQueries.data.show_reply_for_urgent_box ? (
                  <Box
                    css={{ p: 10, backgroundColor: '$error10', borderRadius: '$5', width: '100%' }}>
                    <Typography as={'p'} variant={'body-bold'}>{`Urgent`}</Typography>
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: 258,
                        wordBreak: 'break-word',
                        height: 50,
                        my: 8,
                        columnGap: 4,
                      }}>
                      <MSymbol iconName="acute" size={20} css={{ color: '$error60' }} />
                      <Typography
                        as={'p'}
                        css={{ width: 236, lineHeight: '20px' }}
                        variant={
                          'body'
                        }>{`This application was submitted by Sales for urgent scenario.`}</Typography>
                    </Box>
                    <Box
                      css={{
                        backgroundColor: '$white',
                        borderRadius: '$5',
                        width: 258,
                        px: 16,
                        py: 10,
                        border: '1px solid $secondary20',
                      }}>
                      <MentionArea
                        css={{ minHeight: 44 }}
                        placeholder="You can reply to reasons, feedback, or add others with @ (optional)"
                        value={replyForUrgentFeedback}
                        onChange={(e, newValue) => setReplyForUrgentFeedback(newValue)}>
                        <Mention
                          trigger="@"
                          className={`${MentionArea.className}__mention`}
                          appendSpaceOnAdd={true}
                          displayTransform={(id, display) => {
                            return `@${display}`;
                          }}
                          data={mentionableUserQueries.data || []}
                        />
                      </MentionArea>
                    </Box>
                    <Spacer axis={'vertical'} css={{ $$size: '14px' }} />
                    <DialogRoot>
                      <DialogTrigger asChild>
                        <Button
                          color={'error'}
                          variant={'outlined'}
                          customWidth={'100%'}
                          size={`small`}>{`Make it urgent`}</Button>
                      </DialogTrigger>
                      <DialogPortal>
                        <DialogOverlay />
                        <DialogContent
                          css={{
                            px: 30,
                            py: 20,
                            borderRadius: '$10',
                            maxWidth: '655px',
                          }}>
                          <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                            {/* Make it urgent Title, Close Button */}
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                              <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                              <DialogTitle asChild>
                                <Typography
                                  as={'h5'}
                                  variant={'h5'}
                                  css={{ flexGrow: 1 }}>{`Urgent Approval`}</Typography>
                              </DialogTitle>
                              <DialogClose asChild>
                                <IconButton iconName="close" isIconBold={true} />
                              </DialogClose>
                            </Box>
                            {/* Description */}
                            <DialogDescription css={{ pl: 40 }}>
                              <Typography variant={'body'}>
                                {`We will instantly change the application to the`}{' '}
                                <Typography variant={'body'} css={{ color: '$error60' }}>
                                  urgent
                                </Typography>{' '}
                                {`review process and continue the approval to the next approver..`}
                              </Typography>
                            </DialogDescription>
                            {/* Action */}
                            <Box
                              css={{
                                display: 'flex',
                                columnGap: 20,
                                justifyContent: 'flex-end',
                              }}>
                              <Button
                                color={'primary'}
                                variant={'solid'}
                                isLoading={approvalMutation.isLoading && approvalResultCode === 2}
                                spinnerColor={'primary'}
                                onClick={() => {
                                  setApprovalResultCode(2);
                                  approvalMutation.mutate({
                                    approval_result_code: 2,
                                    feedback_content: replyForUrgentFeedback || undefined,
                                  });
                                }}>{`Make it urgent`}</Button>
                              <DialogClose asChild>
                                <Button
                                  variant={'borderless'}
                                  color={'secondary'}>{`Cancel`}</Button>
                              </DialogClose>
                            </Box>
                          </Box>
                        </DialogContent>
                      </DialogPortal>
                    </DialogRoot>
                    <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                    <DialogRoot>
                      <DialogTrigger asChild>
                        <Button
                          color={'warning'}
                          variant={'outlined'}
                          customWidth={'100%'}
                          size={`small`}>{`Make it general`}</Button>
                      </DialogTrigger>
                      <DialogPortal>
                        <DialogOverlay />
                        <DialogContent
                          css={{
                            px: 30,
                            py: 20,
                            borderRadius: '$10',
                            maxWidth: '655px',
                          }}>
                          <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                            {/* Make it general Title, Close Button */}
                            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                              <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                              <DialogTitle asChild>
                                <Typography
                                  as={'h5'}
                                  variant={'h5'}
                                  css={{ flexGrow: 1 }}>{`General Approval`}</Typography>
                              </DialogTitle>
                              <DialogClose asChild>
                                <IconButton iconName="close" isIconBold={true} />
                              </DialogClose>
                            </Box>
                            {/* Description */}
                            <DialogDescription css={{ pl: 40 }}>
                              <Typography variant={'body'}>
                                {`We will instantly change the application to the`}{' '}
                                <Typography variant={'body'} css={{ color: '$warning60' }}>
                                  general
                                </Typography>{' '}
                                {`review process and continue the approval to the next approver..`}
                              </Typography>
                            </DialogDescription>
                            {/* Action */}
                            <Box
                              css={{
                                display: 'flex',
                                columnGap: 20,
                                justifyContent: 'flex-end',
                              }}>
                              <Button
                                color={'primary'}
                                variant={'solid'}
                                isLoading={approvalMutation.isLoading && approvalResultCode === 3}
                                spinnerColor={'primary'}
                                onClick={() => {
                                  setApprovalResultCode(3);
                                  approvalMutation.mutate({
                                    approval_result_code: 3,
                                    feedback_content: replyForUrgentFeedback || undefined,
                                  });
                                }}>{`Make it general`}</Button>
                              <DialogClose asChild>
                                <Button
                                  variant={'borderless'}
                                  color={'secondary'}>{`Cancel`}</Button>
                              </DialogClose>
                            </Box>
                          </Box>
                        </DialogContent>
                      </DialogPortal>
                    </DialogRoot>
                  </Box>
                ) : null}
                {/* Conditions Box: 有 Conditions 的情況 */}
                {applicationSidebarApprovalByIdQueries.data.show_conditions_box ? (
                  <Box
                    css={{
                      p: 10,
                      backgroundColor: applicationSidebarApprovalByIdQueries.data.is_really_urgent
                        ? '$error10'
                        : '$secondary3',
                      borderRadius: '$5',
                      mt: 10,
                      width: 279,
                    }}>
                    <Typography as={'p'} variant={'body-bold'}>{`Conditions`}</Typography>
                    <Spacer axis={'vertical'} css={{ $$size: '14px' }} />
                    {/* 條件提示句子們 */}
                    <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 14 }}>
                      {/* 無條件但下面 Exceptional Approval 還是要決定是否打勾 */}
                      {applicationSidebarApprovalByIdQueries.data.show_no_conditions ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                          }}>
                          <Typography
                            as={'p'}
                            css={{ width: 258, lineHeight: '20px', color: '$secondary40' }}
                            variant={'body'}>{`No Additional Conditions.`}</Typography>
                        </Box>
                      ) : null}
                      {/* 要先 Generate Document 才可以開始 Approve 的提醒 */}
                      {applicationSidebarApprovalByIdQueries.data.show_please_generate_document ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                            columnGap: 4,
                          }}>
                          <MSymbol iconName="draft" size={20} css={{ color: '$primary50' }} />
                          <Typography
                            as={'p'}
                            css={{ width: 236, lineHeight: '20px' }}
                            variant={
                              'body'
                            }>{`Please generate documents before you approve`}</Typography>
                        </Box>
                      ) : null}
                      {/* 真 Urgent 的提醒 */}
                      {applicationSidebarApprovalByIdQueries.data.is_really_urgent ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                            columnGap: 4,
                          }}>
                          <MSymbol iconName="acute" size={20} css={{ color: '$error60' }} />
                          <Typography
                            as={'p'}
                            css={{ width: 236, lineHeight: '20px' }}
                            variant={'body'}>{`Urgent scenario.`}</Typography>
                        </Box>
                      ) : null}
                      {/* Payment Term 大於 30 天小於等於 45 天 or 大於 45 天的提醒 */}
                      {applicationSidebarApprovalByIdQueries.data.payment_term_30 ||
                      applicationSidebarApprovalByIdQueries.data.payment_term_45 ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                            columnGap: 4,
                          }}>
                          <MSymbol
                            iconName="monetization_on"
                            size={20}
                            css={{ color: '$primary50' }}
                          />
                          <Typography
                            as={'p'}
                            css={{ width: 236, lineHeight: '20px' }}
                            variant={
                              'body'
                            }>{`Payment term exceeds ${getApplicationSidebarApprovalConditionsBoxPaymentTermWording(
                            {
                              paymentTerm30:
                                applicationSidebarApprovalByIdQueries.data.payment_term_30,
                              paymentTerm45:
                                applicationSidebarApprovalByIdQueries.data.payment_term_45,
                            }
                          )} days.`}</Typography>
                        </Box>
                      ) : null}
                      {/* Payment Term Discount Rate 大於 5% 小於 15% or 大於 15 % 的提醒 */}
                      {applicationSidebarApprovalByIdQueries.data.payment_term_discount_rate_5 ||
                      applicationSidebarApprovalByIdQueries.data.payment_term_discount_rate_15 ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                            columnGap: 4,
                          }}>
                          <MSymbol
                            iconName="monetization_on"
                            size={20}
                            css={{ color: '$primary50' }}
                          />
                          <Typography
                            as={'p'}
                            css={{ width: 236, lineHeight: '20px' }}
                            variant={
                              'body'
                            }>{`Discount exceeds ${getApplicationSidebarApprovalConditionsBoxPaymentTermDiscountRateWording(
                            {
                              paymentTermDiscountRate5:
                                applicationSidebarApprovalByIdQueries.data
                                  .payment_term_discount_rate_5,
                              paymentTermDiscountRate15:
                                applicationSidebarApprovalByIdQueries.data
                                  .payment_term_discount_rate_15,
                            }
                          )} %`}</Typography>
                        </Box>
                      ) : null}
                      {/* 是 GFT 的提醒 */}
                      {applicationSidebarApprovalByIdQueries.data.is_gft ? (
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 258,
                            wordBreak: 'break-word',
                            columnGap: 4,
                          }}>
                          <MSymbol iconName="approval" size={20} css={{ color: '$primary50' }} />
                          <Typography
                            as={'p'}
                            css={{ width: 236, lineHeight: '20px' }}
                            variant={'body'}>{`General Financial Terms`}</Typography>
                        </Box>
                      ) : null}
                    </Box>
                    {/* Exceptional Approval: 是否變成 GFT */}
                    {applicationSidebarApprovalByIdQueries.data.show_exceptional_checkbox ? (
                      <>
                        <Spacer axis={'vertical'} css={{ $$size: '14px' }} />
                        <Typography
                          as={'p'}
                          css={{ mb: 8 }}
                          variant={'body-bold'}>{`Exceptional Approval`}</Typography>
                        <Box css={{ display: 'flex', alignItems: 'center' }}>
                          <Box css={{ width: 16, height: 16 }}>
                            <Checkbox
                              id="is-exceptional-gft-checkbox"
                              checked={isExceptionalGftChecked}
                              onChange={() => setIsExceptionalGftChecked(!isExceptionalGftChecked)}
                              disabled={
                                !applicationSidebarApprovalByIdQueries.data.is_google_doc_generated
                              }
                            />
                          </Box>
                          <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                          <Label htmlFor="is-exceptional-gft-checkbox">
                            <Typography
                              as={'p'}
                              css={{
                                color: !applicationSidebarApprovalByIdQueries.data
                                  .is_google_doc_generated
                                  ? '$secondary20'
                                  : '$secondary90',
                              }}
                              variant={'body'}>{`General Financial Terms (GFT)`}</Typography>
                          </Label>
                        </Box>
                      </>
                    ) : null}
                    {/* Feedback Content (Optional) */}
                    <Spacer axis={'vertical'} css={{ $$size: '14px' }} />
                    <MentionArea
                      placeholder="You can reply to reasons, feedback, or add others with @ (optional)"
                      value={conditionsFeedback}
                      onChange={(e, newValue) => setConditionsFeedback(newValue)}>
                      <Mention
                        trigger="@"
                        className={`${MentionArea.className}__mention`}
                        appendSpaceOnAdd={true}
                        displayTransform={(id, display) => {
                          return `@${display}`;
                        }}
                        data={mentionableUserQueries.data || []}
                      />
                    </MentionArea>
                    <Spacer axis={'vertical'} css={{ $$size: '14px' }} />
                    {/* Reject, Approve */}
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      {/* Reject Dialog */}
                      <DialogRoot>
                        <DialogTrigger asChild>
                          <Button
                            color={'error'}
                            variant={'outlined'}
                            customWidth={'calc(50% - 5px)'}
                            startElement={
                              <MSymbol
                                iconName="close"
                                weight={700}
                                css={{ color: 'inherit', fontSize: '16px !important' }}
                              />
                            }
                            size={`small`}>{`Reject`}</Button>
                        </DialogTrigger>
                        <DialogPortal>
                          <DialogOverlay />
                          <DialogContent
                            css={{
                              px: 30,
                              py: 20,
                              borderRadius: '$10',
                              maxWidth: '655px',
                            }}>
                            <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                              {/* Reject Title, Close Button */}
                              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                                <MSymbol
                                  iconName="error"
                                  weight={700}
                                  css={{ color: '$error60' }}
                                />
                                <DialogTitle asChild>
                                  <Typography
                                    as={'h5'}
                                    variant={'h5'}
                                    css={{ flexGrow: 1 }}>{`Reject the application`}</Typography>
                                </DialogTitle>
                                <DialogClose asChild>
                                  <IconButton iconName="close" isIconBold={true} />
                                </DialogClose>
                              </Box>
                              {/* Description */}
                              <DialogDescription css={{ pl: 40 }}>
                                <Typography variant={'body'}>
                                  {`Once the application is`}{' '}
                                  <Typography variant={'body'} css={{ color: '$error60' }}>
                                    rejected
                                  </Typography>{' '}
                                  {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                </Typography>
                              </DialogDescription>
                              {/* Action */}
                              <Box
                                css={{
                                  display: 'flex',
                                  columnGap: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <Button
                                  color={'primary'}
                                  variant="solid"
                                  isLoading={approvalMutation.isLoading && approvalResultCode === 4}
                                  spinnerColor={'primary'}
                                  onClick={() => {
                                    setApprovalResultCode(4);
                                    approvalMutation.mutate({
                                      feedback_content: conditionsFeedback || undefined,
                                      is_gft: isExceptionalGftChecked,
                                      approval_result_code: 4,
                                    });
                                  }}>
                                  {'Reject'}
                                </Button>
                                <DialogClose asChild>
                                  <Button
                                    variant={'borderless'}
                                    color={'secondary'}>{`Cancel`}</Button>
                                </DialogClose>
                              </Box>
                            </Box>
                          </DialogContent>
                        </DialogPortal>
                      </DialogRoot>
                      <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                      {/* Approve Dialog */}
                      <DialogRoot>
                        <DialogTrigger asChild>
                          <Button
                            color={'success'}
                            variant={'outlined'}
                            customWidth={'calc(50% - 5px)'}
                            startElement={
                              <MSymbol
                                iconName="check"
                                weight={700}
                                css={{ color: 'inherit', fontSize: '16px !important' }}
                              />
                            }
                            disabled={
                              applicationSidebarApprovalByIdQueries.data.is_approve_button_disabled
                            }
                            size={`small`}>{`Approve`}</Button>
                        </DialogTrigger>
                        <DialogPortal>
                          <DialogOverlay />
                          <DialogContent
                            css={{
                              px: 30,
                              py: 20,
                              borderRadius: '$10',
                              maxWidth: '655px',
                            }}>
                            <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                              {/* Approve Title, Close Button */}
                              <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                                <MSymbol
                                  iconName="error"
                                  weight={700}
                                  css={{ color: '$error60' }}
                                />
                                <DialogTitle asChild>
                                  <Typography
                                    as={'h5'}
                                    variant={'h5'}
                                    css={{ flexGrow: 1 }}>{`Approve the application`}</Typography>
                                </DialogTitle>
                                <DialogClose asChild>
                                  <IconButton iconName="close" isIconBold={true} />
                                </DialogClose>
                              </Box>
                              {/* Description */}
                              <DialogDescription css={{ pl: 40 }}>
                                <Typography variant={'body'}>
                                  {`Once the application is`}{' '}
                                  <Typography variant={'body'} css={{ color: '$success70' }}>
                                    approved
                                  </Typography>{' '}
                                  {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                </Typography>
                              </DialogDescription>
                              {/* Action */}
                              <Box
                                css={{
                                  display: 'flex',
                                  columnGap: 20,
                                  justifyContent: 'flex-end',
                                }}>
                                <Button
                                  color={'primary'}
                                  variant="solid"
                                  isLoading={approvalMutation.isLoading && approvalResultCode === 1}
                                  spinnerColor={'primary'}
                                  onClick={() => {
                                    setApprovalResultCode(1);
                                    approvalMutation.mutate({
                                      feedback_content: conditionsFeedback || undefined,
                                      is_gft: isExceptionalGftChecked,
                                      approval_result_code: 1,
                                    });
                                  }}>
                                  {'Approve'}
                                </Button>
                                <DialogClose asChild>
                                  <Button
                                    variant={'borderless'}
                                    color={'secondary'}>{`Cancel`}</Button>
                                </DialogClose>
                              </Box>
                            </Box>
                          </DialogContent>
                        </DialogPortal>
                      </DialogRoot>
                    </Box>
                  </Box>
                ) : null}
                {applicationSidebarApprovalByIdQueries.data.show_feedback_box ? (
                  <Box
                    css={{
                      mt: 20,
                      p: 10,
                      backgroundColor: '$secondary3',
                      borderRadius: '$5',
                      width: '100%',
                      wordBreak: 'break-word',
                    }}>
                    <Typography as={'p'} variant={'body-bold'}>{`Feedback or Reason`}</Typography>
                    <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                    {applicationSidebarApprovalByIdQueries.data.feedback ? (
                      <>
                        {/* 與 User 同一 Group 最新 Feedback 者姓名 */}
                        <Typography as={'p'} variant={'body-bold'}>
                          {applicationSidebarApprovalByIdQueries.data.feedback.approver.name
                            ? `${applicationSidebarApprovalByIdQueries.data.feedback.approver.name}`
                            : '-'}
                        </Typography>
                        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                        {/* 與 User 同一 Group 最新 Feedback 時間 */}
                        <Typography as={'p'} variant={'body'} css={{ color: '$secondary40' }}>
                          {moment(
                            applicationSidebarApprovalByIdQueries.data.feedback.approved_at
                          ).format('MM/DD/YY HH:mm A (Z)')}
                        </Typography>
                        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                        {/* 與 User 同一 Group 最新 Feedback 內容 */}
                        <Box css={{ width: 258 }}>
                          <MentionedTypography
                            ellipsisLine="revert"
                            message={
                              applicationSidebarApprovalByIdQueries.data.feedback
                                .feedback_content || '-'
                            }
                          />
                        </Box>
                      </>
                    ) : (
                      '-'
                    )}
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </ScrollAreaRoot>
      )}
    </>
  );
};

export default SidebarApproval;
