import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { nanoid } from 'nanoid';
import { AppStatus, CommonAPIResponse } from '../../../types';
import {
  PostSimpStdAppAttPayload,
  PostSimpStdAppAttResponse,
} from '../../../types/application/simplified/create';

export const usePostSimpStdAppAtt = (options: {
  onSuccess: (
    res: PostSimpStdAppAttResponse,
    appStatus: Extract<AppStatus, 'pending' | 'draft'>
  ) => void;
  onError: (err: AxiosError<CommonAPIResponse>) => void;
}) => {
  const axios = useAxios();
  return useMutation<
    PostSimpStdAppAttResponse,
    AxiosError<CommonAPIResponse>,
    PostSimpStdAppAttPayload
  >({
    mutationKey: ['attachment', 'simp'],
    mutationFn: async (requestBody: PostSimpStdAppAttPayload) => {
      const newAppId = nanoid();
      const filesFormData = new FormData();
      if (requestBody.random_file && requestBody.random_file.length > 0) {
        requestBody.random_file.forEach((randomFile) => {
          filesFormData.append(`random_file`, randomFile);
        });
      }
      if (requestBody.company_registration) {
        filesFormData.append(`company_registration`, requestBody.company_registration);
      }
      const { data } = await axios.post<PostSimpStdAppAttResponse>(
        `/v1/simplified_application/${newAppId}/attachment`,
        filesFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            application_id: newAppId,
          },
        }
      );
      return data;
    },
    onSuccess: (res, variables) => {
      options.onSuccess(res, variables.appStatus ?? 'pending');
    },
    onError: (err) => {
      options.onError(err);
    },
  });
};
