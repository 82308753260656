import type { IdNameValueItem } from '../types';
import { GetMentionedYouPayload, GetMentionedYouResult } from '../types/user';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useAxios } from '../hooks';
import { useSetAtom } from 'jotai';
import { homeApplicationCountAtom } from '../store/atoms';

export const useGetCMUsers = () => {
  const axios = useAxios();
  return useQuery<IdNameValueItem[], Error>(['cm-users'], async () => {
    const { data } = await axios.get(`/v1/user`);
    return data;
  });
};

const MENTIONED_YOU_PAGE_LIMIT = 10;
export const useGetMentionedYouInfinite = ({ user_id, search, ids }: GetMentionedYouPayload) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const setHomeApplicationCount = useSetAtom(homeApplicationCountAtom);
  return useInfiniteQuery<GetMentionedYouResult, AxiosError>(
    ['mentioned-you', user_id, search, ids],
    async ({
      pageParam = {
        user_id,
        search,
        ids,
        limit: MENTIONED_YOU_PAGE_LIMIT,
        offset: 0,
      },
    }) => {
      const { data } = await axios.get(`/v1/user/${user_id}/mentioned`, {
        params: pageParam,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        setHomeApplicationCount((prev) => ({
          ...prev,
          ['mentioned_you']: data.pages.length > 0 ? data.pages[0].mentioned_total : 0,
        }));
      },
      onError: (error) => {
        if (error.response?.status && error.response?.status >= 400) {
          navigate('/no-access');
        } else {
          console.warn(error);
        }
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: MENTIONED_YOU_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
              user_id,
              search,
              ids,
            }
          : undefined;
      },
    }
  );
};
