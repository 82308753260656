import axios from 'axios';
import { AppDetailAttachmentEntity, PostStdAppAttPayload } from '../types/application/constants';

export const fetchFileUrl = async (options: { url: string; name: string }) => {
  try {
    const res = await fetch(options.url);
    const blob = await res.blob();
    const file = new File([blob], `${options.name}`, { type: blob.type });
    return file;
  } catch (error) {
    console.warn(`Fetch file url error: ${error}.`);
    return null;
  }
};

export const getFileObject = async ({
  downloaded_name,
  url,
}: {
  downloaded_name?: string;
  url?: string;
}) => {
  if (!url || !downloaded_name) {
    return null;
  } else {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const file = new File([blob], `${downloaded_name}`, { type: blob.type });
      return file;
    } catch (error) {
      console.warn(`getFileObject error: ${error}.`);
    }
  }
};

export const getFileObjects = async (items: any[]) => {
  if (items.length <= 0) return null;
  const filePromises = items.map(async (item) => {
    const response = await axios.get(item.url, { responseType: 'blob' });
    const blob = response.data;
    const fileName = item.file_name || item.filename || item.id;
    return new File([blob], fileName, {
      type: item.type || item.file_type === 'seal_file' ? 'application/pdf' : blob.type,
    });
  });
  return Promise.all(filePromises);
};

export const getStdAttFileObjects = async (options: {
  contractFileList: AppDetailAttachmentEntity[];
  sealFileList: AppDetailAttachmentEntity[];
}) => {
  const result: PostStdAppAttPayload = {
    google_addendum: null,
    company_registration: null,
    fsr_mail: null,
    ceo_approval: null,
    gm_approval: null,
    seal_file: null,
    random_file: null,
  };
  const googleAddendum = options.contractFileList.filter(
    (file) => file.file_type === 'google_addendum'
  );
  const companyRegistration = options.contractFileList.filter(
    (file) => file.file_type === 'company_registration'
  );
  const fsrMailList = options.contractFileList
    .filter((file) => file.file_type === 'fsr_mail')
    .map((file) => ({ ...file, url: file.signed_url }));
  const ceoApproval = options.contractFileList.filter((file) => file.file_type === 'ceo_approval');
  const gmApproval = options.contractFileList.filter((file) => file.file_type === 'gm_approval');
  const randomFileList = options.contractFileList
    .filter((file) => file.file_type === 'random_file')
    .map((file) => ({ ...file, url: file.signed_url }));
  const sealFileList = options.sealFileList
    .filter((file) => file.file_type === 'seal_file')
    .map((file) => ({ ...file, url: file.signed_url }));

  result.google_addendum =
    googleAddendum.length > 0
      ? await fetchFileUrl({ url: googleAddendum[0].signed_url, name: googleAddendum[0].filename })
      : null;
  result.company_registration =
    companyRegistration.length > 0
      ? await fetchFileUrl({
          url: companyRegistration[0].signed_url,
          name: companyRegistration[0].filename,
        })
      : null;
  result.fsr_mail = await getFileObjects(fsrMailList);
  result.ceo_approval =
    ceoApproval.length > 0
      ? await fetchFileUrl({ url: ceoApproval[0].signed_url, name: ceoApproval[0].filename })
      : null;
  result.gm_approval =
    gmApproval.length > 0
      ? await fetchFileUrl({ url: gmApproval[0].signed_url, name: gmApproval[0].filename })
      : null;

  result.random_file = await getFileObjects(randomFileList);
  result.seal_file = await getFileObjects(sealFileList);

  console.log(result);

  return result;
};
