import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import {
  curAppDetailPanelAtom,
  curAppDetailTabAtom,
  isAppDetailPanelsOpenAtom,
  isNavBarOpenAtom,
  navBarWidthAtom,
} from '../../../../store/atoms';
import { AppDetailTabs } from '../../../../types';
import { appDetailPanels, appDetailTabs } from '../../../../store/application/constants';
import { elementGrowMotion } from '../../../../styles/motions';
import { AppDocBarEntity } from '../../../../types/application/simplified/app-doc-bar';
import {
  useGetAppActionBar,
  usePatchAppWithdraw,
  useDeleteAppDelete,
  usePostAppCopy,
} from '../../../../services/application/simplified-standard/app-actions.api';
import useGetAppDocBar from '../../../../services/application/simplified-standard/app-doc-bar.api';
import DocumentTab from './document-tab';
import InfoPanel from './info-panel';
import GCPSubmissionTab from './gcp-submission-tab';
import GWSSubmissionTab from './gws-submission-tab';
import ApprovalTab from './approval-tab';
import ApprovalPanel from './approval-panel';
import { motion } from 'framer-motion';
import Spinner from '../../../../components/helper/spinner';
import Box from '../../../../components/box';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../../components/helper/tool-tip';
import {
  AppDetailTabsRoot,
  AppDetailTabsList,
  AppDetailTabsTrigger,
  AppDetailTabsContent,
  AppDetailTabsContainer,
} from '../../../../components/surface/app-detail-tabs';
import AppDetailContent from '../../../../components/surface/app-detail-content';
import ActionBar from '../../../../components/bar/action-bar';
import { DocBarRoot, DocBarContent, DocBarItemTrigger } from '../../../../components/bar/doc-bar';
import Spacer from '../../../../components/surface/spacer';
import MSymbol from '../../../../components/icon/m-symbol';
import { Heading5 } from '../../../../components/typography/heading';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import DocIcon from '../../../../components/icon/doc-icon';
import {
  AppDetailPanelsContent,
  AppDetailPanelsList,
  AppDetailPanelsRoot,
  AppDetailPanelsToggler,
  AppDetailPanelsTogglerArrow,
  AppDetailPanelsTrigger,
} from '../../../../components/surface/app-detail-panels';
import Divider from '../../../../components/surface/divider';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import Button from '../../../../components/button/button';
import IconButton from '../../../../components/button/icon-button';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';

const Detail = () => {
  const { product, appId } = useParams();
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const navBarWidth = useAtomValue(navBarWidthAtom);
  const [isAppDetailPanelsOpen, setIsAppDetailPanelsOpen] = useAtom(isAppDetailPanelsOpenAtom);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [curAppDetailTab, setCurAppDetailTab] = useAtom(curAppDetailTabAtom);
  const [curAppDetailPanel, setCurAppDetailPanel] = useAtom(curAppDetailPanelAtom);
  const [curDocId, setCurDocId] = useState('');
  const appActionBarQuery = useGetAppActionBar({ appId: appId || '' });
  const appCopyMutation = usePostAppCopy({ appId: appId || '' });
  const appWithdrawMutation = usePatchAppWithdraw({ appId: appId || '' });
  const appDeleteMutation = useDeleteAppDelete({ appId: appId || '' });
  const appDocBarQuery = useGetAppDocBar({
    appId: appId || '',
    isDraft: appActionBarQuery.isSuccess ? appActionBarQuery.data.status === 'draft' : true,
  });

  const onCopyClick = () => {
    appCopyMutation.mutate({ is_keep_labels: false });
  };
  const onWithdrawClick = () => {
    appWithdrawMutation.mutate();
  };
  // const onEditClick = () => {
  //   navigate(`/applications/edit/${appType}/${product}/${clientType}/${appId}`);
  // };
  const onDeleteClick = () => {
    appDeleteMutation.mutate();
  };

  const onDocBarItemClick = (googleDoc: AppDocBarEntity) => {
    setCurDocId(googleDoc.id);
    setCurAppDetailTab('document');
  };
  const onSimpAppDetailTabChange = (newValue: AppDetailTabs) => {
    setCurAppDetailTab(newValue);
  };

  useEffect(() => {
    if (appDocBarQuery.isSuccess && appDocBarQuery.data.length > 0) {
      setCurDocId(appDocBarQuery.data[0].id);
    }
  }, [appDocBarQuery.data, appDocBarQuery.isSuccess]);

  useEffect(() => {
    // TODO: Panel 全開後優化
    if (appDetailPanels.findIndex((panel) => panel.value === curAppDetailPanel) === -1) {
      setCurAppDetailPanel('info');
    }
  }, [curAppDetailPanel, setCurAppDetailPanel]);

  return (
    <>
      {/* Action Bar */}
      <ActionBar
        as={motion.div}
        initial={isNavBarOpen ? 'shrink' : 'grow'}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={elementGrowMotion(navBarWidth)}>
        {appActionBarQuery.isLoading ? (
          <Spinner />
        ) : appActionBarQuery.isSuccess ? (
          <>
            {/* Product Chip */}
            <ColorfulChip text="Simplified" color="turquoise" variant="solid" />
            {/* App Name */}
            <Box css={{ display: 'flex', flexGrow: 1, minWidth: 0, ml: 20, mr: 10 }}>
              <Heading5
                css={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>{`${appActionBarQuery.data.name}`}</Heading5>
            </Box>
            {/* Action Buttons */}
            <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
              {/* Copy */}
              {appActionBarQuery.data.action_map.show_copy ? (
                <DialogAlertRoot
                  open={isCopyDialogOpen}
                  onOpenChange={(open) => setIsCopyDialogOpen(open)}>
                  {/* Copy Dialog Trigger */}
                  <DialogAlertTrigger asChild>
                    <div>
                      <TooltipRoot>
                        <TooltipTrigger asChild>
                          <IconButton
                            color="secondary"
                            iconName="content_copy"
                            isIconFill={false}
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent sideOffset={5} side={`bottom`} css={{ px: 16, py: 4.5 }}>
                            <Text css={{ color: '$white' }}>{`Copy`}</Text>
                            <TooltipArrow />
                          </TooltipContent>
                        </TooltipPortal>
                      </TooltipRoot>
                    </div>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    {/* Copy Dialog Content */}
                    <DialogAlertContent css={{ px: 40, py: 20, borderRadius: '$10' }}>
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                        <DialogAlertTitle asChild>
                          <Heading5>{`Copy application`}</Heading5>
                        </DialogAlertTitle>
                        <Divider
                          axis={'horizontal'}
                          css={{ $$size: '100%', backgroundColor: '$achromatic20' }}
                        />
                        {/* Copy Dialog Body */}
                        <DialogAlertDescription>
                          <Caption
                            as="p"
                            bold
                            css={{ color: '$achromatic40', mb: 4 }}>{`Document Name`}</Caption>
                          <ButtonText size={14}>{`${appActionBarQuery.data.name}`}</ButtonText>
                        </DialogAlertDescription>
                        {/* Copy Dialog Footer */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={appCopyMutation.isLoading}>
                              <ButtonText bold size={14}>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                          <Button
                            size="md"
                            onClick={onCopyClick}
                            isLoading={appCopyMutation.isLoading}
                            disabled={appCopyMutation.isLoading}
                            css={{ width: 67 }}>
                            <ButtonText bold size={14}>
                              Copy
                            </ButtonText>
                          </Button>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {/* Withdraw */}
              {appActionBarQuery.data.action_map.show_cancel ? (
                <DialogAlertRoot
                  open={isWithdrawDialogOpen}
                  onOpenChange={(open) => setIsWithdrawDialogOpen(open)}>
                  {/* Withdraw Dialog Trigger */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      color="error"
                      startElement={
                        <MSymbol
                          iconName="undo"
                          weight={700}
                          size={16}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold>
                        Withdraw
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    {/* Withdraw Dialog Content */}
                    <DialogAlertContent
                      css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: 655 }}>
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                        {/* Withdraw Dialog Header */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                          <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                          <DialogAlertTitle asChild>
                            <Heading5 css={{ flexGrow: 1 }}>{`Withdraw application`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Withdraw Dialog Body */}
                        <DialogAlertDescription css={{ pl: 40 }}>
                          <Text css={{ color: '$text-secondary' }}>
                            If you withdraw your application now, we will no longer proceed with the
                            subsequent review and it will be saved to Draft. Are you sure you want
                            to withdraw?
                          </Text>
                        </DialogAlertDescription>
                        {/* Withdraw Dialog Footer */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          <Button
                            size="md"
                            color="error"
                            onClick={onWithdrawClick}
                            isLoading={appWithdrawMutation.isLoading}
                            disabled={appWithdrawMutation.isLoading}
                            css={{ width: 99 }}>
                            <ButtonText bold size={14}>
                              Withdraw
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={appWithdrawMutation.isLoading}>
                              <ButtonText bold size={14}>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
              {/* Edit */}
              {/* {appActionBarQuery.data.action_map.show_edit ? (
                <Button
                  size="md"
                  variant="outlined"
                  startElement={
                    <MSymbol
                      iconName="edit"
                      weight={700}
                      size={16}
                      fill={false}
                      css={{ color: 'inherit' }}
                    />
                  }
                  onClick={onEditClick}>
                  <ButtonText size={14} bold>
                    Edit
                  </ButtonText>
                </Button>
              ) : null} */}
              {/* Delete */}
              {appActionBarQuery.data.action_map.show_delete ? (
                <DialogAlertRoot
                  open={isDeleteDialogOpen}
                  onOpenChange={(open) => setIsDeleteDialogOpen(open)}>
                  {/* Delete Dialog Trigger */}
                  <DialogAlertTrigger asChild>
                    <Button
                      size="md"
                      variant="outlined"
                      color="error"
                      startElement={
                        <MSymbol
                          iconName="delete"
                          fill={false}
                          weight={700}
                          size={16}
                          css={{ color: 'inherit' }}
                        />
                      }>
                      <ButtonText size={14} bold>
                        Delete
                      </ButtonText>
                    </Button>
                  </DialogAlertTrigger>
                  <DialogAlertPortal>
                    <DialogAlertOverlay />
                    {/* Delete Dialog Content */}
                    <DialogAlertContent
                      css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: 655 }}>
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                        {/* Delete Dialog Header */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                          <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                          <DialogAlertTitle asChild>
                            <Heading5 css={{ flexGrow: 1 }}>{`Delete application`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Delete Dialog Body */}
                        <DialogAlertDescription css={{ pl: 40 }}>
                          <Text css={{ color: '$text-secondary' }}>
                            Are you sure you want to permanently delete the application, its
                            comments and attachments, and all of its data?
                          </Text>
                        </DialogAlertDescription>
                        {/* Delete Dialog Footer */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          <Button
                            size="md"
                            color="error"
                            onClick={onDeleteClick}
                            isLoading={appDeleteMutation.isLoading}
                            disabled={appDeleteMutation.isLoading}
                            css={{ width: 76 }}>
                            <ButtonText bold size={14}>
                              Delete
                            </ButtonText>
                          </Button>
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={appDeleteMutation.isLoading}>
                              <ButtonText bold size={14}>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    </DialogAlertContent>
                  </DialogAlertPortal>
                </DialogAlertRoot>
              ) : null}
            </Box>
          </>
        ) : appActionBarQuery.isError ? (
          <Heading5>Something went wrong</Heading5>
        ) : null}
      </ActionBar>
      {/* Doc Bar */}
      <DocBarRoot>
        <Spacer axis={'vertical'} css={{ $$size: '57px' }} />
        {/* Doc Bar */}
        {appDocBarQuery.isLoading ? (
          <Spinner />
        ) : appDocBarQuery.isSuccess ? (
          appDocBarQuery.data.length > 0 ? (
            <DocBarContent>
              {appDocBarQuery.data.map((googleDoc) => (
                <DocBarItemTrigger
                  key={googleDoc.id}
                  data-state={curDocId === googleDoc.id ? 'active' : 'inactive'}
                  onClick={() => onDocBarItemClick(googleDoc)}>
                  <TooltipRoot>
                    <TooltipTrigger asChild>
                      <Box css={{ width: 36, height: 36, flexShrink: 0 }}>
                        <DocIcon
                          docType={googleDoc.icon_map.doc_type}
                          docIconBGColor={googleDoc.icon_map.doc_icon_bg}
                          docIconStatus={googleDoc.icon_map.doc_icon_status}
                        />
                      </Box>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={15}
                        side={`right`}
                        css={{
                          px: 16,
                          py: 4.5,
                          display: googleDoc.google_doc_name ? 'revert' : 'none',
                        }}>
                        <Text css={{ color: '$white' }}>{`${googleDoc.google_doc_name}`}</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                </DocBarItemTrigger>
              ))}
            </DocBarContent>
          ) : (
            <Text>-</Text>
          )
        ) : appDocBarQuery.isError ? (
          <Text>Err</Text>
        ) : (
          <>
            <CenteredBoxFlex css={{ width: 50, height: 50 }}>
              <Box css={{ width: 36, height: 36, cursor: 'not-allowed' }}>
                <DocIcon docType={'quotation'} docIconBGColor={'disabled'} docIconStatus={''} />
              </Box>
            </CenteredBoxFlex>
            <CenteredBoxFlex css={{ width: 50, height: 50 }}>
              <Box css={{ width: 36, height: 36, cursor: 'not-allowed' }}>
                <DocIcon docType={'contract'} docIconBGColor={'disabled'} docIconStatus={''} />
              </Box>
            </CenteredBoxFlex>
          </>
        )}
      </DocBarRoot>
      {/* Detail Tabs */}
      <AppDetailContent>
        <AppDetailTabsContainer>
          <AppDetailTabsRoot
            value={curAppDetailTab}
            onValueChange={(newValue) => onSimpAppDetailTabChange(newValue as AppDetailTabs)}>
            <AppDetailTabsList aria-label="simp-gcp-std-detail-tab">
              {appDetailTabs
                .filter((appDetailTab) => appDetailTab.value !== 'seal')
                .map((appDetailTab) => (
                  <AppDetailTabsTrigger key={appDetailTab.id} value={appDetailTab.value as string}>
                    <Box
                      css={{
                        display: 'flex',
                        height: 25,
                        alignItems: 'center',
                        columnGap: 5,
                      }}>
                      <MSymbol
                        iconName={`${appDetailTab.other_info?.iconName}`}
                        css={{ color: 'inherit', fontSize: '16px !important' }}
                      />
                      {`${appDetailTab.name}`}
                    </Box>
                  </AppDetailTabsTrigger>
                ))}
            </AppDetailTabsList>
            <AppDetailTabsContent value={curAppDetailTab} size={'fill'}>
              {(() => {
                switch (curAppDetailTab) {
                  // * Approval Tab
                  case 'approval':
                    return (
                      <ApprovalTab
                        appId={appId || ''}
                        needApproval={appActionBarQuery.data?.is_required_approval || false}
                        appStatus={appActionBarQuery.data?.status || ''}
                      />
                    );
                  // * Submission Tab
                  case 'submission':
                    if (product === 'gcp')
                      return <GCPSubmissionTab product={product || ''} appId={appId || ''} />;
                    if (product === 'gws')
                      return <GWSSubmissionTab product={product || ''} appId={appId || ''} />;
                    return null;
                  // * Document Tab
                  case 'document':
                    return (
                      <DocumentTab
                        appId={appId || ''}
                        googleDocId={curDocId || ''}
                        appStatus={appActionBarQuery.data?.status || ''}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </AppDetailTabsContent>
          </AppDetailTabsRoot>
          <Spacer axis={'vertical'} css={{ $$size: '18px' }} />
        </AppDetailTabsContainer>
      </AppDetailContent>
      {/* Detail Panels */}
      <AppDetailPanelsRoot open={isAppDetailPanelsOpen}>
        <AppDetailPanelsList
          open={isAppDetailPanelsOpen}
          onOpenChange={(open) => setIsAppDetailPanelsOpen(open)}>
          <AppDetailPanelsToggler
            onClick={() => {
              setIsAppDetailPanelsOpen(!isAppDetailPanelsOpen);
            }}>
            <AppDetailPanelsTogglerArrow iconName="navigate_next" open={isAppDetailPanelsOpen} />
          </AppDetailPanelsToggler>
          <Box>
            {appDetailPanels
              .filter(
                (panelTrigger) =>
                  panelTrigger.value !== 'comment' &&
                  panelTrigger.value !== 'activity' &&
                  panelTrigger.value !== 'attachment'
              )
              .map((panelTrigger) => (
                <Box key={panelTrigger.id}>
                  {panelTrigger.value === 'approval' ? (
                    <Divider
                      axis={'horizontal'}
                      css={{ $$size: '30px', backgroundColor: '$secondary40', m: '10px auto' }}
                    />
                  ) : null}
                  <TooltipRoot key={panelTrigger.id}>
                    <TooltipTrigger asChild>
                      <div>
                        <AppDetailPanelsTrigger
                          triggerIconName={panelTrigger.other_info?.iconName as string}
                          light={curAppDetailPanel === panelTrigger.value ? 'on' : 'off'}
                          onClick={() => setCurAppDetailPanel(panelTrigger.value)}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={5}
                        side={`left`}
                        css={{
                          px: 16,
                          py: 4.5,
                        }}>
                        <Text css={{ color: '$white' }}>{`${panelTrigger.name ?? '-'}`}</Text>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                </Box>
              ))}
          </Box>
        </AppDetailPanelsList>
        <AppDetailPanelsContent>
          {(() => {
            switch (curAppDetailPanel) {
              case 'info':
                return <InfoPanel appId={appId || ''} />;
              case 'activity':
                return <>Developing</>;
              case 'approval':
                return <ApprovalPanel appId={appId || ''} />;
              case 'attachment':
                return <>Developing</>;
              case 'comment':
                return <>Developing</>;
              default:
                return null;
            }
          })()}
        </AppDetailPanelsContent>
      </AppDetailPanelsRoot>
    </>
  );
};

export default Detail;
