import { styled } from '../../styles/stitches.config';

const ServiceItemButton = styled('button', {
  px: 20,
  py: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 10,
  borderRadius: 10,
  width: 300,
  cursor: 'pointer',
  backgroundColor: '$white',
  transition: '$all',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '$transparent',
  '&:hover': {
    backgroundColor: '$func-hover',
  },
  '&:active': {
    borderColor: '$primary50',
    backgroundColor: '$primary10',
  },
  '&[data-state=active]': {
    borderColor: '$primary50',
    backgroundColor: '$primary10',
  },
  '&:disabled': {
    pointerEvents: 'none',
    '> img': {
      filter: 'opacity(0.35)',
    },
  },
  variants: {
    showcase: {
      true: {
        width: '100%',
        height: 60,
        pointerEvents: 'none',
      },
    },
  },
  defaultVariants: {
    showcase: false,
  },
});

export default ServiceItemButton;
