import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { scrollToTop } from '../../../../utils/scroll';
import { isNoAttachment } from '../../../../utils/check-validation';
import { PostGWSStdAppPayload } from '../../../../types/application/constants';
import { usePostStdAppAtt } from '../../../../services/application/verified-standard/attachment.api';
import { fixedLayoutGrowMotion } from '../../../../styles/motions';
import {
  usePatchStdApp,
  usePostStdApp,
} from '../../../../services/application/verified-standard/app.api';
import { usePostStdAppCondition } from '../../../../services/application/verified-standard/condition.api';
import { useGetPUPPFromCM } from '../../../../services/application/verified-standard/dropdown.api';
import {
  isNavBarOpenAtom,
  isToastOpenAtom,
  navBarWidthAtom,
  toastContentAtom,
} from '../../../../store/atoms';
import {
  curSubFormStepAtom,
  postGWSStdAppPayloadAtom,
  postStdAppAttPayloadAtom,
} from '../../../../store/application/atoms';
import Box from '../../../../components/box';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import { Heading5 } from '../../../../components/typography/heading';
import Spacer from '../../../../components/surface/spacer';
import ColorfulChip from '../../../../components/chip/colorful-chip';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import Button from '../../../../components/button/button';
import MSymbol from '../../../../components/icon/m-symbol';
import WizardBar from '../../../../components/bar/wizard-bar';
import {
  SubmissionColumn,
  SubmissionPaper,
  SubmissionRow,
  SubmissionSection,
  SubmissionSectionHeader,
} from '../../../../components/surface/submission-paper';
import Disclosure from '../../../../components/surface/disclosure';
import ServiceItemButton from '../../../../components/button/service-item-button';
import Avatar from '../../../../components/surface/avatar';
import FileBlobCard from '../../../../components/card/file-blob-card';
import CheckText from '../../../../components/field/check-text';
import { CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import LogoGoogleWorkspace from '../../../../assets/images/logo-google-workspace.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';

const validationSchema: Yup.AnyObjectSchema = Yup.object().shape({});

const Confirmation = () => {
  const navigate = useNavigate();
  const { appId } = useParams();

  const navBarWidth = useAtomValue(navBarWidthAtom);
  const isNavBarOpen = useAtomValue(isNavBarOpenAtom);
  const setCurSubFormStep = useSetAtom(curSubFormStepAtom);
  const [postStdAppPayload, setPostStdAppPayload] = useAtom(postGWSStdAppPayloadAtom);
  const postStdAppAttPayload = useAtomValue(postStdAppAttPayloadAtom);
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [submitDialog, setSubmitDialog] = useState<{ type: string; conditions: string[] }>({
    type: '',
    conditions: [],
  });

  const { handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: postStdAppPayload,
    resolver: yupResolver(validationSchema),
  });
  const onCMSealFirstCheck = (newValue: boolean) => {
    setPostStdAppPayload((prev) => ({ ...prev, is_cm_seal_first: newValue }));
  };
  const onUrgentRequestCheck = (newValue: boolean) => {
    setPostStdAppPayload((prev) => ({ ...prev, urgent_level: newValue === true ? 1 : 0 }));
  };

  const PUPPFromCMQuery = useGetPUPPFromCM({
    officialPricingDiscountType: postStdAppPayload.official_pricing_discount_type.value,
    officialPricingDiscountValue: postStdAppPayload.official_pricing_discount_value || 0,
    officialPricingDiscountCurrency: postStdAppPayload.official_pricing_discount_currency.value,
    workspaceEditions: postStdAppPayload.domain_infos.map(
      (domainInfo) => domainInfo.workspace_edition.value
    ),
    workspacePlans: postStdAppPayload.domain_infos.map(
      (domainInfo) => domainInfo.workspace_plan.value
    ),
  });
  const postStdAppCondition = usePostStdAppCondition({
    onSuccess: (res) => {
      if (res.is_needed_legal_approval || res.is_needed_finance_approval) {
        setSubmitDialog({ type: 'conditionMet', conditions: res.needed_approval_fields || [] });
      } else {
        setSubmitDialog({ type: 'conditionNotMet', conditions: [] });
      }
      setPostStdAppPayload((prev) => ({
        ...prev,
        is_needed_finance_approval: res.is_needed_finance_approval,
        is_needed_legal_approval: res.is_needed_legal_approval,
        cm_per_user_per_periods: PUPPFromCMQuery.data || [],
        status: 'processing',
      }));
    },
    onError: (err) => {
      console.warn(err);
    },
  });
  const postAppMutation = usePostStdApp();
  const patchAppMutation = usePatchStdApp({ appId: appId || '' });
  const postAttachmentMutation = usePostStdAppAtt({
    appId,
    onSuccess: (res, appStatus) => {
      if (appId) {
        patchAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      } else {
        postAppMutation.mutate({ ...postStdAppPayload, ...res, status: appStatus });
      }
    },
    onError: (err) => {
      setIsSubmitDialogOpen(false);
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });

  const onBackClick = () => {
    setCurSubFormStep(4);
  };
  const onSubmitClick: SubmitHandler<PostGWSStdAppPayload> = () => {
    postStdAppCondition.mutate(postStdAppPayload);
    setIsSubmitDialogOpen(true);
  };
  const onSubmitError = () => {
    setIsSubmitDialogOpen(false);
  };
  const onSubmitCancel = () => {
    setPostStdAppPayload((prev) => ({ ...prev, urgent_level: 0, is_cm_seal_first: false }));
    setIsSubmitDialogOpen(false);
  };
  const onGenerateClick = () => {
    postAttachmentMutation.mutate({ ...postStdAppAttPayload, appStatus: postStdAppPayload.status });
  };
  const onSaveClick = () => {
    postAttachmentMutation.mutate({
      ...postStdAppAttPayload,
      appStatus: appId ? postStdAppPayload.status : 'draft',
    });
  };
  const onLeaveClick = () => {
    navigate('/applications');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitClick, onSubmitError)}>
      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
        <SubmissionPaper>
          <Disclosure
            accordionHeaderEndElement={
              <ColorfulChip text="GWS" color="tangerine" variant="solid" />
            }
            panels={[
              {
                id: 'section-cloudmile',
                name: 'CloudMile',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>General Information</Text>
                      </SubmissionSectionHeader>
                      <ServiceItemButton type="button" data-state="active" showcase>
                        <Box as="img" src={LogoGoogleWorkspace} css={{ py: 14 }} />
                      </ServiceItemButton>
                      <SubmissionRow>
                        {/* CM Legal Entity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.cm_legal_entity.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* CM Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Box
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: '$1',
                              columnGap: 8,
                            }}>
                            <Avatar
                              name={postStdAppPayload.cm_attention_to.name || '-'}
                              picture={postStdAppPayload.cm_picture || '-'}
                              size="sm"
                            />
                            <Text
                              css={{
                                mt: '$1',
                              }}>{`${postStdAppPayload.cm_attention_to.name || '-'}`}</Text>
                          </Box>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* CM Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.cm_email || '-'}
                          </Text>
                        </SubmissionColumn>
                        {/* CM Country Calling Code / CM Phone Number / CM Extension Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.cm_phone_number
                              ? `${postStdAppPayload.cm_country_calling_code.name || ''} ${
                                  postStdAppPayload.cm_phone_number
                                } ${
                                  postStdAppPayload.cm_extension_number
                                    ? `#${postStdAppPayload.cm_extension_number}`
                                    : ''
                                }`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-customer',
                name: 'Customer',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>General Information</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Client */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Client Country`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_country?.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Tax Reg Number */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Tax Reg Number (統一編號)`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.tax_reg_number || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Address */}
                        <SubmissionColumn widthRatio={'10'}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Address`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_address || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionSectionHeader>
                        <Text semibold>Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Currency / Exchange Rate */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Currency / Exchange Rate`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.currency.name || '-'} / ${
                            postStdAppPayload.exchange_rate.name || '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Payment Term */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Payment Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${`${postStdAppPayload.payment_term.name}` || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Quotation Validity */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Quotation Validity`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            moment(postStdAppPayload.quotation_validity).format('DD MMM YYYY') ||
                            '-'
                          }`}</Text>
                        </SubmissionColumn>
                        {/* Automatically Renew Term */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Automatically Renew Term`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postStdAppPayload.automatically_renew_term || '-'
                          } Months`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Attention To */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Attention To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_attention_to || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Client Email */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Email`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.client_email || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Client Country Calling Code / Phone Number / Extension Number */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Phone Number`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>
                            {postStdAppPayload.client_phone_number
                              ? `${postStdAppPayload.client_country_calling_code.name || ''} ${
                                  postStdAppPayload.client_phone_number
                                } ${
                                  postStdAppPayload.client_extension_number
                                    ? `#${postStdAppPayload.client_extension_number}`
                                    : ''
                                }`
                              : '-'}
                          </Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-service',
                name: 'Service',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Payment Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Support Plan */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Support Plan`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.support_plan.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Official Pricing Discount */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postStdAppPayload.official_pricing_discount_type.name || '-'
                          } / ${
                            postStdAppPayload.official_pricing_discount_type.value ===
                            'percentage_discount'
                              ? `${postStdAppPayload.official_pricing_discount_value} %`
                              : postStdAppPayload.official_pricing_discount_type.value ===
                                'fixed_amount_discount'
                              ? `${
                                  postStdAppPayload.official_pricing_discount_value?.toLocaleString() ||
                                  '-'
                                } ${postStdAppPayload.official_pricing_discount_currency.name}`
                              : `0 ${postStdAppPayload.official_pricing_discount_currency.name}`
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Has this customer ever signed a GWS quotation ? */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Has this customer ever signed a GWS quotation ?`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${
                            postStdAppPayload.has_signed_quotation?.toUpperCase() || '-'
                          }`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      {/* Domain Infos */}
                      <SubmissionSection css={{ rowGap: 20 }}>
                        {postStdAppPayload.domain_infos?.map((domainInfo, domainInfoIndex) => (
                          <Fragment key={nanoid()}>
                            <Caption bold css={{ color: '$primary50' }}>{`Domain & Workspace plan ${
                              domainInfoIndex + 1
                            }`}</Caption>
                            <SubmissionRow>
                              {/* Has this domain ever signed a GWS quotation ? */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{
                                    color: '$secondary40',
                                  }}>{`Has this domain ever signed a GWS quotation ?`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${
                                  domainInfo.has_signed_domain_quotation?.toUpperCase() || '-'
                                }`}</Text>
                              </SubmissionColumn>
                              {/* Domain Name */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{ color: '$secondary40' }}>{`Domain Name`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${domainInfo.domain_name || '-'}`}</Text>
                              </SubmissionColumn>
                            </SubmissionRow>
                            <SubmissionRow>
                              {/* Workspace Edition & Plan */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{
                                    color: '$secondary40',
                                  }}>{`Workspace Edition & Plan`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${domainInfo.workspace_edition.name || '-'} / ${
                                  domainInfo.workspace_plan.name || '-'
                                }`}</Text>
                              </SubmissionColumn>{' '}
                              {/* Subscription Term */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{ color: '$secondary40' }}>{`Subscription Term`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${domainInfo.subscription_term || '-'} Months`}</Text>
                              </SubmissionColumn>
                            </SubmissionRow>
                            <SubmissionRow>
                              {/* Seats */}
                              <SubmissionColumn>
                                <Caption bold css={{ color: '$secondary40' }}>{`Seats`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${domainInfo.seats || '-'}`}</Text>
                              </SubmissionColumn>
                              {/* Special Price from Google */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{
                                    color: '$secondary40',
                                  }}>{`Special Price from Google`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${
                                  domainInfo.special_price_from_google?.toUpperCase() || '-'
                                }`}</Text>
                              </SubmissionColumn>
                            </SubmissionRow>
                            <SubmissionRow>
                              {/* PUPM / PUPY from Google */}
                              <SubmissionColumn>
                                <Caption
                                  bold
                                  css={{
                                    color: '$secondary40',
                                  }}>{`PUPM / PUPY from Google`}</Caption>
                                <Text
                                  css={{
                                    mt: '$1',
                                  }}>{`${domainInfo.google_pupp_type.name || '-'} / ${
                                  domainInfo.google_pupp_value?.toLocaleString() || '-'
                                } ${domainInfo.google_pupp_currency.name}`}</Text>
                              </SubmissionColumn>
                              {/* PUPM / PUPY from CM */}
                              {PUPPFromCMQuery.isLoading ? (
                                <Spinner size="mini" />
                              ) : PUPPFromCMQuery.isSuccess ? (
                                <SubmissionColumn>
                                  <Caption
                                    bold
                                    css={{
                                      color: '$secondary40',
                                    }}>{`PUPM / PUPY from CM`}</Caption>
                                  <Text
                                    css={{
                                      mt: '$1',
                                    }}>
                                    {`${
                                      PUPPFromCMQuery.data?.[
                                        domainInfoIndex
                                      ]?.cm_per_user_per_period?.toLocaleString() || '-'
                                    } ${postStdAppPayload.official_pricing_discount_currency.name}`}
                                  </Text>
                                </SubmissionColumn>
                              ) : PUPPFromCMQuery.isError ? (
                                <Text css={{ color: '$error60' }}>{`Something went wrong`}</Text>
                              ) : null}
                            </SubmissionRow>
                          </Fragment>
                        ))}
                      </SubmissionSection>
                      <SubmissionRow>
                        {/* Reason For Discount Rate */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason for Discount Rate`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.reason_for_discount_rate || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Memo for Legal or Finance */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Memo for Legal or Finance`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.memo || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-seal',
                name: 'Seal',
                content: (
                  <>
                    <SubmissionSection>
                      <SubmissionSectionHeader>
                        <Text semibold>Seal Details</Text>
                      </SubmissionSectionHeader>
                      <SubmissionRow>
                        {/* Assignee */}
                        <SubmissionColumn>
                          <Caption bold css={{ color: '$secondary40' }}>{`Assignee`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.assignee.name || '-'}`}</Text>
                        </SubmissionColumn>
                        {/* Application Unit */}
                        <SubmissionColumn>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Application Unit`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.application_unit || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Seal Type */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption bold css={{ color: '$secondary40' }}>{`Seal Type`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.seal_type.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Deliver To */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{
                              color: '$secondary40',
                            }}>{`Deliver To`}</Caption>
                          <Text
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.deliver_to.name || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                      <SubmissionRow>
                        {/* Reason For Application */}
                        <SubmissionColumn widthRatio={10}>
                          <Caption
                            bold
                            css={{ color: '$secondary40' }}>{`Reason for Application`}</Caption>
                          <Text
                            as="p"
                            css={{
                              mt: '$1',
                            }}>{`${postStdAppPayload.reason_for_application || '-'}`}</Text>
                        </SubmissionColumn>
                      </SubmissionRow>
                    </SubmissionSection>
                  </>
                ),
              },
            ]}
          />
        </SubmissionPaper>
        <SubmissionPaper>
          <Disclosure
            panels={[
              {
                id: 'section-attachment',
                name: 'Attachment',
                content: (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                    {isNoAttachment({ attachments: postStdAppAttPayload }) ? (
                      <Text>-</Text>
                    ) : (
                      <>
                        {postStdAppAttPayload.fsr_mail &&
                        postStdAppAttPayload.fsr_mail.length > 0 ? (
                          <>
                            {postStdAppAttPayload.fsr_mail.map((fsrMailFile) => (
                              <FileBlobCard key={nanoid()} file={fsrMailFile} needAction={false} />
                            ))}
                          </>
                        ) : null}
                        {postStdAppAttPayload.company_registration ? (
                          <FileBlobCard
                            file={postStdAppAttPayload.company_registration}
                            needAction={false}
                          />
                        ) : null}
                        {postStdAppAttPayload.ceo_approval ? (
                          <FileBlobCard
                            file={postStdAppAttPayload.ceo_approval}
                            needAction={false}
                          />
                        ) : null}
                        {postStdAppAttPayload.gm_approval ? (
                          <FileBlobCard
                            file={postStdAppAttPayload.gm_approval}
                            needAction={false}
                          />
                        ) : null}
                        {postStdAppAttPayload.random_file &&
                        postStdAppAttPayload.random_file.length > 0 ? (
                          <>
                            {postStdAppAttPayload.random_file.map((randomFile) => (
                              <FileBlobCard key={nanoid()} file={randomFile} needAction={false} />
                            ))}
                          </>
                        ) : null}
                        {postStdAppAttPayload.seal_file &&
                        postStdAppAttPayload.seal_file.length > 0 ? (
                          <>
                            {postStdAppAttPayload.seal_file.map((sealFile) => (
                              <FileBlobCard key={nanoid()} file={sealFile} needAction={false} />
                            ))}
                          </>
                        ) : null}
                      </>
                    )}
                  </Box>
                ),
              },
            ]}
          />
        </SubmissionPaper>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '80px' }} />
      {/* Wizard Bar */}
      <WizardBar
        css={{ left: navBarWidth }}
        animate={isNavBarOpen ? 'shrink' : 'grow'}
        variants={fixedLayoutGrowMotion(navBarWidth)}>
        {/* WizardBar Left*/}
        {/* Cancel */}
        <DialogAlertRoot>
          {/* Cancel Dialog Trigger */}
          <DialogAlertTrigger asChild>
            <Button
              size="md"
              variant="borderless"
              color="achromatic"
              css={{ mr: 'auto' }}
              data-track-id="btn-dialog-trigger-cancel">
              <ButtonText size={14} bold>
                Cancel
              </ButtonText>
            </Button>
          </DialogAlertTrigger>
          <DialogAlertPortal>
            <DialogAlertOverlay />
            {/* Cancel Dialog Content */}
            <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: '655px' }}>
              <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                {/* Cancel Dialog Header */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                  <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                  <DialogAlertTitle asChild>
                    <Heading5 css={{ flexGrow: 1 }}>{`Leave site`}</Heading5>
                  </DialogAlertTitle>
                </Box>
                {/* Cancel Dialog Body */}
                <DialogAlertDescription css={{ pl: 40 }}>
                  <Text css={{ color: '$text-secondary' }}>
                    Are you sure you want to leave this page?
                  </Text>
                </DialogAlertDescription>
                {/* Cancel Dialog Footer */}
                <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                  {/* Leave */}
                  <DialogAlertCancel asChild>
                    <Button
                      variant="borderless"
                      color="achromatic"
                      size="md"
                      onClick={() => onLeaveClick()}
                      data-track-id="btn-leave">
                      <ButtonText size={14} bold>
                        Leave
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                  {/* Save */}
                  {appId && postStdAppPayload.status === 'rejected' ? (
                    <Button
                      variant="outlined"
                      size="md"
                      onClick={() => onSaveClick()}
                      isLoading={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      css={{ width: appId ? 67 : 123 }}
                      data-track-id="btn-save">
                      <ButtonText size={14} bold>
                        {appId ? 'Save' : 'Save as draft'}
                      </ButtonText>
                    </Button>
                  ) : null}
                  {/* Stay */}
                  <DialogAlertCancel asChild>
                    <Button
                      size="md"
                      disabled={
                        postAttachmentMutation.isLoading ||
                        postAppMutation.isLoading ||
                        patchAppMutation.isLoading
                      }
                      data-track-id="btn-stay">
                      <ButtonText size={14} bold>
                        Stay on this page
                      </ButtonText>
                    </Button>
                  </DialogAlertCancel>
                </Box>
              </Box>
            </DialogAlertContent>
          </DialogAlertPortal>
        </DialogAlertRoot>
        {/* WizardBar Right */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 20, ml: 'auto' }}>
          {/* Back */}
          <Button
            size="md"
            variant="outlined"
            onClick={() => onBackClick()}
            data-track-id="btn-back">
            <ButtonText size={14} bold>
              Back
            </ButtonText>
          </Button>
          {/* Submit */}
          <DialogAlertRoot open={isSubmitDialogOpen}>
            {/* Submit Dialog Trigger */}
            <DialogAlertTrigger asChild>
              <Button
                type="submit"
                size="md"
                css={{ width: 82 }}
                data-track-id="btn-dialog-trigger-submit">
                <ButtonText size={14} bold>
                  Submit
                </ButtonText>
              </Button>
            </DialogAlertTrigger>
            <DialogAlertPortal>
              <DialogAlertOverlay />
              <DialogAlertContent css={{ px: 30, py: 20, borderRadius: '$10', maxWidth: 655 }}>
                {postStdAppCondition.isLoading ? (
                  <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30, py: 30 }}>
                    <Spinner size="medium" />
                  </Box>
                ) : postStdAppCondition.isSuccess ? (
                  <>
                    {/* Submit Dialog Content (Condition Met) */}
                    {submitDialog.type === 'conditionMet' ? (
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                        {/* Submit Dialog Header (Condition Met) */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                          <MSymbol iconName="error" weight={700} css={{ color: '$error60' }} />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{
                                flexGrow: 1,
                              }}>{`Automatically initiate the approval process`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Submit Dialog Body (Condition Met) */}
                        <DialogAlertDescription css={{ pl: 40 }}>
                          <Text>
                            Due to the nature of the application involving the following conditions:
                          </Text>
                          {submitDialog.conditions.map((condition, submitDialogConditionIndex) => (
                            <Text key={nanoid()} css={{ color: '$warning90' }}>{`${
                              submitDialogConditionIndex + 1
                            }. ${condition}`}</Text>
                          ))}
                          <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
                          <Text>
                            Once you submit the form, the system will automatically initiate the
                            approval process for this application. Do you agree to proceed with the
                            submission?
                          </Text>
                          <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
                          {/* Execute the "Sealing" process directly */}
                          <CheckText
                            text={`Execute the "Sealing" process directly`}
                            checked={postStdAppPayload.is_cm_seal_first}
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            onChecked={(newValue) => onCMSealFirstCheck(newValue)}
                          />
                          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                          {/* Urgent application request */}
                          <CheckText
                            text={`Urgent application request`}
                            checked={postStdAppPayload.urgent_level === 1}
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            onChecked={(newValue) => onUrgentRequestCheck(newValue)}
                          />
                        </DialogAlertDescription>
                        {/* Submit Dialog Footer (Condition Met) */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          {/* Submit */}
                          <Button
                            size="md"
                            css={{ width: 94.5 }}
                            onClick={onGenerateClick}
                            isLoading={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            data-track-id="btn-condition-met-submit">
                            <ButtonText size={14} bold>
                              Submit
                            </ButtonText>
                          </Button>
                          {/* Cancel */}
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={
                                postAttachmentMutation.isLoading ||
                                postAppMutation.isLoading ||
                                patchAppMutation.isLoading
                              }
                              onClick={onSubmitCancel}
                              data-track-id="btn-condition-met-cancel">
                              <ButtonText size={14} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    ) : null}
                    {/* Submit Dialog Content (Condition Not Met) */}
                    {submitDialog.type === 'conditionNotMet' ? (
                      <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                        {/* Submit Dialog Header (Condition Not Met) */}
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 15 }}>
                          <MSymbol
                            iconName="check_circle"
                            weight={700}
                            css={{ color: '$success70' }}
                          />
                          <DialogAlertTitle asChild>
                            <Heading5
                              css={{ flexGrow: 1 }}>{`Automatically generated Documents`}</Heading5>
                          </DialogAlertTitle>
                        </Box>
                        {/* Submit Dialog Body (Condition Not Met) */}
                        <DialogAlertDescription css={{ pl: 40 }}>
                          <Text css={{ color: '$text-secondary' }}>
                            Once you submit this application, the system will automatically create
                            the Agreement and Quotation.
                          </Text>
                          <Spacer axis={'vertical'} css={{ $$size: '30px' }} />
                          {/* Execute the "Sealing" process directly */}
                          <CheckText
                            text={`Execute the "Sealing" process directly`}
                            checked={postStdAppPayload.is_cm_seal_first}
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            onChecked={(newValue) => onCMSealFirstCheck(newValue)}
                          />
                        </DialogAlertDescription>
                        {/* Submit Dialog Footer (Condition Not Met) */}
                        <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                          {/* Generate */}
                          <Button
                            size="md"
                            css={{ width: 94.5 }}
                            onClick={onGenerateClick}
                            isLoading={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            disabled={
                              postAttachmentMutation.isLoading ||
                              postAppMutation.isLoading ||
                              patchAppMutation.isLoading
                            }
                            data-track-id="btn-condition-not-met-submit">
                            <ButtonText size={14} bold>
                              Generate
                            </ButtonText>
                          </Button>
                          {/* Cancel */}
                          <DialogAlertCancel asChild>
                            <Button
                              size="md"
                              variant="borderless"
                              color="achromatic"
                              disabled={
                                postAttachmentMutation.isLoading ||
                                postAppMutation.isLoading ||
                                patchAppMutation.isLoading
                              }
                              onClick={onSubmitCancel}
                              data-track-id="btn-condition-not-met-cancel">
                              <ButtonText size={14} bold>
                                Cancel
                              </ButtonText>
                            </Button>
                          </DialogAlertCancel>
                        </Box>
                      </Box>
                    ) : null}
                  </>
                ) : postStdAppCondition.isError ? (
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 30,
                    }}>
                    <CenteredBoxFlex>
                      <Box as="img" src={ErrorBG} />
                      <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                      <Text semibold css={{ textAlign: 'center' }}>{`Something went wrong`}</Text>
                    </CenteredBoxFlex>
                    <Box css={{ display: 'flex', columnGap: 20, justifyContent: 'flex-end' }}>
                      <DialogAlertCancel asChild>
                        <Button
                          size="md"
                          variant="borderless"
                          color="achromatic"
                          onClick={onSubmitCancel}
                          data-track-id="btn-condition-error-cancel">
                          <ButtonText size={14} bold>
                            Cancel
                          </ButtonText>
                        </Button>
                      </DialogAlertCancel>
                    </Box>
                  </Box>
                ) : null}
              </DialogAlertContent>
            </DialogAlertPortal>
          </DialogAlertRoot>
        </Box>
      </WizardBar>
    </form>
  );
};

export default Confirmation;
