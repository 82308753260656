import { ChangeEvent, ComponentProps, useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { authUserAtom } from '../../store/atoms';
import {
  useGetApplicationCommentById,
  usePutApplicationCommentById,
  useDeleteApplicationCommentById,
  useGetApplicationMentionableUserById,
} from '../../services/application/official-standard/application.api';
import { checkNDaysAgo } from '../../utils/check-n-days-ago';
import { checkAttachmentsLength } from '../../utils/check-checked';
import moment from 'moment';
import { motion } from 'framer-motion';
import { styled } from '../../styles/stitches.config';
import { MentionsInput, Mention } from 'react-mentions';
import Box from '../box';
import Spinner from '../helper/spinner';
import Typography from '../typography/deprecated-typography';
import Spacer from '../surface/spacer';
import Button from '../button/deprecated-button';
import Avatar from '../surface/avatar';
import MSymbol from '../icon/m-symbol';
import MentionArea from '../dropdown/mention-area';
import MentionedTypography from '../typography/mentioned-typography';
import ColorfulChip from '../chip/colorful-chip';
import IconButton from '../button/icon-button';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../helper/tool-tip';
import ErrorMonoBG from '../../assets/images/bg-error-mono.svg';
import AttachPDFLightOnIcon from '../../assets/images/icon-attach-pdf-light-on.svg';
import PopupErrorIcon from '../../assets/images/icon-popup-error.svg';
import { getFileObjects } from '../../utils/get-file-object';

const COMMENT_HEADER_HEIGHT = 64;
const IMAGE_LIMIT = 8;
const PDF_LIMIT = 2;
const ALLOWED_FILE_MAX_SIZE = 2; // * MB

const StyledBox = styled('div', {
  p: 10,
  borderRadius: '$5',
  border: '1px solid $secondary20',
  transition: '$colors $opacity',
  maxWidth: 280,
});

const StyledMentionsInput = styled(MentionsInput, {
  minHeight: 140,
  width: 236,
  '& > [class$="__control"]': {
    fontSize: 14,
    '& > [class$="__highlighter"]': {
      '& > [class$="__mention"]': {
        position: 'relative',
        zIndex: 1,
        color: '$primary60',
        textShadow: '-1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white, 1px 1px 0 white',
        pointerEvents: 'none',
        backgroundColor: '$white',
      },
    },
    '& > [class$="__input"]': {
      border: 'none',
      outline: 'none',
    },
  },
  '& > [class$="__suggestions"]': {
    boxShadow: '$basic',
    p: 10,
    borderRadius: '$5',
    width: 'max-content',
    backgroundColor: '$white',
    maxHeight: 280,
    overflow: 'auto',
    zIndex: '99 !important',
    '& > [class$="__suggestions__list"]': {
      '& > [class$="__suggestions__item"], > [class$="__suggestions__item--focused"]': {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        height: 30,
        transition: '$colors',
        borderRadius: '$5',
        px: 10,
      },
      '& > [class$="__suggestions__item--focused"]': {
        backgroundColor: '$secondary3',
        '&:active': {
          backgroundColor: '$secondary10',
        },
      },
    },
  },
});

interface CommentCardProps extends ComponentProps<typeof StyledBox> {
  applicationId?: string;
  commentId?: string;
}

const CommentCard = ({ applicationId, commentId, ...restProps }: CommentCardProps) => {
  const authUser = useAtomValue(authUserAtom);
  const { name, picture } = authUser || {};
  const [isEditEditorOpen, setIsEditEditorOpen] = useState(false);
  const [editContents, setEditContents] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isSelectedFilesLimitExceeded, setIsSelectedFilesLimitExceeded] = useState(false);
  const [isImageFileSizeOver, setIsImageFileSizeOver] = useState(false);
  const [isFileSizeOver, setIsFileSizeOver] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleOnEditEditorClose = () => {
    setEditContents('');
    setSelectedFiles([]);
    setIsEditEditorOpen(false);
    setIsFileSizeOver(false);
    setIsImageFileSizeOver(false);
    setIsSelectedFilesLimitExceeded(false);
  };

  const mentionableUserQueries = useGetApplicationMentionableUserById({
    application_id: applicationId || '',
  });
  const commentByIdQueries = useGetApplicationCommentById({
    application_id: applicationId || '',
    comment_id: commentId || '',
  });
  const putCommentByIdMutation = usePutApplicationCommentById({
    application_id: applicationId || '',
    comment_id: commentId || '',
    onSuccess: () => {
      if (isEditEditorOpen) {
        handleOnEditEditorClose();
      }
    },
  });
  const deleteCommentByIdMutation = useDeleteApplicationCommentById({
    application_id: applicationId || '',
    comment_id: commentId || '',
  });

  const handleOnDeleteClick = () => {
    deleteCommentByIdMutation.mutate();
  };
  const handleOnEditClick = async () => {
    if (commentByIdQueries.data) {
      setEditContents(commentByIdQueries.data.content || '');
      const existedFiles = await getFileObjects([
        ...commentByIdQueries.data.images,
        ...commentByIdQueries.data.pdfs,
      ]);
      setSelectedFiles(existedFiles || []);
    }
    setIsEditEditorOpen(true);
  };
  const handleOnResolveComment = (isResolved: boolean) => {
    putCommentByIdMutation.mutate({ is_resolved: !isResolved });
  };
  const handleOnEditComment = () => {
    const payloadSelectedFiles = selectedFiles.reduce((payload, file, index) => {
      const key = `file_${index + 1}`;
      payload[key] = file;
      return payload;
    }, {});
    putCommentByIdMutation.mutate({ content: editContents, ...payloadSelectedFiles });
  };
  const handleOnFileChosen = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleOnFileCancelChosen = (fileName: string) => {
    const indexToCancelChosen = selectedFiles.findIndex((file) => file.name === fileName);
    setSelectedFiles((prev) => prev.filter((_, index) => index !== indexToCancelChosen));
  };
  const handleOnFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles((prev) => {
        const currentSelectedFiles = Array.prototype.slice.call(e.target.files);
        if (
          checkAttachmentsLength({ fileType: 'image', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'image', files: prev }) >
            IMAGE_LIMIT ||
          checkAttachmentsLength({ fileType: 'pdf', files: currentSelectedFiles }) +
            checkAttachmentsLength({ fileType: 'pdf', files: prev }) >
            PDF_LIMIT
        ) {
          setIsSelectedFilesLimitExceeded(true);
          return [...prev];
        }
        setIsSelectedFilesLimitExceeded(false);
        return [...prev, ...currentSelectedFiles];
      });
    }
  };

  useEffect(() => {
    const hasImageFileSizeOver = selectedFiles
      .filter(
        (file) =>
          file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      )
      .some((file) => file.size > 2 * 1024 * 1024);
    const hasFileSizeOver = selectedFiles.some((file) => file.size > 2 * 1024 * 1024);
    setIsImageFileSizeOver(hasImageFileSizeOver);
    setIsFileSizeOver(hasFileSizeOver);
  }, [selectedFiles]);

  return (
    <>
      <StyledBox {...restProps}>
        {commentByIdQueries.isLoading ? (
          <Box
            css={{
              minHeight: 150,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </Box>
        ) : commentByIdQueries.isError ? (
          <Box
            onClick={(e) => e.stopPropagation()}
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '$5',
            }}>
            <Box as="img" src={ErrorMonoBG} />
            <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
              <Typography variant="h6">{`Something's gone wrong`}</Typography>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Typography variant="body-medium">
                {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
              </Typography>
            </Box>
            <Button
              variant="solid"
              size={'small'}
              isLoading={commentByIdQueries.isLoading}
              onClick={() => {
                commentByIdQueries.refetch();
              }}>{`Refresh`}</Button>
          </Box>
        ) : commentByIdQueries.isSuccess ? (
          isEditEditorOpen ? (
            <>
              {/* Edit Editor */}
              <Box
                as={motion.div}
                initial="hide"
                animate={isEditEditorOpen ? 'show' : 'hide'}
                variants={{
                  show: { opacity: 1, display: 'flex' },
                  hide: {
                    opacity: 0,
                    transitionEnd: { display: 'none' },
                  },
                }}
                css={{
                  mb: 10,
                  borderRadius: '$5',
                  flexDirection: 'column',
                  rowGap: 10,
                  width: 258,
                }}>
                {/* Avatar */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6, width: '100%' }}>
                  <Avatar name={name || '-'} picture={picture || ''} />
                  <Box css={{ flexGrow: 1 }}>
                    <Typography as={'p'} variant={'body-bold'}>
                      {name || '-'}
                    </Typography>
                  </Box>
                  {/* Close Button */}
                  <Box css={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton iconName="close" size="small" onClick={handleOnEditEditorClose} />
                  </Box>
                </Box>
                {/* React Mentions */}
                <MentionArea
                  value={editContents}
                  onChange={(e, newValue) => setEditContents(newValue)}>
                  <Mention
                    trigger="@"
                    className={`${MentionArea.className}__mention`}
                    appendSpaceOnAdd={true}
                    displayTransform={(id, display) => {
                      return `@${display}`;
                    }}
                    data={mentionableUserQueries.data || []}
                  />
                </MentionArea>
                {/* Selected attachments (Images) */}
                {checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 0 ? (
                  <>
                    <Box
                      css={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        rowGap: 6,
                        columnGap:
                          checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 2
                            ? 6
                            : 10,
                        height:
                          checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) < 3 ||
                          checkAttachmentsLength({ fileType: 'image', files: selectedFiles }) > 4
                            ? 125
                            : 60,
                      }}>
                      {selectedFiles
                        .filter(
                          (file) =>
                            file.type === 'image/jpg' ||
                            file.type === 'image/jpeg' ||
                            file.type === 'image/png'
                        )
                        .map((image) => (
                          <Box
                            key={image.name}
                            css={{
                              borderRadius: '$10',
                              overflow: 'hidden',
                              flexBasis:
                                checkAttachmentsLength({
                                  fileType: 'image',
                                  files: selectedFiles,
                                }) > 2
                                  ? `calc(25% - ${18 / 4}px)`
                                  : checkAttachmentsLength({
                                      fileType: 'image',
                                      files: selectedFiles,
                                    }) === 2
                                  ? `calc(50% - 5px)`
                                  : `calc(100%)`,
                              border: '1px solid $secondary10',
                              height:
                                checkAttachmentsLength({
                                  fileType: 'image',
                                  files: selectedFiles,
                                }) > 4
                                  ? 'calc(50% - 3px)'
                                  : '100%',
                              position: 'relative',
                              '&:hover': {
                                '> .attachment-mask': {
                                  backgroundColor: 'rgba(0,0,0,0.3)',
                                },
                                '> .attachment-cancel-chosen': {
                                  visibility: 'visible',
                                },
                              },
                            }}>
                            <IconButton
                              iconName="close"
                              isIconBold={true}
                              size={'mini'}
                              className="attachment-cancel-chosen"
                              css={{
                                position: 'absolute',
                                top: 6,
                                right: 6,
                                zIndex: 90,
                                color: '$white',
                                visibility: 'hidden',
                              }}
                              onClick={() => handleOnFileCancelChosen(image.name)}
                            />
                            <Box
                              className="attachment-mask"
                              css={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                zIndex: 85,
                                transition: '$colors',
                                backgroundColor: '$transparent',
                              }}
                            />
                            {Math.round((image.size / (1024 * 1024)) * 100) / 100 >
                            ALLOWED_FILE_MAX_SIZE ? (
                              <Box
                                css={{
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  left: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                <Box as="img" src={PopupErrorIcon} css={{ width: 30 }}></Box>
                              </Box>
                            ) : null}
                            <Box
                              as="img"
                              src={URL.createObjectURL(image)}
                              css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                ) : null}
                {/* Selected attachments (PDFs) */}
                {checkAttachmentsLength({ fileType: 'pdf', files: selectedFiles }) > 0 ? (
                  <>
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 10,
                      }}>
                      {selectedFiles
                        .filter((file) => file.type === 'application/pdf')
                        .map((pdf) => (
                          <Box
                            key={pdf.name}
                            css={{
                              position: 'relative',
                              borderRadius: '$5',
                              overflow: 'hidden',
                              '&:hover': {
                                '> .attachment-mask': {
                                  backgroundColor: 'rgba(0,0,0,0.3)',
                                },
                                '> .attachment-cancel-chosen': {
                                  visibility: 'visible',
                                },
                              },
                            }}>
                            <IconButton
                              iconName="close"
                              isIconBold={true}
                              size={'mini'}
                              className="attachment-cancel-chosen"
                              css={{
                                position: 'absolute',
                                top: 6,
                                right: 6,
                                zIndex: 90,
                                color: '$white',
                                visibility: 'hidden',
                              }}
                              onClick={() => handleOnFileCancelChosen(pdf.name)}
                            />
                            <Box
                              className="attachment-mask"
                              css={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                zIndex: 85,
                                transition: '$colors',
                                backgroundColor: '$transparent',
                              }}
                            />
                            <Box
                              css={{
                                backgroundColor: '$secondary5',
                                px: 5,
                                py: 6,
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: 6,
                                justifyContent: 'space-between',
                              }}>
                              <Box as="img" src={AttachPDFLightOnIcon} />
                              <Box
                                css={{
                                  flexGrow: 1,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: 206,
                                }}>
                                <Typography
                                  as="p"
                                  variant={'body-medium'}
                                  css={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}>
                                  {pdf.name || '-'}
                                </Typography>
                                <Typography
                                  as="p"
                                  variant={'caption'}
                                  css={{
                                    color: '$secondary40',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}>
                                  {`${Math.round((pdf.size / (1024 * 1024)) * 100) / 100} MB`}
                                </Typography>
                                {Math.round((pdf.size / (1024 * 1024)) * 100) / 100 >
                                ALLOWED_FILE_MAX_SIZE ? (
                                  <Typography
                                    as="p"
                                    variant={'caption'}
                                    css={{
                                      color: '$error70',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}>
                                    {`Maximum file size 2MB`}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </>
                ) : null}
                {/* Attachments */}
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                  {/* Select File Button */}
                  <TooltipRoot>
                    <TooltipTrigger asChild>
                      <Box css={{ width: 'fit-content' }}>
                        <IconButton
                          iconName="attach_file"
                          color={'secondary'}
                          onClick={handleOnFileChosen}
                        />
                        <input
                          ref={hiddenFileInput}
                          type="file"
                          multiple
                          accept="application/pdf, image/png, image/jpg, image/jpeg"
                          style={{ display: 'none' }}
                          onChange={handleOnFileChange}
                        />
                      </Box>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent
                        sideOffset={5}
                        side={`top`}
                        css={{
                          px: 16,
                          py: 4.5,
                        }}>
                        <Typography
                          variant={'body'}
                          css={{ color: '$white' }}>{`Attach a file`}</Typography>
                        <TooltipArrow />
                      </TooltipContent>
                    </TooltipPortal>
                  </TooltipRoot>
                  {/* Selected files limit exceeded */}
                  <Box
                    as={motion.div}
                    initial="hide"
                    animate={isSelectedFilesLimitExceeded || isImageFileSizeOver ? 'show' : 'hide'}
                    variants={{
                      show: { display: 'flex', opacity: 1 },
                      hide: { opacity: 0, transitionEnd: { display: 'none' } },
                    }}
                    css={{ alignItems: 'center' }}>
                    {isSelectedFilesLimitExceeded ? (
                      <Typography
                        variant={'caption'}
                        css={{
                          color: '$error70',
                        }}>{`File limitation : Up to 8 images and a maximum of 2 PDFs.`}</Typography>
                    ) : null}
                    {isImageFileSizeOver ? (
                      <Typography
                        as="p"
                        variant={'caption'}
                        css={{
                          color: '$error70',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                        {`Maximum file size 2MB`}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
                {/* Cancel, Save Button */}
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 10,
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}>
                  <Button
                    size="tiny"
                    color="secondary-primary"
                    variant="outlined"
                    onClick={handleOnEditEditorClose}>{`Cancel`}</Button>
                  <Button
                    size="tiny"
                    onClick={handleOnEditComment}
                    disabled={isFileSizeOver}
                    customWidth={54}
                    isLoading={putCommentByIdMutation.isLoading}>{`Save`}</Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box css={{ display: 'flex', columnGap: 6, alignItems: 'center' }}>
                {/* Avatar */}
                <Avatar
                  name={commentByIdQueries.data.editor_name || '-'}
                  picture={commentByIdQueries.data.editor_picture || ''}
                />
                {/* Name, Time */}
                <Box css={{ flexGrow: 1 }}>
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                    <Typography as={'p'} variant={'body-bold'}>
                      {commentByIdQueries.data.editor_name || '-'}
                    </Typography>
                    {checkNDaysAgo({ time: commentByIdQueries.data.edited_at }) ? null : (
                      <ColorfulChip text="New" color="tangerine" variant="solid" dense />
                    )}
                  </Box>
                  <Typography as={'p'} variant={'caption'} css={{ color: '$secondary70' }}>
                    {commentByIdQueries.data.edited_at
                      ? checkNDaysAgo({ time: commentByIdQueries.data.edited_at })
                        ? moment(commentByIdQueries.data.edited_at).format('MM/DD/YY HH:mm A (Z)')
                        : moment(commentByIdQueries.data.edited_at).fromNow()
                      : '-'}
                  </Typography>
                </Box>
                {/* Resolve */}
                <Box css={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    css={{ cursor: 'pointer' }}
                    onClick={() => handleOnResolveComment(commentByIdQueries.data.is_resolved)}>
                    <MSymbol
                      iconName="check_circle"
                      size={20}
                      css={{
                        color: commentByIdQueries.data.is_resolved ? '$success70' : '$secondary70',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
              {/* Content */}
              <MentionedTypography
                message={commentByIdQueries.data?.content || 'No comments left by the commenter.'}
                variant={'caption'}
                tagVariant={'caption-bold'}
                ellipsisLine="revert"
              />
              {/* Attachments after filing (Images) */}
              {commentByIdQueries.data.images?.length > 0 ? (
                <>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  <Box
                    css={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      rowGap: 6,
                      columnGap: commentByIdQueries.data.images?.length > 2 ? 6 : 10,
                      height:
                        commentByIdQueries.data.images?.length < 3 ||
                        commentByIdQueries.data.images?.length > 4
                          ? 125
                          : 60,
                    }}>
                    {commentByIdQueries.data.images.map((image) => (
                      <Box
                        key={image.id}
                        css={{
                          borderRadius: '$10',
                          overflow: 'hidden',
                          flexBasis:
                            commentByIdQueries.data.images?.length > 2
                              ? `calc(25% - ${18 / 4}px)`
                              : commentByIdQueries.data.images?.length === 2
                              ? `calc(50% - 5px)`
                              : `calc(100%)`,
                          border: '1px solid $secondary10',
                          height:
                            commentByIdQueries.data.images?.length > 4 ? 'calc(50% - 3px)' : '100%',
                        }}>
                        <a href={`${image.url || ''}`} target="_blank" rel="noreferrer">
                          <Box
                            as="img"
                            src={image.url}
                            css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          />
                        </a>
                      </Box>
                    ))}
                  </Box>
                </>
              ) : null}
              {/* Attachments after filing (PDFs) */}
              {commentByIdQueries.data.pdfs?.length > 0 ? (
                <>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 6,
                    }}>
                    {commentByIdQueries.data.pdfs.map((pdf) => (
                      <a key={pdf.id} href={`${pdf.url || ''}`} target="_blank" rel="noreferrer">
                        <Box
                          css={{
                            borderRadius: '$5',
                            px: 5,
                            py: 6,
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 6,
                            justifyContent: 'space-between',
                            backgroundColor: '$secondary3',
                            transition: '$colors',
                            '&:hover': { backgroundColor: '$primary10' },
                            '&:active': { backgroundColor: '$primary20' },
                          }}>
                          <Box as="img" src={AttachPDFLightOnIcon} />
                          <Box
                            css={{
                              flexGrow: 1,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: 206,
                            }}>
                            <Typography
                              as="p"
                              variant={'body-medium'}
                              css={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {pdf.file_name || '-'}
                            </Typography>
                            <Typography
                              as="p"
                              variant={'caption'}
                              css={{
                                color: '$secondary40',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {pdf.updated_at
                                ? moment(pdf.updated_at).format('MM/DD/YY HH:mm A (Z)')
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </a>
                    ))}
                  </Box>
                </>
              ) : null}
              {commentByIdQueries.data.comment_action_map.show_delete ||
              commentByIdQueries.data.comment_action_map.show_edit ? (
                <>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  <Box
                    css={{
                      display: 'flex',
                      columnGap: 4,
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    {commentByIdQueries.data.comment_action_map.show_delete ? (
                      <Button
                        variant="borderless"
                        color="error"
                        customWidth={64}
                        size="tiny"
                        isLoading={deleteCommentByIdMutation.isLoading}
                        onClick={handleOnDeleteClick}>{`Delete`}</Button>
                    ) : null}
                    {commentByIdQueries.data.comment_action_map.show_edit ? (
                      <Button
                        variant="borderless"
                        color="secondary"
                        size="tiny"
                        onClick={handleOnEditClick}>{`Edit`}</Button>
                    ) : null}
                  </Box>
                </>
              ) : null}
            </>
          )
        ) : null}
      </StyledBox>
      <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
    </>
  );
};

export default CommentCard;
