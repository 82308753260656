import {
  ApprovalProgressSummary,
  GetMyAssignmentTotalPayload,
  GetMyAssignmentTotalResult,
  GetMyAssignmentListInfinitePayload,
  GetMyAssignmentListInfiniteResult,
  GetMyAssignmentPayload,
  GetMyAssignmentResult,
  GetMyMentionedCommentResult,
  GetMyMentionedFeedbackResult,
} from '../../types/home/home';
import { CommonAPIResponse } from '../../types';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useAxios } from '../../hooks';

const ASSIGNMENT_LIST_LIMIT = 5;

export const useGetApprovalProgressSummary = () => {
  const axios = useAxios();
  return useQuery<ApprovalProgressSummary, AxiosError<CommonAPIResponse>>(
    ['home', 'approval-progress-summary'],
    async () => {
      const { data } = await axios.get(`/v1/complete_application_summary`);
      return data;
    }
  );
};

export const useGetAppliedByMeTotal = ({ search }: GetMyAssignmentTotalPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentTotalResult, AxiosError<CommonAPIResponse>>(
    ['home', 'applied-by-me', 'total', search],
    async () => {
      const { data } = await axios.get(`/v1/home_applications_applied_by_me_total`, {
        params: { search },
      });
      return data;
    }
  );
};
export const useGetAppliedByMeListInfinite = ({
  search,
  sort_by = 'modified_at',
  order_by = 'desc',
}: GetMyAssignmentListInfinitePayload) => {
  const axios = useAxios();
  return useInfiniteQuery<GetMyAssignmentListInfiniteResult, AxiosError<CommonAPIResponse>>(
    ['home', 'applied-by-me', 'list', search],
    async ({
      pageParam = {
        first_task_id: undefined,
        offset: 0,
        limit: ASSIGNMENT_LIST_LIMIT,
        sort_by,
        order_by,
        search,
      },
    }) => {
      const { data } = await axios.get(`/v1/home_applications_applied_by_me/`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination_info?.next_offset
          ? {
              offset: lastPage.pagination_info.next_offset,
              limit: ASSIGNMENT_LIST_LIMIT,
              first_task_id: lastPage.pagination_info.first_task_id,
              sort_by,
              order_by,
              search,
            }
          : undefined;
      },
    }
  );
};
export const useGetAppliedByMe = ({ appId, assignmentType }: GetMyAssignmentPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentResult, AxiosError<CommonAPIResponse>>(
    ['home', 'applied-by-me', appId],
    async () => {
      const { data } = await axios.get(`/v1/home_applications_applied_by_me/${appId}`);
      return data;
    },
    { enabled: !!appId && assignmentType === 'appliedByMe' }
  );
};

export const useGetAssignedToMeTotal = ({ search }: GetMyAssignmentTotalPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentTotalResult, AxiosError<CommonAPIResponse>>(
    ['home', 'assigned-to-me', 'total', search],
    async () => {
      const { data } = await axios.get(`/v1/home_applications_assigned_to_me_total`, {
        params: { search },
      });
      return data;
    }
  );
};
export const useGetAssignedToMeListInfinite = ({
  search,
  sort_by = 'modified_at',
  order_by = 'asc',
}: GetMyAssignmentListInfinitePayload) => {
  const axios = useAxios();
  return useInfiniteQuery<GetMyAssignmentListInfiniteResult, AxiosError<CommonAPIResponse>>(
    ['home', 'assigned-to-me', 'list', search],
    async ({
      pageParam = {
        first_task_id: undefined,
        offset: 0,
        limit: ASSIGNMENT_LIST_LIMIT,
        sort_by,
        order_by,
        search,
      },
    }) => {
      const { data } = await axios.get(`/v1/home_applications_assigned_to_me/`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination_info?.next_offset
          ? {
              offset: lastPage.pagination_info.next_offset,
              limit: ASSIGNMENT_LIST_LIMIT,
              first_task_id: lastPage.pagination_info.first_task_id,
              sort_by,
              order_by,
              search,
            }
          : undefined;
      },
    }
  );
};
export const useGetAssignedToMe = ({ appId, assignmentType }: GetMyAssignmentPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentResult, AxiosError<CommonAPIResponse>>(
    ['home', 'assigned-to-me', appId],
    async () => {
      const { data } = await axios.get(`/v1/home_applications_assigned_to_me/${appId}`);
      return data;
    },
    { enabled: !!appId && assignmentType === 'assignedToMe' }
  );
};

export const useGetMentionedCommentTotal = ({ search }: GetMyAssignmentTotalPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentTotalResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-comment', 'total', search],
    async () => {
      const { data } = await axios.get(`/v1/home_mentioned_comment_total`, {
        params: { search },
      });
      return data;
    }
  );
};
export const useGetMentionedCommentListInfinite = ({
  search,
  sort_by = 'modified_at',
  order_by = 'desc',
}: GetMyAssignmentListInfinitePayload) => {
  const axios = useAxios();
  return useInfiniteQuery<GetMyAssignmentListInfiniteResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-comment', 'list', search],
    async ({
      pageParam = {
        first_task_id: undefined,
        offset: 0,
        limit: ASSIGNMENT_LIST_LIMIT,
        sort_by,
        order_by,
        search,
      },
    }) => {
      const { data } = await axios.get(`/v1/home_mentioned_comment/`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination_info?.next_offset
          ? {
              offset: lastPage.pagination_info.next_offset,
              limit: ASSIGNMENT_LIST_LIMIT,
              first_task_id: lastPage.pagination_info.first_task_id,
              sort_by,
              order_by,
              search,
            }
          : undefined;
      },
    }
  );
};
export const useGetMentionedComment = ({ commentId }: GetMyAssignmentPayload) => {
  const axios = useAxios();
  return useQuery<GetMyMentionedCommentResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-comment', commentId],
    async () => {
      const { data } = await axios.get(`/v1/home_mentioned_comment/${commentId}`);
      return data;
    },
    { enabled: !!commentId }
  );
};

export const useGetMentionedFeedbackTotal = ({ search }: GetMyAssignmentTotalPayload) => {
  const axios = useAxios();
  return useQuery<GetMyAssignmentTotalResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-feedback', 'total', search],
    async () => {
      const { data } = await axios.get(`/v1/home_mentioned_feedback_total`, {
        params: { search },
      });
      return data;
    }
  );
};
export const useGetMentionedFeedbackListInfinite = ({
  search,
  sort_by = 'modified_at',
  order_by = 'desc',
}: GetMyAssignmentListInfinitePayload) => {
  const axios = useAxios();
  return useInfiniteQuery<GetMyAssignmentListInfiniteResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-feedback', 'list', search],
    async ({
      pageParam = {
        first_task_id: undefined,
        offset: 0,
        limit: ASSIGNMENT_LIST_LIMIT,
        sort_by,
        order_by,
        search,
      },
    }) => {
      const { data } = await axios.get(`/v1/home_mentioned_feedback/`, {
        params: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination_info?.next_offset
          ? {
              offset: lastPage.pagination_info.next_offset,
              limit: ASSIGNMENT_LIST_LIMIT,
              first_task_id: lastPage.pagination_info.first_task_id,
              sort_by,
              order_by,
              search,
            }
          : undefined;
      },
    }
  );
};
export const useGetMentionedFeedback = ({ feedbackId }: GetMyAssignmentPayload) => {
  const axios = useAxios();
  return useQuery<GetMyMentionedFeedbackResult, AxiosError<CommonAPIResponse>>(
    ['home', 'mentioned-feedback', feedbackId],
    async () => {
      const { data } = await axios.get(`/v1/home_mentioned_feedback/${feedbackId}`);
      return data;
    },
    { enabled: !!feedbackId }
  );
};
