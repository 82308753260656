import { Fragment, useEffect } from 'react';
import { useGetApplicationActivitiesInfiniteById } from '../../../../services/application/official-standard/application.api';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import Box from '../../../../components/box';
import Typography from '../../../../components/typography/deprecated-typography';
import Spacer from '../../../../components/surface/spacer';
import Button from '../../../../components/button/deprecated-button';
import Spinner from '../../../../components/helper/spinner';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import ErrorMonoBG from '../../../../assets/images/bg-error-mono.svg';
import NoActivityBG from '../../../../assets/images/bg-no-activity.svg';
import Avatar from '../../../../components/surface/avatar';

const ACTIVITIES_HEADER_HEIGHT = 64;

const Activities = ({ applicationId }: { applicationId?: string }) => {
  const applicationActivitiesQueries = useGetApplicationActivitiesInfiniteById({
    application_id: applicationId ?? '',
  });
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      applicationActivitiesQueries.fetchNextPage();
    }
  }, [inView]);
  return (
    <>
      {/* Activity Title */}
      <Box
        css={{
          height: '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid $secondary20',
          flexShrink: 0,
        }}>
        <Typography
          variant={'h5'}
          css={{
            width: 290,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>{`Activity`}</Typography>
      </Box>
      <Box css={{ height: `calc(100% - ${ACTIVITIES_HEADER_HEIGHT}px)` }}>
        <ScrollAreaRoot css={{ width: 320, px: 20 }}>
          <ScrollAreaViewport>
            {applicationActivitiesQueries.status === 'loading' ? (
              <Box
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                }}>
                <Spinner size={'medium'} />
              </Box>
            ) : applicationActivitiesQueries.status === 'error' ? (
              <Box
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  rowGap: '$5',
                }}>
                <Box as="img" src={ErrorMonoBG} />
                <Box css={{ width: '100%', maxWidth: 280, textAlign: 'center' }}>
                  <Typography variant="h6">{`Something's gone wrong`}</Typography>
                  <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                  <Typography variant="body-medium">
                    {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                  </Typography>
                </Box>
                <Button
                  variant="solid"
                  size={'small'}
                  isLoading={applicationActivitiesQueries.isLoading}
                  onClick={() => {
                    applicationActivitiesQueries.refetch();
                  }}>{`Refresh`}</Button>
              </Box>
            ) : (
              <>
                <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                {applicationActivitiesQueries.isSuccess &&
                applicationActivitiesQueries.data.pages.length > 0 ? (
                  <>
                    {applicationActivitiesQueries.data.pages.map((page, pageIndex) => {
                      return (
                        <Fragment key={pageIndex}>
                          {page.activities.map((activity) => (
                            <Fragment key={activity.id}>
                              {activity.header ? (
                                <Typography as="p" variant={'body-bold'} css={{ mb: 10 }}>
                                  {activity.header}
                                </Typography>
                              ) : null}
                              <Box css={{ display: 'flex', mb: 20, columnGap: 6 }}>
                                <Box>
                                  <Avatar
                                    name={activity.user.name || '-'}
                                    picture={activity.user.picture || ''}
                                    css={{ fontSize: '$14' }}
                                    size="sm"
                                  />
                                </Box>
                                <Box>
                                  <Typography as="p" variant={'body'}>
                                    <Typography variant={'body-bold'} css={{ color: '$primary50' }}>
                                      {activity.user.name || '-'}
                                      {` `}
                                    </Typography>
                                    {activity.detail || '-'}
                                  </Typography>
                                  <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                                  <Typography
                                    as="p"
                                    variant={'caption'}
                                    css={{ color: '$secondary70' }}>
                                    {activity.time
                                      ? moment(activity.time).format('MM/DD/YY HH:mm A (Z)')
                                      : '-'}
                                  </Typography>
                                </Box>
                              </Box>
                            </Fragment>
                          ))}
                        </Fragment>
                      );
                    })}
                    <Box ref={ref} css={{ mt: 30, mb: 20 }}>
                      <Typography
                        as="p"
                        align={'center'}
                        variant={'caption'}
                        css={{ color: '$primary50' }}>
                        {applicationActivitiesQueries.isFetchingNextPage
                          ? 'Loading more...'
                          : `Nothing happened before ${moment(
                              applicationActivitiesQueries.data.pages[
                                applicationActivitiesQueries.data.pages.length - 1
                              ].activities[
                                applicationActivitiesQueries.data.pages[
                                  applicationActivitiesQueries.data.pages.length - 1
                                ].activities.length - 1
                              ].time
                            ).format('MMM, YYYY')}`}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box
                    css={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      rowGap: 10,
                    }}>
                    <Box as="img" src={NoActivityBG} />
                    <Typography
                      as={`p`}
                      variant={'body-medium'}
                      align={'center'}
                      css={{ color: '$secondary70' }}>
                      {`No activities at this point`}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaViewport>
        </ScrollAreaRoot>
      </Box>
    </>
  );
};

export default Activities;
