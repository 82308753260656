import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import {
  GetAppDetailApprovalTabResponse,
  GetAppDetailSubmissionTabResponse,
  GetAppDetailDocTabResponse,
} from '../../../types/application/simplified/app-detail-tabs';
import { ProductType } from '../../../types/application/constants';
import { AppStatus } from '../../../types';

export const useGetAppDetailApprovalTab = (options: {
  appId: string;
  needApproval: boolean;
  appStatus: AppStatus | '';
}) => {
  const axios = useAxios();
  return useQuery<GetAppDetailApprovalTabResponse, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'detail-tabs', 'approval-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application/${options.appId}/approval_tab`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled:
      !!(options.appId && options.needApproval && options.appStatus) &&
      options.appStatus !== 'draft',
  });
};

export const useGetAppDetailSubmissionTab = (options: { product: ProductType; appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailSubmissionTabResponse<typeof options.product>, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'detail-tabs', 'submission-tab'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/simplified_application/${options.appId}`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.product && !!options.appId,
  });
};

export const useGetAppDetailDocTab = (options: { appId: string; docId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailDocTabResponse, AxiosError>({
    queryKey: ['app', 'simp', options.appId, 'detail-tabs', 'doc-tab', options.docId],
    queryFn: async () => {
      const { data } = await axios.get(
        `/v1/simplified_application/${options.appId}/google_document/${options.docId}`,
        {
          params: {
            application_id: options.appId,
            document_id: options.docId,
          },
        }
      );
      return data;
    },
    enabled: !!options.docId,
  });
};
