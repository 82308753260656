import { ReactElement, ChangeEvent } from 'react';
import { styled } from '../../styles/stitches.config';
import Box from '../box';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';
import Spacer from '../surface/spacer';
import InputErrorMessage from '../typography/input-error-message';

interface FieldProps {
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  isError?: boolean;
  error?: string;
  disabled?: boolean;
  isShowcase?: boolean;
  startElement?: ReactElement;
  endElement?: ReactElement;
  isLightOnly?: boolean;
}

const StyledInputContainer = styled(Box, {
  px: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  borderStyle: 'solid',
  borderRadius: '$5',
  borderWidth: '1px',
  overflow: 'hidden',
  transition: 'border-color 0.15s linear',
});

const StyledInput = styled('input', {
  width: '100%',
  height: '100%',
  border: 'none',
  outline: 'none',
  fontSize: '$14',
  backgroundColor: '$transparent',
  transition: 'border-color 0.15s linear, background-color 0.15s linear',
  '&::placeholder': {
    height: 'inherit',
    lineHeight: 'inherit',
    color: '$secondary30',
    fontSize: '$14',
  },
  '&:disabled': {
    backgroundColor: 'unset',
    borderColor: 'unset',
  },
});

const Field = ({
  label,
  labelPosition = 'top',
  required = false,
  placeholder,
  value,
  onChange,
  isError,
  error,
  disabled = false,
  isShowcase,
  startElement,
  endElement,
  isLightOnly = false,
}: FieldProps) => {
  return (
    <Box
      css={{
        display: labelPosition === 'top' ? 'block' : labelPosition === 'left' ? 'flex' : 'block',
      }}>
      {label ? (
        <Label>
          <Typography variant="caption">
            {`${label}`}
            {required ? (
              <Typography variant="caption" css={{ color: '$error50' }}>{` *`}</Typography>
            ) : null}
          </Typography>
        </Label>
      ) : null}
      <StyledInputContainer
        css={{
          borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary20',
          backgroundColor: disabled ? '$secondary5' : '$white',
          '&:focus-within': {
            borderColor: isError ? '$error60' : disabled ? '$secondary10' : '$secondary40',
          },
        }}>
        {startElement ? (
          <>
            {startElement} <Spacer axis="horizontal" css={{ $$size: '4px' }} />
          </>
        ) : null}
        <StyledInput
          type="text"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          placeholder={placeholder}
          disabled={disabled || isShowcase}
          css={{
            '&:disabled': {
              color:
                isShowcase && disabled
                  ? '$secondary30'
                  : disabled
                  ? '$secondary30'
                  : '$secondary70',
            },
          }}
        />
        {endElement ? (
          <>
            <Spacer axis="horizontal" css={{ $$size: '4px' }} /> {endElement}
          </>
        ) : null}
      </StyledInputContainer>
      {isLightOnly ? null : <InputErrorMessage isError={isError} errorMessage={error} />}
    </Box>
  );
};

export default Field;
