import type { ReactNode } from 'react';
import * as RadixLabel from '@radix-ui/react-label';
import { styled } from '../../styles/stitches.config';

const StyledLabel = styled(RadixLabel.Root, {
  variants: {
    position: {
      left: { display: 'inline', mr: 4 },
      top: { display: 'block', mb: 4 },
    },
  },
});

const Label = ({
  position = 'top',
  children,
  htmlFor,
}: {
  position?: 'left' | 'top';
  children: ReactNode;
  htmlFor?: string;
}) => {
  return (
    <StyledLabel htmlFor={htmlFor} position={position}>
      {children}
    </StyledLabel>
  );
};

export default Label;
