import { useState } from 'react';
import { Mention } from 'react-mentions';
import moment from 'moment';
import {
  useGetAppDetailApprovalPanel,
  usePostAppDetailApprovalPanel,
} from '../../../../services/application/simplified-standard/app-detail-panels.api';
import { useGetMentionableUserOptions } from '../../../../services/application/simplified-standard/dropdown.api';
import { getApprovalConditionVariant } from '../../../../utils/get-variant';
import { CenteredBoxAbsolute, CenteredBoxFlex } from '../../../../components/surface/centered-box';
import Spinner from '../../../../components/helper/spinner';
import { ButtonText, Caption, Text } from '../../../../components/typography/text';
import Box from '../../../../components/box';
import Spacer from '../../../../components/surface/spacer';
import Divider from '../../../../components/surface/divider';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import { Heading5, Heading6 } from '../../../../components/typography/heading';
import {
  DialogAlertCancel,
  DialogAlertContent,
  DialogAlertDescription,
  DialogAlertOverlay,
  DialogAlertPortal,
  DialogAlertRoot,
  DialogAlertTitle,
  DialogAlertTrigger,
} from '../../../../components/helper/dialog-alert';
import MentionArea from '../../../../components/dropdown/mention-area';
import MSymbol from '../../../../components/icon/m-symbol';
import Button from '../../../../components/button/button';
import Avatar from '../../../../components/surface/avatar';
import MentionedTypography from '../../../../components/typography/mentioned-typography';
import NoApprovalBG from '../../../../assets/images/bg-no-approval.svg';
import PopupAttentionIcon from '../../../../assets/images/icon-popup-attention.svg';
import PopupRejectIcon from '../../../../assets/images/icon-popup-reject.svg';
import PopupSuccessIcon from '../../../../assets/images/icon-popup-success.svg';

export interface AppDetailApprovalPanelProps {
  appId: string;
}

const ApprovalPanel = ({ appId }: AppDetailApprovalPanelProps) => {
  const appDetailApprovalPanelQuery = useGetAppDetailApprovalPanel({ appId });
  const mentionableUserQuery = useGetMentionableUserOptions({ appId });
  const [feedbackInput, setFeedbackInput] = useState('');
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const onFeedbackInputChange = (newValue: string) => {
    setFeedbackInput(newValue);
  };
  const appDetailApprovalPanelMutation = usePostAppDetailApprovalPanel({
    appId,
    onSettled: () => {
      setFeedbackInput('');
      setIsApproveDialogOpen(false);
      setIsRejectDialogOpen(false);
    },
  });
  const onRejectClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      is_gft: false,
      approval_result_code: 4,
    });
  };
  const onApproveClick = () => {
    appDetailApprovalPanelMutation.mutate({
      feedback_content: feedbackInput,
      is_gft: false,
      approval_result_code: 1,
    });
  };
  return (
    <>
      {appDetailApprovalPanelQuery.isLoading ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Spinner size="large" />
        </CenteredBoxFlex>
      ) : appDetailApprovalPanelQuery.isSuccess ? (
        <>
          <CenteredBoxFlex css={{ height: 64, flexShrink: 0 }}>
            <Heading5
              css={{
                width: 290,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>{`Approval`}</Heading5>
          </CenteredBoxFlex>
          <Divider
            axis={'horizontal'}
            css={{ $$size: '100%', backgroundColor: '$func-border-main' }}
          />
          <ScrollAreaRoot css={{ flexGrow: 1, p: 20 }}>
            <ScrollAreaViewport>
              {(() => {
                switch (true) {
                  case appDetailApprovalPanelQuery.data.approver_status_code === '-1':
                    return (
                      <>
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4, mb: 6 }}>
                          <Box as="img" css={{ width: 20, height: 20 }} src={PopupRejectIcon} />
                          <Heading6 css={{ color: '$error60' }}>{`Rejected`}</Heading6>
                        </Box>
                        <Text>{`This application has been rejected.`}</Text>
                      </>
                    );
                  case appDetailApprovalPanelQuery.data.approver_status_code === '0':
                    return (
                      <CenteredBoxAbsolute>
                        <CenteredBoxFlex css={{ width: 280 }}>
                          <Box as={'img'} src={NoApprovalBG} />
                          <Text as="p" css={{ color: '$secondary70' }}>
                            {`This approval is still waiting for `}
                            <Text
                              css={{
                                color: '$primary50',
                                display: 'inline-block',
                              }}>{`${appDetailApprovalPanelQuery.data.waiting_for || '-'}`}</Text>
                          </Text>
                        </CenteredBoxFlex>
                      </CenteredBoxAbsolute>
                    );
                  case appDetailApprovalPanelQuery.data.approver_status_code === '1' ||
                    appDetailApprovalPanelQuery.data.approver_status_code === '2':
                    return (
                      <Box
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: 50,
                          width: '100%',
                        }}>
                        <Box
                          as={'img'}
                          css={{ width: 20, height: 20, mt: 4 }}
                          src={PopupAttentionIcon}
                        />
                        <Text
                          css={{ width: 254 }}
                          bold>{`This application is pending your approval`}</Text>
                      </Box>
                    );
                  case appDetailApprovalPanelQuery.data.approver_status_code === '3':
                    return (
                      <Box>
                        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4, mb: 6 }}>
                          <Box as="img" css={{ width: 20, height: 20 }} src={PopupSuccessIcon} />
                          <Heading6 css={{ color: '$success70' }}>{`Approved`}</Heading6>
                        </Box>
                        <Box>
                          <Text>{`This application has been approved`}</Text>
                        </Box>
                      </Box>
                    );
                  default:
                    return null;
                }
              })()}
              <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
              {/* Conditions Container */}
              {appDetailApprovalPanelQuery.data.approver_status_code === '0' ? null : (
                <Box
                  css={{
                    borderRadius: 6,
                    backgroundColor: '$func-hover',
                    p: 10,
                    maxWidth: 279,
                  }}>
                  {/* Conditions */}
                  <>
                    <ButtonText size={'14'} bold css={{ display: 'block' }}>
                      Conditions
                    </ButtonText>
                    {appDetailApprovalPanelQuery.data.conditions &&
                    appDetailApprovalPanelQuery.data.conditions.length > 0
                      ? appDetailApprovalPanelQuery.data.conditions.map((condition) => (
                          <Box
                            key={`condition-${condition.id}`}
                            css={{ display: 'flex', alignItems: 'center', columnGap: 8, mt: 10 }}>
                            <MSymbol
                              size={20}
                              iconName={
                                getApprovalConditionVariant(condition.condition_type).iconName
                              }
                              css={{
                                color: getApprovalConditionVariant(condition.condition_type).color,
                              }}>
                              {condition.condition_type}
                            </MSymbol>
                            <Text>{`${condition.condition_message || '-'}`}</Text>
                          </Box>
                        ))
                      : '-'}
                  </>
                  <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                  {/* Reasons For Application */}
                  <>
                    <ButtonText size={'14'} bold css={{ display: 'block' }}>
                      Reasons For Application
                    </ButtonText>
                    <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                    <Text as="p">{`${
                      appDetailApprovalPanelQuery.data.reason_for_discount_rate || 'No reasons'
                    }`}</Text>
                  </>
                  {appDetailApprovalPanelQuery.data.approver_status_code === '-1' ||
                  appDetailApprovalPanelQuery.data.approver_status_code === '0' ||
                  appDetailApprovalPanelQuery.data.approver_status_code === '3' ? null : (
                    <>
                      <Divider
                        axis={'horizontal'}
                        css={{ $$size: '100%', backgroundColor: '$func-border-main', my: 20 }}
                      />
                      {/* Feedback Input */}
                      <>
                        <MentionArea
                          placeholder="You can reply to reasons, feedback, or add others with @ (optional)"
                          value={feedbackInput}
                          onChange={(e, newValue) => onFeedbackInputChange(newValue)}>
                          <Mention
                            trigger="@"
                            appendSpaceOnAdd={true}
                            displayTransform={(id, display) => {
                              return `@${display}`;
                            }}
                            data={mentionableUserQuery.data || []}
                          />
                        </MentionArea>
                        <Spacer axis={'vertical'} css={{ $$size: '20px' }} />
                        {/* Approval Buttons */}
                        <CenteredBoxFlex css={{ flexDirection: 'row', columnGap: 10 }}>
                          <Box css={{ flex: 1 }}>
                            <DialogAlertRoot
                              open={isRejectDialogOpen}
                              onOpenChange={(open) => setIsRejectDialogOpen(open)}>
                              {/* Reject */}
                              <DialogAlertTrigger asChild>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  size="md"
                                  startElement={
                                    <MSymbol
                                      size={20}
                                      iconName="close"
                                      css={{ color: 'inherit' }}
                                    />
                                  }>
                                  <ButtonText size={'14'} bold>
                                    Reject
                                  </ButtonText>
                                </Button>
                              </DialogAlertTrigger>
                              <DialogAlertPortal>
                                <DialogAlertOverlay />
                                <DialogAlertContent
                                  css={{
                                    px: 30,
                                    py: 20,
                                    borderRadius: '$10',
                                    maxWidth: '655px',
                                  }}>
                                  <Box
                                    css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                                    {/* Reject Dialog Title, Dialog Close Button */}
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 15,
                                      }}>
                                      <MSymbol
                                        iconName="error"
                                        weight={700}
                                        size={30}
                                        css={{ color: '$error60' }}
                                      />
                                      <DialogAlertTitle asChild>
                                        <Heading5
                                          css={{
                                            flexGrow: 1,
                                          }}>{`Reject the application`}</Heading5>
                                      </DialogAlertTitle>
                                    </Box>
                                    {/* Dialog Description */}
                                    <DialogAlertDescription css={{ px: 40 }}>
                                      <Text>
                                        {`Once the application is`}{' '}
                                        <Text css={{ display: 'inline-block', color: '$error60' }}>
                                          rejected
                                        </Text>{' '}
                                        {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                      </Text>
                                    </DialogAlertDescription>
                                    {/* Dialog Action */}
                                    <Box
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        columnGap: 20,
                                      }}>
                                      <Button
                                        size="md"
                                        color="error"
                                        spinnerColor="error"
                                        isLoading={appDetailApprovalPanelMutation.isLoading}
                                        disabled={appDetailApprovalPanelMutation.isLoading}
                                        css={{ width: 75 }}
                                        onClick={onRejectClick}>
                                        <ButtonText size={'14'} bold>
                                          Reject
                                        </ButtonText>
                                      </Button>
                                      <DialogAlertCancel asChild>
                                        <Button
                                          size="md"
                                          variant="borderless"
                                          color="achromatic"
                                          disabled={appDetailApprovalPanelMutation.isLoading}>
                                          <ButtonText size={'14'} bold>
                                            Cancel
                                          </ButtonText>
                                        </Button>
                                      </DialogAlertCancel>
                                    </Box>
                                  </Box>
                                </DialogAlertContent>
                              </DialogAlertPortal>
                            </DialogAlertRoot>
                          </Box>
                          <Box css={{ flex: 1 }}>
                            <DialogAlertRoot
                              open={isApproveDialogOpen}
                              onOpenChange={(open) => setIsApproveDialogOpen(open)}>
                              {/* Approve */}
                              <DialogAlertTrigger asChild>
                                <Button
                                  variant="outlined"
                                  color="success"
                                  size="md"
                                  startElement={
                                    <MSymbol
                                      size={20}
                                      iconName="check"
                                      css={{ color: 'inherit' }}
                                    />
                                  }>
                                  <ButtonText size={'14'} bold>
                                    Approve
                                  </ButtonText>
                                </Button>
                              </DialogAlertTrigger>
                              <DialogAlertPortal>
                                <DialogAlertOverlay />
                                <DialogAlertContent
                                  css={{
                                    px: 30,
                                    py: 20,
                                    borderRadius: '$10',
                                    maxWidth: '655px',
                                  }}>
                                  <Box
                                    css={{ display: 'flex', flexDirection: 'column', rowGap: 30 }}>
                                    {/* Approve Dialog Title, Dialog Close Button */}
                                    <Box
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: 15,
                                      }}>
                                      <MSymbol
                                        iconName="error"
                                        weight={700}
                                        size={30}
                                        css={{ color: '$error60' }}
                                      />
                                      <DialogAlertTitle asChild>
                                        <Heading5
                                          css={{
                                            flexGrow: 1,
                                          }}>{`Approve the application`}</Heading5>
                                      </DialogAlertTitle>
                                    </Box>
                                    {/* Dialog Description */}
                                    <DialogAlertDescription css={{ px: 40 }}>
                                      <Text>
                                        {`Once the application is`}{' '}
                                        <Text
                                          css={{ display: 'inline-block', color: '$success70' }}>
                                          approved
                                        </Text>{' '}
                                        {`, it cannot be revised again. Are you sure you want to proceed with the approval?`}
                                      </Text>
                                    </DialogAlertDescription>
                                    {/* Dialog Action */}
                                    <Box
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        columnGap: 20,
                                      }}>
                                      <Button
                                        size="md"
                                        color="success"
                                        spinnerColor="success"
                                        isLoading={appDetailApprovalPanelMutation.isLoading}
                                        disabled={appDetailApprovalPanelMutation.isLoading}
                                        css={{ width: 90 }}
                                        onClick={onApproveClick}>
                                        <ButtonText size={'14'} bold>
                                          Approve
                                        </ButtonText>
                                      </Button>
                                      <DialogAlertCancel asChild>
                                        <Button
                                          size="md"
                                          variant="borderless"
                                          color="achromatic"
                                          disabled={appDetailApprovalPanelMutation.isLoading}>
                                          <ButtonText size={'14'} bold>
                                            Cancel
                                          </ButtonText>
                                        </Button>
                                      </DialogAlertCancel>
                                    </Box>
                                  </Box>
                                </DialogAlertContent>
                              </DialogAlertPortal>
                            </DialogAlertRoot>
                          </Box>
                        </CenteredBoxFlex>
                      </>
                    </>
                  )}
                  {/* Approval Feedback or Reasons */}
                  <>
                    {appDetailApprovalPanelQuery.data.feedback ? (
                      <>
                        <Divider
                          axis={'horizontal'}
                          css={{ $$size: '100%', backgroundColor: '$func-border-main', my: 20 }}
                        />
                        <ButtonText size={'14'} bold css={{ display: 'block' }}>
                          Approval Feedback or Reasons
                        </ButtonText>
                        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                        <Box css={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                            <Avatar
                              size="sm"
                              name={`${
                                appDetailApprovalPanelQuery.data.feedback.approver.name || '-'
                              }`}
                              picture={`${
                                appDetailApprovalPanelQuery.data.feedback.approver.picture || '-'
                              }`}
                            />
                            <Text css={{ color: '$primary50' }} bold>{`${
                              appDetailApprovalPanelQuery.data.feedback.approver.name || '-'
                            }`}</Text>
                          </Box>
                          <Caption
                            css={{
                              color: '$text-secondary',
                            }}>
                            {appDetailApprovalPanelQuery.data.feedback.approved_at
                              ? moment(
                                  appDetailApprovalPanelQuery.data.feedback.approved_at
                                ).format('MM/DD/YY HH:mm A (Z)')
                              : '-'}
                          </Caption>
                          <MentionedTypography
                            ellipsisLine="revert"
                            message={`${
                              appDetailApprovalPanelQuery.data.feedback.feedback_content || '-'
                            }`}
                          />
                        </Box>
                      </>
                    ) : null}
                  </>
                </Box>
              )}
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </ScrollAreaRoot>
        </>
      ) : appDetailApprovalPanelQuery.isError ? (
        <CenteredBoxFlex css={{ height: '100%' }}>
          <Box as="img" src={NoApprovalBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Text
            semibold
            css={{
              textAlign: 'center',
            }}>{`${
            appDetailApprovalPanelQuery.error.response?.data.message || 'Something went wrong'
          }`}</Text>
        </CenteredBoxFlex>
      ) : null}
    </>
  );
};

export default ApprovalPanel;
