import type { GetApplicationAttachmentResult } from '../../../../types/application/official/application';
import { useEffect, useState } from 'react';
import { useGetApplicationAttachmentById } from '../../../../services/application/official-standard/application.api';
import moment from 'moment';
import Split from 'react-split';
import '../../../../styles/custom-react-split.css';
import Box from '../../../../components/box';
import Typography from '../../../../components/typography/deprecated-typography';
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../../../../components/surface/scroll-area';
import Spinner from '../../../../components/helper/spinner';
import AttachFileLightOffIcon from '../../../../assets/images/icon-attach-file-light-off.svg';
import NoAttachesBG from '../../../../assets/images/bg-no-attaches.svg';
import Spacer from '../../../../components/surface/spacer';

const Attachment = ({ applicationId }: { applicationId?: string }) => {
  const [currentAttachment, setCurrentAttachment] = useState<GetApplicationAttachmentResult>();

  const applicationAttachmentByIdQueries = useGetApplicationAttachmentById({
    application_id: applicationId ?? '',
  });

  useEffect(() => {
    if (applicationAttachmentByIdQueries.isSuccess) {
      setCurrentAttachment(applicationAttachmentByIdQueries.data[0]);
    }
  }, [applicationAttachmentByIdQueries.isSuccess]);

  if (applicationAttachmentByIdQueries.isLoading) {
    return (
      <Box
        css={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner size={'medium'} />
      </Box>
    );
  }

  return (
    <>
      {/* Attachment Title */}
      <Box
        css={{
          height: '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid $secondary20',
          flexShrink: 0,
        }}>
        <Typography
          variant={'h5'}
          css={{
            width: 290,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>{`Attachment`}</Typography>
      </Box>
      {applicationAttachmentByIdQueries.isSuccess &&
      applicationAttachmentByIdQueries.data.length > 0 ? (
        <>
          <Split
            direction={'vertical'}
            sizes={[20, 80]}
            gutterSize={13}
            style={{ height: 'calc(100% - 64px)' }}>
            {/* <Box> */}
            <ScrollAreaRoot>
              <ScrollAreaViewport>
                <Box css={{ px: 20, py: 10 }}>
                  {applicationAttachmentByIdQueries.data.map((attachment) => (
                    <Box
                      key={attachment.id}
                      onClick={() => {
                        setCurrentAttachment(attachment);
                      }}
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        columnGap: 6,
                        px: 6,
                        mb: 6,
                        cursor: 'pointer',
                        borderWidth: currentAttachment?.id === attachment.id ? '2px' : '1px',
                        borderStyle: 'solid',
                        borderColor:
                          currentAttachment?.id === attachment.id ? '$primary50' : '$secondary20',
                        borderRadius: '$5',
                        backgroundColor: '$white',
                        minWidth: 0,
                        transition: 'background-color 0.15s linear, border-color 0.15s linear',
                        '&:last-child': {
                          mb: 0,
                        },
                        '&:hover': {
                          backgroundColor: '$secondary3',
                        },
                        '&:active': {
                          backgroundColor: '$secondary10',
                        },
                      }}>
                      <Box
                        css={{
                          width: 50,
                          height: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Box
                          as="img"
                          css={{ width: 21, height: 27 }}
                          src={AttachFileLightOffIcon}
                        />
                      </Box>
                      <Typography
                        as="p"
                        variant={'body-medium'}
                        css={{
                          maxWidth: 212,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>{`${attachment.name || '-'}`}</Typography>
                    </Box>
                  ))}
                </Box>
                <ScrollAreaScrollbar orientation="vertical">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaViewport>
            </ScrollAreaRoot>
            {/* </Box> */}
            {/* <Box> */}
            <ScrollAreaRoot>
              <ScrollAreaViewport>
                {/* File Details */}
                <Box css={{ px: 20, pb: 20 }}>
                  <Typography
                    variant={'body'}
                    css={{ fontSize: '$16' }}>{`File Details`}</Typography>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  {/* Kind */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                    <Typography
                      variant={'caption-medium'}
                      css={{ width: 90, color: '$secondary70' }}>{`Kind`}</Typography>
                    <Typography
                      variant={'caption'}
                      css={{
                        width: 186,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: '$primary50',
                      }}>{`${currentAttachment?.kind ?? '-'}`}</Typography>
                  </Box>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  {/* Size */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                    <Typography
                      variant={'caption-medium'}
                      css={{ width: 90, color: '$secondary70' }}>{`Size`}</Typography>
                    <Typography
                      variant={'caption'}
                      css={{
                        width: 186,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: '$primary50',
                      }}>
                      {currentAttachment?.size ? `${currentAttachment?.size} KB` : '-'}
                    </Typography>
                  </Box>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  {/* Created */}
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '$1' }}>
                    <Typography
                      variant={'caption-medium'}
                      css={{ width: 90, color: '$secondary70' }}>{`Created`}</Typography>
                    <Typography
                      variant={'caption'}
                      css={{
                        width: 186,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: '$primary50',
                      }}>
                      {currentAttachment?.created_at
                        ? moment(currentAttachment.created_at).format('MM/DD/YY HH:mm A (Z)')
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
                {/* Preview */}
                <Box css={{ px: 20, pb: 20 }}>
                  <Typography variant={'body'} css={{ fontSize: '$16' }}>{`Preview`}</Typography>
                  <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                  <Box
                    css={{
                      borderRadius: '$5',
                      width: 180,
                      height: 240,
                      overflow: 'hidden',
                      backgroundColor: '$secondary3',
                    }}>
                    {currentAttachment?.preview_url ? (
                      <a target="_blank" rel="noreferrer" href={`${currentAttachment?.url ?? ''}`}>
                        <Box
                          as={'img'}
                          src={currentAttachment?.preview_url ?? ''}
                          css={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                      </a>
                    ) : null}
                  </Box>
                </Box>
                <ScrollAreaScrollbar orientation="vertical">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaViewport>
            </ScrollAreaRoot>
            {/* </Box> */}
          </Split>
        </>
      ) : (
        <Box
          css={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Box as={'img'} src={NoAttachesBG} />
          <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
          <Typography
            as={'p'}
            variant={'body'}
            css={{ color: '$secondary70' }}>{`No documents added yet.`}</Typography>
        </Box>
      )}
    </>
  );
};

export default Attachment;
