import { ComponentProps, MouseEvent, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import { Text, Caption } from '../typography/text';
import IconButton from '../button/icon-button';
import DocPDFIcon from '../../assets/images/icon-doc-pdf.svg';
import DocAppDefaultIcon from '../../assets/images/icon-doc-app-default.svg';
import DocAppActiveIcon from '../../assets/images/icon-doc-app-active.svg';
import PopupErrorIcon from '../../assets/images/icon-popup-error.svg';
import Box from '../box';

const FileBlobCardRoot = styled('div', {
  borderRadius: 10,
  p: 10,
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
  backgroundColor: '$func-hover',
  transition: '$colors',
  '&:hover': {
    backgroundColor: '$primary10',
  },
  '&:active': {
    backgroundColor: '$primary20',
  },
});

const FileBlobCardThumbnail = styled('div', {
  flexShrink: 0,
  width: 50,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  overflow: 'hidden',
});

const FileBlobCardThumbnailImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const FileBlobCardContent = styled('div', {
  flex: 1,
});

const FileBlobCardContentHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: 4,
});

const FileBlobCardAction = styled('div', {
  ml: 'auto',
});

interface FileBlobCardProps extends ComponentProps<typeof FileBlobCardRoot> {
  file: File | null;
  needAction?: boolean;
  isError?: boolean;
  error?: string;
  onCloseClick?: (e: MouseEvent) => void;
}

const FileBlobCard = forwardRef<HTMLDivElement, FileBlobCardProps>(
  ({ file, needAction = true, isError, error, onCloseClick, ...restProps }, ref) => {
    return (
      <FileBlobCardRoot ref={ref} {...restProps}>
        <FileBlobCardThumbnail>
          {file?.type.includes('/pdf') ? (
            <FileBlobCardThumbnailImage src={DocPDFIcon} />
          ) : file?.type.includes('image/') ? (
            <FileBlobCardThumbnailImage
              src={file ? URL.createObjectURL(file) : DocAppDefaultIcon}
            />
          ) : (
            <FileBlobCardThumbnailImage src={DocAppActiveIcon} />
          )}
        </FileBlobCardThumbnail>
        <FileBlobCardContent>
          <FileBlobCardContentHeader>
            <Text>{`${file?.name || '-'}`}</Text>
          </FileBlobCardContentHeader>
          <Caption bold css={{ color: '$text-secondary' }}>
            {file ? `${Math.round((file.size / (1024 * 1024)) * 100) / 100} MB` : '-'}
          </Caption>
          {isError ? (
            <Box css={{ display: 'flex', alignItems: 'center', mt: '$1', columnGap: '$1' }}>
              <Box
                as="img"
                src={PopupErrorIcon}
                css={{
                  objectFit: 'contain',
                  width: 18,
                  height: 18,
                }}
              />
              <Caption
                css={{
                  ml: '$1',
                  color: '$error60',
                }}>{`${error || ''}`}</Caption>
            </Box>
          ) : null}
        </FileBlobCardContent>
        {needAction ? (
          <FileBlobCardAction>
            <IconButton type="button" iconName="close" isIconFill={false} onClick={onCloseClick} />
          </FileBlobCardAction>
        ) : null}
      </FileBlobCardRoot>
    );
  }
);

FileBlobCard.displayName = 'FileBlobCard';

export default FileBlobCard;
