// * 檢查是否全都打勾
export const checkAllChecked = (checkedList: boolean[]) => {
  return checkedList.every((item) => item);
};

// * Check if two arrays of strings or numbers have the same values
export const compareTwoPrimitiveArrays = (
  arr1: string[] | number[],
  arr2: string[] | number[],
  options?: {
    enforceOrder?: boolean; // * By default (false), the order of the values in the arrays doesn't matter.
  }
): boolean => {
  function vKey(i: number, v: string | number) {
    return (options?.enforceOrder ? `${i}-` : '') + `${typeof v}-${v}`;
  }

  if (arr1.length !== arr2.length) return false;

  const d1: { [key: string | number]: boolean } = {};
  const d2: { [key: string | number]: boolean } = {};
  for (let i = arr1.length - 1; i >= 0; i--) {
    d1[vKey(i, arr1[i])] = true;
    d2[vKey(i, arr2[i])] = true;
  }

  for (let i = arr1.length - 1; i >= 0; i--) {
    const v = vKey(i, arr1[i]);
    if (d1[v] !== d2[v]) return false;
  }

  for (let i = arr2.length - 1; i >= 0; i--) {
    const v = vKey(i, arr2[i]);
    if (d1[v] !== d2[v]) return false;
  }

  return true;
};

// * Check attachments length
export const checkAttachmentsLength = ({
  fileType,
  files,
}: {
  fileType: 'image' | 'pdf';
  files: any[];
}) => {
  if (fileType === 'image') {
    const images = files.filter(
      (file) => file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
    );
    return images.length;
  }
  if (fileType === 'pdf') {
    const pdfs = files.filter((file) => file.type === 'application/pdf');
    return pdfs.length;
  }
  return 0;
};
