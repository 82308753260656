import { contentShow, overlayShow } from '../../styles/keyframes';
import * as RadixDialog from '@radix-ui/react-dialog';
import { styled } from '../../styles/stitches.config';

export const DialogRoot = styled(RadixDialog.Root, {});
export const DialogTrigger = styled(RadixDialog.Trigger, {});
export const DialogPortal = styled(RadixDialog.Portal, {});
export const DialogOverlay = styled(RadixDialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});
export const DialogContent = styled(RadixDialog.Content, {
  zIndex: 1000,
  backgroundColor: '$white',
  borderRadius: '$5',
  boxShadow: '$basic',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '85vh',
  padding: 25,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});
export const DialogTitle = styled(RadixDialog.Title, {});
export const DialogDescription = styled(RadixDialog.Description, {});
export const DialogClose = styled(RadixDialog.Close, {});
