import { ComponentProps } from 'react';
import * as RadixAvatar from '@radix-ui/react-avatar';
import { styled } from '../../styles/stitches.config';
import { getNameInitials } from '../../utils/get-wording';
import { ButtonText } from '../typography/text';

const AvatarRoot = styled(RadixAvatar.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  borderRadius: '$rounded',
  backgroundColor: '$grey300',
  variants: {
    size: {
      lg: { width: 64, height: 64 },
      md: { width: 32, height: 32 },
      sm: { width: 24, height: 24 },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
const AvatarImage = styled(RadixAvatar.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});
const AvatarFallback = styled(RadixAvatar.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$secondary50',
  color: '$white',
});

interface AvatarProps extends ComponentProps<typeof AvatarRoot> {
  name: string;
  picture: string;
}

const Avatar = ({ name, picture, ...restProps }: AvatarProps) => {
  return (
    <AvatarRoot {...restProps}>
      <AvatarImage alt={`${name}`} src={`${picture || ''}`} />
      <AvatarFallback delayMs={300}>
        <ButtonText size={14} semibold css={{ color: 'inherit' }}>
          {getNameInitials(name)}
        </ButtonText>
      </AvatarFallback>
    </AvatarRoot>
  );
};

interface AvatarMoreProps extends ComponentProps<typeof AvatarRoot> {
  remainNumber: number;
}

export const AvatarMore = ({ remainNumber, ...restProps }: AvatarMoreProps) => {
  return (
    <AvatarRoot {...restProps}>
      <AvatarImage alt={`avatar-more-${remainNumber}`} src={''} />
      <AvatarFallback delayMs={600}>
        <ButtonText size={14} bold>{`+${remainNumber}`}</ButtonText>
      </AvatarFallback>
    </AvatarRoot>
  );
};

export default Avatar;
