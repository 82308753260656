import Box from '../../../../components/box';
import EmptyApplicationProcessedBG from '../../../../assets/images/bg-empty-application-processed.svg';
import ErrorBG from '../../../../assets/images/bg-error.svg';
import Typography from '../../../../components/typography/deprecated-typography';
import Spacer from '../../../../components/surface/spacer';

const Document = ({
  documentUrl = '',
  documentStatus,
}: {
  documentUrl?: string;
  documentStatus: string;
}) => {
  return (
    <Box
      css={{
        margin: '0 auto',
        width: '100%',
        height: documentStatus === 'generated' ? 1920 : '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
      {(() => {
        switch (documentStatus) {
          case 'can_not_show':
            return (
              <>
                <Box as="img" src={EmptyApplicationProcessedBG} />
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                <Typography
                  variant={
                    'body-medium'
                  }>{`The application has not completed the approval process; therefore, we cannot provide the required documents.`}</Typography>
              </>
            );
          case 'can_show':
            return (
              <Box
                as="iframe"
                css={{ display: 'block', width: '100%', height: '100%', border: 'none' }}
                src={documentUrl}
              />
            );
          case 'failed_to_be_generated':
            return (
              <>
                <Box as="img" src={ErrorBG} />
                <Spacer axis={'vertical'} css={{ $$size: '10px' }} />
                <Typography
                  variant={
                    'body-medium'
                  }>{`Your Document is failed to be generated, please generate again or contact legal.`}</Typography>
              </>
            );
          default:
            return null;
        }
      })()}
    </Box>
  );
};

export default Document;
