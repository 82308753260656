import { taxRegNumberFieldLabel } from '../../../../utils/tax-reg-number-field';
import Box from '../../../../components/box';
import Disclosure from '../../../../components/surface/disclosure';
import Typography from '../../../../components/typography/deprecated-typography';
import Divider from '../../../../components/surface/divider';
import Avatar from '../../../../components/surface/avatar';
import Spacer from '../../../../components/surface/spacer';
import LogoButton from '../../../../components/button/logo-button';
import LogoSvg from '../../../../components/surface/logo-svg';
import AwarenessChip from '../../../../components/chip/awareness-chip';

const Submission = ({ data }: { data: any }) => {
  return (
    <Box
      css={{
        px: 30,
        pb: 30,
        margin: '0 auto',
        mt: 30,
        minWidth: '580px',
        maxWidth: '760px',
        backgroundColor: '$white',
      }}>
      <Disclosure
        panels={[
          {
            id: 'panel-cloudmile',
            name: 'CloudMile',
            content: (
              <>
                {/* General Information */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`General Information`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                {/* Urgent Level */}
                {data && data.urgent_level ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <AwarenessChip awarenessType="urgent" urgentLevel={data.urgent_level} />
                  </>
                ) : null}
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <LogoButton light="on" css={{ backgroundColor: '$primary10' }}>
                  <LogoSvg product={data.product} />
                </LogoButton>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* CM Legal Entity */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`CM-Legal Entity`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.cm_legal_entity}`}</Typography>
                  </Box>
                  {/* Attention To */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Attention To`}</Typography>
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: '$1',
                      }}>
                      <Avatar
                        name={data.cm_attention_to || '-'}
                        picture={data.cm_attention_to_picture || ''}
                        css={{ mr: 8 }}
                        size="sm"
                      />
                      <Typography as="p" variant="body">{`${
                        data.cm_attention_to || '-'
                      }`}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Email */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Email`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.cm_email || '-'}`}</Typography>
                  </Box>
                  {/* Phone Number */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Phone Number`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${
                      data.cm_country_calling_codes && data.cm_phone_number
                        ? `${data.cm_country_calling_codes} ${data.cm_phone_number}`
                        : '-'
                    }`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Payment Detail */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Validity */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Quotation Validity`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.validity || '-'}`}</Typography>
                  </Box>
                  {/* Terms */}
                  {data.product === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Terms`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${
                        data.terms === '-1' ? data.terms_other || '-' : data.terms || '-'
                      }`}</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Automatically Renew Terms */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Automatically Renew Terms`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${
                      data.automatically_renew_terms === '-1'
                        ? data.automatically_renew_terms_other
                        : data.automatically_renew_terms === '0'
                        ? 'No Automatic Renewal'
                        : data.automatically_renew_terms || '-'
                    }`}</Typography>
                  </Box>
                  {/* GCP - Currency & Exchange Rate */}
                  {data.product === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Currency & Exchange Rate`}</Typography>
                      <Typography as="p" variant="body" css={{ mt: '$1' }}>
                        {!data.currency && !data.exchange_rate
                          ? '-'
                          : `${data.currency || '-'} / ${data.exchange_rate || '-'}`}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                {data.product === 'gcp' ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        {/* Commencement Date */}
                        <Typography
                          variant="caption-bold"
                          css={{
                            color: '$secondary40',
                          }}>{`Agreement Commencement Date`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.commencement_date || '-'}`}</Typography>
                      </Box>
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        {/* End Date */}
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Agreement End Date`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.end_date || '-'}`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Special Deal */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Special Deal`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.special_deal || '-'}`}</Typography>
                      </Box>
                      {Boolean(data.special_deal) && data.special_deal !== 'None' ? (
                        <>
                          {/* Commitment Period / Eligibility Period / B2B Lite Commitment Period */}
                          <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                            <Typography variant="caption-bold" css={{ color: '$secondary40' }}>{`${
                              data.special_deal === 'Flex'
                                ? 'Eligibility Period'
                                : data.special_deal === 'CM B2B Lite'
                                ? 'B2B Lite Commitment Period'
                                : 'Commitment Period'
                            }`}</Typography>
                            <Typography
                              as="p"
                              variant="body"
                              css={{
                                mt: '$1',
                              }}>
                              {`${
                                data.special_deal === 'Flex'
                                  ? data.eligibility_period || '-'
                                  : data.special_deal === 'CM B2B Lite'
                                  ? data.b2b_lite_commitment_period || '-'
                                  : data.commitment_period || '-'
                              }`}
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                    {Boolean(data.special_deal) && data.special_deal !== 'None' ? (
                      <>
                        <Spacer axis="vertical" css={{ $$size: '20px' }} />
                        {data.special_deal === 'EDP' ||
                        data.special_deal === 'CNSP' ||
                        data.special_deal === 'Google Full B2B' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.cm_to_google_commitment_amount}` || '-'}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.customer_to_cm_commitment_amount}` || '-'}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.cm_to_google_commitment_amount_1yr
                                        ? `${data.cm_to_google_commitment_amount_1yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.cm_to_google_commitment_amount_2yr
                                        ? `${data.cm_to_google_commitment_amount_2yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.cm_to_google_commitment_amount_3yr
                                        ? `${data.cm_to_google_commitment_amount_3yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.cm_to_google_commitment_amount_4yr
                                        ? `${data.cm_to_google_commitment_amount_4yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.cm_to_google_commitment_amount_5yr
                                        ? `${data.cm_to_google_commitment_amount_5yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              {/* [Customer to CM] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.customer_to_cm_commitment_amount_1yr
                                        ? `${data.customer_to_cm_commitment_amount_1yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.customer_to_cm_commitment_amount_2yr
                                        ? `${data.customer_to_cm_commitment_amount_2yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.customer_to_cm_commitment_amount_3yr
                                        ? `${data.customer_to_cm_commitment_amount_3yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.customer_to_cm_commitment_amount_4yr
                                        ? `${data.customer_to_cm_commitment_amount_4yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {data.customer_to_cm_commitment_amount_5yr
                                        ? `${data.customer_to_cm_commitment_amount_5yr}`
                                        : '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                        {data.special_deal === 'Flex' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Consumption Milestone */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Consumption Milestone`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.cm_to_google_consumption_milestone_amount || '-'}`}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Consumption Milestone */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Consumption Milestone`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.customer_to_cm_consumption_milestone_amount || '-'}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [CM to Google] Service Credit Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[CM to Google] Service Credit Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.cm_to_google_service_credit_amount || '-'}`}
                                </Typography>
                              </Box>
                              {/* [Customer to CM] Corresponding Service Credit Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Service Credit Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.customer_to_cm_service_credit_amount || '-'}`}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                        {data.special_deal === 'CM B2B Lite' ? (
                          <>
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [Customer to CM] B2B Lite Commitment Amount */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] B2B Lite Commitment Amount`}</Typography>
                                <Typography
                                  as="p"
                                  variant="body"
                                  css={{
                                    mt: '$1',
                                  }}>
                                  {`${data.b2b_lite_customer_to_cm_commitment_amount || '-'}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Spacer axis="vertical" css={{ $$size: '20px' }} />
                            <Box css={{ display: 'flex', columnGap: '$5' }}>
                              {/* [Customer to CM] Per Year */}
                              <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                                <Typography
                                  variant="caption-bold"
                                  css={{
                                    color: '$secondary40',
                                  }}>{`[Customer to CM] Per Year`}</Typography>
                                <Box css={{ mt: '$1' }}>
                                  {/* 1st yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`1st yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {`${
                                        data.b2b_lite_customer_to_cm_commitment_amount_1yr || '-'
                                      }`}
                                    </Typography>
                                  </Box>
                                  {/* 2nd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`2nd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {`${
                                        data.b2b_lite_customer_to_cm_commitment_amount_2yr || '-'
                                      }`}
                                    </Typography>
                                  </Box>
                                  {/* 3rd yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`3rd yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {`${
                                        data.b2b_lite_customer_to_cm_commitment_amount_3yr || '-'
                                      }`}
                                    </Typography>
                                  </Box>
                                  {/* 4th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                      mb: '$1',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`4th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {`${
                                        data.b2b_lite_customer_to_cm_commitment_amount_4yr || '-'
                                      }`}
                                    </Typography>
                                  </Box>
                                  {/* 5th yr */}
                                  <Box
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      columnGap: '$4',
                                    }}>
                                    <Typography
                                      variant="caption"
                                      css={{
                                        color: '$secondary70',
                                        flexBasis: '11%',
                                      }}>{`5th yr`}</Typography>
                                    <Typography as="p" variant="body">
                                      {`${
                                        data.b2b_lite_customer_to_cm_commitment_amount_5yr || '-'
                                      }`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ),
          },
          {
            id: 'panel-customer',
            name: `Customer ${data.product === 'gws' ? '& Vender' : ''}`,
            content: (
              <>
                {/* General Information */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`General Information`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* {`Client Name`} */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Client Name`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.client_name || '-'}`}</Typography>
                  </Box>
                  {/* {`Client Country`} */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Client Country`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.client_country || '-'}`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* {`Tex Reg Number`} */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography variant="caption-bold" css={{ color: '$secondary40' }}>
                      {taxRegNumberFieldLabel(data.client_country || '')}
                    </Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                      data.tax_reg_number || '-'
                    }`}</Typography>
                  </Box>
                  {/* {`Attention To`} */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Attention To`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.client_attention_to}`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Email */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Email`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.client_email || '-'}`}</Typography>
                  </Box>
                  {/* Phone Number */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Phone Number`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>
                      {data.client_country_calling_codes && data.client_phone_number
                        ? `${data.client_country_calling_codes} ${data.client_phone_number}`
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Address */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Address`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>
                      {data.client_address || '-'}
                    </Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Payment Detail */}
                <Typography
                  variant="body-bold"
                  css={{ color: '$secondary40' }}>{`Payment details`}</Typography>
                <Divider
                  axis="horizontal"
                  css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                />
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {data.product === 'gws' ? (
                  <>
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Workspace Edition */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Workspace Edition`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.workspace_edition}`}</Typography>
                      </Box>
                      {/* Google Workspace - Workspace Plan */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Workspace Plan`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.workspace_plan}`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Seat */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Seat`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.client_seats || '-'}`}</Typography>
                      </Box>
                      {/* Google Workspace - Domain Name */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Domain Name`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.client_domain_name || '-'}`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Subscription Term */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Subscription Term`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>
                          {data.subscription_term
                            ? `${
                                data.subscription_term === '-1'
                                  ? data.subscription_term_other
                                  : data.subscription_term
                              }`
                            : '-'}
                        </Typography>
                      </Box>
                      {/* Google Workspace - Currency & Exchange Rate */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Currency & Exchange Rate`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>
                          {!data.currency && !data.exchange_rate
                            ? '-'
                            : `${data.currency || '-'} / ${data.exchange_rate || '-'}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                  </>
                ) : null}
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Payment Term */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Payment Term`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>
                      {data.payment_term || '-'}
                    </Typography>
                  </Box>
                  {/* Google Cloud - Payment Discount Rate */}
                  {data.product === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{ color: '$secondary40' }}>{`Discount Rate`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${data.payment_term_discount_rate || '-'}`}</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Support Plan */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Support Plan`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${data.support_plan || '-'}`}</Typography>
                  </Box>
                  {/* Google Cloud - Will customer issue PO / LOA / Order Form/ other ? */}
                  {data.product === 'gcp' ? (
                    <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                      <Typography
                        variant="caption-bold"
                        css={{
                          color: '$secondary40',
                        }}>{`Will customer issue PO / LOA / Order Form/ other ?`}</Typography>
                      <Typography
                        as="p"
                        variant="body"
                        css={{
                          mt: '$1',
                        }}>{`${data.will_customer_issue_po_loa_orderform_other}`}</Typography>
                    </Box>
                  ) : null}
                </Box>
                {/* Google Workspace */}
                {data.product === 'gws' ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Official Pricing Discount */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Official Pricing Discount`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          data.official_pricing_discount || '-'
                        }`}</Typography>
                      </Box>
                      {/* Fixed Amount Discount or Percentage Discount */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        {data.official_pricing_discount === 'Fixed amount discount' ? (
                          <>
                            <Typography
                              variant="caption-bold"
                              css={{
                                color: '$secondary40',
                              }}>{`Fixed Amount Discount / Currency`}</Typography>
                            <Typography as="p" variant="body" css={{ mt: '$1' }}>
                              {`${data.fixed_amount_discount || '-'}`}
                            </Typography>
                          </>
                        ) : null}
                        {data.official_pricing_discount === 'Percentage discount' ? (
                          <>
                            <Typography
                              variant="caption-bold"
                              css={{ color: '$secondary40' }}>{`Percentage discount`}</Typography>
                            <Typography as="p" variant="body" css={{ mt: '$1' }}>
                              {data.percentage_discount ? `${data.percentage_discount}` : '-'}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </>
                ) : null}
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Memo */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Memo`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{ mt: '$1', wordBreak: 'break-all' }}>{`${
                      data.memo || '-'
                    }`}</Typography>
                  </Box>
                </Box>
                {data.product === 'gws' ? (
                  <>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Google Workspace - Special Price From Google */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Special Price From Google`}</Typography>
                        <Typography
                          as="p"
                          variant="body"
                          css={{
                            mt: '$1',
                          }}>{`${data.special_price_from_google}`}</Typography>
                      </Box>
                      {/* Google Workspace - Special Price Workspace Plan */}
                      {data.special_price_from_google === 'Yes' ? (
                        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                          <Typography
                            variant="caption-bold"
                            css={{
                              color: '$secondary40',
                            }}>{`Special Price Workspace Plan`}</Typography>
                          <Typography
                            as="p"
                            variant="body"
                            css={{
                              mt: '$1',
                            }}>{`${data.special_price_workspace_plan}`}</Typography>
                        </Box>
                      ) : null}
                    </Box>
                    {data.special_price_from_google === 'Yes' ? (
                      <>
                        <Spacer axis="vertical" css={{ $$size: '20px' }} />
                        {/* Google Workspace - Special Price From Google Subscription Term */}
                        <Box css={{ display: 'flex', columnGap: '$5' }}>
                          <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                            <Typography
                              variant="caption-bold"
                              css={{
                                color: '$secondary40',
                              }}>{`Special Price From Google Subscription Term`}</Typography>
                            <Typography
                              as="p"
                              variant="body"
                              css={{
                                mt: '$1',
                              }}>{`${
                              data.special_price_from_google_subscription_term === '-1'
                                ? data.special_price_from_google_subscription_term_other
                                : data.special_price_from_google_subscription_term
                            }`}</Typography>
                          </Box>
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ),
          },
          {
            id: 'panel-attach-files',
            name: 'Attach Files',
            content: (
              <>
                {data.product === 'gcp' ? (
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 20,
                    }}>
                    {/* Google Cloud - Google Addendum */}
                    {data.google_addendum ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={data.google_addendum.url}
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography as="p" variant="body-medium">{`Google Addendum`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{
                              color: '$secondary70',
                            }}>{`${data.google_addendum.size} MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {/* Google Cloud - Company Registration */}
                    {data.company_registration ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={data.company_registration.preview_url}
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body-medium">{`Company Registration`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{
                              color: '$secondary70',
                            }}>{`${data.company_registration.size} MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {!data.company_registration && !data.google_addendum ? '-' : null}
                  </Box>
                ) : null}
                {data.product === 'gws' ? (
                  <>
                    {/* Ｗorkspace - Company Registration */}
                    {data.company_registration ? (
                      <Box
                        css={{
                          width: '100%',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                          mb: 8,
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={data.company_registration.preview_url}
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            as="p"
                            variant="body-medium">{`Company Registration`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{
                              color: '$secondary70',
                            }}>{`${data.company_registration.size} MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {/* FSR Mail */}
                    {data.fsr_mail ? (
                      <Box
                        css={{
                          flexBasis: 'calc(100%)',
                          borderRadius: '$10',
                          borderStyle: 'solid',
                          borderColor: '$secondary20',
                          borderWidth: '1px',
                          p: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          columnGap: '$2',
                        }}>
                        <Box
                          css={{
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Box
                            as="img"
                            src={data.fsr_mail.preview_url}
                            css={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                              borderRadius: '$5',
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography as="p" variant="body-medium">{`FSR Mail`}</Typography>
                          {/* 四捨五入到小數點第二位 */}
                          <Typography
                            as="p"
                            variant="caption-bold"
                            css={{
                              color: '$secondary70',
                            }}>{`${data.fsr_mail.size} MB`}</Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {!data.company_registration && !data.fsr_mail ? '-' : null}
                  </>
                ) : null}
              </>
            ),
          },
        ]}
      />
    </Box>
  );
};

export default Submission;
