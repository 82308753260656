import { useNavigate } from 'react-router-dom';
import Box from '../components/box';
import Typography from '../components/typography/deprecated-typography';
import NoAccessBG from '../assets/images/bg-no-access.svg';
import Button from '../components/button/deprecated-button';

const NoAccessPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      css={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 10,
      }}>
      <Box as="img" src={NoAccessBG} />
      <Typography
        variant={'button-bold'}>{`You are not allowed to access to this page`}</Typography>
      <Box>
        <Typography
          as="p"
          variant={'body'}
          align={'center'}>{`Make sure the data exists in this system.`}</Typography>
        <Typography
          as="p"
          variant={'body'}
          align={
            'center'
          }>{`If it does, ask an admin for permission to see the issues.`}</Typography>
      </Box>
      <Button variant="solid" size={'small'} onClick={() => navigate(-1)}>{`Back`}</Button>
    </Box>
  );
};

export default NoAccessPage;
