import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { DropdownOption } from '../../../types';
import {
  CurrencyType,
  MonthField,
  GetAgreementEndDatePayload,
  GetAgreementEndDateResponse,
  PostPUPPPayload,
  PostPUPPResponse,
  ProductType,
  PLPSSupportPlanType,
  GetCMPUPPResponseEntity,
} from '../../../types/application/constants';

export const useGetCMLegalEntityOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'cm-legal-entity'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/cm_legal_entity`);
      return data;
    },
  });
};
export const useGetCMAttentionToOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'cm-attention-to'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/cm_attention_to`);
      return data;
    },
  });
};
export const useGetCountryCallingCodeOptions = (options: { isCMOnly: boolean }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'country-calling-code', options.isCMOnly],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/cm_country_calling_code`, {
        params: {
          cloudmile_entity_only: options.isCMOnly,
        },
      });
      return data;
    },
  });
};
export const useGetMonthOptions = (options: { field: MonthField }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'month', options.field],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/month`, {
        params: {
          state: options.field,
        },
      });
      return data;
    },
  });
};
export const useGetCurrencyOptions = (options: {
  currencyType: CurrencyType;
  cmLegalEntity: string;
  exchangeRate?: string;
}) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: [
      'options',
      'currency',
      options.currencyType,
      options.cmLegalEntity,
      options.exchangeRate,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/currency`, {
        params: {
          currency_type: options.currencyType,
          cm_legal_entity_value: options.cmLegalEntity,
          exchange_rate_value: options.exchangeRate,
        },
      });
      return data;
    },
    // enabled: false,
  });
};
export const useGetExchangeRateOptions = (options: {
  product: ProductType;
  cmLegalEntity: string;
  currency: string;
}) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: [
      'options',
      'exchange-rate',
      options.product,
      options.cmLegalEntity,
      options.currency,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/exchange_rate`, {
        params: {
          currency_value: options.currency,
          cm_legal_entity_value: options.cmLegalEntity,
          product: options.product,
        },
      });
      return data;
    },
    // enabled: false,
  });
};
export const useGetClientOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'client'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/client`);
      return data;
    },
  });
};
export const useGetPLPSClientOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'client', 'plps'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/plps_client`);
      return data;
    },
  });
};
export const useGetCountryCodeOptions = (options: { isCMOnly: boolean }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'country-code'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/client_country`, {
        params: {
          cloudmile_entity_only: options.isCMOnly,
        },
      });
      return data;
    },
  });
};
export const useGetPaymentTermOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'payment-term'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/payment_term`);
      return data;
    },
  });
};
export const useGetOfficialPricingDiscountOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'official-pricing-discount'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/official_pricing_discount_type`);
      return data;
    },
  });
};
export const useGetSupportPlanOptions = (options: { product: ProductType }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', options.product, 'support-plan'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/support_plan`, {
        params: {
          product: options.product,
        },
      });
      return data;
    },
  });
};
export const useGetPLPSSupportPlanOptions = (options: { situation: PLPSSupportPlanType }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'plps_support_plan', options.situation],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/plps_support_plan`, {
        params: {
          situation: options.situation,
        },
      });
      return data;
    },
  });
};
export const useGetAgreementEndDate = (options: GetAgreementEndDatePayload) => {
  const axios = useAxios();
  return useQuery<GetAgreementEndDateResponse, AxiosError>({
    queryKey: [
      'options',
      'agreement-end-date',
      options.agreementCommencementDate,
      options.agreementTerm,
    ],
    queryFn: async () => {
      const { data } = await axios.get(`v1/validation/end_date`, {
        params: {
          agreement_term: options.agreementTerm,
          agreement_commencement_date: options.agreementCommencementDate,
        },
      });
      return data;
    },
    enabled: !!options.agreementTerm && !!options.agreementCommencementDate,
  });
};
export const useGetPLPSBillingAccountOptions = (options: { client: string }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'billing-account', 'plps', options.client],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/plps_billing_account`, {
        params: {
          customer_id: options.client,
        },
      });
      return data;
    },
    enabled: !!options.client,
  });
};
export const useGetSealTypeOptions = (options: { cmLegalEntity: string }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'seal-type', options.cmLegalEntity],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/seal_type`, {
        params: {
          cm_legal_entity_value: options.cmLegalEntity,
        },
      });
      return data;
    },
    enabled: !!options.cmLegalEntity,
  });
};
export const useGetMentionableUserOptions = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', options.appId, 'mentionable-user'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/mentionable_user`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};
export const usePostPUPP = () => {
  const axios = useAxios();
  return useMutation<PostPUPPResponse, AxiosError<{ message: string }>, PostPUPPPayload>({
    mutationKey: ['options', 'pupp'],
    mutationFn: async (requestBody: PostPUPPPayload) => {
      const { data } = await axios.post<PostPUPPResponse>(
        `v1/validation/per_user_per_period`,
        requestBody
      );
      return data;
    },
  });
};
export const useGetWorkspaceEditionOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'workspace-edition'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/workspace_edition`);
      return data;
    },
  });
};
export const useGetPUPPTypeOptions = () => {
  const axios = useAxios();
  return useQuery<DropdownOption[], AxiosError>({
    queryKey: ['options', 'PUPP-type'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/per_user_per_period_type`);
      return data;
    },
  });
};
export const useGetPUPPFromCM = (options: {
  officialPricingDiscountType: string;
  officialPricingDiscountValue: number;
  officialPricingDiscountCurrency: string;
  workspaceEditions: string[];
  workspacePlans: string[];
}) => {
  const axios = useAxios();
  return useQuery<GetCMPUPPResponseEntity[], AxiosError>({
    queryKey: ['options', 'PUPP-from-CM'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app_dropdown/per_user_per_period`, {
        params: {
          official_pricing_discount_type: options.officialPricingDiscountType,
          official_pricing_discount_value: options.officialPricingDiscountValue,
          official_pricing_discount_currency: options.officialPricingDiscountCurrency,
          workspace_edition: options.workspaceEditions,
          workspace_plan: options.workspacePlans,
        },
      });
      return data;
    },
  });
};
