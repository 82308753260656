/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  submissionFormCloudmileAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import {
  useGetSpecialDealAppOptions,
  useGetMonthAppOptions,
} from '../../../../../../services/application/official-standard/app-options.api';
import { useGetCustomerToCMCurrencyOracleOptions } from '../../../../../../services/application/official-standard/oracle-options.api';
import { addCommaForInput } from '../../../../../../utils/strict-for-field';
import Spacer from '../../../../../../components/surface/spacer';
import Box from '../../../../../../components/box';
import Label from '../../../../../../components/typography/label';
import Checkbox from '../../../../../../components/field/checkbox';
import SubmissionUrgentBox from '../../../../../../components/card/submission-urgent-box';
import Typography from '../../../../../../components/typography/deprecated-typography';
import Select from '../../../../../../components/dropdown/select';
import Field from '../../../../../../components/field/deprecated-field';
import RadioField from '../../../../../../components/field/deprecated-radio-field';
import ColorfulChip from '../../../../../../components/chip/colorful-chip';
import InputErrorMessage from '../../../../../../components/typography/input-error-message';
import {
  SimpleSelectContent,
  SimpleSelectItem,
  SimpleSelectItemText,
  SimpleSelectPortal,
  SimpleSelectRoot,
  SimpleSelectTrigger,
  SimpleSelectValue,
  SimpleSelectViewport,
} from '../../../../../../components/dropdown/simple-select';

const initCommitmentData = {
  commitment_period: '',
  eligibility_period: '',
  b2b_lite_commitment_period: '',
  cm_to_google_commitment_amount: '',
  cm_to_google_commitment_amount_1yr: '',
  cm_to_google_commitment_amount_2yr: '',
  cm_to_google_commitment_amount_3yr: '',
  cm_to_google_commitment_amount_4yr: '',
  cm_to_google_commitment_amount_5yr: '',
  cm_to_google_consumption_milestone_amount: '',
  cm_to_google_service_credit_amount: '',
  customer_to_cm_commitment_amount: '',
  customer_to_cm_commitment_amount_1yr: '',
  customer_to_cm_commitment_amount_2yr: '',
  customer_to_cm_commitment_amount_3yr: '',
  customer_to_cm_commitment_amount_4yr: '',
  customer_to_cm_commitment_amount_5yr: '',
  customer_to_cm_consumption_milestone_amount: '',
  customer_to_cm_service_credit_amount: '',
  b2b_lite_customer_to_cm_commitment_amount: '',
  b2b_lite_customer_to_cm_commitment_amount_1yr: '',
  b2b_lite_customer_to_cm_commitment_amount_2yr: '',
  b2b_lite_customer_to_cm_commitment_amount_3yr: '',
  b2b_lite_customer_to_cm_commitment_amount_4yr: '',
  b2b_lite_customer_to_cm_commitment_amount_5yr: '',
};

const GcpCloudmileServiceDocument = () => {
  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormValidation, setSubmissionFormValidation] = useAtom(
    submissionFormValidationAtom
  );

  const commitmentPeriodAppOptionsQueries = useGetMonthAppOptions(
    'commitment_period',
    'commitment-period'
  );
  const specialDealAppOptionsQueries = useGetSpecialDealAppOptions();

  const customerToCMCurrencyOracleOptionsQueries = useGetCustomerToCMCurrencyOracleOptions(
    'gcp',
    submissionFormCloudmile.gcp.cm_legal_entity,
    (res) => {
      if (!submissionFormCloudmile.gcp.customer_to_cm_currency) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gcp: { ...prev.gcp, customer_to_cm_currency: res[0].value },
        }));
      }
    }
  );

  const clearCommitmentDataValidationError = useCallback(
    (initData: { [key: string]: string }) => {
      const initCommitmentValidationData = Object.keys(initData).reduce(
        (acc: { [key: string]: any }, key) => {
          acc[key] = undefined;
          return acc;
        },
        {}
      );
      setSubmissionFormValidation((prev) => ({ ...prev, ...initCommitmentValidationData }));
    },
    [setSubmissionFormValidation]
  );

  useEffect(() => {
    if (submissionFormCloudmile.gcp.commitment_period === '12') {
      const {
        commitment_period,
        cm_to_google_commitment_amount,
        cm_to_google_commitment_amount_1yr,
        customer_to_cm_commitment_amount,
        customer_to_cm_commitment_amount_1yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.commitment_period === '24') {
      const {
        commitment_period,
        cm_to_google_commitment_amount,
        cm_to_google_commitment_amount_1yr,
        cm_to_google_commitment_amount_2yr,
        customer_to_cm_commitment_amount,
        customer_to_cm_commitment_amount_1yr,
        customer_to_cm_commitment_amount_2yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.commitment_period === '36') {
      const {
        commitment_period,
        cm_to_google_commitment_amount,
        cm_to_google_commitment_amount_1yr,
        cm_to_google_commitment_amount_2yr,
        cm_to_google_commitment_amount_3yr,
        customer_to_cm_commitment_amount,
        customer_to_cm_commitment_amount_1yr,
        customer_to_cm_commitment_amount_2yr,
        customer_to_cm_commitment_amount_3yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.commitment_period === '48') {
      const {
        commitment_period,
        cm_to_google_commitment_amount,
        cm_to_google_commitment_amount_1yr,
        cm_to_google_commitment_amount_2yr,
        cm_to_google_commitment_amount_3yr,
        cm_to_google_commitment_amount_4yr,
        customer_to_cm_commitment_amount,
        customer_to_cm_commitment_amount_1yr,
        customer_to_cm_commitment_amount_2yr,
        customer_to_cm_commitment_amount_3yr,
        customer_to_cm_commitment_amount_4yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
  }, [
    clearCommitmentDataValidationError,
    setSubmissionFormCloudmile,
    submissionFormCloudmile.gcp.commitment_period,
  ]);

  useEffect(() => {
    if (submissionFormCloudmile.gcp.b2b_lite_commitment_period === '12') {
      const {
        b2b_lite_commitment_period,
        b2b_lite_customer_to_cm_commitment_amount,
        b2b_lite_customer_to_cm_commitment_amount_1yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.b2b_lite_commitment_period === '24') {
      const {
        b2b_lite_commitment_period,
        b2b_lite_customer_to_cm_commitment_amount,
        b2b_lite_customer_to_cm_commitment_amount_1yr,
        b2b_lite_customer_to_cm_commitment_amount_2yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.b2b_lite_commitment_period === '36') {
      const {
        b2b_lite_commitment_period,
        b2b_lite_customer_to_cm_commitment_amount,
        b2b_lite_customer_to_cm_commitment_amount_1yr,
        b2b_lite_customer_to_cm_commitment_amount_2yr,
        b2b_lite_customer_to_cm_commitment_amount_3yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
    if (submissionFormCloudmile.gcp.b2b_lite_commitment_period === '48') {
      const {
        b2b_lite_commitment_period,
        b2b_lite_customer_to_cm_commitment_amount,
        b2b_lite_customer_to_cm_commitment_amount_1yr,
        b2b_lite_customer_to_cm_commitment_amount_2yr,
        b2b_lite_customer_to_cm_commitment_amount_3yr,
        b2b_lite_customer_to_cm_commitment_amount_4yr,
        ...newCommitmentData
      } = initCommitmentData;
      setSubmissionFormCloudmile((prev) => ({
        ...prev,
        gcp: {
          ...prev.gcp,
          ...newCommitmentData,
        },
      }));
      clearCommitmentDataValidationError(newCommitmentData);
    }
  }, [
    clearCommitmentDataValidationError,
    setSubmissionFormCloudmile,
    submissionFormCloudmile.gcp.b2b_lite_commitment_period,
  ]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(100%)' }}>
          {/* Special Deal*/}
          <RadioField
            label={`Special Deal`}
            required={true}
            value={submissionFormCloudmile.gcp.special_deal as string}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: {
                  ...prev.gcp,
                  special_deal: newValue,
                  customer_to_cm_currency: customerToCMCurrencyOracleOptionsQueries.data
                    ? customerToCMCurrencyOracleOptionsQueries.data[0].value
                    : '',
                  ...initCommitmentData,
                },
              }));
              clearCommitmentDataValidationError(initCommitmentData);
            }}
            options={specialDealAppOptionsQueries.data ?? []}
          />
        </Box>
      </Box>
      {submissionFormCloudmile.gcp.special_deal &&
      submissionFormCloudmile.gcp.special_deal !== 'None' ? (
        <>
          <Spacer axis="vertical" css={{ $$size: '20px' }} />
          {/* Special Deal Section */}
          <Box css={{ borderRadius: '$5', backgroundColor: '$primary10', p: 10 }}>
            <ColorfulChip
              variant="outlined"
              color="sky"
              text={`${submissionFormCloudmile.gcp.special_deal || '-'}`}
            />
            <Spacer axis="vertical" css={{ $$size: '20px' }} />
            {/* EDP, CNSP, Google Full B2B */}
            {submissionFormCloudmile.gcp.special_deal === 'EDP' ||
            submissionFormCloudmile.gcp.special_deal === 'CNSP' ||
            submissionFormCloudmile.gcp.special_deal === 'Google Full B2B' ? (
              <>
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Commitment Period */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)', mr: 'auto' }}>
                    <Select
                      label="Commitment Period"
                      required={true}
                      value={submissionFormCloudmile.gcp.commitment_period ?? ''}
                      placeholder="Select a commitment period"
                      options={commitmentPeriodAppOptionsQueries.data}
                      onChange={(newValue) => {
                        setSubmissionFormCloudmile((prev) => ({
                          ...prev,
                          gcp: { ...prev.gcp, commitment_period: newValue },
                        }));
                      }}
                      isLoading={commitmentPeriodAppOptionsQueries.isLoading}
                      isError={submissionFormValidation.commitment_period?.is_error ?? false}
                      error={submissionFormValidation.commitment_period?.error ?? undefined}
                      disabled={!submissionFormCloudmile.gcp.special_deal}
                    />
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex' }}>
                  {/* [CM to Google] Commitment Amount / Per Year */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Label>
                      <Typography variant="caption">
                        {`[CM to Google] Commitment Amount / Per Year`}
                        <Typography
                          variant="caption"
                          css={{ color: '$error50' }}>{` *`}</Typography>
                      </Typography>
                    </Label>
                    <Box
                      css={{
                        display: 'grid',
                        gridRowGap: '10px',
                        gridColumnGap: '10px',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gridTemplateRows: 'auto auto',
                        mt: '$1',
                      }}>
                      {/* [CM to Google] Total */}
                      <Box css={{ gridColumn: '1 / span 2', display: 'flex', columnGap: 7 }}>
                        <Box css={{ flexGrow: 1 }}>
                          <Field
                            isLightOnly={true}
                            value={submissionFormCloudmile.gcp.cm_to_google_commitment_amount ?? ''}
                            onChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: {
                                  ...prev.gcp,
                                  cm_to_google_commitment_amount: addCommaForInput(newValue),
                                },
                              }));
                            }}
                            startElement={
                              <Typography
                                variant={'button-12'}
                                css={{
                                  color: '$secondary70',
                                  whiteSpace: 'nowrap',
                                }}>{`Total`}</Typography>
                            }
                            isError={
                              submissionFormValidation.cm_to_google_commitment_amount?.is_error
                            }
                            error={submissionFormValidation.cm_to_google_commitment_amount?.error}
                          />
                        </Box>
                        <Box
                          css={{
                            flexShrink: 0,
                            flexBasis: 26,
                            height: 30,
                            borderRadius: '$5',
                            px: 6,
                            backgroundColor: '$secondary5',
                            cursor: 'not-allowed',
                          }}>
                          <Typography
                            variant={'button-12'}
                            css={{ color: '$secondary20' }}>{`USD`}</Typography>
                        </Box>
                      </Box>
                      {/* [CM to Google] 1st yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_commitment_amount_1yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_commitment_amount_1yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`1st yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          isError={
                            submissionFormValidation.cm_to_google_commitment_amount_1yr?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_commitment_amount_1yr?.error}
                        />
                      </Box>
                      {/* [CM to Google] 2nd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_commitment_amount_2yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_commitment_amount_2yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`2nd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              2 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.cm_to_google_commitment_amount_2yr?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_commitment_amount_2yr?.error}
                        />
                      </Box>
                      {/* [CM to Google] 3rd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_commitment_amount_3yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_commitment_amount_3yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`3rd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              3 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.cm_to_google_commitment_amount_3yr?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_commitment_amount_3yr?.error}
                        />
                      </Box>
                      {/* [CM to Google] 4th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_commitment_amount_4yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_commitment_amount_4yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`4th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              4 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.cm_to_google_commitment_amount_4yr?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_commitment_amount_4yr?.error}
                        />
                      </Box>
                      {/* [CM to Google] 5th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_commitment_amount_5yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_commitment_amount_5yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`5th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              5 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.cm_to_google_commitment_amount_5yr?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_commitment_amount_5yr?.error}
                        />
                      </Box>
                    </Box>
                    {submissionFormValidation.cm_to_google_commitment_amount ? (
                      <InputErrorMessage
                        customHeight={24}
                        isError={submissionFormValidation.cm_to_google_commitment_amount.is_error}
                        errorMessage={submissionFormValidation.cm_to_google_commitment_amount.error}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* [Customer to CM] Commitment Amount / Per Year */}
                <Box css={{ display: 'flex' }}>
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Label>
                      <Typography variant="caption">
                        {`[Customer to CM] Commitment Amount / Per Year`}
                        <Typography
                          variant="caption"
                          css={{ color: '$error50' }}>{` *`}</Typography>
                      </Typography>
                    </Label>
                    <Box
                      css={{
                        display: 'grid',
                        gridRowGap: '10px',
                        gridColumnGap: '10px',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gridTemplateRows: 'auto auto',
                        mt: '$1',
                      }}>
                      {/* [Customer to CM] Total */}
                      <Box css={{ gridColumn: '1 / span 2', display: 'flex', columnGap: 7 }}>
                        <Box css={{ flexGrow: 1 }}>
                          <Field
                            isLightOnly={true}
                            value={
                              submissionFormCloudmile.gcp.customer_to_cm_commitment_amount ?? ''
                            }
                            onChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: {
                                  ...prev.gcp,
                                  customer_to_cm_commitment_amount: addCommaForInput(newValue),
                                },
                              }));
                            }}
                            startElement={
                              <Typography
                                variant={'button-12'}
                                css={{
                                  color: '$secondary70',
                                  whiteSpace: 'nowrap',
                                }}>{`Total`}</Typography>
                            }
                            isError={
                              submissionFormValidation.customer_to_cm_commitment_amount?.is_error
                            }
                            error={submissionFormValidation.customer_to_cm_commitment_amount?.error}
                          />
                        </Box>
                        <Box css={{ flexBasis: 26, flexShrink: 0, cursor: 'pointer' }}>
                          <SimpleSelectRoot
                            value={submissionFormCloudmile.gcp.customer_to_cm_currency}
                            onValueChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: { ...prev.gcp, customer_to_cm_currency: newValue },
                              }));
                            }}>
                            <SimpleSelectTrigger
                              css={{
                                all: 'unset',
                                display: 'block',
                                width: 26,
                                height: 18,
                                color: '$secondary90',
                                backgroundColor: '$secondary20',
                                borderColor: '$secondary20',
                                transition: '$colors',
                                borderRadius: '$5',
                                p: 6,
                                '&:hover': {
                                  color: '$secondary90',
                                  borderColor: '$secondary10',
                                  backgroundColor: '$secondary10',
                                },
                                '&[data-state=open]': {
                                  color: '$secondary90',
                                  backgroundColor: '$secondary30',
                                  borderColor: '$secondary30',
                                },
                              }}>
                              <Box
                                css={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontSize: 12,
                                }}>
                                <SimpleSelectValue placeholder="" />
                              </Box>
                            </SimpleSelectTrigger>
                            <SimpleSelectPortal>
                              <SimpleSelectContent
                                position="popper"
                                sideOffset={4}
                                css={{ width: 'revert' }}>
                                <SimpleSelectViewport>
                                  {customerToCMCurrencyOracleOptionsQueries.data &&
                                  customerToCMCurrencyOracleOptionsQueries.data.length > 0 ? (
                                    customerToCMCurrencyOracleOptionsQueries.data?.map(
                                      (customerToCMCurrencyOption) => (
                                        <SimpleSelectItem
                                          key={customerToCMCurrencyOption.id}
                                          value={customerToCMCurrencyOption.value}>
                                          <SimpleSelectItemText>{`${customerToCMCurrencyOption.name}`}</SimpleSelectItemText>
                                        </SimpleSelectItem>
                                      )
                                    )
                                  ) : (
                                    <Typography
                                      variant={'caption'}
                                      css={{ color: '$secondary40' }}>{`No Result`}</Typography>
                                  )}
                                </SimpleSelectViewport>
                              </SimpleSelectContent>
                            </SimpleSelectPortal>
                          </SimpleSelectRoot>
                        </Box>
                      </Box>
                      {/* [Customer to CM] 1st yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_1yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_commitment_amount_1yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`1st yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          isError={
                            submissionFormValidation.customer_to_cm_commitment_amount_1yr?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_commitment_amount_1yr?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 2nd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_2yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_commitment_amount_2yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`2nd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              2 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.customer_to_cm_commitment_amount_2yr?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_commitment_amount_2yr?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 3rd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_3yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_commitment_amount_3yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`3rd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              3 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.customer_to_cm_commitment_amount_3yr?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_commitment_amount_3yr?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 4th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_4yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_commitment_amount_4yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`4th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              4 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.customer_to_cm_commitment_amount_4yr?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_commitment_amount_4yr?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 5th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_commitment_amount_5yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_commitment_amount_5yr: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`5th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(submissionFormCloudmile.gcp.commitment_period as string) / 12 <
                              5 || !submissionFormCloudmile.gcp.commitment_period
                          }
                          isError={
                            submissionFormValidation.customer_to_cm_commitment_amount_5yr?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_commitment_amount_5yr?.error
                          }
                        />
                      </Box>
                    </Box>
                    {submissionFormValidation.customer_to_cm_commitment_amount ? (
                      <InputErrorMessage
                        customHeight={24}
                        isError={submissionFormValidation.customer_to_cm_commitment_amount.is_error}
                        errorMessage={
                          submissionFormValidation.customer_to_cm_commitment_amount.error
                        }
                      />
                    ) : null}
                  </Box>
                </Box>
              </>
            ) : null}
            {/* Flex */}
            {submissionFormCloudmile.gcp.special_deal === 'Flex' ? (
              <>
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Eligibility Period */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)', mr: 'auto' }}>
                    <Select
                      label="Eligibility Period"
                      required={true}
                      value={submissionFormCloudmile.gcp.eligibility_period ?? ''}
                      placeholder="Select a eligibility Period period"
                      options={commitmentPeriodAppOptionsQueries.data}
                      onChange={(newValue) => {
                        setSubmissionFormCloudmile((prev) => ({
                          ...prev,
                          gcp: { ...prev.gcp, eligibility_period: newValue },
                        }));
                      }}
                      isLoading={commitmentPeriodAppOptionsQueries.isLoading}
                      isError={submissionFormValidation.eligibility_period?.is_error ?? false}
                      error={submissionFormValidation.eligibility_period?.error ?? undefined}
                      disabled={!submissionFormCloudmile.gcp.special_deal}
                    />
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex' }}>
                  {/* [CM to Google] Consumption Milestone and corresponding Service Credit */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Label>
                      <Typography variant="caption">
                        {`[CM to Google] Consumption Milestone and corresponding Service Credit`}
                        <Typography
                          variant="caption"
                          css={{ color: '$error50' }}>{` *`}</Typography>
                      </Typography>
                    </Label>
                    <Box
                      css={{
                        display: 'grid',
                        gridColumnGap: '20px',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        mt: '$1',
                      }}>
                      {/* [CM to Google] Consumption Milestone Amount */}
                      <Box css={{ display: 'flex', columnGap: 7 }}>
                        <Box css={{ flexGrow: 1 }}>
                          <Field
                            value={
                              submissionFormCloudmile.gcp
                                .cm_to_google_consumption_milestone_amount ?? ''
                            }
                            onChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: {
                                  ...prev.gcp,
                                  cm_to_google_consumption_milestone_amount:
                                    addCommaForInput(newValue),
                                },
                              }));
                            }}
                            startElement={
                              <Typography
                                variant={'button-12'}
                                css={{
                                  color: '$secondary70',
                                  whiteSpace: 'nowrap',
                                }}>{`Milestone`}</Typography>
                            }
                            disabled={submissionFormCloudmile.gcp.special_deal !== 'Flex'}
                            isError={
                              submissionFormValidation.cm_to_google_consumption_milestone_amount
                                ?.is_error
                            }
                            error={
                              submissionFormValidation.cm_to_google_consumption_milestone_amount
                                ?.error
                            }
                          />
                        </Box>
                        <Box
                          css={{
                            flexShrink: 0,
                            flexBasis: 26,
                            height: 30,
                            borderRadius: '$5',
                            px: 6,
                            backgroundColor: '$secondary5',
                            cursor: 'not-allowed',
                          }}>
                          <Typography
                            variant={'button-12'}
                            css={{ color: '$secondary20' }}>{`USD`}</Typography>
                        </Box>
                      </Box>
                      {/* [CM to Google] Service Credit Amount */}
                      <Box>
                        <Field
                          value={
                            submissionFormCloudmile.gcp.cm_to_google_service_credit_amount ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                cm_to_google_service_credit_amount: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`Credit Amount`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`USD`}</Typography>
                          }
                          disabled={submissionFormCloudmile.gcp.special_deal !== 'Flex'}
                          isError={
                            submissionFormValidation.cm_to_google_service_credit_amount?.is_error
                          }
                          error={submissionFormValidation.cm_to_google_service_credit_amount?.error}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex' }}>
                  {/* [Customer to CM] Consumption Milestone and Corresponding Service Credit */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Label>
                      <Typography variant="caption">
                        {`[Customer to CM] Consumption Milestone and Corresponding Service Credit`}
                        <Typography
                          variant="caption"
                          css={{ color: '$error50' }}>{` *`}</Typography>
                      </Typography>
                    </Label>
                    <Box
                      css={{
                        display: 'grid',
                        gridColumnGap: '20px',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        mt: '$1',
                      }}>
                      {/* [Customer to CM] Consumption Milestone Amount */}
                      <Box css={{ display: 'flex', columnGap: 7 }}>
                        <Box css={{ flexGrow: 1 }}>
                          <Field
                            value={
                              submissionFormCloudmile.gcp
                                .customer_to_cm_consumption_milestone_amount ?? ''
                            }
                            onChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: {
                                  ...prev.gcp,
                                  customer_to_cm_consumption_milestone_amount:
                                    addCommaForInput(newValue),
                                },
                              }));
                            }}
                            startElement={
                              <Typography
                                variant={'button-12'}
                                css={{
                                  color: '$secondary70',
                                  whiteSpace: 'nowrap',
                                }}>{`Milestone`}</Typography>
                            }
                            disabled={submissionFormCloudmile.gcp.special_deal !== 'Flex'}
                            isError={
                              submissionFormValidation.customer_to_cm_consumption_milestone_amount
                                ?.is_error
                            }
                            error={
                              submissionFormValidation.customer_to_cm_consumption_milestone_amount
                                ?.error
                            }
                          />
                        </Box>
                        <Box css={{ flexBasis: 26, flexShrink: 0, cursor: 'pointer' }}>
                          <SimpleSelectRoot
                            value={submissionFormCloudmile.gcp.customer_to_cm_currency}
                            onValueChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: { ...prev.gcp, customer_to_cm_currency: newValue },
                              }));
                            }}>
                            <SimpleSelectTrigger
                              css={{
                                all: 'unset',
                                display: 'block',
                                width: 26,
                                height: 18,
                                color: '$secondary90',
                                backgroundColor: '$secondary20',
                                borderColor: '$secondary20',
                                transition: '$colors',
                                borderRadius: '$5',
                                p: 6,
                                '&:hover': {
                                  color: '$secondary90',
                                  borderColor: '$secondary10',
                                  backgroundColor: '$secondary10',
                                },
                                '&[data-state=open]': {
                                  color: '$secondary90',
                                  backgroundColor: '$secondary30',
                                  borderColor: '$secondary30',
                                },
                              }}>
                              <Box
                                css={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontSize: 12,
                                }}>
                                <SimpleSelectValue placeholder="" />
                              </Box>
                            </SimpleSelectTrigger>
                            <SimpleSelectPortal>
                              <SimpleSelectContent
                                position="popper"
                                sideOffset={4}
                                css={{ width: 'revert' }}>
                                <SimpleSelectViewport>
                                  {customerToCMCurrencyOracleOptionsQueries.data &&
                                  customerToCMCurrencyOracleOptionsQueries.data.length > 0 ? (
                                    customerToCMCurrencyOracleOptionsQueries.data?.map(
                                      (customerToCMCurrencyOption) => (
                                        <SimpleSelectItem
                                          key={customerToCMCurrencyOption.id}
                                          value={customerToCMCurrencyOption.value}>
                                          <SimpleSelectItemText>{`${customerToCMCurrencyOption.name}`}</SimpleSelectItemText>
                                        </SimpleSelectItem>
                                      )
                                    )
                                  ) : (
                                    <Typography
                                      variant={'caption'}
                                      css={{ color: '$secondary40' }}>{`No Result`}</Typography>
                                  )}
                                </SimpleSelectViewport>
                              </SimpleSelectContent>
                            </SimpleSelectPortal>
                          </SimpleSelectRoot>
                        </Box>
                      </Box>
                      {/* [Customer to CM] Service Credit Amount */}
                      <Box>
                        <Field
                          value={
                            submissionFormCloudmile.gcp.customer_to_cm_service_credit_amount ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                customer_to_cm_service_credit_amount: addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`Credit Amount`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={submissionFormCloudmile.gcp.special_deal !== 'Flex'}
                          isError={
                            submissionFormValidation.customer_to_cm_service_credit_amount?.is_error
                          }
                          error={
                            submissionFormValidation.customer_to_cm_service_credit_amount?.error
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : null}
            {/* CM B2B Lite */}
            {submissionFormCloudmile.gcp.special_deal === 'CM B2B Lite' ? (
              <>
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* B2B Lite Commitment Period */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)', mr: 'auto' }}>
                    <Select
                      label="B2B Lite Commitment Period"
                      required={true}
                      value={submissionFormCloudmile.gcp.b2b_lite_commitment_period ?? ''}
                      placeholder="Select a b2b lite commitment period"
                      options={commitmentPeriodAppOptionsQueries.data}
                      onChange={(newValue) => {
                        setSubmissionFormCloudmile((prev) => ({
                          ...prev,
                          gcp: { ...prev.gcp, b2b_lite_commitment_period: newValue },
                        }));
                      }}
                      isLoading={commitmentPeriodAppOptionsQueries.isLoading}
                      isError={
                        submissionFormValidation.b2b_lite_commitment_period?.is_error ?? false
                      }
                      error={
                        submissionFormValidation.b2b_lite_commitment_period?.error ?? undefined
                      }
                    />
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex' }}>
                  {/* [Customer to CM] B2B Lite Commitment Amount / Per Year */}
                  <Box css={{ flexBasis: 'calc(100%)' }}>
                    <Label>
                      <Typography variant="caption">
                        {`[Customer to CM] B2B Lite Commitment Amount / Per Year`}
                        <Typography
                          variant="caption"
                          css={{ color: '$error50' }}>{` *`}</Typography>
                      </Typography>
                    </Label>
                    <Box
                      css={{
                        display: 'grid',
                        gridRowGap: '10px',
                        gridColumnGap: '10px',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gridTemplateRows: 'auto auto',
                        mt: '$1',
                      }}>
                      {/* [Customer to CM] Total */}
                      <Box css={{ gridColumn: '1 / span 2', display: 'flex', columnGap: 7 }}>
                        <Box css={{ flexGrow: 1 }}>
                          <Field
                            isLightOnly={true}
                            value={
                              submissionFormCloudmile.gcp
                                .b2b_lite_customer_to_cm_commitment_amount ?? ''
                            }
                            onChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: {
                                  ...prev.gcp,
                                  b2b_lite_customer_to_cm_commitment_amount:
                                    addCommaForInput(newValue),
                                },
                              }));
                            }}
                            startElement={
                              <Typography
                                variant={'button-12'}
                                css={{
                                  color: '$secondary70',
                                  whiteSpace: 'nowrap',
                                }}>{`Total`}</Typography>
                            }
                            isError={
                              submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount
                                ?.is_error
                            }
                            error={
                              submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount
                                ?.error
                            }
                          />
                        </Box>
                        <Box css={{ flexBasis: 26, flexShrink: 0, cursor: 'pointer' }}>
                          <SimpleSelectRoot
                            value={submissionFormCloudmile.gcp.customer_to_cm_currency}
                            onValueChange={(newValue) => {
                              setSubmissionFormCloudmile((prev) => ({
                                ...prev,
                                gcp: { ...prev.gcp, customer_to_cm_currency: newValue },
                              }));
                            }}>
                            <SimpleSelectTrigger
                              css={{
                                all: 'unset',
                                display: 'block',
                                width: 26,
                                height: 18,
                                color: '$secondary90',
                                backgroundColor: '$secondary20',
                                borderColor: '$secondary20',
                                transition: '$colors',
                                borderRadius: '$5',
                                p: 6,
                                '&:hover': {
                                  color: '$secondary90',
                                  borderColor: '$secondary10',
                                  backgroundColor: '$secondary10',
                                },
                                '&[data-state=open]': {
                                  color: '$secondary90',
                                  backgroundColor: '$secondary30',
                                  borderColor: '$secondary30',
                                },
                              }}>
                              <Box
                                css={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontSize: 12,
                                }}>
                                <SimpleSelectValue placeholder="" />
                              </Box>
                            </SimpleSelectTrigger>
                            <SimpleSelectPortal>
                              <SimpleSelectContent
                                position="popper"
                                sideOffset={4}
                                css={{ width: 'revert' }}>
                                <SimpleSelectViewport>
                                  {customerToCMCurrencyOracleOptionsQueries.data &&
                                  customerToCMCurrencyOracleOptionsQueries.data.length > 0 ? (
                                    customerToCMCurrencyOracleOptionsQueries.data?.map(
                                      (customerToCMCurrencyOption) => (
                                        <SimpleSelectItem
                                          key={customerToCMCurrencyOption.id}
                                          value={customerToCMCurrencyOption.value}>
                                          <SimpleSelectItemText>{`${customerToCMCurrencyOption.name}`}</SimpleSelectItemText>
                                        </SimpleSelectItem>
                                      )
                                    )
                                  ) : (
                                    <Typography
                                      variant={'caption'}
                                      css={{ color: '$secondary40' }}>{`No Result`}</Typography>
                                  )}
                                </SimpleSelectViewport>
                              </SimpleSelectContent>
                            </SimpleSelectPortal>
                          </SimpleSelectRoot>
                        </Box>
                      </Box>
                      {/* [Customer to CM] 1st yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp
                              .b2b_lite_customer_to_cm_commitment_amount_1yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                b2b_lite_customer_to_cm_commitment_amount_1yr:
                                  addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`1st yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          isError={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_1yr
                              ?.is_error
                          }
                          error={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_1yr
                              ?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 2nd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp
                              .b2b_lite_customer_to_cm_commitment_amount_2yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                b2b_lite_customer_to_cm_commitment_amount_2yr:
                                  addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`2nd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(
                              submissionFormCloudmile.gcp.b2b_lite_commitment_period as string
                            ) /
                              12 <
                              2 || !submissionFormCloudmile.gcp.b2b_lite_commitment_period
                          }
                          isError={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_2yr
                              ?.is_error
                          }
                          error={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_2yr
                              ?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 3rd yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp
                              .b2b_lite_customer_to_cm_commitment_amount_3yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                b2b_lite_customer_to_cm_commitment_amount_3yr:
                                  addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`3rd yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(
                              submissionFormCloudmile.gcp.b2b_lite_commitment_period as string
                            ) /
                              12 <
                              3 || !submissionFormCloudmile.gcp.b2b_lite_commitment_period
                          }
                          isError={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_3yr
                              ?.is_error
                          }
                          error={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_3yr
                              ?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 4th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp
                              .b2b_lite_customer_to_cm_commitment_amount_4yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                b2b_lite_customer_to_cm_commitment_amount_4yr:
                                  addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`4th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(
                              submissionFormCloudmile.gcp.b2b_lite_commitment_period as string
                            ) /
                              12 <
                              4 || !submissionFormCloudmile.gcp.b2b_lite_commitment_period
                          }
                          isError={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_4yr
                              ?.is_error
                          }
                          error={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_4yr
                              ?.error
                          }
                        />
                      </Box>
                      {/* [Customer to CM] 5th yr */}
                      <Box>
                        <Field
                          isLightOnly={true}
                          value={
                            submissionFormCloudmile.gcp
                              .b2b_lite_customer_to_cm_commitment_amount_5yr ?? ''
                          }
                          onChange={(newValue) => {
                            setSubmissionFormCloudmile((prev) => ({
                              ...prev,
                              gcp: {
                                ...prev.gcp,
                                b2b_lite_customer_to_cm_commitment_amount_5yr:
                                  addCommaForInput(newValue),
                              },
                            }));
                          }}
                          startElement={
                            <Typography
                              variant={'button-12'}
                              css={{
                                color: '$secondary70',
                                whiteSpace: 'nowrap',
                              }}>{`5th yr`}</Typography>
                          }
                          endElement={
                            <Typography
                              variant={'button-12'}
                              css={{ color: '$secondary70', whiteSpace: 'nowrap' }}>{`${
                              submissionFormCloudmile.gcp.customer_to_cm_currency ?? ''
                            }`}</Typography>
                          }
                          disabled={
                            parseInt(
                              submissionFormCloudmile.gcp.b2b_lite_commitment_period as string
                            ) /
                              12 <
                              5 || !submissionFormCloudmile.gcp.b2b_lite_commitment_period
                          }
                          isError={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_5yr
                              ?.is_error
                          }
                          error={
                            submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount_5yr
                              ?.error
                          }
                        />
                      </Box>
                    </Box>
                    {submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount ? (
                      <InputErrorMessage
                        customHeight={24}
                        isError={
                          submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount
                            .is_error
                        }
                        errorMessage={
                          submissionFormValidation.b2b_lite_customer_to_cm_commitment_amount.error
                        }
                      />
                    ) : null}
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </>
      ) : null}
      <Spacer axis="vertical" css={{ $$size: '30px' }} />
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* This is an urgent request */}
        <Box css={{ mr: '10px' }}>
          <Checkbox
            id="urgent-checkbox"
            checked={submissionFormCloudmile.gcp.urgent_level as boolean}
            onChange={() => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gcp: { ...prev.gcp, urgent_level: !submissionFormCloudmile.gcp.urgent_level },
              }));
            }}
          />
        </Box>
        <Label htmlFor="urgent-checkbox">
          <Typography>{`This is an urgent request`}</Typography>
        </Label>
      </Box>
      {/* The local GM will review and approve the request first. */}
      <SubmissionUrgentBox urgentLevel={submissionFormCloudmile.gcp.urgent_level} />
    </Box>
  );
};

export default GcpCloudmileServiceDocument;
