import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { nanoid } from 'nanoid';
import { useAtom } from 'jotai';
import { isHomeSummaryHideAtom } from '../store/atoms';
import {
  useGetApprovalProgressSummary,
  useGetAssignedToMeTotal,
  useGetAssignedToMeListInfinite,
  useGetAppliedByMeTotal,
  useGetAppliedByMeListInfinite,
  useGetMentionedCommentTotal,
  useGetMentionedCommentListInfinite,
  useGetMentionedFeedbackTotal,
  useGetMentionedFeedbackListInfinite,
} from '../services/home/home.api';
import { fadeInOutMotion } from '../styles/motions';
import { getApplicationsEmptyWording, getFirstWordUpperCase } from '../utils/get-wording';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import Box from '../components/box';
import Button from '../components/button/button';
import Spacer from '../components/surface/spacer';
import { Heading3, Heading4, Heading6 } from '../components/typography/heading';
import { Text, ButtonText, Caption } from '../components/typography/text';
import { CenteredBoxAbsolute, CenteredBoxFlex } from '../components/surface/centered-box';
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '../components/surface/scroll-area';
import MSymbol from '../components/icon/m-symbol';
import DocIcon from '../components/icon/doc-icon';
import {
  ApprovalProgressCardAside,
  ApprovalProgressCardContainer,
  ApprovalProgressCardContent,
  ApprovalProgressCardRoot,
} from '../components/card/approval-progress-card';
import TextInput from '../components/field/text-input';
import {
  SummaryTrackContainer,
  SummaryTrackContent,
  SummaryTrackHeader,
  SummaryTrackHeaderIcon,
  SummaryTrackRoot,
} from '../components/surface/summary-track';
import Skeleton from '../components/surface/skeleton';
import MentionedCommentCard from '../components/card/mentioned-comment-card';
import MentionedFeedbackCard from '../components/card/mentioned-feedback-card';
import AssignmentCard, { AssignmentCardContainer } from '../components/card/assignment-card';
import NoResultMonoBG from '../assets/images/bg-no-result-mono.svg';
import NoAttachesBG from '../assets/images/bg-no-attaches.svg';
import NoCommentBG from '../assets/images/bg-no-comment.svg';
import NoApprovalBG from '../assets/images/bg-no-approval.svg';
import ErrorMonoBG from '../assets/images/bg-error-mono.svg';
import ErrorBG from '../assets/images/bg-error.svg';
import AppliedByMeIcon from '../assets/images/icon-applied-by-me.svg';
import AssignedToMeIcon from '../assets/images/icon-assigned-to-me.svg';
import MentionedCommentIcon from '../assets/images/icon-mentioned-me.svg';
import MentionedFeedbackIcon from '../assets/images/icon-mentioned-feedback.svg';

const HomePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedSearchTerm] = useDebounce(searchParams.get('search'), 1000);
  const [isApprovalProgressSummaryHide, setIsApprovalProgressSummaryHide] =
    useAtom(isHomeSummaryHideAtom);

  const approvalProgressSummaryQuery = useGetApprovalProgressSummary();

  const { ref: appliedByMeRef, inView: appliedByMeRefInView } = useInView();
  const appliedByMeTotalQuery = useGetAppliedByMeTotal({ search: debouncedSearchTerm || '' });
  const appliedByMeListQuery = useGetAppliedByMeListInfinite({ search: debouncedSearchTerm || '' });

  const { ref: assignedToMeRef, inView: assignedToMeRefInView } = useInView();
  const assignedToMeTotalQuery = useGetAssignedToMeTotal({ search: debouncedSearchTerm || '' });
  const assignedToMeListQuery = useGetAssignedToMeListInfinite({
    search: debouncedSearchTerm || '',
  });

  const { ref: mentionedCommentRef, inView: mentionedCommentRefInView } = useInView();
  const mentionedCommentTotalQuery = useGetMentionedCommentTotal({
    search: debouncedSearchTerm || '',
  });
  const mentionedCommentListQuery = useGetMentionedCommentListInfinite({
    search: debouncedSearchTerm || '',
  });

  const { ref: mentionedFeedbackRef, inView: mentionedFeedbackRefInView } = useInView();
  const mentionedFeedbackTotalQuery = useGetMentionedFeedbackTotal({
    search: debouncedSearchTerm || '',
  });
  const mentionedFeedbackListQuery = useGetMentionedFeedbackListInfinite({
    search: debouncedSearchTerm || '',
  });

  const onSearch = (newValue: string) => {
    setSearchParams((params) => {
      params.set('search', newValue);
      return params;
    });
  };

  useEffect(() => {
    if (appliedByMeRefInView) {
      appliedByMeListQuery.fetchNextPage();
    }
    if (assignedToMeRefInView) {
      assignedToMeListQuery.fetchNextPage();
    }
    if (mentionedCommentRefInView) {
      mentionedCommentListQuery.fetchNextPage();
    }
    if (mentionedFeedbackRefInView) {
      mentionedFeedbackListQuery.fetchNextPage();
    }
  }, [
    appliedByMeRefInView,
    assignedToMeRefInView,
    mentionedCommentRefInView,
    mentionedFeedbackRefInView,
  ]);

  return (
    <Box css={{ px: 20, py: 15, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* Home Title */}
        <MSymbol iconName={`home`} fill={false} />
        <Spacer axis="horizontal" css={{ $$size: '10px' }} />
        <Heading4>Home</Heading4>
        {/* Hide and See Summary Button */}
        <Box css={{ ml: 'auto' }}>
          <Button
            variant="borderless"
            color="achromatic-primary"
            size="sm"
            onClick={() => setIsApprovalProgressSummaryHide(!isApprovalProgressSummaryHide)}
            startElement={
              isApprovalProgressSummaryHide ? (
                <MSymbol iconName={`web_asset`} size={16} css={{ color: 'inherit' }} />
              ) : (
                <MSymbol iconName={`web_asset_off`} size={16} css={{ color: 'inherit' }} />
              )
            }>
            <ButtonText size={'14'} bold>
              {`${isApprovalProgressSummaryHide ? 'Show' : 'Hide'} Summary`}
            </ButtonText>
          </Button>
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '25px' }} />
      {/* Summary */}
      <Box
        as={motion.div}
        css={{ overflow: 'hidden' }}
        initial={isApprovalProgressSummaryHide ? 'hide' : 'show'}
        variants={fadeInOutMotion}
        animate={isApprovalProgressSummaryHide ? 'hide' : 'show'}>
        <ButtonText size={16} bold css={{ mb: 10 }}>{`Application Summary`}</ButtonText>
        {approvalProgressSummaryQuery.isLoading ? (
          <ApprovalProgressCardContainer>
            {Array.from({ length: 4 }, (v, i) => i).map(() => (
              <Skeleton key={nanoid()} css={{ height: 70 }} />
            ))}
          </ApprovalProgressCardContainer>
        ) : approvalProgressSummaryQuery.isSuccess ? (
          approvalProgressSummaryQuery.data.length > 0 ? (
            <ApprovalProgressCardContainer>
              {approvalProgressSummaryQuery.data.map((summary) => (
                <ApprovalProgressCardRoot key={summary.id}>
                  {/* App Count and App Progress */}
                  <ApprovalProgressCardContent>
                    <Heading3>{summary.count || '-'}</Heading3>
                    <Text semibold>
                      {summary.status ? getFirstWordUpperCase(summary.status) : '-'}
                    </Text>
                  </ApprovalProgressCardContent>
                  <ApprovalProgressCardAside>
                    <DocIcon
                      docType={summary.icon_map?.doc_type || ''}
                      docIconBGColor={summary.icon_map?.doc_icon_bg || ''}
                      docIconStatus={summary.icon_map?.doc_icon_status || ''}
                    />
                  </ApprovalProgressCardAside>
                </ApprovalProgressCardRoot>
              ))}
            </ApprovalProgressCardContainer>
          ) : (
            <CenteredBoxFlex>
              <Box as="img" src={NoResultMonoBG} />
              <Text semibold>{`There has no summary.`}</Text>
            </CenteredBoxFlex>
          )
        ) : approvalProgressSummaryQuery.isError ? (
          <CenteredBoxFlex css={{ height: '100%', rowGap: 20 }}>
            <Box as="img" src={ErrorBG} />
            <Box>
              <Text
                semibold
                css={{
                  textAlign: 'center',
                }}>{`${'Something went wrong'}`}</Text>
              <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
              <Caption semibold>
                {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
              </Caption>
            </Box>
            <Button
              variant="solid"
              size={'sm'}
              isLoading={approvalProgressSummaryQuery.isLoading}
              onClick={() => {
                approvalProgressSummaryQuery.refetch();
              }}>
              <ButtonText size={12} bold>
                Refresh
              </ButtonText>
            </Button>
          </CenteredBoxFlex>
        ) : null}
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      {/* Tasks (Tracks) Title */}
      <ButtonText size={'16'} bold>
        Tasks
      </ButtonText>
      {/* Control Bar */}
      <Box css={{ py: 13, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Control Bar Left */}
        <Box css={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          {/* Search */}
          <Box css={{ width: 240 }}>
            <TextInput
              value={searchParams.get('search') || ''}
              onChange={(newValue) => onSearch((newValue || '') as string)}
              placeholder="Search"
              clearable={Boolean(searchParams.get('search') && searchParams.get('search') !== '')}
              endElement={
                <Box css={{ display: 'flex', alignItems: 'center', mr: 8 }}>
                  <MSymbol
                    iconName="search"
                    size={20}
                    weight={700}
                    css={{ color: '$achromatic30' }}
                  />
                </Box>
              }
            />
          </Box>
        </Box>
      </Box>
      {/* Tasks */}
      <SummaryTrackContainer>
        {/* Applied by me */}
        <SummaryTrackRoot>
          <SummaryTrackHeader>
            <ButtonText size={16} css={{ color: '$achromatic70' }}>
              Applied by me
            </ButtonText>
            {appliedByMeTotalQuery.isLoading ? (
              <Box css={{ pt: 6 }}>
                <Skeleton rounded css={{ width: 30, height: 30 }} />
              </Box>
            ) : appliedByMeTotalQuery.isSuccess ? (
              <Heading3>{appliedByMeTotalQuery.data?.application_total || '0'}</Heading3>
            ) : appliedByMeTotalQuery.isError ? (
              <Heading3>{'Err'}</Heading3>
            ) : null}
            <SummaryTrackHeaderIcon src={AppliedByMeIcon} />
          </SummaryTrackHeader>
          <SummaryTrackContent>
            <ScrollAreaViewport>
              {appliedByMeListQuery.isLoading ? (
                <Skeleton css={{ position: 'absolute', top: 0, bottom: 0, left: 10, right: 10 }} />
              ) : appliedByMeListQuery.isError ? (
                <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                  <CenteredBoxFlex>
                    <Box as="img" src={ErrorMonoBG} />
                    <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
                      <Heading6>{`Something's gone wrong`}</Heading6>
                      <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                      <Text semibold>
                        {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                      </Text>
                    </Box>
                    <Button
                      size="sm"
                      isLoading={appliedByMeListQuery.isLoading}
                      disabled={appliedByMeListQuery.isLoading}
                      onClick={() => {
                        appliedByMeListQuery.refetch();
                      }}>
                      <ButtonText size="14">Refresh</ButtonText>
                    </Button>
                  </CenteredBoxFlex>
                </CenteredBoxAbsolute>
              ) : (
                <>
                  {(appliedByMeListQuery.isSuccess &&
                    appliedByMeListQuery.data.pages?.[0]?.applications?.length) ??
                  0 > 0 ? (
                    <>
                      {appliedByMeListQuery.data?.pages.map((page, pageIndex) => (
                        <AssignmentCardContainer
                          key={pageIndex}
                          css={{
                            mt: pageIndex === 0 ? 0 : 10,
                          }}>
                          {page.applications?.map((appliedByMe) => {
                            return (
                              <AssignmentCard
                                key={appliedByMe.app_id}
                                appId={appliedByMe.app_id || ''}
                                assignmentType="appliedByMe"
                              />
                            );
                          })}
                        </AssignmentCardContainer>
                      ))}
                      <Box ref={appliedByMeRef} css={{ mt: 15 }}>
                        <Caption
                          css={{ display: 'block', textAlign: 'center', color: '$primary50' }}>
                          {appliedByMeListQuery.isFetchingNextPage
                            ? 'Loading more...'
                            : 'Nothing more to load'}
                        </Caption>
                      </Box>
                    </>
                  ) : (
                    <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                      <CenteredBoxFlex>
                        <Box
                          as="img"
                          src={searchParams.get('search') ? NoResultMonoBG : NoAttachesBG}
                        />
                        <Text semibold css={{ textAlign: 'center' }}>
                          {searchParams.get('search')
                            ? `No Matching Results`
                            : getApplicationsEmptyWording('applied_by_me')}
                        </Text>
                      </CenteredBoxFlex>
                    </CenteredBoxAbsolute>
                  )}
                </>
              )}
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </SummaryTrackContent>
        </SummaryTrackRoot>
        {/* Assigned to me */}
        <SummaryTrackRoot>
          <SummaryTrackHeader>
            <ButtonText size={16} css={{ color: '$achromatic70' }}>
              Assigned to me
            </ButtonText>
            {assignedToMeTotalQuery.isLoading ? (
              <Box css={{ pt: 6 }}>
                <Skeleton rounded css={{ width: 30, height: 30 }} />
              </Box>
            ) : assignedToMeTotalQuery.isSuccess ? (
              <Heading3>{assignedToMeTotalQuery.data?.application_total || '0'}</Heading3>
            ) : assignedToMeTotalQuery.isError ? (
              <Heading3>{'Err'}</Heading3>
            ) : null}
            <SummaryTrackHeaderIcon src={AssignedToMeIcon} />
          </SummaryTrackHeader>
          <SummaryTrackContent>
            <ScrollAreaViewport>
              {assignedToMeListQuery.isLoading ? (
                <Skeleton css={{ position: 'absolute', top: 0, bottom: 0, left: 10, right: 10 }} />
              ) : assignedToMeListQuery.isError ? (
                <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                  <CenteredBoxFlex>
                    <Box as="img" src={ErrorMonoBG} />
                    <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
                      <Heading6>{`Something's gone wrong`}</Heading6>
                      <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                      <Text semibold>
                        {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                      </Text>
                    </Box>
                    <Button
                      size="sm"
                      isLoading={assignedToMeListQuery.isLoading}
                      disabled={assignedToMeListQuery.isLoading}
                      onClick={() => {
                        assignedToMeListQuery.refetch();
                      }}>
                      <ButtonText size="14">Refresh</ButtonText>
                    </Button>
                  </CenteredBoxFlex>
                </CenteredBoxAbsolute>
              ) : (
                <>
                  {(assignedToMeListQuery.isSuccess &&
                    assignedToMeListQuery.data.pages?.[0].applications?.length) ??
                  0 > 0 ? (
                    <>
                      {assignedToMeListQuery.data?.pages.map((page, pageIndex) => (
                        <AssignmentCardContainer
                          key={pageIndex}
                          css={{
                            mt: pageIndex === 0 ? 0 : 10,
                          }}>
                          {page.applications?.map((assignedToMe) => {
                            return (
                              <AssignmentCard
                                key={assignedToMe.app_id}
                                appId={assignedToMe.app_id || ''}
                                assignmentType="assignedToMe"
                              />
                            );
                          })}
                        </AssignmentCardContainer>
                      ))}
                      <Box ref={assignedToMeRef} css={{ mt: 15 }}>
                        <Caption
                          css={{ display: 'block', textAlign: 'center', color: '$primary50' }}>
                          {assignedToMeListQuery.isFetchingNextPage
                            ? 'Loading more...'
                            : 'Nothing more to load'}
                        </Caption>
                      </Box>
                    </>
                  ) : (
                    <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                      <CenteredBoxFlex>
                        <Box
                          as="img"
                          src={searchParams.get('search') ? NoResultMonoBG : NoApprovalBG}
                        />
                        <Text semibold css={{ textAlign: 'center' }}>
                          {searchParams.get('search')
                            ? `No Matching Results`
                            : getApplicationsEmptyWording('assigned_to_me')}
                        </Text>
                      </CenteredBoxFlex>
                    </CenteredBoxAbsolute>
                  )}
                </>
              )}
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </SummaryTrackContent>
        </SummaryTrackRoot>
        {/* Mentioned comment */}
        <SummaryTrackRoot>
          <SummaryTrackHeader>
            <ButtonText size={16} css={{ color: '$achromatic70' }}>
              Mentioned comment
            </ButtonText>
            {mentionedCommentTotalQuery.isLoading ? (
              <Box css={{ pt: 6 }}>
                <Skeleton rounded css={{ width: 30, height: 30 }} />
              </Box>
            ) : mentionedCommentTotalQuery.isSuccess ? (
              <Heading3>
                {mentionedCommentTotalQuery.data?.home_mentioned_comment_total || '0'}
              </Heading3>
            ) : mentionedCommentTotalQuery.isError ? (
              <Heading3>{'Err'}</Heading3>
            ) : null}
            <SummaryTrackHeaderIcon src={MentionedCommentIcon} />
          </SummaryTrackHeader>
          <SummaryTrackContent>
            <ScrollAreaViewport>
              {mentionedCommentListQuery.isLoading ? (
                <Skeleton css={{ position: 'absolute', top: 0, bottom: 0, left: 10, right: 10 }} />
              ) : mentionedCommentListQuery.isError ? (
                <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                  <CenteredBoxFlex>
                    <Box as="img" src={ErrorMonoBG} />
                    <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
                      <Heading6>{`Something's gone wrong`}</Heading6>
                      <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                      <Text semibold>
                        {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                      </Text>
                    </Box>
                    <Button
                      size="sm"
                      isLoading={mentionedCommentListQuery.isLoading}
                      disabled={mentionedCommentListQuery.isLoading}
                      onClick={() => {
                        mentionedCommentListQuery.refetch();
                      }}>
                      <ButtonText size="14">Refresh</ButtonText>
                    </Button>
                  </CenteredBoxFlex>
                </CenteredBoxAbsolute>
              ) : (
                <>
                  {(mentionedCommentListQuery.isSuccess &&
                    mentionedCommentListQuery.data.pages?.[0].mentioned_comments?.length) ??
                  0 > 0 ? (
                    <>
                      {mentionedCommentListQuery.data?.pages.map((page, pageIndex) => (
                        <AssignmentCardContainer
                          key={pageIndex}
                          css={{
                            mt: pageIndex === 0 ? 0 : 10,
                          }}>
                          {page.mentioned_comments?.map((mentionedComment) => {
                            return (
                              <MentionedCommentCard
                                key={mentionedComment.mentioned_comment_id}
                                commentId={mentionedComment.mentioned_comment_id || ''}
                              />
                            );
                          })}
                        </AssignmentCardContainer>
                      ))}
                      <Box ref={mentionedCommentRef} css={{ mt: 15 }}>
                        <Caption
                          css={{ display: 'block', textAlign: 'center', color: '$primary50' }}>
                          {mentionedCommentListQuery.isFetchingNextPage
                            ? 'Loading more...'
                            : 'Nothing more to load'}
                        </Caption>
                      </Box>
                    </>
                  ) : (
                    <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                      <CenteredBoxFlex css={{ alignItems: 'flex-start' }}>
                        <Box
                          as="img"
                          src={searchParams.get('search') ? NoResultMonoBG : NoCommentBG}
                        />
                        <Text semibold css={{ textAlign: 'center' }}>
                          {searchParams.get('search')
                            ? `No Matching Results`
                            : `There are no comments that mentioned you`}
                        </Text>
                      </CenteredBoxFlex>
                    </CenteredBoxAbsolute>
                  )}
                </>
              )}
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </SummaryTrackContent>
        </SummaryTrackRoot>
        {/* Mentioned feedback */}
        <SummaryTrackRoot>
          <SummaryTrackHeader>
            <ButtonText size={16} css={{ color: '$achromatic70' }}>
              Mentioned feedback
            </ButtonText>
            {mentionedFeedbackTotalQuery.isLoading ? (
              <Box css={{ pt: 6 }}>
                <Skeleton rounded css={{ width: 30, height: 30 }} />
              </Box>
            ) : mentionedFeedbackTotalQuery.isSuccess ? (
              <Heading3>
                {mentionedFeedbackTotalQuery.data?.home_mentioned_feedback_total || '0'}
              </Heading3>
            ) : mentionedFeedbackTotalQuery.isError ? (
              <Heading3>{'Err'}</Heading3>
            ) : null}
            <SummaryTrackHeaderIcon src={MentionedFeedbackIcon} />
          </SummaryTrackHeader>
          <SummaryTrackContent>
            <ScrollAreaViewport>
              {mentionedFeedbackListQuery.isLoading ? (
                <Skeleton css={{ position: 'absolute', top: 0, bottom: 0, left: 10, right: 10 }} />
              ) : mentionedFeedbackListQuery.isError ? (
                <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                  <CenteredBoxFlex>
                    <Box as="img" src={ErrorMonoBG} />
                    <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center', mb: 6 }}>
                      <Heading6>{`Something's gone wrong`}</Heading6>
                      <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
                      <Text semibold>
                        {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
                      </Text>
                    </Box>
                    <Button
                      size="sm"
                      isLoading={mentionedFeedbackListQuery.isLoading}
                      disabled={mentionedFeedbackListQuery.isLoading}
                      onClick={() => {
                        mentionedFeedbackListQuery.refetch();
                      }}>
                      <ButtonText size="14">Refresh</ButtonText>
                    </Button>
                  </CenteredBoxFlex>
                </CenteredBoxAbsolute>
              ) : (
                <>
                  {(mentionedFeedbackListQuery.isSuccess &&
                    mentionedFeedbackListQuery.data.pages?.[0].mentioned_feedbacks?.length) ??
                  0 > 0 ? (
                    <>
                      {mentionedFeedbackListQuery.data?.pages.map((page, pageIndex) => (
                        <AssignmentCardContainer
                          key={pageIndex}
                          css={{
                            mt: pageIndex === 0 ? 0 : 10,
                          }}>
                          {page.mentioned_feedbacks?.map((mentionedFeedback) => {
                            return (
                              <MentionedFeedbackCard
                                key={mentionedFeedback.mentioned_feedback_id}
                                feedbackId={mentionedFeedback.mentioned_feedback_id || ''}
                              />
                            );
                          })}
                        </AssignmentCardContainer>
                      ))}
                      <Box ref={mentionedFeedbackRef} css={{ mt: 15 }}>
                        <Caption
                          css={{ display: 'block', textAlign: 'center', color: '$primary50' }}>
                          {mentionedFeedbackListQuery.isFetchingNextPage
                            ? 'Loading more...'
                            : 'Nothing more to load'}
                        </Caption>
                      </Box>
                    </>
                  ) : (
                    <CenteredBoxAbsolute css={{ top: 80, transform: 'translate(-50%, 0)' }}>
                      <CenteredBoxFlex css={{ alignItems: 'flex-start' }}>
                        <Box
                          as="img"
                          src={searchParams.get('search') ? NoResultMonoBG : NoCommentBG}
                        />
                        <Text semibold css={{ textAlign: 'center' }}>
                          {searchParams.get('search')
                            ? `No Matching Results`
                            : `There are no feedbacks that mentioned you`}
                        </Text>
                      </CenteredBoxFlex>
                    </CenteredBoxAbsolute>
                  )}
                </>
              )}
              <ScrollAreaScrollbar orientation="vertical" css={{ background: '$white' }}>
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollAreaViewport>
          </SummaryTrackContent>
        </SummaryTrackRoot>
      </SummaryTrackContainer>
    </Box>
  );
};

export default HomePage;
