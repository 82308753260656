import { forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import Box from '../box';
import DocPDFIcon from '../../assets/images/icon-doc-pdf.svg';
import DocAllApprovedIcon from '../../assets/images/icon-doc-all-approved.svg';
import DocRejectedIcon from '../../assets/images/icon-doc-rejected.svg';
import DocAppActiveIcon from '../../assets/images/icon-doc-app-active.svg';
import DocAppDefaultIcon from '../../assets/images/icon-doc-app-default.svg';
import DocAppDisabledIcon from '../../assets/images/icon-doc-app-disabled.svg';
import DocAppDraftIcon from '../../assets/images/icon-doc-app-draft.svg';
import DocQuoActiveIcon from '../../assets/images/icon-doc-quo-active.svg';
import DocQuoDefaultIcon from '../../assets/images/icon-doc-quo-default.svg';
import DocQuoDisabledIcon from '../../assets/images/icon-doc-quo-disabled.svg';
import DocSealActiveIcon from '../../assets/images/icon-doc-seal-active.svg';
import DocSealDefaultIcon from '../../assets/images/icon-doc-seal-default.svg';
import DocSealDisabledIcon from '../../assets/images/icon-doc-seal-disabled.svg';
import DocSealDraftIcon from '../../assets/images/icon-doc-seal-draft.svg';
import DocPDFActiveIcon from '../../assets/images/icon-doc-pdf-active.svg';
import DocPDFDefaultIcon from '../../assets/images/icon-doc-pdf-default.svg';
import DocStatusApprovedIcon from '../../assets/images/icon-doc-status-approved.svg';
import DocStatusAttentionIcon from '../../assets/images/icon-doc-status-attention.svg';
import DocStatusCompletedIcon from '../../assets/images/icon-doc-status-completed.svg';
import DocStatusRejectedIcon from '../../assets/images/icon-doc-status-rejected.svg';
import DocStatusSignedIcon from '../../assets/images/icon-doc-status-signed.svg';

interface DocIconProps {
  docType: string;
  docIconBGColor: string;
  docIconStatus: string;
  statusIconSmall?: boolean;
}

const DocIconRoot = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const docIconBGColorMap: Record<string, Record<string, string>> = {
  contract: {
    active: DocAppActiveIcon,
    default: DocAppDefaultIcon,
    disabled: DocAppDisabledIcon,
    draft: DocAppDraftIcon,
  },
  quotation: {
    active: DocQuoActiveIcon,
    default: DocQuoDefaultIcon,
    disabled: DocQuoDisabledIcon,
  },
  seal: {
    active: DocSealActiveIcon,
    default: DocSealDefaultIcon,
    disabled: DocSealDisabledIcon,
    draft: DocSealDraftIcon,
  },
  pdf: {
    active: DocPDFActiveIcon,
    default: DocPDFDefaultIcon,
  },
};
const getDocIconBGImage = ({
  docType,
  docIconBGColor,
}: {
  docType: string;
  docIconBGColor: string;
}) => {
  switch (docType) {
    case 'contract':
      return docIconBGColorMap.contract[docIconBGColor];
    case 'quotation':
      return docIconBGColorMap.quotation[docIconBGColor];
    case 'pdf':
      return DocPDFIcon;
    case 'all_approved':
      return DocAllApprovedIcon;
    case 'rejected':
      return DocRejectedIcon;
    case 'seal':
      return docIconBGColorMap.seal[docIconBGColor];
    case 'red_pdf_sign':
      return docIconBGColorMap.pdf[docIconBGColor];
    default:
      return '';
  }
};
const getDocIconStatus = (docIconStatus: string) => {
  switch (docIconStatus) {
    case 'rejected':
      return DocStatusRejectedIcon;
    case 'failed':
      return DocStatusAttentionIcon;
    case 'final':
      return DocStatusApprovedIcon;
    case 'sealing':
      return DocStatusSignedIcon;
    case 'archived':
      return DocStatusCompletedIcon;
    default:
      return '';
  }
};

const StatusIcon = styled('img', {
  position: 'absolute',
  bottom: 3,
  right: 3,
  variants: {
    small: {
      true: {
        width: 12,
        height: 12,
      },
    },
  },
  defaultVariants: {
    small: false,
  },
});

const DocIcon = forwardRef<HTMLDivElement, DocIconProps>(
  ({ docType, docIconBGColor, docIconStatus, statusIconSmall, ...restProps }, ref) => {
    return (
      <DocIconRoot ref={ref} {...restProps}>
        <Box as="img" src={getDocIconBGImage({ docType, docIconBGColor })} />
        {getDocIconStatus(docIconStatus) ? (
          <StatusIcon small={statusIconSmall} src={getDocIconStatus(docIconStatus)} />
        ) : null}
      </DocIconRoot>
    );
  }
);

DocIcon.displayName = 'DocIcon';

export default DocIcon;
