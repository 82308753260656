import { useQuery } from 'react-query';
import { useAxios } from '../../hooks';

export const useGetSampleImage = (sampleId: string) => {
  const axios = useAxios();
  return useQuery<{ url: string }, Error>(
    ['sample-image', `${sampleId}`],
    async () => {
      const { data } = await axios.get(`/v1/sample/${sampleId}`);
      return data;
    },
    {
      enabled: !!sampleId,
    }
  );
};
