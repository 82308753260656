import { useEffect, useState } from 'react';
import { PutSettingsPersonalMyProfilePayload } from '../../../types/settings';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetSettingsPersonalMyProfile,
  usePutSettingsPersonalMyProfile,
} from '../../../services/settings.api';
import { motion } from 'framer-motion';
import { validationRegex } from '../../../store/constants';
import Box from '../../../components/box';
import Typography from '../../../components/typography/deprecated-typography';
import Spacer from '../../../components/surface/spacer';
import Spinner from '../../../components/helper/spinner';
import Button from '../../../components/button/deprecated-button';
import Divider from '../../../components/surface/divider';
import IconButton from '../../../components/button/icon-button';
import MSymbol from '../../../components/icon/m-symbol';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../../components/helper/tool-tip';
import Avatar from '../../../components/surface/avatar';
import FormControl from '../../../components/field/deprecated-form-control';
import Field from '../../../components/field/deprecated-field';
import ErrorBG from '../../../assets/images/bg-error.svg';
import PopupAttentionIcon from '../../../assets/images/icon-popup-attention.svg';
import GoogleLogo from '../../../assets/images/logo-google.svg';

const MyProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const myProfileQueries = useGetSettingsPersonalMyProfile();
  const { control, handleSubmit, getValues, setValue, clearErrors, getFieldState, reset } = useForm(
    {
      defaultValues: { contact_email: '' },
    }
  );
  const myProfileMutate: any = usePutSettingsPersonalMyProfile(() => {
    reset({ contact_email: myProfileQueries.data?.contact_email || '' });
    setIsEditing(false);
  });
  const handleOnEdit = ({ contact_email }: PutSettingsPersonalMyProfilePayload) => {
    if (!getFieldState('contact_email').invalid) {
      myProfileMutate.mutate({ contact_email });
    }
  };
  useEffect(() => {
    if (myProfileQueries.isSuccess) {
      reset({ contact_email: myProfileQueries.data.contact_email });
    }
  }, [myProfileQueries.data, myProfileQueries.isSuccess, reset]);
  return (
    <>
      <Box css={{ py: 5 }}>
        <Typography variant={'h4'}>{`My Profile`}</Typography>
        <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
        <Typography variant={'body'} css={{ color: '$secondary70' }}>
          {`Manage your personal information`}
        </Typography>
      </Box>
      <Spacer axis={'vertical'} css={{ $$size: '15px' }} />
      {myProfileQueries.isLoading ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Spinner size={'large'} />
        </Box>
      ) : null}
      {myProfileQueries.isError ? (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Typography variant="h6">{`Something's gone wrong`}</Typography>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Typography variant="body-medium">
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Typography>
          </Box>
          <Button
            variant="solid"
            size={'small'}
            onClick={() => myProfileQueries.refetch()}>{`Refresh`}</Button>
        </Box>
      ) : null}
      {myProfileQueries.isSuccess ? (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
          }}>
          <Box
            css={{
              width: '100%',
              maxWidth: 664,
              borderRadius: '$10',
              py: 10,
              px: 20,
              backgroundColor: '$white',
              borderStyle: 'solid',
              borderColor: '$secondary20',
              borderWidth: '1px',
            }}>
            {/* User Picture & Warning */}
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                py: 10,
                gap: '$5',
                flexWrap: 'wrap',
              }}>
              {/* Picture */}
              <Avatar
                name={myProfileQueries.data.name || '-'}
                picture={myProfileQueries.data.picture || ''}
                size="lg"
              />
              {/* Warning */}
              <Box
                css={{
                  p: 10,
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 10,
                  flexBasis: 504,
                  flexShrink: 1,
                  flexGrow: 1,
                  backgroundColor: '$warning10',
                  borderRadius: '$5',
                  height: 80,
                }}>
                <Box as={'img'} src={PopupAttentionIcon} css={{ width: 18, height: 18 }} />
                <Box>
                  <Typography
                    as={'p'}
                    variant={'body-bold'}
                    css={{ color: '$warning90' }}>{`Your account is managed`}</Typography>
                  <Typography
                    as={'p'}
                    variant={'button-12'}
                    css={{
                      color: '$warning90',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      '-webkit-line-clamp': 2,
                      '-webkit-box-orient': 'vertical',
                    }}>{`You can't deactivate or delete your account because it's owned and managed by an organization. Contact your organization's admin for assistance.`}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Name */}
            <Box
              as={'dl'}
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120, flexShrink: 0 }}>
                <Typography variant={'body-bold'}>{`Name`}</Typography>
              </Box>
              <Box
                as={'dd'}
                css={{
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography variant={'body-medium'} css={{ flexGrow: 1 }}>{`${
                  myProfileQueries.data.name || '-'
                }`}</Typography>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Email (ID) */}
            <Box
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120 }}>
                <Typography variant={'body-bold'}>{`Email (ID)`}</Typography>
              </Box>
              <Box
                as={'dd'}
                css={{
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  rowGap: '$1',
                }}>
                <Typography
                  variant={'body-medium'}
                  css={{ color: '$secondary70', flexGrow: 1 }}>{`${
                  myProfileQueries.data.email || '-'
                }`}</Typography>
                <Box css={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
                  <Box as={'img'} src={GoogleLogo} />
                  <Typography
                    variant={'body'}
                    css={{ color: '$secondary70' }}>{`Log in with Google enabled`}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider
              axis={'horizontal'}
              css={{ backgroundColor: '$secondary20', width: '100%', my: 8 }}
            />
            {/* Contact Email */}
            <Box
              css={{
                display: 'flex',
                columnGap: 20,
                alignItems: 'center',
                minHeight: 50,
                flexWrap: 'wrap',
              }}>
              <Box as={'dt'} css={{ flexBasis: 120 }}>
                <Typography variant={'body-bold'}>{`Contact Email`}</Typography>
                <Typography variant={'caption'} css={{ color: '$error60' }}>{` *`}</Typography>
              </Box>
              <Box
                as={motion.dd}
                css={{
                  position: 'relative',
                  flexGrow: 1,
                  flexBasis: 368,
                  flexShrink: 1,
                  display: 'flex',
                  alignItems: isEditing ? 'flex-start' : 'center',
                }}>
                {isEditing ? (
                  <>
                    {/* Field */}
                    <FormControl width="100%" onSubmit={handleSubmit((data) => handleOnEdit(data))}>
                      <Controller
                        name={`contact_email`}
                        control={control}
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: validationRegex.email,
                            message: 'Incorrect email format',
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <Field
                            value={field.value}
                            onChange={(value: string) => field.onChange(value)}
                            placeholder="Enter a contact email"
                            isError={fieldState.invalid}
                            error={fieldState.error?.message}
                            endElement={
                              <>
                                {getValues('contact_email') && getValues('contact_email') !== '' ? (
                                  <Box
                                    onClick={() => {
                                      setValue('contact_email', '');
                                      clearErrors('contact_email');
                                    }}
                                    css={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: '$white',
                                      width: '16px',
                                      height: '16px',
                                      borderStyle: 'none',
                                      cursor: 'pointer',
                                      flexShrink: 0,
                                      transition: 'background-color 0.15s linear',
                                      backgroundColor: '$secondary20',
                                      borderRadius: '$rounded',
                                      mr: '$2',
                                      '&:hover': { backgroundColor: '$secondary10' },
                                      '&:active': { backgroundColor: '$secondary30' },
                                    }}>
                                    <MSymbol
                                      iconName="close"
                                      weight={700}
                                      css={{ fontSize: '12px !important', color: 'inherit' }}
                                    />
                                  </Box>
                                ) : null}
                              </>
                            }
                          />
                        )}
                      />
                    </FormControl>
                    <Spacer axis={'horizontal'} css={{ $$size: '16px' }} />
                    {/* Cancel Edit */}
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          iconName="close"
                          shape="squared"
                          variant="outlined"
                          onClick={() => {
                            setValue('contact_email', myProfileQueries.data.contact_email);
                            clearErrors('contact_email');
                            setIsEditing(false);
                          }}
                          css={{ width: 28, height: 28, fontSize: 20, flexShrink: 0 }}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Cancel`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                    <Spacer axis={'horizontal'} css={{ $$size: '10px' }} />
                    {/* Submit Edit */}
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          color={'primary'}
                          iconName="check"
                          shape="squared"
                          variant="outlined"
                          isLoading={myProfileMutate.isLoading}
                          onClick={handleSubmit((data) => handleOnEdit(data))}
                          css={{ width: 28, height: 28, fontSize: 20, flexShrink: 0 }}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Submit`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </>
                ) : (
                  <>
                    <Box css={{ flexGrow: 1 }}>
                      <Typography as={'p'} variant={'body-medium'}>{`${
                        myProfileQueries.data.contact_email || '-'
                      }`}</Typography>
                      <Typography
                        variant={'caption'}
                        css={{
                          color: '$warning90',
                        }}>{`We will send notification messages to this email address.`}</Typography>
                    </Box>
                    <TooltipRoot>
                      <TooltipTrigger asChild>
                        <IconButton
                          iconName="edit"
                          css={{ fontSize: '18px' }}
                          onClick={() => setIsEditing(true)}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`bottom`}
                          css={{
                            px: 16,
                            py: 4.5,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{ color: '$white' }}>{`Edit`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default MyProfile;
