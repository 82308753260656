import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AppDetailCommentPanelFilterType,
  CommonAPIResponse,
  PostApplicationCommentPayload,
} from '../../../types';
import {
  GetAppDetailApprovalPanelResponse,
  GetAppDetailCommentPanelResponse,
  GetAppDetailInfoPanelResponse,
  PostAppDetailApprovalPanelPayload,
} from '../../../types/application/verified/app-detail-panels';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { useSetAtom } from 'jotai';
import { isToastOpenAtom, toastContentAtom } from '../../../store/atoms';

export const useGetAppDetailInfoPanel = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailInfoPanelResponse, AxiosError>({
    queryKey: ['app', 'verified', options.appId, 'detail-panels', 'info-panel'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/sidebar_info`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};

export const useGetAppDetailApprovalPanel = (options: { appId: string }) => {
  const axios = useAxios();
  return useQuery<GetAppDetailApprovalPanelResponse, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'verified', options.appId, 'detail-panels', 'approval-panel'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/${options.appId}/sidebar_approval`, {
        params: {
          application_id: options.appId,
        },
      });
      return data;
    },
    enabled: !!options.appId,
  });
};

export const usePostAppDetailApprovalPanel = (options: {
  appId: string;
  onSettled?: () => void;
}) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<any, AxiosError<CommonAPIResponse>, PostAppDetailApprovalPanelPayload>({
    mutationKey: ['app', 'verified', options.appId, 'detail-panels', 'approval-panel'],
    mutationFn: async (requestBody: PostAppDetailApprovalPanelPayload) => {
      const { data } = await axios.post<any>(
        `/v1/verified_app/${options.appId}/sidebar_approval`,
        requestBody
      );
      return data;
    },
    onSuccess: (res) => {
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['app', 'verified', options.appId]);
      if (options.onSettled) options.onSettled();
      setIsToastOpen(true);
    },
  });
};

const COMMENT_PAGE_LIMIT = 10;
export const useGetAppDetailCommentPanel = (options: {
  appId: string;
  offset?: number;
  limit?: number;
  filterBy?: AppDetailCommentPanelFilterType;
  firstId?: string;
}) => {
  const axios = useAxios();
  return useInfiniteQuery<GetAppDetailCommentPanelResponse, AxiosError<CommonAPIResponse>>(
    ['app', 'verified', options.appId, 'comments', options.offset, options.limit, options.filterBy],
    async ({ pageParam = { offset: 0, limit: COMMENT_PAGE_LIMIT } }) => {
      const { data } = await axios.get(`/v1/application/${options.appId}/comment`, {
        params: { ...pageParam, filter_by: options.filterBy },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage?.next
          ? {
              offset: lastPage.next.next_offset,
              limit: COMMENT_PAGE_LIMIT,
              first_id: lastPage.next.first_id,
            }
          : undefined,
    }
  );
};

export const usePostAppComment = (options: { appId: string; onSuccess?: () => void }) => {
  const axios = useAxios();
  const setIsToastOpen = useSetAtom(isToastOpenAtom);
  const setToastContent = useSetAtom(toastContentAtom);
  return useMutation<any, AxiosError<CommonAPIResponse>, PostApplicationCommentPayload>({
    mutationKey: ['app', 'verified', options.appId, 'comment'],
    mutationFn: async (requestBody: PostApplicationCommentPayload) => {
      const { data } = await axios.post(
        `/v1/verified_app/${options.appId}/comment`,
        { ...requestBody },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    },
    onSuccess: (res) => {
      if (options.onSuccess) options.onSuccess();
      setToastContent({
        isSuccess: true,
        success: `${res.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
    onError: (err) => {
      setToastContent({
        isError: true,
        error: `${err.response?.data.message ?? '-'}`,
      });
      setIsToastOpen(true);
    },
  });
};
