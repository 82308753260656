import { useMemo } from 'react';
import { styled } from '../../styles/stitches.config';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { DropdownOption } from '../../types';
import Box from '../box';
import Label from '../typography/label';
import Typography from '../typography/deprecated-typography';
import { ButtonText, Caption } from '../typography/text';
import { motion } from 'framer-motion';
import { fadeInOutMotion } from '../../styles/motions';

const RadioGroupRoot = styled(RadixRadioGroup.Root, {
  display: 'flex',
  columnGap: 20,
});

const RadioGroupItem = styled(RadixRadioGroup.Item, {
  all: 'unset',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  borderRadius: '$rounded',
  borderWidth: 1,
  borderStyle: 'solid',
  mr: 10,
  cursor: 'pointer',
  transition: '$colors',
  '&[data-state="checked"]': {
    borderColor: '$primary50',
  },
  '&[data-state="checked"]:hover': {
    borderColor: '$primary40',
  },
  '&[data-state="checked"]:active': {
    borderColor: '$primary60',
  },
  '&[data-state="unchecked"]': {
    borderColor: '$primary50',
  },
  '&[data-state="unchecked"]:hover': {
    borderColor: '$primary40',
  },
  '&[data-state="unchecked"]:active': {
    borderColor: '$primary60',
  },
});

const RadioGroupIndicator = styled(RadixRadioGroup.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&:after': {
    content: '""',
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '$rounded',
    transition: '$colors',
  },
  '&[data-state="checked"]:after': {
    backgroundColor: '$primary50',
  },
  '&[data-state="checked"]:hover:after': {
    backgroundColor: '$primary40',
  },
  '&[data-state="checked"]:active:after': {
    backgroundColor: '$primary60',
  },
  '&[data-state="unchecked"]:after': {
    backgroundColor: '$primary50',
  },
});

const RadioGroup = ({
  label,
  required,
  value,
  onChange,
  options,
  error,
  isError,
}: {
  label: string;
  required?: boolean;
  options: DropdownOption[];
  value: string | undefined;
  onChange: (newValue: string) => void;
  error?: string;
  isError?: boolean;
}) => {
  const stringifyValue = useMemo(() => {
    return value?.toString();
  }, [value]);
  const stringifyOptions = useMemo(() => {
    return options.map((option) => ({ ...option, value: option.value.toString() }));
  }, [options]);
  return (
    <Box>
      {label ? (
        <Label>
          <Caption>
            {`${label}`}
            {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
          </Caption>
        </Label>
      ) : null}
      <RadioGroupRoot value={stringifyValue} onValueChange={onChange}>
        {stringifyOptions.length > 0 ? (
          stringifyOptions.map((option) => (
            <Box key={option.id} css={{ display: 'flex', alignItems: 'center', px: 8 }}>
              <RadioGroupItem id={option.id} value={option.value}>
                <RadioGroupIndicator />
              </RadioGroupItem>
              <Label htmlFor={option.id}>
                <Typography variant="body">{option.name}</Typography>
              </Label>
            </Box>
          ))
        ) : (
          <ButtonText size={14} css={{ color: '$text-hint' }}>
            No option
          </ButtonText>
        )}
      </RadioGroupRoot>
      <Box
        as={motion.div}
        initial={'hide'}
        variants={fadeInOutMotion}
        animate={isError ? 'show' : 'hide'}>
        <Caption css={{ color: '$error60' }}>{error}</Caption>
      </Box>
    </Box>
  );
};

export default RadioGroup;
