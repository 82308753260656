import {
  ReactNode,
  forwardRef,
  startTransition,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { motion } from 'framer-motion';
import { DropdownOption, LabelColor } from '../../types';
import { styled } from '../../styles/stitches.config';
import * as Ariakit from '@ariakit/react';
import { Text, Caption, ButtonText } from '../typography/text';
import Box from '../box';
import Label from '../typography/label';
import Spinner from '../helper/spinner';
import Avatar from '../surface/avatar';
import MSymbol from '../icon/m-symbol';
import ColorfulChip from '../chip/colorful-chip';
import { fadeInOutMotion } from '../../styles/motions';

const CombofieldTrigger = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  width: '100%',
  borderRadius: 5,
  border: 'none',
  backgroundColor: '$white',
  color: '$text-primary',
  boxShadow: `0 0 0 1px var(--colors-func-border-main)`,
  transition: '$shadow',
  '&:hover': {
    boxShadow: `0 0 0 2px var(--colors-func-border-dark)`,
  },
  '&:has(input[aria-expanded=true])': {
    boxShadow: `0 0 0 2px var(--colors-primary60)`,
  },
  '&[data-error=true]': {
    color: '$error60',
    boxShadow: `0 0 0 1px var(--colors-error60)`,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: '$text-hint',
    boxShadow: `0 0 0 1px var(--colors-func-disabled-dark)`,
    backgroundColor: '$func-disabled-light',
  },
  variants: {
    size: {
      sm: {
        height: 30,
      },
      md: {
        height: 36,
      },
      lg: {
        height: 44,
      },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});
const StyledComboboxCancel = styled(Ariakit.ComboboxCancel, {
  borderRadius: '$rounded',
  width: 16,
  height: 16,
  border: 'none',
  p: 0,
  fontSize: 14,
  cursor: 'pointer',
  transition: '$colors',
  color: '$achromatic70',
  backgroundColor: '$transparent',
  '&:hover': {
    color: '$achromatic60',
    backgroundColor: '$achromatic3',
  },
  '&:active': {
    color: '$achromatic80',
    backgroundColor: '$achromatic10',
  },
});
const StyledCombobox = styled(Ariakit.Combobox, {
  all: 'unset',
  fontSize: 14,
  lineHeight: 1.75,
  fontWeight: 400,
  flex: 1,
  textIndent: 8,
  '&::placeholder': {
    color: '$text-hint',
  },
});
const StyledComboboxPopover = styled(Ariakit.ComboboxPopover, {
  outline: 'none',
  borderRadius: '$5',
  backgroundColor: '$white',
  boxShadow: '$basic',
  p: 10,
});
const StyledComboboxList = styled('div', {
  maxHeight: 280,
  overflow: 'auto',
});
const StyledComboboxItem = styled(Ariakit.ComboboxItem, {
  px: 10,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  color: '$text-primary',
  cursor: 'pointer',
  backgroundColor: '$white',
  transition: '$colors',
  borderRadius: 5,
  border: 'none',
  '&:hover': {
    backgroundColor: '$func-hover',
  },
  '&:active': {
    backgroundColor: '$func-active',
  },
  '&[data-state=active]': {
    backgroundColor: '$primary20',
  },
  '&:disabled': {
    color: '$func-disabled-dark',
    backgroundColor: '$white',
  },
  variants: {
    size: {
      sm: { minHeight: 40 },
      md: { minHeight: 50 },
      lg: { minHeight: 60 },
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface CombofieldProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  fieldName?: string;
  options: DropdownOption[];
  value: string | undefined;
  onChange: (newValue: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
  optionSize?: 'sm' | 'md' | 'lg';
  optionIconSize?: number;
  chipColor?: LabelColor;
  triggerStartElement?: ReactNode;
  triggerEndElement?: ReactNode;
}

const Combofield = forwardRef<HTMLDivElement, CombofieldProps>(
  (
    {
      label,
      required,
      placeholder,
      fieldName,
      value,
      onChange,
      options,
      isLoading,
      clearable = false,
      isError,
      error,
      disabled,
      optionSize = 'sm',
      optionIconSize,
      chipColor = 'sky',
      triggerStartElement,
      triggerEndElement,
      ...restProps
    },
    ref
  ) => {
    const combofieldTriggerRef = useRef<HTMLButtonElement | null>(null);
    const [combofieldTriggerWidth, setCombofieldTriggerWidth] = useState(0);
    const [otherOption, setOtherOption] = useState(false);
    const stringifyValue = useMemo(() => {
      return value?.toString();
    }, [value]);
    const filteredOptions = useMemo(() => {
      const includes = options
        .map((option) => ({ ...option, value: option.value.toString().toLowerCase() }))
        .filter((option) => option.value.includes(stringifyValue?.toLowerCase() || ''));
      if (includes.length <= 0) {
        setOtherOption(true);
      } else {
        setOtherOption(false);
      }
      return includes;
    }, [options, stringifyValue]);
    const onFieldChange = (newValue: string) => {
      startTransition(() => onChange(newValue));
    };
    useLayoutEffect(() => {
      if (combofieldTriggerRef.current) {
        setCombofieldTriggerWidth(combofieldTriggerRef.current.offsetWidth);
      }
    }, []);
    return (
      <Box ref={ref} {...restProps}>
        {label ? (
          <Label>
            <Caption>
              {`${label}`}
              {required ? <Caption css={{ color: '$error50' }}>{` *`}</Caption> : null}
            </Caption>
          </Label>
        ) : null}
        <Ariakit.ComboboxProvider
          defaultValue={stringifyValue}
          setValue={(newValue) => onFieldChange(newValue)}>
          <CombofieldTrigger
            ref={combofieldTriggerRef}
            name={fieldName}
            type="button"
            disabled={disabled}
            data-error={isError}>
            <StyledCombobox placeholder={placeholder} disabled={disabled} />
            {triggerStartElement ?? null}
            {clearable && <StyledComboboxCancel />}
            {triggerEndElement ?? null}
          </CombofieldTrigger>
          <StyledComboboxPopover
            gutter={8}
            css={{
              zIndex: 1,
              width: combofieldTriggerWidth || 'var(--popover-anchor-width)',
            }}>
            <StyledComboboxList>
              {isLoading ? (
                <Box css={{ p: 10 }}>
                  <Spinner />
                </Box>
              ) : (
                <>
                  {otherOption && (
                    <StyledComboboxItem value={stringifyValue}>
                      <Text
                        css={{
                          wordBreak: 'break-word',
                          textAlign: 'left',
                        }}>{`Other: ${stringifyValue}`}</Text>
                    </StyledComboboxItem>
                  )}
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                      <StyledComboboxItem
                        key={option.id}
                        value={option.value}
                        data-state={stringifyValue === option.value ? 'active' : 'inactive'}
                        size={optionSize}>
                        {option.picture ? (
                          <Avatar name={option.name || '-'} picture={option.picture} size="sm" />
                        ) : null}
                        <Box css={{ flex: 1 }}>
                          <Box css={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
                            {option.icon ? (
                              <MSymbol iconName={option.icon} size={optionIconSize || 24} />
                            ) : null}
                            <Text
                              css={{
                                wordBreak: 'break-word',
                                textAlign: 'left',
                              }}>{`${option.name}`}</Text>
                          </Box>
                          {option.desc ? (
                            <Caption
                              css={{
                                display: 'block',
                                color: '$text-secondary',
                                textAlign: 'left',
                                wordBreak: 'break-word',
                              }}>{`${option.desc}`}</Caption>
                          ) : null}
                        </Box>
                        {option.chip ? (
                          <ColorfulChip variant="solid" color={chipColor} text={option.chip} />
                        ) : null}
                      </StyledComboboxItem>
                    ))
                  ) : (
                    <ButtonText size={14} css={{ color: '$text-hint' }}>
                      No suggestion
                    </ButtonText>
                  )}
                </>
              )}
            </StyledComboboxList>
          </StyledComboboxPopover>
        </Ariakit.ComboboxProvider>
        <Box
          as={motion.div}
          initial={'hide'}
          variants={fadeInOutMotion}
          animate={isError ? 'show' : 'hide'}>
          <Caption css={{ color: '$error60' }}>{error}</Caption>
        </Box>
      </Box>
    );
  }
);

Combofield.displayName = 'Combofield';

export default Combofield;
