import { LabelColor } from '../../types';
import { useEffect, useState } from 'react';
import { useDeleteCustomerById, useGetCustomerById } from '../../services/customer.api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  TooltipArrow,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
  TooltipContent,
} from '../../components/helper/tool-tip';
import Box from '../../components/box';
import Typography from '../../components/typography/deprecated-typography';
import Spacer from '../../components/surface/spacer';
import Button from '../../components/button/deprecated-button';
import Divider from '../../components/surface/divider';
import ColorfulChip from '../../components/chip/colorful-chip';
import Spinner from '../../components/helper/spinner';
import ErrorBG from '../../assets/images/bg-error.svg';
import MSymbol from '../../components/icon/m-symbol';

const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customerId } = useParams();
  const customerByIdQueries = useGetCustomerById(customerId ?? '');
  const deleteCustomerMutation = useDeleteCustomerById({ onMutationSuccess: () => navigate('..') });
  // const editCustomerMutation = useGetCustomerById(customerId ?? '');
  const [isEditTooltipOpen, setIsEditTooltipOpen] = useState(false);
  const [isDeleteTooltipOpen, setIsDeleteTooltipOpen] = useState(false);

  useEffect(() => {
    if (!customerId) {
      navigate('..');
    }
  }, [customerId, navigate]);

  return (
    <Box
      css={{
        px: '20px',
        pt: '20px',
        pb: '50px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <Box css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h4">{`Customer Detail`}</Typography>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '30px' }} />
      {/* Paper */}
      {customerByIdQueries.isError ? (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
            rowGap: '$5',
          }}>
          <Box as="img" src={ErrorBG} />
          <Box css={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
            <Typography variant="h6">{`Something's gone wrong`}</Typography>
            <Spacer axis={'vertical'} css={{ $$size: '4px' }} />
            <Typography variant="body-medium">
              {`Try refreshing or navigating to another page. If you still see this message, our service might be disrupted.`}
            </Typography>
          </Box>
          <Button
            variant="solid"
            size={'small'}
            onClick={() => customerByIdQueries.refetch()}>{`Refresh`}</Button>
        </Box>
      ) : null}
      {customerByIdQueries.isLoading ? (
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          <Spinner size="large" />
        </Box>
      ) : null}
      {customerByIdQueries.isSuccess ? (
        <>
          {customerByIdQueries.data ? (
            <Box
              css={{
                m: '0 auto',
                width: '780px',
                px: '40px',
                py: '20px',
                backgroundColor: '$white',
              }}>
              {/* Company Name & Buttons */}
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h6"
                  css={{
                    alignSelf: 'flex-start',
                  }}>{`${customerByIdQueries.data.company_name}`}</Typography>
                {customerByIdQueries.data.source === 'manual' ? (
                  <Box css={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                    {/* Delete Button */}
                    <TooltipRoot
                      open={isDeleteTooltipOpen}
                      onOpenChange={(open) =>
                        setIsDeleteTooltipOpen(
                          !customerByIdQueries.data.action_map.is_delete_disabled ? false : open
                        )
                      }>
                      <TooltipTrigger asChild>
                        <Box
                          as="span"
                          tabIndex={0}
                          css={{
                            cursor: customerByIdQueries.data.action_map.is_delete_disabled
                              ? 'not-allowed'
                              : 'pointer',
                          }}>
                          <Button
                            variant="borderless"
                            color="error"
                            disabled={customerByIdQueries.data.action_map.is_delete_disabled}
                            startElement={
                              <MSymbol
                                iconName={`delete`}
                                css={{ color: 'inherit', fontSize: '16px !important' }}
                              />
                            }
                            isLoading={deleteCustomerMutation.isLoading}
                            onClick={() =>
                              deleteCustomerMutation.mutate(customerId ?? '')
                            }>{`Delete`}</Button>
                        </Box>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`top`}
                          css={{
                            px: 16,
                            py: 4.5,
                            maxWidth: 400,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{
                              color: '$white',
                            }}>{`You can’t remove the customer because they are still being used by some application(s).`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                    {/* Edit Button */}
                    <TooltipRoot
                      open={isEditTooltipOpen}
                      onOpenChange={(open) =>
                        setIsEditTooltipOpen(
                          !customerByIdQueries.data.action_map.is_edit_disabled ? false : open
                        )
                      }>
                      <TooltipTrigger asChild>
                        <Box
                          as="span"
                          tabIndex={0}
                          css={{
                            cursor: customerByIdQueries.data.action_map.is_edit_disabled
                              ? 'not-allowed'
                              : 'pointer',
                          }}>
                          <Button
                            variant="outlined"
                            disabled={customerByIdQueries.data.action_map.is_edit_disabled}
                            startElement={
                              <MSymbol
                                iconName={`edit`}
                                css={{ color: 'inherit', fontSize: '16px !important' }}
                              />
                            }
                            onClick={() =>
                              navigate(`../edit-company/${customerId}`)
                            }>{`Edit`}</Button>
                        </Box>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent
                          sideOffset={5}
                          side={`top`}
                          css={{
                            px: 16,
                            py: 4.5,
                            maxWidth: 400,
                          }}>
                          <Typography
                            variant={'body'}
                            css={{
                              color: '$white',
                            }}>{`The customer is being used by application(s) in 'processing' or 'approved' state. You cannot update it.`}</Typography>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </TooltipRoot>
                  </Box>
                ) : null}
              </Box>
              <Spacer axis="vertical" css={{ $$size: '20px' }} />
              {/* General Information */}
              <Typography
                variant="body-bold"
                css={{ color: '$secondary40' }}>{`General Information`}</Typography>
              <Divider
                axis="horizontal"
                css={{ $$size: '100%', backgroundColor: '$secondary20' }}
              />
              <Spacer axis="vertical" css={{ $$size: '20px' }} />
              <Box>
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Company Name */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Company Name`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                      customerByIdQueries.data.company_name || '-'
                    }`}</Typography>
                  </Box>
                  {/* Customer Abbreviation */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Customer Abbreviation`}</Typography>
                    <Typography
                      as="p"
                      variant="body"
                      css={{
                        mt: '$1',
                      }}>{`${customerByIdQueries.data.customer_abbreviation || '-'}`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', columnGap: '$5' }}>
                  {/* Category */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Category`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                      customerByIdQueries.data.category || '-'
                    }`}</Typography>
                  </Box>
                  {/* Tax Reg Number (統一編號) */}
                  <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                    <Typography
                      variant="caption-bold"
                      css={{ color: '$secondary40' }}>{`Tax Reg Number (統一編號)`}</Typography>
                    <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                      customerByIdQueries.data.tax_reg_number || '-'
                    }`}</Typography>
                  </Box>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Customer Country */}
                <Box>
                  <Typography
                    variant="caption-bold"
                    css={{ color: '$secondary40' }}>{`Country`}</Typography>
                  <Typography as="p" variant="body" css={{ mt: '$1' }}>
                    {customerByIdQueries.data.country_code || '-'}
                  </Typography>
                </Box>
                <Spacer axis="vertical" css={{ $$size: '20px' }} />
                {/* Address */}
                <Box>
                  <Typography
                    variant="caption-bold"
                    css={{ color: '$secondary40' }}>{`Address`}</Typography>
                  <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                    customerByIdQueries.data.address || '-'
                  }`}</Typography>
                </Box>
              </Box>
              {customerByIdQueries.data.source === 'oracle' ? (
                <>
                  <Spacer axis="vertical" css={{ $$size: '30px' }} />
                  {/* Payment Detail */}
                  <Typography
                    variant="body-bold"
                    css={{ color: '$secondary40' }}>{`Payment Detail`}</Typography>
                  <Divider
                    axis="horizontal"
                    css={{ $$size: '100%', backgroundColor: '$secondary20' }}
                  />
                  <Spacer axis="vertical" css={{ $$size: '20px' }} />
                  <Box>
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Subsidiary */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Subsidiary`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          customerByIdQueries.data.subsidiary || '-'
                        }`}</Typography>
                      </Box>
                      {/* Currency */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Currency`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          customerByIdQueries.data.currency || '-'
                        }`}</Typography>
                      </Box>
                    </Box>
                    <Spacer axis="vertical" css={{ $$size: '20px' }} />
                    <Box css={{ display: 'flex', columnGap: '$5' }}>
                      {/* Exchange Rate Bank */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Exchange Rate Bank`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          customerByIdQueries.data.exchange_rate_bank || '-'
                        }`}</Typography>
                      </Box>
                      {/* Payment Term */}
                      <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
                        <Typography
                          variant="caption-bold"
                          css={{ color: '$secondary40' }}>{`Payment Term`}</Typography>
                        <Typography as="p" variant="body" css={{ mt: '$1' }}>{`${
                          customerByIdQueries.data.payment_term || '-'
                        }`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : null}
              <Spacer axis="vertical" css={{ $$size: '30px' }} />
              {/* Labels */}
              {/* <Typography
                variant="body-medium"
                css={{ color: '$secondary40' }}>{`Labels`}</Typography>
              <Divider
                axis="horizontal"
                css={{ $$size: '100%', backgroundColor: '$secondary20' }}
              />
              <Spacer axis="vertical" css={{ $$size: '20px' }} />
              <Box css={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="dashed"
                  startElement={
                    <Box as="i" className="material-symbols-rounded">{`add`}</Box>
                  }>{`New Label`}</Button>
                <Spacer axis="horizontal" css={{ $$size: '20px' }} />
                <Box css={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                  {location.state.labels && location.state.labels.length > 0
                    ? location.state.labels.map(
                        (label: { id: string; color: LabelColor; label: string }) => (
                          <ColorfulChip
                            key={label.id}
                            text={`${label.label}`}
                            variant="solid"
                            color={label.color}
                            deletable={true}
                          />
                        )
                      )
                    : null}
                </Box>
              </Box> */}
            </Box>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default CustomerDetails;
