import { ApplicationsTabs } from '../types';

export const getNameInitials = (name: string) => {
  return name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
};

export const getFirstWordUpperCase = (words: string) => {
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export const getAttachmentErrorWording = (isFileSizeOver: boolean, isFileExtWrong: boolean) => {
  switch (true) {
    case isFileSizeOver && isFileExtWrong:
      return 'File format requires: .pdf, .png, .jpg and maximum file size 2MB';
    case isFileSizeOver:
      return 'Maximum file size 2MB';
    case isFileExtWrong:
      return 'File format requires: .pdf, .png, .jpg';
    default:
      return '';
  }
};

export const getApplicationsEmptyWording = (curTab: ApplicationsTabs) => {
  switch (curTab) {
    case 'all':
      return 'You have no applications yet';
    case 'applied_by_me':
      return 'There are no applications that you have applied for';
    case 'assigned_to_me':
      return 'There are no applications assigned to you';
    case 'drafts':
      return 'You have no draft applications yet';
    default:
      return `You currently have no ${curTab} applications.`;
  }
};

export const getApplicationSidebarApprovalConditionsBoxPaymentTermWording = ({
  paymentTerm30,
  paymentTerm45,
}: {
  paymentTerm30: boolean;
  paymentTerm45: boolean;
}) => {
  switch (true) {
    case paymentTerm30:
      return '30';
    case paymentTerm45:
      return '45';
    default:
      return '';
  }
};

export const getApplicationSidebarApprovalConditionsBoxPaymentTermDiscountRateWording = ({
  paymentTermDiscountRate5,
  paymentTermDiscountRate15,
}: {
  paymentTermDiscountRate5: boolean;
  paymentTermDiscountRate15: boolean;
}) => {
  switch (true) {
    case paymentTermDiscountRate5:
      return '5';
    case paymentTermDiscountRate15:
      return '15';
    default:
      return '';
  }
};

export const getBossApprovalDesc = (options: {
  isPaymentTerm: boolean;
  isPercentageDiscount: boolean;
}) => {
  switch (true) {
    case options.isPaymentTerm && options.isPercentageDiscount:
      return 'The document should include conditions for the approved discount rate and payment term.';
    case options.isPaymentTerm:
      return 'The document should include conditions for the approved payment term.';
    case options.isPercentageDiscount:
      return 'The document should include conditions for the approved discount rate.';
    default:
      return 'No need to upload the file.';
  }
};

export const getPUPPUnit = (options: { workspacePlan: string }) => {
  switch (true) {
    case options.workspacePlan.includes('pay_by_month'):
      return 'PUPM';
    case options.workspacePlan.includes('pay_by_year'):
      return 'PUPY';
    default:
      return '';
  }
};

export const getPUPPWording = (options: {
  pupp: number;
  workspacePlan: string;
  currency: string;
}) => {
  if (!options.workspacePlan || !options.currency) {
    return '-';
  }
  return `${options.pupp.toLocaleString()} ${getPUPPUnit({
    workspacePlan: options.workspacePlan,
  })} (${options.currency})`;
};

export const getGWSOfficialPricingDiscountWording = (options: {
  type: { name: string; value: string };
  value: number;
  currency: { name: string; value: string };
}) => {
  if (!options.type.value || !options.currency.value) {
    return '-';
  }
  if (options.type.value === 'Percentage discount') {
    return `${options.type.name} / ${options.value} % (${options.currency.name})`;
  }
  return `${options.type.name} / ${options.value} ${options.currency.name}`;
};
