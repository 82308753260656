import type { IdNameValueItem } from '../../../../../../types';
import { useState, useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  authUserAtom,
  submissionFormCloudmileAtom,
  submissionFormCustomerAtom,
  submissionFormSelectedProductAtom,
  submissionFormSelectedSubsidiaryIdAtom,
  submissionFormValidationAtom,
} from '../../../../../../store/atoms';
import {
  useGetCurrencyOracleOptions,
  useGetOfficialPricingDiscountCurrencyOracleOptions,
  useGetSubsidiaryOracleOptions,
} from '../../../../../../services/application/official-standard/oracle-options.api';
import { useGetCountryDialCodeAppOptions } from '../../../../../../services/application/official-standard/app-options.api';
import { useGetCMUsers } from '../../../../../../services/user.api';
import Box from '../../../../../../components/box';
import Select from '../../../../../../components/dropdown/select';
import AutoComplete from '../../../../../../components/dropdown/auto-complete';
import Spacer from '../../../../../../components/surface/spacer';
import ComboField from '../../../../../../components/dropdown/combo-field';
import { applicationApplyServiceItems } from '../../../../../../store/constants';

const GwsCloudmileGeneral = () => {
  const authUser = useAtomValue(authUserAtom);
  const submissionFormSelectedProduct = useAtomValue(submissionFormSelectedProductAtom);
  const submissionFormValidation = useAtomValue(submissionFormValidationAtom);
  const [submissionFormCloudmile, setSubmissionFormCloudmile] = useAtom(
    submissionFormCloudmileAtom
  );
  const [submissionFormCustomer, setSubmissionFormCustomer] = useAtom(submissionFormCustomerAtom);
  const setSubmissionFormSelectedSubsidiaryId = useSetAtom(submissionFormSelectedSubsidiaryIdAtom);

  const currencyOracleOptionsQueries = useGetCurrencyOracleOptions(
    'gws',
    submissionFormCloudmile.gws.cm_legal_entity,
    (res) => {
      if (!submissionFormCloudmile.gws.currency) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: { ...prev.gws, currency: res[0].value },
        }));
      }
    }
  );
  const officialPricingDiscountCurrencyOracleOptionsQueries =
    useGetOfficialPricingDiscountCurrencyOracleOptions(
      'gws',
      submissionFormCloudmile.gws.cm_legal_entity,
      submissionFormCloudmile.gws.exchange_rate,
      (res) => {
        if (!submissionFormCustomer.gws.official_pricing_discount_currency) {
          setSubmissionFormCustomer((prev) => ({
            ...prev,
            gws: { ...prev.gws, official_pricing_discount_currency: res[0].value },
          }));
        }
      }
    );
  const cmLegalEntityOracleOptionsQueries = useGetSubsidiaryOracleOptions('gws');
  const dialingCodeAppOptionsQueries = useGetCountryDialCodeAppOptions(true);

  const cmUsersQueries = useGetCMUsers();
  const [cmUserNames, setCMUserNames] = useState<IdNameValueItem[]>([]);
  const [cmUserEmails, setCMUserEmails] = useState<IdNameValueItem[]>([]);

  useEffect(() => {
    if (submissionFormCloudmile.gws.cm_legal_entity) {
      currencyOracleOptionsQueries.refetch();
      officialPricingDiscountCurrencyOracleOptionsQueries.refetch();
    }
  }, [submissionFormCloudmile.gws.cm_legal_entity]);
  useEffect(() => {
    if (submissionFormCloudmile.gws.exchange_rate) {
      officialPricingDiscountCurrencyOracleOptionsQueries.refetch();
    }
  }, [submissionFormCloudmile.gws.exchange_rate]);

  useEffect(() => {
    if (cmUsersQueries.isSuccess) {
      setCMUserNames(
        cmUsersQueries.data.map((option) => ({
          id: option.id,
          name: option.name,
          value: option.name,
          picture: option.picture ?? '',
        }))
      );
      setCMUserEmails(
        cmUsersQueries.data.map((option) => ({
          id: option.id,
          name: option.email ?? '',
          value: option.email ?? '',
        }))
      );
      if (authUser !== null) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: {
            ...prev.gws,
            cm_attention_to: authUser.name,
            cm_email: authUser.email,
          },
        }));
      }
    }
  }, [authUser, cmUsersQueries.data, cmUsersQueries.isSuccess, setSubmissionFormCloudmile]);

  useEffect(() => {
    if (submissionFormCloudmile.gws.cm_attention_to) {
      const selectedCMUser = cmUsersQueries.data?.find(
        (cmUser) => cmUser.name === submissionFormCloudmile.gws.cm_attention_to
      );
      if (selectedCMUser) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: {
            ...prev.gws,
            cm_email: selectedCMUser.email,
          },
        }));
      } else {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: {
            ...prev.gws,
            cm_email: '',
          },
        }));
      }
    }
  }, [
    submissionFormCloudmile.gws.cm_attention_to,
    cmUsersQueries.data,
    setSubmissionFormCloudmile,
  ]);

  useEffect(() => {
    if (submissionFormCloudmile.gws.cm_email) {
      const selectedCMUser = cmUsersQueries.data?.find(
        (cmUser) => cmUser.email === submissionFormCloudmile.gws.cm_email
      );
      if (selectedCMUser) {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: {
            ...prev.gws,
            cm_attention_to: selectedCMUser.name,
          },
        }));
      } else {
        setSubmissionFormCloudmile((prev) => ({
          ...prev,
          gws: {
            ...prev.gws,
            cm_attention_to: '',
          },
        }));
      }
    }
  }, [submissionFormCloudmile.gws.cm_email, cmUsersQueries.data, setSubmissionFormCloudmile]);

  return (
    <Box>
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* CM-Legal Entity */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="CM-Legal Entity"
            required={true}
            value={submissionFormCloudmile.gws.cm_legal_entity ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  cm_legal_entity: newValue,
                  currency: '',
                  exchange_rate: '',
                },
              }));
              setSubmissionFormCustomer((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  fixed_amount_discount: '',
                  official_pricing_discount_currency: '',
                },
              }));
              const selectedSubsidiaryId = cmLegalEntityOracleOptionsQueries.data
                ?.find((option) => option.name === newValue)
                ?.id.split('-')[2];
              setSubmissionFormSelectedSubsidiaryId(selectedSubsidiaryId ?? '');
            }}
            placeholder="Select a legal entity"
            options={cmLegalEntityOracleOptionsQueries.data}
            isLoading={cmLegalEntityOracleOptionsQueries.isLoading}
            isError={submissionFormValidation.cm_legal_entity?.is_error ?? false}
            error={submissionFormValidation.cm_legal_entity?.error ?? undefined}
          />
        </Box>
        {/* Product */}
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          <Select
            label="Product"
            required={true}
            value={submissionFormSelectedProduct ?? ''}
            disabled={true}
            options={applicationApplyServiceItems}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Attention To */}
          <AutoComplete
            label="Attention To"
            required={true}
            value={submissionFormCloudmile.gws.cm_attention_to ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  cm_attention_to: newValue,
                },
              }));
            }}
            placeholder="Enter a name"
            options={cmUserNames ?? []}
            isError={submissionFormValidation.cm_attention_to?.is_error ?? false}
            error={submissionFormValidation.cm_attention_to?.error ?? undefined}
          />
        </Box>
        <Box css={{ flexBasis: 'calc(50% - 10px)' }}>
          {/* Email */}
          <AutoComplete
            label="Email"
            required={true}
            value={submissionFormCloudmile.gws.cm_email ?? ''}
            onChange={(newValue) => {
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, cm_email: newValue },
              }));
            }}
            placeholder="Enter an email"
            options={cmUserEmails ?? []}
            isError={submissionFormValidation.cm_email?.is_error ?? false}
            error={submissionFormValidation.cm_email?.error ?? undefined}
          />
        </Box>
      </Box>
      <Spacer axis="vertical" css={{ $$size: '20px' }} />
      <Box css={{ display: 'flex', columnGap: '$5' }}>
        {/* Phone Number */}
        <Box css={{ flexBasis: 'calc(50% - 10px)', display: 'flex' }}>
          <ComboField
            mode="easy"
            label="Phone Number"
            selectPlaceholder="(TW) +886"
            fieldPlaceholder="Enter a phone number"
            selectValue={submissionFormCloudmile.gws.cm_country_calling_codes ?? ''}
            fieldValue={submissionFormCloudmile.gws.cm_phone_number ?? ''}
            isFieldError={submissionFormValidation.cm_phone_number?.is_error ?? undefined}
            fieldError={submissionFormValidation.cm_phone_number?.error ?? undefined}
            onSelectChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: { ...prev.gws, cm_country_calling_codes: newValue },
              }))
            }
            onFieldChange={(newValue) =>
              setSubmissionFormCloudmile((prev) => ({
                ...prev,
                gws: {
                  ...prev.gws,
                  cm_phone_number: newValue,
                },
              }))
            }
            selectOptions={dialingCodeAppOptionsQueries.data ?? []}
            isSelectError={submissionFormValidation.cm_country_calling_codes?.is_error ?? undefined}
            selectError={submissionFormValidation.cm_country_calling_codes?.error ?? undefined}
            isSelectOptionsLoading={dialingCodeAppOptionsQueries.isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GwsCloudmileGeneral;
