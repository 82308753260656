import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import moment from 'moment';
import {
  PostSimpStdAppAttPayload,
  PostSimpGCPStdAppPayload,
  PostSimpGWSStdAppPayload,
} from '../../../../types/application/simplified/create';
import { DateISO } from '../../../../types';

export const initPostSimpStdAppAttPayloadAtom = atom<PostSimpStdAppAttPayload>({
  company_registration: null,
  random_file: null,
});

export const postSimpStdAppAttPayloadAtom = atomWithDefault<PostSimpStdAppAttPayload>((get) =>
  get(initPostSimpStdAppAttPayloadAtom)
);

export const initPostSimpGCPStdAppPayloadAtom = atom<PostSimpGCPStdAppPayload>({
  app_type: 'simplified',
  product: 'gcp',
  client_type: 'standard',
  status: 'pending',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  quotation_validity: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_term: undefined,
  automatically_renew_term: undefined,
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },
  agreement_commencement_date: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_end_date: undefined,

  customer_type: 'NEW',
  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',

  payment_term: { name: '', value: 0 },
  official_pricing_discount_type: { name: 'No discount', value: 'No discount' },
  official_pricing_discount_value: 0,
  official_pricing_discount_currency: { name: '', value: '' },
  support_plan: { name: '', value: '' },
  estimated_mrr: undefined,
  is_issued_form: 'NO',
  reason_for_discount_rate: '',
});
export const postSimpGCPStdAppPayloadAtom = atomWithDefault<PostSimpGCPStdAppPayload>((get) =>
  get(initPostSimpGCPStdAppPayloadAtom)
);

export const initPostSimpGWSStdAppPayloadAtom = atom<PostSimpGWSStdAppPayload>({
  app_type: 'simplified',
  product: 'gws',
  client_type: 'standard',
  status: 'pending',
  cm_legal_entity: { name: '', value: '' },
  cm_attention_to: { name: '', value: '' },
  cm_country_calling_code: { name: '', value: '' },
  cm_phone_number: '',
  quotation_validity: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_term: undefined,
  automatically_renew_term: undefined,
  currency: { name: '', value: '' },
  exchange_rate: { name: '', value: '' },
  agreement_commencement_date: moment(new Date()).add(14, 'days').toISOString() as DateISO,
  agreement_end_date: undefined,

  client: { name: '', value: '' },
  client_source: undefined,
  client_country: { name: '', value: '' },
  tax_reg_number: '',
  client_address: '',
  client_attention_to: '',
  client_email: '',
  client_country_calling_code: { name: '', value: '' },
  client_phone_number: '',

  workspace_edition: { name: '', value: '' },
  workspace_plan: { name: '', value: '' },
  payment_term: { name: '', value: 0 },
  support_plan: { name: '', value: '' },
  official_pricing_discount_type: { name: 'No discount', value: 'No discount' },
  official_pricing_discount_value: 0,
  official_pricing_discount_currency: { name: '', value: '' },
  estimated_mrr: undefined,
  per_user_per_period: 0,
  is_issued_form: 'NO',
  is_auto_renewal: 'NO',
  reason_for_discount_rate: '',
});

export const postSimpGWSStdAppPayloadAtom = atomWithDefault<PostSimpGWSStdAppPayload>((get) =>
  get(initPostSimpGWSStdAppPayloadAtom)
);
