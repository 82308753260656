import { ComponentProps, forwardRef } from 'react';
import { styled } from '../../styles/stitches.config';
import { ButtonText } from '../typography/text';
import Checkbox from '../field/checkbox';

const CheckTextRoot = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 'none',
  columnGap: 10,
  backgroundColor: '$transparent',
  '&:disabled': {
    color: '$text-primary',
    cursor: 'not-allowed',
  },
});

interface CheckTextProps extends ComponentProps<typeof CheckTextRoot> {
  text: string;
  checked: boolean;
  onChecked: (newValue: boolean) => void;
  disabled?: boolean;
}

const CheckText = forwardRef<HTMLButtonElement, CheckTextProps>(
  ({ text, checked, onChecked, disabled, ...restProps }, ref) => {
    return (
      <CheckTextRoot
        ref={ref}
        type="button"
        disabled={disabled}
        onClick={() => onChecked(!checked)}
        data-state={checked ? 'active' : 'inactive'}
        {...restProps}>
        <Checkbox disabled={disabled} checked={checked} onChange={() => {}} />
        <ButtonText size="16">{`${text}`}</ButtonText>
      </CheckTextRoot>
    );
  }
);

CheckText.displayName = 'CheckText';

export default CheckText;
