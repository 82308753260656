import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxios } from '../../../hooks';
import { CommonAPIResponse } from '../../../types';
import {
  PostGCPStdAppPayload,
  PostGWSStdAppPayload,
  PostPLPSStdAppPayload,
  PostStdAppConditionResponse,
} from '../../../types/application/constants';

export const useGetCreateAppPermission = () => {
  const axios = useAxios();
  return useQuery<{ show_apply_document: boolean }, AxiosError<CommonAPIResponse>>({
    queryKey: ['app', 'create', 'permission'],
    queryFn: async () => {
      const { data } = await axios.get(`/v1/verified_app/permission/application/create`);
      return data;
    },
  });
};

// TODO: Verified GWS 時記得補上 GWS 的 Payload type

export const usePostStdAppCondition = (options: {
  onSuccess: (res: PostStdAppConditionResponse) => void;
  onError: (err: AxiosError<CommonAPIResponse>) => void;
}) => {
  const axios = useAxios();
  return useMutation<
    PostStdAppConditionResponse,
    AxiosError<CommonAPIResponse>,
    PostGCPStdAppPayload | PostPLPSStdAppPayload | PostGWSStdAppPayload
  >({
    mutationKey: ['app', 'create', 'condition'],
    mutationFn: async (
      requestBody: PostGCPStdAppPayload | PostPLPSStdAppPayload | PostGWSStdAppPayload
    ) => {
      const { data } = await axios.post<PostStdAppConditionResponse>(
        `v1/verified_app/official_terms_validation`,
        requestBody
      );
      return data;
    },
    onSuccess: (res) => {
      options.onSuccess(res);
    },
    onError: (err) => {
      options.onError(err);
    },
  });
};
