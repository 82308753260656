import { contentShow, overlayShow } from '../../styles/keyframes';
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import { styled } from '../../styles/stitches.config';

export const DialogAlertRoot = styled(RadixAlertDialog.Root, {});
export const DialogAlertTrigger = styled(RadixAlertDialog.Trigger, {});
export const DialogAlertPortal = styled(RadixAlertDialog.Portal, {});
export const DialogAlertOverlay = styled(RadixAlertDialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});
export const DialogAlertContent = styled(RadixAlertDialog.Content, {
  zIndex: 1000,
  backgroundColor: '$white',
  borderRadius: '$5',
  boxShadow: '$basic',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '85vh',
  padding: 25,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
});
export const DialogAlertTitle = styled(RadixAlertDialog.Title, {});
export const DialogAlertDescription = styled(RadixAlertDialog.Description, {});
export const DialogAlertCancel = styled(RadixAlertDialog.Cancel, {});
export const DialogAlertAction = styled(RadixAlertDialog.Action, {});
